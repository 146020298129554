import React, { useState } from 'react';
import { Trans } from 'react-i18next';

import { ErrorDetails, ErrorDetailsList, ErrorDetailsListItem, ErrorReasonText, IconArrow, ReadMoreBlockWrapper, ReadMoreButton } from './styles';
import { ProxyInfoRowWrapper } from '../styles';

const ProxyInfoError: React.FC = () => {
  const [isReadMoreVisible, setIsReadMoreVisible] = useState<boolean>(false);

  const handleReadMoreClick = (): void => setIsReadMoreVisible(isVisible => !isVisible);

  const errorKeys = [
    'proxies.error.howToFixListItemOne',
    'proxies.error.howToFixListItemTwo',
    'proxies.error.howToFixListItemThree',
  ];

  return (
    <>
      <ErrorReasonText>
        <Trans i18nKey='proxies.error.reason' />
      </ErrorReasonText>
      {isReadMoreVisible ? (
        <ReadMoreBlockWrapper>
          <ErrorDetails>
            <Trans i18nKey='proxies.error.howToFixTitle' />
            <ErrorDetailsList>
              {errorKeys.map((key) => (
                <ErrorDetailsListItem key={key}>
                  <Trans i18nKey={key} />
                </ErrorDetailsListItem>
              ))}
            </ErrorDetailsList>
          </ErrorDetails>
          <ProxyInfoRowWrapper>
            <ReadMoreButton onClick={handleReadMoreClick}>
              <Trans i18nKey='proxies.readLess' />
              <IconArrow
                color='var(--767676-proxy-manager-info-error)'
                width={16}
                height={10}
                style={{ transform: 'scaleY(-1)' }}
              />
            </ReadMoreButton>
          </ProxyInfoRowWrapper>
        </ReadMoreBlockWrapper>
      ) : (
        <ProxyInfoRowWrapper>
          <ReadMoreButton onClick={handleReadMoreClick}>
            <Trans i18nKey='proxies.readMore' />
            <IconArrow color='var(--767676-proxy-manager-info-error)' width={16} height={10} />
          </ReadMoreButton>
        </ProxyInfoRowWrapper>
      )}
    </>
  );
};

export default ProxyInfoError;
