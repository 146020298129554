import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { searchShareLinksForProfiles } from './api';
import { AddLinkButton } from './button-add-link';
import { IProfileToShareWithPermissions } from './interfaces';
import ShareLinksList from './share-links-list';
import { ShareLinkTitle } from './styles';
import { ShareRole } from '../../../../../interfaces';

interface IShareLinks {
  profiles: IProfileToShareWithPermissions[];
}

const ShareLinks: React.FC<IShareLinks> = (props) => {
  const { profiles } = props;

  const [shareLinks, setShareLinks] = useState<{ _id: string; url: string; role: ShareRole }[]>([]);

  const { t: translation } = useTranslation();

  const isVisible = profiles.length === 1 && profiles.every(({ permissions }) => permissions?.viewShareLinks);

  useEffect(() => {
    if (!isVisible) {
      return;
    }

    searchShareLinksForProfiles(profiles).then((links) => {
      if (!links.length) {
        return;
      }

      setShareLinks(links);
    });
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <ShareLinkTitle>
        {translation('newShareModal.links.title')}
      </ShareLinkTitle>
      {shareLinks.length ?
        <ShareLinksList profileIds={profiles.map(({ id }) => id)} shareLinks={shareLinks} setShareLinks={setShareLinks} /> :
        <AddLinkButton profiles={profiles} setShareLinks={setShareLinks} />}
    </>
  );
};

export default ShareLinks;
