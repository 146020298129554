import React, { memo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import AccountMenu from './account-menu';
import ButtonCreateInHeader, { IButtonCreateInHeader } from './button-create-in-header';
import BuyProxy from './buy-proxy';
import { IconContainerWithDescription } from './buy-proxy/styles';
import { IconBurger, IconGlobe } from './icons';
import { IIconWrapper, typeColorIcon } from './icons/wrapper';
import { HeaderEndContainer } from './styles';
import { PROXY_PAGE } from '../../../common/constants/routes';
import { TRAFFIC_DATA_LOAD_TRIGGERS } from '../../features/proxy/constants';
import { history } from '../../services';
import PerformanceObserverService from '../../services/performance-observer/performance-observer.service';
import { loadTrafficData } from '../../state/proxy/proxy-operations/load-geoproxy-traffic-data.operations';
import { openProxiesTableModal, PROXY_IMPORT_MODAL_NAME } from '../../state/proxy/proxy-table/proxies-table-modal.atom';
import TooltipCustom from '../tooltip-custom';
import { TooltipPlacement } from '../tooltip-custom/interfaces';

type IconInHeaderProps = IIconWrapper & {
  colorIconType: typeColorIcon;
  textColor: string;
  textHoveredColor: string;
}

const HeaderRightPart: React.FC = () => {
  const [anchorElTooltip, setAnchorElTooltip] = useState<HTMLElement|null>(null);
  const [tooltipText, setTooltipText] = useState<string|null>('');
  const [tooltipPlacement, setTooltipPlacement] = useState<TooltipPlacement>('bottom');
  const [anchorElAccountMenu, setAnchorElAccountMenu] = React.useState<HTMLElement | null>(null);
  const [anchorElBuyProxy, setAnchorElBuyProxy] = React.useState<HTMLElement | null>(null);

  const { t: translation } = useTranslation();
  const location = useLocation();

  const onCloseAccountMenu = (): void => setAnchorElAccountMenu(null);

  const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>, translationKey: string, tooltipPlace: TooltipPlacement = 'bottom'): void => {
    setAnchorElTooltip(event.currentTarget);
    setTooltipPlacement(tooltipPlace);
    setTooltipText(translation(translationKey));
  };

  const openAccountMenu: React.MouseEventHandler<HTMLButtonElement> = (event): void => {
    const performanceObserverService = PerformanceObserverService.getInstance();
    performanceObserverService.handleUserAction({ userAction: 'open-account-menu' });

    setAnchorElAccountMenu(event.currentTarget);
  };

  const handleClickCreateProfile: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    history.push('/newProfile');
  };

  const handleClickImportProxies: React.MouseEventHandler<HTMLDivElement> = async (event) => {
    event.stopPropagation();
    openProxiesTableModal(PROXY_IMPORT_MODAL_NAME);
  };

  const handleClickOpenTrafficModal: React.MouseEventHandler<HTMLDivElement> = (event) => {
    loadTrafficData(TRAFFIC_DATA_LOAD_TRIGGERS.trafficModal);
    setAnchorElBuyProxy(event.currentTarget);
  };

  const handleCloseTrafficModal = (): void => setAnchorElBuyProxy(null);

  const iconInHeaderProps: IconInHeaderProps = {
    colorIconType: 'activeGrayWithBackground',
    iconColor: 'var(--767676-header-right-part)',
    iconHoveredColor: 'var(--2B2B31-header-right-part)',
    hoveredHighlight: true,
    textColor: 'var(--767676-header-right-part)',
    textHoveredColor: 'var(--2B2B31-header-right-part)',
    iconType: 'stroke',
  };

  const buttonCreateInHeaderProps: IButtonCreateInHeader = {
    translationKey: 'folders.createFirstFolder.addProfile',
    handleClick: handleClickCreateProfile,
    handleMouseEnter: (event): void => handleMouseEnter(event, 'tooltip.header.createProfile'),
    handleMouseLeave: (): void => setAnchorElTooltip(null),
  };

  if (location.pathname === PROXY_PAGE) {
    buttonCreateInHeaderProps.translationKey = 'header.addProxyMenu.importProxies';
    buttonCreateInHeaderProps.handleClick = handleClickImportProxies;
    buttonCreateInHeaderProps.handleMouseEnter = (event): void => handleMouseEnter(event, 'tooltip.header.addMultipleProxies');
  }

  return (
    <>
      <HeaderEndContainer>
        <ButtonCreateInHeader {...buttonCreateInHeaderProps} />
        <IconContainerWithDescription
          {...iconInHeaderProps}
          onClick={handleClickOpenTrafficModal}
          onMouseEnter={(event): void => handleMouseEnter(event, 'tooltip.header.proxyData')}
          onMouseLeave={(): void => setAnchorElTooltip(null)}
        >
          <IconGlobe />
          <Trans i18nKey='header.buyProxy' />
        </IconContainerWithDescription>
        <IconBurger
          styleType={anchorElAccountMenu ? 'grayWithBackground' : 'activeGrayWithBackground'}
          onClick={openAccountMenu}
          onMouseEnter={(event): void => handleMouseEnter(event, 'tooltip.header.burgerMenu', 'bottom-end')}
          onMouseLeave={(): void => setAnchorElTooltip(null)}
          iconColor={anchorElAccountMenu ? 'var(--2B2B31-header-right-part)' : 'var(--767676-header-right-part)'}
          highlight={!!anchorElAccountMenu}
          iconHoveredColor='var(--2B2B31-header-right-part)'
          hoveredHighlight={true}
        />
      </HeaderEndContainer>
      <AccountMenu anchorEl={anchorElAccountMenu} onClose={onCloseAccountMenu} />
      <BuyProxy anchorEl={anchorElBuyProxy} onClose={handleCloseTrafficModal} />
      <TooltipCustom anchorEl={anchorElTooltip} value={tooltipText || ''} placement={tooltipPlacement} />
    </>
  );
};

export default memo(HeaderRightPart);
