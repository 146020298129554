import { ANDROID_RESOLUTIONS, RESOLUTIONS } from '../features/common/all-resolutions';

// Changing values affects the rendering in drawer
export const HARDWARE_CONCURRENCY = [2, 4, 6, 8, 10, 12, 16, 20, 24, 32, 64] as const;
export const DEVICE_MEMORY = [1, 2, 4, 6, 8] as const;
export const MAX_TOUCH_POINTS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10] as const;

export type HardwareConcurrencyType = typeof HARDWARE_CONCURRENCY[number];
export type DeviceMemoryType = typeof DEVICE_MEMORY[number];
export type MaxTouchPointsType = typeof MAX_TOUCH_POINTS[number];

export interface INavigator {
  userAgent: string;
  resolution: typeof RESOLUTIONS[number]|typeof ANDROID_RESOLUTIONS[number];
  language: string;
  platform: string;
  doNotTrack?: boolean;
  hardwareConcurrency?: HardwareConcurrencyType;
  deviceMemory?: DeviceMemoryType;
  maxTouchPoints?: MaxTouchPointsType;
}
