import styled from '@emotion/styled';

import { IProxyModalSize } from '../../interfaces/proxy-modal-size.interface';
import { ProxyPageModalWrapper } from '../styles';

export const PROXY_SETTINGS_MODAL_SIZE: Omit<IProxyModalSize, 'height'> = {
  width: 422,
};

export const PROXY_SETTINGS_MODAL_CONTENT_SIZE: Omit<IProxyModalSize, 'height'> = {
  width: 374,
};

export const ProxySettingsModalWrapper = styled(ProxyPageModalWrapper)`
  .ant-modal-body {
    height: 317px;
  }
`;
