import { Popconfirm, message } from 'antd';
import React, { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCreateMultipleProfiles } from './create-multiple-profiles.hook';
import { IMenuItemsProps } from './menu-items-props.interface';
import { usePermissionCheck } from './permission-check.hook';
import { AccountMenuPopoverCustom, Button, Input, Item, Separator, WrapperInput } from './styles';
import { DEFAULT_PROFILE_CREATE_COUNT_KEY } from '../../../../../common/constants/constants';
import { history } from '../../../../services';
import { useCurrentTheme } from '../../../../state/theme.atom';
import getSelectedThemeColor from '../../../../utils/get-selected-theme-color';

const CREATE_PROFILES_LIMIT = 30;
const CREATE_PROFILES_DEFAULT = 5;

const ProfileMenuItems: React.FC<IMenuItemsProps> = (props) => {
  const { handleCloseMenu, anchorEl } = props;

  const [profileCount, setProfileCount] = useState(CREATE_PROFILES_DEFAULT);

  const inputRef = useRef<HTMLInputElement>(null);

  const { t: translation } = useTranslation();

  const theme = useCurrentTheme();

  const isDarkTheme = getSelectedThemeColor(theme) === 'dark';

  const { isShowChangePlanMessage, redirectToPricing, setIsShowChangePlanMessage, checkPermission } = usePermissionCheck();

  const onCloseMenu = (): void => {
    setIsShowChangePlanMessage(false);
    handleCloseMenu();
  };

  const { createMultipleProfiles } = useCreateMultipleProfiles({
    profileCount,
    setIsShowChangePlanMessage,
    handleCloseMenu: onCloseMenu,
  });

  useEffect(() => {
    const defaultValueLimit = Number(localStorage.getItem(DEFAULT_PROFILE_CREATE_COUNT_KEY)) || CREATE_PROFILES_DEFAULT;
    setProfileCount(defaultValueLimit);
  }, []);

  const onChangeProfileCount = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = Number(event.target.value);

    if (typeof value === 'number' && !isNaN(value) && value >= 0) {
      setProfileCount(value);
    }
  };

  const addMultipleProfile = async (): Promise<void> => {
    if (!(checkPermission() && profileCount)) {
      inputRef.current?.focus();

      return;
    }

    if (profileCount > CREATE_PROFILES_LIMIT) {
      message.error(translation('notifications.error.createProfilesLimit'));

      setProfileCount(CREATE_PROFILES_LIMIT);
      inputRef.current?.focus();

      return;
    }

    if (profileCount < 0) {
      setProfileCount(0);
      localStorage.setItem(DEFAULT_PROFILE_CREATE_COUNT_KEY, '0');
    } else {
      createMultipleProfiles();
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      addMultipleProfile();
    }
  };

  const redirectToDefaultProfileSettings = (): void => {
    onCloseMenu();
    history.push('/personalArea/default-profile-settings');
  };

  const onCancelConfirmMessage = (): void => {
    setIsShowChangePlanMessage(false);
    onCloseMenu();
  };

  return (
    <AccountMenuPopoverCustom
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onCloseMenu}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      style={{ zIndex: 1029 }}
      isDarkTheme={isDarkTheme}
    >
      <>
        <Popconfirm
          placement='left'
          title={translation('popupPaidFunction.massProfileCreate.text')}
          visible={isShowChangePlanMessage}
          onConfirm={redirectToPricing}
          onCancel={onCancelConfirmMessage}
          okText={translation('popupPaidFunction.button.ok')}
          cancelText={translation('popupPaidFunction.button.cancel')}
        >
          <Item>
            <Button onClick={addMultipleProfile} className='buttonWithInput'>
              {translation('header.addProfileMenu.addMultipleProfiles')}
            </Button>
            <WrapperInput>
              <Input
                ref={inputRef}
                value={profileCount}
                onKeyDown={handleKeyDown}
                onChange={onChangeProfileCount}
              />
            </WrapperInput>
          </Item>
        </Popconfirm>
        <Separator />
        <Item>
          <Button onClick={redirectToDefaultProfileSettings}>
            {translation('header.addProfileMenu.editDefaultProfileSettings')}
          </Button>
        </Item>
      </>
    </AccountMenuPopoverCustom>
  );
};

export default ProfileMenuItems;
