import { atom, getDefaultStore, useAtomValue } from 'jotai';
import { ChangeEvent } from 'react';
import { defaultCookieBotSites } from '../../features/automation/cookiebot-parameters-modal/constants';

const workflowCookieBotSitesAtom = atom<string>(defaultCookieBotSites.join('\n'));

export const getWorkflowCookieBotSites = () => getDefaultStore().get(workflowCookieBotSitesAtom);

export const handleOnSitesTextChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
  const newSitesValue = event.target.value.split('\n');
  setWorkflowCookieBotSites(newSitesValue);
};

export const setWorkflowCookieBotSites = (sitesArray: string[]) => {
  const sitesString = sitesArray.join('\n');

  getDefaultStore().set(workflowCookieBotSitesAtom, sitesString);
};

export const useWorkflowCookieBotSites = () => useAtomValue(workflowCookieBotSitesAtom);
