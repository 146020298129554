import { atom, getDefaultStore, useAtomValue } from 'jotai';

import PerformanceObserverService from '../../services/performance-observer/performance-observer.service';

export const INVITE_MEMBER_MODAL_NAME = 'invite-member-modal-name';
export const ORBITA_INCOMPATIBLE_CANNOT_RUN_MODAL_NAME = 'orbita-incompatible-cannot-run-modal-name';
export const ORBITA_INCOMPATIBLE_SELECT_MODAL_NAME = 'orbita-incompatible-select-modal-name';
export const ORBITA_UPDATE_OR_SKIP_MODAL_NAME = 'orbita-update-or-skip-modal-name';

const MODAL_NAMES = <const>[
  INVITE_MEMBER_MODAL_NAME,
  ORBITA_INCOMPATIBLE_CANNOT_RUN_MODAL_NAME,
  ORBITA_INCOMPATIBLE_SELECT_MODAL_NAME,
  ORBITA_UPDATE_OR_SKIP_MODAL_NAME,
];

type GlobalModal = typeof MODAL_NAMES[number];

export type GlobalModalAction = `open-${GlobalModal}`;
export const GLOBAL_MODAL_ACTION = MODAL_NAMES.map<GlobalModalAction>(name => `open-${name}`);

type GlobalModalType = 'none' | GlobalModal;

type GlobalModalState = {
  currentModal: GlobalModalType;
  entityIds: string[];
}

const defaultGlobalModal: GlobalModalState = {
  currentModal: 'none',
  entityIds: [],
};

const globalModalAtom = atom<GlobalModalState>(defaultGlobalModal);

const useGlobalModalType = (): GlobalModalState['currentModal'] => useAtomValue(globalModalAtom).currentModal;

export const useGlobalModalIsVisible = (modalType: GlobalModalType): boolean => useGlobalModalType() === modalType;

export const getIsGlobalModalVisible = (modalType: GlobalModalType): boolean => {
  const store = getDefaultStore();
  const currentModal = store.get(globalModalAtom).currentModal;
  return currentModal === modalType;
};

export const closeGlobalModal = (): void => {
  getDefaultStore().set(globalModalAtom, { currentModal: 'none', entityIds: [] });
};

export const openGlobalModal = (modalType: GlobalModalType, entityIds: string[] = []): void => {
  if (modalType !== 'none') {
    const performanceObserverService = PerformanceObserverService.getInstance();
    performanceObserverService.handleUserAction({ userAction: `open-${modalType}` });
  }

  getDefaultStore().set(globalModalAtom, { currentModal: modalType, entityIds });
};

export const useGlobalModalEntityIds = (): GlobalModalState['modalProfileIds'] => useAtomValue(globalModalAtom).entityIds;
