import styled from '@emotion/styled';

import { textFont } from '../../style-templates';

export const Wrapper = styled.div<{ background: string, border?: string }>`
  position: relative;
  background: ${(props): string => props.background};
  border-radius: 10px 10px 0 0;
  padding: 12px 20px;
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  overflow: hidden;
  ${(props): string => props.border ? `border: 0.5px solid ${props.border};` : ''} 
`;

export const ArchiveProxiesWrapper = styled.div`
  position: relative;
  border-radius: 10px 10px 0 0;
  padding: 0px 32px 8px;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  overflow: hidden;
`;

export const Text = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: var(--535358-promo-bar);

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TextWitchIcon = styled.span`
  ${textFont};
  margin-right: 7px;
  margin-left: 24px;
  cursor: pointer;
`;

export const TextWithButton = styled(TextWitchIcon)<{ textColor?: string }>`
  color: ${(props): string|null => props.textColor ? props.textColor : null};
`;

export const ContainerClose = styled.div`
  position: absolute;
  right: 16px;
`;

export const TooltipContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 16px;
  margin-top: 8px;
  width: 210px;

  background: var(--FFFFFF-promo-bar);
  box-shadow: 0 1px 4px var(--00000003-promo-bar), 0 4px 8px var(--00000005-promo-bar), 0 1px 12px var(--0000001F-promo-bar);
  border-radius: 4px;
`;

export const TooltipText = styled('div')`
  ${textFont};
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: var(--535358-promo-bar);

`;

