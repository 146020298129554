import { Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

import { FreeProxyRegion, IProxy } from '../../../../interfaces';
import { ElementContainer, Subtitle } from '../domElements';

type GologinProxyFormProps = {
  proxy: IProxy;
  updateProxyRegion: (value: FreeProxyRegion) => void;
  isDisabled: boolean;
}

export const DEFAULT_GOLOGIN_PROXY_REGION: FreeProxyRegion = 'us';

const GologinProxyForm: React.FC<GologinProxyFormProps> = (props) => {
  const { proxy, updateProxyRegion, isDisabled } = props;

  const { t: translation } = useTranslation();

  return (
    <ElementContainer>
      <Subtitle>
        {translation('base.country')}
      </Subtitle>
      <Select
        value={proxy.autoProxyRegion || DEFAULT_GOLOGIN_PROXY_REGION}
        onChange={(value): void => updateProxyRegion(value)}
        style={{ width: 278 }}
        disabled={isDisabled}
      >
        <Option key='us'>
          US (
          {translation('countries.us')}
          )
        </Option>
        <Option key='ca'>
          CA (
          {translation('countries.ca')}
          )
        </Option>
        <Option key='uk'>
          UK (
          {translation('countries.uk')}
          )
        </Option>
        <Option key='de'>
          DE (
          {translation('countries.de')}
          )
        </Option>
        <Option key='in'>
          IN (
          {translation('countries.in')}
          )
        </Option>
      </Select>
    </ElementContainer>
  );
};

export default GologinProxyForm;
