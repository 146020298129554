import { createContext } from 'react';

import { ICheckCompetitorsAppsData, IMigrationData } from '../../electron/interfaces/check-competitors.interfaces';
import { InvoiceDetails } from '../features/personalArea/billing/types/invoice-details.types';

import { IFolder } from '../interfaces';
import {
  LocationChangedPreferenceType,
  LocationUnmaskedPreferenceType,
} from '../ui/gologin-header/hints-preferences/hint-preferences.types';
import { Hint } from '../ui/hints-widget/types';
import { INewFeatures } from './feature-toggle/new-features';

export interface ISharedFolder {
  id: string;
  name: string;
}

export type HintPreferences = {
  locationIsUnmasked: LocationUnmaskedPreferenceType;
  locationChanged: LocationChangedPreferenceType;
};

export interface IUser {
  _id?: string;
  hasTrial?: boolean;
  trialDays?: number;
  profiles?: number;
  profilesCountWithShares?: number;
  hasSuccessPayment?: boolean;
  plan?: any;
  card?: any;
  subscription?: any;
  payment?: any;
  paypalSubscriptionId?: string;
  paypalSubsCount?: number;
  planExpireDate?: string;
  targetPageShown?: boolean;
  isQuickSettingsEnabled?: boolean;
  firstPlanSelected?: boolean;
  usedUpgradePlans?: string[];
  defaultWorkspace?: string;
  sharedFolders?: ISharedFolder[];
  realPayments?: number;
  profileRuns?: number;
  lastAppVersion?: string;
  lastBrowserVersion?: string;
  lastPaymentDate: Date;
  folders: IFolder[];
  selectedFolder?: string;
  newFeatures: INewFeatures;
  isLastPaymentTrialEnded?: boolean;
  migrationData?: IMigrationData;
  gologinMetadata?: ICheckCompetitorsAppsData;
  hints: Hint[];
  invoiceDetails?: InvoiceDetails;
  hintPreferences?: HintPreferences;
}

export type UserPricingData = {
  hasUserEverPaid: boolean;
} & IUser;

export interface IUserCtx extends IUser {
  updateUser: (userInfo: Partial<IUser>) => void;
  updateFolders: (folders: IFolder[]) => void;
  updateSelectedFolder: (folder: string) => void;
  resetSelectedFolder: () => void;
}

export const USER_DEFAULT_VALUE: IUserCtx = {
  _id: '',
  email: '',
  hasTrial: false,
  trialDays: 0,
  createdAt: new Date(0),
  hasSuccessPayment: true,
  profiles: 0,
  profilesCountWithShares: 0,
  isEmailConfirmed: true,
  plan: {},
  card: {},
  subscription: {},
  firstPlanSelected: true,
  payment: {},
  updateUser: () => {},
  paypalSubsCount: 0,
  paypalSubscriptionId: '',
  planExpireDate: '',
  targetPageShown: false,
  usedUpgradePlans: [],
  defaultWorkspace: '',
  sharedFolders: [],
  is2faEnabled: false,
  realPayments: 0,
  profileRuns: 0,
  isCorrectlyRegistered: false,
  lastAppVersion: '3.2.7',
  lastBrowserVersion: '',
  lastPaymentDate: new Date(0),
  folders: [],
  selectedFolder: '',
  newFeatures: {},
  updateFolders: () => {},
  updateSelectedFolder: () => {},
  resetSelectedFolder: () => {},
  isLastPaymentTrialEnded: false,
  invitedByLink: '',
  invitedByUser: '',
  migrationData: {},
  gologinMetadata: {},
  hints: [],
  hintPreferences: {
    locationIsUnmasked: 'hidden',
    locationChanged: 'hidden',
  },
};

export const userContext = createContext<IUserCtx>(USER_DEFAULT_VALUE);
