import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

const IconProxyPlus: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M8 1V15' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M1 8H15' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  </IconWrapper>
);

export default IconProxyPlus;
