import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconPen: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_22721_42962)'>
        <path d='M9 1L11 3L4 10L1 11L2 8L9 1Z' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_22721_42962'>
          <rect width='12' height='12' fill='white' />
        </clipPath>
      </defs>
    </svg>
  </IconWrapper>
);


