import { atom, getDefaultStore, useAtomValue } from 'jotai';
import { HintPreferences } from '../user.context';

const INITIAL_HINT_PREFERENCES: HintPreferences = {
  locationIsUnmasked: 'hidden',
  locationChanged: 'hidden',
};

const hintPreferencesAtom = atom<HintPreferences>(INITIAL_HINT_PREFERENCES);

export const useHintPreferences = (): HintPreferences => useAtomValue(hintPreferencesAtom);

export const setHintPreferences = (newPreferences: HintPreferences): void => {
  getDefaultStore().set(hintPreferencesAtom, newPreferences);
};

export const updateHintPreferences = async (newPreferences: Partial<HintPreferences>): Promise<void> => {
  const oldPreferences = getDefaultStore().get(hintPreferencesAtom);
  const updatedPreferences = { ...oldPreferences, ...newPreferences };

  getDefaultStore().set(hintPreferencesAtom, updatedPreferences);
};
