import React, { FC } from 'react';

import { useDoesProfilesQueryHaveMoreProfiles } from '../../../state/profiles-table/profiles-query';
import InfiniteScrollInTable, { IInfiniteScrollInTable } from '../../common/custom-table/components/infinite-scroll-in-table';

type IInfiniteScrollProfilesTable = Omit<IInfiniteScrollInTable, 'hasMore'>;

export const InfiniteScrollProfilesTable: FC<IInfiniteScrollProfilesTable> = (props) => {
  const { dataLength, initialScrollY, loader, next, children } = props;

  const isMoreProfilesAvailable = useDoesProfilesQueryHaveMoreProfiles();

  return (
    <InfiniteScrollInTable
      dataLength={dataLength}
      next={next}
      hasMore={isMoreProfilesAvailable}
      loader={loader}
      initialScrollY={initialScrollY}
    >
      {children}
    </InfiniteScrollInTable>
  );
};
