import { PROXY_PAGE } from '../../../../common/constants/routes';
import { EMPTY_PROXY } from '../../../features/proxy/constants';
import { history } from '../../../services';
import { mapAndSetProfilesList } from '../../profiles-list.atom';
import { getProfilesTableProxyIdFilter } from '../../profiles-proxy-filter.atom';
import { getProfileSettingsProxyId } from '../proxy-in-profile-settings.atom';
import { getProxyManagerState, setProxyManagerCurrentProxy } from '../proxy-manager-modal-status.atom';
import { mapAndSetProxiesTableList } from '../proxy-table/proxies-table-list.atom';
import { updateSelectedProxies } from '../selected-proxies.atom';

const deleteProxyFromProfiles = (proxyIds: string[]): void => {
  mapAndSetProfilesList(prevProfiles => prevProfiles.map(profile => {
    const isDeletingProfileProxy = proxyIds && proxyIds.includes(profile.proxy.id || '');
    if (!isDeletingProfileProxy) {
      return profile;
    }

    return {
      ...profile,
      proxyEnabled: false,
      proxy: EMPTY_PROXY,
    };
  }));
};

const deleteProxiesFromProxyTable = (proxyIds: string[]): void =>
  mapAndSetProxiesTableList(prevProxies => prevProxies.filter(({ id }) => !proxyIds.includes(id)), proxyIds, true);

export const deleteProxies = (proxyIdsToDelete: string[]): void => {
  const { currentProxy: currentProxyInProxyManager, handleProxySelect } = getProxyManagerState();
  if (currentProxyInProxyManager && currentProxyInProxyManager.id && proxyIdsToDelete.includes(currentProxyInProxyManager.id)) {
    setProxyManagerCurrentProxy(null);
  }

  deleteProxyFromProfiles(proxyIdsToDelete);
  deleteProxiesFromProxyTable(proxyIdsToDelete);
  updateSelectedProxies({
    selectedProxies: [],
    selectedProxy: null,
  });

  const profilesTableProxyIdFilter = getProfilesTableProxyIdFilter();
  if (profilesTableProxyIdFilter && proxyIdsToDelete.includes(profilesTableProxyIdFilter)) {
    history.push(PROXY_PAGE);
  }

  if (handleProxySelect) {
    // TODO: store that in proxy manager too
    const profileSettingsProxyId = getProfileSettingsProxyId();
    if (profileSettingsProxyId && proxyIdsToDelete.includes(profileSettingsProxyId)) {
      handleProxySelect(null);
    }
  }
};
