import styled from '@emotion/styled/macro';

import { textFont } from '../../../../../ui/style-templates';

const EmptyProxyListIconWrapper = styled.div`
  width: 32px;
  height: 32px;
  margin-right: 8px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const EmptyProxyListSearchIconWrapper = styled(EmptyProxyListIconWrapper)``;

export const EmptyProxyListAddIconWrapper = styled(EmptyProxyListIconWrapper)`
  display: none;
`;

export const EmptyProxyListPlaceholderText = styled.span`
  ${textFont()}
  line-height: 20px;
  color: var(--767676-proxy-manager-list-item);
  text-align: center;
`;

export const EmptyProxyListPlaceholderWrapper = styled.div`
  margin-top: 53px;

  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

export const EmptyProxyListPlaceholderContent = styled.div`
  width: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  cursor: pointer;

  ${EmptyProxyListPlaceholderText} {
    margin-top: 16px;
  }

  :hover {
    ${EmptyProxyListSearchIconWrapper} {
      display: none;
    }

    ${EmptyProxyListAddIconWrapper} {
      display: flex;
    }

    ${EmptyProxyListPlaceholderText} {
      color: var(--36363D-proxy-tab-title);
    }
  }
`;
