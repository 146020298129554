// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../fonts/Graphik/Graphik-Regular-Web.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../fonts/Graphik/Graphik-RegularItalic-Web.woff2");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../fonts/Graphik/Graphik-Medium-Web.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../fonts/Graphik/Graphik-Bold-Web.woff2");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../fonts/Graphik/Graphik-Black-Web.woff2");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
exports.push([module.id, "/*\nInstant load    \n========\nhttps://cdn.rawgit.com/mfd/e7842774e037edf15919037594a79b2b/raw/665bdfc532094318449f1010323c84013d5af953/graphik.css\n*/\n@font-face {\n  font-family: 'Graphik';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2');\n}\n@font-face {\n  font-style: italic;\n  font-weight: normal;\n  font-family: 'Graphik';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff2');\n}\n@font-face {\n  font-weight: 500;\n  font-style: normal;\n  font-family: 'Graphik';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('woff2');\n}\n@font-face {\n  font-weight: 700;\n  font-style: normal;\n  font-family: 'Graphik';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('woff2');\n}\n@font-face {\n  font-weight: 900;\n  font-style: normal;\n  font-family: 'Graphik';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format('woff2');\n}\n@flags-url : flagSprite.png;\n", ""]);
// Exports
module.exports = exports;
