import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled';

import { PROXY_SELECTOR_PLACEHOLDER_MIN_WIDTH } from '../../constants/styles';
import { PROXY_SELECTOR_CONTENT_HEIGHT } from '../styles';

export const ProxyPlaceholderText = styled('div')<{ isInDrawer: boolean; newStyle: boolean }>`
  min-width: ${PROXY_SELECTOR_PLACEHOLDER_MIN_WIDTH}px;
  height: ${PROXY_SELECTOR_CONTENT_HEIGHT};
  padding-top: 4px;
  
  display: flex;
  align-items: center;
  
  font-size: 14px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.25px;
  
  color: var(--767676-proxy-manager-placeholder);
  cursor: pointer;
  
  ${(props): SerializedStyles | null => props.isInDrawer ? null : css`
    opacity: 0;
  `}

  ${(props): SerializedStyles | null => props.newStyle ? css`
    padding-top: 0;
  ` : null}

  &:hover {
    color: var(--00A987-proxy-manager);

    & i {
      color: var(--00A987-proxy-manager);
    }
  }

  & i {
    color: var(--CDCDCD-proxy-manager-placeholder);
  }

  .row-active:hover &,
  td:hover &,
  .new-proxy-cell:hover & {
    opacity: 1;
  }
`;
