import { useEffect } from 'react';

import { NEW_FEATURES } from '../state/feature-toggle/new-features';
import { clearGeoProxyCountries, loadGeoProxyCountries } from '../state/proxy/geoproxy-countries.atom';

export const useInitGeoProxyCountries = (userId?: string): void => {
  useEffect(() => {
    if (!(NEW_FEATURES.proxyGroups && userId)) {
      return clearGeoProxyCountries();
    }

    loadGeoProxyCountries();
  }, [userId]);
};
