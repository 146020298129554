import { IProfile } from '../../../interfaces';
import { IProfilesResponse, IRequestProfiles } from '../../quickProfiles/api';

interface IProfileForLinkParams {
  workspaceId: string;
  profileIdOrName: string;
  requestProfileFingerprint: (profileId: string) => Promise<any>;
  requestProfiles: (params: IRequestProfiles) => Promise<IProfilesResponse>;
}

export const getProfileForLink = async ({
  workspaceId,
  profileIdOrName,
  requestProfileFingerprint,
  requestProfiles,
}: IProfileForLinkParams): Promise<IProfile|void> => {
  const isPresumablyProfileObjectId = profileIdOrName.match(/^[0-9a-fA-F]{24}$/);
  if (isPresumablyProfileObjectId) {
    return requestProfileFingerprint(profileIdOrName).catch(() => null);
  }

  const { profiles } = await requestProfiles({ workspaceId, search: decodeURIComponent(profileIdOrName), offset: 0 })
    .catch(() => ({ profiles: [] }));

  const profileWithExactName = profiles.find(({ name }) => name === decodeURIComponent(profileIdOrName));

  return profileWithExactName;
};
