import css from '@emotion/css';
import styled from '@emotion/styled/macro';

import { textFont } from '../../../../../ui/style-templates';

export const CreateProxyButtonWrapper = styled.button`
  padding: 0;
  border: none;
  
  display: flex;
  justify-content: flex-end;
  align-items: center;
  
  background: none;
  cursor: pointer;

  /* gap */
  & > * + * {
    margin-left: 8px;
  }
`;

export const CreateProxyButtonText = styled.span`
  ${textFont()}
  color: var(--98989F-header);
`;

export const createProxyButtonActiveStyles = css`
  ${CreateProxyButtonText} {
    color: var(--2B2B31-header);
  }

  ${CreateProxyButtonWrapper} {
    svg path {
      stroke: var(--2B2B31-header);
    }
  }
`;
