
import { TolgeeProvider } from '@tolgee/react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import './i18n';
import './flag-icons.less';
import './fonts-faces';

import App from './app';
import initSentry from './services/init-sentry';
import { createAppStartTransactionSpan } from './utils/app-start.transaction.utils';
import { UserSessionService } from './utils/user-session-init';
import { tolgeeConfig } from '../common/tolgee.config';

const isElectron = !!window.require;
let ipcRenderer: Electron.IpcRenderer;

if (isElectron) {
  ({ ipcRenderer: ipcRenderer } = window.require('electron'));
  ipcRenderer.invoke('set-global-channel').catch(() => null);
  ipcRenderer.invoke('get-app-version')
    .then((appVersion: string) => {
      window.gologinAppVersion = appVersion || 'app';
      initSentry(appVersion);

      const UserSessionServiceInst = UserSessionService.getInstance();
      UserSessionServiceInst.setUserAppVersion = appVersion;
    });
}

if (!isElectron) {
  initSentry();
  TagManager.initialize({
    gtmId: 'GTM-PCVDMBT',
  });
}

createAppStartTransactionSpan('prepare-app-load');

ReactDOM.render(
  <TolgeeProvider
    tolgee={tolgeeConfig}
    fallback=""
  >
    <App />
  </TolgeeProvider>, document.getElementById('root'));
