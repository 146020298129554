import { message } from 'antd';
import React from 'react';
import { Trans } from 'react-i18next';

import { getSearchQuery } from '../../../state';
import { getProfilesTableProxyIdFilter } from '../../../state/profiles-proxy-filter.atom';
import { getProfilesSortField } from '../../../state/profiles-table/profiles-sort.atom';
import { getSelectedTagId } from '../../../state/tags/selected-tag.atom';
import { IProfilesTableColumn } from '../components/interfaces/app-settings.interface';
import { DEFAULT_SORT_FIELD } from '../constants';

const DND_SORT_FIELD: IProfilesTableColumn['sortField'] = 'order';

export const checkDragAndDropStatus = (): boolean => {
  const selectedTagId = getSelectedTagId();
  const profilesTableProxyIdFilter = getProfilesTableProxyIdFilter();
  const searchQuery = getSearchQuery();
  const sortField = getProfilesSortField() || DEFAULT_SORT_FIELD;

  let errorKey = null;
  switch (true) {
    case !!selectedTagId:
      errorKey = 'profilesTable.dragAndDrop.disableTag';

      break;
    case !!profilesTableProxyIdFilter:
      errorKey = 'profilesTable.dragAndDrop.disableProxy';

      break;
    case !!searchQuery:
      errorKey = 'profilesTable.dragAndDrop.disableSearch';

      break;
    case sortField !== DND_SORT_FIELD:
      errorKey ='profilesTable.dragAndDrop.disableSort';

      break;
    default:
  }

  if (errorKey) {
    message.error(<Trans i18nKey={errorKey} />);
  }

  return !errorKey;
};
