import React from 'react';

const IconTableCross = (): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group">
      <path id="Vector" d="M23 1L1 23" stroke="var(--98989F)" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path id="Vector_2" d="M1 1L23 23" stroke="var(--98989F)" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  </svg>
);

export default IconTableCross;
