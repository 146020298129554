import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconClone: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_19043_21913)'>
        <path d='M11.5 0.5H0.5V11.5H11.5V0.5Z' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M13.5 4.5H15.5V15.5H4.5V13.5' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_19043_21913'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  </IconWrapper>
);
