import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { InputText, Row } from './styles';
import SwitchAutoLang from './switch-auto-lang';
import { IProfile } from '../../../../interfaces';

interface IProfileLanguagesInputBlock {
  mode?: 'newProfile'|'editProfile';
  autoLang: boolean;
  language: string;
  updateProfileInfo: (params: Partial<IProfile>) => void;
  updateNavigator: (field: string, value: any) => void;
  setUpdatedField: (field: keyof IProfile) => void;
}

const ProfileLanguagesInputBlock: FC<IProfileLanguagesInputBlock> = (props) => {
  const { mode, autoLang, language, updateProfileInfo, updateNavigator, setUpdatedField } = props;
  const { t: translation } = useTranslation();

  const inputValue = autoLang ? translation('profile.languages.textBlockedInput') : language;

  const toggleAutoLang = (state: boolean): void => {
    updateProfileInfo({ autoLang: state });
    setUpdatedField('autoLang');
  };

  return (
    <Row>
      <InputText
        disabled={autoLang}
        value={inputValue}
        placeholder={translation(`${mode}.advanced.language.manualInputPlaceholder`) || ''}
        onChange={(event): void => updateNavigator('language', event.target.value)}
      />
      <SwitchAutoLang autoLang={autoLang} onChange={toggleAutoLang} />
    </Row>
  );
};

export default ProfileLanguagesInputBlock;
