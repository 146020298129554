import { css, SerializedStyles } from '@emotion/core';
import styled from '@emotion/styled';
import { Popover } from '@material-ui/core';

export const FolderNameInput = styled.input`
  flex: 1;

  border: none;
  outline: none;
  font-size: 14px;

  padding: 0;

  background: transparent;
  color: var(--2B2B31-header-folders-menu);

  &::placeholder {
    color: var(--CDCDCD-header-folders-sub-menu);
  }
`;

export const ItemRow = styled.div`
  color: var(--2B2B31-header-folders-menu);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 16px;
  font-size: 14px;

  cursor: pointer;
`;

export const FolderRow = styled(ItemRow)`
  border-radius: 4px;
  background: var(--FFFFFF-header-folders-sub-menu);

  :hover {
    background: var(--F2F2F2-header-folders-sub-menu);
  }
`;

export const FoldersListPopoverCustom = styled(Popover)<{ isDarkTheme: boolean }>`
  z-index: 950;

  .MuiPopover-paper {
    width: 214px;

    background: var(--FFFFFF-header-folders-sub-menu);
    border-radius: 4px;

    padding: 5px;
    margin-top: 2px;

    display: flex;
    flex-direction: column;
    margin-left: 92px;

    ${(props): SerializedStyles|null => !props.isDarkTheme ? css`
      box-shadow: 0 3px 6px var(--0000001A-header-folders-sub-menu), 0 4px 8px var(--00000014-header-folders-sub-menu), 0 1px 12px var(--0000000A-header-folders-sub-menu);
    ` : null}
  }
`;

export const RemoveFolderText = styled.span`
  color: var(--767676-header-folders-sub-menu);

  :hover {
    color: var(--222222-header-folders-sub-menu);
  }
`;
