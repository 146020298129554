import React from 'react';
import { useTranslation } from 'react-i18next';

import { GeoProxyType } from '../../../../../common/constants/types';
import { BundlePrices, IGeolocationProxyPrices } from '../../../../interfaces';
import { useCurrentTheme } from '../../../../state/theme.atom';
import getSelectedThemeColor from '../../../../utils/get-selected-theme-color';
import PriceItem from './price-item';
import { ItemRow, PriceListPopoverCustom } from './styles';

type AccountMenuProps = {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  price: IGeolocationProxyPrices;
  geoProxyType: GeoProxyType;
  availableForPurchase: number[];
  visionSide?: 'left' | 'right';
  bundle?: BundlePrices;
};

const PriceList: React.FC<AccountMenuProps> = (props) => {
  const { anchorEl, onClose, price, geoProxyType, availableForPurchase, visionSide = 'left', bundle } = props;

  const theme = useCurrentTheme();
  const { t: translation } = useTranslation();

  const isDarkTheme = getSelectedThemeColor(theme) === 'dark';

  const renderPrices = (): JSX.Element => {
    if (bundle) {
      
      return (
        <>
          {bundle[geoProxyType].map((priceItem) => (
            <PriceItem
              key={priceItem.dataAmount} 
              price={price}
              geoProxyType={geoProxyType} 
              onClose={onClose}
              visionSide={visionSide} 
              count={priceItem.dataAmount}
              bundlePrice={priceItem.price.usd}
            />
          ))}
        </>
      );
    } 
    
    return (
      <>
        {availableForPurchase.map((count) => (
          <PriceItem
            key={count}
            price={price}
            geoProxyType={geoProxyType}
            count={count}
            onClose={onClose}
            visionSide={visionSide}
          />
        ))}
      </>
    );
  };

  return (
    <PriceListPopoverCustom
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      style={{ zIndex: 1200 }}
      isDarkTheme={isDarkTheme}
    >
      <ItemRow style={{ color: 'var(--B3B3B8-header-buy-proxy)', paddingTop: '9px', paddingBottom: '2px', paddingLeft: '10px', fontSize: '12px', lineHeight: '20px', cursor: 'default' }}>
        {translation('trafficModal.buyTraffic')}
      </ItemRow>
      {renderPrices()}
    </PriceListPopoverCustom>
  );
};

export default PriceList;
