import { css, SerializedStyles } from '@emotion/core';

export const textFont = (): SerializedStyles => css`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
`;
