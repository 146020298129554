export const ACCESS_TOKEN = '@gologin:access_token';
export const MEMBER_TOKEN = '@gologin:member_token';

export const oAuthConfig = {
  googleOAuthClientId: '713507725115-sfv5mksf4jjsrccnmaut7rhdmtavk2d5.apps.googleusercontent.com',
  facebookOAuthClientId: '',

  websiteBaseUrl: 'https://app.gologin.com',
  // websiteBaseUrl: 'https://localhost:3000',
};

export const REGISTER_CONFIG = {
  captchaUrl: 'https://captcha.gologin.com',
  frameUrl: 'https://sign-up.gologin.com/',
  websiteFrameUrl: 'https://app.gologin.com/sign_up_frame',
  hcaptchaSitekey: 'd9d6ec11-4d4c-4023-915e-cf89b8a07b2a',
};

export const TIMEZONE_CHECK_URL_RENDERER = 'https://geo.myip.link';
