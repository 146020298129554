import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconSearchGeolocation: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='40' height='32' viewBox='0 0 40 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M21.856 28C20.749 29.242 20 30 20 30C20 30 9 18.866 9 12.048C9 5.057 14.684 1 20 1C25.316 1 31 5.057 31 12.048'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='square'
      />
      <path
        d='M20 15C21.6569 15 23 13.6569 23 12C23 10.3431 21.6569 9 20 9C18.3431 9 17 10.3431 17 12C17 13.6569 18.3431 15 20 15Z'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='square'
      />
      <path d='M36.9999 30.0002L32.5349 25.5352' strokeWidth='2' strokeMiterlimit='10' />
      <path
        d='M29 27C31.7614 27 34 24.7614 34 22C34 19.2386 31.7614 17 29 17C26.2386 17 24 19.2386 24 22C24 24.7614 26.2386 27 29 27Z'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='square'
      />
    </svg>
  </IconWrapper>
);
