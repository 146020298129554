import React, { ChangeEvent, FC, useEffect, useRef, useState, KeyboardEvent } from 'react';

import { IChangeSetting } from '../../interfaces';
import { AreaNoteBorder, ContainerParamsNote, ContainerUserAgentData, TextArea } from '../../styles';

let previousUserAgent = '';

interface IUserAgent extends IChangeSetting {
  profileUserAgent: string;
}

export const UserAgent: FC<IUserAgent> = ({ profileUserAgent, changeSetting }) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [userAgent, setUserAgent] = useState<string>(profileUserAgent);
  const [areaHeight, setAreaHeight] = useState<number>(20);

  const areaRef = useRef<HTMLTextAreaElement|null>(null);
  const hiddenDivRef = useRef<HTMLDivElement|null>(null);

  useEffect(() => {
    previousUserAgent = profileUserAgent;
    setUserAgent(profileUserAgent);

    if (hiddenDivRef.current) {
      hiddenDivRef.current.innerText = profileUserAgent;
      setAreaHeight(+hiddenDivRef?.current?.scrollHeight);
    }
  }, [profileUserAgent]);

  const onAreaFocus = ():void => {
    setIsActive(true);
  };

  const onAreaBlur = ():void => {
    setIsActive(false);
    changeSetting({ navigator: { userAgent } });
  };

  const onAreaChange = (event: ChangeEvent<HTMLTextAreaElement>):void => {
    setUserAgent(event.target.value);

    if (hiddenDivRef.current) {
      hiddenDivRef.current.innerText = event.target.value;
      setAreaHeight(+hiddenDivRef?.current?.scrollHeight);
    }
  };

  const onKeyPress = (event: KeyboardEvent<HTMLTextAreaElement>): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      areaRef?.current?.blur();
    }
  };

  return (
    <ContainerParamsNote>
      <AreaNoteBorder nameAreaActive={isActive}>
        <TextArea
          minHeight={20}
          ref={areaRef}
          placeholder='Enter user agent'
          value={userAgent}
          onChange={onAreaChange}
          onFocus={onAreaFocus}
          onBlur={onAreaBlur}
          onKeyPress={onKeyPress}
          style={{
            height: areaHeight,
            overflowY: 'hidden',
          }}
        />
        <ContainerUserAgentData ref={hiddenDivRef}>
          {userAgent}
        </ContainerUserAgentData>
      </AreaNoteBorder>
    </ContainerParamsNote>
  );
};
