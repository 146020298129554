import { Icon, message } from 'antd';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

import { DeletedProfilesTable } from './deleted-profiles-table';
import { EmptyTableText, LoaderDiv } from './styles';
import useGlobalAntStyles from '../../../hooks/use-global-ant-styles';
import {
  setDeletedProfilesList,
  useDeletedProfilesTable,
  workspaceContext,
} from '../../../state';
import { useCurrentWorkspaceId } from '../../../state/current-workspace-id.atom';
import { loadMoreDeletedProfiles } from '../../../state/deleted-profiles-table.commands';
import { IconCopy } from '../../../ui/gologin-header/icons/icon-copy';
import { SmallGreyText } from '../../../ui/styles';
import LoadingPage from '../../common/loading-page';
import PageOverlay from '../../quickProfiles/components/page-overlay';
import { TabWrapper } from '../menu/tab-wrapper';
import { PageTitle } from '../styles';

const RestoreProfilesPage: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { deletedProfilesList, isMoreDeletedProfilesAvailable } = useDeletedProfilesTable();

  const {
    permissions,
  } = useContext(workspaceContext);

  const workspaceId = useCurrentWorkspaceId() || '';

  const { t: translation } = useTranslation();

  const { areAntStylesLoading } = useGlobalAntStyles();

  useEffect(() => {
    resetDeletedProfiles();
  }, [workspaceId]);

  const resetDeletedProfiles = async (): Promise<void> => {
    setIsLoading(true);
    setDeletedProfilesList([]);
    if (!permissions.viewDeletedProfiles) {
      message.error(translation('notifications.error.permissionWorkspace'));
      setIsLoading(false);

      return;
    }

    await loadMoreDeletedProfiles(workspaceId).finally(() => setIsLoading(false));
  };

  const getEmptyTable = (): JSX.Element|null => {
    if (deletedProfilesList.length) {
      return null;
    }

    return (
      <div style={{ textAlign: 'center' }}>
        <IconCopy styleType='lightGray' padding={0} margin='0 0 20px' iconColor='var(--B5B5BA-profile-table)' />
        <EmptyTableText>
          {translation('folders.createFirstFolder.noProfile')}
        </EmptyTableText>
      </div>
    );
  };

  const getLoader = (): JSX.Element|null => (
    <LoaderDiv>
      <Icon type='loading' style={{ fontSize: 32 }} />
    </LoaderDiv>
  );

  const getDeletedProfileTable = (): JSX.Element => {
    if (isLoading) {
      return <LoadingPage />;
    }

    return (
      <div style={{ marginTop: 16, width: '800px' }}>
        <SmallGreyText>
          {translation('personalArea.restoreProfiles.subTitle')}
        </SmallGreyText>
        <InfiniteScroll
          dataLength={deletedProfilesList.length}
          next={(): Promise<void> => loadMoreDeletedProfiles(workspaceId)}
          hasMore={isMoreDeletedProfilesAvailable}
          loader={getLoader()}
          style={{ overflow: 'hidden' }}
          scrollableTarget={'root'}
        >
          <DeletedProfilesTable />
        </InfiniteScroll>
        {getEmptyTable()}
      </div>
    );
  };

  if (areAntStylesLoading) {
    return <LoadingPage />;
  }

  return (
    <TabWrapper
      tabKey='restore-profiles'
      HelmetTitle={`${translation('personalArea.restoreProfiles.title')} - GoLogin ${window.gologinAppVersion || ''}`}
    >
      <PageTitle>
        {translation('personalArea.restoreProfiles.title')}
      </PageTitle>
      {getDeletedProfileTable()}
      <PageOverlay />
    </TabWrapper>
  );
};

export default RestoreProfilesPage;
