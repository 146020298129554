import styled from '@emotion/styled/macro';

import { textFont } from '../../../../../ui/style-templates';
import { greyDark } from '../../../../../ui/style-templates/colors';
import { ContainerSpaceBetween } from '../../../styles';

export const DescriptionContainer = styled('div')`
  display: flex;
  align-self: center;
  color: var(--767676-profile-new-settings);
  ${textFont()};
`;

export const IconPasteContainer = styled(DescriptionContainer)`
  display: none;
`;

export const LanguageParamContainer = styled(ContainerSpaceBetween)`
  cursor: pointer;

  :hover {
    ${IconPasteContainer} {
      display: flex;
    }
      
    ${DescriptionContainer} {
      display: none;
    }
  }
`;
