import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import TooltipCustom from '../../../../../ui/tooltip-custom';
import { ContainerFolderList } from '../folder-list/styles';

export const Mixed = ({ colorText, onClick, id }: { colorText?: string; onClick?: () => void; id?: string }): JSX.Element => {
  const [anchorElTooltip, setAnchorElTooltip] = useState<HTMLElement|null>(null);

  const { t: translation } = useTranslation();

  return (
    <>
      <ContainerFolderList
        id={id}
        style={{
          fontSize: 14,
          color: colorText || 'var(--767676-profile-new-settings)',
          cursor: colorText ? 'pointer' : 'not-allowed',
          width: 'auto',
          height: 20,
        }}
        onMouseEnter={(event): void => setAnchorElTooltip(event.currentTarget)}
        onMouseLeave={(): void => setAnchorElTooltip(null)}
        onClick={onClick}
      >
        {translation('base.mixed')}
      </ContainerFolderList>
      <TooltipCustom anchorEl={!colorText ? anchorElTooltip : null} value={translation('notifications.error.mixed') || ''} placement='top' />
    </>
  );
};
