import React, { FC } from 'react';

import ModernSwitch from '../../../../ui/modern-switch';
import { IChangeSetting } from '../../interfaces';
import { ContainerParams } from '../../styles';

interface IBrowserBookmarkSaving extends IChangeSetting{
  bookmarks: boolean;
}

export const BrowserBookmarkSaving: FC<IBrowserBookmarkSaving> = ({ changeSetting, bookmarks }) => (
  <ContainerParams>
    <ModernSwitch
      checked={bookmarks}
      onChange={(checked): void => changeSetting({ storage: { bookmarks: checked } })}
    />
  </ContainerParams>
);
