import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconSortUp: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_1018_11755)'>
        <path d='M13.5 9L11.5 7L9.5 9' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M11.5 7.00027L11.5 17.5723' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_1018_11755'>
          <rect width='24' height='24' fill='var(--FFFFFF)' />
        </clipPath>
      </defs>
    </svg>
  </IconWrapper>
);
