import {
  BROWSER_SETTINGS,
  HARDWARE_SETTINGS,
  OVERVIEW_SETTINGS,
  PINNED_SETTINGS,
  PROXY_SETTINGS,
  STORAGE_SETTINGS,
} from './settings-lists';
import { ISections, SECTIONS_TITLE } from '../../interfaces';

export const DEFAULT_SECTIONS: ISections = {
  pinned: {
    name: SECTIONS_TITLE[0],
    items: PINNED_SETTINGS,
    isOpen: true,
  },
  overview: {
    name: SECTIONS_TITLE[1],
    items: OVERVIEW_SETTINGS,
    isOpen: false,
  },
  proxy: {
    name: SECTIONS_TITLE[2],
    items: PROXY_SETTINGS,
    isOpen: false,
  },
  browser: {
    name: SECTIONS_TITLE[3],
    items: BROWSER_SETTINGS,
    isOpen: false,
  },
  storage: {
    name: SECTIONS_TITLE[4],
    items: STORAGE_SETTINGS,
    isOpen: false,
  },
  hardware: {
    name: SECTIONS_TITLE[5],
    items: HARDWARE_SETTINGS,
    isOpen: false,
  },
};
