import React from 'react';

const IconNewProfile = () => (
  <div style={{ display: 'inline-block', marginTop: 7, marginRight: 4, marginLeft: 1 }}>
    <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_0_1" fill="var(--FFFFFF)">
        <rect width="20" height="12" rx="1"/>
      </mask>
      <rect width="20" height="12" rx="1" stroke="var(--00B86D)" strokeWidth="12" mask="url(#path-1-inside-1_0_1)"/>
      <path d="M6.46875 9H5.48047L2.94531 4.96484V9H1.95703V3.3125H2.94531L5.48828 7.36328V3.3125H6.46875V9ZM10.9609 6.46094H8.625V8.21094H11.3555V9H7.63672V3.3125H11.3281V4.10938H8.625V5.67969H10.9609V6.46094ZM16.6211 7.59375L17.4961 3.3125H18.4766L17.1641 9H16.2188L15.1367 4.84766L14.0312 9H13.082L11.7695 3.3125H12.75L13.6328 7.58594L14.7188 3.3125H15.5469L16.6211 7.59375Z" fill="var(--FFFFFF)"/>
    </svg>
  </div>
);

export default IconNewProfile;
