import { API_BASE_URL } from '../../common/constants/constants';
import { http } from '../services';

export const prepareDbgReqDef = () => (url: string, options: any = {}) => {
  if (options.body) {
    options.body = JSON.stringify(options.body);
  }

  return http(`${API_BASE_URL}${url}`, options).then((res: any) => res.body)
};

export const prepareDbgApiDef = () => ({
  baseUrl: API_BASE_URL,
  http,
});
