import {
  CollisionDetection,
  DndContext,
  MouseSensorOptions,
  pointerWithin,
  rectIntersection,
  useSensor,
  useSensors,
} from '@dnd-kit-contextless/core';
import React from 'react';

import { handleGlobalDragEnd, handleGlobalDragStart } from '../../state/dragging-profiles-state.atom';
import { BlockableMouseSensor } from '../drag-n-drop/blockable-mouse-sensor';

// negative to never move on X only: breaks text selection
const DRAG_AND_DROP_MIN_X_DISTANCE = -1;
const DRAG_AND_DROP_MIN_Y_DISTANCE = 12;

const DND_MOUSE_SENSOR_CONFIG: MouseSensorOptions = {
  activationConstraint: {
    distance: {
      x: DRAG_AND_DROP_MIN_X_DISTANCE,
      y: DRAG_AND_DROP_MIN_Y_DISTANCE,
    },
  },
};

const pointerWithinOrRectIntersection: CollisionDetection = (args) => {
  const pointerCollisions = pointerWithin(args);
  if (pointerCollisions.length) {
    return pointerCollisions;
  }

  return rectIntersection(args);
};

export const GlobalDragAndDropContext: React.FC = (props) => {
  const { children } = props;

  const mouseSensor = useSensor(BlockableMouseSensor, DND_MOUSE_SENSOR_CONFIG);
  const dndSensors = useSensors(mouseSensor);

  return (
    <DndContext
      onDragStart={handleGlobalDragStart}
      onDragEnd={handleGlobalDragEnd}
      sensors={dndSensors}
      collisionDetection={pointerWithinOrRectIntersection}
    >
      {children}
    </DndContext>
  );
};
