import {
  getIsMoreDeletedProfilesAvailable,
  getDeletedProfilesList,
  setIsMoreDeletedProfilesAvailable,
  setDeletedProfilesList,
} from './deleted-profiles-table.atom';
import { PAGE_SIZE } from '../../common/constants/constants';
import { getDeletedProfileList, restoreProfiles } from '../features/personalArea/restore-profiles/api';
import { IRestoreProfiles } from '../features/personalArea/restore-profiles/interfaces';
import { IDeletedProfile } from '../interfaces';

const isElectron = !!window.require;
let ipcRenderer: Electron.IpcRenderer;
if (isElectron) {
  ({ ipcRenderer } = window.require('electron'));
}

export const loadMoreDeletedProfiles = async (workspaceId: string): Promise<void> => {
  const currentDeletedProfilesList = getDeletedProfilesList();
  const {
    deletedProfilesList: receivedDeletedProfiles,
    isMoreDeletedProfilesAvailable,
  } = await getDeletedProfileList(workspaceId, currentDeletedProfilesList.length);

  const resultProfilesArray = currentDeletedProfilesList.concat(receivedDeletedProfiles);
  setDeletedProfilesList(resultProfilesArray);
  setIsMoreDeletedProfilesAvailable(isMoreDeletedProfilesAvailable);
};

const updateVisibleDeletedProfilesList = (newDeletedProfilesList: IDeletedProfile[], workspaceId: string): void => {
  const isMoreDeletedProfilesAvailable = getIsMoreDeletedProfilesAvailable();

  setDeletedProfilesList(newDeletedProfilesList);

  const isProfileCountReachedPerPageLimit = newDeletedProfilesList.length < PAGE_SIZE;
  if (isProfileCountReachedPerPageLimit && isMoreDeletedProfilesAvailable) {
    loadMoreDeletedProfiles(workspaceId);
  }
};

export const onRestoreProfiles = async (profileIds: string[], workspaceId: string): Promise<IRestoreProfiles> => {
  const restoredProfiles = await restoreProfiles(profileIds, workspaceId);
  if (ipcRenderer) {
    ipcRenderer.invoke('restore-profiles', profileIds);
  }

  const currentDeletedProfilesList = getDeletedProfilesList();
  const newDeletedProfilesList = currentDeletedProfilesList.filter((profile) => !profileIds.includes(profile.profileId));
  updateVisibleDeletedProfilesList(newDeletedProfilesList, workspaceId);

  return restoredProfiles;
};
