// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../fonts/Gilroy/Gilroy-Semibold.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../fonts/Gilroy/Gilroy-Medium.woff2");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "@font-face {\n  font-weight: 600;\n  font-style: normal;\n  font-family: 'Gilroy';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2');\n}\n@font-face {\n  font-weight: 500;\n  font-style: normal;\n  font-family: 'Gilroy';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff2');\n}\n@flags-url : flagSprite.png;\n", ""]);
// Exports
module.exports = exports;
