import React from 'react';

import { IExtensionIcon } from './interfaces/extension-icon.interface';

export const ExtensionIcon = ({ iconBinary, urlPath }: IExtensionIcon): JSX.Element => {
  let src = `data:image/jpeg;base64,${iconBinary}`;
  if (urlPath) {
    src = urlPath;
  }

  return (
    <img src={src} style={{ width: '24px', height: '24px' }} alt={'no image'} />
  );
};
