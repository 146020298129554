import { IIconRectParams } from '../interfaces';
import { getIconPadding, getRoundRectBorderRadius } from '../utils/get-values';
import { drawGradient } from './draw-gradient';
import { drawRectShadow } from './draw-rect-shadow';

export const drawRectRound = ({ context, scaleFactor, osFormatted, size }: IIconRectParams): void => {
  if (!context) {
    return;
  }

  const padding = getIconPadding(osFormatted, scaleFactor);
  const rectSize = size - padding * 2;
  const borderRadius = getRoundRectBorderRadius(osFormatted, scaleFactor, size);

  const gradient = drawGradient(context, rectSize, padding);
  drawRectShadow({ context, os: osFormatted, scaleFactor });

  context.roundRect(padding, padding, rectSize, rectSize, borderRadius);
  context.fillStyle = gradient;
  context.fill();
};
