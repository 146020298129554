import React from 'react';

const IconPaypal = () => (
  <svg width="77" height="20" viewBox="0 0 77 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_13322_215861)">
      <path d="M24.8309 4C24.6756 4 24.5437 4.11271 24.5195 4.26619L22.7223 15.6634C22.7157 15.705 22.7183 15.7476 22.7298 15.7881C22.7413 15.8287 22.7614 15.8663 22.7888 15.8983C22.8162 15.9303 22.8502 15.956 22.8885 15.9737C22.9267 15.9913 22.9684 16.0004 23.0105 16.0004H25.1418C25.2969 16.0004 25.429 15.8875 25.4532 15.7342L25.9812 12.3874C26.0052 12.2341 26.1373 12.1212 26.2926 12.1212H28.2303C30.4944 12.1212 32.4163 10.4692 32.7674 8.23077C33.1213 5.97338 31.3578 4.0049 28.8583 4H24.8309ZM26.9027 6.5443H28.4531C29.7294 6.5443 30.1451 7.29743 30.0137 8.13031C29.8823 8.96497 29.2367 9.57977 28.002 9.57977H26.424L26.9027 6.5443ZM37.1022 7.31904C36.564 7.32104 35.9447 7.4313 35.2495 7.72088C33.6548 8.38558 32.889 9.75931 32.5638 10.7608C32.5638 10.7608 31.5286 13.8163 33.8673 15.495C33.8673 15.495 36.0363 17.1111 38.4779 15.3954L38.4358 15.6634C38.4293 15.705 38.4318 15.7475 38.4433 15.788C38.4547 15.8285 38.4748 15.8661 38.5022 15.8981C38.5295 15.9301 38.5634 15.9559 38.6017 15.9735C38.6399 15.9912 38.6815 16.0004 38.7236 16.0004H40.7469C40.9021 16.0004 41.034 15.8875 41.0583 15.7342L42.289 7.92938C42.2956 7.88775 42.2931 7.84519 42.2817 7.80462C42.2702 7.76405 42.2502 7.72644 42.2228 7.69437C42.1955 7.6623 42.1615 7.63653 42.1232 7.61885C42.085 7.60116 42.0433 7.59197 42.0012 7.59191H39.9779C39.8227 7.59191 39.6906 7.70485 39.6665 7.8581L39.6004 8.27821C39.6004 8.27821 38.7169 7.31302 37.1022 7.31904ZM37.1683 9.78425C37.4006 9.78425 37.6132 9.81633 37.8038 9.87759C38.6768 10.1578 39.1718 10.9965 39.0285 11.9055C38.8519 13.0249 37.9339 13.8491 36.7567 13.8491C36.5246 13.8491 36.3121 13.8172 36.1212 13.756C35.2484 13.4757 34.7503 12.6371 34.8936 11.728C35.0702 10.6087 35.9911 9.78425 37.1683 9.78425Z" fill="var(--003087)"/>
      <path d="M53.9676 4C53.8123 4 53.6805 4.11271 53.6562 4.26619L51.859 15.6634C51.8525 15.705 51.855 15.7476 51.8665 15.7881C51.878 15.8287 51.8981 15.8663 51.9255 15.8983C51.9529 15.9303 51.9869 15.956 52.0252 15.9737C52.0635 15.9913 52.1051 16.0004 52.1472 16.0004H54.2785C54.4336 16.0004 54.5657 15.8875 54.59 15.7342L55.1179 12.3874C55.1419 12.2341 55.274 12.1212 55.4293 12.1212H57.367C59.6311 12.1212 61.5528 10.4692 61.9036 8.23077C62.2578 5.97338 60.4945 4.0049 57.995 4H53.9676ZM56.0394 6.5443H57.5898C58.8662 6.5443 59.2818 7.29743 59.1504 8.13031C59.019 8.96497 58.3737 9.57977 57.1387 9.57977H55.5607L56.0394 6.5443ZM66.2387 7.31904C65.7005 7.32104 65.0812 7.4313 64.386 7.72088C62.7913 8.38558 62.0255 9.75931 61.7003 10.7608C61.7003 10.7608 60.6656 13.8163 63.0043 15.495C63.0043 15.495 65.1728 17.1111 67.6146 15.3954L67.5725 15.6634C67.566 15.705 67.5685 15.7476 67.58 15.7881C67.5915 15.8287 67.6116 15.8663 67.639 15.8983C67.6664 15.9303 67.7004 15.956 67.7387 15.9737C67.777 15.9913 67.8186 16.0004 67.8608 16.0004H69.8838C70.0391 16.0004 70.1709 15.8875 70.1952 15.7342L71.4264 7.92938C71.433 7.88772 71.4305 7.84511 71.419 7.80451C71.4076 7.76391 71.3875 7.72627 71.3601 7.6942C71.3327 7.66212 71.2986 7.63636 71.2603 7.61871C71.222 7.60105 71.1803 7.5919 71.1381 7.59191H69.1149C68.9596 7.59191 68.8275 7.70485 68.8034 7.8581L68.7373 8.27821C68.7373 8.27821 67.8534 7.31302 66.2387 7.31904ZM66.3048 9.78425C66.5372 9.78425 66.7497 9.81633 66.9403 9.87759C67.8133 10.1578 68.3083 10.9965 68.165 11.9055C67.9884 13.0249 67.0704 13.8491 65.8932 13.8491C65.6611 13.8491 65.4486 13.8172 65.2577 13.756C64.3849 13.4757 63.8868 12.6371 64.0301 11.728C64.2067 10.6087 65.1276 9.78425 66.3048 9.78425Z" fill="var(--0070E0)"/>
      <path d="M43.2486 7.59202C43.0882 7.59202 42.9742 7.74862 43.0232 7.9012L45.2414 14.7845L43.2357 18.0283C43.1384 18.1858 43.2515 18.3891 43.4366 18.3891H45.8072C45.875 18.3891 45.9416 18.3715 46.0006 18.3382C46.0597 18.3049 46.1091 18.2569 46.1442 18.1989L52.3392 7.95065C52.4343 7.79317 52.3207 7.5918 52.1367 7.5918H49.7664C49.698 7.59186 49.6307 7.60973 49.5712 7.64368C49.5118 7.67762 49.4622 7.72645 49.4274 7.78537L46.9889 11.9023L45.7511 7.81589C45.7107 7.6829 45.588 7.5918 45.4492 7.5918L43.2486 7.59202Z" fill="var(--003087)"/>
      <path d="M73.7259 4C73.5709 4 73.4388 4.11294 73.4145 4.26619L71.6169 15.663C71.6103 15.7046 71.6128 15.7472 71.6242 15.7878C71.6357 15.8284 71.6558 15.8661 71.6832 15.8981C71.7106 15.9302 71.7446 15.956 71.7829 15.9736C71.8212 15.9913 71.8629 16.0004 71.9051 16.0004H74.0364C74.1915 16.0004 74.3236 15.8875 74.3478 15.7342L76.145 4.33703C76.1515 4.29543 76.149 4.25292 76.1375 4.2124C76.1261 4.17189 76.106 4.13433 76.0786 4.10231C76.0513 4.07029 76.0174 4.04457 75.9791 4.02691C75.9409 4.00925 75.8993 4.00007 75.8572 4H73.7259Z" fill="var(--0070E0)"/>
      <path d="M7.06227 4C6.92497 4.00011 6.79222 4.04918 6.68786 4.1384C6.5835 4.22761 6.51437 4.35111 6.4929 4.48672L5.54688 10.4868C5.59098 10.2066 5.83244 10.0001 6.11623 10.0001H8.8884C11.6784 10.0001 14.0458 7.9648 14.4784 5.20666C14.5106 5.00074 14.5288 4.79288 14.533 4.58451C13.824 4.21273 12.9911 4 12.0785 4H7.06227Z" fill="var(--001C64)"/>
      <path d="M14.5317 4.58398C14.5275 4.79236 14.5093 5.00022 14.4771 5.20614C14.0445 7.96428 11.6768 9.99958 8.88706 9.99958H6.11488C5.83131 9.99958 5.58963 10.2059 5.54553 10.4863L4.67567 15.9999L4.13081 19.4588C4.12018 19.5257 4.12417 19.594 4.14251 19.6591C4.16085 19.7243 4.19311 19.7846 4.23705 19.8361C4.28099 19.8876 4.33558 19.9289 4.39703 19.9572C4.45849 19.9855 4.52536 20.0002 4.59303 20.0001H7.60199C7.73929 20 7.87203 19.9509 7.97639 19.8617C8.08075 19.7725 8.14988 19.649 8.17134 19.5134L8.9639 14.4865C8.98538 14.3508 9.05457 14.2273 9.15903 14.1381C9.26348 14.0488 9.39634 13.9998 9.53371 13.9998H11.3053C14.0953 13.9998 16.4627 11.9645 16.8953 9.20635C17.2025 7.24879 16.2166 5.46743 14.5317 4.58398Z" fill="var(--0070E0)"/>
      <path d="M3.6434 0C3.35983 0 3.11815 0.20627 3.07404 0.486272L0.712843 15.4589C0.668069 15.7431 0.88771 16.0002 1.17551 16.0002H4.67698L5.54639 10.4866L6.49242 4.48649C6.51388 4.35088 6.58302 4.22738 6.68738 4.13817C6.79174 4.04896 6.92449 3.99989 7.06178 3.99977H12.078C12.9908 3.99977 13.8235 4.21273 14.5325 4.58428C14.5811 2.07317 12.509 0 9.6602 0H3.6434Z" fill="var(--003087)"/>
    </g>
    <defs>
      <clipPath id="clip0_13322_215861">
        <rect width="75.4394" height="20" fill="var(--FFFFFF)" transform="translate(0.707031)"/>
      </clipPath>
    </defs>
  </svg>

);

export default IconPaypal;
