import { API_BASE_URL } from '../../../../common/constants/constants';
import { http } from '../../../services';

export const requestExtensionList = async (
  { skip = 0, searchString = '', extensionIds = [] }: { skip?: number; searchString?: string; extensionIds?: any[] },
): Promise<any[]> => {
  console.log(searchString);
  const url = `${API_BASE_URL}/extensions/extension_list?skip=${skip}&searchString=${searchString}&extensionIds=${extensionIds.join(',')}`;
  const data = await http(url, {
    method: 'GET',
  }).catch(() => ({ body: [] }));

  return data.body;
};

export const addExtensionToSettings = (extId: string, type: string): void => {
  const url = `${API_BASE_URL}/extensions/add_extensions_to_new_profiles`;
  const body = JSON.stringify({ extId, type });
  http(url, {
    method: 'POST',
    body,
  }).catch(() => null);
};

export const removeExtensionFromSettings = (extId: string, type: string): void => {
  const url = `${API_BASE_URL}/extensions/remove_extensions_from_new_profiles`;
  const body = JSON.stringify({ extId, type });
  http(url, {
    method: 'DELETE',
    body,
  }).catch(() => null);
};

