import styled from '@emotion/styled-base';

export const Wrapper = styled('div')`
    width: 100wh;
    height: 100vh;
    padding: 30px;

    display: flex;
    justify-content: center;

    & svg {
        width: 40px;
        height: 40px;
    }
`;
