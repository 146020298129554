import React from 'react';

import { useEffect, useRef } from 'react';
import { IconAutomationSuccess } from '../../../../ui/gologin-header/icons/icon-automation-success';
import { IconSpinner } from '../../../../ui/gologin-header/icons';
import { IconAutomationError } from '../../../../ui/gologin-header/icons/icon-automation-error';
import { AutomationStatusData } from '../../interfaces/automation-status.interfaces';

type AutomationStatusDisplayProps = {
  actualAutomationStatusData: AutomationStatusData;
  visibleAutomationStatusData: AutomationStatusData;
  setVisibleAutomationStatusData: (newStatusData: AutomationStatusData) => void;
}

const DELAYED_STATUSES = ['error', 'completed'];
const INSTANT_STATUSES = ['starting'];
const DELAY_MS = 10000;

const AutomationStatusDisplay: React.FC<AutomationStatusDisplayProps> = (props) => {
  const { actualAutomationStatusData, visibleAutomationStatusData, setVisibleAutomationStatusData } = props;

  const nextStatusRef = useRef<AutomationStatusData | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const { status: currentStatus } = visibleAutomationStatusData;

  const updateCurrentAutomationStatus = (statusData: AutomationStatusData) => {
    setVisibleAutomationStatusData(statusData);
  };

  const updateAutomationStatus = () => {
    if (DELAYED_STATUSES.includes(actualAutomationStatusData.status)) {
      updateCurrentAutomationStatus(actualAutomationStatusData);

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        if (!nextStatusRef.current) {
          timeoutRef.current = null;

          return;
        }

        updateCurrentAutomationStatus(nextStatusRef.current);
        nextStatusRef.current = null;
      }, DELAY_MS);

      return;
    } 

    if (INSTANT_STATUSES.includes(actualAutomationStatusData.status)) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = null;
      updateCurrentAutomationStatus(actualAutomationStatusData);

      return;
    }

    if (!timeoutRef.current) {
      updateCurrentAutomationStatus(actualAutomationStatusData);
    } else {
      nextStatusRef.current = actualAutomationStatusData;
    }
  };

  useEffect(() => {
    updateAutomationStatus();
  }, [actualAutomationStatusData]);

  return (
    <>
      {currentStatus === 'error' ?
        <IconAutomationError padding={0} /> :
        null
      }
      {['starting', 'processing'].includes(currentStatus) ? 
        <IconSpinner
          size={14}
          padding={0}
          iconType='fill'
          iconColor='var(--00A987)'
          iconHoveredColor='var(--00A987)'
        /> :
        null
      }
      {currentStatus === 'completed' ? 
        <IconAutomationSuccess padding={0} /> :
        null
      }
    </>
  );
};

export default AutomationStatusDisplay;
