import { IProfile } from '../../../interfaces';
import { blue, greenMain, greyInformers, red } from '../../../ui/style-templates/colors';

export type StatusType = 'default'|'error'|'success'|'processing';

export const statusColor: Record<StatusType, string> = {
  default: 'var(--B5B5BA-profile-table-status)',
  error: 'var(--F5222D-profile-table-status)',
  success: 'var(--00A987-profile-table-status)',
  processing: 'var(--1890FF-profile-table-status)',
};

export const getStatusTypeOfProfile = (profileStatus: IProfile['status']): StatusType => {
  switch(profileStatus) {
    case 'profileStatuses.ready':
      return 'default';
    case 'profileStatuses.error':
      return 'error';
    case 'profileStatuses.running':
      return 'success';
    default:
      return 'processing';
  }
};
