import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconCapterraLogo: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='22' height='21' viewBox='0 0 22 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M0.542969 7.72135L9.25213 7.72259L14.5479 7.72383V2.47754L0.542969 7.72135Z' fill='#F3A949' />
      <mask id='mask0_17951_60223' style={{ maskType: 'luminance' }} maskUnits='userSpaceOnUse' x='14' y='0' width='8' height='21'>
        <path d='M14.4844 0H21.4547V21H14.4844V0Z' fill='white' />
      </mask>
      <g mask='url(#mask0_17951_60223)'>
        <path d='M14.5469 2.47746V20.9997L21.1607 0.000976562L14.5469 2.47746Z' fill='#46C1ED' />
      </g>
      <mask id='mask1_17951_60223' style={{ maskType: 'luminance' }} maskUnits='userSpaceOnUse' x='9' y='7' width='6' height='14'>
        <path d='M9.09766 7.60352H14.8006V20.9996H9.09766V7.60352Z' fill='white' />
      </mask>
      <g mask='url(#mask1_17951_60223)'>
        <path d='M14.5497 7.72389L9.25391 7.72266L14.5497 20.9999V7.72389Z' fill='#006D9A' />
      </g>
      <path d='M0.542969 7.72168L10.6098 11.1301L9.25213 7.72292L0.542969 7.72168Z' fill='#DE6A64' />
    </svg>
  </IconWrapper>
);
