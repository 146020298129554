import styled from '@emotion/styled';

export const GologinTextArea = styled('textarea')<{ disabled?: boolean }>`
  width: 282px;
  height: 320px;
  border-radius: 8px;
  border: 0.5px solid var(--D2D2D5);
  padding: 8px 12px;

  white-space: pre;
  color: var(--000000A6);
  background-color: var(--FFFFFF);
  transition: all 0.3s;
  resize: none;

  font: 400 14px / 20px Roboto;
  letter-spacing: 0.25px;

  :hover {
    border-color: var(--1FC47D);
    border-right-width: 1px !important;
  }

  :focus {
    border-color: var(--1FC47D);
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px var(--00B86D33);
  }

  ::placeholder {
    color: var(--CDCDCD);
  }

  :disabled {
    cursor: not-allowed;
    background-color: var(--FAFAFA);
    border: none;
  }
  
  :disabled:hover,
  :disabled:focus {
    border-color: var(--D9D9D9);
    border: none;
  }
`;
