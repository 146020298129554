import { requestWorkspace } from './api';
import { IWorkspace } from '../../interfaces/workspaces';
import { IUserCtx, IWorkspaceCtx, blockSearch } from '../../state';
import { resetProfilesTable } from '../../state/profiles-list.atom';
import { ITabFolder } from '../quickProfiles/components/tab-profile';

let ipcRenderer: Electron.IpcRenderer;
const isElectron = !!window.require;
if (isElectron) {
  ipcRenderer = window.require('electron').ipcRenderer;
}

export interface IUserCtxSwitchFields {
  defaultWorkspace?: IUserCtx['defaultWorkspace'];
  sharedFolders?: IUserCtx['sharedFolders'];
  updateFolders: IUserCtx['updateFolders'];
}

const switchWorkspace = async (workspaceId: string, workspaceCtx: IWorkspaceCtx, userCtx: IUserCtxSwitchFields): Promise<IWorkspace> => {
  if (workspaceCtx.id === workspaceId) {
    return { ...workspaceCtx };
  }

  const workspace = await requestWorkspace(workspaceId);
  blockSearch();
  workspaceCtx.updateWorkspace({ ...workspace });
  resetProfilesTable();
  if (isElectron) {
    ipcRenderer && ipcRenderer.invoke('set-active-ws', { workspaceId });
  }

  if (workspaceCtx.id) {
    localStorage.removeItem('SelectedFolder');
  }

  let { folders }: { folders: ITabFolder[] } = workspace;
  const { defaultWorkspace, sharedFolders } = userCtx;
  if (sharedFolders && defaultWorkspace === workspaceId) {
    folders = (folders as ITabFolder[]).concat(sharedFolders);
  }

  userCtx.updateFolders(folders);

  return workspace;
};

export const refreshCurrentWorkspaceData = async (workspaceCtx: IWorkspaceCtx): Promise<void> => {
  const workspace = await requestWorkspace(workspaceCtx.id);
  workspaceCtx.updateWorkspace({ ...workspace });
};

export default switchWorkspace;
