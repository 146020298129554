import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconAutomationSuccess: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_15405_275232)">
        <path d="M8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.85786 12.1421 0.5 8 0.5C3.85786 0.5 0.5 3.85786 0.5 8C0.5 12.1421 3.85786 15.5 8 15.5Z" stroke="#00A987" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.5 8.5L7 11L12 6" stroke="#00A987" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_15405_275232">
          <rect width="16" height="16" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  </IconWrapper>
);
