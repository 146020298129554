import { Icon, Tooltip } from 'antd';
import React, { FC } from 'react';
import { Trans } from 'react-i18next';

interface IClearButtonForm {
  isDisabled: boolean;
  clearNewProxyForm: () => void;
}

const ClearButtonForm: FC<IClearButtonForm> = (props: IClearButtonForm) => {
  const { isDisabled, clearNewProxyForm } = props;

  const handleClickClear: React.MouseEventHandler<HTMLDivElement> = () => {
    if (isDisabled) {
      return;
    }

    clearNewProxyForm();
  };

  const renderTooltip = (): JSX.Element|null => {
    if (isDisabled) {
      return null;
    }

    return (
      <span style={{ fontSize: 12, color: 'var(--000000-antd-tooltip-opacity)' }}>
        <Trans i18nKey='base.cleanForm' />
      </span>
    );
  };

  return (
    <Tooltip title={renderTooltip()}>
      <div onClick={handleClickClear} style={{ paddingTop: 6, marginLeft: 2 }}>
        <Icon
          type='delete'
          style={{
            fontSize: 18,
            opacity: 0.5,
            marginLeft: 8,
            cursor: isDisabled ? 'default' : 'pointer',
          }}
        />
      </div>
    </Tooltip>
  );
};

export default ClearButtonForm;
