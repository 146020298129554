import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

const RECT_BORDER_THICKNESS = 0.5;
const RECT_WIDTH_TO_HEIGHT_PROPORTION = 7 / 5;

const getRectWidth = (height: number): number => RECT_WIDTH_TO_HEIGHT_PROPORTION * height;

type CrossingLineCoordinates = {
  startX: number;
  startY: number;
  endX: number;
  endY: number;
}

const getCrossingLineCoordinates = (rectWidth: number, rectHeight: number): CrossingLineCoordinates => ({
  startX: 0.5,
  startY: 0.5,
  endX: rectWidth - 0.5,
  endY: rectHeight - 0.5,
});

const IconCrossedProxyFlag: React.FC<IIconWrapper> = (props) => {
  const { size: rectHeight = 10 } = props;

  const rectWidth = getRectWidth(rectHeight);
  const crossingLineCoordinates = getCrossingLineCoordinates(rectWidth, rectHeight);

  return (
    <IconWrapper {...props}>
      <svg
        width={rectWidth}
        height={rectHeight}
        viewBox={`0 0 ${rectWidth} ${rectHeight}`}
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect
          x={RECT_BORDER_THICKNESS}
          y={RECT_BORDER_THICKNESS}
          width={rectWidth - RECT_BORDER_THICKNESS * 2}
          height={rectHeight - RECT_BORDER_THICKNESS * 2}
        />
        <path
          d={`M${crossingLineCoordinates.startX} ${crossingLineCoordinates.startY} L${crossingLineCoordinates.endX} ${crossingLineCoordinates.endY}`}
        />
      </svg>
    </IconWrapper>
  );
};

export default IconCrossedProxyFlag;
