import { css } from '@emotion/core';
import { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled/macro';

export const ContainerButtons = styled('div')<{ newStyle?: boolean }>`
  display: flex;
  background-color: inherit;

  ${(props): SerializedStyles => props.newStyle ? css`
    align-items: center;
    justify-content: end;
    gap: 8px;
    margin-right: 14px;
  ` : css`
    justify-content: flex-end;
  `};
`;
