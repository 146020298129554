import React, { FC } from 'react';

import ModernSwitch from '../../../../ui/modern-switch';
import { IChangeSetting } from '../../interfaces';
import { ContainerParams } from '../../styles';

interface ILocalStorageAndIndexedDB extends IChangeSetting{
  indexedDb: boolean;
  local: boolean;
}

export const LocalStorageAndIndexedDB: FC<ILocalStorageAndIndexedDB> = ({ changeSetting, indexedDb, local }) => (
  <ContainerParams>
    <ModernSwitch
      checked={(local && indexedDb)}
      onChange={(checked): void => changeSetting({ storage: { local: checked, indexedDb: checked } })}
    />
  </ContainerParams>
);
