import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import ProfileMenuItems from './menu-items/profile-menu-items';
import { Container } from './styles';
import { PROXY_PAGE } from '../../../../common/constants/routes';
import { IconPlus } from '../icons';
import { IconArrowDown } from '../icons/icon-arrow-down';
import { IconWrapperWithDescription } from '../icons/wrapper';

export interface IButtonCreateInHeader {
  translationKey: string;
  handleClick: React.MouseEventHandler<HTMLDivElement>;
  handleMouseEnter: React.MouseEventHandler<HTMLDivElement>;
  handleMouseLeave: React.MouseEventHandler<HTMLDivElement>;
}

const ButtonCreateInHeader: React.FC<IButtonCreateInHeader> = (props) => {
  const { translationKey, handleClick, handleMouseEnter, handleMouseLeave } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const location = useLocation();
  const isProxyPage = location.pathname === PROXY_PAGE;

  const closeMenu = (): void => setAnchorEl(null);

  // TODO: convert to a prop like `handleClickContainer`
  const handleClickContainer: React.MouseEventHandler<HTMLDivElement> = (event) => {
    if (!isProxyPage) {
      return setAnchorEl(event.currentTarget);
    }

    event.preventDefault();
    event.stopPropagation();

    return handleClick(event);
  };

  return (
    <>
      <Container
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        isMenuShown={!!anchorEl}
        onClick={handleClickContainer}
        paddingRight={isProxyPage ? 8 : 0}
      >
        <IconWrapperWithDescription
          colorIconType='activeGrayWithBackground'
          onClick={handleClick}
          iconColor='var(--767676-header-right-part)'
          iconHoveredColor='var(--2B2B31-header-right-part)'
          hoveredHighlight={false}
          textColor='var(--767676-header-right-part)'
          textHoveredColor='var(--2B2B31-header-right-part)'
          iconType='stroke'
        >
          <IconPlus />
          <Trans i18nKey={translationKey} />
        </IconWrapperWithDescription>
        {isProxyPage ? null : (
          <IconArrowDown
            iconColor={anchorEl ? 'var(--2B2B31-header-right-part)' : 'var(--767676-header-right-part)'}
            iconHoveredColor='var(--2B2B31-header-right-part)'
          />
        )}
      </Container>
      {isProxyPage ? null : <ProfileMenuItems handleCloseMenu={closeMenu} anchorEl={anchorEl} />}
    </>
  );
};

export default ButtonCreateInHeader;
