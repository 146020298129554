import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconEdit: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_22721_42942)'>
        <path d='M0.5 0.5H5.5L11.061 6.061C11.647 6.647 11.647 7.597 11.061 8.182L8.182 11.061C7.596 11.647 6.646 11.647 6.061 11.061L0.5 5.5V0.5Z' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_22721_42942'>
          <rect width='12' height='12' fill='white' />
        </clipPath>
      </defs>
    </svg>
  </IconWrapper>
);

