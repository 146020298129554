import React, { FC } from 'react';

import { IFullProfile } from '../../../../interfaces';
import ModernSwitch from '../../../../ui/modern-switch';
import { ContainerParams } from '../../styles';

interface IActiveSessionLock {
  lockEnabled: boolean;
  changeSetting: (profileData: Partial<IFullProfile>) => void;
}

export const ActiveSessionLock: FC<IActiveSessionLock> = ({ changeSetting, lockEnabled }) => (
  <ContainerParams>
    <ModernSwitch
      onChange={(checked): void => changeSetting({ lockEnabled: checked })}
      checked={lockEnabled}
    />
  </ContainerParams>
);
