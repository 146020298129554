export const initCanvas = (size: number, index: number): HTMLCanvasElement => {
  const canvas = document.createElement('canvas');
  [canvas.width, canvas.height] = [size, size];

  canvas.style.letterSpacing = '0.02px';
  canvas.style.lineHeight = '44px';
  canvas.style.fontFamily = 'Gilroy';
  // Uncomment when developing browser icons
  // if (index === 0) {
  //   document.body.appendChild(canvas);
  // }

  return canvas;
};
