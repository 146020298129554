import styled from '@emotion/styled';

export const GologinInput = styled('input')`
  height: 32px;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 4px 11px;
  font-size: 14px;
  line-height: 1.5;
  background-image: none;
  border-radius: 4px;
  transition: all 0.3s;
  background-color: var(--FFFFFF-antd);
  border: 1px solid var(--D9D9D9-antd);
  color: var(--000000A6-antd);
  margin-bottom: 7px;

  :hover {
    border-color: var(--1FC47D-antd);
  }

  :focus {
    border-color: var(--1FC47D-antd);
    box-shadow: 0 0 0 2px var(--00B86D33-antd);
  }

  ::placeholder {
    color: var(--BFBFBF-antd);
  }
`;
