import { getNotesFacebookAccount } from './get-notes-facebook-account';
import { parseFBAccounts } from './parse-facebook-accounts';
import { IDataToCreateProfile } from '../api';

export const prepareFBProfiles = (
  file: string,
): IDataToCreateProfile[] => {
  const parsedFBAccount = parseFBAccounts(file);

  return parsedFBAccount.map((item) => {
    const { cookies, ...facebookAccountData } = item;
    const notes = getNotesFacebookAccount(facebookAccountData);

    return {
      facebookAccount: {
        notes,
        cookies,
        parsedData: facebookAccountData,
      },
    };
  });
};
