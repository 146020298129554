import React from 'react';
import { useTranslation } from 'react-i18next';
import TooltipCustom from '../../../../ui/tooltip-custom';
import { StatusFlowInfoContainer, TooltipContainer } from './styles';
import WorkflowStatusInfo from './title';
import { Button } from 'antd';
import { stopProfileOrbita } from '../../../quickProfiles/actions';
import { NEW_FEATURES } from '../../../../state/feature-toggle/new-features';
import { ButtonStop } from '../../../quickProfiles/run-button/styles';
import { sendActionAnalytics } from '../../../common/api';
import { AutomationStatusData } from '../../interfaces/automation-status.interfaces';
import { handleRunCookieBot } from '../../cookiebot-parameters-modal/utils';

type AutomationStatusTooltipProps = {
  anchorElTooltip: HTMLElement | null;
  statusData: AutomationStatusData;
  onMouseEnter: React.MouseEventHandler<HTMLElement>;
  onMouseLeave: React.MouseEventHandler<HTMLElement>;
};

const AutomationStatusTooltip: React.FC<AutomationStatusTooltipProps> = (props: AutomationStatusTooltipProps) => {
  const { anchorElTooltip, statusData, onMouseEnter, onMouseLeave } = props;
  
  const {
    workflowId,
    status,
    total = 0,
    successLinksCount = 0,
    erroredLinksCount = 0,
    name = '',
    icon = '',
    profileId,
  } = statusData;

  if (status === 'none') {
    return null;
  }

  const { t: translation } = useTranslation();

  const handleRestartAutomation = async (profileId: string) => {
    handleRunCookieBot({ workflowId, profileId });
  }

  if (!['starting', 'processing', 'completed', 'error'].includes(status)) {
    return null;
  }

  return (
    <TooltipCustom
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      placement='top'
      isTooltipHoverable={true}
      style={{ marginBottom: 8, maxWidth: 236 }}
      value={
      <TooltipContainer>
        {status === 'starting' ? 
          <StatusFlowInfoContainer>
            <WorkflowStatusInfo name={name} icon={icon} />
            {translation('automation.status.starting')}
            <ButtonStop
              newStyle={!!NEW_FEATURES.header}
              style={{ marginTop: 8 }}
              onClick={(): void => {
                stopProfileOrbita(profileId);
                sendActionAnalytics('clicked stop profile');
              }}
            >
              {translation('base.stop')}
            </ButtonStop>
          </StatusFlowInfoContainer> : 
          null
        }
        {status === 'processing' ? 
          <StatusFlowInfoContainer>
            <WorkflowStatusInfo name={name} icon={icon} />
            {translation('automation.status.processed.count', { successCount: successLinksCount, totalCount: total})}
            <ButtonStop
              newStyle={!!NEW_FEATURES.header}
              style={{ marginTop: 8 }}
              onClick={(): void => {
                stopProfileOrbita(profileId);
                sendActionAnalytics('clicked stop profile');
              }}
            >
              {translation('base.stop')}
            </ButtonStop>
          </StatusFlowInfoContainer> : 
          null
        }
        {status === 'completed' ? 
          <StatusFlowInfoContainer>
            <WorkflowStatusInfo name={name} icon={icon} />
            <div>
              {translation('automation.status.completed')}
            </div>
            {translation('automation.status.complete.count', { successCount: successLinksCount, totalCount: total})}
          </StatusFlowInfoContainer> : 
          null
        }
        {status === 'error' ? 
          <StatusFlowInfoContainer>
            <WorkflowStatusInfo name={name} icon={icon} />
            <div>
              {translation('automation.status.error')}
            </div>
            <Button
              style={{ marginTop: 8, textDecoration: 'underline' }}
              size='small'
              type='danger'
              onClick={() => handleRestartAutomation(profileId)}
            >
              {translation('base.restart')}
            </Button>
          </StatusFlowInfoContainer> : 
          null
        }
      </TooltipContainer>
      }
      anchorEl={anchorElTooltip}
    />
  );
};

export default AutomationStatusTooltip;
