import React, { FC, useContext } from 'react';
import { Trans } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import ColumnFilterResetButton, { ColumnFilterResetButtonField } from './column-filter-reset-button';
import { SorterCol } from './sorter-col';
import { NameColText } from './styles';
import { NEW_FEATURES } from '../../../../state/feature-toggle/new-features';
import { PROFILE_LIST_PAGE } from '../../../../../common/constants/routes';
import { ITemplatesCtx, tagsContext } from '../../../../state';
import { useProfilesTableProxyIdFilter } from '../../../../state/profiles-proxy-filter.atom';
import { openProxySettingsModal } from '../../../../state/proxy/proxy-settings-modal.atom';
import { useSelectedProxyFolder } from '../../../../state/proxy/proxy-table/proxy-folders.atom';
import { E_PROXY_FOLDERS } from '../../../proxy/proxy-page/constants/proxy-folders';
import { ProxyTableColumn } from '../../../proxy/proxy-page/types';
import { ProfilesTableColumn } from '../../../quickProfiles/components/interfaces/app-settings.interface';
import ButtonQuickCreateProfile from '../../../quickProfiles/quick-create-profile';
import { ContainerNameCol } from '../../../quickProfiles/table/styles';

interface IColumnHeader {
  column: ProfilesTableColumn | ProxyTableColumn;
  title: string;
  enableDrag?: boolean;
  hasSort?: boolean;
  sorterField?: string;
  quickCreateProfile?: (templateCtx: ITemplatesCtx) => Promise<void>;
}

const ColumnHeader: FC<IColumnHeader> = (props) => {
  const {
    column,
    title = '',
    enableDrag = true,
    hasSort = false,
    sorterField,
    quickCreateProfile = Promise.resolve,
  } = props;

  const { selectedTag } = useContext(tagsContext);
  const profilesTableProxyIdFilter = useProfilesTableProxyIdFilter();

  const selectedFolder = useSelectedProxyFolder();
  const isArchiveProxiesFolderSelected = selectedFolder === E_PROXY_FOLDERS.archive;

  const location = useLocation();
  const isProfilesPage = location.pathname === PROFILE_LIST_PAGE;
  const isProfilesPageProxyColumn = isProfilesPage && column === 'proxy';

  const quickCreateTooltipKey = isProfilesPage ? 'tooltip.profilesTable.addProfile' : 'proxies.addProxy';
  const quickCreateTooltip = <Trans i18nKey={quickCreateTooltipKey} />;

  const containerClassName = enableDrag ? 'dragHandler' : '';
  let columnFilterFieldName: ColumnFilterResetButtonField|null = null;
  switch (true) {
    case column === 'tags' && selectedTag?.field === 'tags':
      columnFilterFieldName = 'tags';
      break;
    case column === 'customStatus' && selectedTag?.field === 'custom-status':
      columnFilterFieldName = 'custom-status';
      break;
    case isProfilesPageProxyColumn && !!profilesTableProxyIdFilter:
      columnFilterFieldName = 'proxy-id';
      break;
    default:
      break;
  }

  const renderQuickCreateButton = (): JSX.Element|null => {
    const handleClick = async (templateCtx: ITemplatesCtx): Promise<void> => {
      if (isProfilesPage) {
        return quickCreateProfile(templateCtx);
      }

      openProxySettingsModal({ modalView: 'proxy-add', currentProxy: null });
    };

    if (isArchiveProxiesFolderSelected) {
      return null;
    }

    return <ButtonQuickCreateProfile createProfile={handleClick} tooltipContent={quickCreateTooltip} />;
  };

  return (
    <ContainerNameCol
      className={containerClassName}
      isProxy={isProfilesPageProxyColumn}
      newStyle={NEW_FEATURES.header}
    >
      <NameColText newStyle={!!NEW_FEATURES.header}>
        {title}
      </NameColText>
      {columnFilterFieldName ? <ColumnFilterResetButton field={columnFilterFieldName} /> : null}
      {hasSort && NEW_FEATURES.header ? <SorterCol sorterField={sorterField} /> : null}
      {column === 'name' ? renderQuickCreateButton() : null}
    </ContainerNameCol>
  );
};

export default ColumnHeader;
