import styled from '@emotion/styled-base';

export const Wrapper = styled('main')`
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: var(--00A987-auth-pages-o-auth-redirect-to-desktop-page);

  p,
  a {
    font-family: Roboto;
    font-size: 16px;
    color: var(--FFFFFF-auth-pages-o-auth-redirect-to-desktop-page);
  }
`;

export const LogoLink = styled('a')`
  width: fit-content;
  
  svg {
    width: 57px;
    height: 29px;
    fill: var(--FFFFFF-auth-pages-o-auth-redirect-to-desktop-page);
  }
`;

export const RedirectText = styled('p')`
  margin: 16px 0 0 0;
`;

export const RedirectLink = styled('a')`
  text-decoration: underline;

  &:hover {
    color: var(--222222-auth-pages-o-auth-redirect-to-desktop-page);
  }
`;
