import React from 'react';
import { Trans } from 'react-i18next';

import {
  ProxySavedSelectorPlaceholder,
  ProxySavedSelectorProxy,
  ProxySavedSelectorUnselectButton,
  SelectedProxyTitleWrapper,
  SelectedProxyWrapper,
} from './styles';
import { NEW_FEATURES } from '../../../../state/feature-toggle/new-features';
import {
  getProfileSettingsProxyForm,
  onProfileSettingsProxySelect,
  useProfileSettingsProxyId,
} from '../../../../state/proxy/proxy-in-profile-settings.atom';
import { getProxyByArtificialProxyId, useProxyListProxyById } from '../../../../state/proxy/proxy-list.atom';
import { ProxySelectorLocation } from '../../../../state/proxy/proxy-manager-modal-status.atom';
import { IconClose } from '../../../../ui/gologin-header/icons';
import CheckProxyBtn from '../../check-proxy-button';
import { EMPTY_PROXY } from '../../constants';
import ProxyFlag, { BIG_FLAG_HEIGHT, SMALL_FLAG_HEIGHT } from '../../proxy-flag';
import { makeProxyTitle } from '../../proxy-helpers';
import { iconProps } from '../constants';
import { SavedProxyStatusWrapper } from '../styles';

type SelectedProxyProps = {
  editingProfileId: string|null;
  proxyManagerTargetRef: React.RefObject<HTMLButtonElement>;
}

const SelectedProxy: React.FC<SelectedProxyProps> = (props) => {
  const { editingProfileId, proxyManagerTargetRef } = props;

  const profileSettingsProxyId = useProfileSettingsProxyId();
  let proxyToRender = useProxyListProxyById(profileSettingsProxyId);
  const profileProxy = getProfileSettingsProxyForm();

  if (!proxyToRender) {
    switch (true) {
      case (!!profileSettingsProxyId):
        proxyToRender = getProxyByArtificialProxyId(profileSettingsProxyId);
      // falls through
      case (!!profileProxy):
        proxyToRender = { ...profileProxy };
        break;
      default:
        proxyToRender = { ...EMPTY_PROXY };
        break;
    }
  }

  if (!profileSettingsProxyId) {
    return (
      <ProxySavedSelectorPlaceholder isProxyManagerWithGroups={!!NEW_FEATURES.proxyGroups}>
        <Trans i18nKey='profileSettingsPage.proxyTab.savedProxyPlaceholder' />
      </ProxySavedSelectorPlaceholder>
    );
  }

  const title = makeProxyTitle(proxyToRender);

  const handleClickUnselect: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onProfileSettingsProxySelect(null);
  };

  return (
    <ProxySavedSelectorProxy>
      <SelectedProxyWrapper>
        <SavedProxyStatusWrapper isProxyManagerWithGroups={!!NEW_FEATURES.proxyGroups}>
          <CheckProxyBtn
            proxy={proxyToRender}
            profileId={editingProfileId || ''}
            proxyTooltipView={ProxySelectorLocation.profileSettingsPage}
            selectorContainerElement={proxyManagerTargetRef}
          />
        </SavedProxyStatusWrapper>
        <ProxyFlag
          countryCode={proxyToRender.country || ''}
          height={NEW_FEATURES.proxyGroups ? BIG_FLAG_HEIGHT : SMALL_FLAG_HEIGHT}
        />
        <SelectedProxyTitleWrapper>
          {title}
        </SelectedProxyTitleWrapper>
      </SelectedProxyWrapper>
      <ProxySavedSelectorUnselectButton onClick={handleClickUnselect}>
        <IconClose {...iconProps} />
      </ProxySavedSelectorUnselectButton>
    </ProxySavedSelectorProxy>
  );
};

export default SelectedProxy;
