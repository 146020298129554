import styled from '@emotion/styled';

import IconArrowDown from '../../../../../ui/icons/IconArrowDown';

export const ReadMoreButton = styled.button`
  color: var(--767676-proxy-manager-info-error);
  outline: none;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  letter-spacing: 0.25px;
  line-height: 20px;
`;

export const IconArrow = styled(IconArrowDown)`
  margin-left: 6px;
  stroke: var(--767676-proxy-manager-info-error);
`;

export const ErrorReasonText = styled.div`
  padding: 3px 0 4px;
  display: flex;
  line-height: 20px;
  letter-spacing: 0.25px;
  font-size: 14px;
`;

export const ErrorDetails = styled.div`
  margin-top: 10px;
  font-size: 14px;
`;

export const ErrorDetailsList = styled.ul`
  list-style: outside;
  padding: 0;
  line-height: 20px;
  letter-spacing: 0.25px;
  margin: 0 0 3px;
`;

export const ErrorDetailsListItem = styled.li`
  margin-left: 20px;
`;

export const ReadMoreBlockWrapper = styled.div`
  position: absolute;
  background-color: var(--FFFFFF-proxy-manager-info-error);
  border-radius: 0px 0px 4px 4px;
  box-shadow: var(--00000026-proxy-manager-info-error) 0px 2px 8px;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 0px 16px 12px;
  margin-top: -13px;
  line-height: 20px;
  letter-spacing: 0.25px;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: -5px;
    height: 5px;
    width: 100%;
    background: var(--FFFFFF-proxy-manager-info-error);
  }
`;
