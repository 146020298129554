import styled from '@emotion/styled/macro';

import { PROXY_ITEM_ELEMENT_SEMI_TRANSPARENCY } from '../constants/styles';

export const EmptyProxyFlagWrapper = styled.div`
  display: inline-block;
  text-align: center;

  & > svg {
    vertical-align: top;
  }
`;

export const IconBlurredProxyFlagWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  margin-top: -1px;
`;

export const ExistingProxyFlagWrapper = styled.div<{ isSemiTransparent: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: ${(props): number => props.isSemiTransparent ? PROXY_ITEM_ELEMENT_SEMI_TRANSPARENCY : 1};
`;
