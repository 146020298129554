import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconPaypalLogo: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg
      width={86}
      height={22}
      viewBox="0 0 86 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.196 5.218h-4.741c-.325 0-.6.235-.651.556l-1.918 12.159a.395.395 0 0 0 .39.456h2.265c.324 0 .6-.236.65-.557l.518-3.28a.66.66 0 0 1 .65-.556h1.501c3.124 0 4.926-1.512 5.397-4.507.212-1.31.01-2.34-.604-3.06-.674-.793-1.87-1.211-3.457-1.211m.547 4.44c-.259 1.702-1.559 1.702-2.816 1.702h-.715l.502-3.178a.395.395 0 0 1 .39-.333h.328c.856 0 1.664 0 2.081.488.25.291.325.724.23 1.321m13.627-.054H44.1a.396.396 0 0 0-.39.333l-.101.635-.16-.23c-.49-.713-1.587-.952-2.68-.952-2.51 0-4.653 1.9-5.07 4.566-.218 1.33.091 2.602.845 3.489.692.815 1.682 1.155 2.86 1.155 2.022 0 3.143-1.3 3.143-1.3l-.101.63a.395.395 0 0 0 .39.458h2.045c.325 0 .6-.236.651-.557l1.227-7.771a.394.394 0 0 0-.389-.456m-3.165 4.419c-.219 1.297-1.248 2.168-2.562 2.168-.659 0-1.186-.211-1.524-.612-.336-.398-.463-.965-.357-1.596.205-1.286 1.252-2.185 2.545-2.185.645 0 1.169.214 1.514.618.346.409.483.979.384 1.607m15.258-4.419h-2.281a.66.66 0 0 0-.546.289l-3.147 4.635-1.334-4.454a.66.66 0 0 0-.632-.47H48.28a.395.395 0 0 0-.375.522l2.514 7.376-2.363 3.336a.395.395 0 0 0 .322.624h2.28a.66.66 0 0 0 .54-.283l7.59-10.955a.395.395 0 0 0-.325-.62"
        fill="#253B80"
      />
      <path
        d="M66.018 5.218h-4.743c-.323 0-.6.235-.65.556l-1.918 12.159a.395.395 0 0 0 .39.456h2.433a.46.46 0 0 0 .455-.39l.544-3.446a.66.66 0 0 1 .65-.557h1.501c3.124 0 4.926-1.512 5.398-4.507.213-1.31.008-2.34-.605-3.06-.674-.793-1.868-1.211-3.455-1.211m.547 4.44c-.259 1.702-1.559 1.702-2.817 1.702h-.714l.502-3.178a.394.394 0 0 1 .39-.333h.328c.855 0 1.664 0 2.081.488.25.291.325.724.23 1.321m13.626-.054h-2.27a.39.39 0 0 0-.389.333l-.1.635-.16-.23c-.492-.713-1.587-.952-2.681-.952-2.51 0-4.652 1.9-5.069 4.566-.216 1.33.09 2.602.845 3.489.694.815 1.682 1.155 2.86 1.155 2.022 0 3.143-1.3 3.143-1.3l-.101.63a.395.395 0 0 0 .39.458h2.045c.324 0 .6-.236.65-.557l1.229-7.771a.396.396 0 0 0-.392-.456m-3.165 4.419c-.218 1.297-1.249 2.168-2.562 2.168-.658 0-1.186-.211-1.525-.612-.335-.398-.461-.965-.356-1.596.206-1.286 1.252-2.185 2.545-2.185.644 0 1.168.214 1.514.618.347.409.484.979.384 1.607m5.842-8.472-1.946 12.382a.395.395 0 0 0 .39.456h1.956a.66.66 0 0 0 .651-.557l1.92-12.158a.395.395 0 0 0-.39-.457h-2.191a.396.396 0 0 0-.39.334"
        fill="#179BD7"
      />
      <path
        d="m5.194 20.752.363-2.303-.808-.02H.892l2.68-16.995a.22.22 0 0 1 .218-.186h6.504c2.159 0 3.649.45 4.427 1.336.365.416.597.851.71 1.33.117.501.12 1.101.004 1.833l-.008.053v.469l.364.206q.46.244.739.563c.312.356.514.808.599 1.344.088.551.059 1.207-.085 1.95-.167.854-.436 1.598-.8 2.207a4.5 4.5 0 0 1-1.264 1.386 5.1 5.1 0 0 1-1.705.77c-.628.163-1.344.245-2.13.245h-.506c-.362 0-.713.13-.99.364a1.54 1.54 0 0 0-.515.921l-.038.207-.64 4.06-.03.149c-.008.047-.02.07-.04.087a.1.1 0 0 1-.067.024z"
        fill="#253B80"
      />
      <path
        d="M16.137 5.854q-.03.186-.067.381c-.857 4.404-3.792 5.925-7.539 5.925H6.623a.93.93 0 0 0-.916.785L4.73 19.14l-.277 1.757a.488.488 0 0 0 .482.564H8.32a.815.815 0 0 0 .805-.686l.033-.172.637-4.044.041-.222a.814.814 0 0 1 .804-.687h.507c3.278 0 5.845-1.332 6.595-5.184.314-1.61.151-2.953-.678-3.898a3.2 3.2 0 0 0-.926-.714"
        fill="#179BD7"
      />
      <path
        d="M15.24 5.496a7 7 0 0 0-.835-.185 10.6 10.6 0 0 0-1.682-.123H7.626a.81.81 0 0 0-.804.688l-1.084 6.868-.031.2a.93.93 0 0 1 .916-.784H8.53c3.747 0 6.682-1.522 7.54-5.925q.037-.195.066-.38a4.6 4.6 0 0 0-.898-.359"
        fill="#222D65"
      />
      <path
        d="M6.823 5.876a.81.81 0 0 1 .803-.687h5.097c.604 0 1.168.04 1.682.123q.221.034.43.081a7 7 0 0 1 .597.164q.38.126.705.297c.256-1.627-.002-2.735-.882-3.738-.97-1.105-2.72-1.578-4.96-1.578H3.79a.93.93 0 0 0-.918.786l-2.71 17.17a.56.56 0 0 0 .552.646H4.73l1.008-6.396z"
        fill="#253B80"
      />
    </svg>
  </IconWrapper>
);
