import { atom, getDefaultStore, useAtomValue } from 'jotai/index';

import { IFullProfile } from '../interfaces';
import { DEFAULT_PROFILE_DATA } from '../utils/default-profile-data';

const fullProfileAtom = atom<IFullProfile>({ ...DEFAULT_PROFILE_DATA });

export const useProfileSettingsFullProfile = (): IFullProfile => useAtomValue(fullProfileAtom);
export const getProfileSettingsFullProfile = (): IFullProfile => getDefaultStore().get(fullProfileAtom);
export const setProfileSettingsFullProfile = (newFullProfile: IFullProfile): void => getDefaultStore().set(fullProfileAtom, newFullProfile);
