import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconRunProfile: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_19010_21940)'>
        <path d='M3.5 2.94313C3.5 2.12977 4.41935 1.65664 5.08122 2.12938L13.381 8.05747C13.9335 8.45212 13.9405 9.27093 13.3948 9.67494L5.095 15.8192C4.435 16.3078 3.5 15.8367 3.5 15.0155V2.94313Z' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_19010_21940'>
          <rect width='16' height='16' fill='white' transform='translate(0 0.5)' />
        </clipPath>
      </defs>
    </svg>
  </IconWrapper>
);
