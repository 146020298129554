import { drawTextLine } from './draw-text-line';
import { drawTextShadow } from './draw-text-shadow';
import { prepareToDrawText } from './prepare-to-draw-text';
import { getIconsConstants } from '../constants';
import { IIconTextParams } from '../interfaces';
import { getTextParams, getTextYValuesTwoLines, getTextYValueSingleLine } from '../utils/get-values';

const getDoesContainNumbersOnly = (text: string): boolean => /^[0-9]+$/.test(text);

export const drawText = ({ osFormatted, context, profileName, size, scaleFactor }: IIconTextParams): void => {
  const textParams = getTextParams(osFormatted, +size, scaleFactor);
  const { lines, linesMetrics } = prepareToDrawText({
    ...textParams,
    context,
    text: profileName,
    textAlign: 'center',
    textBaseline: 'top',
  });

  drawTextShadow(context, +size);

  const [lineFirst, lineSecond] = lines.map(line => line.trim());
  const [lineFirstMetrics, lineSecondMetrics] = linesMetrics;
  const lineParamsBase = { os: osFormatted, scaleFactor, canvasSize: +size };
  const { textColor } = getIconsConstants(osFormatted);
  const textParamsBase = { ...textParams, context, fillStyle: textColor };

  if (!lineSecond) {
    const isCenteringStandard = !getDoesContainNumbersOnly(lineFirst);
    const yValueSingleLine = getTextYValueSingleLine({
      ...lineParamsBase,
      lineMetrics: lineFirstMetrics,
      isCenteringStandard,
    });

    drawTextLine({ ...textParamsBase, yValue: yValueSingleLine, text: lineFirst });

    return;
  }

  const textToDraw = lineFirst + lineSecond;
  const isCenteringStandard = !getDoesContainNumbersOnly(textToDraw);
  const { yValueFirstLine, yValueSecondLine } = getTextYValuesTwoLines({
    ...lineParamsBase,
    lineFirstMetrics,
    lineSecondMetrics,
    isCenteringStandard,
  });

  drawTextLine({ ...textParamsBase, yValue: yValueFirstLine, text: lineFirst });
  drawTextLine({ ...textParamsBase, yValue: yValueSecondLine, text: lineSecond });
};
