import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { IDataToCreateProfile } from '../api';
import { ICheckProfilesLimit, IResultCheckProfilesLimit } from '../interfaces';

export const checkProfilesLimit = (dataToCheckLimit: ICheckProfilesLimit): IResultCheckProfilesLimit => {
  const { user, workspace, parsedFiles } = dataToCheckLimit;
  const {  plan: userPlan } = user;

  let maxProfiles = workspace.planProfilesMax;
  let currentProfilesCount = workspace.profilesCount;
  let allowedProfilesCount = maxProfiles - currentProfilesCount;

  if (!NEW_FEATURES.workspaces) {
    maxProfiles = userPlan.maxProfiles || 0;
    currentProfilesCount = user.profiles || 0;
    allowedProfilesCount = maxProfiles - currentProfilesCount;
  }

  const profileCountSum = parsedFiles.length + currentProfilesCount;
  let filesToImport: IDataToCreateProfile[] = parsedFiles;
  let resultCheckProfilesLimit: IResultCheckProfilesLimit = { filesToImport };

  if (profileCountSum >= maxProfiles) {
    filesToImport = parsedFiles.slice(0, allowedProfilesCount);
    if (profileCountSum > maxProfiles) {
      resultCheckProfilesLimit = { filesToImport, error: 'maxProfiles' };
    }
  }

  const filesToImportCount = filesToImport.length;

  if (!filesToImportCount) {
    return { error: 'maxProfiles' };
  }

  if (filesToImportCount > 30) { // временный лимит на кол-во профилей
    return { error: 'filesLimit' };
  }

  return resultCheckProfilesLimit;
};
