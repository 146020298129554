import styled from '@emotion/styled';

import { ModalText } from '../styles';

export const PriceWrapper = styled.div<{ isStripePayment: boolean }>`
  margin-top: ${({ isStripePayment }): string => isStripePayment ? '32px' : '0'};
  width: 100%;
`;

export const PriceTextWrapper = styled('div')`
  margin-top: 24px;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  column-gap: 12px;
  
  &:first-of-type {
    margin-top: 0;
  }
`;

export const PriceDivider = styled('div')`
  width: 100%;
  margin-bottom: 4px;
  flex-shrink: 2;
  border-bottom: 2px dotted var(--D2D2D5);
`;

export const PriceText = styled(ModalText)`
  text-align: left;
  white-space: nowrap;
  color: var(--36363D);
`;
