import { css } from '@emotion/core';
import { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled/macro';

import { IconWrapperWithDescription } from '../../../../ui/gologin-header/icons/wrapper';
import { textFont } from '../../../../ui/style-templates';
import { Item } from '../../styles';

export const SectionName = styled(IconWrapperWithDescription)`
  display: flex;
  padding: 20px 0 0 16px;
  width: 100%;
  color: var(--2B2B31-profile-new-settings-sections-group);
  align-items: center;
  cursor: auto;
  flex-direction: inherit;
  ${textFont};
`;

export const ArrowContainer = styled('div')<{ isOpen?: boolean }>`
  margin-left: 10px;

  ${(props): SerializedStyles => css`
      transform: ${props.isOpen ? 'rotate(90deg)' : 'rotate(0deg)'};
    `}
`;

export const CollapseContainer = styled.div<{ isOpen: boolean; animation?: string | null }>`
    overflow: hidden;
    max-height: ${(props): string => (props.isOpen ? 'inherit' : '0')};
    transition: ${(props): string => (props.animation ? props.animation : 'inherit')};

    ${(props): SerializedStyles => props.isOpen ? css`
        outline: 0.5px solid var(--E5E5E6-profile-new-settings);
        border-radius: 8px;
        background: var(--FFFFFF-profile-new-settings);
        margin-top: 12px;

        ${Item}:not(:last-child) {
            border-bottom: 0.5px solid var(--E5E5E6-profile-new-settings);
        }
    ` : css`
    border-bottom: 0.5px solid var(--E5E5E6-profile-new-settings);
    margin-top: 20px;
  `}
`;
