import { useGoogleLogin } from '@react-oauth/google';
import React, { useState } from 'react';

import { useQuery } from '../../../../../hooks';
import { history } from '../../../../../services';
import { oAuthConfig } from '../../../../../services/http/config';
import IconGoogleLogo from '../../../../../ui/icons/IconGoogleLogo';
import { IAuthTokens } from '../../../interfaces/auth-tokens.interface';
import { authWithProvider } from '../../api';
import { ICodeErrorResponse } from '../../interfaces/code-error-response.interface';
import { ICodeSuccessResponse } from '../../interfaces/code-success-response.interface';
import { redirectToDesktop } from '../../utils';
import Loader from '../loader';
import { Button } from '../styles';
import { getDeviceFingerprint, getGologinMetaHeader } from '../../../../../utils/user-os';
import { OAuthMetadata } from '../../interfaces/request-data.interface';

const { websiteBaseUrl, googleOAuthClientId } = oAuthConfig;

interface IGoogleButton {
  isElectron?: boolean;
  isIFrame?: boolean;
}

const GoogleButton: React.FC<IGoogleButton> = (props) => {
  const { isElectron, isIFrame = false } = props;

  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const isNavigatedFromDesktop = useQuery().get('desktop');

  const navigateOnSuccess = (accountAuthorised?: IAuthTokens): void => {
    if (!accountAuthorised) {
      return;
    }

    const { token: accessToken = '', isNewUser } = accountAuthorised;
    const pathToNavigateTo = isNewUser ? '/quiz' : '/profileList';
    history.replace(pathToNavigateTo);

    if (isNavigatedFromDesktop) {
      redirectToDesktop({ accessToken, isNewUser });
    }
  };

  const handleAuthSuccess = async ({ code }: ICodeSuccessResponse): Promise<void> => {
    const accountAuthorised = await authWithProvider({
      credential: code,
      provider: 'google',
      isFromDesktop: false,
    });

    setIsLoaderVisible(false);
    navigateOnSuccess(accountAuthorised);
  };

  const handleAuthError = (errorResponse: ICodeErrorResponse): void => {
    setIsLoaderVisible(false);
    console.log(errorResponse);
  };

  const handleNonOAuthError = (): void => {
    setIsLoaderVisible(false);
  };

  // Google One Tap sub-feature (keep for a possible future use)

  // const handleOneTapAuthSuccess = async (credentialResponse: CredentialResponse): Promise<void> => {
  //   const accountAuthorised = await authWithGoogleFromWeb(credentialResponse);
  //   navigateOnSuccess(accountAuthorised);
  // };

  // const handleOneTapAuthError = (): void => {
  //   console.error('Google One Tap Error');
  // };

  // useGoogleOneTapLogin({
  //   onSuccess: handleOneTapAuthSuccess,
  //   onError: handleOneTapAuthError,
  // });

  const handleAuth = useGoogleLogin({
    onSuccess: handleAuthSuccess,
    onError: handleAuthError,
    onNonOAuthError: handleNonOAuthError,
    flow: 'auth-code',
    scope: 'email profile',
    redirect_uri: websiteBaseUrl,
  });

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = async () => {
    setIsLoaderVisible(true);

    if (!isElectron) {
      handleAuth();

      return;
    }

    const googleAuthApiBaseUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
    const redirectUriString = new URL(`${websiteBaseUrl}/redirect-to-desktop?provider=google`).toString();
    const deviceFingerprint = await getDeviceFingerprint();
    const gologinMetaHeader = await getGologinMetaHeader();
    const dataForGoogleAuthState: OAuthMetadata = {
      gologinMetaHeader,
      appId: deviceFingerprint,
    };

    const metadata = encodeURIComponent(JSON.stringify(dataForGoogleAuthState));

    const consentScreenUrl = new URL(googleAuthApiBaseUrl);
    consentScreenUrl.searchParams.append('client_id', googleOAuthClientId);
    consentScreenUrl.searchParams.append('redirect_uri', redirectUriString);
    consentScreenUrl.searchParams.append('scope', 'profile email');
    consentScreenUrl.searchParams.append('response_type', 'code');
    consentScreenUrl.searchParams.append('access_type', 'offline');
    consentScreenUrl.searchParams.append('prompt', 'select_account');

    // сервис гугл авторизации не воспринимает иные название поля для проброса метадаты, кроме 'state'
    consentScreenUrl.searchParams.append('state', metadata);

    const consentScreenUrlString = consentScreenUrl.toString();

    if (isIFrame) {
      const message = {
        type: 'open-oauth',
        message: {
          url: consentScreenUrlString,
        },
      };

      window.parent.postMessage(
        message,
        '*',
      );
    } else {
      window.require('electron').shell.openExternal(consentScreenUrlString);
    }

    setTimeout(() => setIsLoaderVisible(false), 1000);
  };

  return (
    <Button onClick={handleClick}>
      <Loader isVisible={isLoaderVisible} LogoComponent={IconGoogleLogo} />
      <span>
        Continue with Google
      </span>
    </Button>
  );
};

export default GoogleButton;
