import { Icon, message, Popconfirm } from 'antd';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { E_ANALYTICS_ACTIONS, E_ANALYTICS_ACTIONS_INFO } from '../../../../common/constants/analytics';
import { FREE_PLAN_ID } from '../../../../common/constants/constants';
import { userContext, workspaceContext } from '../../../state';
import { showOverlayMask } from '../../../state/overlay-mask.atom';
import { ActionButton } from '../../../ui/action-buttons/styles';
import { openWebsitePricing } from '../../../utils/open-site';
import { sendActionAnalytics } from '../../common/api';

interface IRestoreButton {
  title: string;
  onRestore: () => void;
  style?: React.CSSProperties;
  isDisabled?: boolean;
}

export const RestoreButton: React.FC<IRestoreButton> = (props) => {
  const { title, onRestore, style, isDisabled } = props;

  const [isPaymentPopupVisible, setIsPaymentPopupVisible] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const {
    id: workspaceId,
    permissions,
    planId: workspacePlanId,
    isUnpaid: workspaceIsUnpaid,
    paymentIsTrial: workspaceIsTrial,
  } = useContext(workspaceContext);

  const {
    isQuickSettingsEnabled,
  } = useContext(userContext);

  const { t: translation } = useTranslation();

  const isFreePlanWorkspace = workspaceIsUnpaid || workspacePlanId === FREE_PLAN_ID;

  const onClick = async (): Promise<void> => {
    if (isFreePlanWorkspace || workspaceIsTrial) {
      setIsPaymentPopupVisible(true);
      showOverlayMask(onClosePopconfirm);

      return;
    } else if (!permissions.restoreProfile) {
      message.error(translation('notifications.error.permissionWorkspace'));

      return;
    }

    setIsProcessing(true);
    await onRestore();
    setIsProcessing(false);
  };

  const redirectToSite = async (): Promise<void> => {
    onClosePopconfirm();
    sendActionAnalytics(E_ANALYTICS_ACTIONS.clickedBuyPlan, { actionInfo: E_ANALYTICS_ACTIONS_INFO.restoreProfiles });
    openWebsitePricing({
      workspaceId,
      isQuickSettingsEnabled,
      isShowPaymentMethods: true,
    });
  };

  const onClosePopconfirm = (): void => {
    setIsPaymentPopupVisible(false);
  };

  return (
    <Popconfirm
      placement='bottom'
      title={translation('personalArea.restoreProfiles.popupPaidPlan')}
      onConfirm={redirectToSite}
      onCancel={onClosePopconfirm}
      cancelText={translation('base.cancel')}
      okText={translation('personalArea.billing.changePlanSubmitBtnText')}
      visible={isPaymentPopupVisible}
    >
      <ActionButton disabled={isDisabled || isProcessing} onClick={onClick} style={{ margin: 0, ...style }}>
        {title}
        {isProcessing ? (
          <Icon type='loading' />
        ) : null}
      </ActionButton>
    </Popconfirm>
  );
};
