import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { OptionItem, SearchMenuPopup } from './styles';
import { IconCheck } from '../icons';

interface ISearchMeanu {
  anchorEl: HTMLElement|null;
  onCloseSearchPopup: () => void;
  onPinSearch: () => void;
  isSearchPinned: boolean;
}

export const SearchMenu: FC<ISearchMeanu> = (props): JSX.Element => {
  const { anchorEl, onCloseSearchPopup, onPinSearch, isSearchPinned } = props;

  const { t: translation } = useTranslation();

  return (
    <SearchMenuPopup
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onCloseSearchPopup}
      transitionDuration={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      style={{ zIndex: 900 }}
    >
      <OptionItem style={{ margin: 0 }} onClick={onPinSearch}>
        <span>
          {translation('header.searchProfile.keepAlwaysOpen')}
        </span>
        {isSearchPinned ? <IconCheck iconColor='var(--36363D)' padding={0} /> : null}
      </OptionItem>
    </SearchMenuPopup>
  );
};
