import { useEffect, useState } from 'react';

interface IDelayedMountResult {
  shouldMount: boolean;
}

const useDelayedMount = (defaultShouldMount: boolean, timeout = 400): IDelayedMountResult => {
  const [shouldMount, setShouldMount] = useState(!!defaultShouldMount);

  useEffect(() => {
    if (shouldMount) {
      return;
    }

    window.requestIdleCallback(
      () => setShouldMount(true),
      { timeout },
    );
  }, [shouldMount]);

  return { shouldMount };
};

export default useDelayedMount;
