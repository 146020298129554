import './crypto';
import { jsFonts } from './fonts-hash';

export const getCanvasHash = () => {
  const canvas = document.createElement('canvas');
  canvas.width = 280;
  canvas.height = 60;

  let canvasNumHash: number = 0;
  if (typeof canvas.getContext !== 'function') {
    return canvasNumHash;
  }

  try {
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = 'var(--66CC00)';
    ctx.fillRect(100, 5, 80, 50);
    ctx.fillStyle = 'var(--FF6600)';
    ctx.font = '16pt Arial';
    ctx.fillText('<@nv45. F1n63r,Pr1n71n6!', 10, 40);
    ctx.strokeStyle = 'var(--78BAB0)';
    ctx.arc(80, 10, 20, 0, Math.PI, !1);
    ctx.stroke();

    const dataUrl = canvas.toDataURL ();

    for (let i = 0; i < dataUrl.length; i++) {
      canvasNumHash = (canvasNumHash << 5) - canvasNumHash + dataUrl.charCodeAt(i); // eslint-disable-line no-bitwise
      canvasNumHash &= canvasNumHash; // eslint-disable-line no-bitwise
    }
  } catch (e) {
    canvasNumHash = 0;
  }

  if (!canvasNumHash) {
    return;
  }

  return canvasNumHash.toString();
}

export const getCanvasAndFontsHash = () => {
  return CryptoJS.SHA1(jsFonts() + getCanvasHash()).toString().slice(0, 16);
}
