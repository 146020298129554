import { Button } from 'antd';
import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { ITemplatesCtx, templatesContext } from '../../../state';
import EmptyTablePlaceholder from '../../common/custom-table/components/empty-table-placeholder';

interface INoProfilesPlaceholder {
  quickCreateProfile: (templateCtx: ITemplatesCtx) => Promise<void>;
}

const NoProfilesPlaceholder: FC<INoProfilesPlaceholder> = (props) => {
  const { quickCreateProfile } = props;

  const templateCtx = useContext(templatesContext);

  const { t: translation } = useTranslation();

  if (!NEW_FEATURES.header) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Link to='/newProfile'>
          <Button type='primary'>
            {translation('newProfile.saveProfileBtnText')}
          </Button>
        </Link>
      </div>
    );
  }

  return (
    <EmptyTablePlaceholder
      entityType='profile'
      handleCreateFirstEntity={(): Promise<void> => quickCreateProfile(templateCtx)}
    />
  );
};

export default NoProfilesPlaceholder;
