import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled/macro';

export const NotesContainer = styled('div')<{ newStyle: boolean; newDrawer: boolean; isInDrawer: boolean }>`
  position: relative;

  ${(props): SerializedStyles | null => props.newStyle ? css`
    align-items: center;
    display: flex;
    background: inherit;
    padding: 15px 0;

    .ql-editor {
      padding: ${props.newDrawer && props.isInDrawer ? '0 6px' : '0 8px'};
    }

    background: none;

    .ql-hide-rows.ql-editor > *:not(:first-child) {
      visibility: hidden;
    }

    & > * {
      width: 100%;
    }
  ` : css`
    .ql-container {
      min-height: 38px;
    }
  `}
`;

export const AddNotesContainer = styled('div')<{ newStyle: boolean; newDrawer?: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  opacity: 0;
  font-size: 14px;
  font-family: 'Roboto';
  font-style: normal;
  letter-spacing: 0.25px;
  font-weight: 400;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;

  ${(props): SerializedStyles | null => props.newStyle ? css`
    top: 0;
    min-height: 52px;
  ` : null}

  .ql-drawer-container & {
    left: 7px;
    opacity: 1;
  }

  ${(props): SerializedStyles => props.newDrawer ? css`
    color: var(--81818A-notes);
    :hover {
      color: var(--2B2B31-notes);

      & i {
        color: var(--2B2B31-notes);
      }
    }
  ` : css`
    color: var(--767676-notes);
    :hover {
      color: var(--00A987-notes);

      & i {
        color: var(--00A987-notes);
      }
    }
  `}

  & i {
    color: var(--CDCDCD-notes);
  }

  td:hover & {
    opacity: 1;
  }
`;
