export function renumerateOrders<T extends { order: number }>(arr: T[], STEP: number): T[] {
  const processBlock = (objs: T[], start: number, end: number, prevValue: number, nextValue: number): T[] => {
    const stepValue = (nextValue - prevValue) / (end - start + 2);

    return objs.slice(start, end + 1).map((obj, j) => ({
      ...obj,
      order: prevValue + stepValue * (j + 1)
    }));
  };

  const { result } = arr.reduce((acc, current, index, array) => {
    if (acc.skip > 0) {
      acc.skip--;

      return acc;
    }

    const start = index;
    let end = index;

    while (end < array.length - 1 && array[end].order === array[end + 1].order) {
      end++;
    }

    if (start === end) {
      acc.result.push(current);
    } else {
      const prevValue = start > 0 ? array[start - 1].order : array[start].order - STEP;
      const nextValue = end < array.length - 1 ? array[end + 1].order : array[end].order + STEP;
      const blockResult = processBlock(array, start, end, prevValue, nextValue);
      acc.result.push(...blockResult);
      acc.skip = end - start;
    }

    return acc;
  }, { result: [] as T[], skip: 0 });

  return result;
}
