import { useCallback, useContext } from 'react';

import { userContext } from '../../../state';
import getSelectedThemeColor from '../../../utils/get-selected-theme-color';

const NOTES_COLORS = [
  { lightNote: 'rgba(255, 213, 225, 0.7)', darkNote: 'rgb(163, 71, 71)' },
  { lightNote: 'rgba(255, 240, 175, 0.7)', darkNote: 'rgba(182, 182, 41, 0.7)' },
  { lightNote: 'rgb(191, 235, 222, 0.7)', darkNote: 'rgb(38, 127, 105)' },
  { lightNote: 'rgba(191, 235, 222, 0.7)', darkNote: 'rgb(38, 127, 105)' },
  { lightNote: 'rgba(201, 225, 255, 0.7)', darkNote: 'rgb(30, 75, 150)' },
  { lightNote: 'rgba(228, 216, 242, 0.7)', darkNote: 'rgb(184, 95, 191, 0.7)' },
];

interface IUseNotesColorReplace {
  replaceNotesColors: (notes: string) => string;
}

const useNotesColorReplace = (): IUseNotesColorReplace => {
  const { theme } = useContext(userContext);
  const isDarkTheme = getSelectedThemeColor(theme) === 'dark';

  const replaceNotesColors = useCallback((notes: string) => {
    if (!notes) {
      return notes;
    }

    NOTES_COLORS.forEach(({ lightNote, darkNote }) => {
      const beforeColor = isDarkTheme ? lightNote : darkNote;
      const afterColor = isDarkTheme ? darkNote : lightNote;
      notes = notes.replace(beforeColor, afterColor);
    });

    return notes;
  }, [isDarkTheme]);

  return { replaceNotesColors };
};

export default useNotesColorReplace;
