export const TAG_FIELDS = ['tags', 'custom-status'] as const;
export type TagField = typeof TAG_FIELDS[number];

export interface ITagBase {
  id: string;
  color: string;
  title: string;
  field: TagField;
}

export interface ITag extends ITagBase {
  isNewAdded?: boolean;
  inAllProfiles?: boolean;
  profilesWithTag?: number;
  hasLocalId?: boolean;
}

export interface IPopularTag extends ITag {
  count: number;
}

export interface IAddTag {
  profileIds: string[];
  title: string;
  color: string;
  field: TagField;
  tagId?: string;
  isNewAdded?: boolean;
  lastTempId?: string;
}

export interface IAddTagMethod {
  browserIds: string[];
  title: string;
  color: string;
  field: TagField;
  workspace?: string;
}
