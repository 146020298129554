import { ExpandMore } from '@material-ui/icons';
import React, { FC, useContext, useEffect, useState } from 'react';

import CreateWorkspaceModal from '../../features/modalsComponents/components/create-workspace';
import { workspaceContext } from '../../state';
import { getAccessibleFolderName } from '../../utils/check-folders-to-add-profile';
import { MenuSelectWorkspace } from './menu-select-workspace';
import {
  ContainerSelectWorkspace,
  ContainerTitleMenuDropdown,
  WorkspaceNameText,
  ContainerDescriptionMenuDropdown,
  NumberText,
  PlanText, SelectedFolderNameText,
} from './styles';

declare interface ISelectWorkspace {
  userId: string;
  selectedWorkspaceId: string;
  setSelectedWorkspaceId: (workspaceId: string) => void;
  isOwnerOnly: boolean;
  placeholder: string;
  isLoading?: boolean;
  showAddWorkspace?: boolean;
  selectedFolder?: string;
  setSelectedFolder?: (folderName: string) => void;
  needFoldersList?: boolean;
}

export const SelectWorkspace: FC<ISelectWorkspace> = (props) => {
  const {
    userId, selectedWorkspaceId, setSelectedWorkspaceId, isOwnerOnly, placeholder, isLoading,
    showAddWorkspace, selectedFolder, setSelectedFolder, needFoldersList,
  } = props;

  const [createWorkspaceModalVisible, setCreateWorkspaceModalVisible] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const workspaceCtx = useContext(workspaceContext);
  const workspacesList = isOwnerOnly ?
    workspaceCtx.availableWorkspaces.filter(availableWorkspace => availableWorkspace.owner === userId) :
    workspaceCtx.availableWorkspaces;

  const selectedWorkspace = workspacesList.find(availableWorkspace => availableWorkspace.id === selectedWorkspaceId);

  useEffect(() => {
    if (!setSelectedFolder) {
      return;
    }

    const firstAccessFolder = getAccessibleFolderName({
      folders: selectedWorkspace?.folders || [],
      permission: 'canClaimProfiles',
      limitedAccess: !selectedWorkspace?.permissions.canClaimProfilesWithoutFolder,
      hasFirstFolder: true,
    });

    setSelectedFolder(firstAccessFolder);
  }, []);

  const onClose = (): void => setAnchorEl(null);

  const renderSelectWorkspace = (): JSX.Element => {
    if (!selectedWorkspace) {
      return (
        <ContainerSelectWorkspace>
          <span style={{ marginBottom: 4, color: 'gray' }}>
            {placeholder}
          </span>
          <div style={{ marginTop: 4 }}>
            <ExpandMore htmlColor='var(--00000080-select-workspace)' />
          </div>
        </ContainerSelectWorkspace>
      );
    }

    return (
      <ContainerSelectWorkspace onClick={(event): void => setAnchorEl(event.currentTarget)}>
        <div style={{ lineHeight: '12px' }}>
          <ContainerTitleMenuDropdown>
            <WorkspaceNameText>
              {selectedWorkspace?.name}
            </WorkspaceNameText>
            {selectedFolder ?
              <SelectedFolderNameText>
                {'\xA0'}
                {'· '}
                {selectedFolder}
              </SelectedFolderNameText> : null }
          </ContainerTitleMenuDropdown>
          <ContainerDescriptionMenuDropdown style={{ justifyContent: 'flex-start' }}>
            <NumberText>
              {selectedWorkspace?.profilesCount}
              /
              {selectedWorkspace?.isPlanUnlimited ? '-' : (selectedWorkspace?.planProfilesMax ?? 0)}
            </NumberText>
            <PlanText redText={selectedWorkspace?.planId === 'unpaid'}>
              {selectedWorkspace?.planName}
            </PlanText>
          </ContainerDescriptionMenuDropdown>
        </div>
        <div style={{ marginTop: 3 }}>
          <ExpandMore htmlColor='var(--00000080-select-workspace)' viewBox='-5 0 24 24' />
        </div>
      </ContainerSelectWorkspace>
    );
  };

  return (
    <>
      {renderSelectWorkspace()}
      <MenuSelectWorkspace
        anchorEl={anchorEl}
        onClose={onClose}
        workspacesList={workspacesList}
        selectedWorkspaceId={selectedWorkspaceId}
        setSelectedWorkspaceId={setSelectedWorkspaceId}
        setCreateWorkspaceModalVisible={setCreateWorkspaceModalVisible}
        showAddWorkspace={showAddWorkspace}
        selectedFolder={selectedFolder}
        setSelectedFolder={setSelectedFolder}
        closeParent={onClose}
        needFoldersList={needFoldersList}
        isLoading={isLoading}
      />

      <CreateWorkspaceModal
        visible={createWorkspaceModalVisible}
        onClose={(): void => setCreateWorkspaceModalVisible(false)}
      />
    </>
  );
};
