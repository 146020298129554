import React, { FC } from 'react';

import { DrawerBackdropContainer } from './styles';
import { closeProxyManager, useProxyManagerState } from '../../../state/proxy/proxy-manager-modal-status.atom';
import { closeTagManager, useIsTagManagerOpen } from '../../../state/tags/tag-manager-state.atom';

const DrawerBackdrop: FC = () => {
  const { currentProfileId } = useProxyManagerState();
  const isTagManagerOpen = useIsTagManagerOpen();

  if (!(currentProfileId || isTagManagerOpen)) {
    return null;
  }

  return (
    <DrawerBackdropContainer onClick={(): void => {
      closeProxyManager();
      closeTagManager();
    }} />
  );
};

export default DrawerBackdrop;
