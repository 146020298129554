import { css, SerializedStyles } from '@emotion/core';
import styled from '@emotion/styled';
import React, { FC, MouseEvent, useContext, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { PRICING_PAGE, PROFILE_LIST_PAGE } from '../../../common/constants/routes';
import { browserUpdaterContext, userContext, workspaceContext } from '../../state';
import { NEW_FEATURES } from '../../state/feature-toggle/new-features';
import { useProxyManagerHoverState } from '../../state/proxy/proxy-manager-hover-state.atom';
import DragAndDropFiles from '../../ui/drag-and-drop-files';
import Bar from '../../ui/gologin-header/promo-bar';
import { borderRadiusContainer, changeBorderWrapper } from '../../ui/style-templates';

const Wrapper = styled('div')<{
  hasBorderRadius: boolean;
  removeTopBorder: boolean;
  isHoverDisabled: boolean;
}>`
  margin: 0 24px;

  ${(props): SerializedStyles => borderRadiusContainer(props.hasBorderRadius)};

  background-color: var(--FFFFFF);
  border: 0.5px solid var(--E5E5E6-profile-table);
  box-shadow: var(--0000000f-table-shadow);
  display: flex;
  flex-direction: column;
  flex: 1;

  ${({ isHoverDisabled }) =>
    isHoverDisabled &&
    css`
      pointer-events: none;

      * {
        pointer-events: none;
      }

      [data-proxy-manager-exclude='proxy-selector-buttons'],
      [data-proxy-manager-exclude='proxy-selector-buttons'] * {
        pointer-events: auto;
      }
    `}
`;

let ipcRenderer: Electron.IpcRenderer;

const isElectron = !!window.require;
if (isElectron) {
  ipcRenderer = window.require('electron').ipcRenderer;
}

interface IWrapperGlobal {
  location: Location;
}

const WrapperGlobal: FC<IWrapperGlobal> = ({ children, location }) => {
  const { isProxyManagerHovered } = useProxyManagerHoverState();
  const [appNeedRestart, setAppNeedRestart] = useState<boolean>(false);
  const [hasWrapperBorderRadius, setHasWrapperBorderRadius] = useState<boolean>(false);
  const [needMask, setNeedMask] = useState(false);

  const { hasTrial: userIsTrial } = useContext(userContext);
  const { isUnpaid, paymentIsTrial: workspaceIsTrial } = useContext(workspaceContext);
  const isProfileListPage = !!useRouteMatch(PROFILE_LIST_PAGE);

  const { browserUpdating, showOrbitaDialog } = useContext(browserUpdaterContext);

  useEffect((): (() => void) | void => {
    if (!isElectron) {
      return;
    }

    ipcRenderer.on('app-update-downloaded', () => {
      setAppNeedRestart(true);
    });

    return () => ipcRenderer.removeAllListeners('app-update-downloaded');
  }, []);

  useEffect(() => {
    const isUnpaidWorkspace = NEW_FEATURES.workspaces && isUnpaid;
    const isTrial = userIsTrial || (NEW_FEATURES.workspaces && workspaceIsTrial);
    const needWrapperBorderRadius = !(
      isTrial ||
      showOrbitaDialog ||
      appNeedRestart ||
      browserUpdating ||
      isUnpaidWorkspace
    );
    changeBorderWrapper(needWrapperBorderRadius);
    setHasWrapperBorderRadius(needWrapperBorderRadius);
  }, [appNeedRestart, showOrbitaDialog, userIsTrial, workspaceIsTrial, browserUpdating, isUnpaid]);

  const badPath = [PRICING_PAGE, '/forgot_password', '/sign_in', '/sign_up', '/quiz', '/pay'];
  const isLocationBadPath = badPath.find(path => path === location.pathname);

  const onDragEnter = (event: any): void => {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.types.includes('Files')) {
      setNeedMask(true);
    }
  };

  const onDragOver = (event: MouseEvent): void => {
    event.stopPropagation();
    event.preventDefault();
  };

  if (!NEW_FEATURES.header || isLocationBadPath) {
    return <>{children}</>;
  }

  return (
    <>
      {needMask && location.pathname === '/profileList' ? <DragAndDropFiles setNeedMask={setNeedMask} /> : null}
      <Bar appNeedRestart={appNeedRestart} />
      <Wrapper
        isHoverDisabled={isProxyManagerHovered}
        hasBorderRadius={hasWrapperBorderRadius}
        removeTopBorder={isProfileListPage}
        onDragOver={onDragOver}
        onDragEnter={onDragEnter}
      >
        {children}
      </Wrapper>
    </>
  );
};

export default WrapperGlobal;
