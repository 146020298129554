import { useState } from 'react';

import { MajorVersionOption } from '../features/common/orbita-browser';
import { IBrowserUpdater, IBrowserUpdaterCtx, BROWSER_UPDATER_DEFAULT_VALUE } from '../state';
import { getOSReleaseVersionForWeb } from '../utils/user-os';

const MIN_WIN_FOR_ORBITA_110 = 10;
const BROWSER_VERSION_COMPATIBLE = '109';
const isElectron = !!window.require;
const ipcRenderer: Electron.IpcRenderer | null = isElectron ? window.require('electron').ipcRenderer : null;

export const useBrowserUpdater = (): IBrowserUpdaterCtx => {
  const [browserUpdater, setBrowserUpdater] = useState<IBrowserUpdater>(BROWSER_UPDATER_DEFAULT_VALUE);

  const updateBrowserUpdater = (updateInfo: IBrowserUpdater): void => {
    setBrowserUpdater({
      ...browserUpdater,
      ...updateInfo,
    });
  };

  const isOrbitaVersionCompatibleDesktop = async (versionValue: MajorVersionOption, renderer: Electron.IpcRenderer): Promise<boolean> =>
    renderer.invoke('check-orbita-compatible', { key: 'orbitaMajorVersion', value: versionValue === 'unset' ? 'latest' : versionValue });

  const isOrbitaVersionCompatibleWeb = (versionValue: MajorVersionOption): boolean => {
    const { os, osRelease } = getOSReleaseVersionForWeb();
    const winVersion = Number(osRelease.split('.')[0]) || MIN_WIN_FOR_ORBITA_110;
    if (os !== 'win' || winVersion >= MIN_WIN_FOR_ORBITA_110) {
      return true;
    }

    if (isNaN(+versionValue)) {
      return false;
    }

    return +versionValue <= +BROWSER_VERSION_COMPATIBLE;
  };

  const isOrbitaVersionCompatible = async (versionValue: MajorVersionOption): Promise<boolean> =>
    ipcRenderer ? isOrbitaVersionCompatibleDesktop(versionValue, ipcRenderer) : isOrbitaVersionCompatibleWeb(versionValue);

  return {
    ...browserUpdater,
    updateBrowserUpdater,
    isOrbitaVersionCompatible,
  };
};
