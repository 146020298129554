import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconFillArrowDown: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='5' height='3' viewBox='0 0 5 3' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M5 0H0L2.5 3L5 0Z' fill='var(--36363D)' />
    </svg>
  </IconWrapper>
);

