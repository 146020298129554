import { getEmail } from './ui/Footer/api';
import { LOCAL_GOOGLE_CLIENT_ID_KEY_NAME } from '../common/constants/constants';

const IGNORED_PAGES_PATHS = ['/login', '/createUser', '/sign_in', '/sign_up', '/sign_up_frame'];

let ipcRenderer: any;
const isElectron = !!window.require;
if (isElectron) {
  ({ ipcRenderer } = window.require('electron'));
}

export const initGA = (): Promise<string> => {
  let clientId = '';
  let gaInited = false;

  return new Promise<void>((resolve) => {
    window.gtag && window.gtag('config', 'UA-150829020-1', {
      'custom_map': {
        'dimension1': 'clientId',
      },
    });

    if (window.ga) {
      const timeOut = setTimeout(() => resolve(), 500);
      window.ga((tracker: any) => {
        clientId = tracker.get('clientId');
        clearTimeout(timeOut);
        resolve();
      });
    } else {
      resolve();
    }
  })
    .then(() => {
      if (!clientId) {
        const splittedCookies = (document.cookie || '').split(';');

        if (splittedCookies && splittedCookies.length) {
          const gaClientIdElem = splittedCookies.find(elem => elem.includes('_ga=GA'));
          if (gaClientIdElem) {
            const splittedGaClientIdElem = gaClientIdElem.split('=');
            const gaClientId = splittedGaClientIdElem.pop() || '';
            const splittedGaClientId = gaClientId.split('.');
            if (splittedGaClientId && splittedGaClientId.length === 4) {
              clientId = splittedGaClientId[2] + '.' + splittedGaClientId[3];
              localStorage.setItem(LOCAL_GOOGLE_CLIENT_ID_KEY_NAME, clientId);
              gaInited = true;
            }
          }
        }
      } else {
        localStorage.setItem(LOCAL_GOOGLE_CLIENT_ID_KEY_NAME, clientId);
        gaInited = true;
      }

      const isPageIgnored = window.location.hash ?
        IGNORED_PAGES_PATHS.some(path => window.location.hash.startsWith(`#${path}`)) :
        IGNORED_PAGES_PATHS.some(path => window.location.pathname.startsWith(path));

      if (isPageIgnored) {
        return {};
      }

      return getEmail().catch(() => ({}));
    })
    .then((res: any) => {
      localStorage.setItem('userId', res.id || null);
      if (res?.id && gaInited && window.ga) {
        const userId = res.id;
        window.ga('set', 'userId', userId);
        window.ga('set', 'uid', userId);
        window.ga('set', 'cd2', userId);
      }

      return clientId;
    })
    .catch(() => clientId);
};

export const generateGoogleClientId = (): string => {
  let firstPart = Math.round(Math.random() * 1000000000).toString();
  let secondPart = Math.round(Math.random() * 10000000000).toString();

  if (firstPart.length < 9) {
    let count = 9 - firstPart.length;
    while (count--) {
      firstPart += count;
    }
  }

  if (secondPart.length < 10) {
    let count = 10 - secondPart.length;
    while (count--) {
      secondPart += count;
    }
  }

  const generatedClientId = `0${firstPart}.${secondPart}`;
  localStorage.setItem(LOCAL_GOOGLE_CLIENT_ID_KEY_NAME, generatedClientId);
  ipcRenderer.invoke('update-google-client-id', generatedClientId);

  return generatedClientId;

};

export const getGoogleClientId = async (): Promise<string> => {
  if (!isElectron) {
    return initGA();
  }

  const gidFromLocalStorage = localStorage.getItem(LOCAL_GOOGLE_CLIENT_ID_KEY_NAME);
  if (gidFromLocalStorage) {
    await ipcRenderer.invoke('update-google-client-id', gidFromLocalStorage);

    return gidFromLocalStorage;
  }

  const googleClientId = await ipcRenderer.invoke('get-client-id-from-metadata');

  if (googleClientId) {
    localStorage.setItem(LOCAL_GOOGLE_CLIENT_ID_KEY_NAME, googleClientId);
    await ipcRenderer.invoke('update-google-client-id', googleClientId);

    return googleClientId;
  }

  return generateGoogleClientId();
};

export const updateClientId = async (googleClientId: string): Promise<void> => {
  if (localStorage.getItem(LOCAL_GOOGLE_CLIENT_ID_KEY_NAME)) {
    return;
  }

  localStorage.setItem(LOCAL_GOOGLE_CLIENT_ID_KEY_NAME, googleClientId);
  await ipcRenderer.invoke('update-google-client-id', googleClientId);
};
