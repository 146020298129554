import styled from '@emotion/styled';
import React from 'react';

const IconToFolderStyle = styled('div')`
  svg {
    stroke: var(--767676-icon-to-folder);
    fill: var(--767676-icon-to-folder);

    :hover {
      stroke: var(--222222-icon-to-folder);
      fill: var(--222222-icon-to-folder);
      cursor: pointer;
    }
  }
`;

const IconToFolder = (): JSX.Element => (
  <IconToFolderStyle>
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='Frame 2159'>
        <g id='Group 1766'>
          <g id='Group 1763'>
            <g id='Group 1767'>
              <path id='Vector' fill='none' d='M6.34854 16.6485C6.57359 16.8736 6.87881 17 7.19707 17H17.9971C18.3153 17 18.6206 16.8736 18.8456 16.6485C19.0706 16.4235 19.1971 16.1183 19.1971 15.8V7.4H11.9971L10.7971 5H5.99707V8' strokeLinecap='round' strokeLinejoin='round' />
              <g id='Group 1739'>
                <path id='Union' stroke='none' fillRule='evenodd' clipRule='evenodd' d='M7.77244 14.1894C7.57718 14.3846 7.57718 14.7012 7.77244 14.8965C7.9677 15.0917 8.28429 15.0917 8.47955 14.8965L10.8506 12.5254C11.0459 12.3301 11.0459 12.0136 10.8506 11.8183L8.47955 9.44723C8.28429 9.25197 7.9677 9.25197 7.77244 9.44723C7.57718 9.64249 7.57718 9.95907 7.77244 10.1543L9.28974 11.6716L2.0957 11.6716C1.81956 11.6716 1.5957 11.8955 1.5957 12.1716C1.5957 12.4478 1.81956 12.6716 2.0957 12.6716L9.29019 12.6716L7.77244 14.1894Z' />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </IconToFolderStyle>
);

export default IconToFolder;
