import { API_BASE_URL } from '../../../../../common/constants/constants';
import { http } from '../../../../services';
import { IUpdateProfilesUAOpts, IUpdateProfilesUAResponse } from './interfaces';

export const updateProfilesUA = async (opts: IUpdateProfilesUAOpts): Promise<IUpdateProfilesUAResponse> => {
  const {
    browserIds = [],
    updateAllProfiles = false,
    updateUaToNewBrowserV = false,
    testOrbita = false,
    currentWorkspace = '',
  } = opts;

  let url = `${API_BASE_URL}/browser/update_ua_to_new_browser_v`;
  if (currentWorkspace) {
    url += `?currentWorkspace=${currentWorkspace}`;
  }

  const data = await http(url, {
    method: 'PATCH',
    body: JSON.stringify({
      browserIds,
      updateAllProfiles,
      updateUaToNewBrowserV,
      testOrbita,
    }),
  });

  return data.body;
};
