import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconUser: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M14.5 12.9755C14.5002 12.8147 14.4616 12.6561 14.3874 12.5134C14.3132 12.3706 14.2057 12.2479 14.074 12.1555C12.2699 10.9872 10.1474 10.4085 8 10.4995C5.85262 10.4085 3.73005 10.9872 1.926 12.1555C1.79427 12.2479 1.68677 12.3706 1.61261 12.5134C1.53844 12.6561 1.49982 12.8147 1.5 12.9755V15.4995H14.5V12.9755Z' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8 7.5C9.933 7.5 11.5 5.933 11.5 4C11.5 2.067 9.933 0.5 8 0.5C6.067 0.5 4.5 2.067 4.5 4C4.5 5.933 6.067 7.5 8 7.5Z' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  </IconWrapper>
);
