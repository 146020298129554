import React, { FC, memo } from 'react';

import { Text } from '../../styles';

interface IShowingStep {
  text: string;
  isFirstStep: boolean;
  isCurrent: boolean;
  downloadProgressSize: string;
}

const ShowingStep: FC<IShowingStep> = (props) => {
  const { text, isFirstStep, isCurrent, downloadProgressSize } = props;

  return (
    <div style={{ color: isCurrent ? '' : 'var(--767676-promo-bar-update-orbita)' }}>
      <Text>
        {text}
      </Text>
      {isFirstStep ? (
        <Text style={{ color: 'var(--767676-promo-bar-update-orbita)', marginRight: 16 }}>
          {downloadProgressSize}
        </Text>
      ) : null}
    </div>
  );
};

export default memo(ShowingStep);
