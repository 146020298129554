import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconInvite: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_11845_3695)'>
        <path d='M7 7.5C8.933 7.5 10.5 5.933 10.5 4C10.5 2.067 8.933 0.5 7 0.5C5.067 0.5 3.5 2.067 3.5 4C3.5 5.933 5.067 7.5 7 7.5Z' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M9.894 10C9.13897 9.67019 8.32392 9.49996 7.5 9.5H6.5C4.9087 9.5 3.38258 10.1321 2.25736 11.2574C1.13214 12.3826 0.5 13.9087 0.5 15.5H9.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M12.5 9.5V15.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M9.5 12.5H15.5' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_11845_3695'>
          <rect width='16' height='16' fill='var(--FFFFFF)' />
        </clipPath>
      </defs>
    </svg>
  </IconWrapper>
);
