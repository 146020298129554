import React, { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import moment from 'moment';
import { E_ANALYTICS_ACTIONS } from '../../../../common/constants/analytics';
import { IAutomation, IProfile, IProxy } from '../../../interfaces';
import { browserUpdaterContext } from '../../../state';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { getIsGlobalModalVisible, ORBITA_UPDATE_OR_SKIP_MODAL_NAME } from '../../../state/global-modal';
import { useHintPreferences } from '../../../state/hint-preferences/hint-preferences.atom';
import { getIsProxyColumnPresented } from '../../../state/is-proxy-column-presented.atom';
import { useProfileRunStatus } from '../../../state/profile-run-statuses.atom';
import { getProfilesListProfileById } from '../../../state/profiles-list.atom';
import { showProxyCheckTooltip } from '../../../state/proxy/proxy-check/proxy-check-tooltip.atom';
import { updateProxyStatuses } from '../../../state/proxy/proxy-check/proxy-statuses.atom';
import { getProxyListProxyById } from '../../../state/proxy/proxy-list.atom';
import { IconSpinnerOld } from '../../../ui/gologin-header/icons';
import TooltipCustom from '../../../ui/tooltip-custom';
import { sendActionAnalytics } from '../../common/api';
import { EMPTY_PROXY } from '../../proxy/constants';
import { generateProxyAnalyticsData } from '../../proxy/proxy-helpers';
import { launchProfileWeb, stopProfileOrbita, stopProfileWeb } from '../actions';
import { ILaunchProfileOrbita } from '../components/interfaces/launch-profile-orbita.interface';
import { ButtonRun, ButtonStop, DisabledButtonRun, DrawerButtonRun, DrawerButtonStop } from './styles';
import ViewProfileButton from './view-button';

const isElectron = !!window.require;

type IProfileRunOnly = Pick<IProfile, 'id' | 'isWeb' | 'navigator' | 'canBeRunning'>;

interface IRunProfileButton {
  profile: IProfileRunOnly;
  automation?: IAutomation;
  isInDrawer?: boolean;
  launchProfileOrbita: (opts: ILaunchProfileOrbita) => void;
  onRunButtonClick: () => void;
}

const RunProfileButton: FC<IRunProfileButton> = (props): JSX.Element | null => {
  const { profile, automation, isInDrawer, launchProfileOrbita, onRunButtonClick } = props;
  const hintPreferences = useHintPreferences();
  const isLocationUnmaskedHintShouldBeVisible = hintPreferences.locationIsUnmasked === 'visible-on-run';
  const [anchorElTooltip, setAnchorElTooltip] = useState<HTMLElement | null>(null);

  const { versionLoadingNow, browserUpdating } = useContext(browserUpdaterContext);

  const { status, isWeb, remoteOrbitaUrl } = useProfileRunStatus(profile.id);

  const { t: translation } = useTranslation();

  const sendRunAnalytics = (profilesListProfile: IProfile | null): void => {
    const { id = '', name = '', proxy = EMPTY_PROXY, isAutoGenerated = false } = profilesListProfile || {};
    const proxyListProxy = getProxyListProxyById(proxy?.id);
    const proxyAnalyticsData = generateProxyAnalyticsData(proxyListProxy || proxy);
    sendActionAnalytics(E_ANALYTICS_ACTIONS.clickedRunProfile, {
      ...proxyAnalyticsData,
      profile: id,
      actionInfo: name,
      mode: isAutoGenerated ? 'default profile' : '',
    });
  };

  const onRunClick = async (): Promise<void> => {
    const profilesListProfile = getProfilesListProfileById(profile?.id);
    const isModalVisible = getIsGlobalModalVisible(ORBITA_UPDATE_OR_SKIP_MODAL_NAME);
    const isProxyColumnVisible = getIsProxyColumnPresented();

    sendRunAnalytics(profilesListProfile); 
    
    if (
      isProxyColumnVisible &&
      isLocationUnmaskedHintShouldBeVisible &&
      !profilesListProfile?.proxy.id &&
      !isModalVisible
    ) {
      onRunButtonClick();
      return;
    }

    if (isElectron) {
      launchProfileOrbita({ profile, automation });
    } else {
      launchProfileWeb(profile);
    }

    if (!profilesListProfile?.proxy.id  || ['tor', 'gologin'].includes(profilesListProfile?.proxy?.mode)) {
      return;
    }

    const checkedProxy = await updateProxyStatuses({
      proxies: [profilesListProfile.proxy],
      profileId: profile.id,
      isSharedProxy: profilesListProfile.proxy.isShared,
      view: 'profile-run-button',
    });

    const fullCheckedProxy: IProxy = {
      ...profilesListProfile.proxy,
      ...checkedProxy[0],
      checkDate: moment().toDate(),
    };

    showProxyCheckTooltip({
      profileIds: [profile.id],
      proxies: [fullCheckedProxy],
      view: 'profile-run-button',
    });
  };

  if (automation && status !== 'profileStatuses.ready') {
    return null;
  }

  const showTooltip = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    setAnchorElTooltip(event.currentTarget);
  };

  const hideTooltip = (): void => setAnchorElTooltip(null);

  if (status === 'profileStatuses.running') {
    if (profile.isWeb || isWeb) {
      return (
        <>
          <ViewProfileButton profileId={profile.id} remoteOrbitaUrl={remoteOrbitaUrl} />
          {isInDrawer ? (
            <DrawerButtonStop
              style={{ marginLeft: 10 }}
              onClick={(): void => {
                stopProfileWeb(profile.id);
                sendActionAnalytics('clicked stop profile');
              }}
            >
              {translation('quickSettings.stopBtnTxt')}
            </DrawerButtonStop>
          ) : (
            <ButtonStop
              newStyle={!!NEW_FEATURES.header}
              onClick={(): void => {
                stopProfileWeb(profile.id);
                sendActionAnalytics('clicked stop profile');
              }}
            >
              {translation('base.stop')}
            </ButtonStop>
          )}
        </>
      );
    }

    return (
      <>
        {isElectron ? <ViewProfileButton profileId={profile.id} remoteOrbitaUrl={remoteOrbitaUrl} /> : null}
        {isInDrawer ? (
          <DrawerButtonStop
            style={{ marginLeft: 10 }}
            onClick={(): void => {
              stopProfileOrbita(profile.id);
              sendActionAnalytics('clicked stop profile');
            }}
          >
            {translation('quickSettings.stopBtnTxt')}
          </DrawerButtonStop>
        ) : (
          <ButtonStop
            newStyle={!!NEW_FEATURES.header}
            onClick={(): void => {
              stopProfileOrbita(profile.id);
              sendActionAnalytics('clicked stop profile');
            }}
          >
            {translation('base.stop')}
          </ButtonStop>
        )}
      </>
    );
  }

  const disabled = !profile.canBeRunning;
  const chromeVersionMatch =
    (profile?.navigator?.userAgent && profile.navigator.userAgent.match(/[\s\S\w]+Chrome\/(\d+)/)) || [];
  if (!chromeVersionMatch) {
    return null;
  }

  const [_, version = ''] = chromeVersionMatch;
  const isBrowserUpdating =
    browserUpdating && (Number(version) === Number(versionLoadingNow) || versionLoadingNow === '');

  const loading = !['profileStatuses.ready', 'profileStatuses.error', 'profileStatuses.running'].includes(status || '');
  if (loading && !isInDrawer) {
    return (
      <ButtonRun newStyle={!!NEW_FEATURES.header} isLoading={true} style={{ background: 'transparent' }}>
        <IconSpinnerOld />
      </ButtonRun>
    );
  }

  if (loading && isInDrawer) {
    return (
      <DrawerButtonRun size='small' loading={true}>
        {translation('quickSettings.syncingTxt')}
      </DrawerButtonRun>
    );
  }

  if (disabled || isBrowserUpdating) {
    const tooltipText = isBrowserUpdating
      ? translation('notifications.error.orbitaIsDownloading')
      : translation('notifications.error.runByAnotherUser');

    return (
      <>
        <DisabledButtonRun newStyle={!!NEW_FEATURES.header} onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
          {isInDrawer ? translation('quickSettings.runBtnTxt') : translation('base.run')}
        </DisabledButtonRun>
        <TooltipCustom anchorEl={anchorElTooltip} value={tooltipText} />
      </>
    );
  }

  if (isInDrawer) {
    return (
      <DrawerButtonRun size='small' onClick={onRunClick} loading={loading}>
        {translation('quickSettings.runBtnTxt')}
      </DrawerButtonRun>
    );
  }

  return (
    <ButtonRun newStyle={!!NEW_FEATURES.header} isLoading={loading} onClick={onRunClick}>
      {translation('base.run')}
    </ButtonRun>
  );
};

export default RunProfileButton;
