import { message } from 'antd';
import moment from 'moment';
import React from 'react';
import { Trans } from 'react-i18next';

import { linkProfileProxyInState, unlinkProfileProxyInState } from './link-proxy.operations';
import { determineIsProxyTruthy, GeoProxyType } from '../../../../common/constants/types';
import { calculateSelectableConnectionTypes } from '../../../../common/proxy/traffic/utils';
import { getVpnUfoProxy } from '../../../features/profileSettingsComponents/proxyTab/api';
import { VpnUfoProxyOptions } from '../../../features/profileSettingsComponents/proxyTab/vpn-ufo.types';
import {
  GEOPROXY_CHECK_FAILED_WHEN_CREATING_ERROR,
  GEOPROXY_NOT_FOUND_FOR_COUNTRY_AND_TYPE_ERROR,
  GEOPROXY_TYPE_TAG,
  PROXY_CHECK_ERROR_TAG,
  PROXY_COUNTRY_TAG,
  PROXY_GROUP_ID_TAG,
  PROXY_ID_TAG,
} from '../../../features/proxy/constants';
import { generateGeoProxyCustomName } from '../../../features/proxy/proxy-helpers';
import { generateArtificialGeoProxyId, getNoIdProxyId } from '../../../features/proxy/utils/proxy-id';
import { IGeolocationProxyFullData, IProxy } from '../../../interfaces';
import { GeoProxyWithTraffic } from '../../../interfaces/geoproxy-with-traffic.type';
import { sendReactErrorToSentry } from '../../../utils/sentry.helper';
import { setGeoProxyLastSelectedType } from '../geoproxy-form-data.atom';
import { showProxyCheckTooltip } from '../proxy-check/proxy-check-tooltip.atom';
import { addProxyStatuses, removeProxyStatuses, updateProxyStatuses } from '../proxy-check/proxy-statuses.atom';
import { ProxyCheckTooltipLocation } from '../proxy-check/types/proxy-check-tooltip-location.type';
import { pushManyProxies, removeProxiesFromList, updateProxyInListById } from '../proxy-list.atom';
import { IProxyManagerState } from '../proxy-manager-modal-status.atom';

type GeoProxyWithCredentialsParams = Pick<VpnUfoProxyOptions, 'customName' | 'profileIdToLink'> & {
  country: string;
  connectionType: GeoProxyType;
  profileId: string;
}

const fetchGeoProxyWithCredentials = async ({
  country,
  profileId,
  connectionType,
  customName,
  profileIdToLink,
}: GeoProxyWithCredentialsParams): Promise<GeoProxyWithTraffic> => {
  const parameters: VpnUfoProxyOptions = {
    countryCode: country,
    browserId: profileId,
    isMobile: connectionType === 'mobile',
    isDC: connectionType === 'dataCenter',
    customName,
  };

  if (profileIdToLink) {
    parameters.profileIdToLink = profileIdToLink;
  }

  return getVpnUfoProxy(parameters);
};

type GeoProxySubmitParams = {
  groupId: string;
  profileId: string;
  country: string;
  selectedConnectionType: GeoProxyType;
  availableConnectionTypes: GeoProxyType[];
  trafficData: IGeolocationProxyFullData;
  checkTooltipView: ProxyCheckTooltipLocation;
  handleProxySelect?: IProxyManagerState['handleProxySelect'];
}

type GeoProxyMockParams = {
  country: string;
  connectionType: GeoProxyType;
  profilesCount: number;
  groupId: string;
}

const makeArtificialGeoProxy = ({ country, connectionType, profilesCount, groupId }: GeoProxyMockParams): IProxy => {
  const proxyId = generateArtificialGeoProxyId();
  const customName = generateGeoProxyCustomName(country);

  return {
    id: proxyId,
    groupId,
    host: '',
    port: 80,
    connectionType,
    mode: 'geolocation',
    customName,
    country,
    profilesCount,
    positionInGroup: 'end',
    createdAt: new Date(),
    selectionDate: profilesCount ? Date.now() : 0,
  };
};

const handleGeoProxyWithCredentialsError = (artificialGeoProxy: IProxy, profileId: string): void => {
  removeProxyStatuses([artificialGeoProxy], [profileId]);
  removeProxiesFromList([artificialGeoProxy.id]);
  if (profileId) {
    unlinkProfileProxyInState(profileId);
  }
};

const checkGeoProxy = async (proxy: IProxy, profileId: string, checkTooltipView: ProxyCheckTooltipLocation): Promise<IProxy | string> => {
  const checkedProxy = await updateProxyStatuses({
    proxies: [proxy],
    profileId,
    isEditable: true,
    shouldUpdateProxyInList: false,
  }).catch(() => ({ status: false, error: '' }));

  const checkedFullProxy: IProxy = { ...proxy, ...checkedProxy, checkDate: moment().toDate() };
  if (!(checkedProxy && checkedProxy.status)) {
    sendReactErrorToSentry({
      transactionName: GEOPROXY_CHECK_FAILED_WHEN_CREATING_ERROR,
      message: GEOPROXY_CHECK_FAILED_WHEN_CREATING_ERROR.replaceAll('-', ' '),
      tags: [
        [PROXY_ID_TAG, proxy.id],
        [GEOPROXY_TYPE_TAG, proxy.connectionType],
        [PROXY_COUNTRY_TAG, proxy.country],
        [PROXY_CHECK_ERROR_TAG, checkedProxy?.error || ''],
      ],
    });

    return 'tableProfiles.notification.proxyNotFoundForCountryAndType';
  }

  showProxyCheckTooltip({
    profileIds: [profileId || ''],
    proxies: [checkedFullProxy],
    view: checkTooltipView,
    timeout: 2000,
  });

  return checkedFullProxy;
};

type TranslationKey = string;

export const createGeoProxy = async ({
  groupId,
  profileId,
  country,
  selectedConnectionType,
  availableConnectionTypes,
  trafficData,
  checkTooltipView,
  handleProxySelect = null,
}: GeoProxySubmitParams): Promise<IProxy | TranslationKey | null> => {
  let selectableConnectionType = selectedConnectionType;
  if (!availableConnectionTypes.includes(selectedConnectionType)) {
    const selectableConnectionTypes = calculateSelectableConnectionTypes(trafficData, selectedConnectionType);
    if (!selectableConnectionTypes.length) {
      message.error(<Trans i18nKey='tableProfiles.notification.proxyNotFoundForCountryAndType' />);
      sendReactErrorToSentry({
        transactionName: GEOPROXY_NOT_FOUND_FOR_COUNTRY_AND_TYPE_ERROR,
        message: GEOPROXY_CHECK_FAILED_WHEN_CREATING_ERROR.replaceAll('-', ' '),
        tags: [
          [PROXY_GROUP_ID_TAG, groupId],
          [GEOPROXY_TYPE_TAG, selectedConnectionType],
          [PROXY_COUNTRY_TAG, country],
        ],
      });

      return null;
    }

    [selectableConnectionType] = selectableConnectionTypes;
    setGeoProxyLastSelectedType(selectableConnectionType);
  }

  const profilesCount = profileId && !handleProxySelect ? 1 : 0;
  const artificialGeoProxy = makeArtificialGeoProxy({
    country,
    connectionType: selectableConnectionType,
    groupId,
    profilesCount,
  });

  const geoProxyWithCredentialsParams: GeoProxyWithCredentialsParams = {
    country,
    connectionType: selectableConnectionType,
    profileId,
    customName: artificialGeoProxy.customName,
  };

  addProxyStatuses([artificialGeoProxy], [profileId]);
  pushManyProxies([artificialGeoProxy]);
  if (handleProxySelect) {
    let proxyId = artificialGeoProxy.id;
    if (!proxyId && determineIsProxyTruthy(artificialGeoProxy)) {
      proxyId = getNoIdProxyId(artificialGeoProxy);
    }

    handleProxySelect(proxyId);
  } else if (profileId) {
    linkProfileProxyInState({
      profileId,
      proxy: artificialGeoProxy,
      shouldUpdateProxyInList: false,
    });

    geoProxyWithCredentialsParams.profileIdToLink = profileId;
  }

  const geoProxyWithCredentialsResponse = await fetchGeoProxyWithCredentials(geoProxyWithCredentialsParams).catch((error) => {
    handleGeoProxyWithCredentialsError(artificialGeoProxy, profileId);
    let errorMessage = 'tableProfiles.notification.trafficLimit';
    if (typeof error?.body?.message === 'string') {
      errorMessage = error.body.message;
    }

    return errorMessage;
  });

  if (typeof geoProxyWithCredentialsResponse === 'string') {
    return geoProxyWithCredentialsResponse;
  }

  const proxyWithCredentials = geoProxyWithCredentialsResponse;
  const fullProxy: IProxy = {
    ...artificialGeoProxy,
    ...proxyWithCredentials,
    mode: 'geolocation',
  };

  const checkResult = await checkGeoProxy(fullProxy, profileId, checkTooltipView);
  if (typeof checkResult === 'string') {
    handleGeoProxyWithCredentialsError(artificialGeoProxy, profileId);

    return checkResult;
  }

  const checkedProxy = checkResult;
  updateProxyInListById(artificialGeoProxy.id, checkedProxy);
  removeProxyStatuses([artificialGeoProxy], [profileId]);
  if (handleProxySelect) {
    let proxyId = checkedProxy.id;
    if (!proxyId && determineIsProxyTruthy(checkedProxy)) {
      proxyId = getNoIdProxyId(checkedProxy);
    }

    handleProxySelect(proxyId);
  } else if (profileId) {
    linkProfileProxyInState({
      profileId,
      proxy: checkedProxy,
      shouldUpdateProxyInList: false,
    });
  }

  return { ...proxyWithCredentials, ...checkedProxy, profilesCount };
};
