import { atom, getDefaultStore, useAtomValue } from 'jotai';
import { Hint } from '../../ui/hints-widget/types';

type HintsWidgetState = {
  hints: Hint[];
}

const HINTS_WIDGET_STATE: HintsWidgetState = {
  hints: [],
};

const hintsWidgetStateAtom = atom<HintsWidgetState>(HINTS_WIDGET_STATE);

export const useHintsWidgetState = (): HintsWidgetState => useAtomValue(hintsWidgetStateAtom);

export const setHintsWidgetState = (hints: Hint[]): void => {
  const oldHintsWidgetState = getDefaultStore().get(hintsWidgetStateAtom);
  getDefaultStore().set(hintsWidgetStateAtom, { ...oldHintsWidgetState, hints });
};
