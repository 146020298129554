/* eslint-disable max-lines */
import styled from '@emotion/styled';
import { Button, Input, Tabs, Tooltip } from 'antd';
import moment from 'moment';
import React, { Component, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { TransProps, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import AdvancedTab from './advanced-tab';
import BodyContainer from './body-container';
import ContainerDashboard from './container-dashboard';
import GeolocationTab from './geolocation-tab';
import OverviewTab from './overview-tab';
import TimezoneTab from './timezone-tab';
import WebRTCTab from './webrtc-tab';
import { determineIsTorOrFreeProxy } from '../../../../common/constants/types';
import { changeableProtocols } from '../../../../common/proxy/utils';
import { IProfile, IProxy } from '../../../interfaces';
import { ITemplatesCtx, templatesContext, userContext } from '../../../state';
import { editProfilesListFields } from '../../../state/profiles-list.atom';
import { setProxyStatuses } from '../../../state/proxies.context/api';
import { addProxyStatuses, resetProxyStatuses } from '../../../state/proxy/proxy-check/proxy-statuses.atom';
import { getProfileSettingsPreviousProxy, resetProxyInProfileSettings } from '../../../state/proxy/proxy-in-profile-settings.atom';
import {
  decrementProxyProfilesCount,
  getProxyList,
  incrementProxyProfilesCount,
  updateProxyInListById,
  updateProxyItem,
  updateProxyList,
  useProxyList,
} from '../../../state/proxy/proxy-list.atom';
import Header from '../../../ui/Header';
import { isProxyDataValid } from '../../../utils/proxy.validation-message';
import { UpdateProfile } from '../../common/actions';
import {getRenderersList, getVendorsList, requestFingerprint} from '../../common/api';
import { getBrowserVersionCurrent } from '../../common/orbita-browser';
import BookmarksTab from '../../common/tabs/bookmarks';
import { updateProxyAndProfileProxyData } from '../../common/update-local-profile-data';
import { requestFullProfileInfo } from '../../profile-settings/api';
import ExtensionsTab from '../../profileSettingsComponents/extensionsTab/extensions-tab';
import ProxyTab from '../../profileSettingsComponents/proxyTab';
import { getProxyStatusParams, restoreProxy } from '../../proxy/proxy-helpers';
import { sendAnalytics, updateProfile } from '../api';
import { initialState } from '../reducer';

const ContainerContent = styled('div')`
  margin: 30px 86px;
  color: var(--000000-update-profile);
  font-family: Roboto;
  @media screen and (max-width: 500px) {
    margin: 4% 5%;
  }
  @media screen and (max-width: 325px) {
    margin: 4% 2%;
  }
`;

const ColumnsContainer = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const LeftColumnContainer = styled('div')`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 450px) {
    width: 100%;
  }
`;

const RightColumnContainer = styled('div')`
  max-width: 207px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 450px) {
    display: none;
  }
`;

const PageTitle = styled('h1')`
  margin: 0 !important;
  color: var(--000000-update-profile);
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
`;

const ButtonDiv = styled('div')`
  position: fixed;
  left: 0;
  width: 100%;
  bottom: 0;
  z-index: 999;
  padding: 1em;
  background-color: var(--FFFFFF-update-profile);
  box-shadow: 0 -4px 15px 0px var(--0000001A-update-profile);;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SaveButton = styled(Button)`
  font-size: 12px;
  max-width: 102px;
  max-height: 30px;
  margin-top: 5px;
  margin-bottom: 7px;
  margin-left: 75px;
  text-shadow: none;
  @media screen and (max-width: 450px) {
    margin-left: 0;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Subtitle = styled('span')`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 10px;
  display: block;
`;

const ProfileSummaryContainer = styled('div')`
  background-color: var(--F6F6F6-update-profile);
  width: 207px;
`;

const ProfileSummaryContent = styled('div')`
  margin: 25px;
  display: flex;
  flex-direction: column;
  font-size: 10px;
  line-height: 10px;
  text-indent: 3px;
  opacity: 0.9;

  & span {
    padding: 5px 0;
  }
`;

const ContentBrowserName = styled('div')`
  width: 278px;
  margin-top: 35px;
`;

const ContentTabs = styled('div')`
  margin-top: 43px;
  width: 100%;
  max-width: 570px;
  @media screen and (max-width: 450px) {
    width: 100%;
  }
`;

const GreenText = styled('span')`
  color: var(--2A5A2B-update-profile);
  margin-left: 4px;
`;

const CancelButton = styled(Button)`
  color: var(--1FC47D-update-profile);
  border-color: var(--1FC47D-update-profile);
  font-size: 12px;
  :hover {
    opacity: 0.7;
  }
  max-width: 102px;
  max-height: 30px;
  margin-top: 5px;
  margin-bottom: 7px;
  margin-left: 15px;
  text-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const updatedFields: any = {};
let oldName = '';

interface IUpdateProfilePageProps extends TransProps {
  UpdateProfile: any;
  profileInfo: any;
  history: any;
  location: Location;
  proxyList: IProxy[];
  userId?: string;
}

interface IUpdateProfilePageState {
  showFingerPrint: boolean;
  buttonProxy: boolean;
  GetProxyAnswer: number;
  isLoadingProfileData: boolean;
  disabledSaveButton: boolean;
  loadingSaveButton: boolean;
  updateNoises: boolean;
  isGeolocationScreen: boolean;
  defaultLanguages: string;
  vendors: string[];
  renderers: string[];
}

class UpdateProfilePageClass extends Component<IUpdateProfilePageProps, IUpdateProfilePageState> {
  state = {
    showFingerPrint: false,
    buttonProxy: false,
    GetProxyAnswer: 4,
    isLoadingProfileData: true,
    disabledSaveButton: true,
    loadingSaveButton: false,
    updateNoises: false,
    isGeolocationScreen: false,
    defaultLanguages: '',
    vendors: [],
    renderers: [],
  };

  public componentDidMount() {
    this.getProfileInfo();
  }

  public componentWillUnmount(): void {
    this.props.UpdateProfile(initialState);
    resetProxyInProfileSettings();
  }

  private setUpdatedField(field: string) {
    updatedFields[field] = (updatedFields[field] || 0) + 1;
  }

  private ShowButtonFingerprint = () => {
    this.setState({
      showFingerPrint: true,
    });
  };

  private setIsGeolocationScreen = (state: boolean) => this.setState({ isGeolocationScreen: state });

  private CloseButtonFingerprint = () => {
    this.setState({
      showFingerPrint: false,
    });
  };

  private updateVendorsList = () => {
    getVendorsList().then(resultVendors => {
      this.setState({ vendors: resultVendors.vendorsList });
    });
  };

  private updateRenderersList = async (vendor: string, updateInFp = false): Promise<void> => {
    const resultRenderers = await getRenderersList(vendor);

    if (updateInFp) {
      this.props.UpdateProfile({
        webGLMetadata: {
          ...this.props.profileInfo.webGLMetadata,
          renderer: resultRenderers.renderersList[0],
        },
      });
    }

    this.setState({ renderers: resultRenderers.renderersList });
  };

  public updateFingerprint = async (templatesCtx: ITemplatesCtx) => {
    const {
      os = 'win',
      isM1 = false,
      webRTC = {},
      webGL: defaultWebGL = {},
      webGLMetadata: defaultWebGLMetadata = {},
      canvas: defaultCanvas,
      fonts: defaultFonts = {},
      navigator: defaultNavigator = {},
      mediaDevices: defaultMediaDevices = {},
      plugins: defaultPlugins = {},
      timezone: defaultTimezone = {},
      webglParams: defaultWebglParams = {},
      clientRects: defaultClientRects = {},
      osSpec,
    } = this.props.profileInfo;

    const browserVersionCurrent = getBrowserVersionCurrent(defaultNavigator.userAgent);
    const fingerprint =
      await requestFingerprint({ os, osSpec, template: templatesCtx.selectedTemplate.id, browserVersionCurrent })
        .catch(() => ({}));

    const {
      navigator,
      plugins,
      canvas,
      mediaDevices,
      webGLMetadata = {},
      webglParams = {},
      webGL = {},
      os: fprintOS,
      fonts,
      timezone = {},
      devicePixelRatio,
      clientRects = {},
    } = fingerprint;

    this.updateRenderersList(webGLMetadata.vendor);

    // Если пользователь накликал много раз и мы получили много ответов под разные ОС
    if (os !== fprintOS) {
      return null;
    }

    let deviceMemory = navigator.deviceMemory || 2;
    if (deviceMemory > 8) {
      deviceMemory = 8;
    }

    if (deviceMemory < 1) {
      deviceMemory = 1;
    }

    this.setState({ updateNoises: true });

    this.props.UpdateProfile({
      name: `${this.props.profileInfo.name}`,
      fonts: {
        ...defaultFonts,
        families: fonts,
      },
      webGL: {
        ...defaultWebGL,
        mode:
          webGL.mode === 'noise' || webGL.mode === 'off'
            ? webGL.mode
            : defaultWebGL.mode,
      },
      clientRects: {
        ...defaultClientRects,
        mode:
          clientRects.mode === 'noise' || clientRects.mode === 'off'
            ? clientRects.mode
            : defaultClientRects.mode,
      },
      webGLMetadata: {
        ...defaultWebGLMetadata,
        mode:
          webGLMetadata.mode && webGLMetadata.mode === 'off'
            ? webGLMetadata.mode
            : 'mask',
        vendor: webGLMetadata.vendor || 'Google Inc.',
        renderer: webGLMetadata.renderer || '',
      },
      webRTC,
      mediaDevices: {
        ...defaultMediaDevices,
        audioInputs: mediaDevices.audioInputs || 0,
        audioOutputs: mediaDevices.audioOutputs || 0,
        videoInputs: mediaDevices.videoInputs || 0,
      },
      canvas: {
        ...defaultCanvas,
        mode:
          canvas.mode === 'noise' ||
          canvas.mode === 'off' ||
          canvas.mode === 'block'
            ? canvas.mode
            : defaultCanvas.mode,
      },
      plugins: {
        ...defaultPlugins,
        ...plugins,
      },
      navigator: {
        ...defaultNavigator,
        ...navigator,
        deviceMemory,
      },
      timezone: {
        ...defaultTimezone,
        ...timezone,
      },
      webglParams: {
        ...defaultWebglParams,
        ...webglParams,
      },
      devicePixelRatio,
      osSpec,
    });
  };

  changeButton = (item:any) => {
    this.setState({
      buttonProxy: item,
    });
  };

  private getProfileIdFromLocation = (): string => this.props.location.pathname.replace('/update/', '');

  private getProfileInfo = async () => {
    const profileInfo = await requestFullProfileInfo(
      this.getProfileIdFromLocation(),
    );

    const { archivedProxy = null } = profileInfo || {};
    if (archivedProxy) {
      const restoredProxy = await restoreProxy(archivedProxy);
      delete profileInfo?.archivedProxy;
      profileInfo.proxy = restoredProxy;
      profileInfo.proxyEnabled = true;
    }

    oldName = profileInfo.name;

    const {
      audioContext = { mode: 'noise' },
      browserType = 'chrome',
      canvas = { mode: 'noise' },
      clientRects = { mode: 'noise' },
      dns = '',
      extensions = { enabled: true, names: [] },
      fonts = { families: [] },
      devicePixelRatio = 1,
      geolocation = {
        customize: true,
        enabled: true,
        fillBasedOnIp: true,
        mode: 'prompt',
      },
      bookmarks,
      isBookmarksSynced,
      autoLang = true,
      googleServicesEnabled = false,
      lockEnabled = false,
      mediaDevices = { videoInputs: 1, audioInputs: 1, audioOutputs: 1 },
      name = '',
      navigator,
      notes = '',
      os = 'win',
      osSpec = 'win',
      isM1 = false,
      plugins = { enableVulnerable: true, enableFlash: true },
      proxy = { mode: 'none', host: '', port: 80, username: '', password: '', autoProxyRegion: 'us', torProxyRegion: 'us' },
      proxyEnabled = false,
      startUrl = '',
      storage = {
        local: true,
        extensions: true,
        bookmarks: true,
        history: true,
        passwords: true,
        session: true,
        indexedDb: true,
        enableExternalExtensions: false,
      },
      timezone = {
        enabled: true,
        fillBasedOnIp: true,
        timezone: '',
      },
      webGL = { mode: 'real' },
      webGLMetadata = { mode: 'mask', vendor: 'Google Inc.' },
      webglParams = { glParamValues: [] },
      webRTC,
      chromeExtensions = [],
      userChromeExtensions = [],
    } = profileInfo;

    this.props.UpdateProfile({
      audioContext,
      browserType,
      canvas,
      dns,
      extensions,
      fonts,
      geolocation: {
        ...this.props.profileInfo.geolocation,
        ...geolocation,
      },
      googleServicesEnabled,
      bookmarks,
      isBookmarksSynced,
      autoLang,
      lockEnabled,
      mediaDevices,
      name,
      navigator,
      notes,
      os,
      osSpec,
      isM1,
      plugins,
      proxy,
      proxyEnabled,
      startUrl,
      storage: {
        ...storage,
        indexedDb: storage.indexedDb ?? true,
      },
      timezone,
      webGL,
      webGLMetadata,
      webglParams,
      clientRects,
      devicePixelRatio,
      webRTC,
      chromeExtensions,
      userChromeExtensions,
    });

    this.setState({
      disabledSaveButton: false,
      defaultLanguages: this.props.profileInfo.navigator.language,
      isLoadingProfileData: false,
    });
  };

  private updateProfileName = (e: any) => {
    this.props.UpdateProfile({
      name: e.target.value,
    });

    this.setUpdatedField('name');
  };

  private getWebRtcMode = (enabled: boolean): string => {
    if (enabled) {
      return 'based on ip';
    }

    return 'off';
  };

  private saveProfile = async (): Promise<void> => {
    const proxyDataForValidation = this.props.profileInfo.proxy;
    const selectedProxyMode = this.props.profileInfo.proxy.mode;
    const isNeedValidationProxy =
      ['http', 'socks4', 'socks5'].includes(this.props.profileInfo.proxy.mode?.toLowerCase()) &&
      proxyDataForValidation.host &&
      proxyDataForValidation.port;

    if (isNeedValidationProxy) {
      const isProxyValid = isProxyDataValid({
        host: proxyDataForValidation.host,
        port: proxyDataForValidation.port,
      });

      if (!isProxyValid) {
        return;
      }
    }

    const profileId = this.getProfileIdFromLocation();

    this.setState({
      loadingSaveButton: true,
    });

    if (this.state.isGeolocationScreen && !this.props.profileInfo.proxy.host) {
      return;
    }

    const { name = '' } = this.props.profileInfo;
    if (!name.trim()) {
      await this.props.UpdateProfile({
        name: oldName,
      });
    }

    if (!(['none', 'geolocation', 'gologin', 'tor'].includes(this.props.profileInfo.proxy.mode) || this.props.profileInfo.proxy.host)) {
      await this.props.UpdateProfile({
        proxyEnabled: false,
        proxy: {
          autoProxyRegion: 'us',
          host: '',
          mode: 'none',
          password: '',
          port: 80,
          torProxyRegion: 'us',
          username: '',
        },
      });
    }

    if (!this.props.profileInfo.navigator.language.trim()) {
      await this.props.UpdateProfile({
        navigator: {
          ...this.props.profileInfo.navigator,
          language: this.state.defaultLanguages,
        },
      });
    }

    if (this.props.profileInfo.proxy.mode === 'geolocation') {
      await this.props.UpdateProfile({
        proxy: {
          ...this.props.profileInfo.proxy,
          changeIpUrl: '',
        },
      });
    }

    // Sending analytics at updatedFields
    sendAnalytics(profileId, updatedFields);

    let proxyList = getProxyList();

    // TODO: user = '' руинит запрос на [PUT] /browser/:id
    // лечится перезаходом в апп. Пока убираем руками,
    // поле добавляли Жора или Никита, фикс согласовывался с ними
    if (this.props.profileInfo.proxy.user === '') {
      const proxyData = {
        ...this.props.profileInfo.proxy,
      };

      delete proxyData.user;

      await this.props.UpdateProfile({
        proxy: proxyData,
      });
    }

    const profileToUpdate: IProfile = { ...this.props.profileInfo };
    const profileProxyId = profileToUpdate.proxy?.id;
    const currentProxyInList = proxyList.find(listedProxy => listedProxy.id === profileProxyId);
    // без проверки currentProxyInList.mode === selectedProxyMode
    // протокол сразу меняется на http в локальных данных о прокси
    // это мешает корректной подстановке протокола при повтоной вставке прокси
    // выбранный юзером протокол записывается в selectedProxyMode в начале этой фукнции
    if (profileProxyId && currentProxyInList && currentProxyInList.mode === selectedProxyMode) {
      updateProxyInListById(profileProxyId, profileToUpdate.proxy);
      proxyList = getProxyList();
    }

    let newProfileData: IProfile|null;
    try {
      newProfileData = await updateProfile({
        profileId,
        profileData: profileToUpdate,
        updateNoises: this.state.updateNoises,
        shouldEditProxyInPlace: !!currentProxyInList,
      });

      if (!newProfileData) {
        return;
      }

      const newProfileProxyId = newProfileData.proxy?.id;
      const { user: proxyUserId = '' } = newProfileData.proxy || {};
      const { userId } = this.props;
      const previousProfileProxy = getProfileSettingsPreviousProxy();
      if (previousProfileProxy && !determineIsTorOrFreeProxy(previousProfileProxy)) {
        decrementProxyProfilesCount(previousProfileProxy.id);
      }

      let resProxy: IProxy|null = null;
      const proxyInList = proxyList.find(listedProxy => listedProxy.id === newProfileProxyId) || null;
      if (proxyInList) {
        resProxy = proxyInList;
        if (!determineIsTorOrFreeProxy(resProxy)) {
          incrementProxyProfilesCount(resProxy.id);
        }
      } else if (newProfileProxyId && (!proxyUserId || userId === proxyUserId)) {
        const { proxy: newProfileDataProxy } = newProfileData;
        const newProxyList = [newProfileDataProxy, ...proxyList];
        resProxy = newProfileDataProxy;
        updateProxyList(newProxyList);
      }

      if (resProxy && newProfileData && ![resProxy, newProfileData.proxy].every(determineIsTorOrFreeProxy)) {
        setTimeout(async (): Promise<void> => {
          addProxyStatuses([resProxy], [newProfileData.id]);
          updateProxyItem(resProxy);

          if (resProxy.checkDate && changeableProtocols.includes(selectedProxyMode)) {
            resProxy.mode = selectedProxyMode;
          }

          const statusParams = await getProxyStatusParams(resProxy, selectedProxyMode);
          const newProxyData: IProxy = {
            ...resProxy,
            ...statusParams,
            id: resProxy.id,
            checkDate: moment().toDate(),
          };

          resetProxyStatuses();
          updateProxyItem(newProxyData);

          const checkParams = {
            id: resProxy.id,
            status: statusParams.status,
            country: statusParams.country || resProxy.country,
            city: statusParams.city || resProxy.city,
            error: statusParams.error || '',
            checkDate: moment().unix(),
            lastIp: statusParams.origin,
            mode: statusParams.mode,
          };

          if (newProfileData) {
            updateProxyAndProfileProxyData({
              currentProxyData: resProxy,
              newProxyData,
              statusParams,
              profileId,
            });
          }

          await setProxyStatuses([checkParams]).catch(() => null);
        }, 0);
      }

      const profileInfo = { ...profileToUpdate };
      delete profileInfo.webglParams;

      editProfilesListFields([profileId], profileInfo);
    } finally {
      this.setState({ loadingSaveButton: false });
    }

    // Setting default values for state
    await this.props.UpdateProfile(initialState);
    this.props.history.push('/');
  };

  public render(): JSX.Element {
    const { disabledSaveButton } = this.state;
    const { loadingSaveButton } = this.state;

    return (
      <ContainerDashboard>
        <Header />
        <BodyContainer>
          <ContainerContent>
            <ColumnsContainer>
              <LeftColumnContainer>
                <PageTitle>
                  {this.props.t('editProfile.title')}
                </PageTitle>
                <ContentBrowserName>
                  <Subtitle>
                    {this.props.t('editProfile.profileNameLabel')}
                  </Subtitle>
                  <Input
                    size='small'
                    style={{ height: 32 }}
                    value={this.props.profileInfo.name}
                    onChange={this.updateProfileName}
                    disabled={this.state.disabledSaveButton}
                  />
                </ContentBrowserName>
                <ContentTabs>
                  <Tabs
                    defaultActiveKey='2'
                    size='small'
                    onTabClick={(key: any) => {
                      if (key === '1') {
                        this.ShowButtonFingerprint();
                      } else {
                        this.CloseButtonFingerprint();
                      }
                    }}
                  >
                    <Tabs.TabPane key='1' tab={this.props.t('base.overview')}>
                      <templatesContext.Consumer>
                        {templatesCtx => (
                          <OverviewTab
                            setUpdatedField={this.setUpdatedField}
                            profileInfo={this.props.profileInfo}
                            updateProfileInfo={this.props.UpdateProfile}
                            updateFingerprint={() => this.updateFingerprint(templatesCtx)}
                          />
                        )}
                      </templatesContext.Consumer>
                    </Tabs.TabPane>
                    <Tabs.TabPane key='2' tab={this.props.t('proxy.title')}>
                      <ProxyTab
                        showButton={{
                          buttonProxy: this.state.GetProxyAnswer,
                          changeButton: this.changeButton.bind(this),
                          status: this.state.isLoadingProfileData,
                        }}
                        profileInfo={this.props.profileInfo}
                        profileId={this.getProfileIdFromLocation()}
                        updateProfileInfo={this.props.UpdateProfile}
                        setUpdatedField={this.setUpdatedField}
                        isSaveButtonPressed={this.state.loadingSaveButton}
                        setIsGeolocationScreen={this.setIsGeolocationScreen}
                        saveProfile={this.saveProfile}
                        isCreateProfile={false}
                        hasLoaded={!this.state.isLoadingProfileData}
                      />
                    </Tabs.TabPane>
                    <Tabs.TabPane key='3' tab={this.props.t('base.timezone')}>
                      <TimezoneTab
                        profileInfo={this.props.profileInfo}
                        updateProfileInfo={this.props.UpdateProfile}
                        setUpdatedField={this.setUpdatedField}
                      />
                    </Tabs.TabPane>
                    <Tabs.TabPane key='4' disabled={this.state.disabledSaveButton} tab={this.props.t('base.chromeExtensions')}>
                      <ExtensionsTab
                        updateProfileInfo={this.props.UpdateProfile}
                        setUpdatedField={this.setUpdatedField}
                        profileInfo={this.props.profileInfo}
                        isSaveButtonPressed={this.state.loadingSaveButton}
                      />
                    </Tabs.TabPane>
                    <Tabs.TabPane key='5' tab={this.props.t('personalArea.defaultProfile.bookmarks')}>
                      <BookmarksTab
                        profileInfo={this.props.profileInfo}
                        updateProfileInfo={this.props.UpdateProfile}
                        setUpdatedField={this.setUpdatedField}
                      />
                    </Tabs.TabPane>
                    <Tabs.TabPane key='6' tab={this.props.t('base.webrtc')}>
                      <WebRTCTab
                        profileInfo={this.props.profileInfo}
                        updateProfileInfo={this.props.UpdateProfile}
                        setUpdatedField={this.setUpdatedField}
                      />
                    </Tabs.TabPane>
                    <Tabs.TabPane key='7' tab={this.props.t('geolocation.title')}>
                      <GeolocationTab
                        profileInfo={this.props.profileInfo}
                        updateProfileInfo={this.props.UpdateProfile}
                        setUpdatedField={this.setUpdatedField}
                      />
                    </Tabs.TabPane>
                    <Tabs.TabPane key='8' tab={this.props.t('base.advanced')}>
                      <AdvancedTab
                        profileInfo={this.props.profileInfo}
                        updateProfileInfo={this.props.UpdateProfile}
                        setUpdatedField={this.setUpdatedField}
                        updateVendorsList={this.updateVendorsList}
                        updateRenderersList={this.updateRenderersList}
                        vendors={this.state.vendors}
                        renderers={this.state.renderers}
                      />
                    </Tabs.TabPane>
                  </Tabs>
                </ContentTabs>
                <ButtonDiv>
                  <SaveButton
                    type='primary'
                    onClick={this.saveProfile}
                    disabled={disabledSaveButton}
                    loading={loadingSaveButton}
                  >
                    {this.props.t('base.save')}
                  </SaveButton>
                  <CancelButton
                    onClick={(): void => {
                      this.props.history.push('/');
                    }}
                  >
                    {this.props.t('base.cancel')}
                  </CancelButton>
                </ButtonDiv>
              </LeftColumnContainer>
              <RightColumnContainer>
                <Subtitle>
                  {this.props.t('base.profileSummary')}
                </Subtitle>
                <ProfileSummaryContainer>
                  <ProfileSummaryContent>
                    <span style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis',overflow: 'hidden' }}>
                      {this.props.t('base.profileName')}
                      :
                      <GreenText>
                        {this.props.profileInfo.name}
                      </GreenText>
                    </span>
                    <span>
                      {this.props.t('proxy.title')}
                      :
                      <GreenText>
                        {this.props.profileInfo.proxy.mode}
                      </GreenText>
                    </span>
                    <span>
                      {this.props.t('editProfile.profileSummary.browser')}
                      :
                      <GreenText>
                        {this.props.profileInfo.browserType}
                      </GreenText>
                    </span>
                    <span>
                      {this.props.t('base.os')}
                      ::
                      <GreenText>
                        {this.props.profileInfo.os}
                      </GreenText>
                    </span>
                    <span>
                      {this.props.t('base.useragent')}
                      :
                      <Tooltip
                        title={this.props.profileInfo.navigator.userAgent}
                      >
                        <GreenText>
                          {this.props.profileInfo.navigator.userAgent
                            .toString()
                            .slice(0, 8) + '...'}
                        </GreenText>
                      </Tooltip>
                    </span>
                    <span>
                      {this.props.t('base.resolution')}
                      :
                      <GreenText>
                        {this.props.profileInfo.navigator.resolution}
                      </GreenText>
                    </span>
                    <span>
                      {this.props.t('base.languages')}
                      :
                      <Tooltip
                        title={this.props.profileInfo.navigator.language}
                      >
                        <GreenText>
                          {this.props.profileInfo.navigator.language
                            .toString()
                            .slice(0, 10) + '...'}
                        </GreenText>
                      </Tooltip>
                    </span>
                    <span>
                      {this.props.t('base.platform')}
                      :
                      <GreenText>
                        {this.props.profileInfo.navigator.platform}
                      </GreenText>
                    </span>
                    <span>
                      {this.props.t('base.timezone')}
                      :
                      <GreenText>
                        {
                          this.props.profileInfo.timezone.fillBasedOnIp
                            ? 'based on ip'
                            : this.props.profileInfo.timezone.timezone.length < 15
                              ? this.props.profileInfo.timezone.timezone
                              : <Tooltip title={this.props.profileInfo.timezone.timezone}>
                                {' '}
                                {this.props.profileInfo.timezone.timezone.slice(0, 15)}
                                ...
                              </Tooltip>
                        }
                      </GreenText>
                    </span>
                    <span>
                      {this.props.t('geolocation.title')}
                      :
                      <GreenText>
                        {this.props.profileInfo.geolocation.mode}
                      </GreenText>
                    </span>
                    <span>
                      {this.props.t('base.webrtc')}
                      :
                      <GreenText>
                        {this.getWebRtcMode(this.props.profileInfo.webRTC.enable)}
                      </GreenText>
                    </span>
                    <span>
                      {this.props.t('canvas.title')}
                      :
                      <GreenText>
                        {this.props.profileInfo.canvas.mode}
                      </GreenText>
                    </span>
                    <span>
                      {this.props.t('clientRects.title')}
                      :
                      <GreenText>
                        {this.props.profileInfo.clientRects.mode}
                      </GreenText>
                    </span>
                    <span>
                      {this.props.t('webgl.metadata.title')}
                      :
                      <GreenText>
                        {this.props.profileInfo.webGLMetadata.mode === 'off' ? 'real' : this.props.profileInfo.webGLMetadata.mode}
                      </GreenText>
                    </span>
                    <span>
                      {this.props.t('webgl.image.title')}
                      :
                      <GreenText>
                        {this.props.profileInfo.webGL.mode}
                      </GreenText>
                    </span>
                    <span>
                      {this.props.t('audioContext.title')}
                      :
                      <GreenText>
                        {this.props.profileInfo.audioContext.mode}
                      </GreenText>
                    </span>
                    <span>
                      {this.props.t('fonts.title')}
                      :
                      <GreenText>
                        {this.props.profileInfo.fonts.families.length}
                      </GreenText>
                    </span>
                    <span>
                      {this.props.t('mediaDevices.title')}
                      :
                      <Tooltip title='( videoIn | audioIn | audioOut )'>
                        <GreenText>
                          (
                          {this.props.profileInfo.mediaDevices.videoInputs}
                          |
                          {this.props.profileInfo.mediaDevices.audioInputs}
                          |
                          {this.props.profileInfo.mediaDevices.audioOutputs}
                          )
                        </GreenText>
                      </Tooltip>
                    </span>
                    <span>
                      {this.props.t('base.localStorage')}
                      :
                      <GreenText>
                        {this.props.profileInfo.storage.local.toString()}
                      </GreenText>
                    </span>
                    <span>
                      {this.props.t('editProfile.profileSummary.extStorage')}
                      :
                      <GreenText>
                        {this.props.profileInfo.storage.extensions.toString()}
                      </GreenText>
                    </span>
                    <span>
                      {this.props.t('base.plugins')}
                      :
                      <GreenText>
                        {this.props.profileInfo.plugins.enableVulnerable.toString()}
                      </GreenText>
                    </span>
                  </ProfileSummaryContent>
                </ProfileSummaryContainer>
              </RightColumnContainer>
            </ColumnsContainer>
          </ContainerContent>
        </BodyContainer>
        <Helmet>
          {/* @ts-ignore */}
          <title>
            Edit Browser Profile GoLogin&nbsp;
            {window.gologinAppVersion || ''}
          </title>
        </Helmet>
      </ContainerDashboard>
    );
  }
}

const mapStateToProps = (state: any) => ({
  profileInfo: state.updateProfile,
});

const mapDispatchToProps = {
  UpdateProfile,
};

const UpdateProfilePageClassComponent = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UpdateProfilePageClass)),
);

// eslint-disable-next-line react/no-multi-comp
export const UpdateProfilePage: React.FC = ()  => {
  const proxyList = useProxyList();
  const userCtx = useContext(userContext);
  const { _id: userId } = userCtx;

  return (
    <UpdateProfilePageClassComponent proxyList={proxyList} userId={userId} />
  );
};

export default UpdateProfilePage;
