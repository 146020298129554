import { IShareLink } from './interfaces';
import { API_BASE_URL } from '../../../../../../common/constants/constants';
import { ShareRole } from '../../../../../interfaces';
import { http } from '../../../../../services';

export const searchShareLinksForProfiles = async (profiles: { id: string }[]): Promise<IShareLink[]> =>
  http(`${API_BASE_URL}/share-links/profiles/search`, { method: 'POST', body: JSON.stringify({ profiles }) })
    .then(({ body }: { body: { links: IShareLink[] } }) => body.links).catch(() => []);

export const createProfileShareLinks = async (profiles: { id: string; name: string }[]): Promise<{ _id: string; url: string }> =>
  http(`${API_BASE_URL}/share-links/profiles`, { method: 'POST', body: JSON.stringify({ profiles }) })
    .then(({ body }: { body: { _id: string; url: string } }) => body).catch(() => ({ _id: '', url: '' }));

export const shareProfilesViaLink = async (profileName: string, linkId: string): Promise<string> =>
  http(`${API_BASE_URL}/share-links/profiles/share`, { method: 'POST', body: JSON.stringify({ profileName, linkId }) })
    .then(({ body }: { body: { id: string } }) => body.id);

interface IShareLinkUpdateParams {
  profileIds: string[];
  role?: ShareRole;
  paused?: boolean;
}

export const updateShareLink = async (id: string, { profileIds, role, paused }: IShareLinkUpdateParams): Promise<string> =>
  http(`${API_BASE_URL}/share-links/${id}/profiles`, { method: 'PATCH', body: JSON.stringify({ role, paused, profileIds }) })
    .then(({ body }: { body: { url: string; role: ShareRole } }) => body);

export const deleteShareLink = async (linkId: string, profileIds: string[]): Promise<void> =>
  http(`${API_BASE_URL}/share-links/${linkId}/profiles`, { method: 'DELETE', body: JSON.stringify({ profileIds }) })
    .then(() => null);
