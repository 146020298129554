import { PrimitiveAtom, useAtomValue } from 'jotai';
import React, { FC, memo, useCallback } from 'react';

import Notes from './notes';
import useDelayedMount from '../../../hooks/use-should-mount.hook';
import { IProfile } from '../../../interfaces';
import { callProfilesTableRowMeasurer } from '../../../state/profiles-table-row-measurers.atom';

interface INotesCellProps {
  profileAtom: PrimitiveAtom<IProfile>;
  saveProfileNotes: (profileId: string, notes: string) => void;
}

const NotesCell: FC<INotesCellProps> = (props) => {
  const { profileAtom, saveProfileNotes } = props;

  const profile = useAtomValue(profileAtom);
  const { id: profileId, notes, isAutoGenerated, defaultProps } = profile;
  const isDefaultNotes = !!(isAutoGenerated && defaultProps?.profileNotesIsDefault);
  const hasNotes = notes || isDefaultNotes;

  const onNotesChange = useCallback(() => callProfilesTableRowMeasurer(profileId), [profileId]);

  const { shouldMount } = useDelayedMount(!!hasNotes);
  if (!shouldMount) {
    return null;
  }

  return (
    <Notes
      profileId={profileId}
      notes={notes}
      saveProfileNotes={saveProfileNotes}
      isDefaultNotes={isDefaultNotes}
      location='profiles-table'
      onHeightPossibleChange={onNotesChange}
      isDisablingDragAndDrop={true}
    />
  );
};

export default memo(NotesCell);
