import { Atom, SetStateAction, WritableAtom, getDefaultStore } from 'jotai';

import { IProfile } from '../../interfaces';
import { GroupHeader } from '../../interfaces/group-header.interface';

export type LoaderRow = { id: 0; isLoaderRow: true };
export type BasicTableEntityContent = IProfile | GroupHeader | LoaderRow;
export interface IBasicTableEntity {
  idx: number;
  atom: Atom<BasicTableEntityContent>;
}

export interface IBasicTableProfile {
  idx: number;
  atom: WritableAtom<IProfile, [SetStateAction<IProfile>], void>;
}

export interface IBasicTableGroupHeader {
  idx: number;
  atom: Atom<GroupHeader>;
}

export interface IBasicTableLoaderRow {
  idx: number;
  atom: Atom<LoaderRow>;
}

export const isGroupHeader = (dataItem: BasicTableEntityContent): dataItem is GroupHeader => (dataItem as GroupHeader).isGroupHeader;
export const isLoaderRow = (dataItem: BasicTableEntityContent): dataItem is LoaderRow => (dataItem as LoaderRow).isLoaderRow;
export const getIsBasicEntityProfile = (entityItem: BasicTableEntityContent | null): entityItem is IProfile =>
  !!entityItem && !isGroupHeader(entityItem) && !isLoaderRow(entityItem);

export const isBasicTableEntityGroupHeader = (entity: IBasicTableEntity): entity is IBasicTableGroupHeader => {
  const dataObj = getDefaultStore().get(entity.atom);

  return isGroupHeader(dataObj);
};

export const isBasicTableEntityLoaderRow = (entity: IBasicTableEntity): entity is IBasicTableLoaderRow => {
  const dataObj = getDefaultStore().get(entity.atom);

  return isLoaderRow(dataObj);
};
