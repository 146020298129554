import { css, SerializedStyles } from '@emotion/core';

let hasWrapperBorderRadius = true;

export const changeBorderWrapper = (state: boolean): void => {
  hasWrapperBorderRadius = state;
};

export const borderRadiusContainer = (state = hasWrapperBorderRadius): SerializedStyles => css`
  border-radius: ${state ? '8px 8px 0 0' : '0'};
`;
