import { atom, getDefaultStore, useAtomValue } from 'jotai';

type AutomationModalState = {
  isVisible: false;
} | {
  isVisible: true;
  profileId?: string;
};

const automationModalAtom = atom<AutomationModalState>({ isVisible: false });

export const openAutomationModal = (profileId = ''): void => {
  getDefaultStore().set(automationModalAtom, { profileId, isVisible: true });
}

export const closeAutomationModal = (): void => {
  getDefaultStore().set(automationModalAtom, { isVisible: false });
}

export const useAutomationModal = () => useAtomValue(automationModalAtom);
