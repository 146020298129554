import { IMovedProfile } from './moved-profile.interface';
import { IProfile } from '../../interfaces';
import { CustomStatusGroupFilter, FolderGroupFilter } from '../../interfaces/group-header.interface';
import { getCurrentWorkspaceId } from '../../state/current-workspace-id.atom';
import { getAllProfilesFolderId } from '../../state/folders/all-profiles-folder-id.atom';
import { getFolderById, getFoldersList } from '../../state/folders/folders-list.atom';
import { getGroupHeaderById } from '../../state/profiles-list.atom';
import { getSelectedFolderId } from '../../state/selected-folder.atom';
import { getTagById } from '../../state/tags/tags-list.atom';
import { IManageProfilesInFolderParams, manageProfilesInWorkspaceFolder } from '../folders/api';
import { IDeleteProfilesTagReq, addTagRequest, deleteProfilesTag } from '../tags/api';
import { IAddTagMethod } from '../tags/interfaces/tag.interface';

interface IOnProfilesGroupChangeResult {
  newProfiles: IProfile[];
  updateTaskPromises: Promise<unknown>[];
}

export const onProfilesGroupChange = (profiles: IMovedProfile[], newGroupHeaderId: string): IOnProfilesGroupChangeResult => {
  const groupHeader = getGroupHeaderById(newGroupHeaderId);
  if (!groupHeader) {
    return { newProfiles: profiles, updateTaskPromises: [] };
  }

  const { filter: groupFilter } = groupHeader;
  switch (groupFilter.type) {
    case 'custom-status':
      return onProfileCustomStatusChange(profiles, groupFilter);
    case 'folder': {
      const folderIdsToKeep = [];
      const selectedFolderId = getSelectedFolderId();
      if (selectedFolderId && selectedFolderId !== getAllProfilesFolderId()) {
        folderIdsToKeep.push(selectedFolderId);
      }

      return onProfileFolderChange(profiles, groupFilter, folderIdsToKeep);
    }
    default:
      return { newProfiles: profiles, updateTaskPromises: [] };
  }
};

const onProfileCustomStatusChange = (profiles: IMovedProfile[], groupFilter: CustomStatusGroupFilter): IOnProfilesGroupChangeResult => {
  const newCustomStatusId = groupFilter.customStatusId;
  const profilesToEdit = profiles.filter((profile) => {
    const currCustomStatus = profile.tags.find((tag) => tag.field === 'custom-status');

    return newCustomStatusId !== currCustomStatus?.id;
  });

  const profileIdsToEdit = profilesToEdit.map((profile) => profile.id);
  if (!profileIdsToEdit.length) {
    return { newProfiles: profiles, updateTaskPromises: [] };
  }

  const updateTasksPromises = [];
  const workspaceId = getCurrentWorkspaceId();
  const newCustomStatus = getTagById(newCustomStatusId);
  if (newCustomStatus) {
    const requestOpts: IAddTagMethod = {
      browserIds: profileIdsToEdit,
      ...newCustomStatus,
      workspace: workspaceId,
    };

    updateTasksPromises.push(addTagRequest(requestOpts));
  } else {
    const requestsToDo = profiles.reduce<IDeleteProfilesTagReq[]>((acc, profile) => {
      const currCustomStatus = profile.tags.find((tag) => tag.field === 'custom-status');
      if (!currCustomStatus) {
        return acc;
      }

      const reqByTag = acc.find((req) => req.tagId === currCustomStatus.id);
      if (reqByTag) {
        reqByTag.profileIds.push(profile.id);

        return acc;
      }

      acc.push({ workspace: workspaceId, tagId: currCustomStatus.id, profileIds: [profile.id] });

      return acc;
    }, [] as IDeleteProfilesTagReq[]);

    updateTasksPromises.push(...requestsToDo.map((req) => deleteProfilesTag(req)));
  }

  const newProfiles = profiles.map((profile) => {
    if (!profileIdsToEdit.includes(profile.id)) {
      return profile;
    }

    const newTags = profile.tags.filter((tag) => tag.field !== 'custom-status');
    if (newCustomStatus) {
      newTags.push(newCustomStatus);
    }

    return { ...profile, tags: newTags };
  });

  return { newProfiles, updateTaskPromises: updateTasksPromises };
};

const onProfileFolderChange = (
  profiles: IMovedProfile[],
  groupFilter: FolderGroupFilter,
  folderIdsToKeep: string[],
): IOnProfilesGroupChangeResult => {
  const newFolderId = groupFilter.folderId;
  const newFolder = getFolderById(newFolderId);

  const allFolderIdsToKeep = [...folderIdsToKeep];
  if (newFolder && newFolderId) {
    allFolderIdsToKeep.push(newFolderId);
  }

  const profilesToEdit = profiles.filter((profile) => {
    const { movedFromGroupHeaderIds } = profile;
    const [firstGroupHeaderId] = profile.movedFromGroupHeaderIds;
    const wasInSameGroup = movedFromGroupHeaderIds.length === 1 && firstGroupHeaderId === newFolderId;

    return firstGroupHeaderId && !wasInSameGroup;
  });

  const profileIdsToEdit = [...new Set(profilesToEdit.map((profile) => profile.id))];
  if (!profileIdsToEdit.length) {
    return { newProfiles: profiles, updateTaskPromises: [] };
  }

  let profilesToAddFolderTo: IMovedProfile[] = [];
  if (newFolder) {
    profilesToAddFolderTo = profilesToEdit.filter((profile) => {
      const profileFolders = profile.folders || [];

      return !profileFolders.includes(newFolder.name);
    });
  }

  const profileIdsToAddFolderTo = profilesToAddFolderTo.map(({ id }) => id);

  const updateTasksPromises = [];
  const workspaceId = getCurrentWorkspaceId();
  if (newFolder) {
    const action = 'add';
    const folderName = newFolder.name;
    updateTasksPromises.push(manageProfilesInWorkspaceFolder({
      workspaceId,
      name: folderName,
      profiles: profileIdsToAddFolderTo,
      action,
    }));
  }

  const foldersList = getFoldersList();
  const requestsToDo = profileIdsToEdit.reduce<IManageProfilesInFolderParams[]>((acc, profileId) => {
    const profile = profilesToEdit.find(profileToEdit => profileToEdit.id === profileId);
    if (!profile) {
      return acc;
    }

    // if profile is duplicated between groups,
    // we can't know which one it was in, when user selected it
    // so we keep it in all folders
    // but if is "no folder" - we clear it
    if (newFolder && profile.movedFromGroupHeaderIds.length > 1) {
      return acc;
    }

    profile.movedFromGroupHeaderIds.forEach((movedFromGroupHeaderId) => {
      const relatedFolder = foldersList.find((folder) => folder.id === movedFromGroupHeaderId);
      if (!relatedFolder || allFolderIdsToKeep.includes(movedFromGroupHeaderId)) {
        return;
      }

      const { name: folderName } = relatedFolder;
      const reqByFolderName = acc.find((req) => req.name === folderName);
      if (reqByFolderName) {
        reqByFolderName.profiles.push(profile.id);

        return acc;
      }

      acc.push({ workspaceId, name: folderName, profiles: [profile.id], action: 'remove' });
    });

    return acc;
  }, []);

  updateTasksPromises.push(...requestsToDo.map((req) => manageProfilesInWorkspaceFolder(req)));

  const newProfiles = profiles.map((profileWithExtraField) => {
    // remove the moved groups as they are not relevant anymore
    const { movedFromGroupHeaderIds: _, ...profile } = profileWithExtraField;

    if (!profileIdsToEdit.includes(profile.id)) {
      return profile;
    }

    const folderNamesToRemove = requestsToDo.reduce<string[]>((acc, req) => {
      if (req.profiles.includes(profile.id)) {
        acc.push(req.name);
      }

      return acc;
    }, []);

    const profileFolders = profile.folders || [];
    const newFolders = profileFolders.filter((folderName) => !folderNamesToRemove.includes(folderName));
    if (newFolder && profileIdsToAddFolderTo.includes(profile.id)) {
      newFolders.push(newFolder.name);
    }

    return { ...profile, folders: newFolders };
  });

  return { newProfiles, updateTaskPromises: updateTasksPromises };
};
