import ReactGA from 'react-ga4';

import { requestPayments } from './features/personalArea/api';

const GA4_MEASUREMENT_ID = 'G-LQVGW7X8RP';

export const initGA4 = (): void => ReactGA.initialize(GA4_MEASUREMENT_ID, {
  testMode: false,
  gaOptions: {
    non_personalized_ads: 0,
  },
});

export const sendEventGA4 = (event: string, params: any): void => ReactGA.event(event, params);
export const sendGA4 = (options: any): void => ReactGA.send(options);

export const setCustomDimGA4 = (customDimentionsData: any): void => {
  const { userId, clientId } = customDimentionsData || {};
  ReactGA.gtag('set', 'user_properties', {
    clientId,
    userId,
  });
};

export const sendGA4PurchaseEvent = async (puchaseEvent: any): Promise<void> => {
  const {
    userId,
    amount,
    planId,
    planName,
    paymentId,
  } = puchaseEvent;

  const isProduction = window && window.location.hostname.includes('gologin.com');
  if (!isProduction) {
    return;
  }

  const userPayments = await requestPayments();
  const paymentBefore = userPayments.find((payment) => payment.amount > 0 && payment.id !== paymentId);
  if (paymentBefore) {
    return;
  }

  initGA4();
  ReactGA.ga((tracker: any) => {
    const clientId = tracker.get('clientId').toString();
    setCustomDimGA4({ userId, clientId });
  });

  sendEventGA4('purchase', {
    currency: 'USD',
    transaction_id: paymentId,
    value: amount / 100,
    user_id: userId,
    items: [
      {
        item_id: planId,
        item_name: planName,
        quantity: 1,
        item_category: 'gologin_plan',
        price: amount / 100,
      },
    ],
  });
};

