import css from '@emotion/css';
import styled from '@emotion/styled';

export const TurnstileCaptcha = styled.iframe<{ isFrameHidden: boolean }>`
  border: 0;

  ${(props): any => css`
    ${(props.isFrameHidden) ? `
      height: 0px;
      visibility: hidden;
    ` : `
      width: 300px;
      height: 70px;
      margin-top: 32px;
    `}
  `}
`;
