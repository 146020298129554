import { message } from 'antd';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddFolderButton, AddFolderContainer, CreateFolderInput } from './styles';
import { userContext, workspaceContext } from '../../../../state';
import IconFolderSimple from '../../../../ui/icons/IconFolderSimple';
import { createFolder } from '../../api';

export const AddFolderRow: React.FC = () => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [folderName, setFolderName] = useState<string>('');

  const { updateFolders: updateUserFolders } = useContext(userContext);
  const { id: workspaceId, folders: workspaceFolders, updateWorkspace } = useContext(workspaceContext);

  const { t: translation } = useTranslation();

  const onDone = async (): Promise<void> => {
    const folderNameTrim = folderName.trim().replace(/\s+/ig, ' ');

    if (!folderNameTrim) {
      setIsEditing(false);

      return;
    }

    if (workspaceFolders.find(folder => folder.name === folderNameTrim)) {
      message.error(translation('permissionSelectMenu.foldersTable.folderAlreadyExists'));
      setIsEditing(false);
      setFolderName('');

      return;
    }

    let newFolders = workspaceFolders.concat({
      id: folderName,
      name: folderName,
      permissions: {
        manageMember: true,
        manageAdminMember: true,
        addProfile: true,
        importProfile: true,
        multipleCreateProfiles: true,
        dropProfiles: true,
        canClaimProfiles: true,
      },
    });

    updateWorkspace({ folders: newFolders });
    updateUserFolders(newFolders);

    setIsEditing(false);
    setFolderName('');

    const createdFolder = await createFolder(workspaceId, folderName).catch(err => {
      console.error(err);
      newFolders = newFolders.filter(folder => folder.id !== folderName);
      updateWorkspace({ folders: newFolders });
      updateUserFolders(newFolders);

      return null;
    });

    if (createdFolder) {
      newFolders = newFolders.filter(folder => folder.id !== folderName).concat({ ...createdFolder });

      updateWorkspace({ folders: newFolders });
      updateUserFolders(newFolders);
    }
  };

  const onKeyPress = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter') {
      onDone();
    }
  };

  const renderAddFolder = (): JSX.Element => {
    if (isEditing) {
      return (
        <CreateFolderInput
          value={folderName}
          onChange={(event): void => setFolderName(event.target.value)}
          onKeyPress={onKeyPress}
          autoFocus={true}
          onBlur={onDone}
        />
      );
    }

    return (
      <AddFolderButton
        onClick={(): void => setIsEditing(true)}
      >
        {translation('permissionSelectMenu.foldersTable.addFolder')}
      </AddFolderButton>
    );
  };

  return (
    <AddFolderContainer>
      <IconFolderSimple />
      {renderAddFolder()}
    </AddFolderContainer>
  );
};
