import styled from '@emotion/styled';

export const ProxyTabCustomFormWrapper = styled.div`
  & > div {
    padding-top: 24px;
  }

  & > * + * {
    margin-top: 24px;
    border-top: 1px solid var(--E5E5E5-proxy-tab-border);
  }
`;

export const ProxyTabCustomFormTitle = styled.h2`
  margin: 0 0 24px 0;

  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: var(--36363D-proxy-tab-title);

  &.proxy-saved-selector-title {
    margin-bottom: 10px;
  }
`;

export const ProxyTabAddNewProxyFormWrapper = styled.div<{ isDisabled: boolean }>`
  opacity: ${(props): number => props.isDisabled ? 0.3 : 1};
`;
