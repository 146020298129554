import { atom, getDefaultStore, useAtomValue } from 'jotai';

export const visibleProxyGroupIdsAtom = atom<string[]>([]);

const getVisibleProxyGroupIds = (): string[] => getDefaultStore().get(visibleProxyGroupIdsAtom);
const setVisibleProxyGroupIds = (groupIds: string[]): void => getDefaultStore().set(visibleProxyGroupIdsAtom, groupIds);
export const useVisibleProxyGroupIds = (): string[] => useAtomValue(visibleProxyGroupIdsAtom);

export const hideProxyGroups = (): void => setVisibleProxyGroupIds([]);

export const toggleProxyGroup = (groupIdToToggle: string): void => {
  const proxyGroupsCurrentlyVisible = getVisibleProxyGroupIds();
  const proxyGroupsNewVisible = proxyGroupsCurrentlyVisible.includes(groupIdToToggle) ?
    proxyGroupsCurrentlyVisible.filter(groupId => groupId !== groupIdToToggle) :
    [...proxyGroupsCurrentlyVisible, groupIdToToggle];

  setVisibleProxyGroupIds(proxyGroupsNewVisible);
};
