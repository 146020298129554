import { Folder as FolderIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';

import { AddIcon, DoneIcon, FolderName, FolderNameContainer, FolderRow } from './styles';
import { handleFolderProfiles } from '../../../../../../state/folders/profiles-folders.operations';
import {
  FOLDERS_MANAGER_MODAL_NAME,
  useProfilesTableModalProfiles,
  useProfilesTableModalIsVisible,
} from '../../../../../../state/profiles-table-modal.atom';

type FolderItem = {
  name: string;
}

export const Folder: React.FC<FolderItem> = (props) => {
  const { name } = props;

  const isModalVisible = useProfilesTableModalIsVisible(FOLDERS_MANAGER_MODAL_NAME);
  const modalProfiles = useProfilesTableModalProfiles();
  const profileToGetFolders = modalProfiles[0]?.folders || [];

  const [foldersSelected , setFoldersSelected] = useState<string[]>([]);
  const [selectedProfileIds, setSelectedProfileIds] = useState<string[]>([]);

  const profileIds = modalProfiles.map(profile => profile.id);

  useEffect(() => {
    setSelectedProfileIds(profileIds);
    setFoldersSelected(profileToGetFolders);
  }, [isModalVisible]);

  const handleClick: React.MouseEventHandler = async () => {
    const action = foldersSelected.includes(name) ? 'remove' : 'add';
    handleFolderProfiles(name, selectedProfileIds, action);

    if (action === 'add') {
      setFoldersSelected([...new Set([...foldersSelected, name])]);

      return;
    }

    const newFolderSelected = foldersSelected.filter(folder => folder !== name);
    setFoldersSelected(newFolderSelected);
  };

  return (
    <FolderRow
      onClick={handleClick}
      selected={foldersSelected.includes(name)}
    >
      <FolderNameContainer>
        <FolderIcon />
        <FolderName>
          {name}
        </FolderName>
      </FolderNameContainer>
      {foldersSelected.includes(name) ? <DoneIcon /> : <AddIcon />}
    </FolderRow>
  );
};
