import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconBriefcase: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='briefcase-25 1'>
        <g id='Group'>
          <path id='Vector' d='M5.5 3.5V0.5H10.5V3.5' strokeLinecap='round' strokeLinejoin='round' />
          <path id='Vector_2' d='M6.5 9.5H0.5V4.5C0.5 4.23478 0.605357 3.98043 0.792893 3.79289C0.98043 3.60536 1.23478 3.5 1.5 3.5H14.5C14.7652 3.5 15.0196 3.60536 15.2071 3.79289C15.3946 3.98043 15.5 4.23478 15.5 4.5V9.5H9.5' strokeLinecap='round' strokeLinejoin='round' />
          <path id='Vector_3' d='M14.5 11.5V13.5C14.5 13.7652 14.3946 14.0196 14.2071 14.2071C14.0196 14.3946 13.7652 14.5 13.5 14.5H2.5C2.23478 14.5 1.98043 14.3946 1.79289 14.2071C1.60536 14.0196 1.5 13.7652 1.5 13.5V11.5' strokeLinecap='round' strokeLinejoin='round' />
          <path id='Vector_4' d='M9.5 7.5H6.5V11.5H9.5V7.5Z' strokeLinecap='round' strokeLinejoin='round' />
        </g>
      </g>
    </svg>
  </IconWrapper>
);
