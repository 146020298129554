import styled from '@emotion/styled';

export const DraggedProfilesContainer = styled('div')`
  background: var(--FFFFFF-profile-table);
  opacity: 0.6;
  padding: 14px;
  margin-left: 26px;

  cursor: grabbing;

  border: 1px solid var(--EBEBEB);
  border-radius: 8px;

  position: absolute;
  z-index: 1000;

  width: fit-content;

  font: 14px / 24px Roboto;
  font-weight: 500;
`;
