import { Menu } from 'antd';
import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { MenuKey } from './menu-key';
import { workspaceContext } from '../../../state';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { MenuTitle, SubmenuTitle } from '../styles';
import { useUserBasicInfo } from '../../../state/user/user-basic-info.atom';

const isElectron = !!window.require;

declare interface IPersonalAreaMenu {
  selectedKey: MenuKey;
}

const PersonalAreaMenu: FC<IPersonalAreaMenu> = (props) => {
  const { selectedKey } = props;

  const { permissions } = useContext(workspaceContext);

  const { email } = useUserBasicInfo();

  const { t: translation } = useTranslation();

  const menuMarginTop = NEW_FEATURES.workspaces ? 40 : 0;

  return (
    <>
      <Menu
        mode='inline'
        style={{ width: 240, marginTop: menuMarginTop }}
        defaultSelectedKeys={[selectedKey]}
      >
        <MenuTitle>
          {email || 'Account'}
        </MenuTitle>
        <Menu.Item data-sentry-unmask key='overview'>
          <Link to='/personalArea'>
            {translation('personalArea.overview.title')}
          </Link>
        </Menu.Item>
        <Menu.Item data-sentry-unmask key='default-profile-settings'>
          <Link to='/personalArea/default-profile-settings'>
            {translation('personalArea.defaultProfile.title')}
          </Link>
        </Menu.Item>
        <Menu.Item data-sentry-unmask key='two-factor-auth'>
          <Link to='/personalArea/2fa'>
            {translation('personalArea.twoFA.title')}
          </Link>
        </Menu.Item>
        <Menu.Item data-sentry-unmask key='affiliate-program'>
          <Link to='/personalArea/AffiliateProgram'>
            {translation('personalArea.affiliateProgram.title')}
          </Link>
        </Menu.Item>
        <Menu.Item data-sentry-unmask key='active-sessions'>
          <Link to='/personalArea/ActiveSessions'>
            {translation('personalArea.activeSessions.title')}
          </Link>
        </Menu.Item>
        <Menu.Item data-sentry-unmask key='token-api'>
          <Link to='/personalArea/TokenApi'>
            {translation('personalArea.api.title')}
          </Link>
        </Menu.Item>
        {isElectron ? (
          <Menu.Item data-sentry-unmask key='cache'>
            <Link to='/personalArea/Cache'>
              {translation('personalArea.cache.title')}
            </Link>
          </Menu.Item>
        ) : null}
        <Menu.Item data-sentry-unmask key='password-manager'>
          <Link to='/personalArea/PasswordManager'>
            {translation('personalArea.vault.title')}
          </Link>
        </Menu.Item>
        <SubmenuTitle data-sentry-unmask>
          {translation('personalArea.workspace.title')}
        </SubmenuTitle>
        <Menu.Item data-sentry-unmask key='members'>
          <Link to='/members'>
            {translation('personalArea.workspaceSetting.title')}
          </Link>
        </Menu.Item>
        <Menu.Item data-sentry-unmask key='billing'>
          <Link to='/personalArea/Billing'>
            {translation('personalArea.billing.title')}
          </Link>
        </Menu.Item>
        <Menu.Item data-sentry-unmask disabled={!permissions.viewDeletedProfiles} key='restore-profiles'>
          <Link to='/personalArea/restore-profiles'>
            {translation('personalArea.restoreProfiles.title')}
          </Link>
        </Menu.Item>
      </Menu>
    </>
  );
};

export default PersonalAreaMenu;
