import React, { useContext, useState } from 'react';

import { ItemRowActive, PriceText } from './styles';
import { GeoProxyType } from '../../../../../common/constants/types';
import { IGeolocationProxyPrices } from '../../../../interfaces';
import { workspaceContext } from '../../../../state';
import { NEW_FEATURES } from '../../../../state/feature-toggle/new-features';
import { openQuickPricing } from '../../../../state/quick-pricing.atom';
import ProxyPaymentWaySelector from '../proxy-payment-way-selector';

type MenuItemsProps = {
  price: IGeolocationProxyPrices;
  geoProxyType: GeoProxyType;
  count: number;
  onClose: () => void;
}

const PriceItem: React.FC<MenuItemsProps> = (props) => {
  const { price, geoProxyType, count, onClose } = props;

  const [isPaymentWaySelectorVisible, setIsPaymentWaySelectorVisible] = useState<boolean>(false);
  const [selectorTarget, setSelectorTarget] = useState<HTMLElement|null>(null);

  const { paymentIsTrial = true } = useContext(workspaceContext);

  const calculatePrice = (): string => {
    const proxyPrice = price[geoProxyType];

    return (proxyPrice * count / 100).toFixed(0);
  };

  const handleMenuClick = async (event: React.MouseEvent<HTMLElement>): Promise<void> => {
    if (NEW_FEATURES.pricingGeoProxyTraffic && paymentIsTrial) {
      return openQuickPricing();
    }

    setIsPaymentWaySelectorVisible(true);
    setSelectorTarget(event.currentTarget);
  };

  const onCloseSelector = (): void => {
    onClose();
    setIsPaymentWaySelectorVisible(false);
    setSelectorTarget(null);
  };

  return (
    <ItemRowActive onClick={handleMenuClick}>
      {count}
      &nbsp;GB
      <PriceText>
        $
        {calculatePrice()}
      </PriceText>
      {isPaymentWaySelectorVisible ?
        <ProxyPaymentWaySelector
          rootAnchorEl={selectorTarget}
          onClose={onCloseSelector}
          count={count}
          geoProxyType={geoProxyType}
        /> : null}
    </ItemRowActive>
  );
};

export default PriceItem;

