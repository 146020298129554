import { Icon } from 'antd';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CloseIconContainer,
  IconSharing,
  IconUser,
  MenuPopup,
  OptionItem,
  Row,
  RowSpaceBetween,
  TextEmail,
  TextSharing,
  UserContainer,
  UserIconContainer,
} from './styles';
import { removeFolderInvite, updateInviteFolderRole } from '../api';
import { useUserBasicInfo } from '../../../state/user/user-basic-info.atom';

type UserRow = {
  inviteId: string;
  email: string;
  role: 'administrator'|'redactor'|'guest';
  getInvites: () => void;
  changeInvitesLoading: (state: boolean) => void;
}

export const UserRow: FC<UserRow> = (props) => {
  const { changeInvitesLoading, getInvites, role, email, inviteId } = props;

  const [anchorElChangeRole, setAnchorElChangeRole] = useState<HTMLElement|null>(null);
  const [GetRole, changeGetRole] = useState(role);

  const { t: translation } = useTranslation();

  const { email: userEmail } = useUserBasicInfo();

  const changeUserRole = async (id: string, changedRole: 'administrator'|'redactor'|'guest'): Promise<void> => updateInviteFolderRole(id, changedRole)
    .finally(() => changeInvitesLoading(false));

  const openPopupChangeRole = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => setAnchorElChangeRole(event.currentTarget);

  const closePopupChangeRole = (): void => setAnchorElChangeRole(null);

  const removeInvite = async (id: string): Promise<void> => {
    changeInvitesLoading(true);
    await removeFolderInvite(id)
      .finally(() => changeInvitesLoading(false));
    getInvites();
  };

  const showIcon = (): JSX.Element|null => {
    if(role === 'owner'){
      return null;
    }

    return <IconSharing type="down" style={{ paddingTop:2 }} />;
  };

  const isDisable = role === 'owner' || email === userEmail;

  const changeInviteText = (): string => {
    if (GetRole === 'guest') {
      return translation('roles.profile.guest.subtitle');
    }

    if (GetRole === 'owner') {
      return translation('roles.profile.owner.subtitle');
    }

    if (GetRole === 'redactor') {
      return translation('roles.profile.editor.subtitle');
    }

    return translation('roles.profile.administrator.subtitle');
  };

  return (
    <UserContainer>
      <RowSpaceBetween>
        <Row>
          <UserIconContainer>
            <IconUser type='user' />
          </UserIconContainer>
          <TextEmail>
            {email}
          </TextEmail>
        </Row>
        <div onClick={openPopupChangeRole} style={{ cursor: isDisable ? 'not-allowed' : 'pointer', display: 'flex' }}>
          <TextSharing style={{ marginLeft: 15, marginTop: 2 }}>
            {changeInviteText()}
          </TextSharing>
          {showIcon()}
        </div>
        <CloseIconContainer>
          {(role !== 'owner' && email !== userEmail) && <Icon type='delete' onClick={(): Promise<void> => removeInvite(inviteId)} />}
        </CloseIconContainer>
      </RowSpaceBetween>
      <MenuPopup
        open={!!anchorElChangeRole}
        anchorEl={anchorElChangeRole}
        onClose={closePopupChangeRole}
        transitionDuration={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        style={{ zIndex: 1150 }}
      >
        <OptionItem
          onClick={(): void => {
            changeGetRole('guest');
            changeUserRole(inviteId, 'guest').catch(() => {
              changeGetRole(GetRole);
            });
          }}
          key='guest'
        >
          {translation('roles.profile.guest.title')}
        </OptionItem >
        <OptionItem
          onClick={(): void => {
            changeGetRole('redactor');
            changeUserRole(inviteId, 'redactor').catch(() => {
              changeGetRole(GetRole);
            });
          }}
          key='redactor'
        >
          {translation('roles.profile.editor.title')}
        </OptionItem>
        <OptionItem
          onClick={(): void => {
            changeGetRole('administrator');
            changeUserRole(inviteId, 'administrator').catch(() => {
              changeGetRole(GetRole);
            });
          }}
          key='administrator'
        >
          {translation('roles.profile.administrator.title')}
        </OptionItem>
      </MenuPopup>
    </UserContainer>
  );
};
