import React from 'react';
import { useTranslation } from 'react-i18next';

import { history } from '../../../../../../services';
import IconFolder from '../../../../../../ui/icons/IconFolder';
import { CreateFolderBtn, CreateFolderContainer, CreateFolderTxt } from './styles';

const FolderCreate: React.FC = () => {
  const { t: translation } = useTranslation();
  const handleClick: React.MouseEventHandler = () => history.replace('/folders');

  return (
    <CreateFolderContainer>
      <IconFolder />
      <CreateFolderTxt>
        {translation('foldersManagerModal.foldersLink.help')}
      </CreateFolderTxt>
      <CreateFolderBtn onClick={handleClick}>
        {translation('foldersManagerModal.foldersLink.label')}
      </CreateFolderBtn>
    </CreateFolderContainer>
  );
};

export default FolderCreate;
