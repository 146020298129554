import styled from '@emotion/styled';
import React, { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import LoadableIconButton from './loadable-icon-button';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { ITemplatesCtx, templatesContext } from '../../../state';
import { IconPlus } from '../../../ui/gologin-header/icons';
import IconPlusInCircle from '../../../ui/icons/IconPlusInCircle';

interface IQuickCreateProfile {
  tooltipContent: JSX.Element;
  createProfile: (templateCtx: ITemplatesCtx) => Promise<void>;
}

const ButtonQuickCreateProfile: FC<IQuickCreateProfile> = (props) => {
  const { tooltipContent, createProfile } = props;

  const templateCtx = useContext(templatesContext);

  const renderIcon = (): JSX.Element => {
    if (NEW_FEATURES.header) {
      return (
        <IconPlus
          margin='-4px 0 0 0'
          styleType='activeGrayWithBackground'
          iconColor='var(--98989F-profile-table-create-quick-profile)'
          iconHoveredColor='var(--2B2B31-profile-table-create-quick-profile)'
        />
      );
    }

    return <IconPlusInCircle />;
  };

  return (
    <LoadableIconButton
      onClick={(): Promise<void> => createProfile(templateCtx)}
      icon={renderIcon()}
      tooltipContent={tooltipContent}
      style={{
        margin: NEW_FEATURES.header ? '-4px 5px 0 0' : '-4px 5px 0 11px',
        height: 21,
      }}
    />
  );
};

export default ButtonQuickCreateProfile;
