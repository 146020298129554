import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconExport: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_22721_42949)'>
        <path d='M3.5 5.5H2C1.60218 5.5 1.22064 5.65804 0.93934 5.93934C0.658035 6.22064 0.5 6.60218 0.5 7V10C0.5 10.3978 0.658035 10.7794 0.93934 11.0607C1.22064 11.342 1.60218 11.5 2 11.5H10C10.3978 11.5 10.7794 11.342 11.0607 11.0607C11.342 10.7794 11.5 10.3978 11.5 10V7C11.5 6.60218 11.342 6.22064 11.0607 5.93934C10.7794 5.65804 10.3978 5.5 10 5.5H8.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M3 3.5L6 0.5L9 3.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M6 8.5V0.5' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_22721_42949'>
          <rect width='12' height='12' fill='white' />
        </clipPath>
      </defs>
    </svg>
  </IconWrapper>
);
