import React, { FC, memo } from 'react';
import styled from '@emotion/styled';
import CircularProgress from '@material-ui/core/CircularProgress';

interface IStyledButton {
  loading?: boolean;
  width?: number;
}

export const StyledButton = styled.button<IStyledButton>`
  height: 56px;
  padding: 17px 30px;
  margin: 32px auto 0 auto;
  border-radius: 60px;
  border: none;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Graphik LC', Roboto;
  font-weight: 400;
  font-size: 18px;
  line-height: 21.6px;
  color: var(--000000-auth-pages-button);

  background: var(--FFE164-auth-pages-button);
  outline: none;
  cursor: pointer;
  transition: all linear .3s;
  
  width: ${props => `${props.width}px` || 'auto'};

  ${(props) => {
    if (!props.loading) {
      return `
        :hover {
          color: var(--FFE164-auth-pages-button);
          background: var(--000000-auth-pages-button);
        }
      `;
    }
  }}
`;

interface ISubmitButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  width?: number;
}
const SButton: FC<ISubmitButton> = (props) => {

  if (props.loading) {
    return (
      <StyledButton {...props}>
        <CircularProgress value={99} disableShrink={true} size={14} color='inherit' />
      </StyledButton>
    );
  }

  return (
    <StyledButton {...props}>{props.children}</StyledButton>
  );
}

export const SubmitButton = memo(SButton);
