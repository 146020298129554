import styled from '@emotion/styled/macro';

export const HiddenMask = styled('div')`
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 900;
`;

export const Mask = styled(HiddenMask)`
  top: 0;
  left: 0;
  background-color: var(--F9F9F9);
  padding: 24px;
`;

export const Container = styled('div')`
  border-radius: 8px;
  border: 1px dashed var(--98989F);
  box-shadow: rgb(0 0 0 0.1) 0 4px 4px 0;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  pointer-events: none;
  width: 100%;
  height: 100%;
`;

export const Text = styled('div')`
  color: var(--98989F);
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.44px;
`;
