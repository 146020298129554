import { useState } from 'react';

import { IAutomationCtx, AUTOMATION_DEFAULT_VALUE } from '../state/automation/automation.context';

export const useAutomation = (): IAutomationCtx => {
  const [profilesSelected, setProfilesSelected] = useState<IAutomationCtx['profilesSelected']>(AUTOMATION_DEFAULT_VALUE.profilesSelected);
  const [searchString, setSearchString] = useState<string>('');
  const [isAutomationModalVisible, setIsAutomationModalVisible] =
    useState<IAutomationCtx['isAutomationModalVisible']>(AUTOMATION_DEFAULT_VALUE.isAutomationModalVisible);

  return {
    profilesSelected,
    setProfilesSelected,
    searchString,
    setSearchString,
    isAutomationModalVisible,
    setIsAutomationModalVisible,
  };
};
