import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { IWorkspaceFolderRole, LimitedWorkspaceRole } from '../../../interfaces/workspaces';
import { workspaceContext } from '../../../state';
import LabeledElement from '../../../ui/LabeledElement';
import { SelectRole } from '../../../utils/roles-texts';
import RoleSelect from '../role-select';
import FoldersRoleTable, { IFolderRole } from './folders-role-table';
import LimitedAccessRadio from './limited-access-radio';

interface IPermissionsSelect {
  limitedAccess: boolean;
  role: LimitedWorkspaceRole;
  folders: IWorkspaceFolderRole[];
  isCurrentMemberGlobalAdmin: boolean;
  onChange: (limitedAccess: boolean, role: LimitedWorkspaceRole, folders: IWorkspaceFolderRole[]) => void;
  isRadioExtraTextShown?: boolean;
  showRemoveMember?: boolean;
  onRemoveMember?: () => void;
  blockWorkspaceWideAdminSelect?: boolean;
}

const PermissionsSelect: FC<IPermissionsSelect> = (props) => {
  const {
    limitedAccess, role, folders, isCurrentMemberGlobalAdmin, onChange,
    isRadioExtraTextShown, showRemoveMember, onRemoveMember, blockWorkspaceWideAdminSelect,
  } = props;

  const { folders: workspaceFolders, permissions: workspacePermissions } = useContext(workspaceContext);

  const { t: translation } = useTranslation();

  let manageableFolders = workspaceFolders;
  if (!isCurrentMemberGlobalAdmin) {
    manageableFolders = workspaceFolders.filter(folder => folder.permissions.manageMember);
  }

  const preparedFolders = manageableFolders
    .map<IFolderRole>(manageableFolder => {
      const foundFolder = folders.find(folder => manageableFolder.name === folder.name);
      let folderRole: IFolderRole['role'] = 'unset';
      if (foundFolder?.role === 'owner') {
        folderRole = 'admin';
      } else if (foundFolder?.role) {
        folderRole = foundFolder.role;
      }

      return {
        name: manageableFolder.name,
        role: folderRole,
      };
    });

  const onFolderRolesUpdate = (changedFolders: IFolderRole[]): void => {
    const addedFolders: IWorkspaceFolderRole[] = changedFolders
      .filter(changedFolder => !folders.find(folder => changedFolder.name === folder.name) && changedFolder.role !== 'unset')
      .map<IWorkspaceFolderRole>(folder => ({
        name: folder.name,
        role: folder.role === 'unset' ? 'guest' : folder.role, // just to calm down TypeScript
        permissions: {
          manageAccess: true,
          // TODO: pass this from backend somehow
          manageAdminAccess: isCurrentMemberGlobalAdmin,
        },
      }));

    const newFolders: IWorkspaceFolderRole[] = folders
      // edit existing roles of folders and delete unset ones
      .reduce<IWorkspaceFolderRole[]>((acc, folder) => {
        const currChangedFolder = changedFolders.find(changedFolder => changedFolder.name === folder.name);
        if (!currChangedFolder) {
          acc.push(folder);

          return acc;
        }

        if (currChangedFolder.role !== 'unset') {
          acc.push({ ...folder, role: currChangedFolder.role });
        }

        return acc;
      }, [])
      .concat(addedFolders);

    onChange(limitedAccess, role, newFolders);
  };

  const onToggle = (val: boolean): void => {
    const changedFolders = val ? folders : [];
    onChange(val, role, changedFolders);
  };

  const showPermissionsEdit = (): JSX.Element => {
    if (limitedAccess) {
      return (
        <FoldersRoleTable
          folders={preparedFolders}
          onChange={onFolderRolesUpdate}
          canCreateFolder={workspacePermissions.createFolder}
        />
      );
    }

    return (
      <LabeledElement title={translation('permissionSelectMenu.selectRole')}>
        <RoleSelect
          type='invite'
          role={role}
          setRole={(selectedRole): void => onChange(limitedAccess, selectedRole as SelectRole, folders)}
          showRemoveMember={showRemoveMember}
          onRemoveMember={onRemoveMember}
          blockAdminSelect={blockWorkspaceWideAdminSelect}
          width='264px'
        />
      </LabeledElement>
    );
  };

  return (
    <div style={{ width: 448 }}>
      <LimitedAccessRadio
        limitedAccess={limitedAccess}
        showGiveAccessText={!!isRadioExtraTextShown}
        onToggle={onToggle}
        canSwitchToWorkspace={isCurrentMemberGlobalAdmin}
        onRemoveMember={(showRemoveMember && onRemoveMember) || null}
      />
      {showPermissionsEdit()}
    </div>
  );
};

export default React.memo(PermissionsSelect);
