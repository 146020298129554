import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled';

import { textFont } from '../../../../ui/style-templates';
import { SAVED_PROXY_SELECTOR_CONTENT_HEIGHT } from '../constants';

export const SelectedProxyWrapper = styled.div`
  height: ${SAVED_PROXY_SELECTOR_CONTENT_HEIGHT};
  display: flex;
  align-items: center;
`;

export const ProxySavedSelectorPlaceholder = styled.span<{ isProxyManagerWithGroups: boolean }>`
  flex-grow: 1;
  display: flex;

  ${(props): SerializedStyles | null => props.isProxyManagerWithGroups ? null : css`
    margin-left: 8px;
  `}
  
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: ${SAVED_PROXY_SELECTOR_CONTENT_HEIGHT};
  letter-spacing: 0.2px;
`;

export const ProxySavedSelectorProxy = styled.div`
  width: 100%;
  height: ${SAVED_PROXY_SELECTOR_CONTENT_HEIGHT};
  
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const selectedProxyTitleEllipsis = css`
  max-width: 168px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SelectedProxyTitleWrapper = styled.span`
  height: ${SAVED_PROXY_SELECTOR_CONTENT_HEIGHT};

  ${textFont()}
  line-height: ${SAVED_PROXY_SELECTOR_CONTENT_HEIGHT};

  ${selectedProxyTitleEllipsis}
`;

export const ProxySavedSelectorUnselectButton = styled.div`
  cursor: pointer;
`;
