import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { useProfilesList } from './profiles-list.atom';
import { IProfile } from '../interfaces';
import PerformanceObserverService from '../services/performance-observer/performance-observer.service';
import { setAutomationSearch } from './automation/automation-search.atom';

export const DELETE_OR_LEAVE_PROFILE_MODAL_NAME = 'delete-or-leave-profile';
export const SHARE_MODAL_NAME = 'share-modal';
export const PROFILE_HISTORY_MODAL_NAME = 'profile-history';
export const UPDATE_PROFILE_MODAL_NAME = 'update-profile';
export const LOCALES_MODAL_NAME = 'locales-modal';
export const PROFILE_COOKIES_MODAL_NAME = 'profile-cookies-modal';
export const PROFILE_COOKIES_MANAGER_MODAL_NAME = 'profile-cookies-manager-modal';
export const TRANSFER_PROFILE_MODAL_NAME = 'transfer-profile-modal';
export const FOLDERS_MANAGER_MODAL_NAME = 'folders-manager-modal';
export const PROFILE_TABLE_COLUMNS_MODAL_NAME = 'profile-table-columns-modal-name';
export const PROFILE_EXTENSIONS_MODAL_NAME = 'profile-extensions-modal-name';

const MODAL_NAMES = <const>[
  DELETE_OR_LEAVE_PROFILE_MODAL_NAME,
  SHARE_MODAL_NAME,
  PROFILE_HISTORY_MODAL_NAME,
  UPDATE_PROFILE_MODAL_NAME,
  LOCALES_MODAL_NAME,
  PROFILE_COOKIES_MODAL_NAME,
  PROFILE_COOKIES_MANAGER_MODAL_NAME,
  TRANSFER_PROFILE_MODAL_NAME,
  FOLDERS_MANAGER_MODAL_NAME,
  PROFILE_TABLE_COLUMNS_MODAL_NAME,
  PROFILE_EXTENSIONS_MODAL_NAME,
];

type ProfilesTableModal = typeof MODAL_NAMES[number];

export type ModalUserAction = `open-${ProfilesTableModal}`;
export const MODAL_USER_ACTIONS = MODAL_NAMES.map<ModalUserAction>(name => `open-${name}`);

type ProfilesTableModalType = 'none'|ProfilesTableModal;

interface IProfilesTableModal {
  currentModal: ProfilesTableModalType;
  modalProfileIds: string[];
  isInCloseAnimation: boolean;
}

const defaultProfilesTableModal: IProfilesTableModal = {
  currentModal: 'none',
  modalProfileIds: [],
  isInCloseAnimation: false,
};

const profilesTableModalAtom = atom<IProfilesTableModal>(defaultProfilesTableModal);

export const useProfilesTableModalType = (): IProfilesTableModal['currentModal'] => useAtomValue(profilesTableModalAtom).currentModal;

export const useProfilesTableModalIsVisible = (modalType: ProfilesTableModalType): boolean => {
  const isModalActive = useProfilesTableModalType() === modalType;
  const isModalInCloseAnimation = useAtomValue(profilesTableModalAtom).isInCloseAnimation;

  return isModalActive && !isModalInCloseAnimation;
};

export const startClosingProfilesTableModal = (): void => {
  const profilesTableModal = getDefaultStore().get(profilesTableModalAtom);

  getDefaultStore().set(profilesTableModalAtom, { ...profilesTableModal, isInCloseAnimation: true });
};

export const closeProfilesTableModal = (): void => {
  getDefaultStore().set(profilesTableModalAtom, { currentModal: 'none', modalProfileIds: [], isInCloseAnimation: false });
};

export const openProfilesTableModal = (modalType: ProfilesTableModalType, modalProfileIds: string[] = []): void => {
  if (modalType !== 'none') {
    const performanceObserverService = PerformanceObserverService.getInstance();
    performanceObserverService.handleUserAction({ userAction: `open-${modalType}` });
  }

  getDefaultStore().set(profilesTableModalAtom, { currentModal: modalType, modalProfileIds, isInCloseAnimation: false });
};

const useProfilesTableModalProfileIds = (): IProfilesTableModal['modalProfileIds'] => useAtomValue(profilesTableModalAtom).modalProfileIds;

export const useProfilesTableModalProfiles = (): IProfile[] => {
  const profilesList = useProfilesList();
  const modalProfileIds = useProfilesTableModalProfileIds();

  return profilesList.filter(profile => modalProfileIds.includes(profile.id));
};

