import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconUpdate: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_19010_21965)'>
        <path d='M15.163 1.06836L15.212 6.40336L10.125 4.70136' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M15.4334 9.49971C15.1867 11.3371 14.2684 13.018 12.8555 14.2181C11.4426 15.4183 9.63526 16.0526 7.78222 15.9988C5.92918 15.945 4.16173 15.2068 2.82089 13.9266C1.48004 12.6465 0.660825 10.9151 0.521288 9.0665C0.381751 7.21793 0.931785 5.38319 2.06527 3.91626C3.19875 2.44933 4.83536 1.45416 6.65932 1.12278C8.48329 0.791389 10.3654 1.14726 11.9424 2.12173C13.5195 3.09619 14.6797 4.6202 15.1994 6.39971' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_19010_21965'>
          <rect width='16' height='16' fill='white' transform='translate(0 0.5)' />
        </clipPath>
      </defs>
    </svg>

  </IconWrapper>
);
