import { IBrowserIconsConstants, IBrowserIconsConstantsCommon } from '../interfaces';
import { BrowserBuild, OsForBrowserIcons } from '../types';
import { BROWSER_ICONS_CONSTANTS_FOR_MAC } from './constants-mac';
import { BROWSER_ICONS_CONSTANTS_FOR_WIN_10 } from './constants-win-10';
import { BROWSER_ICONS_CONSTANTS_FOR_WIN_11 } from './constants-win-11';

const CONSTANTS_COMMON: IBrowserIconsConstantsCommon = { textColor: '#FFFFFF', rectFillColor: '#222222' };

export const getIconsConstants = (os: OsForBrowserIcons): IBrowserIconsConstants => {
  let constantsForOs;
  switch (os) {
    case 'win10':
      constantsForOs = BROWSER_ICONS_CONSTANTS_FOR_WIN_10;
      break;
    case 'win11':
      constantsForOs = BROWSER_ICONS_CONSTANTS_FOR_WIN_11;
      break;
    default:
      constantsForOs = BROWSER_ICONS_CONSTANTS_FOR_MAC;
      break;
  }

  return { ...CONSTANTS_COMMON, ...constantsForOs };
};

interface IBrowserVersionMajorAndFull {
  major: number;
  full: BrowserBuild;
}

interface IBrowserVersionForGreenSquare {
  win10: IBrowserVersionMajorAndFull;
  win11: IBrowserVersionMajorAndFull;
  mac: IBrowserVersionMajorAndFull;
}

export const BROWSER_VERSION_FOR_GREEN_SQUARE: IBrowserVersionForGreenSquare = {
  win10: { major: 119, full: '119.1.2.1' },
  win11: { major: 119, full: '119.1.2.1' },
  mac: { major: 119, full: '119.1.2.37' },
};
