import { API_BASE_URL } from '../../../../common/constants/constants';
import { INewFeatures } from '../../../state/feature-toggle/new-features';
import { http } from '../../../services';

interface IPasswords {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
}

export const changePassword = async (passwords: IPasswords): Promise<void> =>
  http(`${API_BASE_URL}/user/password/manual`, {
    method: 'PATCH',
    body: JSON.stringify(passwords),
  });

export const changeEmail = async (email: string, password: string): Promise<void> =>
  http(`${API_BASE_URL}/user/email`, {
    method: 'PATCH',
    body: JSON.stringify({
      email,
      password,
    }),
  });

export const confirmEmailAddress = async (): Promise<void> =>
  http(`${API_BASE_URL}/user/email/confirm`, {
    method: 'POST',
  });

export type IUpdateFeatures = Pick<INewFeatures, 'header' | 'drawer'>;

export const updateFeatures = async (features: IUpdateFeatures): Promise<void> =>
  http(`${API_BASE_URL}/user/features`, {
    method: 'PATCH',
    body: JSON.stringify(features),
  });
