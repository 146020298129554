import { IProxy } from '../../../interfaces';
import { PROFILE_SETTINGS_PAGE_DEFAULT_PROXY } from '../../newProfile/reducer';
import { areProxyFieldsEqual } from '../utils/find-proxy-in-list';

export const getIsDefaultProxyInProfileSettings = (proxy: IProxy|null): boolean =>
  !!proxy && !areProxyFieldsEqual(proxy, { ...PROFILE_SETTINGS_PAGE_DEFAULT_PROXY, mode: 'http' });

export const getIsValidProxy = (proxy: IProxy|null): boolean => !!proxy && proxy.mode !== 'none';

export const getIsProxySelected = (proxy: IProxy|null): boolean =>
  getIsValidProxy(proxy) && getIsDefaultProxyInProfileSettings(proxy);
