import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { PROXY_GROUPS_SORTER_FIELD_KEY, PROXY_GROUPS_SORTER_ORDER_KEY } from '../../../../common/constants/local-storage';
import { Sorter } from '../../../features/common/custom-table/interfaces/column.interfaces';
import { determineIsSortOrder, SORT_ORDERS } from '../../../features/common/sorter-order';

export const PROXY_GROUP_SORTER_FIELD_LIST = <const>['createdAt', 'selectionDate'];

type ProxyGroupsSorterField = typeof PROXY_GROUP_SORTER_FIELD_LIST[number];

export const PROXY_GROUP_SORTER_FIELDS: Record<ProxyGroupsSorterField, ProxyGroupsSorterField> = {
  createdAt: 'createdAt',
  selectionDate: 'selectionDate',
};

type ProxyGroupsSorter = Sorter<ProxyGroupsSorterField>;

const determineIsSorterField = (value: string | null): value is ProxyGroupsSorterField =>
  !!value && Object.keys(PROXY_GROUP_SORTER_FIELDS).includes(value);

export const DEFAULT_PROXY_GROUPS_SORTER: ProxyGroupsSorter = { field: PROXY_GROUP_SORTER_FIELDS.createdAt, order: SORT_ORDERS.descend };

const proxyGroupsSorterAtom = atom<ProxyGroupsSorter | null>(null);

const getProxyGroupsSorterInStorage = (): ProxyGroupsSorter => {
  let { field, order } = DEFAULT_PROXY_GROUPS_SORTER;
  const storedField = localStorage.getItem(PROXY_GROUPS_SORTER_ORDER_KEY);
  if (determineIsSorterField(storedField)) {
    field = storedField;
  }

  const storedOrder = localStorage.getItem(PROXY_GROUPS_SORTER_ORDER_KEY);
  if (determineIsSortOrder(storedOrder)) {
    order = storedOrder;
  }

  return { field, order };
};

export const proxyGroupsSorterPersistentAtom = atom(
  (get) => {
    const sorterCurrent = get(proxyGroupsSorterAtom);
    if (sorterCurrent) {
      return sorterCurrent;
    }

    return getProxyGroupsSorterInStorage();
  },
  (_get, set, sorterUpdated: ProxyGroupsSorter) => {
    if (sorterUpdated.field && sorterUpdated.order) {
      set(proxyGroupsSorterAtom, sorterUpdated);
      localStorage.setItem(PROXY_GROUPS_SORTER_FIELD_KEY, sorterUpdated.field);
      localStorage.setItem(PROXY_GROUPS_SORTER_ORDER_KEY, sorterUpdated.order);

      return;
    }

    set(proxyGroupsSorterAtom, DEFAULT_PROXY_GROUPS_SORTER);
    localStorage.setItem(PROXY_GROUPS_SORTER_FIELD_KEY, DEFAULT_PROXY_GROUPS_SORTER.field);
    localStorage.setItem(PROXY_GROUPS_SORTER_ORDER_KEY, DEFAULT_PROXY_GROUPS_SORTER.order);
  },
);

const setProxyGroupsSorter = (sorter: ProxyGroupsSorter): void => getDefaultStore().set(proxyGroupsSorterPersistentAtom, sorter);

export const useProxyGroupsSorter = (): ProxyGroupsSorter => useAtomValue(proxyGroupsSorterPersistentAtom);

export const toggleProxyGroupsOrder = (sorterField: ProxyGroupsSorterField): void => {
  setProxyGroupsSorter({ field: sorterField, order: DEFAULT_PROXY_GROUPS_SORTER.order });
};
