import React, { useCallback } from 'react';

import ProfileName from './profile-name';
import ProfileNameIcons from './profile-name-icons';
import SharingIcon from './sharing-icon';
import { ContainerName } from './styles';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { IProfile } from '../../../interfaces';
import { closeProfilesSettings, toggleProfilesTableProfileSettings } from '../../../state/profiles-settings-atom';
import IconNewProfile from '../../../ui/icons/IconNewProfile';
import QuickSettingsButton from '../../common/custom-table/components/quick-settings-button';
import { sendActionAnalytics } from '../../common/api';
import { E_ANALYTICS_ACTIONS, ExtraActionInfo } from '../../../../common/constants/analytics';
import { getProfilesListProfileById } from '../../../state/profiles-list.atom';
import { generateProxyAnalyticsData } from '../../proxy/proxy-helpers';
import { getProxyListProxyById } from '../../../state/proxy/proxy-list.atom';

interface INameCellCore {
  profileName: string;
  profile: IProfile;
  onStartEdit: () => void;
}

const NameCellCore: React.FC<INameCellCore> = (props) => {
  const {
    profileName,
    profile,
    onStartEdit,
  } = props;

  const {
    id: profileId,
    os,
    isM1,
    isPinned,
    sharedEmails = [],
    lastActivity,
  } = profile;

  const isShared = profile.isShared || !!sharedEmails.length;

  const sendOpenAnalytics = async () => {
    let extraActionInfo: ExtraActionInfo = {};
    const profilesListProfile = getProfilesListProfileById(profileId);
    if (profilesListProfile) {
      const proxyListProxy = getProxyListProxyById(profilesListProfile.proxy?.id);
      const proxy = proxyListProxy || profilesListProfile.proxy;
      const proxyAnalyticsData = generateProxyAnalyticsData(proxy);
      extraActionInfo = {
        ...extraActionInfo,
        actionInfo: profilesListProfile.name,
        profile: profileId,
        ...proxyAnalyticsData
      };
    }

    await sendActionAnalytics(E_ANALYTICS_ACTIONS.openedQuickProfileSettings, extraActionInfo);
  }

  const handleProfileSettings = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    const { isOpened } = toggleProfilesTableProfileSettings(profileId);
    if (isOpened) {
      sendOpenAnalytics().catch(() => null);
    }
  }, [profileId]);

  return (
    <ContainerName
      onClick={(event): void => {
        event.stopPropagation();
        closeProfilesSettings();
      }}
    >
      {!NEW_FEATURES.header ? <SharingIcon isShared={isShared} /> : null}
      <ProfileName
        profileName={profileName}
        onStartEdit={onStartEdit}
      />
      {!lastActivity && !NEW_FEATURES.header && <IconNewProfile />}
      <ProfileNameIcons
        os={os}
        isM1={isM1}
        isPinned={isPinned}
        isShared={isShared}
        isNewProfile={!lastActivity}
      />
      <QuickSettingsButton openProfileSettings={handleProfileSettings} />
    </ContainerName>
  );
};

export default React.memo(NameCellCore);
