import styled from "@emotion/styled";

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 300px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: var(--fffffff);

  padding: 12px 16px 16px 16px;
  box-shadow: var(--box-shadow-hint-preference-popover);
`;

export const Title = styled.h3`
  color: var(--36363D);

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;

  margin: 0;
  margin-right: auto;
`;

export const Description = styled.p`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;

  color: var(--36363D);

  margin: 0;
  margin-bottom: 8px;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
`;

export const Button = styled.button`
  border-radius: 6px;
  padding: 6px 16px;

  background-color: var(--00A987);
  color: var(--FFFFFF);

  border: none;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;

  margin-top: 4px;
  &:hover {
    background-color: var(--00997A);
  }
`;

export const ButtonSecondary = styled.button`
  border-radius: 6px;
  padding: 0px 16px;

  background-color: transparent;
  color: var(--767676);

  border: none;
  cursor: pointer;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;

  &:hover {
    color: var(--36363D);
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-bottom: 8px;
  column-gap: 8px;
`;

export const IconButton = styled.div`
  cursor: pointer;
`;
