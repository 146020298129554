import { Dropdown } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ShareActionsDropdown from './share-actions-dropdown';
import { ShareLinksAction, SharingSelectRole, getInviteActionText } from '../../../../../utils/roles-texts';
import { IShareActionsPropsBase } from '../interfaces';
import { IconSharing, TextSharing } from '../styles';

interface IShareActionsSelect extends IShareActionsPropsBase {
  actionCurrent: SharingSelectRole|ShareLinksAction;
  canEditRole: boolean;
}

const ShareActionsSelect: React.FC<IShareActionsSelect> = (props) => {
  const { action, actionCurrent, canEditRole } = props;

  const { t: translation } = useTranslation();

  const showInviteText = (): string => {
    let localRole = actionCurrent;
    if (action.length >= 2) {
      localRole = 'varies';
    }

    if (localRole === 'pause') {
      return translation('newShareModal.links.menuPauseStatus');
    }

    return getInviteActionText(localRole, translation);
  };

  return (
    <Dropdown
      overlay={(): React.ReactNode => <ShareActionsDropdown {...props} />}
      trigger={['hover']}
      disabled={!canEditRole}
    >
      <span style={{ cursor: canEditRole ? 'pointer' : 'default', display: 'flex', marginLeft: '1px' }}>
        <TextSharing style={{ marginRight: 6 }}>
          {showInviteText()}
        </TextSharing>
        {canEditRole ? <IconSharing type='down' /> : <div style={{ width: 14 }} />}
      </span>
    </Dropdown>
  );
};

export default ShareActionsSelect;
