/* eslint-disable id-length */
import * as Sentry from '@sentry/react';
import { TFunction } from 'i18next';

import { IProxy } from '../../../../../interfaces';
import { ReactError } from '../../../../../utils/sentry-parameters/custom-errors';
import countries from '../../../../common/countries';

const COMMA_SEPARATOR = ', ';
const SPACE_SEPARATOR = ' ';

interface ISentryScopeMocked {
  setLevel: (level: string) => void;
  setTransactionName: (transactionName: string) => void;
  setFingerprint: (fingerprint: string[]) => void;
  setTag: (key: string, value: string) => void;
}

const captureError = (countryCode = ''): void => {
  // временно отключаем для разгрузки sentry
  // const transactionName = 'proxy-city-and-country-display-error';
  // Sentry.captureException(new ReactError(transactionName.replaceAll('-', ' ')), (scope: ISentryScopeMocked) => {
  //   scope.setLevel('error');
  //   scope.setTransactionName(transactionName);
  //   scope.setFingerprint([transactionName]);
  //   scope.setTag('country-code', countryCode);

  //   return scope;
  // });
};

const getCountryCode = (proxy: IProxy): string => {
  let countryCode = proxy.country;
  if (proxy.mode === 'tor') {
    countryCode = proxy.torProxyRegion;
  } else if (proxy.mode === 'gologin') {
    countryCode = proxy.autoProxyRegion;
  }

  return (countryCode || '').toUpperCase();
};

const composeCityAndCountryFromCountriesList = (proxy: IProxy, countryCode: string): string => {
  if (!countryCode) {
    captureError();

    return '';
  }

  const countryListed = countries.find(({ code }) => code === countryCode);
  if (!countryListed) {
    captureError(countryCode);

    return '';
  }

  const cityAndCountry = [proxy.city, countryListed.name].filter(Boolean).join(COMMA_SEPARATOR);
  const countryCodeDecorated = `(${countryCode})`;

  return [cityAndCountry, countryCodeDecorated].filter(Boolean).join(SPACE_SEPARATOR);
};

const prefixProxyCityAndCountry = (proxyInfoCountry: string, prefixKey: string, translation: TFunction): string =>
  [translation(prefixKey), proxyInfoCountry].join(COMMA_SEPARATOR);

export const getProxyInfoCountry = (proxy: IProxy, translation: TFunction): string => {
  const countryCode = getCountryCode(proxy);
  const cityAndCountry = composeCityAndCountryFromCountriesList(proxy, countryCode);
  if (!['gologin', 'tor'].includes(proxy.mode)) {
    return cityAndCountry;
  }

  const prefixKey = proxy.mode === 'gologin' ? 'proxies.freeProxy' : 'proxies.torProxy';

  return prefixProxyCityAndCountry(cityAndCountry, prefixKey, translation);
};
