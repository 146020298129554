import { css } from '@emotion/core';
import { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled/macro';
import { Button } from 'antd';

import { textFont } from '../../../ui/style-templates';

export const ProfileButton = styled('button')<{ newStyle: boolean; isLoading?: boolean }>`
  background: transparent;
  padding: 5px;
  text-align: center;
  outline: none;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  cursor: pointer;
  transition: 0.2s;
  display: flex;
  height: 26px;
  min-width: 56px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  width: auto;

  :hover {
    background-color: var(--00B86D-profile-table-button-run);
    color: var(--FFFFFF-profile-table-button-run);
  }
  
  ${(props): SerializedStyles => props.newStyle ? css`
    height: 24px;
    min-width: 46px;
    border: 1px solid;
    color: var(--00997A-profile-table-button-run);
    border-color: ${props.isLoading ? 'var(--00A987-profile-table-button-run)' : 'var(--88D1C3-profile-table-button-run)'};
    ${textFont()};
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;

    :hover {
      background-color: var(--00A987-profile-table-button-run);
      border-color: var(--00A987-profile-table-button-run);
    }
    svg {
      fill: var(--00A987-profile-table-button-run);
    }
    
    ` : css`
    border: 1px solid var(--00B86D-profile-table-button-run);
    color: var(--00B86D-profile-table-button-run);
  `}
`;

export const DisabledButtonRun = styled(ProfileButton)<{ newStyle: boolean }>`
  color: var(--00000040-antd);
  background-color: var(--F5F5F5-antd);
  border: 1px solid var(--D9D9D9-antd);
  cursor: not-allowed;
  
  :hover {
    color: var(--00000040-antd);
    border-color: var(--D9D9D9-antd);
    background-color: var(--F5F5F5-antd);
  }

  ${(props): SerializedStyles|null => props.newStyle ? css`
    font-weight: 400;
  ` : null}
`;

export const ButtonStop = styled(ProfileButton)<{ newStyle: boolean }>`
    line-height: ${(props): string => props.newStyle ? '16px' : '14px'};
    letter-spacing: ${(props): string => props.newStyle ? '0.2px' : ''};
    cursor: pointer;
    padding: 5px;
    border-color: ${(props): string => props.newStyle ? 'var(--FFA8A8-profile-table)' : 'var(--E83E5E-profile-table)'};
    color: ${(props): string => props.newStyle ? 'var(--F5222D-profile-table)' : 'var(--E83E5E-profile-table)'};
    white-space: nowrap;
    transition: 0.2s;
    font-weight: ${(props): string => props.newStyle ? '400' : '500'};
    margin-left: ${(props): string => props.newStyle ? '0' : '16px'};

    :hover {
        ${(props): SerializedStyles => props.newStyle ? css`
      border-color: var(--FF0000-profile-table);
      background-color: var(--FF0000-profile-table);
      color: var(--FFFFFF-profile-table);
    ` : css`
      border-color: var(--E83E5EE6);
      background-color: var(--E83E5EE6);
      color: var(--FFFFFF-profile-table);
    `}
    }
`;

export const ButtonRun = styled(ProfileButton)<{
  isInDrawer?: boolean;
  newStyle: boolean;
  isLoading?: boolean;
}>`
    cursor: pointer;
    background: var(--FFFFFF-profile-table);
    z-index: 100;
    transition: width 0s;

    :focus {
        color: var(--00997A-profile-table);
        border-color: var(--88D1C3-profile-table);

        :hover {
            color: var(--FFFFFF-profile-table);
        }
    }

    :hover {
        background-color: ${(props): string => props.newStyle ? 'var(--00A987-profile-table)' : 'var(--00B86D-profile-table)'};
        color: var(--FFFFFF-profile-table);
    }

    ${(props): SerializedStyles => props.newStyle ? css`
    background: inherit;
    color: var(--00997A-profile-table);
    border: 1px solid ${props.isLoading ? 'var(--00A987-profile-table)' : 'var(--88D1C3-profile-table)'};
    line-height: 16px;
    letter-spacing: 0.2px;
    font-weight: 400;

    :hover {
      background-color: var(--00A987-profile-table);
      border-color: var(--00A987-profile-table);

      svg {
        fill: var(--00A987-profile-table);
      }
    }

    svg {
      fill: var(--00A987-profile-table);
    }

    &.ant-btn.ant-btn-loading::before {
      opacity: 1;
      background: inherit;
    }
  ` : css`
    border: 1px solid var(--00B86D-profile-table);
    color: var(--00B86D-profile-table);
  `}
`;

export const DrawerButtonRun = styled(Button)`
  background: transparent;
  border: 1px solid var(--00B86D);
  border-radius: 4px;
  padding: 5px 11px 4px 11px;
  min-height: 26px;
  color: var(--00B86D);
  outline: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    background-color: var(--00B86D);
    color: var(--FFFFFF);
    transition: 0.2s;
  }
`;

export const DrawerButtonStop = styled('button')`
  font-size: 12px;
  line-height: 14px;
  cursor: pointer;
  height: 25px;
  border-radius: 4px;
  border-color: transparent;
  background-color: var(--E9515C);
  color: var(--FFFFFF);
  min-width: 54px;
  outline: none;
`;
