import React from 'react';
import styled from '@emotion/styled';

const IconCopyProxyInDrawerStyle = styled('svg')`
  stroke: var(--ACACAC);
  :hover {
    stroke: var(--00A987);
    cursor: pointer;
  }
`;

const IconCopyProxyInDrawer = () => (
    <IconCopyProxyInDrawerStyle width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.5 6.5L8 11.5L12.5 6.5H9.5V0.5H6.5V6.5H3.5Z" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15.5 10.5V14.5C15.5 15.052 15.052 15.5 14.5 15.5H1.5C0.948 15.5 0.5 15.052 0.5 14.5V10.5" strokeLinecap="round" strokeLinejoin="round"/>
    </IconCopyProxyInDrawerStyle>
);

export default IconCopyProxyInDrawer;
