import React from 'react';
import { Trans } from 'react-i18next';

import { GeoProxyTrafficTooltipWrapper } from './styles';
import { GeoProxyType } from '../../../../../../../common/constants/types';

type GeoProxyTrafficTooltipProps = {
  trafficByType: Record<GeoProxyType, string>;
}

const GeoProxyTrafficTooltip: React.FC<GeoProxyTrafficTooltipProps> = (props) => {
  const { trafficByType } = props;

  return (
    <GeoProxyTrafficTooltipWrapper>
      <Trans
        i18nKey='pricing.geoProxyTrafficTooltip.description'
        values={trafficByType}
      />
    </GeoProxyTrafficTooltipWrapper>
  );
};

export default GeoProxyTrafficTooltip;
