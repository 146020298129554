import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconGlobe: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_12619_11368)'>
        <path d='M8.00013 15.5C9.77518 15.5 11.2141 12.1421 11.2141 8C11.2141 3.85786 9.77518 0.5 8.00013 0.5C6.22509 0.5 4.78613 3.85786 4.78613 8C4.78613 12.1421 6.22509 15.5 8.00013 15.5Z' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M0.5 8H15.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.85786 12.1421 0.5 8 0.5C3.85786 0.5 0.5 3.85786 0.5 8C0.5 12.1421 3.85786 15.5 8 15.5Z' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_12619_11368'>
          <rect width='16' height='16' fill='var(--FFFFFF)' />
        </clipPath>
      </defs>
    </svg>
  </IconWrapper>
);
