import css from '@emotion/css';
import styled from '@emotion/styled';
import { Button, Icon } from 'antd';

export const Row = styled('div')`
  display: flex;
  flex-direction: row;
`;

export const Subtitle = styled('span')`
  display: block;
  font-size: 12px;
  color: var(--000000);
  margin-bottom: 10px;
`;

export const SaveAndNextBtn = styled(Button)`
  font-size: 12px;
  max-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CheckProxyButtonDiv = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const LoaderDiv = styled('div')`
  display: inline-block;
`;

export const SuccessProxyCheckContainer = styled('div')`
  margin-top: 10px;
  display: block;
  .check-params {
    display: flex;
  }
`;

export const ProxyCheckDescription = styled('div')`
  margin-top: 6px;
  margin-left: 1px;
  display: flex;
  color: var(--222222);
  opacity: 0.5;
`;

export const ProxyCheckResultDiv = styled('div')<{ fontColor: string }>`
  display: flex;
  ${props => css`color: ${props.fontColor};`}
`;

export const CountryFlagContainer = styled('div')`
  display: flex;
  cursor: alias;
  margin-right: 5px;
  align-items: center;
`;

export const ProxyFirstLineContainer = styled('div')`
  display: flex;
  align-items: center;
`;

export const IconSync = styled(Icon)`
  width: 15px;
  margin-left: 5px;
  cursor: pointer;
`;
