import { atom, getDefaultStore, useAtomValue } from 'jotai';

export const PROXY_ADD_MODAL_NAME = 'proxy-add-modal';
export const PROXY_EDIT_MODAL_NAME = 'proxy-edit-modal';
export const PROXY_IMPORT_MODAL_NAME = 'proxy-import-modal';
const MODAL_NAMES = <const>[
  // TODO: reuse `proxy-settings-modal` here
  // PROXY_ADD_MODAL_NAME,
  // PROXY_EDIT_MODAL_NAME,
  PROXY_IMPORT_MODAL_NAME,
];

const PROXIES_TABLE_INACTIVE_MODAL_NAME = 'none';

type ProxiesTableModalName = typeof MODAL_NAMES[number];
type ProxiesTableInactiveModalName = typeof PROXIES_TABLE_INACTIVE_MODAL_NAME;
type ProxiesTableModal = ProxiesTableModalName|ProxiesTableInactiveModalName;

interface IProxiesTableModal {
  currentModal: ProxiesTableModal;
}

const defaultProxiesTableModal: IProxiesTableModal = {
  currentModal: PROXIES_TABLE_INACTIVE_MODAL_NAME,
};

const proxiesTableModalAtom = atom<IProxiesTableModal>(defaultProxiesTableModal);

const setProxiesTableModal = (modalParams: IProxiesTableModal): void =>
  getDefaultStore().set(proxiesTableModalAtom, modalParams);

export const useProxiesTableModalType = (): ProxiesTableModal => useAtomValue(proxiesTableModalAtom).currentModal;

export const useProxiesTableModalIsVisible = (modalType: ProxiesTableModal): boolean => useProxiesTableModalType() === modalType;

export const closeProxiesTableModal = (): void =>
  setProxiesTableModal({ currentModal: PROXIES_TABLE_INACTIVE_MODAL_NAME });

export const openProxiesTableModal = (modalType: ProxiesTableModalName): void =>
  setProxiesTableModal({ currentModal: modalType });
