import React from 'react';

const IconReplace = () => (
    <svg width="8" height="12" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="1" cy="1" r="1" fill="var(--929292)"/>
    <circle cx="5" cy="1" r="1" fill="var(--929292)"/>
    <circle cx="1" cy="5" r="1" fill="var(--929292)"/>
    <circle cx="5" cy="5" r="1" fill="var(--929292)"/>
    <circle cx="1" cy="9" r="1" fill="var(--929292)"/>
    <circle cx="5" cy="9" r="1" fill="var(--929292)"/>
    </svg>
);

export default IconReplace;
