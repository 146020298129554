import { API_BASE_URL } from '../../../common/constants/constants';
import { IWorkspaceFolder, IWorkspaceFolderRole, WorkspaceRole } from '../../interfaces/workspaces';
import { http } from '../../services';

export const kickMember = async (workspaceId: string, memberId: string): Promise<void> =>
  http(`${API_BASE_URL}/workspaces/${workspaceId}/members/${memberId}`, {
    method: 'DELETE',
  });

interface IUpdateMemberPermissions {
  workspaceId: string;
  memberId: string;
  limitedAccess: boolean;
  role: WorkspaceRole;
  folders: IWorkspaceFolderRole[];
}

export const updateMemberPermissions = async ({
  workspaceId,
  memberId,
  limitedAccess,
  role,
  folders,
}: IUpdateMemberPermissions): Promise<void> =>
  http(`${API_BASE_URL}/workspaces/${workspaceId}/members/${memberId}`, {
    method: 'PATCH',
    body: JSON.stringify({
      limitedAccess,
      role,
      folders,
    }),
  });

export const createFolder = async (workspaceId: string, name: string): Promise<IWorkspaceFolder> =>
  http(`${API_BASE_URL}/workspaces/${workspaceId}/folders`, {
    method: 'POST',
    body: JSON.stringify({
      name,
    }),
  }).then((res: any) => res.body);

export const renameWorkspace = async (workspaceId: string, name: string): Promise<void> =>
  http(`${API_BASE_URL}/workspaces/${workspaceId}/rename`, {
    method: 'PATCH',
    body: JSON.stringify({
      name,
    }),
  });
