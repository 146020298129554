import { Tooltip } from 'antd';

import React from 'react';

export const getFlag = (toAllProfiles: boolean) => (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.5 1.5H13.5L11.5 5.5L13.5 9.5H2.5'
        fill={toAllProfiles ? 'var(--00A987)' : 'none'}
      />
      <path
        d='M2.5 1.5H13.5L11.5 5.5L13.5 9.5H2.5'
        stroke={toAllProfiles ? 'var(--00A987)' : 'var(--CDCDCD)'}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.5 0.5V15.5'
        stroke={toAllProfiles ? 'var(--00A987)' : 'var(--CDCDCD)'}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
);
