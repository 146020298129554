import React, { FC } from 'react';

import { DEVICE_MEMORY, DeviceMemoryType } from '../../../../interfaces';
import { ModernSelect } from '../../../../ui/modern-select';
import { IChangeSetting } from '../../interfaces';
import { ContainerParams } from '../../styles';

interface IDeviceMemory extends IChangeSetting {
  deviceMemory: DeviceMemoryType;
}

export const DeviceMemory: FC<IDeviceMemory> = ({ changeSetting, deviceMemory }) => {
  const selectItems = DEVICE_MEMORY.map(memory => ({
    title: memory.toString() + ' GB',
    value: memory.toString(),
  }));

  return (
    <ContainerParams>
      <ModernSelect
        title={deviceMemory + ' GB'}
        currentValue={deviceMemory.toString()}
        itemList={selectItems}
        onSelected={(value): void => changeSetting({ navigator: { deviceMemory: (Number(value) as DeviceMemoryType) } })}
      />
    </ContainerParams>
  );
};
