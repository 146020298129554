import * as Sentry from '@sentry/react';

import {
  EventPerformanceEntry,
  IFinishUserAction,
  ILoadProfilesTransactionData,
  IUserAction,
  IUserActionFull,
  IUserActionMark,
  ProperPerformanceObserverInit,
} from './interfaces';
import { MS_IN_SECOND } from '../../../common/constants/constants';
import { getProfilesList } from '../../state/profiles-list.atom';
import { ProfileStatusType } from '../../types';
import { sendAppStartTransactionToSentry } from '../../utils/app-start.transaction.utils';
import { ReactError } from '../../utils/sentry-parameters/custom-errors';
import { ITransactionObject, ITransactionSpanObject } from '../../utils/sentry-parameters/helper.functions.interfaces';

// minimum duration of observed event for browser performance observer to send into this service
// cannot be less than 16ms
const EVENT_DURATION_THRESHOLD_MS = 30;

class PerformanceObserverService {
  private static instance: PerformanceObserverService;
  private observer: PerformanceObserver;

  private loadProfilesMark: PerformanceMark | null = null;
  private transactions: Map<string, ITransactionObject>;
  private userActionMarks: IUserActionMark[];

  public static getInstance(): PerformanceObserverService {
    if (!PerformanceObserverService.instance) {
      PerformanceObserverService.instance = new PerformanceObserverService();
    }

    return PerformanceObserverService.instance;
  }

  private constructor() {
    this.transactions = new Map();
    this.userActionMarks = [];
    this.observer = new PerformanceObserver((list) => {
      list.getEntries().forEach(this.processPerformanceEntry);
    });
  }

  public startObserver = (): void => {
    try {
      this.observer.observe({ type: 'longtask', buffered: true });
      this.observer.observe({
        type: 'event',
        durationThreshold: EVENT_DURATION_THRESHOLD_MS,
        buffered: true,
      // we have to override the interface, because TS doesn't know it
      } as ProperPerformanceObserverInit);
    } catch (error) {
      const message = error instanceof Error ? error.message : 'unknown';
      Sentry.captureException(new ReactError(message), (scope) => {
        scope.setLevel('error');
        scope.setTransactionName('start-performance-observer');
        scope.setFingerprint(['start-performance-observer']);

        return scope;
      });
    }
  };

  private processPerformanceEntry = (entry: PerformanceEntry): void => {
    switch (entry.entryType) {
      case 'longtask':
        return this.processLongTaskEntry(entry);
      case 'event':
        return this.processEventEntry(entry as EventPerformanceEntry);
      default:
    }
  };

  private getUserMarkTimestamps = (opts: IFinishUserAction): { action: IUserActionFull; startTimestamp: number; endTimestamp: number } | null => {
    const currentUserActionMarkIndex = this.userActionMarks.findIndex(userActionMark => {
      const actionTime = userActionMark.mark.startTime;

      return actionTime >= opts.processingStart && actionTime <= opts.processingEnd;
    });

    if (currentUserActionMarkIndex === -1) {
      return null;
    }

    const currentUserActionMark = this.userActionMarks[currentUserActionMarkIndex];
    this.userActionMarks.splice(currentUserActionMarkIndex, 1);

    const startTimestamp = (performance.timeOrigin + currentUserActionMark.mark.startTime) / MS_IN_SECOND;
    const endTimestamp = (performance.timeOrigin + opts.processingEnd) / MS_IN_SECOND;

    return { action: currentUserActionMark.action, startTimestamp, endTimestamp };
  };

  private finishUserAction = (opts: IFinishUserAction): void => {
    const markTimestamps = this.getUserMarkTimestamps(opts);
    if (!markTimestamps) {
      return;
    }

    const { action, startTimestamp, endTimestamp } = markTimestamps;
    const transactionName = action.userAction;
    const transaction = Sentry.startTransaction({ name: transactionName, startTimestamp });
    this.applyUserActionTransactionTags(action, transaction);
    transaction.finish(endTimestamp);
  };

  private applyUserActionTransactionTags = (userAction: IUserActionFull, transaction: ITransactionObject): void => {
    if (userAction.loadedProfilesCount) {
      transaction.setTag('loaded-profiles-count', userAction.loadedProfilesCount);
    }

    switch (userAction.userAction) {
      case 'open-profile-settings':
        transaction.setTag('had-profile-id', !!userAction.previousProfileId);
        break;
      case 'open-tags-popup':
        transaction.setTag('profile-tags-count', userAction.profileTagsCount);
        transaction.setTag('total-tags-count', userAction.totalTagsCount);
        break;
      case 'change-selected-profiles':
        transaction.setTag('selected-profiles-count', userAction.selectedProfilesCount);
        break;
      default:
    }
  };

  private processEventEntry = (entry: EventPerformanceEntry): void => {
    const { processingStart, processingEnd } = entry;
    if (entry.name !== 'click') {
      return;
    }

    this.finishUserAction({ processingStart, processingEnd });
  };

  private processLongTaskEntry = (entry: PerformanceEntry): void => {
    const { startTime, duration } = entry;
    const endTime = startTime + duration;
    if (!this.loadProfilesMark) {
      return;
    }

    const { startTime: markTime, detail = {} } = this.loadProfilesMark;
    const { traceId = '' } = detail;
    const isMarkInsideEntry = markTime && markTime >= startTime && markTime <= endTime;
    if (!isMarkInsideEntry) {
      return;
    }

    const transaction = this.transactions.get(traceId);
    if (!transaction) {
      return;
    }

    transaction.finish();
    sendAppStartTransactionToSentry();

    this.transactions.delete(traceId);
    this.loadProfilesMark = null;
  };

  public setProfilesMark = (transaction: ITransactionObject): void => {
    const { traceId } = transaction;
    if (!traceId) {
      return;
    }

    this.loadProfilesMark = performance.mark('load-profiles-table-req-end', { detail: { traceId } });
    this.transactions.set(traceId, transaction);
  };

  public setLoadProfilesTags = (data: ILoadProfilesTransactionData): void => {
    const { transaction, selector, prevProfiles, receivedProfiles, updateType } = data;
    const { folder, tag, search, offset } = selector;
    const tags = {
      'profiles-prev-count': prevProfiles.length,
      'profiles-received-count': receivedProfiles.length,
      'profiles-update-type': updateType,
      'profiles-has-folder': !!folder,
      'profiles-has-tag': !!tag,
      'profiles-is-search': !!search,
      'profiles-offset': offset,
    };

    Object.entries(tags).forEach(([key, val]) => transaction.setTag(key, val));
  };

  private extendUserActionToFull = (action: IUserAction): IUserActionFull => {
    const loadedProfilesCount = getProfilesList().length;

    return { ...action, loadedProfilesCount };
  };

  public handleUserAction = (action: IUserAction): void => {
    try {
      const fullAction = this.extendUserActionToFull(action);
      const mark = performance.mark(`user-action-${action.userAction}`);
      this.userActionMarks.push({ mark, action: fullAction });
    } catch (error) {
      const message = error instanceof Error ? error.message : 'unknown';
      Sentry.captureException(new ReactError(message), (scope) => {
        scope.setLevel('error');
        scope.setTransactionName('handle-user-action-failed');
        scope.setFingerprint(['handle-user-action-failed']);

        return scope;
      });
    }
  };

  public createProfileRunTransaction = (profileId: string): ITransactionObject => {
    const profilesLoaded = getProfilesList().length;

    const transaction = Sentry.startTransaction({ name: 'run-profile-full' });

    transaction.setTag('loaded-profiles-count', profilesLoaded);

    this.transactions.set(profileId, transaction);

    return transaction;
  };

  public createProfileRunSpan = (profileId: string, status: ProfileStatusType): ITransactionSpanObject => {
    const transaction = this.transactions.get(profileId);
    if (!transaction) {
      return { setStatus: (): void => void 0, finish: (): void => void 0 };
    }

    const simpleStatus = status.replace(/^profileStatuses\./, '');

    return transaction.startChild({ op: 'ui', description: `set-status-${simpleStatus}` });
  };

  public finishProfileRunTransaction = (profileId: string): void => {
    const transaction = this.transactions.get(profileId);
    if (transaction) {
      transaction.finish();
      this.transactions.delete(profileId);
    }
  };
}

export default PerformanceObserverService;
