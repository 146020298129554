import React from 'react';

import { ProxyModeSelectorWrapper, ProxyModeLabel, ProxyModeRadioInput, ProxyModeRadioInputContent } from './styles';
import { ProxyMode } from '../../../../../interfaces';

type UserProxyFormMode = {
  value: ProxyMode;
  label: string;
}

const USER_PROXY_FORM_MODES: UserProxyFormMode[] = [{
  value: 'http',
  label: 'http',
}, {
  value: 'socks5',
  label: 'socks 5',
}, {
  value: 'socks4',
  label: 'socks 4',
}];

type ProxyModeSelectorProps = {
  currentMode: ProxyMode;
  handleChangeMode: React.ChangeEventHandler<HTMLInputElement>;
}

export const ProxyModeSelector: React.FC<ProxyModeSelectorProps> = (props) => {
  const { currentMode, handleChangeMode } = props;

  return (
    <ProxyModeSelectorWrapper>
      {USER_PROXY_FORM_MODES.map((option) => (
        <ProxyModeLabel key={option.value}>
          <ProxyModeRadioInput
            checked={currentMode === option.value}
            onChange={handleChangeMode}
            type='radio'
            value={option.value}
          />
          <ProxyModeRadioInputContent>
            {option.label}
          </ProxyModeRadioInputContent>
        </ProxyModeLabel>
      ))}
    </ProxyModeSelectorWrapper>
  );
};
