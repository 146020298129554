import { css } from '@emotion/core';
import { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled';

import { textFont } from '../../../ui/style-templates';

export const DrawerProfileHeaderButtons = styled('div')<{ newStyle: boolean }>`
  display: flex;
  align-items: center;
  
  ${(props): SerializedStyles|null => props.newStyle ? css`
    justify-content: space-between;
    padding-right: 25px;
  ` : null}
`;

export const NewStyleText = styled('span')`
  ${textFont};
  margin-left: 8px;
  white-space: nowrap;
`;

export const DrawerTrustworthy = styled('div')`
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  color: var(--00B86D);
  margin-top: 2px;
  `;

export const DrawerBackdropContainer = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 100;
`;
