import styled from '@emotion/styled/macro';

import { blackMain } from '../../../../../ui/style-templates/colors';

export const ContainerFolderList = styled('div')`
  line-height: 24px;
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  color: var(--2B2B31-profile-new-settings);
  font-weight: 400;
  flex-direction: column;
  width: 292px;
`;
