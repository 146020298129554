import { createContext } from 'react';

import { IFullProfile, IProfile } from '../interfaces';

export interface IProfilesCtx {
  getProfile: (profileId: string) => IFullProfile|null;
  updateFullProfilesMap: (profiles: (IProfile|IFullProfile)[]) => void;
  refreshFullProfile: (profile: IProfile|IFullProfile) => void;
}

export const PROFILES_DEFAULT_VALUE: IProfilesCtx = {
  getProfile: () => null,
  updateFullProfilesMap: () => {},
  refreshFullProfile: () => {},
};

export const profilesContext = createContext<IProfilesCtx>(PROFILES_DEFAULT_VALUE);
