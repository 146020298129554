import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconTrash: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_22721_42969)'>
        <path d='M0.5 2.5H11.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M4.5 2.5V0.5H7.5V2.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M10 4.5L9.599 10.107C9.543 10.892 8.89 11.5 8.103 11.5H3.894C3.107 11.5 2.454 10.891 2.398 10.106L2 4.5' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_22721_42969'>
          <rect width='12' height='12' fill='white' />
        </clipPath>
      </defs>
    </svg>
  </IconWrapper>
);
