import { API_BASE_URL } from '../../../../../common/constants/constants';
import {
  IWorkspaceFolderRole,
  IWorkspaceMember,
  WorkspaceRole,
} from '../../../../interfaces/workspaces';
import { http } from '../../../../services';

interface IInviteMembers {
  workspaceId: string;
  emails: string[];
  limitedAccess: boolean;
  role: WorkspaceRole;
  folders: IWorkspaceFolderRole[];
  workspaceName: string;
}

export const inviteMembers = (inviteObj: IInviteMembers): Promise<IWorkspaceMember[]> => {
  const { workspaceId } = inviteObj;
  const toSend: Omit<IInviteMembers, 'workspaceId'> & { workspaceId?: string } = {
    ...inviteObj,
  };

  delete toSend.workspaceId;

  return http(`${API_BASE_URL}/workspaces/${workspaceId}/members`, {
    method: 'POST',
    body: JSON.stringify(toSend),
  }).then((res: any) => res.body);
};
