import React from 'react';

const IconAddSquared = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='10' y='5' width='1' height='9' fill='#666666'/>
    <rect x='15' y='9' width='1' height='9' transform='rotate(90 15 9)' fill='#666666'/>
    <rect x='3.4' y='2.4' width='14.2' height='14.2' rx='0.6' stroke='#666666' strokeWidth='0.8'/>
  </svg>
);

export default IconAddSquared;
