import React from 'react';

const IconSuccessAutosave = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 19C21.2 19 23 17.2 23 15C23 12.8 21.2 11 19 11C18.7 7.1 15.5 4 11.5 4C7.5 4 4.2 7.2 4 11.1C2.3 11.5 1 13.1 1 15C1 17.2 2.8 19 5 19H19Z" stroke="var(--767676)" strokeMiterlimit="10" strokeLinecap="square"/>
    <path d="M8 12L10.5 14.5L15 10" stroke="var(--767676)" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default IconSuccessAutosave;
