import { API_BASE_URL } from '../../../../../common/constants/constants';
import { IMySharedAccount } from '../../../../interfaces';
import { http } from '../../../../services';

export const shareAccount = async (params: any) => {
  const data = await http(`${API_BASE_URL}/share/member`, {
    method: 'POST',
    body: JSON.stringify(params),
  });

  return data.body;
};

export const requestGetMySharedAccounts = async (): Promise<IMySharedAccount[]> => {
  const data = await http(`${API_BASE_URL}/share/my-members`, {
    method: 'GET',
  });

  return data.body;
};

export const requestDeleteMySharedAccounts = async (profileId: string) => {
  const data = await http(`${API_BASE_URL}/share/${profileId}`, {
    method: 'DELETE',
  });

  return data.body;
};
