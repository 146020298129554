import { message } from 'antd';

import { Price } from './price';
import { showErrorCreationPaymentLink } from './utils';
import { IPlan } from '../../../interfaces/plan';
import { sendErrorPaymentAnalytics } from '../../common/api';
import { getSelectedPlan } from '../../modalsComponents/components/checkout-modal/utils';
import { E_CURRENCY, E_PAYMENT_METHODS, E_PAYMENT_METHODS_ANALYTICS, IDiscount, IDiscountElem, IPriceConfig, PaymentPeriodType } from '../interfaces';

interface IHandlePaymentCreationError {
  error: any;
  selectedDiscounObj: number | IDiscount | null;
  planPeriod: PaymentPeriodType;
  method: E_PAYMENT_METHODS;
  paymentMethod: E_PAYMENT_METHODS_ANALYTICS;
  promoDiscount: Partial<IDiscountElem>;
  plans: IPlan[];
  selectedPlan: string;
  priceConfig?: IPriceConfig;
  tabCurrency?: E_CURRENCY;
}

export const handlePaymentCreationError = ({
  error,
  selectedDiscounObj,
  planPeriod,
  method,
  paymentMethod,
  promoDiscount,
  tabCurrency,
  plans,
  selectedPlan,
  priceConfig,
}: IHandlePaymentCreationError): void => {
  const errorCode = error.status || error.body?.statusCode || 0;
  const errorMessage = error.message || error.body?.message || 'unknown error';
  showErrorCreationPaymentLink({ message, errorCode, errorMessage });

  const planToPay = getSelectedPlan(plans, selectedPlan);
  const totalPrice = new Price(planToPay, tabCurrency)
    .period(selectedDiscounObj)
    .promo(promoDiscount, priceConfig);

  sendErrorPaymentAnalytics({
    messageText: errorMessage,
    extra: {
      plan: selectedPlan,
      planPeriod,
      scenario: `get${paymentMethod}PaymentLink`,
    },
    method,
    paymentError: {
      paymentMethod,
      description: errorMessage,
      amount: totalPrice.getAmountInCurrency(),
      currency: tabCurrency,
      plan: planToPay.name,
    },
  });
};
