import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FontsManager from '../../../../features/modalsComponents/components/fonts-manager';
import { IFonts } from '../../../../interfaces';
import { OsType } from '../../../../types';
import { getFontsList, getSystemFonts } from '../../../../utils/fonts-list';
import { ModernSelect } from '../../../../ui/modern-select';
import { IChangeSetting } from '../../interfaces';
import { ContainerParams, FontsSpan } from '../../styles';

interface IFontFullData {
  name: string;
  value: string;
  fileNames?: string[];
  os?: string[];
  checked?: boolean;
}

const getUserFonts = async (os: OsType, families: string[]): Promise<IFontFullData[]> => getFontsList(os, families);

interface IFontsComponent extends IChangeSetting {
  os: OsType;
  profileFonts: IFonts;
}

const Fonts: FC<IFontsComponent> = (props) => {
  const { os, profileFonts, changeSetting } = props;
  const [fonts, setFonts] = useState<IFontFullData[]>([]);
  const [fontsModalVisible, setFontsModalVisible] = useState<boolean>(false);
  const [systemFonts, setSystemFonts] = useState<string[]>([]);

  const { t: translation } = useTranslation();

  useEffect(() => {
    getUserFonts(os, profileFonts.families)
      .then((resultFonts) => setFonts(resultFonts));
    getSystemFonts(os).then((resultSystemFonts) => setSystemFonts(resultSystemFonts));
  }, [profileFonts.families]);

  const onMaskingChanged = (enableMasking: boolean): void => {
    changeSetting({
      fonts: {
        ...profileFonts,
        enableMasking,
      },
    });
  };

  const saveFontsModal = (): void => {
    const newFontFamilies = fonts
      .filter(fontElem => fontElem.checked)
      .map(fontElem => fontElem.value);

    updateFonts(newFontFamilies);
    setFontsModalVisible(false);
  };

  const updateFonts = (newFonts: string[]): void => {
    changeSetting({
      fonts: {
        ...profileFonts,
        families: newFonts,
      },
    });
  };

  const selectItems = [{
    title: translation('quickSettings.parameters.real'),
    value: 'real',
  }, {
    title: translation('quickSettings.parameters.masked'),
    value: 'masked',
  }];

  const fontsDataLength = profileFonts.enableMasking ? profileFonts.families.length : systemFonts.length;

  return (
    <>
      <ContainerParams
        onClick={(): void => setFontsModalVisible(os !== 'android')}
        style={{ cursor: 'pointer', justifyContent: 'space-between' }}
      >
        <div onClick={(event): void => event.stopPropagation()}>
          <ModernSelect
            currentValue={profileFonts.enableMasking ? 'masked' : 'real'}
            itemList={selectItems}
            onSelected={(value): void => onMaskingChanged(value === 'masked')}
          />
        </div>
        <FontsSpan>
          {translation('quickSettings.browser.fontsNum', { count: fontsDataLength })}
        </FontsSpan>
      </ContainerParams>
      <FontsManager
        fonts={fonts}
        fontsModalVisible={fontsModalVisible}
        updateFontsList={(newFonts): void => setFonts([...newFonts])}
        onCancel={(): void => {
          setFontsModalVisible(false);
          setFonts(fonts);
        }}
        onOk={saveFontsModal}
      />
    </>
  );
};

export default React.memo(Fonts);
