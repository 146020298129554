import { message } from 'antd';
import React from 'react';
import { Trans } from 'react-i18next';

const MAX_TEXT_LEN = 30;
const ELLIPSIS = '...';

const limitText = (text: string): string => text.length > MAX_TEXT_LEN ?
  text.slice(0, MAX_TEXT_LEN - ELLIPSIS.length) + ELLIPSIS :
  text;

const showAfterInviteMessage = (emailsToInvite: string[]): void => {
  let [email] = emailsToInvite;
  email = limitText(email);

  const messageContent = (
    <span>
      {emailsToInvite.length === 1 ? (
        <Trans i18nKey='modals.inviteUserWorkspace.successMessage.youveInvitedEmail' values={{ email }} />
      ) : (
        <Trans i18nKey='modals.inviteUserWorkspace.successMessage.youveInvitedMembers' count={emailsToInvite.length} />
      )}
    </span>
  );

  message.success(messageContent, 3);
};

export default showAfterInviteMessage;
