import { message } from 'antd';

export const filterEmailsByUser = (emails: string[], userEmail = '', messageErrorText: string): string[] => {
  const isEmailInList = emails.find(email => email.toLowerCase() === userEmail.toLowerCase());
  if (!isEmailInList) {
    return emails;
  }

  if (emails.length === 1) {
    message.error(messageErrorText);

    return [];
  }

  return emails.filter(email => email.toLowerCase() !== userEmail.toLowerCase());
};
