import { IDataForGetTimezone, IGetLanguagesByBasedIp, IGetTimezoneByBasedIp } from './interfaces';
import { IProxy, ProxyMode } from '../../../interfaces';

const getTorOrFreeProxyRegion = (proxy: IProxy): string => {
  if (proxy.mode === 'tor' && proxy.torProxyRegion) {
    return proxy.torProxyRegion;
  }

  if (proxy.mode === 'gologin' && proxy.autoProxyRegion) {
    return proxy.autoProxyRegion;
  }

  return 'us';
};

export const getTimezoneByBasedIp = (dataForGetTimezone: IDataForGetTimezone): IGetTimezoneByBasedIp => {
  const { proxy, proxyFromCtx, localUserDevice = {}, translation } = dataForGetTimezone;
  const { status = false, timezone = '', country = '' } = proxyFromCtx || {};
  const { timezone: realTimezone = '', country: realCountry = '' } = localUserDevice || {};
  if (['gologin', 'tor'].includes(proxy.mode as ProxyMode)) {
    const freeProxyCountry = getTorOrFreeProxyRegion(proxy);
    const freeProxyTimezone = translation('countries.' + freeProxyCountry) || '';

    return { timezone: freeProxyTimezone, country: freeProxyCountry };
  }

  if (status && timezone) {
    return { timezone, country };
  }

  return { timezone: realTimezone, country: realCountry };
};

export const getLanguageByBasedIp = (dataForGetLanguage: IGetLanguagesByBasedIp): string => {
  const { proxyFromCtx, realLocalLanguages = '', realLocalCountry = '' } = dataForGetLanguage;
  const { status = false, languages: languagesFromProxy = '', country: countryFromProxy = '' } = proxyFromCtx || {};
  let languagesByBasedIp = languagesFromProxy;
  let countryByBasedIp = countryFromProxy;
  if (!(status && languagesFromProxy)) {
    languagesByBasedIp = realLocalLanguages;
    countryByBasedIp = realLocalCountry;
  }

  const [firstDetectedLangLocale] = languagesByBasedIp.split(',');
  let resultLangs: string[] = [];
  if (countryByBasedIp) {
    resultLangs.push([firstDetectedLangLocale, countryByBasedIp].join('-'));
  }

  resultLangs.push(firstDetectedLangLocale, 'en-US', 'en');
  resultLangs = [...new Set(resultLangs)];

  return resultLangs.reduce((acc: string, lang: string, index: number) => {
    if (!index) {
      acc += `${lang},`;

      return acc;
    }

    const qualityParam = 10 - index;
    if (qualityParam > 0) {
      const separator = (resultLangs.length - index) < 2 ? '' : ',';
      acc += `${lang};q=${Number(qualityParam * 0.1).toFixed(1)}${separator}`;
    }

    return acc;
  }, '');
};
