import { ITransactionObject } from './helper.functions.interfaces';

export const sentryTransactionDefaultObject: ITransactionObject = {
  startChild: () => ({
    finish: () => ({}),
    setStatus: () => ({}),
  }),
  setStatus: () => ({}),
  setTag: () => ({}),
  finish: () => ({}),
  traceId: '',
};
