import { Radio } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import LabeledElement from '../../../ui/LabeledElement';
import { RadioButton, RemoveMemberButton } from './styles';

interface ILimitedAccessRadio {
  limitedAccess: boolean;
  showGiveAccessText: boolean;
  onToggle: (limitedAccess: boolean) => void;
  onRemoveMember: null | (() => void);
  canSwitchToWorkspace: boolean;
}

const LimitedAccessRadio: React.FC<ILimitedAccessRadio> = (props) => {
  const { canSwitchToWorkspace, onRemoveMember, limitedAccess, showGiveAccessText, onToggle } = props;
  const defaultLimitedAccessValue = limitedAccess || !canSwitchToWorkspace;

  const { t: translation } = useTranslation();

  let selectedFoldersKey = 'permissionSelectMenu.selectedFolders';
  if (onRemoveMember) {
    selectedFoldersKey = 'permissionSelectMenu.selectedFoldersShort';
  }

  return (
    <div style={{ margin: '0 0 20px 0' }}>
      <LabeledElement title={translation('permissionSelectMenu.giveAccessTo')} hideTitle={!showGiveAccessText}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Radio.Group
            value={defaultLimitedAccessValue}
            size='default'
            onChange={(event): void => onToggle(event.target.value)}
          >
            <RadioButton
              value={true}
              style={{ borderRadius: '4px 0px 0px 4px', fontFamily: 'Roboto' }}
            >
              {translation(selectedFoldersKey)}
            </RadioButton>
            <RadioButton
              value={false}
              disabled={!canSwitchToWorkspace}
              style={{ borderRadius: '0px 4px 4px 0px', fontFamily: 'Roboto' }}
            >
              {translation('permissionSelectMenu.workspace')}
            </RadioButton>
          </Radio.Group>
          {(onRemoveMember && limitedAccess) ? (
            <RemoveMemberButton onClick={onRemoveMember}>
              {translation('permissionSelectMenu.removeMemberButton')}
            </RemoveMemberButton>
          ) : null}
        </div>
      </LabeledElement>
    </div>
  );
};

export default LimitedAccessRadio;
