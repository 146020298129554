import { MEMBER_TOKEN } from './config';

export { setAuth } from './actions';
export { configureHttp } from './client';
import { retrieveToken } from './storage';
import { API_BASE_URL, TWO_FA_TOKEN_KEY_NAME } from '../../../common/constants/constants';

export const sendCredentialsToLocalServer = async (type: 'member' | 'access' = 'access', source?: string): Promise<Promise<Response | null> | void> => {
  const isElectron = !!window.require;
  if (!isElectron) {
    return;
  }

  const { ipcRenderer } = window.require('electron');

  const token = type === 'access' ? await retrieveToken() : sessionStorage.getItem(MEMBER_TOKEN);
  const twoFa = localStorage.getItem(TWO_FA_TOKEN_KEY_NAME);

  ipcRenderer.invoke('set-token', { token, twoFa, source });

  return fetch('http://localhost:36912/service/credentials', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      access: token,
      twoFa,
      api: API_BASE_URL,
    }),
  }).catch(() => null);
};
