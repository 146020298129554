import React, { FC } from 'react';

import { HeaderButtonsContainer, HeaderContainer } from './styles';
import { IProfile } from '../../../interfaces';
import { IProfileRunStatus } from '../../../state/profiles-list.atom';
import HeaderRightPart from '../../../ui/gologin-header/header-right-part';
import { IconDoubleArrow } from '../../../ui/gologin-header/icons';
import { useProfileRunStatus } from '../../../state/profile-run-statuses.atom';

interface IProfileSettingsHeader {
  profile: IProfile;
  changeVisibility: () => void;
  renderDropDownMenu: (rowInfo: IProfile & IProfileRunStatus, isInDrawer?: boolean) => JSX.Element|null;
}

const ProfileSettingsHeader: FC<IProfileSettingsHeader> = (props) => {
  const { profile, changeVisibility, renderDropDownMenu } = props;

  const profileRunStatus = useProfileRunStatus(profile?.id);

  return (
    <HeaderContainer>
      <HeaderButtonsContainer>
        <IconDoubleArrow
          onClick={changeVisibility}
          iconColor='var(--767676-profile-new-settings-header)'
          iconHoveredColor='var(--2B2B31-profile-new-settings-header)'
          padding={0}
        />
        <div style={{ marginLeft: 22 }}>
          {renderDropDownMenu({ ...profile, ...profileRunStatus }, true)}
        </div>
      </HeaderButtonsContainer>
      <HeaderRightPart />
    </HeaderContainer>
  );
};

export default ProfileSettingsHeader;
