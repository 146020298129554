import { useTranslate } from '@tolgee/react';
import React, { FC } from 'react';

import { FieldContainer, FieldDescription, FieldTitle } from './styles';
import { IRow } from '../../constants';

interface IPlanFields {
  row: IRow;
}

const PlanField: FC<IPlanFields> = (props) => {
  const { row } = props;

  const { t: translation } = useTranslate();

  if (row.id === 'header' || row.id === 'footer') {
    return (<FieldContainer rowType={row.type} />);
  }

  const translationKey = {
    maxProfiles: {
      title: 'pricing.planTable.browserProfileTitle',
      description: 'pricing.planTable.browserProfileText',
    },
    maxShares: {
      title: 'pricing.planTable.profileShareTitle',
      description: 'pricing.planTable.profileShareText',
    },
    maxAccountShares: {
      title: 'pricing.planTable.teamMemberTitle',
      description: 'pricing.planTable.teamMemberText',
    },
    androidApp: {
      title: 'pricing.planTable.androidAppTitle',
      description: 'pricing.planTable.androidAppText',
    },
    fingerprintsDatabases: {
      title: 'pricing.planTable.fingerprintsDatabaseTitle',
      description: 'pricing.planTable.fingerprintsDatabaseText',
    },
    remoteInstances: {
      title: 'pricing.planTable.cloudLaunchTitle',
      description: 'pricing.planTable.cloudLaunchText',
    },
    cloudBrowsing: {
      title: 'pricing.planTable.cloudBrowseTitle',
      description: 'pricing.planTable.cloudBrowseText',
    },
    restEnabled: {
      title: 'pricing.planTable.restapiTitle',
      description: 'pricing.planTable.restapiText',
    },
  };

  return (
    <FieldContainer rowType={row.type}>
      <FieldTitle>
        {translation(translationKey[row.id].title)}
      </FieldTitle>
      <FieldDescription>
        {translation(translationKey[row.id].description)}
      </FieldDescription>
    </FieldContainer>
  );
};

export default PlanField;
