import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconSettings: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_11845_3685)'>
        <path d='M3.5 0.5H12.5C14.157 0.5 15.5 1.843 15.5 3.5C15.5 5.157 14.157 6.5 12.5 6.5H3.5' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M12.5 15.5H3.5C1.843 15.5 0.5 14.157 0.5 12.5C0.5 10.843 1.843 9.5 3.5 9.5H12.5' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M3.5 6.5C5.15685 6.5 6.5 5.15685 6.5 3.5C6.5 1.84315 5.15685 0.5 3.5 0.5C1.84315 0.5 0.5 1.84315 0.5 3.5C0.5 5.15685 1.84315 6.5 3.5 6.5Z' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M12.5 15.5C14.1569 15.5 15.5 14.1569 15.5 12.5C15.5 10.8431 14.1569 9.5 12.5 9.5C10.8431 9.5 9.5 10.8431 9.5 12.5C9.5 14.1569 10.8431 15.5 12.5 15.5Z' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_11845_3685'>
          <rect width='16' height='16' fill='var(--FFFFFF)' />
        </clipPath>
      </defs>
    </svg>
  </IconWrapper>
);
