import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconEllipse: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='4' cy='4' r='3.5' />
    </svg>
  </IconWrapper>
);
