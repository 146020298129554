import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { InvoiceDetails } from '../../features/personalArea/billing/types/invoice-details.types';

const invoiceDetailsAtom = atom<InvoiceDetails | null>(null);
const savedInvoiceDetailsAtom = atom<InvoiceDetails | null>(null);

export const setInvoiceDetails = (data: InvoiceDetails | null): void => getDefaultStore().set(invoiceDetailsAtom, data);
export const getInvoiceDetails = (): InvoiceDetails | null => getDefaultStore().get(invoiceDetailsAtom);
export const useInvoiceDetails = (): InvoiceDetails | null => useAtomValue(invoiceDetailsAtom);

export const setSavedInvoiceDetails = (data: InvoiceDetails | null): void => {
  getDefaultStore().set(savedInvoiceDetailsAtom, data);
  getDefaultStore().set(invoiceDetailsAtom, data);
};

export const resetUnsavedInvoiceDetails = (): void => {
  const savedInvoiceDetails = getSavedInvoiceDetails();
  setInvoiceDetails(savedInvoiceDetails);
};

export const getSavedInvoiceDetails = (): InvoiceDetails | null => getDefaultStore().get(savedInvoiceDetailsAtom);
export const useSavedInvoiceDetails = (): InvoiceDetails | null => useAtomValue(savedInvoiceDetailsAtom);
