import React from 'react';
export interface IIconDelete {
    style?: React.CSSProperties;
  }

const IconDelete = () => (
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5V3.5ZM15 4.5C15.2761 4.5 15.5 4.27614 15.5 4C15.5 3.72386 15.2761 3.5 15 3.5V4.5ZM2.99953 3.97828C2.98753 3.7024 2.75416 3.48848 2.47828 3.50047C2.2024 3.51247 1.98848 3.74584 2.00047 4.02172L2.99953 3.97828ZM3 15.5L2.50047 15.5217C2.5121 15.7892 2.7323 16 3 16V15.5ZM13 15.5V16C13.2677 16 13.4879 15.7892 13.4995 15.5217L13 15.5ZM13.9995 4.02172C14.0115 3.74584 13.7976 3.51247 13.5217 3.50047C13.2458 3.48848 13.0125 3.7024 13.0005 3.97828L13.9995 4.02172ZM4 1V0.5C3.72386 0.5 3.5 0.723858 3.5 1H4ZM12 1H12.5C12.5 0.723858 12.2761 0.5 12 0.5V1ZM1 4.5H15V3.5H1V4.5ZM2.00047 4.02172L2.50047 15.5217L3.49953 15.4783L2.99953 3.97828L2.00047 4.02172ZM3 16H13V15H3V16ZM13.4995 15.5217L13.9995 4.02172L13.0005 3.97828L12.5005 15.4783L13.4995 15.5217ZM4.5 4V1H3.5V4H4.5ZM4 1.5H12V0.5H4V1.5ZM11.5 1V4H12.5V1H11.5Z" fill="var(--747474)"/>
</svg>

);

export default IconDelete;
