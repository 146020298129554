import { atom, getDefaultStore, useAtomValue } from 'jotai';
import { automationWorkflowByIdAtom } from './workflows.atom';

type WorkflowParametersModalState = {
  isVisible: false;
} | {
  isVisible: true;
  workflowId: string;
  workflowName: string;
  workflowIcon: string;
  profileId?: string;
};

const workflowParametersModalAtom = atom<WorkflowParametersModalState>({ isVisible: false });

export const openWorkflowParametersModal = (workflowId: string, profileId = ''): void => {
  const store = getDefaultStore();
  const getWorkflowData = store.get(automationWorkflowByIdAtom);
  const { name, icon } = getWorkflowData(workflowId);

  getDefaultStore().set(workflowParametersModalAtom, { workflowId, workflowName: name, profileId, workflowIcon: icon, isVisible: true });
}

export const closeWorkflowParametersModal = (): void => {
  getDefaultStore().set(workflowParametersModalAtom, { isVisible: false });
}

export const useWorkflowParametersModal = () => useAtomValue(workflowParametersModalAtom);
