import { useAtomValue } from 'jotai';
import React, { FC } from 'react';

import { ITableCell } from './interfaces';
import { TextCell } from './table/styles';
import { IProfile } from '../../interfaces';

const OsCell: FC<ITableCell> = (props) => {
  const { profileAtom } = props;

  const { os, osSpec, isM1 } = useAtomValue(profileAtom);
  const osNames: Record<IProfile['os'], string> = {
    win: 'Windows 10',
    mac: 'MacOS Intel',
    lin: 'Linux',
    android: 'Android',
  };

  let osToDisplay;

  switch(true){
    case os === 'mac' && (osSpec?.includes('M') || isM1):
      osToDisplay = `MacOS ${osSpec || ''}`;
      break;
    case os === 'win' && osSpec === 'win11':
      osToDisplay = 'Windows 11';
      break;
    default:
      osToDisplay = osNames[os];
  }

  return (
    <TextCell>
      {osToDisplay}
    </TextCell>
  );
};

export default OsCell;
