import { useTranslate } from '@tolgee/react';
import React, { FC, useEffect, useState } from 'react';

import {
  ThreeDSecureWrapper,
  ThreeDSecureButton,
  ThreeDSecureSubtitle,
  ThreeDSecureTitle,
} from './styles';
import { TWO_FA_TOKEN_KEY_NAME } from '../../../../../common/constants/constants';
import { IPlan } from '../../../../interfaces/plan';
import { ACCESS_TOKEN } from '../../../../services/http/config';
import IconModalCheck from '../../../../ui/icons/IconModalCheck';
import { redirectToDesktop } from '../../../authentification/o-auth/utils';

const COUNTER_END = 0;
const COUNTER_INTERVAL = 1;
const COUNTER_PERIOD = 5;

interface ISuccessPaymentModal {
  modalVisible: boolean;
  selectedPlan: string;
  styles?:  React.CSSProperties;
  getPlanInfo: (planId: string, field: keyof IPlan) => JSX.Element | string;
}

const SuccessPaymentModal: FC<ISuccessPaymentModal> = (props) => {
  const {
    modalVisible,
    selectedPlan,
    styles,
    getPlanInfo,
  } = props;

  const [closeCounter, setCloseCounter] = useState<number>(COUNTER_PERIOD);

  const { t: translation } = useTranslate();

  const redirectToApp = (): void => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN) || '';
    const twoFaToken = localStorage.getItem(TWO_FA_TOKEN_KEY_NAME) || '';
    redirectToDesktop({ accessToken, twoFaToken });
  };

  useEffect(() => {
    if (!modalVisible || closeCounter < COUNTER_END) {
      return;
    }

    if (closeCounter === COUNTER_END) {
      redirectToApp();

      return;
    }

    const timer = setInterval(() => setCloseCounter(prev => prev - COUNTER_INTERVAL), 1000);

    return () => clearInterval(timer);
  }, [modalVisible, closeCounter]);

  return (
    <>
      <ThreeDSecureWrapper style={styles}>
        <IconModalCheck />
        <div>
          <ThreeDSecureTitle id='modal-title'>
            {getPlanInfo(selectedPlan, 'name')}
            {' '}
            {translation('pricing.paymentModal.trial.plan')}
            {' '}
            {translation('pricing.checkoutModal.activated')}
            {'!'}
          </ThreeDSecureTitle>
          <ThreeDSecureSubtitle id='modal-description'>
            {translation('pricing.checkoutModal.redirectTextFirst')}
            {' '}
            {closeCounter}
            {' '}
            {translation('pricing.checkoutModal.redirectTextSecond')}
          </ThreeDSecureSubtitle>
        </div>
        <ThreeDSecureButton onClick={redirectToApp}>
          {translation('pricing.checkoutModal.profilesBtn')}
        </ThreeDSecureButton>
      </ThreeDSecureWrapper>
    </>
  );
};

export default SuccessPaymentModal;
