import React, { FC, KeyboardEvent, useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { DescriptionContainer } from './styles';
import { userContext, workspaceContext } from '../../../../state';
import GologinModal from '../../../../ui/gologin-modal';
import { GreenSubmitButton } from '../../../../ui/modern-modal';
import { GologinInput } from '../../../../ui/styles/input';
import { createWorkspace } from '../../../common/api';
import onWorkspaceCreated from '../../../common/on-workspace-created';

interface ICreateWorkspaceModal {
  visible: boolean;
  onClose: () => void;
}

const CreateWorkspaceModal: FC<ICreateWorkspaceModal> = props => {
  const { visible, onClose } = props;

  const [workspaceName, setWorkspaceName] = useState<string>('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);

  const userCtx = useContext(userContext);
  const workspaceCtx = useContext(workspaceContext);

  const { t: translation } = useTranslation();

  useEffect(() => {
    setIsSubmitDisabled(!workspaceName.trim());
  }, [workspaceName]);

  const onCreateWorkspace = async (): Promise<void> => {
    if (isSubmitDisabled) {
      return;
    }

    onClose();

    const createdWorkspace = await createWorkspace(workspaceName);
    await onWorkspaceCreated(createdWorkspace.id, workspaceCtx, userCtx);
    setWorkspaceName('');
    setIsSubmitDisabled(true);
  };

  const onKeyPress = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onCreateWorkspace();
    }
  };

  return (
    <GologinModal
      isVisible={visible}
      onClose={(): void => {
        onClose();
        setWorkspaceName('');
      }}
      width='380px'
      title={translation('modals.createWorkspace.title')}
    >
      <DescriptionContainer>
        <Trans i18nKey='modals.createWorkspace.description' />
      </DescriptionContainer>
      <GologinInput
        value={workspaceName}
        onChange={(event): void => setWorkspaceName(event.currentTarget.value)}
        placeholder={translation('modals.createWorkspace.workspaceName') || ''}
        autoFocus={true}
        onKeyPress={onKeyPress}
      />
      <GreenSubmitButton style={{ margin: '32px auto 0' }} onClick={onCreateWorkspace} disabled={isSubmitDisabled}>
        {translation('modals.createWorkspace.create')}
      </GreenSubmitButton>
    </GologinModal>
  );
};

export default CreateWorkspaceModal;
