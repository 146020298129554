import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconPaste: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M12.5 1.5H13.5C13.7652 1.5 14.0196 1.60536 14.2071 1.79289C14.3946 1.98043 14.5 2.23478 14.5 2.5V14.5C14.5 14.7652 14.3946 15.0196 14.2071 15.2071C14.0196 15.3946 13.7652 15.5 13.5 15.5H2.5C2.23478 15.5 1.98043 15.3946 1.79289 15.2071C1.60536 15.0196 1.5 14.7652 1.5 14.5V2.5C1.5 2.23478 1.60536 1.98043 1.79289 1.79289C1.98043 1.60536 2.23478 1.5 2.5 1.5H3.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M10.5 0.5H5.5V2.5H10.5V0.5Z' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4.5 6.5H11.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4.5 9.5H11.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4.5 12.5H11.5' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  </IconWrapper>
);
