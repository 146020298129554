import styled from '@emotion/styled/macro';

import { NoGroupsProxyManagerProxyStatusWrapper } from './proxy-connected-status/styles';
import { UnlinkButtonWrapper } from './proxy-unlink-button/styles';

export const ProxyConnectionButtonWrapper = styled.div`
  color: var(--767676-proxy-manager-list-item);

  ${UnlinkButtonWrapper} {
    display: none;
  }
  
  ${NoGroupsProxyManagerProxyStatusWrapper} {
    display: flex;
  }

  :hover {
    ${UnlinkButtonWrapper} {
      display: flex;
    }

    ${NoGroupsProxyManagerProxyStatusWrapper} {
      display: none;
    }
  }
`;
