import styled from '@emotion/styled/macro';

export const ProxyStatusDot = styled.div<{ isProxyManagerWithGroups: boolean; status?: string }>`
  width: 16px;
  height: 16px;

  &:after {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: 4px;
    display: block;

    background: ${(props): React.CSSProperties['background'] => {
    switch (true) {
      case props.status === 'success':
        return 'var(--00A987-proxy-manager)';
      case props.status === 'fail':
        return props.isProxyManagerWithGroups ? 'var(--EB5757)' : 'var(--F5222D-proxy-manager-check-proxy-button)';
      default:
        return props.isProxyManagerWithGroups ? 'var(--B5B5BA)' : 'var(--D9D9D9-proxy-manager-check-proxy-button)';
    }
  }}
  }
`;
