import { useTranslate } from '@tolgee/react';
import React, { FC } from 'react';

import IconArrowSelect from '../../../../ui/icons/IconArrowSelect';
import { ModernSelect } from '../../../../ui/modern-select';
import { IMappedPlan } from '../../interfaces';
import { getSelectedCustomPlan } from '../../plans-data';
import { IconCheckboxContainer, SelectButtonContainer, SelectTitle } from '../preview-cards/styles';

interface ICustomPlanSelect {
  previewCustomPlans: IMappedPlan[];
  selectedCustomPlanId: string;
  setSelectedCustomPlanId: (planId: string) => void;
}

const CustomPlanSelect: FC<ICustomPlanSelect> = (props) => {
  const {
    previewCustomPlans,
    selectedCustomPlanId,
    setSelectedCustomPlanId,
  } = props;

  const { t: translation } = useTranslate();

  const selectedCustomPlan = getSelectedCustomPlan(previewCustomPlans, selectedCustomPlanId);

  const selectItems = previewCustomPlans.map((previewCustomPlan) => {
    if (!previewCustomPlan.id) {
      throw new Error('Custom plan is not defined');
    }

    return ({
      title: `${previewCustomPlan.maxProfiles} ${translation('pricing.customSelectProfile.profiles', { count: previewCustomPlan.maxProfiles })}`,
      value: previewCustomPlan.id,
    });
  });

  const SelectCustom: JSX.Element = (
    <SelectButtonContainer>
      <SelectTitle>
        {selectedCustomPlan.maxProfiles}
      </SelectTitle>
      <IconCheckboxContainer>
        <IconArrowSelect />
      </IconCheckboxContainer>
    </SelectButtonContainer>
  );

  return (
    <ModernSelect
      currentValue={selectedCustomPlanId}
      itemList={selectItems}
      onSelected={(value): void => setSelectedCustomPlanId(value)}
      targetElement={SelectCustom}
      zIndex={1400}
      isDisableDarkTheme={true}
    />
  );
};

export default CustomPlanSelect;
