import styled from '@emotion/styled';
import { Radio, Button, Select } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { macArmOptions } from '../../common/settings/browser/mac-arm-options';

const TabContainer = styled('div')`
  font-size: 12px;
  line-height: 20px;
  color: var(--000000-update-profile-overview);
`;

const ContentOperationSystem = styled('div')`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
`;

const ButtonNewFingerprint = styled(Button)`
  font-size: 12px;
  margin-top: 10px;
  color: var(--1FC47D-update-profile-overview);
  border-color: var(--1FC47D-update-profile-overview);
  font-size: 12px;
  :hover {
    opacity: 0.7;
  }
  padding: 0 10px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: 'center;
`;

const Subtitle = styled('span')`
  display: block;
  font-size: 12px;
  color: var(--000000-update-profile-overview);
  margin-bottom: 10px;
`;

const RadioButton = styled(Radio.Button)`
  width: 90px;
  height: 30px;
  text-align: center;
  font-size: 12px;
  line-height: 29px;
  @media screen and (max-width: 325px) {
    width: 75px;
  }
`;

// any to be able to set type in implementation
export const SelectAdvanced = styled<any>(Select)`
  margin-top: 20px;
  
  width: 111px;
  @media screen and (max-width: 450px) {
    width: 100%;
  }
`;

type TOsPickOptions = 'win' | 'win11' | 'mac' | 'macArm' | 'lin' | 'android';

interface IOverviewTab {
  updateProfileInfo: any;
  profileInfo: any;
  updateFingerprint: any;
  setUpdatedField: (filed: string) => any;
}
const OverviewTab: FC<IOverviewTab> = (props) => {
  const { updateProfileInfo, profileInfo, updateFingerprint, setUpdatedField } = props;

  const { t: translation } = useTranslation();

  const updateOsValue = async (event: RadioChangeEvent): Promise<void> => {
    const os = event.target.value;

    await updateProfileInfo({
      os,
    });

    setUpdatedField('os');
  };

  const updateFingerprintAndSetField = (): void => {
    updateFingerprint();
    setUpdatedField('fingerprint');
  };

  const getRadioValue = () => {
    if (profileInfo.isM1 || profileInfo.osSpec?.includes('M')) {
      return 'macArm';
    }

    if (profileInfo.osSpec === 'win11') {
      return 'win11';
    }

    return profileInfo.os;
  };

  const getDisabledFields = (field: TOsPickOptions): boolean => {
    const { os, isM1, osSpec } = profileInfo;

    if (osSpec === 'win11') {
      return field !== 'win11';
    }

    if (os === 'mac' && (isM1 || osSpec?.includes('M'))) {
      return field !== 'macArm';
    }

    return os !== field;
  };

  const getOsSpecSelector = (): ReactElement | null => {
    if (getRadioValue() === 'macArm') {
      return (
        <SelectAdvanced
          disabled={true}
          value={profileInfo.osSpec}
          onChange={(value: string): void => {
            updateProfileInfo({
              osSpec: value,
            });
            setUpdatedField('osSpec');
          }}
        >
          {macArmOptions.map((option) => (
              <Select.Option value={option} key={option}>
                {option}
              </Select.Option>
            ))}
        </SelectAdvanced>
      );
    }

    return null;
  };

  return (
    <TabContainer>
      <ContentOperationSystem>
        <Subtitle>
          {translation('base.operatingSystem')}
        </Subtitle>
        <Radio.Group
          value={getRadioValue()}
          size='default'
          onChange={updateOsValue}
        >
          <RadioButton disabled={getDisabledFields('win')} value='win'>
            Windows
          </RadioButton>
          <RadioButton disabled={getDisabledFields('win11')} value='win11' style={{ width: 100 }}>
            Windows 11
          </RadioButton>
          <RadioButton disabled={getDisabledFields('mac')} value='mac'>
            Mac Intel
          </RadioButton>
          <RadioButton disabled={getDisabledFields('macArm')} value='macArm'>
            Mac ARM
          </RadioButton>
          <RadioButton disabled={getDisabledFields('lin')} value='lin'>
            Linux
          </RadioButton>
          <RadioButton disabled={getDisabledFields('android')} value='android'>
            Android
          </RadioButton>
        </Radio.Group>
        {getOsSpecSelector()}
        <div>
          <ButtonNewFingerprint
            onClick={updateFingerprintAndSetField}
          >
            {translation('editProfile.overview.updateFingerprintBtnText')}
          </ButtonNewFingerprint>
        </div>
      </ContentOperationSystem>
    </TabContainer>
  );
};

export default OverviewTab;
