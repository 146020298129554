import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconRefreshTwoArrows: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <path
        d='M1.5 7.99926C1.49974 6.5932 1.95542 5.22496 2.79867 4.09982C3.64193 2.97468 4.82729 2.15331 6.17692 1.75895C7.52655 1.36458 8.96766 1.41849 10.2841 1.91257C11.6005 2.40665 12.7212 3.31427 13.478 4.49926'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.5 0.5V4.5H9.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.5005 8C14.5007 9.40606 14.045 10.7743 13.2018 11.8994C12.3585 13.0246 11.1732 13.8459 9.82354 14.2403C8.47391 14.6347 7.0328 14.5808 5.7164 14.0867C4.4 13.5926 3.27931 12.685 2.52246 11.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.5 15.5V11.5H6.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  </IconWrapper>
);
