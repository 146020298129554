import { updateMigrationData } from './api';
import { IMigrationData } from '../../../electron/interfaces/check-competitors.interfaces';

export const checkCompetitorsForMigration = async (migrationData: IMigrationData, ipcRenderer: any): Promise<void> => {
  const checkResult = await ipcRenderer.invoke('check-competitors-apps-for-migration', {
    migrationData,
  }).catch(() => null);

  if (!checkResult) {
    return;
  }

  const { isDolphinChecked = false } = checkResult;
  const requestBody: any = {};
  if (isDolphinChecked) {
    requestBody.isDolphinChecked = true;
  }

  updateMigrationData(requestBody).catch(() => void 0);
};
