// This is an anti-pattern, hard to maintain.
// Yet, for now it solves the issues in an abundance of check-proxy flows.
// Nonetheless, it should be replaced with another pattern.
// Perhaps, ids for the proxy-status-dots & for the tooltips.
export const PROXY_CHECK_TOOLTIP_LOCATIONS = <const>{
  selectorProfileTable: 'selector-profile-table',
  selectorProfileSettingsFavourite: 'selector-profile-settings-favourite',
  selectorProfileSettingsProxy: 'selector-profile-settings-proxy',
  proxyUngroupedListItem: 'proxy-ungrouped-list-item',
  proxyGroupedListItem: 'proxy-grouped-list-item',
  proxyManagerEditForm: 'proxy-manager-edit-form',
  proxyPageTable: 'proxy-page-table',
  profileSettingsPage: 'profile-settings-page',
  none: null,
};
