import React, { FC } from 'react';

import { requestFingerprint } from '../../../../features/common/api';
import { OSWithM1 } from '../../../../utils/user-os';
import { ModernSelect } from '../../../../ui/modern-select';
import { ISelectItem } from '../../../../ui/modern-select/interfaces';
import { ISetting } from '../../interfaces';
import { ContainerParams } from '../../styles';

export const OperatingSystem: FC<ISetting> = ({ changeSetting, fullProfile })  => {
  const updateFingerprint = async ({ os = 'win', isM1 = false }): Promise<void> => {
    const resolution = `${screen.width}x${screen.height}`;

    const fingerprint = await requestFingerprint({ os, resolution, isM1 }).catch(() => ({}));
    const { os: fingerprintOS } = fingerprint;

    if (os !== fingerprintOS) {
      return;
    }

    const newProfile = {
      ...fullProfile,
      os: fingerprintOS,
      isM1,
    };

    changeSetting(newProfile);
  };

  const onChange = (event: OSWithM1): void => {
    updateFingerprint({
      os: event === 'macM1' ? 'mac' : event,
      isM1: event === 'macM1',
    });
  };

  const osToRender: ISelectItem[] = [
    { title: 'Windows', value: 'win' },
    { title: 'MacOS', value: 'mac' },
    { title: 'MacOS M1', value: 'macM1' },
    { title: 'Linux', value: 'lin' },
    { title: 'Android', value: 'android' },
  ];

  return (
    <ContainerParams>
      <ModernSelect
        currentValue={fullProfile.os}
        itemList={osToRender}
        onSelected={(value): void => onChange(value as OSWithM1)}
      />
    </ContainerParams>
  );
};
