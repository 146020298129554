import { getIconsConstants } from './constants';
import { drawRectRound } from './draw-rect-round';
import { drawText } from './draw-text';
import { IGenerateBrowserIconsData } from './interfaces';
import { BrowserIconSize } from './types';
import { initCanvas } from './utils';
import { ITransactionObject } from '../../utils/sentry-parameters/helper.functions.interfaces';

export const generateBrowserIcons = ({
  profileId,
  profileName,
  osFormatted,
  scaleFactor,
  iconDesignVersion,
  isInitialRender = false,
}: IGenerateBrowserIconsData, transaction?: ITransactionObject): string[] => {
  if (!(osFormatted && iconDesignVersion === '2:green-square')) {
    return [];
  }

  const initCanvasSpan = transaction?.startChild({ op: 'init-canvas' });
  let { browserIconsSizes } = getIconsConstants(osFormatted);
  if (osFormatted !== 'linux') {
    browserIconsSizes = browserIconsSizes.map(size => size * scaleFactor);
    if (osFormatted === 'win10') {
      const [iconBigSize] = browserIconsSizes;
      const iconSmallSize = iconBigSize / 2;
      browserIconsSizes = [iconBigSize, iconSmallSize];
    }
  }

  const canvases = browserIconsSizes.map(initCanvas);
  initCanvasSpan?.finish();

  const getContextSpan = transaction?.startChild({ op: 'get-context' });
  const contexts = canvases.map(canvas => canvas.getContext('2d'));
  getContextSpan?.finish();

  const drawRectWithGradientSpanRound = transaction?.startChild({ op: 'draw-background' });
  contexts.forEach((context, index) => drawRectRound({
    context,
    scaleFactor,
    osFormatted,
    size: browserIconsSizes[index],
  }));

  drawRectWithGradientSpanRound?.finish();

  const drawTextSpan = transaction?.startChild({ op: 'draw-symbols' });
  contexts.forEach((context, index) => {
    if (!context) {
      return;
    }

    const size: BrowserIconSize = `${browserIconsSizes[index]}`;
    const iconLabelParamsBase = { context, size, profileName };
    drawText({ ...iconLabelParamsBase, osFormatted, scaleFactor });
  });

  drawTextSpan?.finish();
  if (isInitialRender) {
    canvases.map(canvas => canvas.remove);

    return [];
  }

  const convertCanvasToPngSpan = transaction?.startChild({ op: 'convert-canvas-to-png' });
  const pngs = canvases.map(canvas => canvas.toDataURL('image/png', 1));
  convertCanvasToPngSpan?.finish();

  return pngs;
};
