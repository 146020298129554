import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { usePaymentModalPaymentType } from './payment-modal.atom';
import { EMPTY_DISCOUNT } from '../../features/pricing/constants';
import { IDiscountShort, PaymentType } from '../../features/pricing/interfaces';

const promoDiscountAtom = atom<IDiscountShort>(EMPTY_DISCOUNT);
const isPromoDiscountAvailableAtom = atom<boolean>(false);

export const usePromoDiscount = (): IDiscountShort => useAtomValue(promoDiscountAtom);
const useIsPromoAvaliable = (): boolean => useAtomValue(isPromoDiscountAvailableAtom);

export const usePromoDiscountAvaliable = (): IDiscountShort => {
  const isPromoAvaliable = useIsPromoAvaliable();
  const paymentMethod = usePaymentModalPaymentType();
  const promoDiscount = usePromoDiscount();

  if (paymentMethod === PaymentType.PayPal || !isPromoAvaliable) {
    return EMPTY_DISCOUNT;
  }

  return promoDiscount;
};

export const getIsPromoDiscountAvaliable = (): boolean => getDefaultStore().get(isPromoDiscountAvailableAtom);
export const setIsPromoDiscountAvaliable = (isPromoDiscountAvailable: boolean): void =>
  getDefaultStore().set(isPromoDiscountAvailableAtom, isPromoDiscountAvailable);

export const setPromoDiscount = (data: IDiscountShort): void => getDefaultStore().set(promoDiscountAtom, data);
export const getPromoDiscount = (): IDiscountShort => getDefaultStore().get(promoDiscountAtom);
