import React, { memo, useEffect } from 'react';
import { getAutomationWorkflowDataById } from '../../../state/automation/workflows.atom';
import { upsertAutomationProfileStatuses } from '../../../state/automation-profile-statuses.atom';

let ipcRenderer: Electron.IpcRenderer;
const isElectron = !!window.require;
if (isElectron) {
  ({ ipcRenderer } = window.require('electron'));
}

const IpcGlobalHandler: React.FC = () => {
  useEffect(() => {
    if (!ipcRenderer) {
      return;
    }
    
    ipcRenderer.on('update-automation-profile-status', (_, res) => {
      const { workflowId } = res;
      const { name, icon = '' } = getAutomationWorkflowDataById(workflowId);
      upsertAutomationProfileStatuses([{ ...res, name, icon }]);
    });

    return () => {
      ipcRenderer.removeAllListeners('update-automation-profile-status');
    };
  }, [ipcRenderer]);

  return null;
};

export default memo(IpcGlobalHandler);
