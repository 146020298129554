import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IFullProfile, WEBGL_IMAGE_MODES, WebglImageModesType } from '../../../../interfaces';
import { ModernSelect } from '../../../../ui/modern-select';
import { ContainerParams } from '../../styles';

interface IWebglImageComponent {
  mode: WebglImageModesType;
  changeSetting: (profileData: Partial<IFullProfile>) => void;
}

export const WebglImage: FC<IWebglImageComponent> = ({ changeSetting, mode }) => {
  const { t: translation } = useTranslation();

  const titleSelect: Record<WebglImageModesType, string> = {
    noise: translation('quickSettings.parameters.maskedWithNoise'),
    off: translation('quickSettings.parameters.real'),
  };

  const selectItems = WEBGL_IMAGE_MODES.map(webglImageMode => ({
    title: titleSelect[webglImageMode],
    value: webglImageMode,
  }));

  return (
    <ContainerParams>
      <ModernSelect
        currentValue={mode}
        itemList={selectItems}
        onSelected={(value): void => changeSetting({ webGL: { mode: value as WebglImageModesType } })}
      />
    </ContainerParams>
  );
};
