import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconSearch: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_11246_25946)'>
        <path d='M15.5 15.5L12.5 12.5' strokeLinecap='round' strokeLinejoin='round' />
        <path
          d='M6.5 12.5C9.81371 12.5 12.5 9.81371 12.5 6.5C12.5 3.18629 9.81371 0.5 6.5 0.5C3.18629 0.5 0.5 3.18629 0.5 6.5C0.5 9.81371 3.18629 12.5 6.5 12.5Z'
          strokeLinecap='round' strokeLinejoin='round' />
      </g>
    </svg>
  </IconWrapper>
);
