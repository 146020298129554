import styled from '@emotion/styled';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IProfile } from '../../../interfaces';
import { IAddTag, ITag, ITagBase, TagField } from '../interfaces/tag.interface';
import EditModeTags from './edit-mode-tags';

const TagsContainer = styled('div')`
  background: var(--FFFFFF-tags-multiple);
  box-shadow: 0px 1px 4px var(--00000003-tags-multiple), 0px 4px 8px var(--00000005-tags-multiple), 0px 1px 12px var(--0000001F-tags-multiple);
  border-radius: 8px;
  padding: 16px 16px 12px;
  width: 301px;
  position: absolute;
  top: 4px;
  left: -50px;
  font-family: 'Roboto';
  font-style: normal;
`;

const TitleEditTags = styled('div')`
  color: var(--767676-tags-multiple);
  width: 100%;
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  font-family: Roboto;
  font-style: normal;
`;

interface IProps {
  profileList: any[];
  selectedRows: string[];
  updateTag: (tag: ITagBase) => Promise<void>;
  addTag: (params: IAddTag) => Promise<void>;
  removeTag: (tagId: string, isInSuggest: boolean) => Promise<void>;
  removeProfileTag: (profileIds: string[], tagId: string) => Promise<void>;
  field: TagField;
}

const MultipleTagsPopper: FC<IProps> = (props: IProps) => {
  const {
    profileList,
    selectedRows,
    updateTag,
    addTag,
    removeTag,
    removeProfileTag,
    field,
  } = props;

  const { t: translation } = useTranslation();

  const { filteredProfiles, tags } = React.useMemo(() => {
    const filteredProfilesArr = selectedRows.reduce<IProfile[]>((acc, id) => {
      const profileFromList = profileList.find(profile => profile.id === id);
      if (profileFromList) {
        acc.push(profileFromList);
      }

      return acc;
    }, []);

    const tagsArr: ITag[] = filteredProfilesArr.reduce<ITag[]>(
      (acc, profile) => acc.concat(profile.tags.filter(tag => tag.field === field) || []),
      [],
    );

    const { inAllProfilesTags, otherTags } = tagsArr.reduce<{ inAllProfilesTags: ITag[]; otherTags: ITag[] }>((acc, tag) => {
      const allTags = [...acc.inAllProfilesTags, ...acc.otherTags];
      const tagInRes = allTags.find(resTag => resTag.id === tag.id);
      if (tagInRes) {
        return acc;
      }

      const tagItem = { ...tag };
      tagItem.inAllProfiles = true;
      tagItem.profilesWithTag = filteredProfilesArr.reduce((profilesWithTag, profile) => {
        const profileTags = profile.tags || [];
        const tagInProfile = profileTags.find(profileTag => profileTag.id === tag.id);
        if (tagInProfile) {
          return profilesWithTag + 1;
        }

        tagItem.inAllProfiles = false;

        return profilesWithTag;
      }, 0);

      const arrName = tagItem.inAllProfiles ? 'inAllProfilesTags' : 'otherTags';
      acc[arrName] = [...acc[arrName], tagItem];

      return acc;
    }, { inAllProfilesTags: [], otherTags: [] });

    return {
      filteredProfiles: filteredProfilesArr,
      tags: [
        ...inAllProfilesTags,
        ...otherTags,
      ],
    };
  }, [profileList, selectedRows]);

  return (
    <TagsContainer>
      <TitleEditTags>
        {translation('tags.mixedProfileTags')}
      </TitleEditTags>
      <EditModeTags
        tags={tags}
        profileIds={selectedRows}
        addTag={addTag}
        updateTag={updateTag}
        removeTag={removeTag}
        removeProfileTag={(removeProfileTag)}
        isAddMode={true}
        profilesList={filteredProfiles}
        isBulkMode={true}
        field={field}
      />
    </TagsContainer>
  );
};

export default MultipleTagsPopper;
