import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonWrapper, Text } from './styles';
import { ShareRole } from '../../../../../../interfaces';
import { IconPlus } from '../../../../../../ui/gologin-header/icons';
import { createProfileShareLinks } from '../api';
import { IProfileToShare } from '../interfaces';

interface IAddLinkButton {
  profiles: IProfileToShare[];
  setShareLinks: React.Dispatch<React.SetStateAction<{ _id: string; url: string; role: ShareRole }[]>>;
}

export const AddLinkButton: React.FC<IAddLinkButton> = (props) => {
  const { profiles, setShareLinks } = props;

  const [isEnabled, setIsEnabled] = useState(true);

  const { t: translation } = useTranslation();

  const handleClick: React.MouseEventHandler<HTMLDivElement> = async (event) => {
    event.preventDefault();
    if (!isEnabled) {
      return;
    }

    setIsEnabled(false);
    if (profiles.length !== 1) {
      return;
    }

    const { _id, url } = await createProfileShareLinks(profiles);
    if (!url) {
      return;
    }

    setShareLinks(shareLinksOld => [...shareLinksOld, { _id, url, role: 'guest' }]);
  };

  return (
    <ButtonWrapper
      onClick={handleClick}
      iconType='stroke'
      colorIconType='activeGray'
      iconColor='var(--B5B5BA)'
      iconHoveredColor='var(--222222)'
      textColor='var(--81818A)'
      textHoveredColor='var(--222222)'
    >
      <IconPlus padding={0} margin='0 10px 0 0' />
      <Text>
        {translation('newShareModal.links.addNewLinkButton')}
      </Text>
    </ButtonWrapper>
  );
};
