import React from 'react';

import { GeoProxyType } from '../../../../../../common/constants/types';
import { IProxy } from '../../../../../interfaces';
import { IconHome } from '../../../../../ui/gologin-header/icons/icon-home';
import { IconProxyDataCenterSmall } from '../../../../../ui/gologin-header/icons/icon-proxy-datacenter-small';
import { IconProxyMobile } from '../../../../../ui/gologin-header/icons/icon-proxy-mobile';
import { IIconWrapper } from '../../../../../ui/gologin-header/icons/wrapper';
import { sendReactErrorToSentry } from '../../../../../utils/sentry.helper';
import { GEOPROXY_TYPE_ICON_NOT_SHOWN_ERROR, GEOPROXY_TYPE_ICON_NOT_SHOWN_ERROR_MESSAGE, PROXY_ID_TAG } from '../../../constants';

const iconsMap: Record<GeoProxyType, React.FC<IIconWrapper>> = {
  resident: IconHome,
  mobile: IconProxyMobile,
  dataCenter: IconProxyDataCenterSmall,
};

type GeoProxyTypeIconProps = Pick<IProxy, 'id'|'connectionType'>;

const GeoProxyTypeIcon: React.FC<GeoProxyTypeIconProps> = (props) => {
  const { id, connectionType } = props;

  if (!connectionType) {
    sendReactErrorToSentry({
      transactionName: GEOPROXY_TYPE_ICON_NOT_SHOWN_ERROR,
      message: GEOPROXY_TYPE_ICON_NOT_SHOWN_ERROR_MESSAGE,
      tags: [[PROXY_ID_TAG, id]],
    });

    return null;
  }

  const Icon = iconsMap[connectionType];

  return (
    <Icon
      padding={0}
      iconColor='var(--B5B5BA-geoproxy-connection-type-icon)'
    />
  );
};

export default GeoProxyTypeIcon;
