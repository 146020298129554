import { IProxyModalSize } from '../interfaces/proxy-modal-size.interface';

// keeping these sizes as clones, because they are prone to change in the design
export const UNGROUPED_PROXY_MANAGER_SIZE: IProxyModalSize = {
  width: 330,
  height: 278,
};

export const GROUPED_PROXY_MANAGER_SIZE: IProxyModalSize = {
  width: 330,
  height: 272,
};
