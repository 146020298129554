import { Popconfirm } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IProfilesMenu } from './interfaces';
import { MassActionsButtonLine, MassActionsMarginBlock } from './styles';
import { useIsProfilesSettingsOpen } from '../../../state/profiles-settings-atom';
import {
  getProfilesTableSelectedProfilesActions, resetProfilesTableSelectedIds,
  toggleAllProfilesSelected,
  useAllProfilesSelectionStatus, useProfilesTableSelectedIds,
} from '../../../state/profiles-table-selected-ids.atom';
import { GologinNewCheckbox } from '../../../ui/gologin-checkbox/gologin-new-checkbox';
import {
  IconBookmark,
  IconClone2,
  IconClose,
  IconExport,
  IconFolder,
  IconPen, IconPlay,
  IconPlug,
  IconRefresh,
  IconShare,
  IconStop2,
  IconSwap,
  IconTrash,
} from '../../../ui/gologin-header/icons';
import { IconProxyDataCenter } from '../../../ui/gologin-header/icons/icon-proxy-datacenter';
import {
  getActionsButtonStyles,
  MassActionsButtonText,
  MassActionsRow,
} from '../../common/custom-table/components/mass-actions-menu/styles';
import EditTagsMenuBtn from '../components/edit-tags-menu-btn';

const isElectron = !!window.require;

const MultipleOperationsMenu: FC<IProfilesMenu> = (props): JSX.Element => {
  const {
    isBrowserUpdating,
    isProcessing,
    toggleModalSharing,
    openFoldersModal,
    openBookmarksManager,
    toggleModalTransfer,
    openExtensionsModal,
    cloneProfile,
    updateTag,
    addTag,
    removeProfileTag,
    removeTag,
    updateFingerprints,
    deleteOrLeaveMultipleProfiles,
    onRenameProfiles,
    showExportPayPopUp,
    setShowExportPayPopUp,
    redirectToSite,
    isStartDisabled,
    stopProfiles,
    startProfiles,
    limitForLaunch,
    setIsShowProxyManager,
    getLoader,
    exportProfile,
    setAnchorElTooltip,
  } = props;

  const isProfilesSettingsOpen = useIsProfilesSettingsOpen();
  const selectedProfilesIds = useProfilesTableSelectedIds();
  const selectedProfilesActions = getProfilesTableSelectedProfilesActions();

  const { t: translation } = useTranslation();

  const allRowsSelectionStatus = useAllProfilesSelectionStatus();
  const isSelected = allRowsSelectionStatus === 'all';
  const isIndeterminate = allRowsSelectionStatus === 'some';

  const buttonMassRun = (disabled: boolean, onClickAction?: () => void): JSX.Element => (
    <MassActionsButtonLine
      onClick={onClickAction}
      {...getActionsButtonStyles(disabled)}
    >
      <MassActionsRow>
        <IconPlay
          padding={0}
          size={12}
        />
        <MassActionsButtonText>
          {translation('profiles.multipleOperations.run')}
        </MassActionsButtonText>
      </MassActionsRow>
    </MassActionsButtonLine>
  );

  const renderButtonMassRun = (disabled: boolean, onClickAction?: () => void): JSX.Element => {
    if (disabled) {
      return (
        <div
          onMouseEnter={(event): void => setAnchorElTooltip(event.currentTarget)}
          onMouseLeave={(): void => setAnchorElTooltip(null)}
          title={translation('notifications.error.orbitaIsDownloading')}
        >
          {buttonMassRun(disabled, onClickAction)}
        </div>
      );
    }

    return buttonMassRun(disabled, onClickAction);
  };

  return (
    <>
      <MassActionsMarginBlock isProfilesSettingsOpen={isProfilesSettingsOpen}>
        <MassActionsButtonLine
          {...getActionsButtonStyles(isProcessing)}
          style={{ cursor: 'default' }}
        >
          <MassActionsRow>
            <GologinNewCheckbox
              onChange={toggleAllProfilesSelected}
              checked={isSelected}
              isIndeterminate={isIndeterminate}
              isSmall={true}
            />
            <MassActionsButtonText>
              {translation('tableProfiles.multipleOperations.selected', { count: selectedProfilesIds.length })}
            </MassActionsButtonText>
            <IconClose
              onClick={resetProfilesTableSelectedIds}
              padding={0}
              iconColor='var(--B5B5BA-header-account-menu)'
              iconHoveredColor='var(--36363D)'
              size={12}
            />
          </MassActionsRow>
        </MassActionsButtonLine>
        {isElectron ? (
          <>
            {!isBrowserUpdating && isStartDisabled
              ? (
                <Popconfirm
                  title={translation('profiles.multipleOperations.confirms.startProfilesFirstPart') + limitForLaunch + translation('profiles.multipleOperations.confirms.startProfilesSecondPart')}
                  placement='bottom'
                  onConfirm={startProfiles}
                  okText={translation('base.yes')}
                  cancelText={translation('base.no')}
                >
                  {renderButtonMassRun(isBrowserUpdating)}
                </Popconfirm>
              )
              : renderButtonMassRun(isBrowserUpdating, startProfiles)}
          </>
        ) : null}
        <MassActionsButtonLine {...getActionsButtonStyles(isProcessing)} onClick={stopProfiles}>
          <MassActionsRow>
            <IconStop2
              padding={0}
              size={12}
            />
            <MassActionsButtonText>
              {translation('profiles.multipleOperations.stop')}
            </MassActionsButtonText>
          </MassActionsRow>
        </MassActionsButtonLine>
        <MassActionsButtonLine
          {...getActionsButtonStyles(isProcessing)}
          onClick={(): void => openFoldersModal(selectedProfilesIds)}
        >
          <MassActionsRow>
            <IconFolder
              padding={0}
              size={12}
            />
            <>
              {translation('profiles.multipleOperations.addToFolder')}
            </>
          </MassActionsRow>
        </MassActionsButtonLine>
        <MassActionsButtonLine
          onClick={(): void => setIsShowProxyManager(true)}
          {...getActionsButtonStyles(isProcessing)}
        >
          <MassActionsRow>
            <IconProxyDataCenter
              padding={0}
              size={12}
            />
            <MassActionsButtonText>
              {translation('proxy.title')}
            </MassActionsButtonText>
          </MassActionsRow>
        </MassActionsButtonLine>
        <MassActionsButtonLine
          {...getActionsButtonStyles(isProcessing)}
          onClick={(): void => toggleModalSharing(true)}
        >
          <MassActionsRow>
            <IconShare
              iconType='stroke'
              padding={0}
              size={12}
            />
            <MassActionsButtonText>
              {translation('profiles.multipleOperations.share')}
            </MassActionsButtonText>
          </MassActionsRow>
        </MassActionsButtonLine>
        <EditTagsMenuBtn
          selectedRows={selectedProfilesIds}
          updateTag={updateTag}
          addTag={addTag}
          removeProfileTag={removeProfileTag}
          removeTag={removeTag}
          isProcessing={isProcessing}
          ButtonLine={MassActionsButtonLine}
          Row={MassActionsRow}
          MultipleOperationBtnText={MassActionsButtonText}
          isNewMenu={true}
        />
        <MassActionsButtonLine
          {...getActionsButtonStyles(isProcessing)}
          onClick={openExtensionsModal}
        >
          <MassActionsRow>
            <IconPlug
              padding={0}
              size={12}
            />
            <MassActionsButtonText>
              {translation('profiles.multipleOperations.extensions')}
            </MassActionsButtonText>
          </MassActionsRow>
        </MassActionsButtonLine>
        <MassActionsButtonLine
          {...getActionsButtonStyles(isProcessing)}
          onClick={openBookmarksManager}
        >
          <MassActionsRow>
            <IconBookmark
              padding={0}
              size={12}
            />
            <MassActionsButtonText>
              {translation('profilesTable.multipleOperations.bookmarks')}
            </MassActionsButtonText>
          </MassActionsRow>
        </MassActionsButtonLine>
        <MassActionsButtonLine {...getActionsButtonStyles(isProcessing)} onClick={onRenameProfiles}>
          <MassActionsRow>
            <IconPen
              padding={0}
              size={12}
            />
            <MassActionsButtonText>
              {translation('profiles.multipleOperations.renameProfiles', { count: selectedProfilesIds.length })}
            </MassActionsButtonText>
          </MassActionsRow>
        </MassActionsButtonLine>
        <MassActionsButtonLine
          onClick={cloneProfile}
          {...getActionsButtonStyles(isProcessing)}
        >
          <MassActionsRow>
            <IconClone2
              padding={0}
              size={12}
            />
            <MassActionsButtonText>
              {translation('profiles.menu.clone')}
            </MassActionsButtonText>
          </MassActionsRow>
        </MassActionsButtonLine>
        <MassActionsButtonLine
          {...getActionsButtonStyles(isProcessing)}
          onClick={(): void => toggleModalTransfer(true)}
        >
          <MassActionsRow>
            <IconSwap
              padding={0}
              size={12}
            />
            <MassActionsButtonText>
              {translation('profiles.multipleOperations.transfer')}
            </MassActionsButtonText>
          </MassActionsRow>
        </MassActionsButtonLine>
        <Popconfirm
          placement='bottom'
          title={translation('profiles.multipleOperations.confirms.exportProfilesUpgradePlan')}
          onConfirm={redirectToSite}
          onCancel={(): void => setShowExportPayPopUp(false)}
          cancelText={translation('base.cancel')}
          okText={translation('personalArea.billing.changePlanSubmitBtnText')}
          visible={showExportPayPopUp}
        >
          <MassActionsButtonLine
            onClick={exportProfile}
            {...getActionsButtonStyles(isProcessing)}
          >
            <MassActionsRow>
              <IconExport
                padding={0}
                size={12}
              />
              <MassActionsButtonText>
                {translation('base.export')}
              </MassActionsButtonText>
              {getLoader()}
            </MassActionsRow>
          </MassActionsButtonLine>
        </Popconfirm>
        <Popconfirm
          placement='bottom'
          title={translation('profiles.multipleOperations.confirms.newFingerprints')}
          onConfirm={updateFingerprints}
          okText={translation('base.yes')}
          cancelText={translation('base.no')}
          disabled={isProcessing}
        >
          <MassActionsButtonLine
            {...getActionsButtonStyles(isProcessing)}
          >
            <MassActionsRow>
              <IconRefresh
                padding={0}
                size={12}
              />
              <MassActionsButtonText>
                {translation('profiles.multipleOperations.updateFingerprint')}
              </MassActionsButtonText>
            </MassActionsRow>
          </MassActionsButtonLine>
        </Popconfirm>
        <Popconfirm
          placement='bottom'
          title={translation('profiles.multipleOperations.confirms.deleteProfiles')}
          onConfirm={deleteOrLeaveMultipleProfiles}
          okText={translation('base.yes')}
          cancelText={translation('base.no')}
          disabled={isProcessing}
        >
          <MassActionsButtonLine
            {...getActionsButtonStyles(isProcessing)}
          >
            <MassActionsRow>
              <IconTrash
                padding={0}
                size={12}
              />
              <MassActionsButtonText>
                {selectedProfilesActions.length === 2 &&
                    <>
                      {`${translation('base.delete')} / ${translation('base.leave')}`}
                    </>}
                {selectedProfilesActions.length === 1 &&
                    <>
                      {translation(`base.${selectedProfilesActions[0].toLowerCase()}`)}
                    </>}
                {selectedProfilesActions.length === 0 &&
                    <>
                      {translation('base.delete')}
                    </>}
              </MassActionsButtonText>
            </MassActionsRow>
          </MassActionsButtonLine>
        </Popconfirm>
      </MassActionsMarginBlock>
    </>
  );
};

export default MultipleOperationsMenu;
