const colors = {
  pink: {
    fontColor: 'var(--FFD6E1-tags-colors)',
    borderColor: 'var(--FFD6E1-tags-colors)',
  },
  yellow: {
    fontColor: 'var(--FFEFAF-tags-colors)',
    borderColor: 'var(--FFEFAF-tags-colors)',
  },
  green: {
    fontColor: 'var(--BEEBDE-tags-colors)',
    borderColor: 'var(--BEEBDE-tags-colors)',
  },
  blue: {
    fontColor: 'var(--C8E0FF-tags-colors)',
    borderColor: 'var(--C8E0FF-tags-colors)',
  },
  lightgrey: {
    fontColor: 'var(--EEEEF1-tags-colors)',
    borderColor: 'var(--EEEEF1-tags-colors)',
  },
  peach: {
    fontColor: 'var(--FFE1D5-tags-colors)',
    borderColor: 'var(--FFE1D5-tags-colors)',
  },
  lime: {
    fontColor: 'var(--DFF6DA-tags-colors)',
    borderColor: 'var(--DFF6DA-tags-colors)',
  },
  turquoise: {
    fontColor: 'var(--D1F5F9-tags-colors)',
    borderColor: 'var(--D1F5F9-tags-colors)',
  },
  lilac: {
    fontColor: 'var(--E5D8F2-tags-colors)',
    borderColor: 'var(--E5D8F2-tags-colors)',
  },
  grey: {
    fontColor: 'var(--E1E1E1-tags-colors)',
    borderColor: 'var(--E1E1E1-tags-colors)',
  },
};

export default colors;
