import styled from '@emotion/styled';

export const ProxyModeSelectorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  top: -1px;
`;

export const ProxyModeLabel = styled.label`
  cursor: pointer;
  padding: 0;
  margin-right: 4px;
  height: 20px;
  display: flex;

  &:last-child div {
    margin-right: 0;
  }
`;

export const ProxyModeRadioInput = styled.input`
  display: none;

  &:checked + div {
    color: var(--222222-proxy-manager-radio-buttons);
    border-color: var(--767676-proxy-manager-radio-buttons);
  }
`;

export const ProxyModeRadioInputContent = styled.div`
  padding: 1px 7px;
  text-transform: uppercase;
  white-space: nowrap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  outline: none;
  letter-spacing: 0.2px;
  border: 1px solid var(--EBEBEB-proxy-manager-radio-buttons);
  border-radius: 10px;
  color: var(--CDCDCD-proxy-manager-radio-buttons);
  line-height: 16px;

  &:hover {
    color: var(--222222-proxy-manager-radio-buttons);
    border-color: var(--767676-proxy-manager-radio-buttons);
  }
`;
