import { NEW_PROFILE_PAGE_FOLDER_NAME } from '../../../common/constants/constants';
import { LOCAL_STORAGE_GROUPS_OLD, LOCAL_STORAGE_GROUPS_STATUS } from '../../../common/constants/local-storage';
import { GroupField, GroupHeader } from '../../interfaces/group-header.interface';
import { getCurrentWorkspaceId } from '../current-workspace-id.atom';
import { getSelectedFolderId } from '../selected-folder.atom';

interface ILocalStorageGroupsStateBasic {
  field: GroupField;
  groupHeaders: Pick<GroupHeader, 'id' | 'isOpen'>[];
}

interface ILocalStorageGroupsEntry extends ILocalStorageGroupsStateBasic {
  workspaceId: string;
  folderId: string | null;
}

interface ILocalStorageCompleteGroupsStatus {
  entries: ILocalStorageGroupsEntry[];
}

const findLocalEntryIndex = (
  localEntries: ILocalStorageGroupsEntry[],
  entry: Pick<ILocalStorageGroupsEntry, 'workspaceId' | 'folderId'>,
): number => localEntries.findIndex((localEntry) => (
  localEntry.workspaceId === entry.workspaceId
  && localEntry.folderId === entry.folderId
));

export const saveGroupsToLocalStorage = (groupHeaders: GroupHeader[]): void => {
  const field = groupHeaders[0]?.filter.type || null;
  if (!field) {
    // ignore implicit resets, should use reset action
    return;
  }

  const groupHeadersToSave: ILocalStorageGroupsStateBasic['groupHeaders'] = groupHeaders.map((groupHeader) => ({
    id: groupHeader.id,
    isOpen: groupHeader.isOpen,
  }));

  const currentEntry: ILocalStorageGroupsEntry = {
    workspaceId: getCurrentWorkspaceId(),
    folderId: getSelectedFolderId(),
    field,
    groupHeaders: groupHeadersToSave,
  };

  const { entries = [] } = loadCompleteGroupsStatus() || {};
  const prevEntryIndex = findLocalEntryIndex(entries, currentEntry);
  if (prevEntryIndex === -1) {
    entries.push(currentEntry);
  } else {
    entries.splice(prevEntryIndex, 1, currentEntry);
  }

  const toSave: ILocalStorageCompleteGroupsStatus = {
    entries,
  };

  localStorage.setItem(LOCAL_STORAGE_GROUPS_STATUS, JSON.stringify(toSave));
  localStorage.removeItem(LOCAL_STORAGE_GROUPS_OLD);
};

export const loadCompleteGroupsStatus = (): ILocalStorageCompleteGroupsStatus | null => {
  const localStorageStateStr = localStorage.getItem(LOCAL_STORAGE_GROUPS_STATUS) || '';
  let groupsStatus: ILocalStorageCompleteGroupsStatus | null = null;
  try {
    groupsStatus = JSON.parse(localStorageStateStr);
  } catch (err) { /* empty */ }

  if (!groupsStatus) {
    return null;
  }

  return groupsStatus;
};

export const loadOldGroupsFromLocalStorage = (): ILocalStorageGroupsStateBasic | null => {
  const localStorageStateStr = localStorage.getItem(LOCAL_STORAGE_GROUPS_OLD) || '';
  let groupsState: ILocalStorageGroupsStateBasic | null = null;
  try {
    groupsState = JSON.parse(localStorageStateStr);
  } catch (err) { /* empty */ }

  if (!groupsState) {
    return null;
  }

  return groupsState;
};

export const loadGroupsFromLocalStorage = (): ILocalStorageGroupsStateBasic | null => {
  const oldGroups = loadOldGroupsFromLocalStorage();
  if (oldGroups) {
    return oldGroups;
  }

  const workspaceId = getCurrentWorkspaceId();
  const folderId = getSelectedFolderId();

  const { entries = [] } = loadCompleteGroupsStatus() || {};
  const entryIndex = findLocalEntryIndex(entries, { workspaceId, folderId });
  if (entryIndex === -1) {
    return null;
  }

  return entries[entryIndex];
};

export const resetLocalStorageGroups = (): void => {
  localStorage.removeItem(LOCAL_STORAGE_GROUPS_OLD);
  sessionStorage.removeItem(NEW_PROFILE_PAGE_FOLDER_NAME);

  const { entries = [] } = loadCompleteGroupsStatus() || {};
  const entryIndex = findLocalEntryIndex(entries, {
    workspaceId: getCurrentWorkspaceId(),
    folderId: getSelectedFolderId(),
  });

  if (entryIndex !== -1) {
    entries.splice(entryIndex, 1);
  }

  const toSave: ILocalStorageCompleteGroupsStatus = {
    entries,
  };

  localStorage.setItem(LOCAL_STORAGE_GROUPS_STATUS, JSON.stringify(toSave));
};
