import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconSmallClose: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M11.5 4.5L4.5 11.5' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4.5 4.5L11.5 11.5' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  </IconWrapper>
);
