import { atom, getDefaultStore, useAtomValue } from 'jotai';

export type WorkflowCookieBotParameter = {
  name: string;
  title: string;
  subtitle: string;
  value: boolean | string | number;
};

const workflowCookieBotParametersAtom = atom<WorkflowCookieBotParameter[]>([
  {
    name: 'shouldLoadImages',
    title: 'automationModal.cookiebot.params.shouldLoadImages.title',
    subtitle: 'automationModal.cookiebot.params.shouldLoadImages.subtitle',
    value: true,
  },
  {
    name: 'shouldScroll',
    title: 'automationModal.cookiebot.params.shouldScroll.title',
    subtitle: 'automationModal.cookiebot.params.shouldScroll.subtitle',
    value: true,
  },
  {
    name: 'shouldClickButtons',
    title: 'automationModal.cookiebot.params.shouldClickButtons.title',
    subtitle: 'automationModal.cookiebot.params.shouldClickButtons.subtitle',
    value: true,
  },
]);

export const getWorkflowCookieBotParameters = (): WorkflowCookieBotParameter[] => 
  getDefaultStore().get(workflowCookieBotParametersAtom);

export const setWorkflowCookieBotParameters = (workflowCookieBotParameters: WorkflowCookieBotParameter[]): void => {
  getDefaultStore().set(workflowCookieBotParametersAtom, workflowCookieBotParameters);
}

export const useWorkflowCookieBotParameters = () => useAtomValue(workflowCookieBotParametersAtom);

export const updateWorkflowCookieBotParameter = (
  name: string,
  newValue: WorkflowCookieBotParameter['value']
) => {
  const store = getDefaultStore();
  const prev = store.get(workflowCookieBotParametersAtom);

  store.set(workflowCookieBotParametersAtom, 
    prev.map(item => (item.name === name ? { ...item, value: newValue } : item))
  );
};