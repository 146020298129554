import { TFunction } from 'i18next';

export type SelectRole = 'admin'|'editor'|'guest';
export type SharingSelectRole = 'administrator'|'redactor'|'guest'|'varies'|'owner';
export type AnyRole = SelectRole|SharingSelectRole|'remove'|'noAccess'|'mySharedAccount';

const ROLES_TEXTS: Record<AnyRole, string> = {
  admin: 'roles.fullAccess.title',
  administrator: 'roles.fullAccess.title',
  redactor: 'roles.canEdit.title',
  editor: 'roles.canEdit.title',
  guest: 'roles.canRun.title',
  varies: 'roles.varies',
  owner: 'roles.owner',
  remove: 'roles.extra.removeMember',
  noAccess: 'roles.noAccess',
  mySharedAccount: 'workspaceSettingsMembers.popupRemoveMember.title',
};

export const SHARE_LINKS_ACTIONS = ['pause', 'resume', 'delete'] as const;
export type ShareLinksAction = typeof SHARE_LINKS_ACTIONS[number];

const SHARE_LINKS_TEXTS: Record<ShareLinksAction, string> = {
  pause: 'newShareModal.links.menuPauseTitle',
  resume: 'newShareModal.links.menuResumeTitle',
  delete: 'newShareModal.links.menuDeleteTitle',
};

const INVITE_TEXTS = { ...ROLES_TEXTS, ...SHARE_LINKS_TEXTS };

export const getInviteActionText = (action: AnyRole|ShareLinksAction, translation: TFunction): string => translation(INVITE_TEXTS[action]);
