import { ISelectItem } from '../../../ui/modern-select/interfaces';

interface IGeolocationItem {
  city: string;
  cityAscii: string;
  latitude: number;
  longitude: number;
  country: string;
  countryCode2: string;
  countryCode3: string;
  adminName: string;
  id: number;
}

export let GEOLOCATION_DATA: IGeolocationItem[] = [];
export let GEOLOCATION_SELECT_DATA: ISelectItem[] = [];
export const fillGlobalGeolocationData = async (): Promise<void> => {
  const { CITIES_DATA } = await import('./data-list');
  const lines = CITIES_DATA.split(';');
  GEOLOCATION_DATA = lines.map(line => {
    const modifiedLine = line.replace(/(\d+),(\d+)/g, '$1.$2');
    const values = modifiedLine
      .split(',')
      .map(value => value.trim());

    const convertedValues = values.map(value => {
      if (value.startsWith('"') && value.endsWith('"')) {
        const numberValue = value.slice(1, -1);

        return parseFloat(numberValue.replace(',', '.'));
      }

      return value;
    });

    return {
      city: convertedValues[0].toString(),
      cityAscii: convertedValues[1].toString(),
      latitude: Number(convertedValues[2]),
      longitude: Number(convertedValues[3]),
      country: convertedValues[4].toString(),
      countryCode2: convertedValues[5].toString(),
      countryCode3: convertedValues[6].toString(),
      adminName: convertedValues[7].toString(),
      id: Number(convertedValues[8]),
    };
  });

  GEOLOCATION_SELECT_DATA = GEOLOCATION_DATA
    .map(({ city, country, id, countryCode2 }) => ({
      title: city + ', ' + country,
      value: city,
      subValue: countryCode2,
      key: id + '',
    }));
};
