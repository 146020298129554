import { API_BASE_URL } from '../../common/constants/constants';
import { http } from '../services';

export const sendUserEventToServer = async (fieldName: string, googleClientId = ''): Promise<void> => {
  if (!['pickedTrialPlan', 'startedDownloadApp', 'visitedPaymentPage', 'openedBrowser'].includes(fieldName)) {
    return;
  }

  await http(`${API_BASE_URL}/user-stats/update-user-events`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      [fieldName]: true,
      googleClientId,
    }),
  }).catch(() => void 0);
};

export const sendCommonEventsToServer = async (fieldName: string, options: any): Promise<void> => {
  if (!['visitedRegisterPage', 'openedApp'].includes(fieldName)) {
    return;
  }

  await http(`${API_BASE_URL}/user-stats/update-common-events`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      [fieldName]: true,
      openedAppTrue: options.openedAppTrue || false,
      userSystemLocale: options.userSystemLocale || '',
      googleClientId: options.googleClientId || '',
      filenameParserError: options.filenameParserError || '',
      isAdvertising: !!options.isAdvertising,
      utm: options.utm || '',
      fontsHash: options.fontsHash || '',
    }),
  }).catch((error: unknown) => console.error(`Error in send event: ${fieldName}`, error));
};
