import { IWorkspaceCtx } from '../../../../state';
import { CheckboxRole } from './index';

const ROLES: Record<CheckboxRole, number> = {
  admin: 3,
  editor: 2,
  guest: 1,
  unset: 0,
};

export const lowerRole = (role: CheckboxRole): CheckboxRole => {
  const low: Record<CheckboxRole, CheckboxRole> = {
    admin: 'editor',
    editor: 'guest',
    guest: 'unset',
    unset: 'unset',
  };

  return low[role];
};

export const isEqualOrHigherRole = (role: CheckboxRole, against: CheckboxRole): boolean => {
  return ROLES[role] >= ROLES[against];
};

export const maxRole = (r1: CheckboxRole, r2: CheckboxRole): CheckboxRole => {
  return ROLES[r1] > ROLES[r2] ? r1 : r2;
};

export const minRole = (r1: CheckboxRole, r2: CheckboxRole): CheckboxRole => {
  return ROLES[r1] > ROLES[r2] ? r2 : r1;
};

export const canManageAdminInFolder = (workspaceCtx: IWorkspaceCtx, folderName: string): boolean =>
  !!workspaceCtx.folders?.find(folder => folderName === folder.name)?.permissions?.manageAdminMember;
