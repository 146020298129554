import React, { FC, useEffect, useMemo } from 'react';

import CustomTableLoader from '../../features/common/custom-table/components/custom-table-loader';

type GologinTableLoaderRowProps = {
  measure: () => void;
  style: React.CSSProperties;

  // needed to properly center the icon
  tableContainerWidth: number;
};

const ICON_SIZE = 32;
const ICON_PADDING = 8;

export const GologinTableLoaderRow: FC<GologinTableLoaderRowProps> = (props) => {
  const { measure, style, tableContainerWidth } = props;

  const adjustedStyle = useMemo(() => ({
    ...style,
    position: 'absolute' as const,
    left: (tableContainerWidth / 2) - (ICON_SIZE / 2) - ICON_PADDING,
  }), [style, tableContainerWidth]);

  useEffect(measure, []);

  return (
    <CustomTableLoader
      areColumnsResizing={false}
      style={adjustedStyle}
      isTextCentered={false}
      iconSize={ICON_SIZE}
      iconPadding={ICON_PADDING}
    />
  );
};
