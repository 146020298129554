import { resetNewUserFeature } from '../state/feature-toggle/new-features';
import { history } from '../services';
import { sendCredentialsToLocalServer } from '../services/http';
import { MEMBER_TOKEN } from '../services/http/config';
import { clearToken, retrieveToken } from '../services/http/storage';
import { beginUsingSentryAndStartReplay } from '../services/init-sentry';
import { IWorkspaceCtx, WORKSPACE_DEFAULT_VALUE, resetToDefaultSearchState } from '../state';
import { resetCurrentWorkspaceId } from '../state/current-workspace-id.atom';
import { resetProfilesTable } from '../state/profiles-list.atom';
import { deleteTokenRequest } from '../ui/Header/api';

const isElectron = !!window.require;
let ipcRenderer: Electron.IpcRenderer;

if (window.require) {
  ({ ipcRenderer } = window.require('electron'));
}

const logoutFromFacebook = (): void => {
  const { FB } = window as any;
  if (!FB) {
    return;
  }

  FB.api('me/permissions', 'delete', () => {
    FB.getLoginStatus(() => null, true);
  });
};

export async function logout(workspaceCtx?: IWorkspaceCtx): Promise<void> {
  logoutFromFacebook();

  resetCurrentWorkspaceId();

  if (workspaceCtx) {
    workspaceCtx.updateAvailableWorkspaces([], true);
    workspaceCtx.updateWorkspace(WORKSPACE_DEFAULT_VALUE);
  }

  localStorage.removeItem('workspace');
  localStorage.removeItem('SelectedFolder');

  sessionStorage.removeItem('selected_account');
  sessionStorage.removeItem(MEMBER_TOKEN);
  resetNewUserFeature();
  resetProfilesTable();

  const token = await retrieveToken();
  if (isElectron) {
    ipcRenderer.send('close-all-profiles');
  }

  beginUsingSentryAndStartReplay();

  if (!token) {
    return;
  }

  const [_, payload] = (token as string).split('.');

  const tokenJSON = atob(payload);
  const tokenPayload = JSON.parse(tokenJSON);
  const { jwtid } = tokenPayload;

  console.log(jwtid);

  const promises = [
    deleteTokenRequest(jwtid),
    sendCredentialsToLocalServer('access', 'logout'),
  ];

  await Promise.all(promises as [Promise<any>]).catch(console.warn);

  if (isElectron) {
    await ipcRenderer.invoke('remove-token-electron');
  }

  clearToken();
  resetToDefaultSearchState();
  history.replace('/sign_in');
}

export const checkAndLogout = async (workspaceCtx: IWorkspaceCtx, setIsLogoutModalVisible: (arg: boolean) => void): Promise<void> => {
  if (!isElectron) {
    logout(workspaceCtx);

    return;
  }

  const isAnyProfilesRunning = await ipcRenderer.invoke('check-profiles-running');

  if (isAnyProfilesRunning) {
    setIsLogoutModalVisible(true);

    return;
  }

  logout(workspaceCtx);
};
