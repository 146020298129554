import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IFullProfile } from '../../../../interfaces';
import { IconPlusFill } from '../../../../ui/gologin-header/icons';
import { blackMain, grey, greyInformers } from '../../../../ui/style-templates/colors';
import { SettingsInput } from '../../../../ui/closeable-input/styles';
import {
  ContainerIcon,
  ContainerParams,
  ContainerSubRow,
} from '../../styles';

interface ICustomDns {
  dns: string;
  changeSetting: (profileData: Partial<IFullProfile>) => void;
}

export const CustomDns: FC<ICustomDns> = ({ changeSetting, dns }) => {
  const [userDns, setUserDns] = useState<string>('');
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement|null>(null);

  const { t: translation } = useTranslation();

  useEffect(() => {
    if (!dns) {
      setUserDns('');
    }

    setUserDns(dns);
  }, [dns]);

  const handleHotkey = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      inputRef?.current?.blur();
    }
  };

  const onBlur = (): void => {
    changeSetting({ dns: userDns });
    setIsEdit(false);
  };

  const renderDns = (): JSX.Element => {
    if (isEdit || userDns) {
      return (
        <div style={{ backgroundColor: 'inherit', width: '100%' }}>
          <SettingsInput
            onChange={(event): void => setUserDns(event.target.value)}
            onBlur={onBlur}
            onKeyPress={handleHotkey}
            onFocus={(event): void => {
              event.currentTarget.select();
              setIsEdit(true);
            }}
            value={userDns}
            autoFocus={true}
          />
        </div>
      );
    }

    return (
      <ContainerIcon
        iconColor='var(--B5B5BA-profile-new-settings)'
        iconHoveredColor='var(--2B2B31-profile-new-settings)'
        textColor='var(--81818A-profile-new-settings)'
        textHoveredColor='var(--2B2B31-profile-new-settings)'
        iconType='stroke'
        onClick={(): void => setIsEdit(true)}
      >
        <IconPlusFill padding={0} margin='0 8px 0 0' />
        {translation('quickSettings.proxyLocation.addCustomDns')}
      </ContainerIcon>
    );
  };

  return (
    <ContainerParams>
      <ContainerSubRow>
        {renderDns()}
      </ContainerSubRow>
    </ContainerParams>
  );
};
