import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

export interface IInfiniteScrollInTable {
  dataLength: number;
  next: () => void;
  hasMore: boolean;
  loader: JSX.Element|null;
  initialScrollY?: number;
}

const InfiniteScrollInTable: React.FC<IInfiniteScrollInTable> = (props) => {
  const { dataLength, hasMore, initialScrollY, loader, next, children } = props;

  return (
    <InfiniteScroll
      dataLength={dataLength}
      next={next}
      hasMore={hasMore}
      loader={loader}
      style={{ overflow: 'visible' }}
      scrollableTarget='root'
      initialScrollY={initialScrollY}
    >
      {children}
    </InfiniteScroll>
  );
};

export default InfiniteScrollInTable;
