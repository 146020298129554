import { OverscanIndices, OverscanIndicesGetterParams } from 'react-virtualized';

const overscanIndicesGetter = (params: OverscanIndicesGetterParams): OverscanIndices => {
  const {
    cellCount,
    overscanCellsCount,
    startIndex,
    stopIndex,
  } = params;

  return {
    overscanStartIndex: Math.max(0, startIndex - overscanCellsCount),
    overscanStopIndex: Math.min(
      cellCount - 1,
      stopIndex + overscanCellsCount,
    ),
  };
};

export default overscanIndicesGetter;
