import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { ANNUAL_DISCOUNT } from '../../features/modalsComponents/components/checkout-modal/constants';
import { E_PERIOD, IDiscount, PaymentPeriodType } from '../../features/pricing/interfaces';

const discountsListAtom = atom<IDiscount[]>([]);

export const useDiscountsList = (): IDiscount[] => useAtomValue(discountsListAtom);
export const useDiscountObjById = (discountId: string): IDiscount|null => {
  const discountList = useDiscountsList();

  return discountList.find(discountObj => discountObj.id === discountId) || null;
};

export const setDiscountsList = (data: IDiscount[]): void => getDefaultStore().set(discountsListAtom, data);
export const getDiscountsList = (): IDiscount[] => getDefaultStore().get(discountsListAtom);
export const getDiscountObjById = (discountId: string): IDiscount|null =>
  getDiscountsList().find(discountObj => discountObj.id === discountId) || null;

export const convertDiscountIdToPeriod = (discountId: string): PaymentPeriodType =>
  discountId === ANNUAL_DISCOUNT ? E_PERIOD.ANNUAL : E_PERIOD.MONTHLY;

