import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { InfoContainer, Container, Setting, ListContainer, ParameterSubtitle, ParameterTitle, ValueContainer, TitleContainer } from './styles';
import { IProfileLaunch } from '../interfaces';
import GologinModal from '../../../ui/gologin-modal';
import { Button, Input } from 'antd';
import { closeWorkflowParametersModal, useWorkflowParametersModal } from '../../../state/automation/workflow-parameters-modal.atom';
import { ScrollbarContainer } from '../../../ui/scrollbar';
import { StyledSwitch } from '../../../ui/modern-switch/styles';
import { handleOnSitesTextChange, useWorkflowCookieBotSites } from '../../../state/automation/workflow-cookiebot-sites.atom';
import { handleRunCookieBot } from './utils';
import { GologinTextArea } from '../../../ui/gologin-textarea/styles';
import { updateWorkflowCookieBotParameter, useWorkflowCookieBotParameters } from '../../../state/automation/workflow-cookiebot-parameters.atom';
import WorkflowCookieBotParametersModalTitle from './title';
import { closeAutomationModal } from '../../../state/automation/automation-modal.atom';
import { IconSpinnerOld } from '../../../ui/gologin-header/icons';

type ParametersModal = IProfileLaunch;

const WorkflowCookieBotParametersModal: React.FC<ParametersModal> = (props) => {
  const { launchProfileOrbita } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const cookieBotSites = useWorkflowCookieBotSites();
  const cookieBotParameters = useWorkflowCookieBotParameters();
  const workflowParametersModalState = useWorkflowParametersModal();
  const isParametersModalVisible = workflowParametersModalState.isVisible;

  const workflowId = 'workflowId' in workflowParametersModalState ? workflowParametersModalState.workflowId : '';
  const workflowName = 'workflowName' in workflowParametersModalState ? workflowParametersModalState.workflowName : '';
  const workflowIcon = 'workflowIcon' in workflowParametersModalState ? workflowParametersModalState.workflowIcon : '';
  const profileId = 'profileId' in workflowParametersModalState ? workflowParametersModalState.profileId : '';

  const { t: translation } = useTranslation();

  const closeModal = (): void => {
    closeWorkflowParametersModal();
  };

  const updateState = (name: string, newValue: boolean | string | number) => {
    updateWorkflowCookieBotParameter(name, newValue);
  };

  const handleSubmit = async (): Promise<void> => {
    setIsLoading(true)
    await handleRunCookieBot({ workflowId, profileId })
      .finally(() => setIsLoading(false));

    closeModal();
    closeAutomationModal();
  }

  return (
    <GologinModal
      width='692px'
      title={<WorkflowCookieBotParametersModalTitle name={workflowName} icon={workflowIcon} />}
      isVisible={isParametersModalVisible}
      onClose={closeModal}
      bodyStyle={{ height: 381 }}
      footer={(
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={handleSubmit} disabled={isLoading} style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ margin: '0px 10.5px'}}>
              {isLoading ?
                <IconSpinnerOld size={16} /> :
                <Trans style={{ margin: '0px 1px'}} i18nKey={'base.run'} />
              }
            </div>
          </Button>
        </div>
      )}
    >
      <Container>
        <GologinTextArea 
          value={cookieBotSites}
          onChange={handleOnSitesTextChange}
          placeholder={translation('cookiebotModal.sites.placeholder') || ''}
        />
        <ScrollbarContainer style={{ width: 326, height: 320 }}>
          <ListContainer>
            {cookieBotParameters.map(({ name, title, subtitle, value }) => {
              return (
                <Setting key={name}>
                  <InfoContainer>
                    <ParameterTitle>
                      {translation(title)}
                    </ParameterTitle>
                    <ParameterSubtitle>
                      {translation(subtitle)}
                    </ParameterSubtitle>
                  </InfoContainer>
                  <ValueContainer>
                    {typeof value === 'boolean' ? (
                      <StyledSwitch
                        size='medium'
                        checked={value}
                        onChange={(_, afterChecked): void => updateState(name, afterChecked)}
                        color='primary'
                      />
                    ) : typeof value === 'number' ? (
                      <Input
                        type='number'
                        value={value}
                        onChange={(event) => updateState(name, Number(event.target.value))}
                      />
                    ) : (
                      <Input
                        type='text'
                        value={value}
                        onChange={(event) => updateState(name, event.target.value)}
                      />
                    )}
                  </ValueContainer>
                </Setting>
              )
            })}
          </ListContainer>
        </ScrollbarContainer>
      </Container>
    </GologinModal>
  );
};

export default WorkflowCookieBotParametersModal;
