import { defineDelimeterAndCutCookies } from './define-delimeter-and-cut-cookies';
import { DATE_REGEX, EMAIL_REGEX, FACEBOOK_PROFILE_URL_REGEX, FB_TOOL_URL_REGEX, GOOGLE_DRIVE_URL_REGEX, USER_AGENT_REGEX } from '../constants';
import { IParsedFacebookData } from '../interfaces';

const getValueAfterMatching = (data: string, regex: RegExp): string => regex.test(data) ? data : '';

export const parseFBAccounts = (data: string): IParsedFacebookData[] => {
  const fileLines = data.split('\n');

  return fileLines.reduce((acc: IParsedFacebookData[], currentValue: string) => {
    if (!currentValue.trim().length) {
      return acc;
    }

    const accountFB: IParsedFacebookData = {
      notParsedData: [],
    };

    const { delimeter, cookies, newDataCookiesRemoved } = defineDelimeterAndCutCookies(currentValue);

    accountFB.cookies = cookies;

    const splittedData = newDataCookiesRemoved.split(delimeter).filter((value) => value.length);

    splittedData.forEach((value) => {
      let email;
      let splittedEmail;
      let fbUrl;
      let splittedFbIdAccount: string[] = [];
      let fb2faToolUrl;
      let date;
      let token;
      let useragent;
      let googleDriveUrl;

      switch (value) {
        case (email = getValueAfterMatching(value, EMAIL_REGEX)):
          splittedEmail = value.split(':');

          if (splittedEmail.length > 1) {
            const [mail, password] = splittedEmail;

            accountFB.password = password;
            accountFB.email = mail;
          } else {
            accountFB.email = email;
          }

          break;

        case (fbUrl = getValueAfterMatching(value, FACEBOOK_PROFILE_URL_REGEX)):
          accountFB.fbUrl = fbUrl;
          splittedFbIdAccount = value.split('id=');
          accountFB.fbIdAccount = splittedFbIdAccount.length ? splittedFbIdAccount[1] : '';

          break;
        case (fb2faToolUrl = getValueAfterMatching(value, FB_TOOL_URL_REGEX)):
          accountFB.fb2faToolUrl = fb2faToolUrl;
          break;
        case (date = getValueAfterMatching(value, DATE_REGEX)):
          accountFB.date = date;
          break;
        case (token = !useragent && value.length > 120 ? value : ''):
          accountFB.token = token;
          break;
        case (useragent = getValueAfterMatching(value, USER_AGENT_REGEX)):
          accountFB.uaVersion = useragent;
          break;
        case (googleDriveUrl = getValueAfterMatching(value, GOOGLE_DRIVE_URL_REGEX)):
          accountFB.googleDriveUrl = googleDriveUrl;
          break;
        default:
          accountFB.notParsedData.push({
            value,
          });
          break;
      }
    });

    acc.push(accountFB);

    return acc;
  }, []);
};
