import React, { FC, useEffect, useState } from 'react';

import { IWorkspaceShort } from '../../interfaces/workspaces';
import { IconSpinner } from '../gologin-header/icons';
import IconAddSquared from '../icons/IconAddSquared';
import { MenuSelectFolder } from './menu-select-folder';
import { ISelectWorkspaceParams } from './select-workspace-params.interface';
import {
  AddWorkspaceText,
  ContainerAddWorkspace,
  Divider,
  DropdownMenu,
  SelectWorkspacePopover,
} from './styles';
import { WorkspaceRow } from './workspace-row';
import { useTranslation } from 'react-i18next';

interface IMenuSelectWorkspace {
  anchorEl: HTMLElement|null;
  onClose: () => void;
  workspacesList: IWorkspaceShort[];
  selectedWorkspaceId: string;
  setSelectedWorkspaceId: (workspaceId: string) => void;
  setCreateWorkspaceModalVisible: (state: boolean) => void;
  closeParent: () => void;
  isLoading?: boolean;
  showAddWorkspace?: boolean;
  selectedFolder?: string;
  setSelectedFolder?: (folderName: string) => void;
  needFoldersList?: boolean;
}

export const MenuSelectWorkspace: FC<IMenuSelectWorkspace> = (props) => {
  const {
    anchorEl, onClose, workspacesList, selectedWorkspaceId, setSelectedWorkspaceId, setCreateWorkspaceModalVisible,
    closeParent, isLoading, showAddWorkspace, selectedFolder, setSelectedFolder, needFoldersList,
  } = props;

  const [anchorElFolders, setAnchorElFolders] = useState<HTMLElement|null>(null);
  const [hoveredWorkspace, setHoveredWorkspace] = useState<IWorkspaceShort|null>(null);

  const { t: translation } = useTranslation();

  useEffect(() => {
    if (!anchorElFolders) {
      setHoveredWorkspace(null);
    }
  }, [anchorElFolders]);

  const onCloseFoldersList = (): void => setAnchorElFolders(null);

  const onSelectFolder = (folderName: string): void => {
    setSelectedFolder && setSelectedFolder(folderName);
    closeParent();
    onCloseFoldersList();
  };

  const onSelectWorkspace = ({
    event,
    hasPermission,
    workspace,
    firstAccessFolder,
    showFoldersList,
  }: ISelectWorkspaceParams): void => {
    if (!hasPermission) {
      return;
    }

    event.stopPropagation();
    setSelectedWorkspaceId(workspace.id);
    const isCurrentWorkspace = selectedWorkspaceId === workspace.id && !!workspace.folders?.find(folder => folder.name === selectedFolder);
    if (setSelectedFolder && !isCurrentWorkspace) {
      if (workspace.permissions.canClaimProfilesWithoutFolder) {
        setSelectedFolder('');
      } else {
        setSelectedFolder(firstAccessFolder || '');
      }
    }

    if (showFoldersList && setAnchorElFolders) {
      setAnchorElFolders(event.currentTarget);

      return;
    }

    closeParent();
  };

  const onDropdownMouseLeave = (): void => {
    if(!anchorElFolders) {
      setHoveredWorkspace(null);
    }
  };

  const shouldShowSelectFolderMenu = needFoldersList && setSelectedFolder && hoveredWorkspace;

  if (isLoading) {
    return (
      <DropdownMenu style={{ fontSize: 28, textAlign: 'center' }}>
        <IconSpinner
          size={28}
          padding={0}
        />
      </DropdownMenu>
    );
  }

  return (
    <SelectWorkspacePopover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      style={{ marginTop: 4 }}
    >
      <DropdownMenu onMouseLeave={onDropdownMouseLeave}>
        {workspacesList.map((workspace, index) => {
          const shouldShowDivider = workspacesList.length !== index + 1 || showAddWorkspace;

          return (
            <React.Fragment key={workspace.id}>
              <WorkspaceRow
                workspace={workspace}
                hoveredWorkspace={hoveredWorkspace}
                selectedWorkspaceId={selectedWorkspaceId}
                setHoveredWorkspace={setHoveredWorkspace}
                needFoldersList={needFoldersList}
                selectedFolder={selectedFolder}
                onSelect={onSelectWorkspace}
              />
              {shouldShowDivider ? <Divider /> : null}
            </React.Fragment>
          );
        })}
        {showAddWorkspace ? (
          <ContainerAddWorkspace onClick={(): void => {
            setCreateWorkspaceModalVisible(true);
            closeParent();
          }}>
            <IconAddSquared />
            <AddWorkspaceText>
              {translation('personalArea.overview.addWorkspace')}
            </AddWorkspaceText>
          </ContainerAddWorkspace>
        ) : null}
      </DropdownMenu>
      {shouldShowSelectFolderMenu ? (
        <MenuSelectFolder
          selectedFolder={selectedFolder || ''}
          anchorElFolders={anchorElFolders}
          onCloseFoldersList={onCloseFoldersList}
          workspace={hoveredWorkspace}
          onSelect={onSelectFolder}
        />
      ) : null}
    </SelectWorkspacePopover>
  );
};
