import { message } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AllowedFileError, IOnImportFile, ProfilesLimitError } from './interfaces';
import { useHandleImportFile } from './use-handle-import-file';
import { checkAllowedFiles } from './utils/check-allowed-files';
import { checkProfilesLimit } from './utils/check-profiles-limit';
import { parseFileList } from './utils/parse-file-list';
import { DEFAULT_PROFILE_NAME, importFilesContext, templatesContext, userContext, workspaceContext } from '../../state';
import { NEW_FEATURES } from '../../state/feature-toggle/new-features';
import { openProfilesSettingsOfSelectedIds } from '../../state/profiles-settings-atom';
import { putNewProfilesIntoFirstGroup } from '../../state/profiles-table/group-headers.operations';
import { resetProfilesTableSorting } from '../../state/profiles-table/profiles-sort.atom';
import { updateProfilesTableSelectedIds } from '../../state/profiles-table-selected-ids.atom';
import { clearProxyLists } from '../../state/proxy/proxy-list.atom';
import { loadProxyList } from '../../state/proxy/proxy-operations/load-proxies.operations';
import { getAccessibleFolderName } from '../../utils/check-folders-to-add-profile';
import { updateTemplateProfileName } from '../../utils/parse-name-formatting';
import { getSelectedFolderName } from '../../state/selected-folder.atom';

interface IDropProps {
  onDragLeave: (event: any) => void;
  onDragOver: (event: DragEvent) => void;
  onDrop: (event: DragEvent) => Promise<void>;
  onDragEnter: (event: any) => void;
}

export const useHandlesDrop = (props: {
    setNeedMask: (value: boolean) => void;
  }): {
    isDragging: boolean;
    dropProps: IDropProps;
  } => {
  const { t: translation } = useTranslation();
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const maskTimer = useRef<NodeJS.Timeout | null>(null);

  const workspaceCtx = useContext(workspaceContext);
  const userCtx = useContext(userContext);
  const templateCtx = useContext(templatesContext);
  const {
    setDropFileStep,
    setErrorImportMessage,
    setManyProfilesName,
  } = useContext(importFilesContext);

  const {
    folders: workspaceFolders,
    me,
    permissions,
  } = useContext(workspaceContext);

  const { selectedTemplate } = templateCtx;
  const { dropFiles } = selectedTemplate.profileName;
  const { hasSuccessPayment, hasTrial } = userCtx;
  const { id: workspaceId, availableWorkspaces } = workspaceCtx;
  const currentWorkspace = availableWorkspaces.find(workspace => workspace.id === workspaceId) || workspaceCtx;

  const getErrorText = (errorType: AllowedFileError | ProfilesLimitError): string => translation(`notifications.error.${errorType}`);

  const { onImportFile } = useHandleImportFile();

  useEffect(() => {
    // нужно для закрытия экрана дропа файлов при открытой модалке (любой)
    maskTimer.current = setTimeout(() => props.setNeedMask(false), 100);
  }, []);

  const checkIsCreateProfilesAvailable = (): boolean => {
    if (NEW_FEATURES.workspaces) {
      if (permissions.dropProfiles) {
        return true;
      }

      const dropAccessToCreate = getAccessibleFolderName({
        folders: workspaceFolders,
        permission: 'dropProfiles',
        limitedAccess: !!me?.limitedAccess,
        hasFirstFolder: true,
        hasSelectedFolder: true,
      });

      if (!dropAccessToCreate) {
        message.error(translation('notifications.error.permissionWorkspace'));
      }

      return !!dropAccessToCreate;
    }

    const isDisabled = !hasSuccessPayment || !!hasTrial;

    if (isDisabled) {
      message.error(translation('header.createProfilesNotAllowed'));
    }

    return !isDisabled;
  };

  const onDragLeave = (event: any): void => {
    event.preventDefault();
    event.stopPropagation();
    props.setNeedMask(false);
    setIsDragging(false);
  };

  const onDragOver = (event: MouseEvent): void => {
    maskTimer.current && clearTimeout(maskTimer.current);
    setIsDragging(true);
    event.stopPropagation();
    event.preventDefault();
  };

  const onDragEnter = (event: any): void => {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.types.includes('Files')) {
      props.setNeedMask(true);
      setIsDragging(true);
    }
  };

  const onDrop = async (event: DragEvent): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();
    props.setNeedMask(false);
    setIsDragging(false);
    const { files } = event.dataTransfer;

    const { allowedFiles, error: errorCheckAllowed } = checkAllowedFiles(files);
    if (errorCheckAllowed) {
      setErrorImportMessage(getErrorText(errorCheckAllowed));
    }

    if (!allowedFiles) {
      if (errorCheckAllowed) {
        message.error(getErrorText(errorCheckAllowed));
      }

      setErrorImportMessage('');

      return;
    }

    const parsedFiles = await parseFileList(allowedFiles);
    const { filesToImport, error: errorProfilesLimit } = checkProfilesLimit({ user: userCtx, workspace: currentWorkspace, parsedFiles });
    if (errorProfilesLimit) {
      setErrorImportMessage(getErrorText(errorProfilesLimit));
    }

    if (!filesToImport) {
      if (errorProfilesLimit) {
        message.error(getErrorText(errorProfilesLimit));
      }

      setErrorImportMessage('');

      return;
    }

    setDropFileStep('importing');
    const selectedFolderName = getSelectedFolderName();
    const browserName = (NEW_FEATURES.templateProfileName && dropFiles) || DEFAULT_PROFILE_NAME;
    const dataToImport: IOnImportFile = { filesToImport, workspaceId, selectedFolder: selectedFolderName, browserName };

    if (!checkIsCreateProfilesAvailable()) {
      setDropFileStep(null);

      return;
    }

    const newProfileIds = await onImportFile(dataToImport);
    if (!newProfileIds) {
      return;
    }

    await putNewProfilesIntoFirstGroup(newProfileIds);
    updateProfilesTableSelectedIds(newProfileIds);
    openProfilesSettingsOfSelectedIds();
    setDropFileStep(newProfileIds ? 'loading' : null);

    const messageDuration = 5;
    message.success(translation('notifications.success.profilesCreated', { count: newProfileIds.length || 0 }), messageDuration);

    setManyProfilesName(browserName);
    const needToUpdateTemplateProfileName = NEW_FEATURES.templateProfileName && browserName === dropFiles;
    if (needToUpdateTemplateProfileName) {
      await updateTemplateProfileName(dropFiles, newProfileIds.length, templateCtx);
    }

    clearProxyLists();
    loadProxyList();
    resetProfilesTableSorting();
  };

  return {
    dropProps: {
      onDragLeave,
      onDragOver,
      onDragEnter,
      onDrop,
    },
    isDragging,
  };
};
