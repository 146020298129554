import styled from '@emotion/styled';
import React, { FC, useContext } from 'react';

import { browserUpdaterContext, workspaceContext } from '../../../state';
import TrialBar from './trial';
import UnpaidWorkspaceBar from './unpaid-workspace';
import UpdateAppBar from './update-app';
import UpdateOrbitaBar from './update-orbita';

let ipcRenderer: Electron.IpcRenderer;

const isElectron = !!window.require;
if (isElectron) {
  ipcRenderer = window.require('electron').ipcRenderer;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 23px;
  transform: translateY(0.5px);
  z-index: 199;

  & > div:first-of-type,
  & > div:last-of-type {
    box-shadow: var(--0000000f-promobar-shadow);
  }

  * {
    margin: 0 1px;
  }
`;

interface IBar {
  appNeedRestart: boolean;
}

const Bar: FC<IBar> = ({ appNeedRestart }): JSX.Element => {
  const { paymentIsTrial: workspaceIsTrial, isUnpaid: isUnpaidWorkspace } = useContext(workspaceContext);

  const { browserUpdating, showOrbitaDialog } = useContext(browserUpdaterContext);

  const isTrial = workspaceIsTrial && !isUnpaidWorkspace;

  const showUpdateBar = (): JSX.Element | null => {
    if (showOrbitaDialog || browserUpdating) {
      return <UpdateOrbitaBar hasOneStep={!!isTrial} />;
    }

    if (appNeedRestart) {
      return <UpdateAppBar />;
    }

    return null;
  };

  return (
    <Container id='promobar'>
      {showUpdateBar()}
      {isTrial ? <TrialBar /> : null}
      {isUnpaidWorkspace ? <UnpaidWorkspaceBar /> : null}
    </Container>
  );
};

export default Bar;
