import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled/macro';

import { PROXY_ITEM_ELEMENT_SEMI_TRANSPARENCY } from '../../../constants/styles';
import { NoGroupsProxyManagerProxyStatusWrapper } from '../../../proxy-connection-button/proxy-connected-status/styles';
import { UnlinkButtonWrapper } from '../../../proxy-connection-button/proxy-unlink-button/styles';
import { ProxyEditButtonWrapper } from '../../../proxy-edit-button/styles';
import { ProxyItemBase, proxyListItemActiveStyles } from '../styles';

export const UnroupedProxyItemWrapper = styled(ProxyItemBase)<{ isProxyManagerWithGroups: boolean; isFailed?: boolean; isHighlighted?: boolean }>`
  padding: 0 0 0 36px;
  display: flex;
  align-items: center;
  color: var(--222222-proxy-manager-list-item);
  cursor: pointer;

  ${(props): SerializedStyles => props.isProxyManagerWithGroups ? css`
    height: 36px;
    border-radius: 4px;
    margin-bottom: 4px;

    ${props.isHighlighted ? css`
      background-color: var(--F9F9F9-proxy-list-item-selected);
    ` : null}

    :hover {
      ${proxyListItemActiveStyles}  
    }
  ` : css`
    height: 41px;

    :not(:first-of-type) {
      border-top: 1px solid var(--EBEBEB-proxy-manager-list-item);
    }

    ${UnlinkButtonWrapper},
    ${ProxyEditButtonWrapper} {
      display: none;
    }

    :hover {
      background-color: var(--F9F9F9-proxy-manager-list-item);

      ${ProxyTitleWrapper} {
        color: var(--00A987-proxy-manager);
      }

      ${UnlinkButtonWrapper},
      ${ProxyEditButtonWrapper} {
        display: flex;
      }

      ${NoGroupsProxyManagerProxyStatusWrapper} {
        display: none;
      }
    }
  `}
`;

export const ProxyTitleWrapper = styled.span<{ isProxyManagerWithGroups: boolean; isSemiTransparent: boolean }>`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  opacity: ${(props): React.CSSProperties['opacity'] => props.isSemiTransparent ? PROXY_ITEM_ELEMENT_SEMI_TRANSPARENCY : 1};

  ${(props): SerializedStyles | null => props.isProxyManagerWithGroups ? css`
    max-width: calc(100% - 96px);
    padding-top: 1px;
    flex-grow: 1;
  ` : css`
    max-width: calc(100% - 124px);
  `}
`;

export const ProxyConnectedStatusWrapper = styled.div`
  margin: 0 12px;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${UnlinkButtonWrapper},
  ${ProxyEditButtonWrapper} {
    display: none;
  }

  /* gap */
  & > * + * {
    margin-left: 11px;
  }
`;
