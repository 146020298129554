import React, { FC } from 'react';

const IconCopyProxy: FC = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_10669_103279)">
      <path d="M15 5.5H9C8.44772 5.5 8 5.94772 8 6.5V14.5C8 15.0523 8.44772 15.5 9 15.5H15C15.5523 15.5 16 15.0523 16 14.5V6.5C16 5.94772 15.5523 5.5 15 5.5Z" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5 13.5H2C1.44772 13.5 1 13.0523 1 12.5V1.5C1 0.947715 1.44772 0.5 2 0.5H11C11.5523 0.5 12 0.947715 12 1.5V2.5" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_10669_103279">
        <rect width="16" height="16" fill="var(--FFFFFF)" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default IconCopyProxy;
