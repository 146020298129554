import styled from '@emotion/styled/macro';

export const SwitchContainer = styled('div')<{ padding: number }>`
  position: relative;
  display: flex;
  padding: ${(props): number => props.padding}px;
  border-radius: 8px;
  background: var(--F2F2F2-new-pricing);
  flex-direction: row;
  cursor: pointer;
`;

export const SwitchElem = styled('div')`
  display: flex;
  padding: 3px 16px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 6px;
  background-color: transparent;
  z-index: 101;
`;

export const SwitchDivBackground = styled('div')<{ isChecked: boolean }>`
  position: absolute;
  display: flex;
  padding: 3px 16px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 6px;
  background: var(--main-colors-white, var(--FFFFFF-new-pricing));
  height: 26px;
  transition: all 0.4s ease-out;
  z-index: 100;
`;

export const SwitchText = styled('div')<{ isChecked: boolean }>`
  color: ${(props): string => props.isChecked ? 'var(--36363D-new-pricing)' : 'var(--81818A-new-pricing)'};
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
`;

export const SwitchSubtext = styled('div')<{ isChecked: boolean }>`
  color: ${(props): string => props.isChecked ? 'var(--36363D-new-pricing)' : 'var(--00A987-new-pricing)'};
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
`;
