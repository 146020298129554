import styled from '@emotion/styled';

interface IAuthTextField {
  status?: 'error'|'default';
}

export const AuthTextField = styled.input<IAuthTextField>`
  height: 50px;
  padding: 13px 14px;
  border: 1px solid var(--E0E0E0-auth-pages-text);
  border-radius: 4px;

  font-family: Calibre, Roboto;
  font-weight: 400;
  line-height: 21px;
  color: var(--808080-auth-pages-text);
  outline: none;
  background: var(--FCFCFC-auth-pages-text);

  & + * {
    margin-top: 10px;
  }
  
  // Handling "status" props
  ${(props) => {
    if (props.status === 'error') {
      return 'border: 2px solid var(--F5222D-auth-pages-text);';
    }
  }}
  
  mix-blend-mode: normal;
  
  &::placeholder {
    color: var(--808080-auth-pages-text);
  }
`;
