export interface IPasteSpintax {
  inputElem: HTMLInputElement|null;
  newText: string;
  setProfileName: (profileName: string) => void;
}

export const pasteSpintax = (pasteSpintaxParam: IPasteSpintax): void => {
  const { inputElem, setProfileName, newText } = pasteSpintaxParam;
  if (!inputElem) {
    return;
  }

  const defaultSelectionPosition = 0;
  const start = inputElem.selectionStart || defaultSelectionPosition;
  const end = inputElem.selectionEnd || defaultSelectionPosition;
  const text = inputElem.value;
  const startStringPosition = 0;
  const before = text.substring(startStringPosition, start);
  const after  = text.substring(end, text.length);
  setProfileName(before + newText + after);
};
