import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { IFreeProxy } from '../proxies.context/interfaces/IFreeProxy';

const freeProxiesAtom = atom<IFreeProxy[]>([]);

const setFreeProxies = (data: IFreeProxy[]): void => getDefaultStore().set(freeProxiesAtom, data);

export const useFreeProxies = (): IFreeProxy[] => useAtomValue(freeProxiesAtom);

export const updateFreeProxies = (data: IFreeProxy[]): void => {
  setFreeProxies(data);
};
