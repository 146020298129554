import React, { Component } from 'react';
import { Input, Slider } from 'antd';
import styled from '@emotion/styled';
import { TransProps, withTranslation } from 'react-i18next';

const InputDiv = styled('div')`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

const IpInput = styled(Input)`
  width: 30%;
`;

// TODO: BUG FIX UPDATE PROFILE
// TODO: УБРАТЬ margin-bottom
const SliderDiv = styled('div')`
  margin-bottom: 80px;
  display: inline-block;
  width: 30%;
`;

const SubTitle = styled('span')`
  display: inline-block;
  font-size: 12px;
  line-height: 14px;
  padding-bottom: 10px;
`;

declare interface IGeolocationManager extends TransProps {
  updateProfileInfo: any;
  profileInfo: any;
}

class GeolocationManager extends Component<IGeolocationManager> {
  state = {
    latitude: this.props.profileInfo.geolocation.latitude,
    longitude: this.props.profileInfo.geolocation.longitude,
    accuracy: this.props.profileInfo.geolocation.accuracy || 10,
  };

  private changeGeolocationParam(field: string, e: any) {
    const regex = /^-?\d*\.?\d*$/;
    const value = e.target.value;
    if (!regex.test(value)) {
      return;
    }

    this.setState({
      [field]: e.target.value,
    });
    this.props.updateProfileInfo({
      geolocation: {
        ...this.props.profileInfo.geolocation,
        [field]: e.target.value,
      }
    });
  }

  public render(): JSX.Element {
    return (
      <>
        <InputDiv>
          <SubTitle>{this.props.t('base.latitude')}</SubTitle>
          <IpInput
            size='small'
            onChange={(e: any) => this.changeGeolocationParam('latitude', e)}
            value={this.state.latitude}
          />
        </InputDiv>
        <InputDiv>
          <SubTitle>{this.props.t('base.longitude')}</SubTitle>
          <IpInput
            size='small'
            onChange={(e: any) => this.changeGeolocationParam('longitude', e)}
            value={this.state.longitude}
          />
        </InputDiv>
        <InputDiv>
          <SubTitle
            style={{ paddingBottom: 0 }}
          >
            {this.props.t('base.accuracy')} ({this.props.t('base.meters')?.toLowerCase()}) {this.state.accuracy}
          </SubTitle>
          <SliderDiv>
            <Slider
              step={10}
              min={10}
              value={this.state.accuracy}
              dots={true}
              tooltipVisible={false}
              onChange={(e: any) => this.changeGeolocationParam('accuracy', { target: { value: e } })}
            />
          </SliderDiv>
        </InputDiv>
      </>
    );
  }
}

export default withTranslation()(GeolocationManager);
