import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconAutomation: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_29086_5500)">
        <path
          d="M2.5 8.5C3.60457 8.5 4.5 7.60457 4.5 6.5C4.5 5.39543 3.60457 4.5 2.5 4.5C1.39543 4.5 0.5 5.39543 0.5 6.5C0.5 7.60457 1.39543 8.5 2.5 8.5Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 4.5C9.10457 4.5 10 3.60457 10 2.5C10 1.39543 9.10457 0.5 8 0.5C6.89543 0.5 6 1.39543 6 2.5C6 3.60457 6.89543 4.5 8 4.5Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.5 8.5V11.5L5.5 13.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.5 8.5C14.6046 8.5 15.5 7.60457 15.5 6.5C15.5 5.39543 14.6046 4.5 13.5 4.5C12.3954 4.5 11.5 5.39543 11.5 6.5C11.5 7.60457 12.3954 8.5 13.5 8.5Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.5 8.5V11.5L10.5 13.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 4.5V15.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_29086_5500">
          <rect width={16} height={16} fill="white" />
        </clipPath>
      </defs>
    </svg>
  </IconWrapper>
);
