import styled from '@emotion/styled';
import { Modal, CircularProgress } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import CoinbaseCommerceButton from 'react-coinbase-commerce';

export const StyledModal = styled(Modal)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 5vh 0;
  overflow: auto;
`;

export const Spinner = styled(CircularProgress)`
  &.MuiCircularProgress-colorPrimary {
    color: var(--FFFFFF-google-pay);
  }
`;

export const ModalWrapper = styled('div')`
  position: relative;
  width: 742px;
  min-height: 764px;
  padding: 40px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--FFFFFF);
  box-shadow: 0px 8px 16px var(--0000001F), 0px 12px 24px var(--0000001F), 0px 1px 32px var(--0000001F);
  border-radius: 16px;
  -webkit-tap-highlight-color: transparent;
`;

export const FormWrapper = styled('div')`
  margin-top: 40px;
  min-height: 404px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  row-gap: 32px;
`;

export const ModalTitle = styled('h2')`
  margin-bottom: 24px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: var(--36363D-new-pricing);
`;

export const ModalSubtitle = styled('h3')`
  margin: 0;
  max-height: 40px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: var(--36363D-new-pricing);
  letter-spacing: 0.25px;
`;

export const ModalText = styled('p')`
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
`;

export const ModalFooter = styled(ModalText)`
  margin: 32px auto 0;
  max-width: 400px;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: var(--98989F);
`;

export const CloseButton = styled(CloseOutlined)`
  position: absolute;
  top: 26.5px;
  right: 26.5px;
  cursor: pointer;
`;

export const StyledButton = styled('button')`
  display: block;
  margin: 32px auto 0;
  outline: none;
  padding: 11px 20px;
  min-width: 200px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: var(--FFFFFF-new-pricing);
  background: var(--00A987-new-pricing);
  border-radius: 26px;
  border: 1px solid var(--00A987-new-pricing);
  cursor: pointer;
  
  &:hover {
    background: var(--00997A-new-pricing);
  }
  
  &:disabled {
    color: var(--81818A-new-pricing);
    background: var(--FFFFFF-new-pricing);
    border: 1px solid var(--D2D2D5-new-pricing);
  }
`;

export const CryptoButton = styled(CoinbaseCommerceButton)`
  display: block;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: var(--FFFFFF);
  height: auto;
  background: var(--00A987);
  border-radius: 26px;
  border: 1px solid var(--00A987);
  margin: 0 auto;
  min-width: 200px;
  padding: 11px 20px;
  box-sizing: border-box;
  align-self: center;
  
  cursor: pointer;

  &:after {
    box-shadow: none;
    display: none;
  }

  > span {
    color: var(--333333);
    text-shadow: none;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 12px;
    cursor: pointer;
  }

  .spinner {
    top: 4px;
  }
`;

export const QiwiButton = styled(StyledButton)`
  margin: 0 auto;
`;

export const ModalErrorText = styled(ModalText)`
  margin-top: 24px;
  text-align: center;
  color: var(--FA0B0B);
`;

export const ModalErrorLink = styled(ModalErrorText)`
  display: inline;
  margin: 0;
  cursor: pointer;
  font-weight: bold;
`;
