import React, { useRef, FC, useState, useEffect } from 'react';

import { CollapseContainer } from './styles';

interface ICollapse {
  isOpen: boolean;
  isAlwaysOpen: boolean;
}

const Collapse: FC<ICollapse> = (props) => {
  const { isOpen, isAlwaysOpen, children } = props;

  const [shouldMount, setShouldMount] = useState<boolean>(isOpen || isAlwaysOpen);

  const ref = useRef<HTMLDivElement|null>(null);

  useEffect(() => {
    if (shouldMount) {
      return;
    }

    window.requestIdleCallback(
      () => setShouldMount(true),
      { timeout: 200 },
    );
  }, [shouldMount]);

  if (isAlwaysOpen) {
    return (
      <CollapseContainer isOpen={true}>
        {children}
      </CollapseContainer>
    );
  }

  if (!shouldMount) {
    return <CollapseContainer isOpen={false} />;
  }

  return (
    <CollapseContainer
      ref={ref}
      isOpen={isOpen}
    >
      {children}
    </CollapseContainer>
  );
};

export default Collapse;
