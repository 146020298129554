import { useTranslate } from '@tolgee/react';
import React, { FC, useContext, useState } from 'react';

import { ChoosePlanButton } from './domElements';
import { IPlan } from '../../../interfaces/plan';
import {
  useCheckoutCurrency,
  useDiscountObjById,
  usePlanObjById,
  usePriceConfig,
  usePromoDiscountAvaliable,
  userContext,
  workspaceContext,
} from '../../../state';
import { useIsUpgradeDiscountAvaliable, useUpgradeDiscount } from '../../../state/pricing/upgrade-discount.atom';
import TooltipCustom from '../../../ui/tooltip-custom';
import { ANNUAL_DISCOUNT, MONTH_DISCOUNT } from '../../modalsComponents/components/checkout-modal/constants';
import { BackgroundType } from '../constants';
import { E_PERIOD } from '../interfaces';
import { updatePlanAndSendStats } from '../plans-data';
import { Price } from '../utils/price';

interface ISwitchPlanButton {
  columnPlanId: string;
  selectedDiscount: string;
  buttonStyle: BackgroundType;
  isSmallButton?: boolean;
  isShortText?: boolean;
  getPlanInfo: (planId: string, field: keyof IPlan) => JSX.Element | string;
  onPlanSelect: (id: string, isUpgrade?: boolean) => Promise<void>;
}

const SwitchPlanButton: FC<ISwitchPlanButton> = (props) => {
  const { columnPlanId, selectedDiscount, buttonStyle, isSmallButton, isShortText, getPlanInfo, onPlanSelect } = props;

  const columnPlanObj = usePlanObjById(columnPlanId);
  const selectedDiscounObj = useDiscountObjById(selectedDiscount);
  const upgradeDiscount =  useUpgradeDiscount();
  const currency = useCheckoutCurrency();
  const isUpgradeDiscountAvaliable = useIsUpgradeDiscountAvaliable();
  const promoDiscount = usePromoDiscountAvaliable();
  const priceConfig = usePriceConfig();

  const [anchorElTooltip, setAnchorElTooltip] = useState<HTMLElement|null>(null);
  const [tooltipText, setTooltipText] = useState('');

  const { firstPlanSelected, payment, plan } = useContext(userContext);
  const {
    id: workspaceId,
    planId,
    planName,
    paymentDiscount,
  } = useContext(workspaceContext);

  const workspacePlanObj = usePlanObjById(planId);

  const { t: translation } = useTranslate();

  let { discountId: currentDiscount } = payment;
  let { name: currentPlanName } = plan;

  if (workspaceId) {
    currentDiscount = paymentDiscount;
    currentPlanName = planName;
  }

  const isDiscountDowngrade = currentDiscount === ANNUAL_DISCOUNT && currentDiscount !== selectedDiscount;

  const getDowngradeTooltipText = (): string => {
    if (isDiscountDowngrade) {
      return translation('pricing.buttonHoverTooltip.text3');
    }

    return translation('pricing.buttonHoverTooltip.text2', '', {
      currentPlan: currentPlanName,
      selectedPlan: getPlanInfo(columnPlanId, 'name'),
    });
  };

  const onPayNow = async (): Promise<void> => {
    updatePlanAndSendStats({
      onPlanSelect,
      planId: columnPlanId,
      firstPlanSelected,
    });
  };

  const getLabelButton = (): string => {
    if (firstPlanSelected) {
      return translation('pricing.button.payNow');
    }

    if (isSmallButton) {
      return translation(`pricing.planTableButton.${currentPlanName.toLowerCase()}`);
    }

    return translation('pricing.button.trySevenDay');
  };

  const onMouseLeave = (): void => {
    setAnchorElTooltip(null);
  };

  const getTooltipMessage = (): JSX.Element => (
    <div>
      {tooltipText}
      <strong>
        {translation('pricing.buttonHoverTooltip.supportMessage')}
      </strong>
    </div>
  );

  const renderCurrentButton = (): JSX.Element => (
    <ChoosePlanButton
      buttonStyle={buttonStyle}
      isSmallButton={isSmallButton}
      onClick={onPayNow}
    >
      {isShortText ? translation('pricing.button.currentPlan') : translation('pricing.currentPlan.text')}
    </ChoosePlanButton>
  );

  const renderDowngradePlan = (): JSX.Element => (
    <ChoosePlanButton
      onMouseEnter={(event): void => {
        setAnchorElTooltip(event.currentTarget);
        setTooltipText(getDowngradeTooltipText());
      }}
      onMouseLeave={onMouseLeave}
      isDisabled={true} buttonStyle={buttonStyle} isSmallButton={isSmallButton}>
      {translation('pricing.downgradePlan.button')}
    </ChoosePlanButton>
  );

  const renderUpgradePlan = (): JSX.Element => (
    <ChoosePlanButton
      buttonStyle={buttonStyle}
      onClick={onPayNow}
      isSmallButton={isSmallButton}
    >
      {isShortText ? translation('pricing.button.upgradePlan') : translation('pricing.upgradePlan.button')}
    </ChoosePlanButton>
  );

  const getButton = (): JSX.Element => {
    const { periodType: period = E_PERIOD.ANNUAL } = upgradeDiscount;
    const totalPrice = new Price(columnPlanObj, currency)
      .period(selectedDiscounObj)
      .promo(promoDiscount, priceConfig)
      .upgrade(upgradeDiscount)
      .getAmount();

    const isSamePlan = columnPlanId === planId && selectedDiscount === paymentDiscount;
    const isPeriodDowngrade = (period === E_PERIOD.ANNUAL) && (selectedDiscount === MONTH_DISCOUNT);
    const { priceForMonth: selectedPriceForMonth = 0 } = columnPlanObj || {};
    const { priceForMonth: workspacePriceForMonth = 0 } = workspacePlanObj || {};
    const isPlanDowngrade = selectedPriceForMonth < workspacePriceForMonth;
    if (isUpgradeDiscountAvaliable) {
      if (isSamePlan) {
        return renderCurrentButton();
      }

      if (totalPrice < 0 || isPeriodDowngrade || isPlanDowngrade) {
        return renderDowngradePlan();
      }

      return renderUpgradePlan();
    }

    return (
      <ChoosePlanButton
        buttonStyle={buttonStyle}
        onClick={onPayNow}
        isSmallButton={isSmallButton}
      >
        {getLabelButton()}
      </ChoosePlanButton>
    );
  };

  return (
    <>
      {getButton()}
      <TooltipCustom
        anchorEl={anchorElTooltip}
        value={getTooltipMessage()}
        placement='bottom'
        style={{
          maxWidth: 216,
          color: 'var(--36363D)',
        }}
      />
    </>
  );

};

export default SwitchPlanButton;
