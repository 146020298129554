import React, { FC, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import moment from 'moment';
import { PROFILE_LIST_PAGE } from '../../../../common/constants/routes';
import { IProxy } from '../../../interfaces';
import { templatesContext } from '../../../state';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { useOpenedLocationChangedHint } from '../../../state/opened-location-changed-hint.atom';
import { setProxyStatuses } from '../../../state/proxies.context/api';
import { updateGeoProxyCustomNameCounters, useDefaultGeoProxy } from '../../../state/proxy/default-geoproxy.atom';
import { updateGeoProxyForm } from '../../../state/proxy/geoproxy-form-data.atom';
import { PROXY_CHECK_TOOLTIP_LOCATIONS } from '../../../state/proxy/proxy-check/constants';
import { showProxyCheckTooltip } from '../../../state/proxy/proxy-check/proxy-check-tooltip.atom';
import { addProxyStatuses, resetProxyStatuses } from '../../../state/proxy/proxy-check/proxy-statuses.atom';
import { updateProxyItem } from '../../../state/proxy/proxy-list.atom';
import { checkGeoProxy } from '../../../state/proxy/proxy-operations/create-geoproxy.operations';
import { openProxySettingsModal } from '../../../state/proxy/proxy-settings-modal.atom';
import { IconPlus } from '../../../ui/gologin-header/icons';
import IconPlusInCircle from '../../../ui/icons/IconPlusInCircle';
import { updateProxyAndProfileProxyData } from '../../common/update-local-profile-data';
import { getProxyStatusParams, makeGeoProxyCustomName } from '../../proxy/proxy-helpers';
import { evaluateGeoProxyCustomNameCounter } from '../../proxy/utils/default-proxy-data';
import { generateArtificialGeoProxyId } from '../../proxy/utils/proxy-id';
import { quickCreateProfile } from './create-quick-profile.action';
import LoadableIconButton from './loadable-icon-button';

interface IQuickCreateProfile {
  tooltipContent: JSX.Element;
}

const ButtonQuickCreateProfile: FC<IQuickCreateProfile> = props => {
  const { tooltipContent } = props;

  const templateCtx = useContext(templatesContext);

  const location = useLocation();
  const isProfilesPage = location.pathname === PROFILE_LIST_PAGE;

  const renderIcon = (): JSX.Element => {
    if (NEW_FEATURES.header) {
      return (
        <IconPlus
          margin='-4px 0 0 0'
          styleType='activeGrayWithBackground'
          iconColor='var(--98989F-profile-table-create-quick-profile)'
          iconHoveredColor='var(--2B2B31-profile-table-create-quick-profile)'
        />
      );
    }

    return <IconPlusInCircle />;
  };
  const defaultGeoProxy = useDefaultGeoProxy();

  const makeArtificialGeoProxy = ({
    country,
    city,
    connectionType,
    customName,
    profilesCount,
    groupId,
  }: any): IProxy => {
    const proxyId = generateArtificialGeoProxyId();
    updateGeoProxyCustomNameCounters(country);

    return {
      id: proxyId,
      groupId,
      host: '',
      port: 80,
      mode: 'geolocation',
      country,
      city,
      connectionType,
      customName,
      profilesCount,
      customSortingInGroup: 'end',
      createdAt: new Date(),
      selectionDate: profilesCount ? Date.now() : 0,
    };
  };

  const handleClick = async (): Promise<void> => {
    if (isProfilesPage) {
      const newProfileData = await quickCreateProfile(templateCtx);
      
    } else {
      openProxySettingsModal({ modalView: 'proxy-add', currentProxy: null });
    }
  };

  return (
    <LoadableIconButton
      onClick={handleClick}
      icon={renderIcon()}
      tooltipContent={tooltipContent}
      style={{
        margin: NEW_FEATURES.header ? '-4px 5px 0 0' : '-4px 5px 0 11px',
        height: 21,
      }}
    />
  );
};

export default ButtonQuickCreateProfile;
