import styled from '@emotion/styled';

export const TooltipContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 236px;
`;

export const Description = styled('p')`
  font: 400 12px/16px Roboto;
  letter-spacing: 0.2px;
  color: var(--36363D);
  max-width: 204px;
`;

export const BottomContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 204px;
  padding-bottom: 4px;
`;

export const ReadMore = styled('div')`
  cursor: pointer;
  width: 154px;
  font: 400 12px/16px Roboto;
  letter-spacing: 0.2px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--767676);
`;

