import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { HiddenContainer, SearchInput } from './styles';
import { DivAnchor } from '../../types';

interface ISelectCustom {
  value: string;
  placeholder: string;
  onChange: (value: string) => void;
  maxWidth?: string;
}

export const StretchSearch: FC<ISelectCustom> = (props): JSX.Element => {
  const {
    value, placeholder, onChange, maxWidth,
  } = props;

  const [isUsePlaceholder, setIsUsePlaceholder] = useState<boolean>(true);

  const searchRef = useRef<DivAnchor>(null);
  const hiddenDivRef = useRef<DivAnchor>(null);

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.focus();
    }
  }, []);

  useLayoutEffect(() => {
    if (!(hiddenDivRef.current && searchRef.current)) {
      return;
    }

    searchRef.current.style.width = `${hiddenDivRef.current?.getBoundingClientRect().width}px`;
  }, []);

  return (
    <>
      <HiddenContainer ref={hiddenDivRef}>
        {isUsePlaceholder ? placeholder : value}
      </HiddenContainer>
      <SearchInput
        value={isUsePlaceholder ? placeholder : value}
        onChange={(event): void => {
          onChange(event.target.value);
          setIsUsePlaceholder(false);
        }}
        onFocus={(event): void => event.target.select()}
        ref={searchRef}
        style={{ maxWidth }}
        onClick={(event): void => event.stopPropagation()}
      />
    </>
  );
};
