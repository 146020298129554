import { IFullProfile, IProfile } from '../../../../interfaces';
import { DEFAULT_PROFILE_DATA } from '../../../../utils/default-profile-data';
import { requestShortCookies } from '../../../common/api';
import { handleActiveProxiesAlreadyArchived } from '../../../proxy/proxy-helpers';
import { requestFullProfileInfo } from '../../api';

interface IGetProfileInfo {
  profileData: IProfile|IFullProfile;
  updateProfile: (data: IFullProfile) => void;
  setCookiesLoading: (state: boolean) => void;
}

export const getProfileInfo = async (props: IGetProfileInfo): Promise<void> => {
  const { profileData, updateProfile, setCookiesLoading } = props;
  if (!Object.prototype.hasOwnProperty.call(profileData, 'cookies')) {
    setCookiesLoading(true);
  }

  const promises = [
    requestFullProfileInfo(profileData.id, true),
    requestShortCookies(profileData.id),
  ];

  const [fingerprint, cookies] = await Promise.all(promises);
  const {
    geolocation = DEFAULT_PROFILE_DATA.geolocation,
    webRTC = DEFAULT_PROFILE_DATA.webRTC,
  } = fingerprint;

  const { archivedProxy = null } = fingerprint || {};
  if (archivedProxy) {
    handleActiveProxiesAlreadyArchived([archivedProxy]);
  }

  const { archivedProxy: profileDataArchivedProxy } = profileData;
  if (profileDataArchivedProxy && !archivedProxy) {
    delete profileData.archivedProxy;
  }

  updateProfile({
    ...DEFAULT_PROFILE_DATA,
    ...profileData,
    ...fingerprint,
    folders: profileData.folders || [],
    geolocation: {
      ...profileData.geolocation,
      ...geolocation,
    },
    webRTC,
    cookies,
    isFullProfile: true,
  });

  setCookiesLoading(false);
};
