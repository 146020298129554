import React from 'react';

import { IconSpinnerOld } from '../../../../ui/gologin-header/icons';

interface ICustomTableLoader {
  areColumnsResizing: boolean;
  style?: React.CSSProperties;
  iconSize?: number;
  iconPadding?: number;
  isTextCentered?: boolean;
}

const CustomTableLoader: React.FC<ICustomTableLoader> = (props) => {
  const {
    areColumnsResizing,
    style,
    iconSize = 32,
    iconPadding = 8,
    isTextCentered = true,
  } = props;

  if (areColumnsResizing) {
    return null;
  }

  const finalStyle = { ...style };
  if (isTextCentered) {
    finalStyle.textAlign = 'center';
  }

  return (
    <div style={finalStyle}>
      <IconSpinnerOld size={iconSize} padding={iconPadding} />
    </div>
  );
};

export default CustomTableLoader;
