import { message } from 'antd';

import { ISetExtensionsOptions } from './interfaces/extensions-modal.interface';
import { API_BASE_URL } from '../../../../../common/constants/constants';
import { http } from '../../../../services';

export const setExtensionsRequest = async (options: ISetExtensionsOptions) => {
  http(`${API_BASE_URL}/browser/chrome_extensions/many`, {
    method: 'PATCH',
    body: JSON.stringify(options),
  }).catch(() => {
    message.error('Something went wrong updating your extensions. Please try again later');

    return null;
  });

  return null;
};

export const getExtensionsCount = async (): Promise<number> => {
  const data = await http(`${API_BASE_URL}/extensions/count`, {
    method: 'GET',
  }).catch(() => ({ body: 0 }));

  return data.body;
};
