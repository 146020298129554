import { atom, getDefaultStore, useAtomValue } from 'jotai';

export const allProfilesFolderIdAtom = atom<string|null>(null);

export const getAllProfilesFolderId = (): string|null => getDefaultStore().get(allProfilesFolderIdAtom);
export const useAllProfilesFolderId = (): string|null => useAtomValue(allProfilesFolderIdAtom);
export const setAllProfilesFolderId = (newAllProfilesFolderId: string|null): void => {
  const prevAllProfilesFolderId = getAllProfilesFolderId();
  if (newAllProfilesFolderId !== prevAllProfilesFolderId) {
    getDefaultStore().set(allProfilesFolderIdAtom, newAllProfilesFolderId);
  }
};
