import { IBookmarkFoldersObj } from '../interfaces/bookmarks-utils.interfaces';

interface IZeroProfileBookmarks {
  checksum: string;
  roots: IBookmarkFoldersObj;
  version: number;
}

export const zeroProfileBookmarks: IZeroProfileBookmarks = {
  'checksum': 'f2a8ce0f0fddfeee2229d72f0f30e713',
  'roots': {
    'bookmark_bar': {
      'children': [ {
        'children': [ {
          'name': 'Facebook',
          'type': 'url',
          'url': 'https://www.facebook.com/',
        }, {
          'name': 'Notifications',
          'type': 'url',
          'url': 'https://www.facebook.com/settings?tab=notifications',
        }, {
          'name': 'Account Quality',
          'type': 'url',
          'url': 'https://www.facebook.com/accountquality',
        }, {
          'name': 'Ads Manager',
          'type': 'url',
          'url': 'https://www.facebook.com/adsmanager/manage/campaigns',
        }, {
          'name': 'Billing',
          'type': 'url',
          'url': 'https://www.facebook.com/ads/manager/account_settings/account_billing',
        }, {
          'name': 'Business Manager',
          'type': 'url',
          'url': 'https://business.facebook.com/select',
        } ],
        'name': 'Facebook',
        'type': 'folder',
      }, {
        'children': [ {
          'name': 'Amazon',
          'type': 'url',
          'url': 'https://www.amazon.com/',
        }, {
          'name': 'eBay',
          'type': 'url',
          'url': 'https://ebay.com/',
        }, {
          'name': 'Walmart',
          'type': 'url',
          'url': 'https://walmart.com/',
        }, {
          'name': 'Shopify',
          'type': 'url',
          'url': 'https://www.shopify.com/',
        }, {
          'name': 'Avito',
          'type': 'url',
          'url': 'https://avito.ru/',
        }, {
          'name': 'Taobao',
          'type': 'url',
          'url': 'https://taobao.com/',
        }, {
          'name': 'Wildberries',
          'type': 'url',
          'url': 'https://wildberries.ru/',
        }, {
          'name': 'Aliexpress',
          'type': 'url',
          'url': 'https://aliexpress.com/',
        }, {
          'name': 'PayPal',
          'type': 'url',
          'url': 'https://paypal.com/',
        }, {
          'name': 'Payoneer',
          'type': 'url',
          'url': 'https://payoneer.com/',
        } ],
        'name': 'Dropshipphing',
        'type': 'folder',
      }, {
        'children': [ {
          'name': 'Coinlist',
          'type': 'url',
          'url': 'https://coinlist.co/',
        }, {
          'name': 'Huobi',
          'type': 'url',
          'url': 'https://www.huobi.com/',
        }, {
          'name': 'Discord',
          'type': 'url',
          'url': 'https://discord.com/',
        }, {
          'name': 'Premint',
          'type': 'url',
          'url': 'https://www.premint.xyz/',
        } ],
        'name': 'Crypto',
        'type': 'folder',
      }, {
        'children': [ {
          'name': 'bet365',
          'type': 'url',
          'url': 'https://www.bet365.com/',
        }, {
          'name': 'Fonbet',
          'type': 'url',
          'url': 'https://www.fon.bet/',
        }, {
          'name': '1xbet',
          'type': 'url',
          'url': 'https://1xbet.com/',
        }, {
          'name': 'Parimatch',
          'type': 'url',
          'url': 'https://parimatch.com/',
        }, {
          'name': 'Melbet',
          'type': 'url',
          'url': 'https://melbet.com/',
        }, {
          'name': '1xstavka',
          'type': 'url',
          'url': 'https://1xstavka.ru/',
        } ],
        'name': 'Betting',
        'type': 'folder',
      }, {
        'name': 'Facebook',
        'type': 'url',
        'url': 'https://www.facebook.com/',
      }, {
        'name': 'Amazon',
        'type': 'url',
        'url': 'https://www.amazon.com/',
      }, {
        'name': 'TikTok',
        'type': 'url',
        'url': 'https://www.tiktok.com/',
      }, {
        'name': 'Google Ads',
        'type': 'url',
        'url': 'https://ads.google.com/',
      }, {
        'name': 'Browser check',
        'type': 'url',
        'url': 'https://iphey.com/',
      } ],
      'name': 'Bookmarks bar',
      'type': 'folder',
    },
    'other': {
      'children': [  ],
      'name': 'Other bookmarks',
      'type': 'folder',
    },
    'synced': {
      'children': [  ],
      'name': 'Mobile bookmarks',
      'type': 'folder',
    },
  },
  'version': 1,
};
