import { API_BASE_URL } from '../../../common/constants/constants';
import { denormalizeProfile } from '../../../common/utils';
import { IProfile } from '../../interfaces';
import { http } from '../../services';
import { INewNameProfile } from '../../utils/parse-name-formatting/interfaces';

export const requestFullProfileInfo = async (profileId: string, isWithCookies = false): Promise<IProfile> => {
  const url = new URL(`${API_BASE_URL}/browser/${profileId}`);
  if (isWithCookies) {
    url.searchParams.append('withCookies', 'true');
  }

  const data = await http(url.toString());

  return denormalizeProfile(data.body);
};

export const renameProfileNameMany = async (newNameProfiles: INewNameProfile[]) => {
  const data = await http(`${API_BASE_URL}/browser/name/many`, {
    method: 'PATCH',
    body: JSON.stringify(newNameProfiles),
  });

  return data.body;
};
