import React, { FC } from 'react';
import type { ResizeCallbackData } from 'react-resizable';
import { Resizable } from 'react-resizable';

const ResizableTitle = (
  props: React.HTMLAttributes<any> & {
    onResize: (e: React.SyntheticEvent<Element>, data: ResizeCallbackData) => void;
    width: number;
    minWidth: number;
    resizeStart: () => void;
    resizeEnd: () => void;
  },
): JSX.Element => {
  const { onResize, width, resizeStart, minWidth, resizeEnd, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      minConstraints={[minWidth, 0]}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e): void => {
            e.stopPropagation();
          }}
        />
      }
      className="noselect"
      onResize={onResize}
      onResizeStart={(): void => {
        resizeStart();
      }}
      onResizeStop={(): void => {
        resizeEnd();
      }}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export default ResizableTitle;
