import React, { useMemo } from 'react';

import { AutomationWorkflow, IProfileLaunch } from '../interfaces';
import { getWorkflowsPinnedAndTheRest } from '../utils/get-workflows-pinned-and-the-rest';
import { WorkflowItem } from './workflow-item';
import { LoadingSpinner } from '../../../ui/loading-spinner';
import { ScrollbarContainer } from '../../../ui/scrollbar';

interface IAutomationList extends IProfileLaunch {
  isLoading: boolean;
  workflows: AutomationWorkflow[];
  profileId?: string;
}

const WorkflowList: React.FC<IAutomationList> = (props) => {
  const { isLoading, workflows, profileId, launchProfileOrbita } = props;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const { pinned, theRest } = useMemo(() => getWorkflowsPinnedAndTheRest(workflows), [workflows]);

  const renderWorkflowItem = ({ id, icon, name, slug, drawflow, fullDescription }: AutomationWorkflow, index: number): JSX.Element =>
    <WorkflowItem {...{ id, icon, name, slug, drawflow, fullDescription, profileId, launchProfileOrbita }} key={index} />;

  return (
    <ScrollbarContainer style={{ maxHeight: 490, marginTop: 24 }}>
      {pinned.map(renderWorkflowItem)}
      {theRest.map(renderWorkflowItem)}
    </ScrollbarContainer>
  );
};

export default WorkflowList;
