import { getTextShadowParams } from '../utils';

export const drawTextShadow = (context: CanvasRenderingContext2D, canvasSize: number): void => {
  const { shadowOffsetY, shadowBlur } = getTextShadowParams(canvasSize);

  context.shadowColor = 'rgba(0, 0, 0, 0.25)';
  context.shadowOffsetX = 0;
  context.shadowOffsetY = shadowOffsetY;
  context.shadowBlur = shadowBlur;
};
