import styled from '@emotion/styled';
import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

const IconContainer = styled('svg')`
  animation: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes spin {
    from {
      transform:rotate(0deg);
    }
    to {
      transform:rotate(360deg);
    }
  }
`;

export const IconPreload: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <IconContainer width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_11934_6256)'>
        <path d='M0.5 8.0002C0.499956 6.24712 1.11403 4.5494 2.23554 3.20199C3.35706 1.85457 4.91515 0.942606 6.63913 0.624511C8.36312 0.306415 10.1441 0.602293 11.6726 1.46074C13.2011 2.31919 14.3806 3.68597 15.0062 5.32364C15.6318 6.96131 15.6639 8.76638 15.097 10.4253C14.5302 12.0842 13.4 13.4921 11.9031 14.4044C10.4061 15.3167 8.63677 15.6758 6.90255 15.4193C5.16833 15.1628 3.57877 14.3068 2.41 13.0002' stroke='var(--222222)' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_11934_6256'>
          <rect width='16' height='16' fill='var(--FFFFFF)' />
        </clipPath>
      </defs>
    </IconContainer>
  </IconWrapper>
);
