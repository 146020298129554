import React, { FC, memo } from 'react';

import { GologinTableHeaderColumnContainer } from './styles';
import { SelectionStatus } from '../../state/profiles-table-selected-ids.atom';
import { GologinNewCheckbox } from '../gologin-checkbox/gologin-new-checkbox';

interface IAllRowsSelection {
  className: string;
  width: number;
  useSelectionStatus: () => SelectionStatus;
  toggleAllRowsSelection: () => void;
}

const AllRowsSelection: FC<IAllRowsSelection> = (props) => {
  const { className, width, useSelectionStatus, toggleAllRowsSelection } = props;

  const allRowsSelectionStatus = useSelectionStatus();
  const isSelected = allRowsSelectionStatus === 'all';
  const isIndeterminate = allRowsSelectionStatus === 'some';

  return (
    <GologinTableHeaderColumnContainer
      style={{ width, height: 47 }}
      className={className}
      newStyle={true}
    >
      <GologinNewCheckbox
        innerPadding={'15px 0 0 6px'}
        onChange={toggleAllRowsSelection}
        checked={isSelected}
        isIndeterminate={isIndeterminate}
        isShownOnlyOnHover={true}
        isFillingSpace={true}
      />
    </GologinTableHeaderColumnContainer>
  );
};

export default memo(AllRowsSelection);
