import { getGoogleClientId } from '../../../../initGA';
import { jsFonts } from '../../../../utils/fonts-hash';
import { updateCommonEvents } from '../api';

export const sendStats = async () => {
  let isAdvertising = false;
  let utm;

  const googleClientId = await getGoogleClientId();

  const cookies = (document.cookie || '').split(';');
  if (cookies.length) {
    utm = cookies.find(elem => elem.includes('utm='));

    if (utm) {
      isAdvertising = true;
    }
  }

  const fontsHash = jsFonts();

  updateCommonEvents({
    isAdvertising,
    googleClientId,
    fontsHash,
    utm,
  });
};
