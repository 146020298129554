import { redirectToDesktopWithShare } from './redirect-to-desktop';
import { history } from '../../../../services';
import { ACCESS_TOKEN } from '../../../../services/http/config';

export const handleRedirectViaShareLink = (profileName: string, linkShortId: string): void => {
  if (!(profileName && linkShortId)) {
    return;
  }

  const token = localStorage.getItem(ACCESS_TOKEN) || '';
  redirectToDesktopWithShare(profileName, linkShortId, token);
  if (!token) {
    history.push('/share');
  }
};
