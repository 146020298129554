import * as Sentry from '@sentry/react';

import { generateBrowserIconsPromise } from './generate-browser-icons-promise';

export const generateBrowserIconsOnStart = (): void => {
  const transactionGenerateBrowserIcons = Sentry.startTransaction({ name: 'generate-browser-icons-on-app-start' });

  /**
   * It takes quite a bit of time to check all of these cases,
   * and they have to be stored somewhere.
   * That's why I am leaving them here to speed up further development iterations and tests.
   * As soon as this part is covered by tests, the comments shall be removed.
   *
   * Also, one needs to insert in the beginning of the App component:
   *
    generateBrowserIconsOnStart();

    return <></>;
   */

  generateBrowserIconsPromise({
    profileId: '1',
    profileName: '1',
    // profileName: 'гугл',
    // profileName: 'проф',
    // profileName: 'пфор',
    // profileName: 'dacq',
    // profileName: 'abaa',
    // profileName: 'abcd',
    // profileName: 'aaaa',
    // profileName: 'ABCD',
    // profileName: 'AaaA',
    // profileName: 'qqqA',
    // profileName: 'qqqq',
    // profileName: 'AAAA',
    // profileName: 'Click to change',
    // profileName: 'Facebook',
    // profileName: 'Linked',
    // profileName: 'Coinlist',
    // profileName: 'profile',
    // profileName: 'Google',
    // profileName: 'pfil',
    // profileName: 'a',
    // profileName: 'b',
    // profileName: 'q',
    // profileName: 'lq',
    osFormatted: 'win10',
    scaleFactor: 0.5,
    isInitialRender: true,
    iconDesignVersion: '2:green-square',
  });

  transactionGenerateBrowserIcons?.finish();
};
