import generateOrderList, { IBasicEntity } from './create-order-list';
import { IMovedProfile } from './moved-profile.interface';
import { IDraggedItem, reorderItems } from './reorder-items';

interface IReorderBasicEntitiesOpts<T extends IBasicEntity> {
  basicEntities: T[];
  fromIndex: number;
  toIndex: number;
  allDraggedProfiles: IDraggedItem[];
}

interface IReorderBasicEntitiesResult<T> {
  targetGroupId: string | null;
  movedItems: Array<T & Pick<IMovedProfile, 'movedFromGroupHeaderIds'>>;
  reorderedList: T[];
}

const reorderBasicEntities = <T extends IBasicEntity>(opts: IReorderBasicEntitiesOpts<T>): IReorderBasicEntitiesResult<T> => {
  const { basicEntities, fromIndex, toIndex, allDraggedProfiles } = opts;
  const orderList = generateOrderList({ basicEntities, middleIndex: fromIndex });
  const targetGroupId = orderList[toIndex]?.targetGroupId;
  const { movedItems, reorderedList } = reorderItems({
    orderList,
    fromIndex,
    toIndex,
    draggedItems: allDraggedProfiles,
    targetGroupId,
  });

  return { targetGroupId, movedItems, reorderedList };
};

export default reorderBasicEntities;
