import { i18n as i18next } from 'i18next';

import { API_BASE_URL } from '../../../../common/constants/constants';
import { ThemeType } from '../../../interfaces/theme.type';
import { history } from '../../../services';
import { ACCESS_TOKEN, MEMBER_TOKEN } from '../../../services/http/config';
import { retrieveToken } from '../../../services/http/storage';
import { switchTheme } from '../../../state/theme.atom';
import { deleteTokenRequest } from '../api';

const isElectron = !!window.require;

export const makeAutoLogin = async ({ query, i18n }: { query: URLSearchParams; i18n: i18next }): Promise<void> => {
  if (isElectron) {
    return;
  }

  const autoLoginToken = query.get('auto-login-token');
  const locale = query.get('locale');
  const theme = query.get('theme') as ThemeType;

  if (locale) {
    i18n.changeLanguage(locale);
  }

  if (!autoLoginToken) {
    return;
  }

  if (theme) {
    switchTheme(theme);
  }

  query.delete('auto-login-token');
  query.delete('theme');
  let newAccessToken: string;
  try {
    const getAccessTokenData = await fetch(`${API_BASE_URL}/user/auto-login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${autoLoginToken}`,
      },
    });

    const accessTokenData: { token: string } = await getAccessTokenData.json();
    newAccessToken = accessTokenData?.token;
  } catch (error) {
    return;
  }

  const accessToken = await retrieveToken();
  if (newAccessToken) {
    localStorage.setItem(ACCESS_TOKEN, newAccessToken);
  }

  const memberToken = sessionStorage.getItem(MEMBER_TOKEN);
  if (memberToken) {
    sessionStorage.removeItem('selected_account');
    sessionStorage.removeItem(MEMBER_TOKEN);
  }

  if (accessToken) {
    const [_, payload] = (accessToken as string).split('.');
    const tokenJSON = atob(payload);
    const tokenPayload = JSON.parse(tokenJSON);
    const { jwtid } = tokenPayload;

    await deleteTokenRequest(jwtid);
  }

  const querystring = query.toString();

  history.replace({
    ...location,
    search: querystring && `?${querystring}`,
  });
};
