import React, { FC, memo } from 'react';

import { IBasicTableEntity } from '../../../state/profiles-table/basic-table-entities-subtypes';
import TagsCell from '../../tags/components/tags';
import { IAddTag, ITagBase } from '../../tags/interfaces/tag.interface';

interface ICustomStatusCell {
  profileAtom: IBasicTableEntity['atom'];
  rowIdx: IBasicTableEntity['idx'];
  addTag: (params: IAddTag) => Promise<void>;
  updateTag: (tag: ITagBase) => Promise<void>;
  removeProfileTag: (profileIds: string[], tagId: string) => Promise<void>;
  removeTag: (tagId: string, isInSuggest: boolean) => Promise<void>;
  onTagsPopupOpen: (profileTagsCount: number, totalTagsCount: number) => void;
}

const CustomStatusCell: FC<ICustomStatusCell> = (props) => {
  const {
    profileAtom,
    rowIdx,
    addTag,
    updateTag,
    removeProfileTag,
    removeTag,
    onTagsPopupOpen,
  } = props;

  return (
    <TagsCell
      profileAtom={profileAtom}
      rowIdx={rowIdx}
      addTag={addTag}
      updateTag={updateTag}
      removeProfileTag={removeProfileTag}
      removeTag={removeTag}
      onTagsPopupOpen={onTagsPopupOpen}
      field='custom-status'
    />
  );
};

export default memo(CustomStatusCell);
