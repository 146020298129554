import styled from '@emotion/styled';
import { Icon, Menu } from 'antd';

export const Row = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const IconSharing = styled(Icon)`
  font-size: 9px;
  margin-top: 4px;
  opacity: 0.5;
  color: var(--000000-modals-profile-share-modal);
  margin-right: 3px;
  padding-top: 0;
`;

export const ItemChangeRoleTitle = styled('p')`
  font: normal 14px 'Roboto';
  color: var(--000000-modals-profile-share-modal);

  margin: 0;
`;

export const TextSharing = styled('span')`
  font-family: Roboto;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: var(--222222-modals-profile-share-modal);
  
  white-space: nowrap;
  justify-content: flex-end;
`;

export const RowSpaceBetween = styled(Row)`
  justify-content: space-between;
`;

export const UserIconContainer = styled('div')`
  margin-right: 4px;
  margin-bottom: 0;
  margin-top: 4px;
`;

export const UserContainer = styled('div')`
  display: contents;
`;

export const TextEmail = styled('span')`
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: var(--222222-modals-profile-share-modal);

  width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 4px;
`;

export const TextMessageWrapper = styled('div')`
  display: inline-block;
  font-size: 14px;
  line-height: 18px;
  color: var(--767676-modals-profile-share-modal);
  font-family: 'Roboto';
`;

export const CustomMenu = styled(Menu)`
  border-radius: 8px;
  width: 264px;
  padding: 8px 0;

  &.ant-menu-root.ant-menu-vertical {
    box-shadow: 0 2px 8px var(--00000026-antd) !important;
    border: none;
  }
`;

export const ConfirmTitleText = styled('span')`
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-left: 1px;
  margin-bottom: 0;

  color: var(--222222-modals-transfer-profile);
`;

export const ConfirmButton = styled('div')`
  line-height: 1.5;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  font-size: 14px;
  border-radius: 4px;
  outline: 0;
  color: var(--000000A6-antd);
  background-color: var(--FFFFFF-antd);
  border: 1px solid var(--D9D9D9-antd);
  padding: 6px 12px;
  
  :hover {
    color: var(--1FC47D-antd-button-text);
    background-color: var(--FFFFFF-antd);
    border-color: var(--1FC47D-antd-button-text);
  }
`;

export const CancelButton = styled(ConfirmButton)`
  color: var(--FFFFFF-antd);
  background-color: var(--00B86D-antd);
  border-color: var(--00B86D-antd);
  
  :hover {
    color: var(--FFFFFF-antd);
    background-color: var(--1FC47D-antd);
    border-color: var(--1FC47D-antd);
  }
`;
