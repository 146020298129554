import { redirectToElectron } from '../../../common/components/redirect-to-desktop-page/utils';

export const redirectToDesktopWithShare = (profileName = '', linkId = '', token = ''): void => {
  let path = `share/${profileName}/${linkId}`;
  if (token) {
    const searchParams = new URLSearchParams();
    searchParams.append('access-token', token);
    path += `?${searchParams.toString()}`;
  }

  localStorage.setItem('SelectedFolder', '');
  redirectToElectron(path);
};
