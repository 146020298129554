import React from 'react';

const IconPayPal = () => (
  <svg width="45" height="29" viewBox="0 0 45 29" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="45" height="29" rx="1" fill="var(--E0E7EB)"/>
</svg>


);

export default IconPayPal;
