import { IProfileFBAccount } from './interfaces';
import { API_BASE_URL } from '../../../common/constants/constants';
import { IProxy } from '../../interfaces';
import { http } from '../../services';
import { OSWithM1 } from '../../utils/user-os';

export interface IDataToCreateProfile {
  profileName?: string;
  fileContent?: string;
  cookies?: string;
  proxy?: IProxy;
  facebookAccount?: IProfileFBAccount;
}

export interface IImportSourceData {
  sources: IDataToCreateProfile[];
  workspaceId: string;
  selectedFolder: string;
  userOs: OSWithM1;
  osSpec?: string;
  isCreateMultipleProfiles?: boolean;
}

export const createProfileByData = async (data: IImportSourceData): Promise<string[]> => {
  const { sources, workspaceId, userOs, selectedFolder, isCreateMultipleProfiles, osSpec } = data;

  const result = await http(`${API_BASE_URL}/browser/features/import/files`, {
    method: 'POST',
    body: JSON.stringify({ files: sources, workspaceId, os: userOs, osSpec, folderName: selectedFolder, isCreateMultipleProfiles }),
  });

  return result.body;
};
