import React, { ClipboardEvent, useContext, useEffect, useRef, useState } from 'react';

import { InputInactive } from './input-inactive';
import { SearchHistoryPopup } from './search-history-popup';
import SearchMenu from './search-menu';
import { history } from '../../../services';
import {
  userContext,
  workspaceContext,
  clearSearch,
  closeSearch,
  doSearch,
  openSearch,
  useSearchState,
  resetToDefaultSearchState,
} from '../../../state';
import { InputWithPopover } from '../../modern-popover/input-with-popover';
import { ISlotInputInactive } from '../../modern-popover/interfaces';
import { IconSearch } from '../icons';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';

type SearchProps = {
  isSearchShown: boolean;
  entityType: 'profiles' | 'proxies';
}

const Search: React.FC<SearchProps> = (props) => {
  const { isSearchShown, entityType } = props;

  const [isSearchHistoryOpen, setIsSearchHistoryOpen] = useState<boolean>(true);
  const [anchorElSearchMenu, setAnchorElSearchMenu] = React.useState<HTMLElement|null>(null);
  const [isSearchPinned, setIsSearchPinned] = React.useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const { id: workspaceId } = useContext(workspaceContext);
  const { _id: userId = '' } = useContext(userContext);
  const { searchInputText, isSearchOpen } = useSearchState();

  const onPressHotKey = (event: KeyboardEvent): void => {
    if (!(event.ctrlKey || event.metaKey) || event.code !== 'KeyF') {
      return;
    }

    event.preventDefault();
    onOpenSearchHistory();
    inputRef.current?.focus();
  };

  useEffect(() => {
    if (!isSearchShown) {
      resetToDefaultSearchState();

      return;
    }

    window.addEventListener('keydown', onPressHotKey);

    return () => {
      window.removeEventListener('keydown', onPressHotKey);
      resetToDefaultSearchState();
    };
  }, [history.location.pathname]);

  const onClickCross = (event: MouseEvent): void => {
    event.stopPropagation();
    clearSearch();
    if (!searchInputText) {
      closeSearch();
      onCloseSearchHistoryPopup();

      return;
    }

    onOpenSearchHistory();
    inputRef.current?.focus();
  };

  const onKeyPress = (event: React.KeyboardEvent): void => {
    if (event.key !== 'Enter') {
      return;
    }

    event.preventDefault();
    doSearch({ newSearch: searchInputText, userId, type: entityType, workspaceId });
    onCloseSearchHistoryPopup();
  };

  const onPaste = (event: ClipboardEvent<HTMLInputElement>): void => {
    if (NEW_FEATURES.objectPool) {
      return;
    }

    const clipboardText = event.clipboardData.getData('Text');
    if (!inputRef.current) {
      return;
    }

    const selectionStart = inputRef.current.selectionStart || 0;
    const selectionEnd = inputRef.current.selectionEnd || 0;
    const newValue = searchInputText.slice(0, selectionStart) + clipboardText + searchInputText.slice(selectionEnd);
    doSearch({ newSearch: newValue, userId, type: entityType, workspaceId });
    onCloseSearchHistoryPopup();
  };

  const onCancelPopup = (): void => {
    if (!NEW_FEATURES.objectPool) {
      doSearch({ newSearch: searchInputText, userId, type: entityType, workspaceId });
    }

    if (!searchInputText) {
      closeSearch();
    }
  };

  const onCloseSearchPopup = (): void => {
    setAnchorElSearchMenu(null);
  };

  const onPinSearch = (): void => {
    onCloseSearchPopup();
    setIsSearchPinned(!isSearchPinned);
    inputRef.current?.focus();
  };

  const onOpenSearchHistory = (): void => {
    openSearch();
    setIsSearchHistoryOpen(true);
  };

  const onCloseSearchHistoryPopup = (): void => {
    setIsSearchHistoryOpen(false);
  };

  const onOpenSearchMenu = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    if (!isSearchOpen) {
      return;
    }

    setAnchorElSearchMenu(event.currentTarget);
  };

  const renderInputInactive = ({ onClick }: ISlotInputInactive): JSX.Element => (
    <InputInactive
      onClick={onClick}
      isSearchPinned={isSearchPinned}
      onOpenSearchHistory={onOpenSearchHistory}
      onOpenSearchMenu={onOpenSearchMenu}
      onClickCross={onClickCross}
      inputRef={inputRef}
      onKeyPress={onKeyPress}
      onPaste={onPaste}
    />);

  if (!(isSearchOpen || isSearchPinned)) {
    return (
      <IconSearch
        styleType='activeGrayWithBackground'
        iconColor='var(--767676-header-new-search)'
        iconHoveredColor='var(--2B2B31-header-new-search)'
        hoveredHighlight={true}
        margin='0'
        onClick={onOpenSearchHistory}
      />
    );
  }

  return (
    <div>
      <InputWithPopover
        popupSlot={(): JSX.Element|null => (
          <SearchHistoryPopup
            onClosePopup={onCloseSearchHistoryPopup}
            entityType={entityType}
          />
        )}
        popupMargin='0 0 0 16px'
        slotInputInactive={renderInputInactive}
        anchorEl={inputRef.current}
        onCancel={onCancelPopup}
        needCancelMask={true}
        optionsVisible={isSearchHistoryOpen}
        setOptionsVisible={setIsSearchHistoryOpen}
      />
      <SearchMenu
        anchorEl={anchorElSearchMenu}
        onCloseSearchPopup={onCloseSearchPopup}
        onPinSearch={onPinSearch}
        isSearchPinned={isSearchPinned}
      />
    </div>
  );
};

export default Search;
