import moment from 'moment';
import { AUTOMATION_API_BASE_URL } from './constants';
import { AutomationWorkflow } from './interfaces/automation-workflow.interface';

// export const fetchPublicWorkflows = async (): Promise<AutomationWorkflow[]> => fetch(`${AUTOMATION_API_BASE_URL}/workflows/publics`)
//   .then((response) => response.json())
//   .catch(() => []);

export const fetchPublicWorkflows = async (): Promise<AutomationWorkflow[]> => [
  {
    "id": "649a876eb789b737a9e8c6e9",
    "user": "641b26ee8043263124b3fe17",
    "userName": "GoLogin",
    "status": "public",
    "extVersion": "1.2.9",
    "version": "0.1",
    "category": [
      "automation",
    ],
    "icon": "https://automations-content.s3.eu-central-1.amazonaws.com/images/649a876eb789b737a9e8c6e9/2366419.png",
    "fullDescription": "automationModal.cookiebot.description",
    "installCount": 173,
    "upvotesCount": 0,
    "name": "Cookie bot",
    "description": "Сollect cookies on different sites automatically by taking links from google sheet",
    "createdAt": moment("2023-06-27T06:53:34.641Z").utc().toDate(),
    "updatedAt": moment("2025-03-02T18:30:27.598Z").utc().toDate(),
    "slug": "cookie-bot-urls",
    "video": "https://youtu.be/eTIlFIh16bg",
    "images": [
      "https://automations-content.s3.eu-central-1.amazonaws.com/images/649a876eb789b737a9e8c6e9/SS.jpg"
    ],
    "drawflow": {
      "nodes": [],
    },
    "publishDate": moment("2023-06-27T06:53:34.641Z").utc().toDate(),
    "role": ''
  }
]
