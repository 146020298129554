import { IconDesignVersion } from '../../types';

interface IIconsStartData {
  pngs: string[];
  design: IconDesignVersion;
}

export const getIconsFromSessionStorage = (profileId: string): IIconsStartData => {
  const iconPngBig = sessionStorage.getItem(`browserIconBig:${profileId}`) || '';
  const iconPngSmall = sessionStorage.getItem(`browserIconSmall:${profileId}`) || '';
  // `browserIconsDesign:${profileId}` is a IconDesignVersion
  // localStorage returns type `string|null`
  // but we expect only `IconDesignVersion`
  // that's why --> `as IconDesignVersion`
  const design = sessionStorage.getItem(`browserIconsDesign:${profileId}`) as IconDesignVersion;
  const pngs = [iconPngBig, iconPngSmall].filter(Boolean);

  return { pngs, design };
};
