import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { HARDWARE_CONCURRENCY, HardwareConcurrencyType } from '../../../../interfaces';
import { ModernSelect } from '../../../../ui/modern-select';
import { IChangeSetting } from '../../interfaces';
import { ContainerParams } from '../../styles';

interface IHardwareConcurrency extends IChangeSetting {
  hardwareConcurrency: HardwareConcurrencyType;
}

export const HardwareConcurrency: FC<IHardwareConcurrency> = ({ changeSetting, hardwareConcurrency }) => {
  const { t: translation } = useTranslation();

  const selectItems = HARDWARE_CONCURRENCY.map(hardware => ({
    title: translation('quickSettings.hardware.threads', { count: hardware }),
    value: hardware.toString(),
  }));

  return (
    <ContainerParams>
      <ModernSelect
        currentValue={hardwareConcurrency.toString()}
        itemList={selectItems}
        onSelected={(value): void => changeSetting({ navigator: { hardwareConcurrency: (Number(value) as HardwareConcurrencyType) } })}
      />
    </ContainerParams>
  );
};
