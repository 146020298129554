import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconCheckFingerprint: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M1 9.03223L5 13.0322L15 3.03223' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  </IconWrapper>
);
