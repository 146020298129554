import styled from '@emotion/styled';

export const EmailCell = styled('div')`
  display: flex;
  align-items: center;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const EmailTooltip = styled('div')`
  font-family: 'Roboto';
  color: var(--222222-members-row);
  font-size: 13px;
  line-height: 20px;
  margin: 10px 8px;
`;

export const EmailColumn = styled('span')`
  cursor: pointer;
  margin-left: 4px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: var(--222222-members-row);
`;
