import React from 'react';

const IconLogoGoCircles: React.FC = () => (
  <svg
    width='57'
    height='29'
    viewBox='0 0 57 29'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 13.8961C0 6.22146 6.23296 0 13.9218 0C18.4536 0 22.8783 2.89434 25.2787 5.80317L35.7741 18.5529L35.7746 18.5535C37.4777 20.6209 40.4706 22.3585 42.9138 22.3585C47.5961 22.3585 51.3918 18.5698 51.3918 13.8961C51.3918 9.22247 47.5961 5.43382 42.9138 5.43382C40.4729 5.43382 37.4615 7.18819 35.7552 9.26233L35.7546 9.26301L33.9115 11.5019L30.426 7.17695L31.548 5.81397L31.5485 5.81329C33.9507 2.89364 38.3767 0 42.9138 0C50.6027 0 56.8356 6.22146 56.8356 13.8961C56.8356 21.5708 50.6027 27.7923 42.9138 27.7923C38.3831 27.7923 33.9743 24.9241 31.569 22.0037L31.5687 22.0035L31.5685 22.0032L28.1567 17.8585V28.1264H23.3933V23.9083C20.9014 26.0575 17.4495 27.7923 13.9218 27.7923C6.23295 27.7923 0 21.5708 0 13.8961ZM21.0767 9.25784L25.5812 14.7299H14.6287V19.4845H20.1859C18.4478 21.118 15.9909 22.3585 13.9218 22.3585C9.23952 22.3585 5.44386 18.5698 5.44386 13.8961C5.44386 9.22247 9.23952 5.43382 13.9218 5.43382C16.3558 5.43382 19.3669 7.18695 21.0752 9.25609L21.0767 9.25784Z'
    />
  </svg>
);

export default IconLogoGoCircles;
