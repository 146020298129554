import { format } from 'date-fns';
import moment from 'moment';
import React, { FC, useState, useEffect, useContext } from 'react';
import IntercomWidget from 'react-intercom';
import { useLocation } from 'react-router-dom';

import { getEmail, requestUserMetadata } from './api';
import patchIntercomLinks from './patch-intercom-links';
import { LOCAL_GOOGLE_CLIENT_ID_KEY_NAME } from '../../../common/constants/constants';
import { PRICING_PAGE } from '../../../common/constants/routes';
import { retrieveToken } from '../../services/http/storage';
import { USER_DEFAULT_VALUE, userContext } from '../../state';
import { setShouldOpenQuickPricingAtLaunch } from '../../state/quick-pricing.atom';
import { updateUserBasicInfo, useUserBasicInfo } from '../../state/user/user-basic-info.atom';

let ipcRenderer: Electron.IpcRenderer;
const isElectron = !!window.require;

if (isElectron) {
  ({ ipcRenderer } = window.require('electron'));
}

const APP_ID = 'izr1nygf';

const intercomHideOnPaths = [
  PRICING_PAGE,
];

const Intercom: FC = () => {
  const [isLoading, changeLoading] = useState<boolean>(true);
  const [userMetadata, setUserMetadata] = useState<any>({});
  const [orbitaVersion, setOrbitaVersion] = useState<string>('unspecified');

  const location = useLocation();

  const { updateUser } = useContext(userContext);

  const { email: userEmail } = useUserBasicInfo();

  useEffect(() => {
    ipcRenderer && ipcRenderer.invoke('get-orbita-browser-version')
      .then((resultOrbitaVersion: string) => setOrbitaVersion(resultOrbitaVersion));

    // todo: better solution when to patch links
    setInterval(patchIntercomLinks, 1000);
  }, []);

  // NOTE: Этот компонент запрашивает информацию о пользователе при каждом переходе на новую страницу
  // TODO: Fix
  useEffect(() => {
    // Do not request user
    const pathnamesIgnored = [
      '/login',
      '/createUser',
      '/sign_in',
      '/sign_up',
      '/sign_up_frame',
      '/two_factor',
      '/forgot_password',
      '/redirect-to-desktop',
      '/share',
    ];

    if (pathnamesIgnored.includes(location.pathname)) {
      updateUser(USER_DEFAULT_VALUE);

      return;
    }

    // to fix bug of race condition against private route with auto-login token
    const isPricingPage = location.pathname === PRICING_PAGE;
    if (userEmail || isPricingPage) {
      return;
    }

    retrieveToken().then((token) => {
      if (token) {
        updateMetadata().catch(() => null);
      }
    });

    async function getUserInfo(): Promise<void> {
      const {
        email,
        createdAt,
        _id,
        plan = {},
        card = {},
        subscription = {},
        payment = {},
        profiles,
        profilesCountWithShares = 0,
        isEmailConfirmed = false,
        paypalSubsCount = 0,
        googleClientId,
        paypalSubscriptionId = '',
        planExpireDate = '',
        targetPageShown = false,
        firstProfileStarted = false,
        isQuickSettingsEnabled = false,
        firstPlanSelected = false,
        newFeatures = {},
        migrationData = {},
        gologinMetadata = {},
      } = await getEmail().catch(() => ({}));

      if (!email) {
        return;
      }

      if (isQuickSettingsEnabled) {
        localStorage.setItem('@gologin:quickSettingsEnabled', 'true');
      } else {
        localStorage.removeItem('@gologin:quickSettingsEnabled');
      }

      if (googleClientId) {
        localStorage.setItem(LOCAL_GOOGLE_CLIENT_ID_KEY_NAME, googleClientId);
      }

      const paymentObj = subscription?.payment || payment;
      const { isTrial, expiresIn } = paymentObj;

      // TODO Временное решение, на этой неделе поправим
      // TODO Так как юзер запрашивается 2 раза и 2 раза обновляется контекст
      // TODO А это мешает отображению шапки, так как я смотрю наличие userId
      if (['/profileList'].includes(location.pathname)) {
        return;
      }

      updateUserBasicInfo({
        email,
        createdAt,
        isEmailConfirmed,
        isCorrectlyRegistered,
      });

      updateUser({
        _id,
        plan,
        profiles,
        profilesCountWithShares,
        hasTrial: !!isTrial,
        trialDays: isTrial && (new Date(expiresIn) > new Date())
          ? moment(expiresIn).diff(moment(), 'days') + 1
          : 0,
        card,
        firstPlanSelected,
        targetPageShown,
        paypalSubsCount,
        paypalSubscriptionId,
        planExpireDate,
        isQuickSettingsEnabled,
        newFeatures,
        migrationData,
        gologinMetadata,
      });

      setShouldOpenQuickPricingAtLaunch(!firstProfileStarted);
    }

    getUserInfo();
  }, [location]);

  const updateMetadata = async (): Promise<void> => {
    try {
      const metadata = await requestUserMetadata();
      setUserMetadata(metadata);
    } finally {
      changeLoading(false);
    }
  };

  const renderPlanName = (): string => {
    if (!userMetadata?.payment?.name) {
      return 'Unspecified';
    }

    if (userMetadata?.payment?.name?.includes('Trial')) {
      return 'Trial';
    }

    return userMetadata?.plan?.name;
  };

  const renderSubscription = (): string | null => {
    if (userMetadata?.payment?.createdAt) {
      return `${format(new Date(userMetadata.payment.createdAt), 'dd/MM/yyyy')} - ${format(new Date(userMetadata.payment.expiresIn), 'dd/MM/yyyy')}`;
    }

    return null;
  };

  const renderPaymentMethod = (): string => {
    if (!userMetadata?.payment?.name) {
      return 'Unspecified';
    }

    if (userMetadata?.payment?.name?.includes('Paypal')) {
      return 'Paypal';
    }

    if (userMetadata?.payment?.name?.includes('Crypto')) {
      return 'Crypto';
    }

    return 'Credit Card';
  };

  const {
    quiz = {},
    profiles = {},
    team = {},
    shares = {},
    payment = {},
    email = '',
    _id = '',
    createdAt = '',
    isCorrectlyRegistered,
    googleClientId,
    sharingTookMs = 111,
  } = userMetadata;

  const { survey: quizSurvey = '', profiles: quizProfiles = 0, api: quizApi = false, from: quizFrom = '', teamMembers: quizTeam = 0 } = quiz;
  const { createdAt: subscriptionStarted = '', expiresIn: subscriptionEnded = '' } = payment;
  const { count: profilesCount = 0 } = profiles;
  const { count: teamMembersCount = 0 } = team;
  const { count: sharesCount = 0 } = shares;
  let leadType: 'correct' | 'incorrect' | 'undefined' = isCorrectlyRegistered ? 'correct' : 'incorrect';
  if (typeof isCorrectlyRegistered === 'undefined') {
    leadType = 'undefined';
  }

  const parsedSharingTookMs = Number(`${sharingTookMs}`.slice(1, -1)) || 1;

  return !(isLoading || intercomHideOnPaths.includes(location.pathname)) ? (
    <>
      <IntercomWidget
        appID={APP_ID}
        custom_launcher_selector={'.open_intercom'}
        email={email}
        name={email}
        created_at={createdAt ? format(new Date(createdAt), 'dd/MM/yyyy') : null}
        user_id={_id.toString()}
        plan={renderPlanName()}
        subscription={renderSubscription()}
        payment_method={renderPaymentMethod()}
        quiz_survey={quizSurvey}
        quiz_profiles={quizProfiles}
        quiz_api={quizApi}
        quiz_from={quizFrom}
        quiz_team={quizTeam}
        profiles={profilesCount}
        team_members={teamMembersCount}
        shares={sharesCount}
        lead_type={leadType}
        subscription_started={moment(subscriptionStarted).unix()}
        subscription_ended={moment(subscriptionEnded).unix()}
        gologin_version={window.gologinAppVersion}
        orbita_version={orbitaVersion}
        clientId={googleClientId}
        userIds_per_clientId={parsedSharingTookMs}
      />
    </>
  ) : (
    <></>
  );
};

export default Intercom;
