import React from 'react';

const IconLogout = () => (
  <svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M4 6L2 8L4 10" stroke="var(--666666-icon-logout)" strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M5 2H13V14H5.47059H5" stroke="var(--666666-icon-logout)" strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M8 8H2" stroke="var(--666666-icon-logout)" strokeWidth="0.7" strokeLinecap="round"/>
</svg>
);

export default IconLogout;
