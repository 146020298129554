export const debounce = <T extends (...args: any[]) => void>(
  callback: T,
  time: number
): ((...args: Parameters<T>) => void) => {
  let lastCall: number | null = null;
  let timer: ReturnType<typeof setTimeout>;

  return function (...args: Parameters<T>) {
    const prevCall = lastCall;
    lastCall = Date.now();

    if (prevCall && lastCall - prevCall < time) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => callback(...args), time);
  };
};
