import React, { FC, memo } from 'react';
import { Trans } from 'react-i18next';

import { TABLE_CUSTOMIZATION_POPOVER_WIDTH } from '.';
import {
  closeTableCustomization,
  useTableCustomizationMenu,
} from './table-customization-menu-state.atom';
import { E_ANALYTICS_ACTIONS } from '../../../../common/constants/analytics';
import { GroupField } from '../../../interfaces/group-header.interface';
import {
  groupProfilesTable,
  resetProfilesList,
  resetProfilesTableGroups,
} from '../../../state/profiles-list.atom';
import { resetLocalStorageGroups } from '../../../state/profiles-table/groups-local-storage';
import { useProfilesTableGroupField } from '../../../state/profiles-table/profiles-table-group-field.atom';
import { IconCheck } from '../../../ui/gologin-header/icons';
import GologinPopover from '../../../ui/gologin-popover';
import { GologinPopoverItemRow } from '../../../ui/gologin-popover/gologin-popover-item-row';
import { sendActionAnalytics } from '../../common/api';

const GroupsTableCustomizationMenu: FC = () => {
  const { groupAnchorEl } = useTableCustomizationMenu();
  const currentGroupField = useProfilesTableGroupField();

  const onNoGroupsSelect = (): void => {
    sendActionAnalytics(E_ANALYTICS_ACTIONS.clickedDisableProfilesGroup);
    resetProfilesTableGroups();
    resetLocalStorageGroups();
  };

  const groupOptions: Array<{ groupField: GroupField; titleKey: string; onClick: () => void }> = [
    {
      groupField: null,
      titleKey: 'profiles.tableSettings.groupNone',
      onClick: onNoGroupsSelect,
    },
    {
      groupField: 'custom-status',
      titleKey: 'profiles.tableSettings.groupStatus',
      onClick: (): void => {
        sendActionAnalytics(E_ANALYTICS_ACTIONS.clickedEnableProfilesGroupByStatus);
        groupProfilesTable('custom-status');
      },
    },
    {
      groupField: 'folder',
      titleKey: 'profiles.tableSettings.groupFolder',
      onClick: (): void => {
        sendActionAnalytics(E_ANALYTICS_ACTIONS.clickedEnableProfilesGroupByFolder);
        groupProfilesTable('folder');
      },
    },
  ];

  const onGroupOptionClick = (groupField: GroupField, onClickHandler: () => void): void => {
    closeTableCustomization();
    if (groupField === currentGroupField) {
      return;
    }

    resetProfilesList();
    onClickHandler();
  };

  return (
    <GologinPopover
      anchorEl={groupAnchorEl}
      onClose={closeTableCustomization}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      width={TABLE_CUSTOMIZATION_POPOVER_WIDTH}
      extraTranslate={{
        left: 8,
      }}
    >
      {groupOptions.map(({ groupField, titleKey, onClick: onClickHandler }) => (
        <GologinPopoverItemRow
          onClick={(): void => onGroupOptionClick(groupField, onClickHandler)}
          key={groupField}
          style={{ gap: 8, padding: '6px 12px' }}
        >
          <div style={{ flex: '1' }}>
            <Trans i18nKey={titleKey} />
          </div>
          {groupField === currentGroupField ? (
            <IconCheck iconColor='var(--222222)' padding={0} />
          ) : null}
        </GologinPopoverItemRow>
      ))}
    </GologinPopover>
  );
};

export default memo(GroupsTableCustomizationMenu);
