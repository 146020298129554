import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import { Table } from 'antd';

import { IconMeatballsContainer } from './styles';
import { borderRadiusContainer, textFont } from '../../../ui/style-templates';
import { CheckProxyButtonWrapper } from '../../proxy/check-proxy-button/styles';
import { ProxySelectorContentWrapper } from '../../proxy/proxy-selector/styles';

export const TableProfile = styled(Table)<{ isMinWidth: boolean; newStyle: boolean }>`
  scrollbar-width: ${props => props.newStyle && 'none'}; // for mozilla firefox
  ${props => props.newStyle && borderRadiusContainer()};

  .ant-table-placeholder {
    border-bottom: 0;
    display: ${props => props.newStyle && 'none'};
  }

  .ant-table-empty .ant-table-body {
    &::-webkit-scrollbar {
      display: ${props => props.newStyle && 'none'};
    }
    scrollbar-width: ${props => props.newStyle && 'none'}; // for mozilla firefox
  }

  &::-webkit-scrollbar {
    display: ${props => props.newStyle && 'none'};
  }

  .ant-table-thead > tr, .ant-table-tbody > tr {
    background-color: var(--FFFFFF-profile-table);
  }

  .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
    background-color: ${props => props.newStyle ? 'inherit' : 'var(--F9F9F9-profile-table)'};
  }

  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background-color: var(--F9F9F9-profile-table);
  }

  .ant-table-tbody > tr > td.ant-table-column-sort {
    background-color: var(--FFFFFF-profile-table);
  }

  .ant-table-thead > tr > th.ant-table-column-sort {
    background-color: var(--FFFFFF-profile-table);
  }

  .ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters:hover::before {
    background-color: var(--F9F9F9-profile-table);
  }

  .ant-table-thead > tr > th {
    background: inherit;
  }

  .thead > tr > th {
    background-color: var(--FFFFFF-profile-table);
    padding: 16px;
  }

  .ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters {
    display: flex;
  };

  .ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters > .ant-table-column-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .ant-table-thead > tr > th:nth-child(2) .ant-table-header-column .ant-table-column-sorters > .ant-table-column-title {
    overflow: visible;
  }

  .ant-table-tbody > tr > td {
    vertical-align: top;
    transition: none;

    &.notes-cell {
      padding: 10px 8px;
    }
    &.new-notes-cell {
      padding: 0;
      overflow: visible;
      white-space: normal;
    }
    &.new-status-cell {
      overflow: visible;
      padding-left: 12px;

      :hover {
        z-index: 104;
      }
    }
    &.tags-cell {
      padding: 0;
      position: relative;
    }
    &.new-tags-cell {
      padding: 0;
      position: relative;
      overflow: visible;
    }
    &.new-proxy-cell {
      overflow: visible;
      padding: 0;
      position: relative;
    }
    &.proxy-cell,
    &.name-cell {
      padding: 0;
      position: relative;
    }
  }

  ${props => props.newStyle && css `
    .ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters {
      :hover {
        ::before {
          background-color: inherit;
        }
      }
    };

    .ant-table-tbody {
      tr {
        :hover {
          ${IconMeatballsContainer} {
            visibility: visible;
          }

          ${CheckProxyButtonWrapper} {
            display: flex;
          }

          ${ProxySelectorContentWrapper} {
            z-index: 102;
          }
        }

        td {
          border-bottom: 0.5px solid var(--E5E5E6-profile-table);
          align-items: center;
          vertical-align: top;
          background-color: inherit;
          position: relative;
          height: 100%;
          padding: 16px 8px;

          ${textFont()};
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          color: var(--2B2B31-profile-table);
        }

        td:first-child  {
          padding: 16px 6px 15px 12px;
        }

        td:nth-child(2) {
          padding: 0;
        }
      }
    }

    .ant-table-thead {
      tr:first-child {
        th:first-child {
          padding: 12px 6px 12px 12px;
        }

        th:nth-child(2) {
          padding: 12px 8px 12px 6px;
        }

        padding: 16px 8px;
      }
    }

    .ant-table-tbody > tr.ant-table-row-selected td {
      background-color: var(--F9F9F9-profile-table);
    }

    .ant-table-tbody > tr.ant-table-row {
      content-visibility: auto;
    }

    .ant-table-thead > tr > th {
      padding: 12px 8px;
      border-bottom: 0.5px solid var(--E5E5E6-profile-table);
      color: var(--98989F-profile-table);

      :hover {
        color: var(--2B2B31-profile-table);
      }

      .ant-table-column-sorter {
        display: none;
      }

      &:last-child {
        width: 100%;
      }
    }

    &.profiles-table .react-resizable-handle::after {
      background-color: var(--EAEAEB-profile-table);
    }

    &.profiles-table .react-resizable-handle:hover::after {
      background-color: var(--767676-profile-table);
    }
  `}
`;
