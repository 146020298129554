import * as Sentry from '@sentry/react';

import { PROFILE_SHARED_KEY_NAME } from '../../../../common/constants/constants';
import { PROFILE_LIST_PAGE } from '../../../../common/constants/routes';
import { IProfile } from '../../../interfaces';
import { history } from '../../../services';
import { IUserCtx, IWorkspaceCtx } from '../../../state';
import { ReactError } from '../../../utils/sentry-parameters/custom-errors';
import switchWorkspace from '../switch-workspace';

const SHARE_LINK_PROFILE_STRINGIFY_ERROR = 'share-link-profile-stringify-error';
const SELECTED_FOLDER_KEY = 'SelectedFolder';
const DEFAULT_FOLDER_VALUE = 'all';

interface IHandleShareViaLinkParams {
  profile: IProfile|null;
  defaultWorkspace: string;
  addProfileFirstToList: (profile: IProfile) => void;
  updateSelectedFolder: (folder: string) => void;
  userCtx: IUserCtx;
  workspaceCtx: IWorkspaceCtx;
  isUpdatePageRoute?: boolean;
}

export const handleShareViaLinkFromEvent = async ({
  profile,
  defaultWorkspace,
  addProfileFirstToList,
  updateSelectedFolder,
  userCtx,
  workspaceCtx,
  isUpdatePageRoute = false,
}: IHandleShareViaLinkParams): Promise<void> => {
  if (!profile) {
    return;
  }

  if (isUpdatePageRoute) {
    sessionStorage.setItem(PROFILE_SHARED_KEY_NAME, JSON.stringify(profile));

    return history.replace(PROFILE_LIST_PAGE);
  }

  let workspaceIdInStorage = '';
  try {
    const workspaceInfoJson = localStorage.getItem('workspace') || '';
    const workspaceInfo = JSON.parse(workspaceInfoJson);
    workspaceIdInStorage = workspaceInfo.id;
  } catch (error) {
    return;
  }

  const selectedFolder = localStorage.getItem(SELECTED_FOLDER_KEY) || DEFAULT_FOLDER_VALUE;
  if (defaultWorkspace === workspaceIdInStorage && selectedFolder === DEFAULT_FOLDER_VALUE) {
    addProfileFirstToList(profile);
    require('scroll-into-view')(document.querySelector('#main-header'));

    return;
  }

  if (!(defaultWorkspace && workspaceIdInStorage)) {
    return;
  }

  try {
    localStorage.setItem(SELECTED_FOLDER_KEY, DEFAULT_FOLDER_VALUE);
    sessionStorage.setItem(PROFILE_SHARED_KEY_NAME, JSON.stringify(profile));
  } catch (error) {
    Sentry.captureException(new ReactError(SHARE_LINK_PROFILE_STRINGIFY_ERROR.replaceAll('-', ' ')), (scope) => {
      scope.setLevel('error');
      scope.setTransactionName(SHARE_LINK_PROFILE_STRINGIFY_ERROR);
      scope.setFingerprint([SHARE_LINK_PROFILE_STRINGIFY_ERROR]);

      return scope;
    });
  }

  if (defaultWorkspace === workspaceIdInStorage && selectedFolder !== DEFAULT_FOLDER_VALUE) {
    return updateSelectedFolder(DEFAULT_FOLDER_VALUE);
  }

  await switchWorkspace(defaultWorkspace, { ...workspaceCtx, id: workspaceIdInStorage }, userCtx);
};
