import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  BookmarksTabTitle,
  BookmarksTextArea,
  BookmarksTabContainer,
  BookmarksTextEditLocked,
  BookmarksTextHowToSync,
  BookmarksTextInfoPart2,
} from './styles';
import { zeroProfileBookmarks } from '../../../../../electron/constants/zero-profile-bookmarks';
import { IProfile } from '../../../../interfaces';
import { parseBookmarksObjToTxt, parseBookmarksTxtToObj } from '../../../../utils/bookmarks-utils';

interface IBookmarksTab {
  profileInfo: IProfile;
  updateProfileInfo: (profileInfo: any) => any;
  setUpdatedField: (field: string) => any;
}
const BookmarksTab: FC<IBookmarksTab> = (props) => {
  const [profileBookmarks, setProfileBookmarks] = useState('');
  const [containerHeight, setContainerHeight] = useState(1000);

  const textareaRef = useRef(null);
  const { t: translation } = useTranslation();

  // minAppVersionForSyncBookmarks - временное решение. нужно будет удалить, когда будет известна версия релиза правки синка закладок
  const minAppVersionForSyncBookmarks = translation('app.temp.minMASTERAppVersionForSyncBookmarks');
  const textHowToSync = translation('profileSettings.bookmarks.lockDescriptionHowToSync');
  const textInfoSyncBookmarksPart1 =
    translation('profileSettings.bookmarks.lockDescriptionPart1', { version: minAppVersionForSyncBookmarks });

  const textInfoSyncBookmarksPart2 =
    translation('profileSettings.bookmarks.lockDescriptionPart2');

  useEffect(() => {
    const bookmarksData = parseBookmarksObjToTxt(props.profileInfo.bookmarks || zeroProfileBookmarks.roots);
    setProfileBookmarks(bookmarksData);
  }, [props.profileInfo.bookmarks]);

  useEffect(() => {
    const handleResize = (entries: any): void => {
      const [entry] = entries || [];
      if (!entry) {
        return;
      }

      const { height } = entry.contentRect;
      setContainerHeight(height + 200);
    };

    if (textareaRef.current) {
      const resizeObserver = new ResizeObserver(handleResize);
      resizeObserver.observe(textareaRef.current);
    }

    return () => {
      if (textareaRef.current) {
        const resizeObserver = new ResizeObserver(handleResize);
        resizeObserver.unobserve(textareaRef.current);
      }
    };
  }, []);

  const handleOnBookmarksTextChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    setProfileBookmarks(event.target.value);
  };

  const saveBookmarks = (): void => {
    if (!props.profileInfo.isBookmarksSynced) {
      return;
    }

    props.updateProfileInfo({
      bookmarks: parseBookmarksTxtToObj(props.profileInfo.bookmarks, profileBookmarks),
    });

    props.setUpdatedField('bookmarks');
  };

  return (
    <BookmarksTabContainer
      style={{ height: props.profileInfo.isBookmarksSynced ? containerHeight : 500 }}
    >
      <BookmarksTabTitle
        disabled={!props.profileInfo.isBookmarksSynced}
      >
        {translation('profileSettings.bookmarks.title')}
      </BookmarksTabTitle>
      <BookmarksTextArea
        ref={textareaRef}
        disabled={!props.profileInfo.isBookmarksSynced}
        canResize={true}
        value={props.profileInfo.isBookmarksSynced ? profileBookmarks : ''}
        onChange={handleOnBookmarksTextChange}
        placeholder={translation('profileSettings.bookmarks.placeholder') || ''}
        style={{ marginTop: 10 }}
        onBlur={(): void => saveBookmarks()}
      />
      {!props.profileInfo.isBookmarksSynced ? (
        <BookmarksTextEditLocked>
          {textInfoSyncBookmarksPart1}
          &nbsp;
          <BookmarksTextHowToSync>
            {textHowToSync}
          </BookmarksTextHowToSync>
          .
          <br />
          <BookmarksTextInfoPart2>
            {textInfoSyncBookmarksPart2}
          </BookmarksTextInfoPart2>
        </BookmarksTextEditLocked>
      ) : null}

    </BookmarksTabContainer>
  );
};

export default BookmarksTab;
