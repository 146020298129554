import React from 'react';

const IconRemove = () => (
  <svg style={{ marginRight: 8, marginTop: -1 }} width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.5 6.5V13.5C1.5 14.605 2.395 15.5 3.5 15.5H11.5C12.605 15.5 13.5 14.605 13.5 13.5V6.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M0.5 3.5H14.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5.5 3.5V0.5H9.5V3.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7.5 7.5V12.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.5 7.5V12.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.5 7.5V12.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default IconRemove;
