import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ContainerIcon,
  ContainerParams,
  CookieCountContainer,
  CookieItemContainer,
  CookiesWrapper,
  NoCookiesSpan,
} from './styles';
import { ICookie } from '../../../../../interfaces';
import { IconPlusFill } from '../../../../../ui/gologin-header/icons';
import { blackMain, grey, greyInformers } from '../../../../../ui/style-templates/colors';

interface ICookies {
  cookiesLoading: boolean;
  cookies: ICookie[];
  showModalCookies: () => void;
}

export const Cookies: FC<ICookies> = (props) => {
  const { cookies, cookiesLoading, showModalCookies } = props;

  const { t: translation } = useTranslation();

  const renderCookies = (): JSX.Element => {
    if (cookiesLoading) {
      return (
        <NoCookiesSpan>
          loading...
        </NoCookiesSpan>
      );
    }

    if (!cookies.length) {
      return (
        <ContainerIcon
          iconColor='var(--B5B5BA-profile-new-settings)'
          iconHoveredColor='var(--2B2B31-profile-new-settings)'
          textColor='var(--81818A-profile-new-settings)'
          textHoveredColor='var(--2B2B31-profile-new-settings)'
          iconType='stroke'
        >
          <IconPlusFill padding={0} margin='0 8px 0 0' />
          {translation('quickSettings.favorites.addCookies')}
        </ContainerIcon>
      );
    }

    return (
      <CookiesWrapper>
        {cookies.map(({ cookie, count }) => (
          <CookieItemContainer key={cookie}>
            {cookie}
            <CookieCountContainer>
              {count}
            </CookieCountContainer>
          </CookieItemContainer>
        ))}
      </CookiesWrapper>
    );
  };

  return (
    <ContainerParams onClick={showModalCookies}>
      {renderCookies()}
    </ContainerParams>
  );
};
