import styled from '@emotion/styled';

import { IconWrapperWithDescription } from './icons/wrapper';
import { textFont } from '../style-templates';
import { blackMain, greyDark } from '../style-templates/colors';

export const HeaderEndContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: end;
  align-items: center;
`;

export const SearchWrapper = styled.div<{ isProxyPage: boolean }>`
  /* to get rid off the inconsistent search position between proxy & profiles pages */
  margin-left: ${(props): string => props.isProxyPage ? '0' : '32px'};
`;

export const SearchAndFoldersWrapper = styled.div<{ isBackButtonHidden: boolean; shouldMatchProfilesHeaderPosition: boolean }>`
  display: ${(props): string => props.isBackButtonHidden ? 'none' : 'flex'};
  overflow: hidden;
  position: ${(props): string => props.shouldMatchProfilesHeaderPosition ? 'relative' : 'static'};
  right: ${(props): string => props.shouldMatchProfilesHeaderPosition ? '8px' : '0'};
`;

export const ContainerFolders = styled('div')`
  overflow-x: auto;
  height: 100%;
  display: flex;
  white-space: nowrap;
  margin-left: 16px;
  scrollbar-width: none; // for mozilla firefox

  &::-webkit-scrollbar {
    display: none;
  }

  * + * {
    margin-left: 8px;
  }
`;

export const ContainerBack = styled('div')`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const BackText = styled('span')`
  ${textFont};
  color: var(--767676-header);
  
  :hover {
    color: var(--2B2B31-header);
  }
`;

export const ItemRow = styled.div`
  ${textFont};

  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;
`;

export const AddFolderInput = styled.input`
  flex: 1;
  min-width: 0;

  width: 200px;
  border: none;
  outline: none;
  padding: 0;

  color: var(--2B2B31-header);

  background: transparent;
  
  ${textFont};

  &::placeholder {
    color: var(--767676-header);
  }
`;

export const Text = styled.span`
  ${textFont};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--767676-header);
`;

export const AddFolderContainer = styled(IconWrapperWithDescription)`
  overflow: hidden;
  text-overflow: ellipsis;

  :hover {
    span {
      transition: background-color 0.05s ease-in-out, color 0.05s ease-in-out, stroke 0.05s ease-in-out;
      color: var(--222222-header);
    }
  }
`;
