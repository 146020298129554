import { Checkbox } from '@material-ui/core';
import React from 'react';

import { SimpleCheckboxContainer } from './styles';
import { IGologinCheckbox } from '../gologin-checkbox/interfaces';
import {
  IconCheckbox,
  IconCheckboxActive,
  IconCheckboxIndeterminate,
  IconCheckboxSmall,
  IconCheckboxSmallActive,
  IconCheckboxSmallIndeterminate,
} from '../gologin-header/icons';

export type GologinNewCheckboxProps = Omit<IGologinCheckbox, 'hasPadding' | 'isFilledOnSelection'> & {
   innerPadding?: string;
   isSmall?: boolean;
};

export const GologinNewCheckbox: React.FC<GologinNewCheckboxProps> = (props) => {
  const {
    isDisabled,
    innerPadding,
    onChange,
    checked,
    isIndeterminate,
    isShownOnlyOnHover,
    isFillingSpace,
    isSmall,
  } = props;

  const isHidden = !(checked || isIndeterminate) && !!isShownOnlyOnHover;
  const color = checked ? 'var(--00A987-profile-table)' : 'var(--CDCDCD-profile-table)';
  const iconProps = { iconColor: color, padding: 0 } as const;

  return (
    <SimpleCheckboxContainer
      isHidden={isHidden}
      isFillingSpace={isFillingSpace}
      innerPadding={innerPadding}
      isDisabled={isDisabled}
    >
      <Checkbox
        icon={isSmall ? <IconCheckboxSmall {...iconProps} /> : <IconCheckbox {...iconProps} />}
        checkedIcon={isSmall ? <IconCheckboxSmallActive {...iconProps} /> : <IconCheckboxActive {...iconProps} />}
        indeterminateIcon={isSmall ? <IconCheckboxSmallIndeterminate {...iconProps} /> : <IconCheckboxIndeterminate {...iconProps} />}
        checked={checked}
        indeterminate={!checked && !!isIndeterminate}
        onChange={onChange}
        disableRipple={true}
        disabled={isDisabled}
      />
    </SimpleCheckboxContainer>
  );
};
