export interface ILocalUserDevice {
  timezone?: string;
  country?: string;
  languages?: string;
}

export const DEFAULT_LOCAL_USER_DEVICE: ILocalUserDevice = {
  timezone: '',
  country: '',
  languages: '',
};
