import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IFullProfile, IMediaDevices } from '../../../../interfaces';
import { InputTitle, SettingsInput } from '../../../../ui/closeable-input/styles';
import { ModernSelect } from '../../../../ui/modern-select';
import {
  ContainerParams,
  ContainerSubRow,
  ContainerSpaceBetween,
  GreyDarkTextContainer,
} from '../../styles';

const defaultDevice = {
  videoInputs: 0,
  audioInputs: 0,
  audioOutputs: 0,
};

let userDeviceInfo = { ...defaultDevice };

interface IMediaDevicesComponent {
  profileMediaDevices: IMediaDevices;
  changeSetting: (profileData: Partial<IFullProfile>) => void;
}

export const MediaDevices: FC<IMediaDevicesComponent> = ({ changeSetting, profileMediaDevices }) => {
  const [isEditModeOpened, setIsEditModeOpened] = useState<boolean>(false);
  const [mediaDevices, setMediaDevices] = useState<IMediaDevices>(profileMediaDevices);

  const { t: translation } = useTranslation();

  const getUserMedia = async (): Promise<void> => {
    const userMediaDevices = await navigator.mediaDevices.enumerateDevices();
    userDeviceInfo = userMediaDevices.reduce((allDevice, device) => {
      if (device.kind === 'audioinput') {
        allDevice.audioInputs++;

        return allDevice;
      }

      if (device.kind === 'audiooutput') {
        allDevice.audioOutputs++;

        return allDevice;
      }

      allDevice.videoInputs++;

      return allDevice;
    }, { ...defaultDevice });
  };

  useEffect(() => {
    getUserMedia();
  }, []);

  useEffect(() => {
    setMediaDevices(profileMediaDevices.enableMasking ? profileMediaDevices : userDeviceInfo);
  }, [profileMediaDevices]);

  const onMaskingChanged = (enableMasking: boolean): void => {
    changeSetting({
      mediaDevices: {
        ...profileMediaDevices,
        enableMasking,
      },
    });
  };

  const renderMediaInfo = (): string => {
    if (profileMediaDevices?.enableMasking) {
      const { videoInputs, audioInputs, audioOutputs } = profileMediaDevices;

      return `Cam. ${videoInputs} / Mic. ${audioInputs} / Sp. ${audioOutputs}`;
    }

    const { videoInputs, audioInputs, audioOutputs } = userDeviceInfo;

    return `Cam. ${videoInputs} / Mic. ${audioInputs} / Sp. ${audioOutputs}`;
  };

  const onMediaDeviceChanged = (type: 'videoInputs'|'audioInputs'|'audioOutputs', event: ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value.replace(/[^0-9]/g, '');
    setMediaDevices({ ...mediaDevices, [type]: Number(value) });
  };

  const onKeyPressInput = (event: React.KeyboardEvent): void => {
    if (event.key !== 'Enter') {
      return;
    }

    saveMediaInfo();
  };

  const saveMediaInfo = (): void => {
    const maxValue = 9;
    const videoInputs = +mediaDevices.videoInputs > maxValue ? maxValue : +mediaDevices.videoInputs;
    const audioInputs = +mediaDevices.audioInputs > maxValue ? maxValue : +mediaDevices.audioInputs;
    const audioOutputs = +mediaDevices.audioOutputs > maxValue ? maxValue : +mediaDevices.audioOutputs;

    changeSetting({
      mediaDevices: {
        ...profileMediaDevices,
        videoInputs,
        audioInputs,
        audioOutputs,
      },
    });

    setMediaDevices({ ...mediaDevices, videoInputs, audioInputs, audioOutputs });
  };

  const renderInputMediaDevices = (): JSX.Element|null => {
    if (!isEditModeOpened) {
      return null;
    }

    const inputsData: { title: string; type: 'videoInputs'|'audioInputs'|'audioOutputs' }[] = [
      { title: translation('quickSettings.hardware.videoinput'), type: 'videoInputs' },
      { title: translation('quickSettings.hardware.audioinput'), type: 'audioInputs' },
      { title: translation('quickSettings.hardware.audiooutput'), type: 'audioOutputs' },
    ];

    return (
      <ContainerSubRow hasMarginTop={true} hasGap={true} onClick={(event): void => event.stopPropagation()}>
        {inputsData.map(({ title, type }) => (
          <>
            <InputTitle hasMarginTop={true}>
              {title}
            </InputTitle>
            <SettingsInput
              onChange={(event): void => onMediaDeviceChanged(type, event)}
              onBlur={saveMediaInfo}
              onKeyPress={onKeyPressInput}
              value={mediaDevices[type].toString()}
              title={title}
              key={type}
              disabled={!profileMediaDevices.enableMasking}
              style={{ padding: '6px 0', marginTop: 8 }}
              onFocus={(event): void => event.currentTarget.select()}
            />
          </>
        ))}
      </ContainerSubRow>
    );
  };

  const toggleEditMode = (): void => {
    setIsEditModeOpened(!isEditModeOpened);
  };

  const selectItems = [{
    title: translation('quickSettings.parameters.real'),
    value: 'real',
  }, {
    title: translation('quickSettings.parameters.masked'),
    value: 'masked',
  }];

  return (
    <ContainerParams
      style={{ flexWrap: 'wrap' }}
      onClick={toggleEditMode}
      hasCursorPointer={true}
    >
      <ContainerSpaceBetween>
        <div onClick={(event): void => event.stopPropagation()}>
          <ModernSelect
            currentValue={profileMediaDevices.enableMasking ? 'masked' : 'real'}
            itemList={selectItems}
            onSelected={(value): void => onMaskingChanged(value === 'masked')}
          />
        </div>
        <GreyDarkTextContainer hasCursorPointer={true}>
          {renderMediaInfo()}
        </GreyDarkTextContainer>
      </ContainerSpaceBetween>
      {renderInputMediaDevices()}
    </ContainerParams>
  );
};
