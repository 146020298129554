import { API_BASE_URL } from '../../../../../common/constants/constants';
import { http } from '../../../../services';

interface IImportResultData {
  _id: string;
  name: string;
  proxy: string;
  os: string;
}

export interface IImportResult {
  createdProfiles: IImportResultData[];
  updatedProfiles: IImportResultData[];
  profilesWithErrors: IImportResultData[];
}

export const profilesImport = async (browserTxt: string) => {
  try {
    const data = await http(`${API_BASE_URL}/browser/browser-import`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/csv'
      },
      body: browserTxt,
    })

    return data.body;
  } catch (e) {
    throw e;
  }
}

export const profilesExport = async (profileIds: []) => {
  try {
    const data = await http(`${API_BASE_URL}/browser/browsers.csv`, {
      method: 'POST',
      body: JSON.stringify({
        browsersIds: profileIds
      })
    });

    return data.body;
  } catch (e) {
    throw e;
  }
}

interface IImportWorkspaceProfiles {
  browserTxt: string;
  workspaceId: string;
  folderName?: string;
}

export const importWorkspaceProfiles = ({ browserTxt, folderName, workspaceId }: IImportWorkspaceProfiles): Promise<IImportResult> =>
  http(`${API_BASE_URL}/browser/browser-import?currentWorkspace=${workspaceId}&folderName=${folderName}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'text/csv',
    },
    body: browserTxt,
  }).then((res: any) => res.body);

export const exportWorkspaceProfiles = (workspaceId: string, profileIds: string[]) =>
  http(`${API_BASE_URL}/browser/browsers.csv?currentWorkspace=${workspaceId}`, {
    method: 'POST',
    body: JSON.stringify({
      browsersIds: profileIds,
    }),
  }).then((res: any) => res.body);
