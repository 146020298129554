import React from 'react';

const IconArrowSelect = (): JSX.Element => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.5 6.5L8 11L12.5 6.5" stroke="var(--98989F)" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);

export default IconArrowSelect;
