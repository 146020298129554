import styled from '@emotion/styled';

import { textFont } from '../../../style-templates';
import { BuyProxyPopoverCustom, VolumeProxyContainer } from '../styles';

export const PriceListPopoverCustom = styled(BuyProxyPopoverCustom)`
  .MuiPopover-paper {
    margin-left: 0;
    padding: 4px;
    width: 167px;
    line-height: 20px;

    * + * {
      margin-top: 1px;
    }
  }
`;

export const ItemRow = styled.div`
  ${textFont};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 12px; 

  background: var(--FFFFFF-header-buy-proxy);
  border-radius: 4px;

  cursor: default;
`;

export const PriceText = styled(VolumeProxyContainer)`
  ${textFont};
  color: var(--767676-header-buy-proxy);
`;

export const ItemRowActive = styled(ItemRow)`
  :hover {
    cursor: pointer;
    background-color: var(--F2F2F2-header-buy-proxy);
    color: var(--36363D-header-buy-proxy-data-quantity);

    & > div {
      color: var(--36363D-header-buy-proxy-data-quantity);
    }
  }
`;
