import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled/macro';

import { QuickSettingsButtonWrapper } from '../../common/custom-table/components/quick-settings-button/styles';

export const ContainerShareIcon = styled('div')`
  visibility: hidden;
`

export const Row = styled('div')`
  display: flex;
  align-items: center;
  font-size: 12px;
  position: relative; // to properly (absolutely) position <ProfileSettingsBtn />
`;

export const ContainerTable = styled('div')<{ newStyle: boolean; isEditMode?: boolean; isProxyPage?: boolean }>`
  height: 100%;
  padding: 16px;

  ${(props): SerializedStyles|null => (props.newStyle || props.isEditMode) ? css`
    padding: 14px 22px 14px 6px;
  ` : null}

  ${(props): SerializedStyles|null => props.isProxyPage ? css`
    padding: 16px 22px 16px 6px;
  ` : null}

  :hover {
    ${QuickSettingsButtonWrapper} {
      visibility: visible;
    }
  }
`;

export const ContainerName = styled('div')`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    ${QuickSettingsButtonWrapper} {
      visibility: visible;
    }
  }
`;

export const ContainerButtons = styled('div')`
  justify-content: flex-end;
  display: flex;
  padding-left: 10px;
  align-items: center;
`;
