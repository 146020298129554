import styled from '@emotion/styled';

export const TooltipContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 236px;

  font: 400 12px/16px Roboto;
  letter-spacing: 0.2px;
  color: var(--36363D);
  max-width: 204px;
`;

export const StatusFlowInfoContainer = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
