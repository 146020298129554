import React from 'react';

const IconAppleM1 = () => (
  <svg width='25' height='20' viewBox='0 0 598 238' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_513:94)'>
      <path d='M195.8 185C192.334 193.086 188.045 200.793 183 208C176.3 217.6 170.8 224.2 166.6 227.9C160 233.9 153 237 145.5 237.2C140.1 237.2 133.6 235.7 126 232.5C119.364 229.622 112.232 228.06 105 227.9C97.5716 228.034 90.2384 229.596 83.4 232.5C75.7 235.6 69.5 237.2 64.7 237.4C57.5 237.7 50.3 234.5 43.1 227.8C38.5 223.8 32.8 216.9 25.9 207.2C18.162 196.085 12.0347 183.931 7.7 171.1C2.6 156.3 0 142 0 128.1C0 112.2 3.4 98.5 10.3 87C15.5206 77.982 22.9549 70.4445 31.9 65.1C40.7194 59.7798 50.8008 56.9142 61.1 56.8C66.8 56.8 74.4 58.6 83.7 62.1C93 65.6 99 67.4 101.6 67.4C103.6 67.4 110.2 65.3 121.5 61.2C132.167 57.4 141.167 55.8 148.5 56.4C168.5 58 183.5 65.9 193.5 80.1C175.6 90.9 166.8 106.1 167 125.5C167.2 140.7 172.7 153.3 183.5 163.3C188.3 167.823 193.849 171.477 199.9 174.1C198.7 177.9 197.3 181.5 195.8 185ZM150 4.8C150 16.7 145.7 27.8 137 38C126.6 50.2 113.9 57.2 100.3 56.1C100.094 54.609 99.9936 53.1052 100 51.6C100 40.2 105 28 113.8 18C118.476 12.6916 124.195 8.40254 130.6 5.4C136.572 2.32884 143.102 0.492305 149.8 0C149.9 1.6 150 3.2 150 4.8Z' fill='black'/>
      <path d='M448 233.7V83.7003H447L385.92 233.7H360.14L299.06 83.7003H298.06V233.7H263.9V22.3203H307.7L372.45 183.6H373.62L438.37 22.3203H482V233.7H448Z' fill='black'/>
      <path d='M560.6 58.3603H559.72L505.08 95.8603V61.1403L560.31 22.3203H597.66V233.7H560.6V58.3603Z' fill='black'/>
    </g>
    <defs>
      <clipPath id='clip0_513:94'>
        <rect width='597.66' height='237.42' fill='var(--FFFFFF)'/>
      </clipPath>
    </defs>
  </svg>
);

export default IconAppleM1;
