import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { ISharedProxy } from '../../features/proxy/components/interfaces/shared-proxy.interfaces';

const sharedProxiesAtom = atom<ISharedProxy[]>([]);

export const getSharedProxies = (): ISharedProxy[] => getDefaultStore().get(sharedProxiesAtom);
export const setSharedProxies = (data: ISharedProxy[]): void => getDefaultStore().set(sharedProxiesAtom, data);

export const useSharedProxies = (): ISharedProxy[] => useAtomValue(sharedProxiesAtom);
