import css from '@emotion/css';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { TableCell, TableRow } from '@material-ui/core';

export const FolderNameContainer = styled('div')`
  display: flex;
  align-items: center;

  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: var(--222222-members-permissions-select);

  svg {
    margin-right: 7.5px;
  }
`;

export const FolderNameText = styled('span')`
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const AddFolderButton = styled('button')`
  border: none;
  padding: 0;
  background: none;
  outline: none;

  margin-bottom: 1px; // to compensate padding of input
  border-bottom: 1px dashed var(--767676-members-permissions-select);

  cursor: pointer;

  color: var(--767676-members-permissions-select);

  line-height: 14px;

  &:hover {
    color: var(--222222-members-permissions-select);
  }
`;

export const CreateFolderInput = styled('input')`
  border: none;
  border-bottom: 1px solid var(--22222233-members-permissions-select);
  outline: none;
  background: none;

  padding: 0;
  margin: 0;

  width: 160px;
  font: 400 14px Roboto;
  color: var(--222222-members-permissions-select);
`;

export const CustomTableRow = styled(
  TableRow, { shouldForwardProp: isPropValid })<{
  removeBorder?: boolean, rowPadding?: number
}>`
  &.MuiTableRow-root > .MuiTableCell-root {
    height: 32px;
    border-bottom: 1px solid var(--E0E0E0-members-permissions-select);
    padding-right: 0;
    padding-left: 0;
    ${props => props.removeBorder && css`border-bottom: 0;`}
    ${props => props.rowPadding && css`
      padding-top: ${props.rowPadding - 1}px;
      padding-bottom: ${props.rowPadding - 2}px;
      background-color: var(--FFFFFF-members-permissions-select);
    `}
  }

  &.MuiTableRow-root > .MuiTableCell-root:nth-child(1){
    padding-left: 7px;
    width: auto;
  }

  &.MuiTableRow-root:last-child > .MuiTableCell-root{
    ${props => props.rowPadding && css`
      padding-bottom: ${props.rowPadding - 1}px;
    `}
  }
`;

export const CustomHeaderTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    padding: 0 !important;
    background-color: var(--FFFFFF-members-permissions-select);
  }

  & > * {
    margin: 0 2px;
  }

  width: 76px;
  font-size: 12px;
  line-height: 14px;
`;

export const AddFolderContainer = styled(FolderNameContainer)`
  color: var(--00000066-members-permissions-select);
`;

export const GreyText = styled('span')`
  color: var(--767676-members-permissions-select);
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  white-space: nowrap
`;
