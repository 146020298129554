import React from 'react';
import { Trans } from 'react-i18next';

import { CreateFirstButtonContainer, CreateFirstButtonText, EmptyTableTitle, EmptyTableWrapper } from './styles';
import { IconPlus } from '../../../../../ui/gologin-header/icons';
import { IconCopy } from '../../../../../ui/gologin-header/icons/icon-copy';

export interface IEmptyTablePlaceholder {
  entityType: 'profile'|'proxy';
  handleCreateFirstEntity: React.MouseEventHandler<HTMLDivElement>;
  isButtonHidden?: boolean;
}

// TODO: might also be reused in <FoldersEmpty />
const EmptyTablePlaceholder: React.FC<IEmptyTablePlaceholder> = (props) => {
  const { entityType, handleCreateFirstEntity, isButtonHidden = false } = props;

  const titleKey = entityType === 'profile' ? 'folders.createFirstFolder.noProfile' : 'proxyPage.noProxiesPlaceholder.title';
  const buttonTextKey = entityType === 'profile' ? 'folders.createFirstFolder.addProfile' : 'proxyPage.noProxiesPlaceholder.button';

  return (
    <EmptyTableWrapper>
      <IconCopy
        styleType='lightGray'
        padding={0}
        margin='0 0 20px'
        iconColor='var(--B5B5BA-profile-table)'
      />
      <EmptyTableTitle>
        <Trans i18nKey={titleKey} />
      </EmptyTableTitle>
      {isButtonHidden ? null : (
        <CreateFirstButtonContainer
          iconType='stroke'
          iconColor='var(--B5B5BA-profile-table)'
          textColor='var(--767676-profile-table)'
          iconHoveredColor='var(--222222)'
          textHoveredColor='var(--222222)'
          onClick={handleCreateFirstEntity}
        >
          <IconPlus padding={0} />
          <CreateFirstButtonText>
            <Trans i18nKey={buttonTextKey} />
          </CreateFirstButtonText>
        </CreateFirstButtonContainer>
      )}
    </EmptyTableWrapper>
  );
};

export default EmptyTablePlaceholder;
