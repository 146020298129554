import { atom, getDefaultStore, useAtomValue } from 'jotai';
import { forceReloadProfilesQuery } from './profiles-table/profiles-query';

const DEFAULT_SEARCH_QUERY = '';

// search: null, blocked search
const searchQueryAtom = atom<string|null>(DEFAULT_SEARCH_QUERY);

export const getSearchQuery = (): string|null => getDefaultStore().get(searchQueryAtom);
export const useSearchQuery = (): string|null => useAtomValue(searchQueryAtom);

export const setSearchQuery = (newSearchQuery: string|null): void => {
  getDefaultStore().set(searchQueryAtom, newSearchQuery);
  forceReloadProfilesQuery();
};

export const blockSearch = (): void => setSearchQuery(null);

export const resetToDefaultSearchQuery = (): void => setSearchQuery(DEFAULT_SEARCH_QUERY);
