import styled from '@emotion/styled';

import { ModernModal } from '../../../ui/modern-modal';

export const ProxyPageModalWrapper = styled(ModernModal)`
  .ant-modal-header {
    height: 68px;
    padding: 24px;
    background-color: var(--F9F9F9-proxy-settings-modal-background);
  }

  .ant-modal-body {
    padding: 0 24px;
  }

  .ant-modal-content {
    border-radius: 8px;
    background-color: var(--F9F9F9-proxy-settings-modal-background);

    .ant-modal-close-x {
      width: fit-content;
      height: fit-content;
      margin: 20px 12px 0 0;
    }
  }

  .ant-modal-footer {
    height: calc(24px * 2 + 36px);
    padding: 24px;
    border: none;
  }
`;
