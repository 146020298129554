import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';

const fade = (opacityFinal: number): string => keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: ${opacityFinal};
  }
`;

export const Wrapper = styled('div')`
  width: 100%;
  margin-top: 32px;

  display: flex;
  flex-flow: column nowrap;
`;

export const Button = styled('button')`
  width: 100%;
  height: 50px;
  padding: 0;
  border: none;
  border-radius: 60px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--FFFFFF-auth-pages-o-auth-buttons);
  box-shadow: 0px 0px 3px var(--00000015-auth-pages-o-auth-buttons), 0px 2px 3px var(--0000002B-auth-pages-o-auth-buttons);

  cursor: pointer;

  svg[name='google-logo'] {
    animation: ${fade(1)} 400ms ease-in-out alternate;
  }

  & + * {
    margin-top: 16px;
  }

  span {
    margin-left: 15px;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: var(--000000-auth-pages-o-auth-buttons);
    opacity: 0.54;

    transition: opacity 0.2s ease-in-out;
  }

  &:hover span {
    opacity: 0.8;
  }
`;

export const SeparationLine = styled('div')`
  width: 100%;
  margin-top: 20px;

  span {
    height: 16px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    &::before,
    &::after {
      content: ' ';
      width: 168px;
      height: 1px;
      background-color: var(--D9D9D9-auth-pages-o-auth-buttons);
    }

    &::before {
      margin-right: 8px;
    }

    &::after {
      margin-left: 8px;
    }
  }
`;

export const SpinnerWrapper = styled('div')`
  width: 24px;
  height: 24px;
  color: var(--000000-auth-pages-o-auth-buttons);

  animation: ${fade(0.54)} 400ms ease-in-out forwards;

  svg {
    width: 24px;
    height: 24px;
  }
`;
