import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled';

import { IconWrapperWithDescription } from '../../../ui/gologin-header/icons/wrapper';

export const MassActionsButtonLine = styled(IconWrapperWithDescription)<{ isDisabled?: boolean }>`
  ${({ isDisabled }): SerializedStyles|null => isDisabled ? css`
    cursor: not-allowed;
    background-color: var(--F5F5F5);
  ` : css`
    cursor: pointer;

    :hover {
      background-color: var(--F2F2F2);
    }
  `}

  height: 28px;
  font-size: 12px;
  border: 0;
  background-color: var(--F9F9F9);
  border-radius: 14px;
  padding: 6px 16px 6px 16px;
`;

export const MassActionsMarginBlock = styled.div<{ isProfilesSettingsOpen: boolean }>`
  margin: 12px;
  gap: 12px;
  display: flex;
  flex-wrap: wrap;

  ${(props): SerializedStyles => css`
    width: ${props.isProfilesSettingsOpen ? 'calc(100% - 505px)' : '100%'};
    min-width: ${props.isProfilesSettingsOpen ? '650px' : 'auto'};
  `}
`;
