import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconSearch from '../../../ui/icons/IconSearch';
import { SearchInput, Wrapper } from './styles';
import { debounce } from '../../common/utils';
import { setAutomationSearch } from '../../../state/automation/automation-search.atom';

const Search: React.FC = () => {
  const [localSearchString, setLocalSearchString] = useState('');
  const { t: translation } = useTranslation();

  const debouncedSetSearch = useMemo(
    () =>
      debounce((value: string) => {
        setAutomationSearch(value);
      }, 500),
    [setAutomationSearch]
  );
  
  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedValue = target.value?.trimLeft();
    setLocalSearchString(trimmedValue);
    debouncedSetSearch(trimmedValue);
  };

  return (
    <Wrapper>
      <SearchInput
        value={localSearchString}
        onChange={handleChange}
        prefix={
          <div style={{ marginLeft: -8.5 }}>
            <IconSearch color='var(--767676)' />
          </div>
        }
        placeholder={translation('automationModal.search') || ''}
      />
    </Wrapper>
  );
};

export default Search;
