import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconRefresh: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_22721_42945)'>
        <path d='M10.606 9.00802C9.91976 10.0586 8.8949 10.8429 7.70152 11.2309C6.50813 11.6188 5.21797 11.5869 4.04517 11.1406C2.87236 10.6943 1.88744 9.86041 1.25385 8.77726C0.62026 7.69411 0.376111 6.42686 0.561922 5.18584C0.747732 3.94482 1.35233 2.80466 2.27537 1.95457C3.19841 1.10448 4.38439 0.595595 5.63648 0.512362C6.88857 0.429128 8.13147 0.776555 9.15891 1.49698C10.1864 2.21741 10.9366 3.26751 11.285 4.47302' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M11.5 0.5V4.5L7.53101 4.007' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_22721_42945'>
          <rect width='12' height='12' fill='white' />
        </clipPath>
      </defs>
    </svg>
  </IconWrapper>
);
