import React, { FC, Fragment } from 'react';

import PlanCell from './plan-cell';
import PlanCellSelect from './plan-cell-select';
import PlanField from './plan-field';
import {
  TableContainer,
  PlanCellContainer,
} from './styles';
import { IPlan } from '../../../../interfaces/plan';
import { IRow, TABLE_ROWS } from '../../constants';
import { IMappedPlan } from '../../interfaces';
import { getMappedCustomPlans, getMappedPlans, getSelectedCustomPlan } from '../../plans-data';

interface ITable {
  plans: IPlan[];
  selectedCustomPlanId: string;
  selectedDiscount: string;
  setSelectedCustomPlanId: (planId: string) => void;
  onPlanSelect: (id: string) => Promise<void>;
  getPlanInfo: (planId: string, field: keyof IPlan) => JSX.Element | string;
}

const ROW_MAX_PROFILES = 'maxProfiles';

const PlanTable: FC<ITable> = (props): JSX.Element => {
  const {
    plans,
    selectedCustomPlanId,
    selectedDiscount,
    setSelectedCustomPlanId,
    onPlanSelect,
    getPlanInfo,
  } = props;

  const tablePlans = getMappedPlans(plans);
  const tableCustomPlans = getMappedCustomPlans(plans);
  const selectedCustomPlan = getSelectedCustomPlan(tableCustomPlans, selectedCustomPlanId);

  const getPlanCell = (tableRow: IRow, tablePlan: IMappedPlan): JSX.Element => {
    const currentShowPlan = tablePlan.isCustomPlan ? selectedCustomPlan : tablePlan;
    const needShowSelectProfiles = currentShowPlan.isCustomPlan && tableRow.id === ROW_MAX_PROFILES;
    if (needShowSelectProfiles) {
      return (
        <PlanCellContainer backgroundColor={tablePlan.background} rowInfo={tableRow}>
          <PlanCellSelect
            plans={plans}
            tablePlan={currentShowPlan}
            selectedCustomPlanId={selectedCustomPlanId}
            setSelectedCustomPlanId={setSelectedCustomPlanId}
          />
        </PlanCellContainer>
      );
    }

    return (
      <PlanCell
        selectedDiscount={selectedDiscount}
        row={tableRow}
        tablePlan={currentShowPlan}
        onPlanSelect={onPlanSelect}
        getPlanInfo={getPlanInfo}
      />
    );
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <TableContainer>
        {TABLE_ROWS.map((tableRow) => (
          <Fragment key={tableRow.id}>
            <PlanField row={tableRow} />
            {tablePlans.map((tablePlan) => (
              <Fragment key={tablePlan.id}>
                {getPlanCell(tableRow, tablePlan)}
              </Fragment>
            ))}
          </Fragment>
        ))}
      </TableContainer>
    </div>
  );
};

export default PlanTable;
