import React from 'react';

const IconSharing = () => (
    <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.6">
<circle cx="4.6331" cy="2.16215" r="2.16215" fill="black"/>
<circle cx="9.7664" cy="2.16215" r="2.16215" fill="black"/>
<path d="M0 8.95683C0 6.73917 1.79777 4.94141 4.01543 4.94141H5.55983C7.77748 4.94141 9.57525 6.73917 9.57525 8.95683V8.95683H0V8.95683Z" fill="black"/>
<path d="M8.25854 4.99902L10.5019 4.97837C12.7135 4.95801 14.5172 6.74518 14.5172 8.95683V8.95683H10.2585C10.2585 7.33331 9.25854 5.99902 8.25854 4.99902Z" fill="black"/>
</g>
</svg>



);

export default IconSharing;