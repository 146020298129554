import { requestWorkspace } from './api';
import { IWorkspace } from '../../interfaces/workspaces';
import { performBootstrap } from '../../object-pool/bootstrap/perform-bootstrap';
import { resetObjectPool } from '../../object-pool/object-pool.atom';
import { IUserCtx, IWorkspaceCtx, blockSearch, resetToDefaultSearchQuery } from '../../state';
import { NEW_FEATURES } from '../../state/feature-toggle/new-features';
import { resetProfilesTable } from '../../state/profiles-list.atom';
import { resetProfilesQueryLoadingStatus } from '../../state/profiles-table/profiles-query';
import { ITabFolder } from '../quickProfiles/components/tab-profile';
import { defineDbg } from '../../dbg/define-dbg';
import { resetSelectedFolderId } from '../../state/selected-folder.atom';

let ipcRenderer: Electron.IpcRenderer;
const isElectron = !!window.require;
if (isElectron) {
  ipcRenderer = window.require('electron').ipcRenderer;
}

export interface IUserCtxSwitchFields {
  _id?: IUserCtx['_id'];
  defaultWorkspace?: IUserCtx['defaultWorkspace'];
  sharedFolders?: IUserCtx['sharedFolders'];
  updateFolders: IUserCtx['updateFolders'];
}

const switchWorkspace = async (workspaceId: string, workspaceCtx: IWorkspaceCtx, userCtx: IUserCtxSwitchFields): Promise<IWorkspace> => {
  if (workspaceCtx.id === workspaceId) {
    return { ...workspaceCtx };
  }

  resetProfilesQueryLoadingStatus();
  resetObjectPool();
  resetProfilesTable();

  // this block is needed to make private route reload
  // when workspace switches from one to another
  // so that behavior doesn't look too jumpy
  if (workspaceCtx.isLoaded) {
    blockSearch();
    // clear members -> clear me -> isLoaded sets to false
    workspaceCtx.updateWorkspace({ members: [] });
    resetToDefaultSearchQuery();
  }

  const workspace = await requestWorkspace(workspaceId);
  workspaceCtx.updateWorkspace({ ...workspace });
  blockSearch();
  if (isElectron) {
    ipcRenderer && ipcRenderer.invoke('set-active-ws', { workspaceId });
  }

  if (workspaceCtx.id) {
    resetSelectedFolderId();
  }

  let { folders }: { folders: ITabFolder[] } = workspace;
  const { defaultWorkspace, sharedFolders } = userCtx;
  if (sharedFolders && defaultWorkspace === workspaceId) {
    folders = (folders as ITabFolder[]).concat(sharedFolders);
  }

  userCtx.updateFolders(folders);

  if (NEW_FEATURES.debugUtils && !(window as any).dbg) {
    defineDbg();
  }

  if (NEW_FEATURES.objectPool && userCtx._id) {
    await performBootstrap(userCtx._id, workspaceId);
  }

  // to lift the block of the search
  resetToDefaultSearchQuery();

  return workspace;
};

export const refreshCurrentWorkspaceData = async (workspaceCtx: IWorkspaceCtx): Promise<void> => {
  const workspace = await requestWorkspace(workspaceCtx.id);
  workspaceCtx.updateWorkspace({ ...workspace });
};

export default switchWorkspace;
