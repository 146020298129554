import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { NO_FOLDER_GROUP_ID } from './generate-group-headers';
import { GroupHeader } from '../../interfaces/group-header.interface';
import { getProfilesTableGroupHeaders, profilesTableGroupHeadersAtom, useProfilesTableGroupHeaders } from '../profiles-list.atom';
import { getHasSelectedFolder } from '../selected-folder.atom';

const firstOpenUnloadedGroupHeaderAtom = atom<GroupHeader['id'] | null>((get) => {
  const groupHeaders = get(profilesTableGroupHeadersAtom);
  const firstUnloadedGroup = groupHeaders.find((groupHeader) =>
    !(groupHeader.loadingStatus === 'loaded' || groupHeader.loadingStatus === 'loading-initiated') && groupHeader.isOpen,
  );

  return firstUnloadedGroup?.id || null;
});

export const getFirstOpenUnloadedGroupHeader = (): GroupHeader['id'] | null => getDefaultStore().get(firstOpenUnloadedGroupHeaderAtom);
export const useFirstOpenUnloadedGroupHeader = (): GroupHeader['id'] | null => useAtomValue(firstOpenUnloadedGroupHeaderAtom);

type CalcShouldGroupBeVisibleOpts = {
  groupHeaders: GroupHeader[];
  firstOpenUnloadedGroupHeaderId: GroupHeader['id'] | null;
  groupHeaderId: GroupHeader['id'];
  shouldHideEmptyFolderGroups: boolean;
}

export const calcShouldGroupBeVisibleInTable = (opts: CalcShouldGroupBeVisibleOpts): boolean => {
  const { groupHeaders, firstOpenUnloadedGroupHeaderId, groupHeaderId, shouldHideEmptyFolderGroups } = opts;

  const isNoFolderGroup = groupHeaderId === NO_FOLDER_GROUP_ID;
  const groupHeader = groupHeaders.find(({ id }) => id === groupHeaderId);
  const isGroupEmpty = !groupHeader?.totalProfiles;
  const isFoldersGroup = groupHeader?.filter.type === 'folder';
  if ((isNoFolderGroup || (isGroupEmpty && isFoldersGroup)) && shouldHideEmptyFolderGroups) {
    return false;
  }

  if (!firstOpenUnloadedGroupHeaderId || firstOpenUnloadedGroupHeaderId === groupHeaderId) {
    return true;
  }

  const firstToFind = groupHeaders.find((gh) => gh.id === firstOpenUnloadedGroupHeaderId || gh.id === groupHeaderId);
  if (!firstToFind) {
    return false;
  }

  return firstToFind.id !== firstOpenUnloadedGroupHeaderId;
};

export const useShouldGroupBeVisibleInTable = (groupHeaderId: GroupHeader['id']): boolean => {
  const groupHeaders = useProfilesTableGroupHeaders();
  const firstOpenUnloadedGroupHeaderId = useFirstOpenUnloadedGroupHeader();
  const hasSelectedFolder = getHasSelectedFolder();

  return calcShouldGroupBeVisibleInTable({
    groupHeaders,
    firstOpenUnloadedGroupHeaderId,
    groupHeaderId,
    shouldHideEmptyFolderGroups: hasSelectedFolder,
  });
};

export const getShouldGroupBeVisibleInTable = (groupHeaderId: GroupHeader['id'], shouldAlwaysShowEmptyFolderGroups = false): boolean => {
  const groupHeaders = getProfilesTableGroupHeaders();
  const firstOpenUnloadedGroupHeaderId = getFirstOpenUnloadedGroupHeader();
  const hasSelectedFolder = getHasSelectedFolder();

  return calcShouldGroupBeVisibleInTable({
    groupHeaders,
    firstOpenUnloadedGroupHeaderId,
    groupHeaderId,
    shouldHideEmptyFolderGroups: hasSelectedFolder && !shouldAlwaysShowEmptyFolderGroups,
  });
};
