import styled from '@emotion/styled';
import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import IconSpinnerAutosave from '../../../ui/icons/IconSpinnerAutosave';
import IconSuccessAutosave from '../../../ui/icons/IconSuccessAutosave';

const LoaderContainer = styled.div`
  color: var(--767676);
  font-size: 14px;
  line-height: 16px;
  margin-left: 23px;
  display: flex;
  align-items: center;
`;

const LoaderLabel = styled.span`
  font-family: Roboto;
  font-weight: 400;
  margin-left: 15px;
`;

export type TLoaderStatus = 'processing'|'done'|'default';

interface ILoader {
  state: TLoaderStatus;
}
const Loader: FC<ILoader> = (props) => {
  const { t: translation } = useTranslation();

  // NOTE: Components
  const renderIcon = () => {
    if (props.state === 'processing') {
      return (
        <IconSpinnerAutosave />
      );
    }

    return (
      <IconSuccessAutosave />
    );
  };

  // NOTE: Logic
  if (props.state === 'default') {
    return null;
  }

  return (
    <LoaderContainer>
      {renderIcon()}
      <LoaderLabel>{props.state === 'processing' ? translation('base.saving') : translation('base.savedInProfile')}</LoaderLabel>
    </LoaderContainer>
  );

};

export default memo(Loader);
