import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconLockSmall: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg xmlns='http://www.w3.org/2000/svg' width='12' height='14' viewBox='0 0 12 14' fill='none'>
      <path d='M9.75 12.625H2.25C1.629 12.625 1.125 12.121 1.125 11.5V7.75C1.125 7.129 1.629 6.625 2.25 6.625H9.75C10.371 6.625 10.875 7.129 10.875 7.75V11.5C10.875 12.121 10.371 12.625 9.75 12.625Z'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M3.375 5.125V4C3.375 2.55025 4.55025 1.375 6 1.375C7.44975 1.375 8.625 2.55025 8.625 4V5.125'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  </IconWrapper>
);
