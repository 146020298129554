import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconLogout: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M5.5 8.5H15.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M11.5 4.5L15.5 8.5L11.5 12.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8.5 15.5H1.5C0.948 15.5 0.5 15.052 0.5 14.5V2.5C0.5 1.948 0.948 1.5 1.5 1.5H8.5' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  </IconWrapper>
);
