import {
  ANDROID_HARDWARE_SETTINGS,
  BROWSER_SETTINGS,
  HARDWARE_SETTINGS,
  OVERVIEW_SETTINGS,
  PINNED_SETTINGS,
  PROXY_SETTINGS,
  STORAGE_SETTINGS,
} from './content/sections-group/settings-lists';
import { IFullProfile, INavigator, IProfile, IWebRTC } from '../../interfaces';
import { INewNameProfile } from '../../utils/parse-name-formatting/interfaces';
import { IFolder } from '../newProfile/components/folders-select/folder.interface';

export const SETTINGS_PARAM = [
  ...typeof ANDROID_HARDWARE_SETTINGS,
  ...typeof OVERVIEW_SETTINGS,
  ...typeof BROWSER_SETTINGS,
  ...typeof HARDWARE_SETTINGS,
  ...typeof PINNED_SETTINGS,
  ...typeof PROXY_SETTINGS,
  ...typeof STORAGE_SETTINGS,
] as const;

export const SECTIONS_TITLE = [
  'favorites',
  'profileOverview',
  'proxyLocation',
  'browser',
  'storage',
  'hardware',
] as const;

export interface ISection {
  name: typeof SECTIONS_TITLE[number];
  items: Partial<typeof SETTINGS_PARAM>;
  isOpen: boolean;
}

export interface ISections {
  [key: string]: ISection;
}

export interface IChangeSetting {
  changeSetting: (profileData: Partial<IFullProfile>) => void;
}

export interface ISetting extends IChangeSetting {
  fullProfile: IFullProfile;
}

export interface ISettingsParam extends IChangeSetting {
  fullProfile: IFullProfile;
  cookiesLoading: boolean;
  selectedProfilesIds?: string[];
  updateProfileFingerprints: (profilesData: IProfile[]) => void;
  changeProfilesData: (newProfilesData: INewNameProfile[]) => void;
  changeProfilesDataMany: (profileIds: string[], newProfilesData: Partial<IProfile>) => void;
}

export interface ISettingsParamOpts extends ISettingsParam {
  isMultiProfilesSettings: boolean;
}

export interface ISettingParam {
  [key: typeof SETTINGS_PARAM[number]]: JSX.Element;
}

export interface IWebRTCComponent extends IChangeSetting {
  webRTC: IWebRTC;
}

export interface INavigatorComponent extends IChangeSetting {
  navigator: INavigator;
}
