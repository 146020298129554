import { atom, getDefaultStore, useAtom } from 'jotai';

export const isNotificationIconInLocationUnmaskedClickedAtom = atom(false);

export const useIsNotificationIconInLocationUnmaskedClicked = () => useAtom(isNotificationIconInLocationUnmaskedClickedAtom);

export const setIsNotificationIconInLocationUnmaskedClicked = (newValue: boolean): void => {
  const store = getDefaultStore();
  store.set(isNotificationIconInLocationUnmaskedClickedAtom, newValue);
};

export const toggleIsNotificationIconInLocationUnmaskedClicked = (): void => {
  const store = getDefaultStore();
  const currentValue = store.get(isNotificationIconInLocationUnmaskedClickedAtom);
  store.set(isNotificationIconInLocationUnmaskedClickedAtom, !currentValue);
};
