import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ExtensionsModal } from '../../../../features/modalsComponents/components/extensions-modal/extensions-modal';
import { getExtensionList } from '../../../../features/profileSettingsComponents/extensionsTab/get-extension-list';
import { IExtensionFromDb } from '../../../../features/profileSettingsComponents/extensionsTab/interfaces/extension.interface';
import { IFullProfile } from '../../../../interfaces';
import { extensionsContext } from '../../../../state';
import { IconPlusFill } from '../../../../ui/gologin-header/icons';
import { blackMain, grey, greyInformers } from '../../../../ui/style-templates/colors';
import { IChangeSetting } from '../../interfaces';
import { ContainerIcon, SeparatedContainer } from '../../styles';

interface IExtensionsProps extends IChangeSetting {
  updateStateProfile: (profileData: Partial<IFullProfile>) => void;
  fullProfile: IFullProfile;
}

export const Extensions: FC<IExtensionsProps> = (props) => {
  const { changeSetting, updateStateProfile, fullProfile } = props;
  const { id: profileId, chromeExtensions = [], userChromeExtensions = [] } = fullProfile;

  const [profileExtensions, setProfileExtensions] = useState<IExtensionFromDb[]>([]);
  const [extensionsModalVisible, setExtensionsModalVisible] = useState<boolean>(false);

  const { isLoading: isLoadingExtensions, updateExtensionsMap, getExtensions } = useContext(extensionsContext);

  const { t: translation } = useTranslation();

  useEffect(() => {
    fetchProfileExtensions();
  }, [profileId, chromeExtensions, userChromeExtensions]);

  const fetchProfileExtensions = async (): Promise<void> => {
    const extensionList = await getExtensionList({
      chromeExtensions,
      userChromeExtensions,
      updateProfileInfo: updateStateProfile,
      isLoadingExtensions,
      updateExtensionsMap,
      getExtensions,
    });

    setProfileExtensions(extensionList);
  };

  const renderExtensions = (): JSX.Element => {
    if (!profileExtensions.length) {
      return (
        <ContainerIcon
          iconColor={greyInformers}
          iconHoveredColor={blackMain}
          textColor={grey}
          textHoveredColor={blackMain}
          iconType='stroke'
        >
          <IconPlusFill padding={0} margin='0 8px 0 0' />
          {translation('storage.extension.addExtensions')}
        </ContainerIcon>
      );
    }

    return (
      <SeparatedContainer>
        {profileExtensions.map(({ name, extId }) => (
          <span key={extId}>
            {name}
          </span>
        ))}
      </SeparatedContainer>
    );
  };

  return (
    <>
      <div onClick={(): void => setExtensionsModalVisible(true)}>
        {renderExtensions()}
      </div>
      <ExtensionsModal
        userExtensionsTab={profileExtensions}
        setUserExtensionsExtensionsTab={setProfileExtensions}
        extensionsModalVisible={extensionsModalVisible}
        setExtensionsModalVisible={setExtensionsModalVisible}
        updateProfileInfo={changeSetting}
        profileInfo={fullProfile}
      />
    </>
  );
};
