import { createReducer } from 'redux-starter-kit';

import { zeroProfileBookmarks } from '../../../electron/constants/zero-profile-bookmarks';
import { UpdateProfile } from '../common/actions';

export const initialState = {
  name: '',
  autoLang: true,
  bookmarks: zeroProfileBookmarks.roots,
  isBookmarksSynced: false,
  proxyEnabled: false,
  googleServicesEnabled: false,
  startUrl: '',
  lockEnabled: false,
  dns: '',
  proxy: {
    mode: 'none',
    host: '',
    port: 80,
    username: '',
    password: '',
    autoProxyRegion: 'us',
    torProxyRegion: 'us',
  },
  browserType: 'chrome',
  os: 'win',
  osSpec: '',
  isM1: false,
  timezone: {
    enabled: true,
    fillBasedOnIp: true,
    timezone: '',
  },
  navigator: {
    userAgent: '',
    resolution: '',
    language: '',
    platform: '',
    hardwareConcurrency: 2,
    deviceMemory: 2,
    doNotTrack: false,
  },
  canvas: {
    mode: 'off',
  },
  geolocation: {
    mode: 'prompt',
    enabled: true,
    fillBasedOnIp: true,
    customize: true,
    latitude: 0,
    longitude: 0,
    accuracy: 10,
  },
  geoProxyInfo: {
    connection: '',
    country: '',
    region: '',
    city: '',
  },
  webRTC: {
    enable: true,
    isEmptyIceList: true,
    mode: 'public',
  },
  webGL: {
    mode: 'noise',
  },
  clientRects: {
    mode: 'noise',
  },
  webGLMetadata: {
    mode: 'mask',
    vendor: '',
    rerender: '',
  },
  webglParams: {
    glParamValues: [],
  },
  audioContext: {
    mode: 'noise',
  },
  fonts: {
    enableMasking: true,
    enableDomRect: true,
    families: [],
  },
  mediaDevices: {
    enableMasking: true,
    audioInputs: 0,
    audioOutputs: 0,
    videoInputs: 0,
  },
  extensions: {
    enabled: true,
    preloadCustom: true,
    names: [],
  },
  storage: {
    local: true,
    extensions: true,
    bookmarks: true,
    history: true,
    passwords: true,
    session: true,
    indexedDb: true,
    enableExternalExtensions: false,
  },
  plugins: {
    enableVulnerable: true,
    enableFlash: true,
  },
  devicePixelRatio: 1,
  chromeExtensions: [],
  userChromeExtensions: [],
};

export default createReducer(initialState, {
  [UpdateProfile as any]: (state, action) => {
    const payloadProxy = action.payload?.proxy ? { ...action.payload.proxy } : null;

    return { ...state, ...action.payload, proxy: payloadProxy || state.proxy };
  },
});
