import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconCookies: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_19010_21947)'>
        <path d='M8 16C12.1421 16 15.5 12.6421 15.5 8.5C15.5 4.35786 12.1421 1 8 1C3.85786 1 0.5 4.35786 0.5 8.5C0.5 12.6421 3.85786 16 8 16Z' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M5.5 10.5C5.5 10.7761 5.27614 11 5 11C4.72386 11 4.5 10.7761 4.5 10.5C4.5 10.2239 4.72386 10 5 10C5.27614 10 5.5 10.2239 5.5 10.5Z' />
        <path d='M11 10.5C11.5523 10.5 12 10.0523 12 9.5C12 8.94772 11.5523 8.5 11 8.5C10.4477 8.5 10 8.94772 10 9.5C10 10.0523 10.4477 10.5 11 10.5Z' />
        <path d='M7 6.5C7.55228 6.5 8 6.05228 8 5.5C8 4.94772 7.55228 4.5 7 4.5C6.44772 4.5 6 4.94772 6 5.5C6 6.05228 6.44772 6.5 7 6.5Z' />
      </g>
      <defs>
        <clipPath id='clip0_19010_21947'>
          <rect width='16' height='16' fill='white' transform='translate(0 0.5)' />
        </clipPath>
      </defs>
    </svg>
  </IconWrapper>
);
