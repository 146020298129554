import { IProfileOptions } from './profile-extractor.interfaces';
import { ProfileStatusType } from '../../app/types';

export interface ITransferStatusData {
  status: string;
  statusMessage?: IStatusMessageObject|string;
  startProfileTimestamp?: number;
  errorMessage?: string;
  profileOptions?: IProfileOptions;
  timings?: any;
  proxyId?: string;
}

export interface IProfileStatus {
  key: string;
  priority: number;
  info: ProfileStatusType;
}

export interface IProfileStatusUnexpected {
  key: IProfileStatus['key'];
  priority: IProfileStatus['priority'];
  info: 'unexpected';
}

export type IProfileStatusOrUnexpected = IProfileStatus | IProfileStatusUnexpected;

export const TimezoneErrorPrefixes = {
  timezone: 'timezone',
  proxy: 'proxy',
} as const;

type TimezoneErrorPrefix = typeof TimezoneErrorPrefixes[keyof typeof TimezoneErrorPrefixes];

interface IStatusMessageObject {
  errorType: string;
  errorMessage: string;
  prefix: TimezoneErrorPrefix;
}

export interface IUpdateProfileStatusEvent {
  status: ProfileStatusType;
  message: IStatusMessageObject | string;
  profileId: string;
  proxyId?: string;
}
