import { populateObjectPoolProfileFields } from './populate-object-pool-profile-fields';
import { PAGE_SIZE } from '../../../common/constants/constants';
import { SortOrder } from '../../features/common/sorter-order';
import { IGroupMetadata, IProfilesResponse, IRequestProfiles } from '../../features/quickProfiles/api';
import { DEFAULT_SORT_FIELD, DEFAULT_SORT_ORDER } from '../../features/quickProfiles/constants';
import { getAllProfilesFolderId } from '../../state/folders/all-profiles-folder-id.atom';
import { filterProfileByGroupHeader } from '../../state/profiles-list.atom';
import { generateGroupHeaders } from '../../state/profiles-table/generate-group-headers';
import { getObjectPoolEntitiesByType } from '../object-pool.atom';
import type { ObjectPoolFolderProfile, ObjectPoolProfile, PopulatedObjectPoolProfile } from '../types';
import { ObjectPoolShare } from '../types/object-pool-share';
import { getCurrentUserId } from '../../state/current-user-id.atom';
import { getProxyListProxyById } from '../../state/proxy/proxy-list.atom';

const DEFAULT_LIMIT = 500;

export const resolveProfilesListFromObjectPool = async (opts: IRequestProfiles): Promise<IProfilesResponse> => {
  const {
    folderId,
    offset = 0,
    sortField = DEFAULT_SORT_FIELD,
    sortOrder = DEFAULT_SORT_ORDER,
    groupField = null,
    limit = DEFAULT_LIMIT,
    search = '',
  } = opts;

  if (!folderId) {
    throw new Error('inconsistent folder id');
  }

  const objectPoolProfiles = getObjectPoolEntitiesByType('profile');
  const objectPoolFoldersProfiles = getObjectPoolEntitiesByType('folder-profile');
  const objectPoolShares = getObjectPoolEntitiesByType('share');
  const populatedObjectPoolProfiles = populateProfilesWithFoldersProfiles({
    userId: getCurrentUserId(),
    profiles: objectPoolProfiles,
    foldersProfiles: objectPoolFoldersProfiles,
    shares: objectPoolShares,
    currentFolderId: folderId,
  });

  const filteredRawProfiles = populatedObjectPoolProfiles.filter(filterObjectPoolProfileByOpts(opts));
  let sortedRawProfiles;
  if (!search) {
    sortedRawProfiles = [...filteredRawProfiles].sort(sortObjectPoolProfiles(sortField as any, sortOrder));
  } else {
    const batchedProfiles = filteredRawProfiles.reduce<BatchedProfile[]>((acc, objectPoolProfile) => {
      if (objectPoolProfile.name.includes(search)) {
        (objectPoolProfile as BatchedProfile).batch = 1;
        acc.push(objectPoolProfile);
      } else if (objectPoolProfile.notes.includes(search)) {
        (objectPoolProfile as BatchedProfile).batch = 2;
        acc.push(objectPoolProfile);
      }

      return acc;
    }, []);

    sortedRawProfiles = [...batchedProfiles].sort(sortObjectPoolProfiles(sortField as any, sortOrder));
  }

  const paginatedRawProfiles = sortedRawProfiles.slice(offset, offset + limit);
  const profiles = paginatedRawProfiles.map(populateObjectPoolProfileFields);

  let groupsMetadata: IProfilesResponse['groupsMetadata'] | null = null;
  const groupHeaders = generateGroupHeaders(groupField);
  if (groupHeaders) {
    groupsMetadata = groupHeaders.map<IGroupMetadata>((groupHeader) => ({
      groupId: groupHeader.id,
      filteredProfilesCount: profiles.filter((profile) => filterProfileByGroupHeader(groupHeader, profile)).length,
    }));
  }

  const result: IProfilesResponse = {
    profiles,
    isMoreProfilesAvailable: profiles.length === PAGE_SIZE,
    total: sortedRawProfiles.length,
  };

  if (groupsMetadata) {
    result.groupsMetadata = groupsMetadata;
  }

  return result;
};

type PopulateProfilesWithFoldersProfilesOpts = {
  userId: string;
  profiles: ObjectPoolProfile[];
  foldersProfiles: ObjectPoolFolderProfile[];
  shares: ObjectPoolShare[];
  currentFolderId: string;
};

const populateProfilesWithFoldersProfiles = ({
  userId,
  profiles,
  foldersProfiles,
  shares,
  currentFolderId,
}: PopulateProfilesWithFoldersProfilesOpts): PopulatedObjectPoolProfile[] => {
  const foldersMap = new Map<ObjectPoolProfile['id'], ObjectPoolFolderProfile[]>();
  const sharesMap = new Map<ObjectPoolProfile['id'], ObjectPoolShare[]>();
  foldersProfiles.forEach((fp) => {
    const profileFolders = foldersMap.get(fp.profile);
    if (profileFolders) {
      profileFolders.push(fp);

      return;
    }

    foldersMap.set(fp.profile, [fp]);
  });

  shares.forEach((sh) => {
    const profileShares = sharesMap.get(sh.instanceId);
    if (profileShares) {
      profileShares.push(sh);

      return;
    }

    sharesMap.set(sh.instanceId, [sh]);
  });

  return profiles.map((objectPoolProfile) => {
    const folderProfiles = foldersMap.get(objectPoolProfile.id) || [];
    const currFolderProfile = folderProfiles.find((fp) => fp.folder === currentFolderId);
    const shares = sharesMap.get(objectPoolProfile.id) || [];

    // we assume pairs of to.id and instanceId are unique
    const activeShare = shares.find((share) => share.to.id === userId);

    const { overrideSharesCount } = objectPoolProfile;
    const sharesCount = overrideSharesCount ?? shares.length;

    return {
      ...objectPoolProfile,
      folders: folderProfiles,
      order: currFolderProfile?.order || getProfileOrderByCreatedAt(objectPoolProfile),
      isPinned: !!currFolderProfile?.isPinned,

      // TODO: deduplicate (and move out from resolver?)
      shareId: activeShare?.id,
      isShared: !!sharesCount,
      sharesCount,
      sharedEmails: (new Array(sharesCount)).map((share) => ''),
      role: activeShare?.role || 'owner',
    };
  });
};

const FOLDER_ORDER_MULTIPLIER = 10000;
const getProfileOrderByCreatedAt = (profile: { createdAt: string }): number => +(new Date(profile.createdAt)) * FOLDER_ORDER_MULTIPLIER;

const filterObjectPoolProfileByOpts = (opts: IRequestProfiles): ((profile: PopulatedObjectPoolProfile) => boolean) => (profile) => {
  const {
    folderId = '',
    tag = '',
    proxyId = '',
  } = opts;

  const hasFolderId = folderId && folderId !== getAllProfilesFolderId();
  if (hasFolderId && !profile.folders.find((fp) => fp.folder === folderId)) {
    return false;
  }

  if (tag && !profile.tagIds.includes(tag)) {
    return false;
  }

  if (proxyId && profile.proxy.id !== proxyId) {
    return false;
  }

  return true;
};

export const BROWSER_SORT_FIELDS = [
  'lastActivity',
  'proxyType',
  'updatedAt',
  'createdAt',
  'sharedEmails',
  'name',
  'os',
  'order',
] as const;
export type BrowserSortField = (typeof BROWSER_SORT_FIELDS)[number];

type BatchedProfile = PopulatedObjectPoolProfile & { batch?: number };
const PIN_BATCH = -1;

const sortObjectPoolProfiles = (sortField: BrowserSortField, sortOrder: SortOrder) => (
  (curr: BatchedProfile, next: BatchedProfile): number => {
    if (curr.isPinned) {
      curr.batch = PIN_BATCH;
    }

    if (next.isPinned) {
      next.batch = PIN_BATCH;
    }

    const currBatch = curr.batch || 0;
    const nextBatch = next.batch || 0;
    if (currBatch < nextBatch) {
      return -1;
    } else if (currBatch > nextBatch) {
      return 1;
    }

    let currValue: string | number | Date | undefined;
    let nextValue: string | number | Date | undefined;

    const extractProxyTypeForSorting = (item: BatchedProfile): string => {
      const proxy = getProxyListProxyById(item.proxy?.id) || item.proxy;
      const { mode: value } = proxy || { mode: 'none' };
      if (value === 'none') return '';

      return value;
    }

    if (sortField === 'proxyType') {
      currValue = extractProxyTypeForSorting(curr);
      nextValue = extractProxyTypeForSorting(next);
    } else if (sortField === 'sharedEmails') {
      currValue = (curr.sharedEmails || []).length;
      nextValue = (next.sharedEmails || []).length;
    } else if (sortField === 'lastActivity') {
      const EPOCH_STR = (new Date(0)).toISOString();
      currValue = curr.lastActivity || EPOCH_STR;
      nextValue = next.lastActivity || EPOCH_STR;
    } else {
      currValue = curr[sortField];
      nextValue = next[sortField];
    }

    if (currValue === nextValue) {
      return 0;
    }

    let comparisonResult: number;

    if (typeof currValue === 'string' && typeof nextValue === 'string') {
      comparisonResult = currValue.localeCompare(nextValue, 'en-US', { numeric: true });
    } else if (typeof currValue === 'number' && typeof nextValue === 'number') {
      comparisonResult = currValue - nextValue;
    } else if (currValue instanceof Date && nextValue instanceof Date) {
      comparisonResult = currValue.getTime() - nextValue.getTime();
    } else {
      throw new Error(`Unsupported field type for sorting: ${sortField} (with types ${typeof currValue}, ${typeof nextValue})`);
    }

    return sortOrder === 'ascend' ? comparisonResult : -comparisonResult;
  }
);
