import { message } from 'antd';

import { convertAdsPowerProxy } from './helpers/adsPowerFunctions';
import { assignOsFromUserAgent } from './helpers/assignOsFromUserAgent';
import { IShortProfile } from './interfaces/interfaces';

export const parseProfiles = (profilesString: string): any => {
  const stringArray = profilesString.split('\n');

  const headerObject: any = {};
  let headerString = '';
  let isFromAdsPower = false;
  const arrayWithFinalProfiles: any[] = [];

  for (let profileString of stringArray) {
    if (profileString.includes('\r')) {
      profileString = profileString.replace('\r', '');
    }

    let profileArray = splitExceptWithin(profileString);

    if (!Object.keys(headerObject).length) {
      // Excel в некоторых случаях вставляет пустое место в первый элемент. Здесь мы это убираем.
      const header = profileArray.map(element => {
        // adsPower support
        switch (element) {
          case 'cookie':
            isFromAdsPower = true;
            element = 'cookies';
            break;
          case 'remark':
            isFromAdsPower = true;
            element = 'notes';
            break;
          case 'ua':
            isFromAdsPower = true;
            element = 'navigator.userAgent';
            break;
          default:
            // do nothing
        }

        element.replace(/\uFEFF/g, '');

        return element;
      });

      if (header) {
        header.forEach((headerElement: string) => {
          headerObject[headerElement] = '';
        });
        headerString = profileString;
        continue;
      }

      continue;
    }

    if (Object.keys(headerObject).length > profileArray.length) {
      continue;
    }

    profileArray = profileArray.slice(0, Object.keys(headerObject).length);

    const formattedArrayProfile = profileArray.map(profileValue => {
      if (/^"\[/.test(profileValue)) {
        const removedLeftBracketQuote = profileValue.replace(/^"\[/g, '[');
        const removedRightBracketQuote = removedLeftBracketQuote.replace(/]"$/g, ']');
        const removedDoubleQuotes = removedRightBracketQuote.replace(/""/g, '"');
        try {
          profileValue = JSON.parse(removedDoubleQuotes);
        } catch(error) {
          message.error('notifications.error.invalidJsonInFile');
          throw error;
        }
      }

      if (/^"/.test(profileValue)) {
        const removedStartQuotesProfileValue = profileValue.replace(/^"/, '');
        profileValue = removedStartQuotesProfileValue.replace(/"$/, '');
      }

      if (/\\n/g.test(profileValue)) {
        profileValue = profileValue.replace(/\\n/g, '\n');
      }

      return profileValue;
    });

    Object.entries(headerObject).forEach(([key], index: number) => {
      if (['_id', 'name', 'proxy', 'os', 'navigator.userAgent', 'proxytype'].includes(key)) {
        headerObject[key] = formattedArrayProfile[index];
      }
    });

    assignOsFromUserAgent(headerObject);

    if (isFromAdsPower) {
      convertAdsPowerProxy(headerObject);
    }

    const shortProfile: IShortProfile = {
      _id: headerObject._id,
      name: headerObject.name,
      proxy: headerObject.proxy,
      os: headerObject.os,
      fullString: profileString,
    };

    arrayWithFinalProfiles.push(shortProfile);
  }

  return { arrayWithFinalProfiles, header: headerString };
};

const splitExceptWithin = (text: string): string[] => {
  let start = 0;
  let encapsulated = false;
  let bracketsEncapsulated = false;
  let proxyEncapsulated = false;
  const splitArray = [];

  [...text].forEach((char, i) => {
    if (proxyEncapsulated && (char === ',' || char === ';' || char === '\t')) {
      proxyEncapsulated = false;
    }

    if ([',', ';', '\t'].includes(char) && !(encapsulated || bracketsEncapsulated || proxyEncapsulated)) {
      splitArray.push(text.substring(start, i));
      start = i + 1;
    }

    if (char === '"') {
      encapsulated = !encapsulated;
    }

    if (char === '(') {
      bracketsEncapsulated = true;
    }

    if (char === ')') {
      bracketsEncapsulated = false;
    }

    if (bracketsEncapsulated && text.substring(i, i + 2).includes('""')) {
      bracketsEncapsulated = false;
    }

    if (['http://', 'socks4://', 'socks5://'].includes(text.substring(start, i))) {
      proxyEncapsulated = true;
    }
  });

  splitArray.push(text.substring(start));

  return splitArray;
};
