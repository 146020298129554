import { IWorkspaceFolder, IWorkspaceFolderPermissions } from '../interfaces/workspaces';
import { getSelectedFolderId } from '../state/selected-folder.atom';

const filterFoldersForPermission = (
  folders: IWorkspaceFolder[],
  permission: keyof IWorkspaceFolderPermissions,
): IWorkspaceFolder[] => folders
  .filter(folder => folder.permissions && folder.permissions[permission]);

interface IGetAccessibleFolderName {
  folders: IWorkspaceFolder[];
  permission: keyof IWorkspaceFolderPermissions;
  limitedAccess: boolean;
  hasFirstFolder?: boolean;
  hasSelectedFolder?: boolean;
}

export const getAccessibleFolderName = (props: IGetAccessibleFolderName): string => {
  const { folders, permission, limitedAccess, hasFirstFolder, hasSelectedFolder } = props;

  const foldersList = filterFoldersForPermission(folders, permission);
  if (!foldersList.length) {
    return '';
  }

  const selectedFolderId = getSelectedFolderId();
  const selectedFolder = foldersList.find(folder => folder.id === selectedFolderId);

  if (hasSelectedFolder && selectedFolder) {
    return selectedFolder.name;
  }

  if (foldersList.length === 1 && limitedAccess) {
    return foldersList[0].name;
  }

  if (foldersList.length > 1 && limitedAccess && hasFirstFolder) {
    const [sortedFolderList] = foldersList.sort((curr, next) => curr.name.localeCompare(next.name));

    return sortedFolderList.name;
  }

  return '';
};
