import styled from '@emotion/styled/macro';

import { GOLOGIN_POPOVER_DEFAULT_PADDING } from './gologin-popover-item-row';
import { textFont } from '../style-templates';

export const GologinPopoverHint = styled.div`
  padding: ${GOLOGIN_POPOVER_DEFAULT_PADDING};

  ${textFont()}
  color: var(--767676);

  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  text-align: left;
`;
