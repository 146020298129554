import React from 'react';
import styled from '@emotion/styled';

const IconRemoveFolderFromRowStyle = styled('svg')`
  stroke: var(--BDBDBD);
  :hover {
    stroke: var(--F5222D);
    cursor: pointer;
  }
`;

const IconRemoveFolderFromRow = () => (
  <IconRemoveFolderFromRowStyle width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 2L2 10" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square"/>
    <path d="M10 10L2 2" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square"/>
  </IconRemoveFolderFromRowStyle>
);

export default IconRemoveFolderFromRow;
