import React from 'react';

import {
  ORBITA_INCOMPATIBLE_CANNOT_RUN_MODAL_NAME,
  useGlobalModalIsVisible,
} from '../../../../../../state/global-modal';
import { IOrbitaModalBase } from '../../interfaces';
import ModalBase from '../../modal-base';

interface IIncompatibleCannotRunModal {
  handleCancel: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const IncompatibleCannotRunModal: React.FC<IIncompatibleCannotRunModal> = (props) => {
  const { handleCancel } = props;

  const isModalVisible = useGlobalModalIsVisible(ORBITA_INCOMPATIBLE_CANNOT_RUN_MODAL_NAME);

  const keyBase = 'modals.orbitaIncompatibleCannotRunModal.';

  const modalBaseProps: IOrbitaModalBase = {
    visible: isModalVisible,
    handleCancel,
    titleKey: keyBase + 'title',
    body: {
      key: keyBase + 'body',
    },
    primaryButton: {
      key: keyBase + 'submit',
      isDisabled: false,
      handleClick: handleCancel,
    },
  };

  return <ModalBase {...modalBaseProps} />;
};

export default IncompatibleCannotRunModal;
