import { labelsParsedFields } from '../constants';
import { IParsedFacebookData, LabelParsedFields } from '../interfaces';

export const getNotesFacebookAccount = (data?: IParsedFacebookData): string => {
  if (!data) {
    return '';
  }

  return Object.keys(labelsParsedFields).reduce((acc: string, currentValue: LabelParsedFields) => {
    const label = labelsParsedFields[currentValue];
    const value = data[currentValue];

    if (!value) {
      return acc;
    }

    if (currentValue === 'email') {
      const psw = data.password;
      acc = acc + `<p><b>${label}:</b> ${value}${psw ? `:${psw}` : ''}</p></br>`;

      return acc;
    }

    if (currentValue === 'notParsedData' && Array.isArray(value)) {
      let allNotParsedData = '';

      value.forEach((item) => {
        allNotParsedData = allNotParsedData + `<p>${item.value}</p>`;
      });

      acc = acc + `<p><b>${label}:</b> ${allNotParsedData}</p></br>`;

      return acc;
    }

    acc = acc + `<p><b>${label}:</b> ${value}</p></br>`;

    return acc;
  }, '');
};
