import React, { FC } from 'react';
import styled from '@emotion/styled';

const Label = styled('span')`
  font-family: Roboto;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--767676-label-component);
`;

const Container = styled('div')`
  margin-top: 4px;
`;

interface ILabeledElement {
  title: string;
  hideTitle?: boolean;
}

const LabeledElement: FC<ILabeledElement> = ({ children, title, hideTitle }) => (
  <>
    {hideTitle ? null : (
      <Label>
        {title}
      </Label>
    )}
    <Container>
      {children}
    </Container>
  </>
);

export default LabeledElement;
