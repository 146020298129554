import { readFile } from './read-file';
import { parseMultipleProxies } from '../../../utils/proxy-string';
import { IDataToCreateProfile } from '../api';
import { prepareFBProfiles } from '../facebook-parser/prepare-facebook-profiles';
import { prepareProxyProfiles } from '../proxy-parser/prepare-proxy-profiles';

const prepareProfiles = async (file: string): Promise<IDataToCreateProfile[]> => {
  const isProxyFromFile = !(
    file
      .trim()
      .split('')
      .findIndex((value) => value === '{') > -1
  );

  if (isProxyFromFile) {
    const proxyFromFile = await parseMultipleProxies(file);

    return prepareProxyProfiles(proxyFromFile);
  }

  const isCookies = ['{', '['].includes(file.trim().charAt(0));

  if (isCookies) {
    return [{ cookies: file, fileContent: file }];
  }

  return prepareFBProfiles(file);
};

export const parseFileList = async (files: File[]): Promise<IDataToCreateProfile[]> => {
  const contentFiles = await Promise.all(files.map(readFile));
  const filesToImport: IDataToCreateProfile[] = [];

  await Promise.all(contentFiles.map(async file => {
    const preparedProfiles = await prepareProfiles(file);
    filesToImport.push(...preparedProfiles);
  }));

  return filesToImport;
};
