import React from 'react';

const IconFolder = () => (
  <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M35.7995 24.1379H10.7517C9.44563 24.1379 8.25149 23.4 7.66751 22.2317L5.1722 17.2414L7.66751 12.2512C8.25149 11.0828 9.44544 10.3449 10.7517 10.3449H35.7997C37.1058 10.3449 38.2999 11.0828 38.8839 12.2512L41.379 17.2414L38.8837 22.2317C38.2997 23.4 37.1058 24.1379 35.7995 24.1379Z" fill="var(--FFC36E)"/>
    <path d="M35.7995 24.1379H10.7517C9.44563 24.1379 8.25149 23.4 7.66751 22.2317L5.1722 17.2414L7.66751 12.2512C8.25149 11.0828 9.44544 10.3449 10.7517 10.3449H35.7997C37.1058 10.3449 38.2999 11.0828 38.8839 12.2512L41.379 17.2414L38.8837 22.2317C38.2997 23.4 37.1058 24.1379 35.7995 24.1379Z" fill="var(--FFC36E)"/>
    <path d="M94.8272 13.7932H5.17209C4.21995 13.7932 3.44788 14.5652 3.44788 15.5174V24.1381H96.5514V15.5172C96.5514 14.565 95.7793 13.7932 94.8272 13.7932Z" fill="var(--EFF2FA)"/>
    <path d="M96.5514 20.6896H3.44788V24.1378H96.5514V20.6896Z" fill="var(--E1E6F2)"/>
    <path d="M96.5518 17.2414H42.4449C41.792 17.2414 41.1949 17.6104 40.9029 18.1944L38.8842 22.2317C38.3002 23.4 37.1059 24.1379 35.8 24.1379H10.752C9.4459 24.1379 8.25176 23.4 7.66777 22.2317L5.64883 18.1944C5.35684 17.6104 4.75977 17.2414 4.10684 17.2414H3.44824C1.54375 17.2414 0 18.7852 0 20.6897V86.2069C0 88.1114 1.54375 89.6551 3.44824 89.6551H96.5518C98.4563 89.6551 100 88.1114 100 86.2069V20.6897C100 18.7852 98.4563 17.2414 96.5518 17.2414Z" fill="var(--FFD782)"/>
    <path d="M94.8272 86.2069H5.17209C4.21995 86.2069 3.44788 85.4348 3.44788 84.4827C3.44788 83.5306 4.21995 82.7585 5.17209 82.7585H94.8272C95.7793 82.7585 96.5514 83.5306 96.5514 84.4827C96.5514 85.435 95.7793 86.2069 94.8272 86.2069Z" fill="var(--FFC36E)"/>
    <path d="M63.7934 43.1035H89.6555C90.6076 43.1035 91.3797 42.3314 91.3797 41.3793V27.5861C91.3797 26.634 90.6076 25.8619 89.6555 25.8619H63.7934C62.8412 25.8619 62.0692 26.634 62.0692 27.5861V41.3793C62.0692 42.3314 62.8412 43.1035 63.7934 43.1035Z" fill="var(--EFF2FA)"/>
    <path d="M86.2067 32.7586H67.2413C66.2891 32.7586 65.5171 31.9866 65.5171 31.0344C65.5171 30.0823 66.2891 29.3102 67.2413 29.3102H86.2067C87.1589 29.3102 87.9309 30.0823 87.9309 31.0344C87.9309 31.9868 87.1589 32.7586 86.2067 32.7586Z" fill="var(--C7CFE2)"/>
    <path d="M86.2067 39.6551H67.2413C66.2891 39.6551 65.5171 38.883 65.5171 37.9309C65.5171 36.9787 66.2891 36.2066 67.2413 36.2066H86.2067C87.1589 36.2066 87.9309 36.9787 87.9309 37.9309C87.9309 38.8832 87.1589 39.6551 86.2067 39.6551Z" fill="var(--D7DEED)"/>
    <path d="M35.7995 24.1379H10.7517C9.44563 24.1379 8.25149 23.4 7.66751 22.2317L5.1722 17.2414L7.66751 12.2512C8.25149 11.0828 9.44544 10.3449 10.7517 10.3449H35.7997C37.1058 10.3449 38.2999 11.0828 38.8839 12.2512L41.379 17.2414L38.8837 22.2317C38.2997 23.4 37.1058 24.1379 35.7995 24.1379Z" fill="var(--FFC36E)"/>
    <path d="M35.7995 24.1379H10.7517C9.44563 24.1379 8.25149 23.4 7.66751 22.2317L5.1722 17.2414L7.66751 12.2512C8.25149 11.0828 9.44544 10.3449 10.7517 10.3449H35.7997C37.1058 10.3449 38.2999 11.0828 38.8839 12.2512L41.379 17.2414L38.8837 22.2317C38.2997 23.4 37.1058 24.1379 35.7995 24.1379Z" fill="var(--95CEA1)"/>
    <path d="M94.8272 13.7932H5.17209C4.21995 13.7932 3.44788 14.5652 3.44788 15.5174V24.1381H96.5514V15.5172C96.5514 14.565 95.7793 13.7932 94.8272 13.7932Z" fill="var(--EFF2FA)"/>
    <path d="M96.5514 20.6896H3.44788V24.1378H96.5514V20.6896Z" fill="var(--E1E6F2)"/>
    <path d="M96.5518 17.2414H42.4449C41.792 17.2414 41.1949 17.6104 40.9029 18.1944L38.8842 22.2317C38.3002 23.4 37.1059 24.1379 35.8 24.1379H10.752C9.4459 24.1379 8.25176 23.4 7.66777 22.2317L5.64883 18.1944C5.35684 17.6104 4.75977 17.2414 4.10684 17.2414H3.44824C1.54375 17.2414 0 18.7852 0 20.6897V86.2069C0 88.1114 1.54375 89.6551 3.44824 89.6551H96.5518C98.4563 89.6551 100 88.1114 100 86.2069V20.6897C100 18.7852 98.4563 17.2414 96.5518 17.2414Z" fill="var(--D9F1DE)"/>
    <path d="M94.8272 86.2069H5.17209C4.21995 86.2069 3.44788 85.4348 3.44788 84.4827C3.44788 83.5306 4.21995 82.7585 5.17209 82.7585H94.8272C95.7793 82.7585 96.5514 83.5306 96.5514 84.4827C96.5514 85.435 95.7793 86.2069 94.8272 86.2069Z" fill="var(--95CEA1)"/>
    <path d="M63.7934 43.1035H89.6555C90.6076 43.1035 91.3797 42.3314 91.3797 41.3793V27.5861C91.3797 26.634 90.6076 25.8619 89.6555 25.8619H63.7934C62.8412 25.8619 62.0692 26.634 62.0692 27.5861V41.3793C62.0692 42.3314 62.8412 43.1035 63.7934 43.1035Z" fill="var(--F0FFF3)"/>
    <path d="M86.2067 32.7586H67.2413C66.2891 32.7586 65.5171 31.9866 65.5171 31.0344C65.5171 30.0823 66.2891 29.3102 67.2413 29.3102H86.2067C87.1589 29.3102 87.9309 30.0823 87.9309 31.0344C87.9309 31.9868 87.1589 32.7586 86.2067 32.7586Z" fill="var(--95CEA1)"/>
    <path d="M86.2067 39.6551H67.2413C66.2891 39.6551 65.5171 38.883 65.5171 37.9309C65.5171 36.9787 66.2891 36.2066 67.2413 36.2066H86.2067C87.1589 36.2066 87.9309 36.9787 87.9309 37.9309C87.9309 38.8832 87.1589 39.6551 86.2067 39.6551Z" fill="var(--B9E4C2)"/>
    <path d="M63.7939 43.1035H89.656C90.6081 43.1035 91.3802 42.3314 91.3802 41.3793V27.5861C91.3802 26.6339 90.6081 25.8619 89.656 25.8619H63.7939C62.8417 25.8619 62.0696 26.6339 62.0696 27.5861V41.3793C62.0696 42.3314 62.8417 43.1035 63.7939 43.1035Z" fill="var(--EFF2FA)"/>
    <path d="M86.2068 32.7586H67.2413C66.2892 32.7586 65.5171 31.9866 65.5171 31.0344C65.5171 30.0823 66.2892 29.3102 67.2413 29.3102H86.2068C87.1589 29.3102 87.931 30.0823 87.931 31.0344C87.931 31.9868 87.1589 32.7586 86.2068 32.7586Z" fill="var(--C7CFE2)"/>
    <path d="M86.2068 39.6551H67.2413C66.2892 39.6551 65.5171 38.883 65.5171 37.9308C65.5171 36.9787 66.2892 36.2066 67.2413 36.2066H86.2068C87.1589 36.2066 87.931 36.9787 87.931 37.9308C87.931 38.8832 87.1589 39.6551 86.2068 39.6551Z" fill="var(--D7DEED)"/>
  </svg>
);

export default IconFolder;
