import { getRequestProperties } from './client';
import { history } from '..';
import { TRequestMethod } from '../../../common/types/request-methods';
import { IRequestValidate, IRequestPropertiesResult } from '../../interfaces/check-public-endpoint.interface';

const publicEndpointsData: { [key: string]: { methods: TRequestMethod[] } } = {
  '/user/login': {
    methods: ['POST'],
  },
  '/user/sign-in': {
    methods: ['POST'],
  },
  '/user/password': {
    methods: ['POST'],
  },
  '/user': {
    methods: ['POST'],
  },
  '/user/reset': {
    methods: ['POST'],
  },
};

const excludesPathnames = [
  '/sign_up',
];

const shouldSkipRequestValidation = ({ urlEndpoint, method }: IRequestValidate): boolean => {
  const isRequestToPublicEndpoint = !!publicEndpointsData[urlEndpoint]?.methods?.includes(method);
  const isRequestFromExcludesPathname = excludesPathnames.includes(history.location.pathname);

  return isRequestToPublicEndpoint || isRequestFromExcludesPathname;
};

export const validateRequestAndGetProperties = async ({ urlEndpoint, method }: IRequestValidate): Promise<IRequestPropertiesResult> => {
  let retryLimit = 100;
  const timeoutToRetryFetchWithToken = 100;

  let { memberToken, token, headers } = await getRequestProperties();
  if (urlEndpoint && !shouldSkipRequestValidation({ urlEndpoint, method })) {
    while ((!(token || memberToken)) && retryLimit--) {
      await new Promise((resolve) => setTimeout(resolve, timeoutToRetryFetchWithToken));
      ({ memberToken, token, headers } = await getRequestProperties());
    }
  }

  headers = {
    ...headers,
    Authorization: `Bearer ${token}`,
  };

  return { memberToken, token, headers };
};
