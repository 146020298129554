import { ACCESS_TOKEN } from './config';
import { sendCredentialsToLocalServer } from './index';
import { getUserOS } from '../../utils/user-os';

let ipcRenderer: Electron.IpcRenderer|null = null;
const isElectron = !!window.require;
let isWinVaultAlreadyChecked = false;

if (isElectron) {
  ({ ipcRenderer } = window.require('electron'));
}

export function getAuthTokenFromLocalStorage(): string|undefined {
  return localStorage.getItem(ACCESS_TOKEN) || void 0;
}

export async function retrieveToken(): Promise<string|void> {
  const currentToken = getAuthTokenFromLocalStorage();
  if (currentToken) {
    return currentToken;
  }

  const os = await getUserOS();
  if (os.name !== 'win' || !isElectron) {
    return void 0;
  }

  // только для винды, для восстановления авторизации после обновления
  if (ipcRenderer && !isWinVaultAlreadyChecked) {
    return ipcRenderer.invoke('check-auth-token')
      .then((token: string) => {
        localStorage.setItem(ACCESS_TOKEN, token);

        return token;
      })
      .catch(() => void 0)
      .finally(() => {
        isWinVaultAlreadyChecked = true;
      });
  }

  return void 0;
}

export function saveToken(token: string, source?: string): void {
  localStorage.setItem(ACCESS_TOKEN, token);

  sendCredentialsToLocalServer('access', source);
}

export function clearToken(): void {
  localStorage.removeItem(ACCESS_TOKEN);

  sendCredentialsToLocalServer('access', 'clear token');
}
