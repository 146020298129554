import { Backdrop } from '@material-ui/core';
import { DefaultTFuncReturn } from 'i18next';
import React, { CSSProperties, ReactElement } from 'react';

import { ModalContent, CustomModal, ModalHeader, ModalBody, ModalFooter, ModalContainer } from './styles';
import { IconClose } from '../gologin-header/icons';

interface IGologinModal {
  isVisible: boolean;
  onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  width?: string;
  title?: string|DefaultTFuncReturn|JSX.Element;
  footer?: ReactElement;
  bodyStyle?: CSSProperties;
}

const GologinModal: React.FC<IGologinModal> = (props) => {
  const { isVisible, onClose, children, width = 'auto', title, footer, bodyStyle } = props;

  return (
    <CustomModal
      open={isVisible}
      onClose={onClose}
      style={{ zIndex: 1000, backgroundColor: 'var(--00000073-modal-mask)' }}
      BackdropComponent={Backdrop}
    >
      <ModalContainer>
        <ModalContent hasTitle={!!title}>
          {title ? (
            <ModalHeader>
              {title}
              <IconClose onClick={onClose} padding={0} iconColor={'var(--00000073-antd)'} />
            </ModalHeader>
          ) : null}
          <ModalBody width={width} style={bodyStyle}>
            {children}
          </ModalBody>
          {footer ? (
            <ModalFooter>
              {footer}
            </ModalFooter>
          ) : null}
        </ModalContent>
      </ModalContainer>
    </CustomModal>
  );
};

export default GologinModal;
