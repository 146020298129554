import React from 'react';

const IconFolderSimple = () => (
  <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M5.5 2.5L4.5 0.5H0.5V9.5C0.5 9.76522 0.605357 10.0196 0.792893 10.2071C0.98043 10.3946 1.23478 10.5 1.5 10.5H10.5C10.7652 10.5 11.0196 10.3946 11.2071 10.2071C11.3946 10.0196 11.5 9.76522 11.5 9.5V2.5H5.5Z'
      stroke='var(--767676-modals-invite-members-icon-folder)'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default IconFolderSimple;
