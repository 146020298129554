import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { PROXY_FOLDER_SELECTED } from '../../../../common/constants/local-storage';
import { E_PROXY_FOLDERS, PROXY_FOLDERS_DEFAULT, TProxyFolder } from '../../../features/proxy/proxy-page/constants/proxy-folders';
import { IFolder } from '../../../interfaces';

const selectedProxyFolderAtom = atom<TProxyFolder>(E_PROXY_FOLDERS.allProxies);
const proxyFoldersAtom = atom<IFolder[]>(PROXY_FOLDERS_DEFAULT);

export const selectedProxyFolderPersistentAtom = atom(
  (get) => get(selectedProxyFolderAtom),
  (_get, set, newSelectedFolderName: TProxyFolder) => {
    set(selectedProxyFolderAtom, newSelectedFolderName);
    localStorage.setItem(PROXY_FOLDER_SELECTED, newSelectedFolderName);
  },
);

export const useSelectedProxyFolder = (): TProxyFolder => useAtomValue(selectedProxyFolderPersistentAtom);
export const getSelectedProxyFolder = (): TProxyFolder => getDefaultStore().get(selectedProxyFolderPersistentAtom);
export const setSelectedProxyFolder = (newSelectedFolderName: TProxyFolder): void =>
  getDefaultStore().set(selectedProxyFolderPersistentAtom, newSelectedFolderName);

export const getProxyFolders = (): IFolder[] => getDefaultStore().get(proxyFoldersAtom);
export const useProxyFolders = (): IFolder[] => useAtomValue(proxyFoldersAtom);

export const useIsArchiveProxiesFolderSelected = (): boolean => (useSelectedProxyFolder() === E_PROXY_FOLDERS.archive);
