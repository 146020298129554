export const getCookie = (name: string): any => {
  const cookies = getAllCookies();

  return cookies[name];
};

export const getAllCookies = (): any  => {
  const pairs = document.cookie.split(';');
  const cookies: any = {};

  pairs.forEach((pair) => {
    const [key, value] = pair.split('=');
    cookies[(key + '').trim()] = decodeURIComponent(value);
  });

  return cookies;
};
