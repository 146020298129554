import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconShare: React.FC<IIconWrapper> = (props) => (
  <IconWrapper iconType='fill' {...props}>
    <svg xmlns='http://www.w3.org/2000/svg' width={props.size || 16} height={props.size || 16} viewBox='0 0 16 16' fill='none'>
      <g clipPath='url(#clip0_12354_163161)'>
        <path d='M3.5 3.5H1.5C0.948 3.5 0.5 3.948 0.5 4.5V14.5C0.5 15.052 0.948 15.5 1.5 15.5H12.5C13.052 15.5 13.5 15.052 13.5 14.5V11.5' strokeLinecap='round' strokeLinejoin='round'/>
        <path d='M11.5 0.5L15.5 4.5L11.5 8.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M15.5 4.5H10.5C8.291 4.5 6.5 6.291 6.5 8.5V10.5' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_12354_163161'>
          <rect width='16' height='16' fill='var(--FFFFFF)' />
        </clipPath>
      </defs>
    </svg>
  </IconWrapper>
);
