import { useAtomValue } from 'jotai';
import React, { FC } from 'react';

import { ProxySelectorWrapper } from './styles';
import useDelayedMount from '../../../hooks/use-should-mount.hook';
import { useProxyManagerState } from '../../../state/proxy/proxy-manager-modal-status.atom';
import { getIsProxyArchived } from '../../proxy/proxy-helpers';
import ProxySelector from '../../proxy/proxy-selector';
import { ITableCell } from '../interfaces';

const ProxyCell: FC<ITableCell & { rowIdx: number }> = (props) => {
  const { profileAtom, rowIdx } = props;

  const { id: profileId, proxy, proxyEnabled, role, archivedProxy = null } = useAtomValue(profileAtom);
  const { containerElement } = useProxyManagerState();

  const isArchivedProxy = getIsProxyArchived(archivedProxy);

  const hasProxy = isArchivedProxy || (proxyEnabled && proxy.mode !== 'none');
  const { shouldMount } = useDelayedMount(!!hasProxy);
  if (!shouldMount) {
    return null;
  }

  return (
    <ProxySelectorWrapper isAppearedAboveBackdrop={!!containerElement}>
      <ProxySelector
        proxy={isArchivedProxy ? archivedProxy : proxy}
        proxyEnabled={!!proxyEnabled || isArchivedProxy}
        profileId={profileId}
        isInDrawer={false}
        isSharedProxy={role !== 'owner'}
        localProxySelectorLocation={`profiles-table-${rowIdx}`}
      />
    </ProxySelectorWrapper>
  );
};

export default ProxyCell;
