import { TableCell } from '@material-ui/core';
import { Tooltip } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { RestoreButton } from './restore-button';
import { ProfileNameCell, ProfileNameColumn } from './styles';
import { CustomTableRow, EmailCell, EmailColumn, EmailTooltip } from '../../../ui/styles';
import dateToText from '../../../utils/date-to-text';

interface IProfileRow {
  name: string;
  deletedAt: Date;
  deletedByUserEmail: string;
  onRestoreProfile: () => void;
}

export const DeletedProfileRow: FC<IProfileRow> = (props): JSX.Element => {
  const { name, deletedAt, deletedByUserEmail, onRestoreProfile } = props;

  const { t: translation } = useTranslation();

  const renderTooltipTitle = (): JSX.Element => (
    <EmailTooltip>
      {deletedByUserEmail}
    </EmailTooltip>
  );

  return (
    <CustomTableRow>
      <TableCell style={{ paddingLeft: 0, width: '220px' }}>
        <ProfileNameCell>
          <ProfileNameColumn>
            {name}
          </ProfileNameColumn>
        </ProfileNameCell>
      </TableCell>
      <TableCell style={{ width: '160px' }}>
        {dateToText(deletedAt)}
      </TableCell>
      <TableCell style={{ width: '220px' }}>
        <EmailCell>
          <Tooltip title={renderTooltipTitle}>
            <EmailColumn>
              {deletedByUserEmail}
            </EmailColumn>
          </Tooltip>
        </EmailCell>
      </TableCell>
      <TableCell style={{ width: '220px' }}>
        <RestoreButton
          onRestore={onRestoreProfile}
          title={translation('personalArea.restoreProfiles.restoreButton')}
        />
      </TableCell>
    </CustomTableRow>
  );
};
