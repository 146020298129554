import { IProfile } from '../../../interfaces';
import { ITag } from '../../tags/interfaces/tag.interface';

interface IModifyTagResponse {
  updateRequired: boolean;
  newProfiles: IProfile[];
}

export interface IAddTagToProfiles {
  profiles: IProfile[];
  tag: ITag;
  targetProfileIds?: string[]|'all';
  compareBy?: 'id'|'title';
}

export const addTagToProfiles = ({
  profiles,
  tag,
  targetProfileIds = 'all',
  compareBy = 'id',
}: IAddTagToProfiles): IModifyTagResponse => {
  let updateRequired = false;
  const newProfiles = profiles.map(profile => {
    if (targetProfileIds !== 'all') {
      const profileObject = targetProfileIds.find(profileId => profile.id === profileId);
      if (!profileObject) {
        return profile;
      }
    }

    const profileTags = profile.tags || [];
    const existedTag = profileTags.find(profileTag =>
      profileTag[compareBy] === tag[compareBy] &&
      profileTag.field === tag.field,
    );

    if (existedTag) {
      return profile;
    }

    updateRequired = true;
    const newProfileTags = [...profileTags, tag];

    return {
      ...profile,
      tags: newProfileTags,
    };
  });

  return { updateRequired, newProfiles };
};

interface IUpdateTagInProfiles extends IAddTagToProfiles {
  appendIfNotFound?: boolean;
}

export const updateTagInProfiles = ({
  profiles,
  tag,
  targetProfileIds = 'all',
  compareBy = 'id',
  appendIfNotFound = false,
}: IUpdateTagInProfiles): IModifyTagResponse => {
  let updateRequired = false;
  const newProfiles = profiles.map(profile => {
    if (targetProfileIds !== 'all') {
      const profileObject = targetProfileIds.find(profileId => profile.id === profileId);
      if (!profileObject) {
        return profile;
      }
    }

    let foundInProfile = false;
    const profileTags = profile.tags || [];
    let newProfileTags = profileTags.map(profileTag => {
      const isSameTag = profileTag[compareBy] === tag[compareBy] && profileTag.field === tag.field;
      if (!isSameTag) {
        return profileTag;
      }

      foundInProfile = true;

      return tag;
    });

    if (!foundInProfile && appendIfNotFound) {
      newProfileTags = [...profileTags, tag];
    }

    updateRequired = true;

    return {
      ...profile,
      tags: newProfileTags,
    };
  });

  return { updateRequired, newProfiles };
};

export const removeTagFromProfiles = (
  profiles: IProfile[],
  tagId: string,
  targetProfileIds: IAddTagToProfiles['targetProfileIds'] = 'all',
): IProfile[] => profiles.map(profile => {
  if (targetProfileIds !== 'all') {
    const profileObject = targetProfileIds.find(profileId => profile.id === profileId);
    if (!profileObject) {
      return profile;
    }
  }

  const tags = profile.tags.filter(tag => (tag.id !== tagId));

  return {
    ...profile,
    tags,
  };
});

export const removeCustomStatusesFromProfiles = (
  profiles: IProfile[],
  workspaceId: string,
  targetProfileIds: IAddTagToProfiles['targetProfileIds'] = 'all',
): IProfile[] => profiles.map(profile => {
  if (targetProfileIds !== 'all') {
    const profileObject = targetProfileIds.find(profileId => profile.id === profileId);
    if (!profileObject) {
      return profile;
    }
  }

  const tags = profile.tags.filter(tag => tag.field !== 'custom-status');

  return {
    ...profile,
    tags,
  };
});

export const findProfilesWithTag = (profiles: IProfile[], tagId: string): IProfile[] => profiles.filter(profile => {
  const profileTags = profile.tags || [];
  const tagInProfile = profileTags.find(profileTag => tagId === profileTag.id);

  return !!tagInProfile;
});
