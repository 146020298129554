import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconShield: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_12578_230214)'>
        <path d='M8.00031 3.5L9.13731 5.804L11.6803 6.174L9.84031 7.967L10.2743 10.5L8.00031 9.304L5.72631 10.5L6.16031 7.967L4.32031 6.174L6.86331 5.804L8.00031 3.5Z' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M8 15.5C8 15.5 0.5 12.821 0.5 2.643L8 0.5L15.5 2.643C15.5 12.821 8 15.5 8 15.5Z' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_12578_230214'>
          <rect width='16' height='16' fill='var(--FFFFFF)' />
        </clipPath>
      </defs>
    </svg>
  </IconWrapper>
);
