import { http } from '../../services';
import { API_BASE_URL } from '../../../common/constants/constants';

export const requestGetSharedAccounts = async () => {
  const data = await http(`${API_BASE_URL}/share/member`, {
    method: 'GET',
  });

  return data.body;
};

export const deleteTokenRequest = (tokenId: string): Promise<string> =>
  http(`${API_BASE_URL}/user/token/${tokenId}`, {
    method: 'DELETE',
  });

export const shareAccount = async (params: any) => {
  try {
    const data = await http(`${API_BASE_URL}/share/member`, {
      method: 'POST',
      body: JSON.stringify(params),
    });
    return data.body;
  } catch (error) {
    throw error;
  }
};

export const requestGetMySharedAccounts = async () => {
  try {
    const data = await http(`${API_BASE_URL}/share/my-members`, {
      method: 'GET',
    });
    return data.body;
  } catch (error) {
    throw error;
  }
};

export const requestDeleteMySharedAccounts = async (profileId: string) => {
  try {
    const data = await http(`${API_BASE_URL}/share/${profileId}`, {
      method: 'DELETE',
    });
    return data.body;
  } catch (error) {
    throw error;
  }
};

export const sendUpdateAnalytics = (type: string): void => {
  http(`${API_BASE_URL}/analytics/updates`, {
    method: 'POST',
    body: JSON.stringify({ type }),
  }).catch(() => { });
};

export const checkCheckoutSession = async (sessionId: string) =>
  http(`${API_BASE_URL}/billing/proxies/check_checkout_session`, {
    method: 'POST',
    body: JSON.stringify({ sessionId }),
  }).then((res: any) => res.body);
