import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconEyeOpen: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props} iconType='stroke'>
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <path d='M0.5 8C0.5 8 3.5 2.5 8 2.5C12.5 2.5 15.5 8 15.5 8C15.5 8 12.5 13.5 8 13.5C3.5 13.5 0.5 8 0.5 8Z'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M8 10.5C9.38071 10.5 10.5 9.38071 10.5 8C10.5 6.61929 9.38071 5.5 8 5.5C6.61929 5.5 5.5 6.61929 5.5 8C5.5 9.38071 6.61929 10.5 8 10.5Z'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  </IconWrapper>
);
