import { format } from 'date-fns';
import { useAtomValue } from 'jotai';
import React, { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import TooltipCustom from '../../../ui/tooltip-custom';
import { ITableCell } from '../interfaces';
import { TextCell } from '../table/styles';

export type DateCellField = 'updatedAt' | 'lastActivity' | 'createdAt';

interface IDateCell extends ITableCell {
  field: DateCellField;
  hasTooltip?: boolean;
}

const DateCell: FC<IDateCell> = (props) => {
  const { profileAtom, field, hasTooltip } = props;

  const [anchorElTooltip, setAnchorElTooltip] = useState<HTMLElement | null>(null);
  const [tooltipValue, setTooltipValue] = useState<string>('');

  const { t: translation } = useTranslation();

  const profile = useAtomValue(profileAtom);
  const date = profile[field];

  const openTooltip = (event: React.MouseEvent<HTMLSpanElement>): void => {
    if (!(hasTooltip && date)) {
      return;
    }

    setAnchorElTooltip(event.currentTarget);
    setTooltipValue(translation('tooltip.profilesTable.lastLaunched') + ' ' + format(new Date(date), 'MMMM d, yyyy pp'));
  };

  const closeTooltip = (): void => {
    setTooltipValue('');
    setAnchorElTooltip(null);
  };

  if (!date) {
    return null;
  }

  if (NEW_FEATURES.header) {
    const isTooltipShown = hasTooltip && anchorElTooltip && tooltipValue;

    return (
      <TextCell>
        <span
          style={{ cursor: hasTooltip ? 'pointer': 'default' }}
          onMouseEnter={openTooltip}
          onMouseLeave={closeTooltip}
        >
          {format(new Date(date), 'MMM d, yyyy p')}
        </span>
        {isTooltipShown ? (
          <TooltipCustom
            anchorEl={anchorElTooltip}
            value={tooltipValue}
          />
        ) : null}
      </TextCell>
    );
  }

  return (
    <span>
      {format(new Date(date), 'dd/MM/yyyy')}
    </span>
  );
};

export default memo(DateCell);
