interface ICalculateTargetOrder {
  orderList: Array<{ name?: string; order?: number }>;
  fromIndex: number;
  toIndex: number;
  movedIndexes: number[];
  step: number;
  baseline: number;
}

export const calculateTargetOrder = (opts: ICalculateTargetOrder): [number, number] => {
  const { orderList, fromIndex, toIndex, movedIndexes, step, baseline } = opts;

  if (!orderList.length) {
    return [0, 0];
  }

  if (toIndex <= 0) {
    const highOrder = orderList[0].order ?? baseline;

    return [highOrder + step, highOrder];
  }

  if (toIndex >= orderList.length - 1) {
    const lowOrder = orderList[orderList.length - 1].order ?? baseline;

    return [lowOrder, lowOrder - step];
  }

  const dropOffset = 1;
  let lowIndex = toIndex;
  let highIndex = toIndex + dropOffset;
  if (toIndex < fromIndex) {
    lowIndex -= dropOffset;
    highIndex -= dropOffset;
  }

  while (movedIndexes.includes(lowIndex) && lowIndex > 0) {
    lowIndex -= dropOffset;
  }

  while (movedIndexes.includes(highIndex) && highIndex < orderList.length) {
    highIndex += dropOffset;
  }

  const targetLowerBound = orderList[lowIndex]?.order ?? 0;
  const targetUpperBound = orderList[highIndex]?.order ?? baseline;

  return [targetLowerBound, targetUpperBound];
};
