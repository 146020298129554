import { atom, getDefaultStore, useAtomValue } from 'jotai';
import { ILaunchProfileOrbita } from '../features/quickProfiles/components/interfaces/launch-profile-orbita.interface';
import { getStatusCellElement } from '../features/quickProfiles/status-cell/utils';
import { getIsStateColumnPresented } from './is-state-column-presented.atom';

export interface LocationUnmaskedHintState {
  isOpen: boolean;
  profileId: string | null;
  anchorEl: HTMLElement | null;
  runCallback?: (opts: ILaunchProfileOrbita) => void;
}

const openedLocationIsUnmaskedHintAtom = atom<LocationUnmaskedHintState>({
  isOpen: false,
  profileId: null,
  anchorEl: null,
  runCallback: undefined,
});

export const useOpenedLocationIsUnmaskedHint = (): LocationUnmaskedHintState => {
  return useAtomValue(openedLocationIsUnmaskedHintAtom);
};

export const getOpenedLocationIsUnmaskedHint = (): LocationUnmaskedHintState => {
  const store = getDefaultStore();
  return store.get(openedLocationIsUnmaskedHintAtom);
};

export const setOpenedLocationIsUnmaskedHint = (state: Partial<LocationUnmaskedHintState>): void => {
  const store = getDefaultStore();
  store.set(openedLocationIsUnmaskedHintAtom, prev => ({
    ...prev,
    ...state,
  }));
};

export const closeLocationIsUnmasked = (): void => {
  const store = getDefaultStore();
  store.set(openedLocationIsUnmaskedHintAtom, prev => ({
    ...prev,
    isOpen: false,
    profileId: null,
    anchorEl: null,
    runCallback: undefined,
  }));
};

export const openLocationIsUnmasked = (
  profileId: string,
  fallbackAnchorEl: HTMLElement,
  runCallback?: (opts: ILaunchProfileOrbita) => void,
): void => {
  const store = getDefaultStore();
  const isStateColumnPresented = getIsStateColumnPresented();

  let anchorEl: HTMLElement | null = fallbackAnchorEl;

  if (isStateColumnPresented) {
    const statusCellElement = getStatusCellElement(profileId);

    if (statusCellElement) {
      anchorEl = statusCellElement;
    }
  }

  store.set(openedLocationIsUnmaskedHintAtom, {
    isOpen: true,
    profileId,
    anchorEl,
    runCallback,
  });
};
