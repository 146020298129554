import styled from '@emotion/styled';
import { Radio } from 'antd';

export const RadioButton = styled(Radio.Button)`
  padding: 6px 16px;
  height: 32px;

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  user-select: none;
`;

export const RemoveMemberButton = styled('button')`
  outline: none;
  background: none;
  border: none;
  padding: 0;
  user-select: none;
  cursor: pointer;

  font-family: 'Roboto';
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;

  color: var(--222222-members-permissions-select);
  opacity: 0.5;
`;
