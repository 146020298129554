import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { getAllProfilesFolderId } from './folders/all-profiles-folder-id.atom';
import { getFoldersList } from './folders/folders-list.atom';
import { IFolder } from '../interfaces';
import { NEW_FEATURES } from './feature-toggle/new-features';
import { sendActionAnalytics } from '../features/common/api';
import { E_ANALYTICS_ACTIONS } from '../../common/constants/analytics';
import { performGlobalDeltaSync } from '../object-pool/delta-sync/perform-global-delta-sync';
import { closeProfilesSettings } from './profiles-settings-atom';
import { reloadProfilesTableGroups, resetProfilesTableGroups } from './profiles-list.atom';
import { reloadProfilesTableProfiles } from './profiles-table/profiles-query';

export const selectedFolderIdAtom = atom<string|null>(null);

export const getSelectedFolderId = (): string|null => {
  const selectedFolderId = getDefaultStore().get(selectedFolderIdAtom);
  if (selectedFolderId) {
    return getDefaultStore().get(selectedFolderIdAtom);
  }

  const selectedFolderName = localStorage.getItem('SelectedFolder');

  return getFoldersList().find(folder => folder.name === selectedFolderName)?.id || null;
};

export const useSelectedFolderId = (): string|null => useAtomValue(selectedFolderIdAtom);
export const setSelectedFolderId = (newSelectedFolderId: string|null = null): void => {
  getDefaultStore().set(selectedFolderIdAtom, newSelectedFolderId);

  const selectedFolder = getFoldersList().find(folder => folder.id === newSelectedFolderId);
  let selectedFolderToSaveLocalStorage = selectedFolder?.name;
  if (!selectedFolder) {
    selectedFolderToSaveLocalStorage = '';
  }

  localStorage.setItem('SelectedFolder', selectedFolderToSaveLocalStorage);
};

export const getSelectedFolderName = (): string => {
  const selectedFolder = getFoldersList().find(folder => folder.id === getSelectedFolderId());

  return selectedFolder?.name || '';
};

export const getHasSelectedFolder = (): boolean => {
  const selectedFolderId = getSelectedFolderId();
  const allProfilesFolderId = getAllProfilesFolderId();
  let hasSelectedFolder = !!selectedFolderId;
  if (hasSelectedFolder && allProfilesFolderId) {
    hasSelectedFolder = selectedFolderId !== allProfilesFolderId;
  }

  return hasSelectedFolder;
};

export const resetSelectedFolderId = (): void => {
  localStorage.removeItem('SelectedFolder');
  const allProfilesFolderId = getAllProfilesFolderId();
  if (allProfilesFolderId) {
    setSelectedFolderId(allProfilesFolderId);
  } else {
    setSelectedFolderId(null);
  }
};

export const toggleSelectedFolder = (selectedFolder?: IFolder): void => {
  const isSystemFolder = !selectedFolder || selectedFolder.systemFolder
  const folderId = !isSystemFolder ? selectedFolder.id : null;
  const folderNameToSave = !isSystemFolder ? selectedFolder.name : ''
  setSelectedFolderId(folderId);

  if (NEW_FEATURES.objectPool && folderNameToSave) {
    sendActionAnalytics(E_ANALYTICS_ACTIONS.visitedFolder, { actionInfo: folderNameToSave });
  }

  if (NEW_FEATURES.objectPool) {
    if (isSystemFolder) {
      performGlobalDeltaSync();
    }
  }

  closeProfilesSettings();
  resetProfilesTableGroups();
  reloadProfilesTableProfiles();
  reloadProfilesTableGroups();
};
