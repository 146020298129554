import moment from 'moment';

import { determineShouldChangeProtocol } from '../../../../common/proxy/utils';
import { isNotNull } from '../../../../common/typescript/predicates';
import { updateProxyAndProfileProxyData } from '../../../features/common/update-local-profile-data';
import { getProxyStatusParams, IStatusParams } from '../../../features/proxy/proxy-helpers';
import { generateArtificialProxyId } from '../../../features/proxy/utils/proxy-id';
import { IProfile, IProxy } from '../../../interfaces';
import { setProxyStatuses } from '../../proxies.context/api';
import { ISetProxyStatusParams } from '../../proxies.context/interfaces/ISetProxyStatusParams';
import { PROXY_CHECK_TOOLTIP_LOCATIONS } from '../proxy-check/constants';
import { showProxyCheckTooltip } from '../proxy-check/proxy-check-tooltip.atom';
import { addProxyStatuses, removeProxyStatuses } from '../proxy-check/proxy-statuses.atom';
import { ProxyFormProxy, setProxyForm } from '../proxy-form.atom';
import { getProxyList, updateProxyInListById, updateProxyItem } from '../proxy-list.atom';

type ProxyCheckResult = {
  id: string;
  status: boolean;
  country?: string;
  city?: string;
  error?: string;
  checkDate: number;
  lastIp?: string;
  mode?: string;
}

export const checkArtificialProxyInForm = async (proxyFields: ProxyFormProxy): Promise<IStatusParams> => {
  const proxyMode = proxyFields.mode;
  const artificialProxyId = generateArtificialProxyId(proxyMode);

  const artificialProxy: IProxy = {
    id: artificialProxyId,
    profilesCount: 0,
    mode: proxyMode,
    customName: proxyFields.customName,
    host: proxyFields.host,
    port: proxyFields.port,
    username: proxyFields.username,
    password: proxyFields.password,
    changeIpUrl: proxyFields.changeIpUrl,
    createdAt: moment().toDate(),
  };

  addProxyStatuses([artificialProxy]);
  setProxyForm(artificialProxy);
  const statusParams = await getProxyStatusParams(artificialProxy);
  const checkedProxy: IProxy = {
    ...artificialProxy,
    ...statusParams,
    checkDate: moment().toDate(),
  };

  removeProxyStatuses([artificialProxy]);
  showProxyCheckTooltip({
    profileIds: [],
    proxies: [checkedProxy],
    view: PROXY_CHECK_TOOLTIP_LOCATIONS.proxyManagerEditForm,
    timeout: 2000,
  });

  setProxyForm(checkedProxy);

  return statusParams;
};

export const checkProfilesProxies = async (newProfiles: IProfile[]): Promise<void> => {
  const profileProxiesData = newProfiles.map(profile => ({ profileId: profile.id, proxy: profile.proxy }));
  const proxyList = getProxyList();

  const checkProxiesPromises =
    profileProxiesData.map(async ({ profileId, proxy }) => {
      if (!proxy.id) {
        return null;
      }

      const currentProxyFromList = proxyList.find(proxyFromList => proxyFromList.id === proxy.id);
      if (!currentProxyFromList || currentProxyFromList.checkDate) {
        return null;
      }

      const statusParams = await getProxyStatusParams({ ...proxy });

      const newProxyData: IProxy = {
        ...proxy,
        ...statusParams,
        checkDate: moment().toDate(),
      };

      updateProxyItem(newProxyData);

      if (!statusParams) {
        return null;
      }

      const result: ProxyCheckResult = {
        id: proxy.id,
        status: statusParams.status,
        country: statusParams.country || proxy.country,
        city: statusParams.city || proxy.city,
        error: statusParams.error,
        checkDate: moment().unix(),
        lastIp: statusParams.origin,
      };

      if (determineShouldChangeProtocol(proxy.mode, statusParams.mode)) {
        result.mode = statusParams.mode;
      }

      updateProxyAndProfileProxyData({
        currentProxyData: proxy,
        newProxyData,
        statusParams,
        profileId,
      });

      return result;
    });

  const proxiesChecked = await Promise.all(checkProxiesPromises);
  setProxyStatuses(proxiesChecked.filter(isNotNull)).catch(() => null);
};
