import { ITag } from '../../features/tags/interfaces/tag.interface';
import { GroupFilterType, GroupHeader } from '../../interfaces/group-header.interface';
import { IGenericFolder, getFoldersList } from '../folders/folders-list.atom';
import { setProfilesTableGroupHeaders } from '../profiles-list.atom';
import { getSelectedFolderId } from '../selected-folder.atom';
import { getTagsList } from '../tags/tags-list.atom';

export const NO_CUSTOM_STATUS_GROUP_ID = 'no-custom-status';
export const NO_FOLDER_GROUP_ID = 'no-folder';

export const isNoEntitiesGroupHeader = (groupHeader: GroupHeader): boolean =>
  [NO_CUSTOM_STATUS_GROUP_ID, NO_FOLDER_GROUP_ID].includes(groupHeader.id);

const makeGroupHeadersForCustomStatuses = (allTags: ITag[]): GroupHeader[] => allTags
  .reduce<GroupHeader[]>((acc, tag) => {
    if (tag.field !== 'custom-status') {
      return acc;
    }

    acc.push({
      id: tag.id,
      isGroupHeader: true,
      filter: { type: 'custom-status', customStatusId: tag.id },
      isOpen: true,
      loadingStatus: 'unloaded',
      totalProfiles: null,
    });

    return acc;
  }, [])
  .concat([{
    id: NO_CUSTOM_STATUS_GROUP_ID,
    isGroupHeader: true,
    filter: { type: 'custom-status', customStatusId: null },
    isOpen: true,
    loadingStatus: 'unloaded',
    totalProfiles: null,
  }]);

const makeGroupHeadersForFolders = (folders: IGenericFolder[]): GroupHeader[] => folders
  .reduce<GroupHeader[]>((acc, folder) => {
    if (folder.shared) {
      return acc;
    }

    acc.push({
      id: folder.id,
      isGroupHeader: true,
      filter: { type: 'folder', folderId: folder.id },
      isOpen: true,
      loadingStatus: 'unloaded',
      totalProfiles: null,
    });

    return acc;
  }, [])
  .concat([{
    id: NO_FOLDER_GROUP_ID,
    isGroupHeader: true,
    filter: { type: 'folder', folderId: null },
    isOpen: true,
    loadingStatus: 'unloaded',
    totalProfiles: null,
  }]);

export const generateGroupHeaders = (groupBy?: GroupFilterType | null): GroupHeader[] | null => {
  let groupHeaders = null;
  if (groupBy === 'custom-status') {
    const allTags = getTagsList();
    groupHeaders = makeGroupHeadersForCustomStatuses(allTags);
  }

  if (groupBy === 'folder') {
    const allFolders = [...getFoldersList()];
    const selectedFolderId = getSelectedFolderId();
    const selectedFolderIdx = allFolders.findIndex((folder) => folder.id.toString() === selectedFolderId);
    if (selectedFolderIdx !== -1) {
      const [selectedFolder] = allFolders.splice(selectedFolderIdx, 1);
      allFolders.unshift(selectedFolder);
    }

    groupHeaders = makeGroupHeadersForFolders(allFolders);
  }

  return groupHeaders;
};
