import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { ProxyModeSelector } from './proxy-mode-selector';
import { CheckProxyButtonWrapper, HostPortSplitter, ProxyFormInputEl } from './styles';
import { PROXY_PAGE } from '../../../../../common/constants/routes';
import { IProxy } from '../../../../interfaces';
import { NEW_FEATURES } from '../../../../state/feature-toggle/new-features';
import { PROXY_CHECK_TOOLTIP_LOCATIONS } from '../../../../state/proxy/proxy-check/constants';
import { useIsProxyChecking } from '../../../../state/proxy/proxy-check/proxy-statuses.atom';
import { setProxyForm } from '../../../../state/proxy/proxy-form.atom';
import { pasteProxyToForm } from '../../../../state/proxy/proxy-operations/paste-proxies-operations';
import { IconProxyPaste } from '../../../../ui/gologin-header/icons/icon-proxy-paste';
import CheckProxyBtn from '../../check-proxy-button';
import ProxyFlag, { SMALL_FLAG_HEIGHT } from '../../proxy-flag';
import ControlIconButton, { CONTROL_BUTTON_TOOLTIP_KEY } from '../../proxy-page/proxy-settings-modal/control-icon-button';
import { ProxyFlagBlockEl } from '../proxy-list/styles';

type ProxyEditFormFieldSlot = React.FC<{ style?: React.CSSProperties }>;

type ProxyEditFormFieldsProps = {
  formValues: IProxy;
  currentProfileId: string | null;
  currentProxy?: IProxy;
  Row: ProxyEditFormFieldSlot;
  RowKey: ProxyEditFormFieldSlot;
  RowValue: ProxyEditFormFieldSlot;
  rowStyles?: React.CSSProperties;
}

const ProxyEditFormFields: React.FC<ProxyEditFormFieldsProps> = (props) => {
  const { formValues, currentProfileId, Row, RowKey, RowValue, currentProxy, rowStyles } = props;

  const isProxyChecking = useIsProxyChecking(currentProxy, currentProfileId);

  const location = useLocation();
  const { t: translation } = useTranslation();

  const handleChangeMode = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setProxyForm({
      ...formValues,
      mode: event.target.value,
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setProxyForm({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  const handlePaste = async (event: React.ClipboardEvent<HTMLInputElement>): Promise<void | true> => {
    const clipboardText = await navigator.clipboard.readText();
    const pasteProxyRes = await pasteProxyToForm(formValues, clipboardText);

    if (pasteProxyRes) {
      event.preventDefault();
    }
  };

  const handlePasteChangeIpUrl = async (event: React.ClipboardEvent<HTMLInputElement>): Promise<void> => {
    const clipboardText = event.clipboardData.getData('Text');
    const isPossibleUrl = clipboardText.startsWith('http://') || clipboardText.startsWith('https://');
    if (isPossibleUrl) {
      // without event.preventDefault - pastes into field, without parsing
      return;
    }

    const pasteProxyRes = await pasteProxyToForm(formValues, clipboardText);
    if (pasteProxyRes) {
      event.preventDefault();
    }
  };

  return (
    <>
      <Row style={rowStyles}>
        <RowKey>
          <Trans i18nKey='proxies.formFields.type' />
        </RowKey>
        <RowValue>
          <ProxyModeSelector
            currentMode={formValues.mode}
            handleChangeMode={handleChangeMode}
          />
        </RowValue>
      </Row>
      <Row style={rowStyles}>
        <RowKey>
          <Trans i18nKey='proxies.formFields.customName' />
        </RowKey>
        <RowValue>
          <ProxyFormInputEl
            value={formValues.customName}
            name='customName'
            onChange={handleChange}
            onPaste={handlePaste}
            placeholder={translation('proxies.formFields.customNamePlaceholder') || ''}
          />
        </RowValue>
      </Row>
      <Row style={rowStyles}>
        <RowKey style={{ width: 40 }}>
          <Trans i18nKey='proxies.formFields.host' />
        </RowKey>
        <RowValue style={location.pathname !== PROXY_PAGE ? {
          marginLeft: NEW_FEATURES.header ? 82 : 57,
        } : {
          marginLeft: NEW_FEATURES.header && formValues.country ? -24 : 0,
          alignItems: 'center',
        }}>
          <ProxyFlagBlockEl
            newStyle={!!NEW_FEATURES.header}
            style={{
              opacity: (formValues.host && formValues.port) ? 1 : 0,
            }}
          >
            <CheckProxyButtonWrapper>
              <CheckProxyBtn
                proxy={formValues}
                profileId={currentProfileId || ''}
                proxyTooltipView={PROXY_CHECK_TOOLTIP_LOCATIONS.proxyManagerEditForm}
              />
            </CheckProxyButtonWrapper>
            {formValues.country ? (
              <ProxyFlag
                countryCode={formValues.country}
                height={SMALL_FLAG_HEIGHT}
              />
            ) : null}
          </ProxyFlagBlockEl>
          <ProxyFormInputEl
            value={formValues.host}
            name='host'
            onChange={handleChange}
            onPaste={handlePaste}
            placeholder={translation('proxies.formFields.hostPlaceholder') || ''}
            inputWidth={111}
          />
          <HostPortSplitter>
            :
          </HostPortSplitter>
          <ProxyFormInputEl
            value={formValues.port}
            name='port'
            onChange={handleChange}
            onPaste={handlePaste}
            placeholder={translation('proxies.formFields.portPlaceholder') || ''}
            inputWidth={58}
          />
          {location.pathname === PROXY_PAGE ? (
            <ControlIconButton
              handleClick={handlePaste}
              tooltipTranslationKey={CONTROL_BUTTON_TOOLTIP_KEY.pasteProxy}
              wrapperStyles={{ position: 'relative', right: 8, marginRight: 8 }}
              Icon={IconProxyPaste}
            />
          ) : null}
        </RowValue>
      </Row>
      <Row style={rowStyles}>
        <RowKey>
          <Trans i18nKey='proxies.formFields.username' />
        </RowKey>
        <RowValue>
          <ProxyFormInputEl
            value={formValues.username}
            autoComplete='new-password'
            name='username'
            onChange={handleChange}
            onPaste={handlePaste}
            placeholder={translation('proxies.formFields.usernamePlaceholder') || ''}
          />
        </RowValue>
      </Row>
      <Row style={rowStyles}>
        <RowKey>
          <Trans i18nKey='proxies.formFields.password' />
        </RowKey>
        <RowValue>
          <ProxyFormInputEl
            value={formValues.password}
            autoComplete='new-password'
            name='password'
            type='text'
            onChange={handleChange}
            onPaste={handlePaste}
            placeholder={translation('proxies.formFields.passwordPlaceholder') || ''}
            data-lpignore="true"
          />
        </RowValue>
      </Row>
      <Row style={rowStyles}>
        <RowKey>
          <Trans i18nKey='proxies.formFields.changeIpUrl' />
        </RowKey>
        <RowValue>
          <ProxyFormInputEl
            value={formValues.changeIpUrl}
            name='changeIpUrl'
            onChange={handleChange}
            onPaste={handlePasteChangeIpUrl}
            placeholder={translation('proxies.formFields.changeIpUrlPlaceholder') || ''}
          />
        </RowValue>
      </Row>
    </>
  );
};

export default ProxyEditFormFields;
