import { atom, getDefaultStore, useAtomValue } from 'jotai';

type UserBasicInfo = {
  email?: string;
  createdAt?: Date;
  isEmailConfirmed?: boolean;
  isCorrectlyRegistered?: boolean;
  invitedByLink?: string;
  invitedByUser?: string;
};

const userBasicInfoAtom = atom<UserBasicInfo|null>({});

export const getUserBasicInfo = (): UserBasicInfo|null => getDefaultStore().get(userBasicInfoAtom) || null;
export const useUserBasicInfo = (): UserBasicInfo|null => useAtomValue(userBasicInfoAtom);
export const setUserBasicInfo = (newUserInfo: UserBasicInfo|null): void => {
  getDefaultStore().set(userBasicInfoAtom, newUserInfo);
};

export const updateUserBasicInfo = (newUserInfo: UserBasicInfo): void => {
  const oldUserBasicInfo = getDefaultStore().get(userBasicInfoAtom);
  setUserBasicInfo({ ...oldUserBasicInfo, ...newUserInfo })
};

export const resetUserBasicInfo = (): void => setUserBasicInfo({});
