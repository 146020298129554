import React, { FC, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Layout } from 'antd';
import AppInstaller from './header-app-installer';

const { Header } = Layout;

const Container = styled(Header)`
  background: var(--00B86D);
  box-shadow: inset -500px 20px 150px var(--0057FF26);
  height: 80px;
  align-items: center;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 420px) {
    padding-left: 4%;
    width: 100%;
  }
  @media screen and (max-width: 325px) {
    padding-left: 1%;
    width: 100%;
  }
`;

const HeaderContainer: FC = ({ children }) => {
  const [installerClosed, closeInstallerState] = useState(
    !!localStorage.getItem('@gologin:installer_closed')
  );

  const closeInstaller = () => {
    closeInstallerState(true);

    localStorage.setItem('@gologin:installer_closed', 'true');
  };
  const [windowWidth, changwindowWidth] = useState(0);

  const handleResize = () => {
    changwindowWidth(window.innerWidth)
  };
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
  }, []);
  useEffect(() => {
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  return (
    <>
      {windowWidth>500 && !window.require && !installerClosed && (
        <AppInstaller closeInstaller={closeInstaller} />
      )}
      <Container>{children}</Container>
    </>
  );
};

export default HeaderContainer;
