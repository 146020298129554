import { createHashHistory, createBrowserHistory } from 'history';
import { configureStore } from 'redux-starter-kit';

import { configureHttp } from './http';
import { createMiddleware, createReducer } from './redux';

const isElectron = !!window.require;
const history = isElectron ? createHashHistory() : createBrowserHistory();

const reducer = createReducer(history);
const middleware = createMiddleware(history);

const store = configureStore({
  reducer,
  middleware,
});

const http = configureHttp(store);
export { store, http, history };
