import { message } from 'antd';
import React, { FC, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { browserUpdaterContext, userContext } from '../../state';

let ipcRenderer: Electron.IpcRenderer;
const isElectron = !!window.require;
if (isElectron) {
  ipcRenderer = window.require('electron').ipcRenderer;
}

const BrowserUpdater: FC = () => {
  const {
    updateBrowserUpdater,
    browserUpdating,
  } = useContext(browserUpdaterContext);

  const { t: translation } = useTranslation();

  const startBrowserUpdating = async (): Promise<void> => {
    updateBrowserUpdater({
      initialized: true,
      browserUpdating: true,
      showOrbitaDialog: false,
    });
    ipcRenderer.invoke('download-browser', {});
  };

  const getFormattedErrorMessage = (errorMessage: string): string => {
    switch(true) {
      case (errorMessage.includes('EPERM')):
        return 'notifications.error.permission';
      default:
        return errorMessage;
    }
  };

  useEffect(() => {
    ipcRenderer && ipcRenderer.on('download-browser', (event, res) => {
      const { result, progressState = {}, isRetrying = false, isDiscSpaceError, unsupportedWinVersion, orbitaVersion } = res;
      const step = Number.isInteger(progressState.step) ? progressState.step : null;
      const parsedOrbitaVersion = (version: string): string => {
        if (version === '10') {
          version = '100';
        }

        if (version === '13') {
          version = '103';
        }

        return version;
      };

      let objToUpdate: any = {
        initialized: true,
        downloadProgressStatus: 'progress',
        versionLoadingNow: parsedOrbitaVersion(orbitaVersion),
      };

      if (isDiscSpaceError || unsupportedWinVersion) {
        objToUpdate.isSpaceError = isDiscSpaceError;
        objToUpdate.unsupportedWinVersion = unsupportedWinVersion;
        objToUpdate.browserUpdating = true;
        updateBrowserUpdater(objToUpdate);

        return;
      }

      if (isRetrying) {
        objToUpdate.downloadProgressStatus = 'retrying';
        objToUpdate.browserUpdating = true;
        updateBrowserUpdater(objToUpdate);

        return;
      }

      if (Number.isInteger(step)) {
        const { total = 0, transferred = 0, percent = 0 } = progressState;
        const totalMB = Math.round(total / 1024 / 1024);
        const transferredMB = Math.round(transferred / 1024 / 1024);
        const percents = Math.round((percent || 0) * 100);

        objToUpdate = {
          ...objToUpdate,
          currentStep: step,
          browserUpdating: true,
          downloadProgressStr: percents <= 100 && !step ? `${percents}% (${transferredMB}/${totalMB} MB)` : '',
        };
      }

      if (!progressState.inProgress) {
        objToUpdate = {
          ...objToUpdate,
          browserUpdating: false,
          downloadProgressStatus: result ? 'finish' : 'error',
        };

        const formattedError = getFormattedErrorMessage(res.errorMessage);

        if (res.errorMessage) {
          message.error(translation(formattedError));
        }
      }

      updateBrowserUpdater(objToUpdate);
    });

    return () => {
      ipcRenderer && ipcRenderer.removeAllListeners('download-browser');
    };
  }, []);

  return null;
};

export default React.memo(BrowserUpdater);
