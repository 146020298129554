import styled from '@emotion/styled';
import { Modal } from '@material-ui/core';

export const CustomModal = styled(Modal)`
  display: flex;
  align-items: start;
  justify-content: center;
  border-radius: 4px;
  overflow: auto;

  .MuiBackdrop-root {
    background-color: transparent;
    position: absolute;
  },
`;

export const ModalContent = styled('div')<{ hasTitle?: boolean }>`
  background: var(--FFFFFF);
  border-radius: 8px;
  overflow: hidden;
  outline: none;
  margin-top: 100px;
  margin-bottom: 24px;
`;

export const ModalBody = styled('div')<{ width: string }>`
  background: var(--FFFFFF);
  color: var(--000000D9-antd);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  padding: 24px;
  border-radius: 4px;
  width: ${({ width }): string => width};
`;

export const ModalHeader = styled('div')`
  background: var(--FFFFFF);
  color: var(--000000D9-antd);
  border-bottom: 1px solid var(--E8E8E8-antd);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  padding: 16px 24px;
  justify-content: space-between;
  display: flex;
  align-items: center;
`;

export const ModalFooter = styled('div')`
  border-top: 1px solid var(--E8E8E8-antd);
  padding: 10px 24px;
  text-align: right;
  background: transparent;
`;

export const ModalContainer = styled('div')`
  outline: none;
`;
