import { LocalizationErrorMessages } from '../../../../common/constants/localization-error-messages';
import { RequestErrorMessages } from '../../../../common/constants/request-error-messages';
import { updateProxyRequest } from '../../../features/proxy/api';
import { IProxyCredentialsNew } from '../../../features/proxy/components/interfaces/proxy-credentials-new.interface';
import { handleActiveProxiesAlreadyArchived, makeArchivedProxyFromProxy, restoreProxy } from '../../../features/proxy/proxy-helpers';
import { updateProfileProxy } from '../../../features/quickProfiles/api';
import { IProxy } from '../../../interfaces';
import { getProxyRequest } from '../../proxies.context/api';
import { getProxyList } from '../proxy-list.atom';

type UpdateProxyParams = {
  proxyId: string;
  proxy: IProxyCredentialsNew;
  profileId?: string;
};

type UpdateProfileProxyParams = {
  profileId: string;
  proxy: IProxy;
};

type UpdateProxyResultError = {
  message: string;
}

export type ProxyRequestError = {
  error: UpdateProxyResultError;
}

export const updateProxyWithErrorHandling = async (params: UpdateProxyParams): Promise<IProxy | ProxyRequestError> => {
  const { proxyId, proxy, profileId } = params;
  const updateProxyResponse: IProxy|ProxyRequestError = await updateProxyRequest({ proxyId, proxy, profileId }).catch((error) => {
    const { body } = error || {};
    let { message = '' } = body || {};
    if (message && message === RequestErrorMessages.ProxyHasBeenDeleted) {
      const archivedProxyInProxyList = getProxyList().find((listProxy) => listProxy.id === proxyId);
      const {
        country: archivedProxyCountry = '',
        customName: archivedProxyCustomName = '',
        host: archivedProxyHost = '',
      } = archivedProxyInProxyList || {};

      const { customName = '', host = '' } = proxy || {};
      const name = archivedProxyCustomName || archivedProxyHost || customName || host || '';
      const archivedProxy = { id: proxyId, country: archivedProxyCountry, name };
      handleActiveProxiesAlreadyArchived([archivedProxy], true);
      message = LocalizationErrorMessages.ProxyDeletedError;
    }

    return {
      error: {
        message: message || LocalizationErrorMessages.SomethingWentWrongAgainLater,
      },
    };
  });

  return updateProxyResponse;
};

export const updateProfileProxyWithErrorHandling = async (params: UpdateProfileProxyParams): Promise<IProxy | ProxyRequestError> => {
  const { profileId, proxy } = params;
  const updateProfileProxyResponse: IProxy|ProxyRequestError = await updateProfileProxy(profileId, proxy).catch((error) => {
    const { body } = error || {};
    let { message = '' } = body || {};
    if (message && message === RequestErrorMessages.ProxyNotFound) {
      const archivedProxy = makeArchivedProxyFromProxy(proxy);
      restoreProxy(archivedProxy);
      message = LocalizationErrorMessages.ProxyNotFoundError;
    }

    return {
      error: {
        message: message || LocalizationErrorMessages.SomethingWentWrongAgainLater,
      },
    };
  });

  return updateProfileProxyResponse;
};

export const getProxyWithErrorHandling = async (proxyId: string): Promise<IProxy | ProxyRequestError> =>
  getProxyRequest(proxyId).catch((error) => {
    const { body } = error || {};
    const { message = '' } = body || {};

    return {
      error: {
        message: message || LocalizationErrorMessages.SomethingWentWrongAgainLater,
      },
    };
  });
