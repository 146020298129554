import React from 'react';
import { useTranslation } from 'react-i18next';

import { Folder } from './folder';
import { useFoldersList } from '../../../../../state/folders/folders-list.atom';
import { GologinInput } from '../../../../../ui/styles/input';

interface IFolders {
  searchFolderName: string;
  handleSearchInputChange: React.FormEventHandler<HTMLInputElement>;
}

const Folders: React.FC<IFolders> = (props) => {
  const { searchFolderName, handleSearchInputChange } = props;
  const { t: translation } = useTranslation();

  const foldersList = useFoldersList();

  const renderFolders = (): (JSX.Element | null)[] => foldersList.filter(Boolean).map((folder, index) => {
    if (folder.shared || !folder.name?.includes(searchFolderName)) {
      return null;
    }

    return (
      <Folder
        name={folder.name}
        key={index}
      />
    );
  });

  return (
    <>
      <GologinInput
        onChange={handleSearchInputChange}
        placeholder={translation('foldersManagerModal.search.placeholder') || ''}
        value={searchFolderName}
        style={{ marginBottom: 7 }}
      />
      {renderFolders()}
    </>
  );
};

export default Folders;
