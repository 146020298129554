import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconThumbUp: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_17950_58982)'>
        <path d='M3.5 7.5H0.5V15.5H3.5V7.5Z' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M5.5 15.5H12.4C12.855 15.4999 13.2963 15.3447 13.6512 15.06C14.0061 14.7753 14.2533 14.3781 14.352 13.934L15.463 8.934C15.5281 8.64133 15.5265 8.33778 15.4584 8.04579C15.3903 7.75381 15.2574 7.48088 15.0696 7.24717C14.8818 7.01347 14.6439 6.82498 14.3734 6.69565C14.1029 6.56632 13.8068 6.49945 13.507 6.5H9.5V2.5C9.5 1.96957 9.28929 1.46086 8.91421 1.08579C8.53914 0.710714 8.03043 0.5 7.5 0.5L5.5 6.5' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_17950_58982'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  </IconWrapper>
);
