import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { sendUpdateAnalytics } from '../../../../ui/Header/api';
import GologinModal from '../../../../ui/gologin-modal';
import { CancelButton, SaveButton } from '../profile-bookmarks/styles';

interface IUpdateAppNotificationModalProps {
  isModalVisible: boolean;
  setIsModalVisible: (arg: boolean) => void;
  setUpdatingLoader?: (arg: boolean) => void;
}

let ipcRenderer: Electron.IpcRenderer;

const isElectron = !!window.require;
if (isElectron) {
  ({ ipcRenderer } = window.require('electron'));
}

export const UpdateAppNotificationModal: FC<IUpdateAppNotificationModalProps> = (props) => {
  const { isModalVisible, setIsModalVisible, setUpdatingLoader } = props;

  const { t: translation } = useTranslation();

  const closeImmediately = async (): Promise<void> => {
    await ipcRenderer.invoke('close-all-profiles-without-save');
    setIsModalVisible(false);
    await sendUpdateAnalytics('app');
    ipcRenderer.invoke('install-app-updates');
  };

  const saveThenUpdate = async (): Promise<void> => {
    setIsModalVisible(false);
    await sendUpdateAnalytics('app');
    await ipcRenderer.invoke('close-all-profiles-with-save');
    ipcRenderer.invoke('install-app-updates');
  };

  return (
    <GologinModal
      title={translation('updateAppModal.title.text')}
      isVisible={isModalVisible}
      onClose={(): void => {
        setIsModalVisible(false);
        setUpdatingLoader && setUpdatingLoader(false);
      }}
    >
      <div style={{ marginTop: 16 }}>
        {translation('updateAppModal.subtitle.text')}
      </div>
      <div style={{ display: 'flex', justifyContent: 'right', marginTop: 32 }}>
        <CancelButton key="option1" onClick={closeImmediately} style={{ marginRight: 16 }}>
          {translation('updateAppModal.button.closeImmediately')}
        </CancelButton>
        <SaveButton key="option2" onClick={saveThenUpdate}>
          {translation('updateAppModal.button.saveProfiles')}
        </SaveButton>
      </div>
    </GologinModal>
  );
};
