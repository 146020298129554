import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconImport: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_11339_135572)'>
        <path d='M4.59091 15.5L1.86364 15.5C1.50198 15.5 1.15513 15.3244 0.899399 15.0118C0.643668 14.6993 0.499999 14.2754 0.499999 13.8333L0.499998 2.16667C0.499998 1.72464 0.643666 1.30071 0.899398 0.988154C1.15513 0.675594 1.50198 0.499999 1.86363 0.499999L14.1364 0.499998C14.498 0.499998 14.8449 0.675592 15.1006 0.988153C15.3563 1.30071 15.5 1.72464 15.5 2.16666L15.5 13.8333C15.5 14.2754 15.3563 14.6993 15.1006 15.0118C14.8449 15.3244 14.498 15.5 14.1364 15.5L11.4091 15.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M7.98144 5.50071L7.98145 15.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M4.79945 7.68164L7.98127 4.49982L11.1631 7.68164' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_11339_135572'>
          <rect width='16' height='16' fill='var(--FFFFFF)' />
        </clipPath>
      </defs>
    </svg>
  </IconWrapper>
);
