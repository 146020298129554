import React, { useState } from 'react';
import { Trans } from 'react-i18next';

import { GeoProxyType } from '../../../../../common/constants/types';
import { IGeolocationProxyPrices } from '../../../../interfaces';
import TooltipWrapper from '../../../tooltip-custom/tooltip-wrapper';
import ProxyPaymentWaySelector from '../proxy-payment-way-selector';
import { ItemRowActive, PriceText } from './styles';

type MenuItemsProps = {
  price: IGeolocationProxyPrices | null;
  geoProxyType: GeoProxyType;
  count: number;
  onClose: () => void;
  visionSide?: 'left' | 'right';
  bundlePrice?: number;
};

const PriceItem: React.FC<MenuItemsProps> = (props) => {
  const { price, bundlePrice, geoProxyType, count, onClose, visionSide = 'left' } = props;

  const [isPaymentWaySelectorVisible, setIsPaymentWaySelectorVisible] = useState<boolean>(false);
  const [selectorTarget, setSelectorTarget] = useState<HTMLElement | null>(null);

  // previously used to limit paying for trial,
  // can be turned back on when need a lock tooltip
  const isTrafficOnlyPaymentLocked = false;

  const calculatePrice = (): string => {
    if (bundlePrice) {
      
      return (bundlePrice / 100).toFixed(0);
    }

    const proxyOldPrice = price[geoProxyType];

    return ((proxyOldPrice * count) / 100).toFixed(0);
  };

  const handleMenuClick = async (event: React.MouseEvent<HTMLElement>): Promise<void> => {
    if (isTrafficOnlyPaymentLocked) {
      return;
    }

    setIsPaymentWaySelectorVisible(true);
    setSelectorTarget(event.currentTarget);
  };

  const onCloseSelector = (): void => {
    onClose();
    setIsPaymentWaySelectorVisible(false);
    setSelectorTarget(null);
  };

  const renderContent = (): JSX.Element => (
    <ItemRowActive style={{color: 'var(--36363D-header-buy-proxy-data-quantity)'}} onClick={handleMenuClick}>
      {count}
      &nbsp;GB
      <PriceText>${calculatePrice()}</PriceText>
      {isPaymentWaySelectorVisible ? (
        <ProxyPaymentWaySelector
          rootAnchorEl={selectorTarget}
          onClose={onCloseSelector}
          count={count}
          geoProxyType={geoProxyType}
          visionSide={visionSide}
        />
      ) : null}
    </ItemRowActive>
  );

  if (!isTrafficOnlyPaymentLocked) {
    return renderContent();
  }

  return (
    <TooltipWrapper
      value={<Trans i18nKey="modals.proxyTrafficModal.priceItemTooltipForTrial" />}
      style={{ maxWidth: 200, textAlign: 'center' }}
    >
      {renderContent()}
    </TooltipWrapper>
  );
};

export default PriceItem;
