import React, { FC } from 'react';

import { ArrowContainer } from './styles';
import { IconArrow } from '../gologin-header/icons';

interface ICollapseArrow {
   isOpen: boolean;
   onClick: () => void;
   onMouseEnter: () => void;
   onMouseLeave: () => void;
}

const CollapseArrow: FC<ICollapseArrow> = (props) => (
  <ArrowContainer {...props}>
    <IconArrow padding={props.isOpen ? '4px 5px 2px 5px' : '3px 4px 3px 6px'} />
  </ArrowContainer>
);

export default CollapseArrow;
