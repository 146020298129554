import styled from '@emotion/styled';
import { Icon } from 'antd';

export const Row = styled('div')`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  align-items: center;
  cursor: pointer;
`;

export const ProxyIcon = styled<{ disabled?: boolean }>(Icon)`
  font-size: 14px;
  color: var(--929292-profile-table-proxy);
  transition: color 0.3s linear;
  padding: 2px;
  
  opacity: ${props => props.disabled ? 0.5 : 'none'};

  :hover {
    color: ${props => props.disabled ? 'var(--929292-profile-table-proxy)' : 'var(--01A987-profile-table-proxy)'};
  }
`;

interface IProxyTxtSpan {
  isError?: boolean;
}
export const ProxyTxtSpan = styled<IProxyTxtSpan>('span')`
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 8px;
  color: ${props => props.isError ? 'var(--F5222D-profile-table-proxy)' : 'var(--000000-profile-table-proxy)'};
`;
