import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CANVAS_MODES, CanvasModesType, IFullProfile } from '../../../../interfaces';
import { ModernSelect } from '../../../../ui/modern-select';
import { ContainerParams } from '../../styles';

interface ICanvasComponent {
  mode: CanvasModesType;
  changeSetting: (profileData: Partial<IFullProfile>) => void;
}

export const Canvas: FC<ICanvasComponent> = ({ changeSetting, mode }) => {
  const { t: translation } = useTranslation();

  const titleSelect: Record<CanvasModesType, string> = {
    noise: translation('quickSettings.parameters.maskedWithNoise'),
    off: translation('quickSettings.parameters.real'),
    block: translation('quickSettings.parameters.blocked'),
  };

  const selectItems = CANVAS_MODES.map(canvasMode => ({
    title: titleSelect[canvasMode],
    value: canvasMode,
  }));

  return (
    <ContainerParams>
      <ModernSelect
        currentValue={mode}
        itemList={selectItems}
        onSelected={(value): void => changeSetting({ canvas: { mode: value as CanvasModesType } })}
      />
    </ContainerParams>
  );
};
