import { createContext } from 'react';

import { MajorVersionOption } from '../features/common/orbita-browser';

export interface IBrowserUpdater {
  initialized?: boolean;
  currentStep?: number;
  browserUpdating?: boolean;
  showOrbitaDialog?: boolean;
  downloadProgressStr?: string;
  downloadProgressStatus?: string;
  updateBrowserUpdater?: (updateInfo: any) => void;
  isDiscSpaceError?: boolean;
  versionLoadingNow?: string;
  isOrbitaVersionCompatible?: (versionValue: MajorVersionOption) => Promise<boolean>;
}

export interface IBrowserUpdaterCtx extends IBrowserUpdater {
  updateBrowserUpdater: (updateInfo: IBrowserUpdater) => void;
}

export const BROWSER_UPDATER_DEFAULT_VALUE: IBrowserUpdaterCtx = {
  initialized: false,
  currentStep: 0,
  browserUpdating: false,
  showOrbitaDialog: false,
  downloadProgressStr: '0/0',
  downloadProgressStatus: 'progress',
  updateBrowserUpdater: () => ({}),
  isDiscSpaceError: false,
  versionLoadingNow: '',
  isOrbitaVersionCompatible: async () => false,
};

export const browserUpdaterContext = createContext<IBrowserUpdaterCtx>(BROWSER_UPDATER_DEFAULT_VALUE);
