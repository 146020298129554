import { IDBPDatabase, deleteDB, openDB } from 'idb';

import { genIdbHash } from './gen-idb-hash';
import type { ObjectPoolEntity, ObjectPoolEntityType } from '../types';
import { removeDBMetaEntry } from './meta-db';
import { doDeleteDB } from './delete-db';

const STORE_VERSION = 1;
const CACHE_DB_VERSION = 1;
const PERSISTED_STORES: Record<ObjectPoolEntityType, boolean> = {
  profile: true,
  'folder-profile': true,
  share: true,
};

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

const getEntries = <T extends object>(obj: T): Entries<T> => Object.entries(obj) as Entries<T>;

export const openCacheDb = async (dbName: string): Promise<IDBPDatabase> => openDB(dbName, CACHE_DB_VERSION, {
  upgrade (db) {
    getEntries(PERSISTED_STORES).forEach(([entityName, isPersisted]) => {
      if (isPersisted) {
        upgradeCacheDBStore(db, entityName);
      }
    });
  },
});

const upgradeCacheDBStore = (db: IDBPDatabase, entityType: ObjectPoolEntityType): void => {
  const storeName = genStoreName({ entityType, version: STORE_VERSION });
  if (db.objectStoreNames.contains(storeName)) {
    return;
  }

  const objectStore = db.createObjectStore(storeName, { keyPath: 'id' });
  objectStore.createIndex('id', 'id', { unique: true });
};

export const loadAllFromCacheDBStore = async <T extends ObjectPoolEntity>(dbName: string, entityType: T['objectPoolEntityType']): Promise<T[]> => {
  const db = await openCacheDb(dbName);
  const storeName = genStoreName({ entityType, version: STORE_VERSION });
  const res = await db.getAll(storeName);

  return res || null;
};

type PutIntoCacheDBStoreOpts<T extends ObjectPoolEntity> = {
  dbName: string;
  entityType: T['objectPoolEntityType'];
  entities: T[];
};

export const putIntoCacheDBStore = async <T extends ObjectPoolEntity>({
  dbName,
  entityType,
  entities,
}: PutIntoCacheDBStoreOpts<T>): Promise<void> => {
  const db = await openCacheDb(dbName);
  const storeName = genStoreName({ entityType, version: STORE_VERSION });
  const tx = db.transaction(storeName, 'readwrite');
  entities.forEach((entity) => tx.store.put(entity));

  return tx.done;
};

type DeleteFromCacheDBStoreOpts<T extends ObjectPoolEntity> = {
  dbName: string;
  entityType: T['objectPoolEntityType'];
  entityIds: Array<T['id']>;
};

export const deleteFromCacheDBStore = async <T extends ObjectPoolEntity>({
  dbName,
  entityType,
  entityIds,
}: DeleteFromCacheDBStoreOpts<T>): Promise<void> => {
  const db = await openCacheDb(dbName);
  const storeName = genStoreName({ entityType, version: STORE_VERSION });
  const tx = db.transaction(storeName, 'readwrite');
  entityIds.forEach((entityId) => tx.store.delete(entityId));

  return tx.done;
};

export const clearCacheDBStore = async (dbName: string, entityType: ObjectPoolEntityType): Promise<void> => {
  const db = await openCacheDb(dbName);
  const storeName = genStoreName({ entityType, version: STORE_VERSION });
  db.clear(storeName);
};

export const purgeCacheDB = async (dbName: string): Promise<'success'|'blocked'> => {
  const dbDeleteRes = await doDeleteDB(dbName);
  if (dbDeleteRes !== 'success') {
    return dbDeleteRes;
  }

  await removeDBMetaEntry(dbName);

  return 'success';
};

type StoreParams = {
  entityType: ObjectPoolEntityType;
  version: number;
}

const genStoreName = ({ entityType, version }: StoreParams): string => genIdbHash(`${entityType}-${version}`);
