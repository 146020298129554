import { Icon, message } from 'antd';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GeolocationProxyForm } from './geolocation-proxy-form';
import { FREE_PLAN_ID } from '../../../../../common/constants/constants';
import { GeoProxyType } from '../../../../../common/constants/types';
import { ProxyMode } from '../../../../interfaces';
import { userContext, workspaceContext } from '../../../../state';
import { NEW_FEATURES } from '../../../../state/feature-toggle/new-features';
import { getProfileGeoProxyParams } from '../api';
import ProxyChecker from '../components/proxy-checker';
import { LoaderDivProxy } from '../domElements';

declare interface ILuminatiProxyIndex {
  profileInfo: any;
  profileId?: string;
  updateProfileInfo: (params: any) => void;
  geolocationProxy: any;
  setGeolocationProxy: (params: any) => void;
  isSaveButtonPressed?: boolean;
  setIsGeolocationScreen?: (arg: boolean) => void;
  saveProfile?: () => void;
  isCreateProfile: boolean;
}

const GeolocationProxyIndex: FC<ILuminatiProxyIndex> = (props: ILuminatiProxyIndex) => {
  const { plan, hasSuccessPayment = false, hasTrial = true } = useContext(userContext);
  const { paymentIsTrial = true, isUnpaid, planId } = useContext(workspaceContext);

  const isFreePlanUser = hasSuccessPayment && plan._id === FREE_PLAN_ID;
  const isFreePlanWorkspace = !isUnpaid && planId === FREE_PLAN_ID;
  const isTrial = NEW_FEATURES.workspaces ? paymentIsTrial : hasTrial;
  const isTrialOrFreePlan = (NEW_FEATURES.workspaces ? isFreePlanWorkspace : isFreePlanUser) || isTrial;

  const [proxyLoading, setProxyLoading] = useState<boolean>(false);
  const [currentDisplay, setCurrentDisplay] = useState<'result'|'form'>('result');
  const [initialCheck, setInitialCheck] = useState<boolean>(false);
  const [connection, setConnection] = useState<GeoProxyType>(isTrialOrFreePlan ? GeoProxyType.DataCenter : GeoProxyType.Resident);
  const [currentProxyCountry, setCurrentProxyCountry] = useState<string>('');

  const { t: translation } = useTranslation();

  useEffect(() => {
    const { isCreateProfile, geolocationProxy, profileInfo, profileId, setGeolocationProxy } = props;

    const profileProxy = profileInfo.proxy || {};
    setGeolocationProxy(profileProxy);
    if (profileProxy.country) {

      return;
    }

    const query: any = {
      usernameAuth: profileInfo.proxy.username || '',
      passwordAuth: profileInfo.proxy.password || '',
      profileId: profileId || '',
    };

    const proxyMode: ProxyMode = profileInfo.proxy?.mode;
    const isCustomSelected = proxyMode !== 'geolocation';
    if (!(query.usernameAuth && query.passwordAuth) || isCustomSelected) {
      setCurrentDisplay('form');

      return;
    }

    setProxyLoading(true);
    const queryString =
      Object.keys(query)
        .reduce((res: string, key: string) => res + key + '=' + encodeURIComponent(query[key]) + '&', '');

    getProfileGeoProxyParams(queryString)
      .then((responseObject: any) => {

        if (responseObject.country) {
          setCurrentDisplay('result');
          responseObject.status = 'success';
          responseObject.origin = responseObject.ip;
          changeConnectionType(responseObject.connectionType);
        } else {
          setCurrentDisplay('form');
        }

        setGeolocationProxy(responseObject);
        setProxyLoading(false);
      })
      .catch(() => {
        message.error(translation('notifications.error.noProxy'));
        setCurrentDisplay('form');
      });
  }, []);

  useEffect(() => {
    props.setIsGeolocationScreen && props.setIsGeolocationScreen(true);

    return () => {
      props.setIsGeolocationScreen && props.setIsGeolocationScreen(false);
    };
  }, []);

  const changeConnectionType = (connectionType: GeoProxyType): void => {
    setCurrentProxyCountry('');
    setConnection(connectionType);
  };

  const setCurrentDisplayFunction = (display: 'form'|'result'): void => {
    if (display === 'form') {
      props.updateProfileInfo({
        proxy: {
          port: 80,
          host: '',
          username: '',
          password: '',
          mode: 'geolocation',
        },
      });
    }

    setCurrentDisplay(display);
  };

  const getProxyInfo = () => {
    const { geolocationProxy, profileInfo, setGeolocationProxy } = props;

    if (!geolocationProxy.country) {
      return null;
    }

    return (
      <ProxyChecker
        profileProxy={profileInfo.proxy}
        proxyChecked={geolocationProxy}
        setCurrentDisplay={setCurrentDisplayFunction}
        currentDisplay={currentDisplay}
        setProxyChecked={setGeolocationProxy}
        isLoading={false}
        geolocationShowOption={true}
        initialCheck={initialCheck}
      />
    );
  };

  const getProxySelectorForm = () => {
    if (currentDisplay === 'result') {
      return null;
    }

    return (
      <GeolocationProxyForm
        profileInfo={props.profileInfo}
        profileId={props.profileId}
        updateProfileInfo={props.updateProfileInfo}
        setCurrentDisplay={setCurrentDisplayFunction}
        setProxyCurrent={props.setGeolocationProxy}
        setInitialCheck={setInitialCheck}
        setProxyLoading={setProxyLoading}
        isSaveButtonPressed={props.isSaveButtonPressed}
        geolocationProxy={props.geolocationProxy}
        setIsGeolocationScreen={props.setIsGeolocationScreen}
        saveProfile={props.saveProfile}
        isCreateProfile={props.isCreateProfile}
        connection={connection}
        setConnection={changeConnectionType}
        currentDisplay={currentDisplay}
        currentProxyCountry={currentProxyCountry}
        setCurrentProxyCountry={setCurrentProxyCountry}
        isTrialOrFreePlan={isTrialOrFreePlan}
      />
    );
  };

  const getContent = () => {
    if (proxyLoading && currentDisplay === 'result') {
      return (
        <LoaderDivProxy>
          <Icon type='loading' />
        </LoaderDivProxy>
      );
    }

    return (
      <>
        {getProxyInfo()}
        {getProxySelectorForm()}
      </>
    );
  };

  return (
    <>
      {getContent()}
    </>
  );
};

export default GeolocationProxyIndex;
