export const drawGradient = (context: CanvasRenderingContext2D, rectSize: number, padding: number): CanvasGradient => {
  const gradient = context.createLinearGradient(
    padding + rectSize / 2,
    padding,
    padding + rectSize / 2,
    padding + rectSize,
  );

  gradient.addColorStop(0, '#00B488');
  gradient.addColorStop(1, '#0B7E62');

  return gradient;
};
