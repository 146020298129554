import React, { useState } from 'react';

import { RunButtonWrapperProps } from '../../../interfaces';
import { Trans } from 'react-i18next';
import { Button } from 'antd';
import { handleRunCookieBot } from '../../../cookiebot-parameters-modal/utils';
import { closeAutomationModal } from '../../../../../state/automation/automation-modal.atom';
import { IconSpinnerOld } from '../../../../../ui/gologin-header/icons';

export const RunButtonWrapper: React.FC<RunButtonWrapperProps> = (props) => {
  const { profileId, workflowId } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClick = async () => {
    setIsLoading(true)
    await handleRunCookieBot({ workflowId, profileId })
      .finally(() => setIsLoading(false));

    closeAutomationModal();
  };

  return <Button onClick={handleClick} disabled={isLoading} style={{ display: 'flex', alignItems: 'center' }}>
    {isLoading ?
      <IconSpinnerOld size={16} /> :
      <Trans style={{ margin: '0px 1px'}} i18nKey={'base.run'} />
    }
  </Button>;
};
