import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconArrowRight: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='17' height='12' viewBox='0 0 17 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M1.16406 6H16.1641' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M11.1641 11L16.1641 6L11.1641 1' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  </IconWrapper>
);
