export const assignOsFromUserAgent = (profileObject: any) => {
  if (profileObject['navigator.userAgent']?.includes('Windows')) {
    profileObject.os = 'win';
    return;
  }

  if (profileObject['navigator.userAgent']?.includes('Android')) {
    profileObject.os = 'android';
    return;
  }

  if (profileObject['navigator.userAgent']?.includes('Mac')) {
    profileObject.os = 'mac';
    return;
  }

  if (profileObject['navigator.userAgent']?.includes('Linux')) {
    profileObject.os = 'lin';
  }
};
