import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconTransfer: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M3 15C4.10457 15 5 14.1046 5 13C5 11.8954 4.10457 11 3 11C1.89543 11 1 11.8954 1 13C1 14.1046 1.89543 15 3 15Z' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M14 5C15.1046 5 16 4.10457 16 3C16 1.89543 15.1046 1 14 1C12.8954 1 12 1.89543 12 3C12 4.10457 12.8954 5 14 5Z' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3 8V6C3 5.20435 3.31607 4.44129 3.87868 3.87868C4.44129 3.31607 5.20435 3 6 3H9' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M7 1L9 3L7 5' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  </IconWrapper>
);
