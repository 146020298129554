import React from 'react';
// import { useTranslation } from 'react-i18next';

import { LogoLink, RedirectLink, RedirectText, Wrapper } from './styles';
import IconLogoGoCircles from '../../../../ui/icons/IconLogoGoCircles';
import { LoadingSpinner } from '../../../../ui/loading-spinner';

interface IRedirectToDesktopPage {
  isLoading: boolean;
  handleClick: React.MouseEventHandler;
}

export const RedirectToDesktopPage: React.FC<IRedirectToDesktopPage> = (props) => {
  const { isLoading, handleClick } = props;

  // no translation, since the tolgee is not loaded for some reasons
  // might be related to hash routing ('#/')
  // const { t: translation } = useTranslation();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Wrapper>
      <LogoLink href='https://gologin.com' target='_blank'>
        <IconLogoGoCircles />
      </LogoLink>
      <RedirectText>
        {/* {translation('webApp.redirectPageToDesktopApp.title')} */}
        Redirecting to your GoLogin app…
      </RedirectText>
      <RedirectLink onClick={handleClick}>
        {/* {translation('webApp.redirectPageToDesktopApp.link')} */}
        If you weren&apos;t redirected, click here.
      </RedirectLink>
    </Wrapper>
  );
};
