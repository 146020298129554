import { TFunction } from 'i18next';

import { GEOLOCATION_SELECT_DATA } from '../../../../../features/common/geolocations';
import { ISelectGroupItems } from '../../../../../ui/modern-select/interfaces';

export const selectItems = (translation: TFunction): ISelectGroupItems[] => [{
  groupTitle: translation('quickSettings.parameters.automatic'),
  selectItems: [{
    title: translation('quickSettings.parameters.basedIP'),
    value: 'automatic',
    isSearchIgnored: true,
  }],
}, {
  groupTitle: translation('quickSettings.parameters.manual'),
  selectItems: [{
    title: translation('quickSettings.favorites.customCoordinates'),
    value: 'custom coordinates',
    isSearchIgnored: true,
  }, ...GEOLOCATION_SELECT_DATA,
  {
    title: translation('quickSettings.parameters.blocked'),
    value: 'block',
    isSearchIgnored: true,
  }],
}];
