import { Atom, atom, getDefaultStore, useAtomValue } from 'jotai';

import { getIsBasicEntityProfile } from './basic-table-entities-subtypes';
import { updateGroupHeader } from './group-headers.operations';
import { getShouldGroupBeVisibleInTable } from './group-loading';
import { isNotNull } from '../../../common/typescript/predicates';
import { IProfile } from '../../interfaces';
import { GroupHeader } from '../../interfaces/group-header.interface';
import { getIsJotaiNoAtomInitError } from '../../utils/jotai-no-atom-init';
import {
  filterProfileByGroupHeader,
  profileAtomListAtom,
  profilesListAtom,
  profilesTableGroupHeadersAtom,
  profilesTableGroupHeadersListAtom,
} from '../profiles-list.atom';

type LoaderRow = { id: 0; isLoaderRow: true };
type BasicTableEntityContent = IProfile | GroupHeader | LoaderRow;
export interface IBasicTableEntity {
  idx: number;
  atom: Atom<BasicTableEntityContent>;
}

export interface IBasicTableGroupHeader {
  idx: number;
  atom: Atom<GroupHeader>;
}

export interface IBasicTableLoaderRow {
  idx: number;
  atom: Atom<LoaderRow>;
}

const basicTableEntitiesAtom: Atom<IBasicTableEntity[]> = atom<IBasicTableEntity[]>((get) => {
  let basicTableEntities: IBasicTableEntity[] = [];
  try {
    basicTableEntities = get(basicTableEntitiesAtom);
  } catch (error: unknown) {
    if (!getIsJotaiNoAtomInitError(error)) {
      throw error;
    }
  }

  const groupHeaders = get(profilesTableGroupHeadersAtom);
  const groupHeaderAtoms = get(profilesTableGroupHeadersListAtom);
  const profiles = get(profilesListAtom);
  const profileAtoms = get(profileAtomListAtom);

  if (!groupHeaders.length) {
    const newBasicTableEntities = profileAtoms.map((profileAtom, idx) => ({ idx, atom: profileAtom }));

    if (basicTableEntities.length !== newBasicTableEntities.length) {
      return newBasicTableEntities;
    }

    basicTableEntities.forEach(basicTableEntity => {
      const { idx, atom: prevAtom } = basicTableEntity;
      const newAtom = newBasicTableEntities[idx].atom;
      const prevAtomValue = getDefaultStore().get(prevAtom);
      const newAtomValue = getDefaultStore().get(newAtom);

      if (prevAtomValue.id !== newAtomValue.id) {
        getDefaultStore().set(prevAtom, newAtomValue);
      }
    });

    return basicTableEntities;
  }

  const groups = groupHeaders.map((groupHeader) => {
    const groupProfileIndexes = profiles.reduce<number[]>((acc, profile, index) => {
      if (filterProfileByGroupHeader(groupHeader, profile)) {
        acc.push(index);
      }

      return acc;
    }, []);

    const actualProfilesCount = groupProfileIndexes.length;
    if (groupHeader.loadingStatus === 'loaded' && groupHeader.totalProfiles !== actualProfilesCount) {
      updateGroupHeader(groupHeader.id, { totalProfiles: actualProfilesCount });
    }

    const isGroupVisible = getShouldGroupBeVisibleInTable(groupHeader.id, true);
    const isGroupLoadedEnoughToShow = ['loading', 'loaded'].includes(groupHeader.loadingStatus);
    if (!(groupHeader.isOpen && isGroupLoadedEnoughToShow && isGroupVisible)) {
      return { ...groupHeader, profileIndexes: [] };
    }

    return { ...groupHeader, profileIndexes: groupProfileIndexes };
  });

  const newBasicTableEntities = groups.reduce<IBasicTableEntity[]>((acc, group, groupIdx) => {
    const { profileIndexes, loadingStatus } = group;

    acc.push({ idx: acc.length, atom: groupHeaderAtoms[groupIdx] });
    if (loadingStatus === 'loading-initiated') {
      acc.push({ idx: acc.length + 1, atom: atom(() => ({ id: 0, isLoaderRow: true })) });
    } else {
      acc.push(...profileIndexes.map((profileIdx, arrIdx) => ({ idx: acc.length + arrIdx, atom: profileAtoms[profileIdx] })));
    }

    return acc;
  }, []);

  // TODO: update targeted atoms and not whole array all the time
  // it is blocked by group/profiles atoms overriding each other
  // not too easy to fix right now
  return newBasicTableEntities;
});

export const useBasicTableEntities = (): IBasicTableEntity[] => useAtomValue(basicTableEntitiesAtom);
export const getBasicTableEntities = (): IBasicTableEntity[] => getDefaultStore().get(basicTableEntitiesAtom);

export const getBasicTableEntityById = (entityId: string): IBasicTableEntity | null => getBasicTableEntities().find(
  basicTableProfile => getDefaultStore().get(basicTableProfile.atom)?.id === entityId,
) || null;

export const getProfileByBasicProfilesIdx = (idx: number): IProfile | null => {
  const basicTableProfiles = getBasicTableEntities();
  const basicTableProfile = basicTableProfiles[idx];
  if (!basicTableProfile?.atom) {
    return null;
  }

  const entityAtom = basicTableProfile.atom;
  const entity = getDefaultStore().get(entityAtom);
  if (!getIsBasicEntityProfile(entity)) {
    return null;
  }

  return entity;
};

export const getBasicTableProfileIds = (): string[] => getBasicTableEntities().map(
  basicTableProfile => {
    const basicTableEntity = getDefaultStore().get(basicTableProfile.atom);
    if (getIsBasicEntityProfile(basicTableEntity)) {
      return basicTableEntity.id;
    }

    return null;
  },
).filter(isNotNull);

export const getBasicTableEntityItems = (): BasicTableEntityContent[] => getBasicTableEntities().map(
  basicTableProfile => getDefaultStore().get(basicTableProfile.atom),
).filter(isNotNull);
