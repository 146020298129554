import styled from '@emotion/styled';

import { IconWrapperWithDescription } from '../../../../../../ui/gologin-header/icons/wrapper';
import { textFont } from '../../../../../../ui/style-templates';

export const ButtonWrapper = styled(IconWrapperWithDescription)`
  width: fit-content;
  padding: 0 12px 0 0;
  margin: 8px 0 -8px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Text = styled.span`
  ${textFont};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
