import React from 'react';
import styled from '@emotion/styled/macro';

const LineStyle = styled('path')`
  stroke: var(--949393);
`;

const CircleStyle = styled('rect')`
  stroke: var(--E8E8E8);
`;

const IconPlusInCircleStyle = styled('svg')`
  &:hover {
    stroke: var(--00B86D);
    cursor: pointer;

    ${LineStyle} {
      stroke: var(--00B86D);
    }
    ${CircleStyle} {
      stroke: var(--00B86D);
    }
  }
`;

const IconPlusInCircle = () => (
  <IconPlusInCircleStyle width="25" height="24" viewBox='0 0 24 24' fill="none" xmlns="http://www.w3.org/2000/svg">
    <LineStyle d="M12.5898 7V17" stroke-linecap="round" stroke-linejoin="round"/>
    <LineStyle d="M7.58984 12H17.5898" stroke-linecap="round" stroke-linejoin="round"/>
    <CircleStyle x="1.08984" y="0.5" width="23" height="23" rx="11.5"/>
  </IconPlusInCircleStyle>
);

export default IconPlusInCircle;
