import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { PROFILES_TABLE_SORTER_FIELD_KEY, PROFILES_TABLE_SORTER_ORDER_KEY } from '../../../common/constants/constants';
import { SORT_ORDERS, SortOrder } from '../../features/common/sorter-order';
import { DEFAULT_SORT_FIELD, DEFAULT_SORT_ORDER, SortField } from '../../features/quickProfiles/constants';
import { resetProfilesList } from '../profiles-list.atom';

export const profilesSortFieldAtom = atom<SortField>(DEFAULT_SORT_FIELD);
export const profilesSortOrderAtom = atom<SortOrder>(DEFAULT_SORT_ORDER);

export const getProfilesSortField = (): SortField => getDefaultStore().get(profilesSortFieldAtom);
const setProfilesSortField = (newSortField: SortField): void => {
  const sortFieldToSet = newSortField || DEFAULT_SORT_FIELD;
  getDefaultStore().set(profilesSortFieldAtom, sortFieldToSet);
  localStorage.setItem(PROFILES_TABLE_SORTER_FIELD_KEY, sortFieldToSet);
};

const getProfilesSortOrder = (): SortOrder => getDefaultStore().get(profilesSortOrderAtom);
const setProfilesSortOrder = (newSortOrder: SortOrder): void => {
  const sortOrderToSet = newSortOrder || DEFAULT_SORT_ORDER;
  getDefaultStore().set(profilesSortOrderAtom, sortOrderToSet);
  localStorage.setItem(PROFILES_TABLE_SORTER_ORDER_KEY, sortOrderToSet);
};

export const useProfilesTableSorting = (): { sortField: SortField; sortOrder: SortOrder } => {
  const sortField = useAtomValue(profilesSortFieldAtom);
  const sortOrder = useAtomValue(profilesSortOrderAtom);

  return { sortField, sortOrder };
};

export const initProfilesTableSorting = (): void => {
  const storedField = localStorage.getItem(PROFILES_TABLE_SORTER_FIELD_KEY);
  const storedOrder = localStorage.getItem(PROFILES_TABLE_SORTER_ORDER_KEY);

  setProfilesSortField(storedField || DEFAULT_SORT_FIELD);
  setProfilesSortOrder(storedOrder || DEFAULT_SORT_ORDER);
};

export const sortProfilesTable = (field: SortField, order: SortOrder): void => {
  const prevField = getProfilesSortField();
  const prevOrder = getProfilesSortOrder();
  const isSortFieldSame = (prevField || DEFAULT_SORT_FIELD) === (field || DEFAULT_SORT_FIELD);
  const isSortOrderSame = (prevOrder || DEFAULT_SORT_ORDER) === (order || DEFAULT_SORT_ORDER);
  if (isSortFieldSame && isSortOrderSame) {
    return;
  }

  resetProfilesList();
  setProfilesSortField(field);
  setProfilesSortOrder(order);
};

export const resetProfilesTableSorting = (): void => {
  sortProfilesTable(DEFAULT_SORT_FIELD, DEFAULT_SORT_ORDER);
};

export const onProfilesTableSortClick = (columnSortField: SortField): void => {
  const currSortField = getProfilesSortField();
  const currSortOrder = getProfilesSortOrder();

  if (currSortField === columnSortField) {
    if (currSortOrder === SORT_ORDERS.ascend) {
      sortProfilesTable(columnSortField, SORT_ORDERS.descend);
    } else if (currSortOrder === SORT_ORDERS.descend) {
      resetProfilesTableSorting();
    }

    return;
  }

  sortProfilesTable(columnSortField, 'ascend');
};
