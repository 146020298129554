import { IRectShadowParams } from '../interfaces';
import { getRectShadowParams } from '../utils';

export const drawRectShadow = ({ context, os, scaleFactor }: IRectShadowParams): void => {
  const { shadowOffsetY, shadowBlur } = getRectShadowParams(os, scaleFactor);

  context.shadowColor = 'rgba(0, 0, 0, 0.3)';
  context.shadowOffsetX = 0;
  context.shadowOffsetY = shadowOffsetY;
  context.shadowBlur = shadowBlur;
};
