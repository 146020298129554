import { TFunction } from 'i18next';

import TIMEZONES from '../../../../../features/common/timezones';
import { ISelectGroupItems } from '../../../../../ui/modern-select/interfaces';

const sortTimezonesByOffset = (firstValue: string, secondValue: string): number => {
  const parsedValueA = parseInt(firstValue.replace('−', '-').replace(':', ''), 10);
  const parsedValueB = parseInt(secondValue.replace('−', '-').replace(':', ''), 10);

  if (parsedValueA === parsedValueB) {
    return 0;
  } else if (parsedValueA < parsedValueB) {
    return -1;
  }

  return 1;
};

export const selectItems = (translation: TFunction): ISelectGroupItems[] => [{
  groupTitle: translation('quickSettings.parameters.automatic'),
  selectItems: [{
    title: translation('quickSettings.parameters.basedIP'),
    value: 'automatic',
    isSearchIgnored: true,
  }],
}, {
  groupTitle: translation('quickSettings.parameters.manual'),
  selectItems: TIMEZONES
    .sort((firstValue, secondValue) => sortTimezonesByOffset(firstValue.offset, secondValue.offset))
    .map(({ name, offset }) => ({ title: name.replace('/', ', '), value: name, hint: offset })),
}];
