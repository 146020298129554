import { determineShouldChangeProtocol } from '../../../common/proxy/utils';
import { IProxy } from '../../interfaces';
import { mapAndSetProfilesList } from '../../state/profiles-list.atom';
import { updateProxyRequest } from '../proxy/api';
import { IStatusParams } from '../proxy/proxy-helpers';

const updateProfileProxyMode = (statusParams: IStatusParams, profileId: string): void => {
  const { mode: newProxyMode } = statusParams || {};
  if (newProxyMode) {
    mapAndSetProfilesList((profiles) => profiles.map((profile) => {
      if (profile?.id !== profileId) {
        return profile;
      }

      return {
        ...profile,
        proxy: {
          ...profile?.proxy,
          mode: newProxyMode,
        },
      };
    }));
  }
};

type UpdateProxyAndProfileProxyDataProps = {
  currentProxyData: IProxy;
  newProxyData: IProxy;
  statusParams: IStatusParams;
  profileId: string;
}

export const updateProxyAndProfileProxyData = ({
  currentProxyData,
  newProxyData,
  statusParams,
  profileId,
}: UpdateProxyAndProfileProxyDataProps): void => {
  const { mode: proxyMode } = currentProxyData;
  if (determineShouldChangeProtocol(proxyMode, statusParams.mode)) {
    updateProxyRequest({
      proxyId: newProxyData.id,
      proxy: newProxyData,
      profileId,
    });

    updateProfileProxyMode(statusParams, profileId);
  }
};
