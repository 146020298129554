import React from 'react';

import { IOrbitaModalBase, IOrbitaModalWrapper } from '../../interfaces';
import ModalBase from '../../modal-base';

interface IOrbitaIncompatibleSelectModal extends IOrbitaModalWrapper {
  handleClose: () => void;
}

const IncompatibleSelectModal: React.FC<IOrbitaIncompatibleSelectModal> = (props) => {
  const { visible, handleSubmit, handleCancel, handleClose } = props;
  const keyBase = 'modals.orbitaIncompatibleSelectModal.';

  const modalBaseProps: IOrbitaModalBase = {
    visible,
    handleCancel: handleClose,
    titleKey: keyBase + 'title',
    body: {
      key: keyBase + 'body',
    },
    primaryButton: {
      key: keyBase + 'submit',
      isDisabled: false,
      handleClick: handleSubmit,
    },
    secondaryButton: {
      key: keyBase + 'cancel',
      isDisabled: false,
      handleClick: handleCancel,
    },
  };

  return <ModalBase {...modalBaseProps} />;
};

export default IncompatibleSelectModal;
