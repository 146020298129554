import React, { FC, useCallback } from 'react';

import { RawProfileRow } from './styles';
import { useProfileSortable } from './use-profile-sortable';
import { onProfilesTableRowClick } from '../../../state/profiles-table.commands';
import { useProfileRowGroupKey } from '../../../state/profiles-list.atom';

type ProfileRowProps = {
  style: React.CSSProperties;
  key: any;
  className?: string;

  // TODO: combine these into one if possible
  // theoretically, they should be the same
  // but some rows might not be rendered
  tableRowIndex: number;
  basicEntityIdx: number;
}

const ProfileRow: FC<ProfileRowProps> = (props) => {
  const {
    tableRowIndex,
    basicEntityIdx,
    children,
    key,
    style,
    className,
  } = props;

  let baseClassName = 'gologin-table-row';
  if (className) {
    baseClassName += ' ' + className;
  }

  const groupRowKey = useProfileRowGroupKey(basicEntityIdx);

  const {
    attributes,
    listeners,
    setNodeRef,
    isDragging,
    relativeDropPosition,
  } = useProfileSortable(tableRowIndex);

  const handleOnClick = useCallback((): void => {
    onProfilesTableRowClick(tableRowIndex);
  }, [tableRowIndex]);

  return (
    <RawProfileRow
      key={key}
      ref={setNodeRef}
      className={baseClassName}
      data-row-key={tableRowIndex}
      {...attributes}
      {...listeners}
      dropPosition={relativeDropPosition}
      isDragging={isDragging}
      style={style}
      role='row'
      // eslint-disable-next-line no-undefined
      tabIndex={undefined}
      onClick={handleOnClick}
      data-group-key={groupRowKey}
    >
      {children}
    </RawProfileRow>
  );
};

export default ProfileRow;
