import { T } from '@tolgee/react';
import React, { FC } from 'react';

import { IconCapterraLogo, IconShapeStar } from '../../../../ui/gologin-header/icons';
import { ReviewAuthorContainer, ReviewAuthorText, ReviewContainer, ReviewContent, StarsContainer } from '../domElements';

interface IReviewCard {
  starsCount: number;
  order: number;
}

const ReviewCard: FC<IReviewCard> = ({ starsCount, order }) => (
  <ReviewContainer>
    <StarsContainer style={{ gap: 4 }}>
      {[...Array(5)].map((_, index) => (
        <IconShapeStar key={index} padding={0} iconColor={starsCount > index ? 'var(--F5D23D-new-pricing)' : 'var(--D2D2D5-new-pricing)'} iconType='fill' />
      ))}
    </StarsContainer>
    <ReviewContent>
      <T
        keyName={`pricing.review${order}.text`}
        params={{ strong: <strong />, br: <br /> }}
      />
    </ReviewContent>
    <ReviewAuthorContainer>
      <IconCapterraLogo padding={0} margin={'0 10px 0 0'} />
      <ReviewAuthorText>
        <T
          keyName={`pricing.review${order}.author`}
          params={{ strong: <strong />, br: <br /> }}
        />
      </ReviewAuthorText>
    </ReviewAuthorContainer>
  </ReviewContainer>
);

export default ReviewCard;
