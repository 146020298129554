import React, { FC, useContext, useEffect } from 'react';
import { Trans } from 'react-i18next';

import { IconContainer, ItemTitleContainer, OptionItem, OptionItemTitle, OptionsTitle, WrapperOptions } from './styles';
import { userContext, workspaceContext, doSearch, useSearchState } from '../../../state';
import {
  useShownSearchHistory,
  refreshSearchHistory,
  removeSearchFromHistory,
} from '../../../state/search-history';
import { IconCross, IconClockHistory } from '../icons';

interface ISearchHistoryPopup {
  onClosePopup: () => void;
  entityType: 'profiles'|'proxies';
}

export const SearchHistoryPopup: FC<ISearchHistoryPopup> = (props) => {
  const { onClosePopup, entityType } = props;

  const { id: workspaceId } = useContext(workspaceContext);
  const { _id: userId = '' } = useContext(userContext);

  const { searchInputText } = useSearchState();

  useEffect(() => {
    refreshSearchHistory(userId, workspaceId);
  }, [workspaceId, userId]);

  const shownSearchHistory = useShownSearchHistory(searchInputText, entityType);

  if (!shownSearchHistory.length) {
    return null;
  }

  const onClick = (query: string): void => {
    doSearch({
      newSearch: query,
      userId,
      type: entityType,
      workspaceId,
    });

    onClosePopup();
  };

  const onRemoveSearchFromHistory = (event: MouseEvent, query: string): void => {
    event.stopPropagation();
    removeSearchFromHistory(query, userId, workspaceId);
  };

  return (
    <WrapperOptions>
      <OptionsTitle>
        <Trans i18nKey='header.searchHistory.title' />
      </OptionsTitle>
      {shownSearchHistory.map(item => (
        <OptionItem
          key={item.query}
          onClick={(): void => onClick(item.query)}
        >
          <ItemTitleContainer>
            <IconClockHistory
              styleType='activeGrayWithBackground'
              iconColor='var(--CDCDCD-header-new-search)'
              hoveredHighlight={true}
              padding={0}
              margin='0 8px 0 0'
            />
            <OptionItemTitle>
              {item.query}
            </OptionItemTitle>
          </ItemTitleContainer>
          <IconContainer>
            <IconCross
              iconColor='var(--98989F-header-new-search)'
              iconHoveredColor='var(--767676-header-new-search)'
              styleType='activeGray'
              padding={4}
              onClick={(event): void => onRemoveSearchFromHistory(event, item.query)}
            />
          </IconContainer>
        </OptionItem>
      ))}
    </WrapperOptions>
  );
};
