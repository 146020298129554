import { FormatIcu } from '@tolgee/format-icu';
import { BackendFetch, FormatSimple, Tolgee } from '@tolgee/react';

export const tolgeeConfig = Tolgee()
  .use(FormatSimple())
  .use(FormatIcu())
  .use(BackendFetch({ prefix: 'https://cdn.tolg.ee/6d192c811c7623322264dfee17f76032' }))
  .init({
    availableLanguages: ['en-US', 'fr', 'ru-RU', 'pt', 'vi-VN', 'ja', 'zh-Hans-CN'],
    fallbackLanguage: 'en-US',
    defaultLanguage: 'en-US',
    ns: ['translation'],
    fallbackNs: 'translation',
    defaultNs: 'translation',
    staticData: {
      'en-US:translation': () => import('../app/locales/translation/en-US.json'),
    },
  });
