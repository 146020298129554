import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconProxyCopy: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_11433_144975)'>
        <path
          d='M14.5 5.5H8.5C7.94772 5.5 7.5 5.94772 7.5 6.5V14.5C7.5 15.0523 7.94772 15.5 8.5 15.5H14.5C15.0523 15.5 15.5 15.0523 15.5 14.5V6.5C15.5 5.94772 15.0523 5.5 14.5 5.5Z'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M4.5 13.5H1.5C0.947715 13.5 0.5 13.0523 0.5 12.5V1.5C0.5 0.947715 0.947715 0.5 1.5 0.5H10.5C11.0523 0.5 11.5 0.947715 11.5 1.5V2.5'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_11433_144975'>
          <rect width='16' height='16' fill='var(--FFFFFF)' />
        </clipPath>
      </defs>
    </svg>
  </IconWrapper>
);
