import styled from '@emotion/styled';
import { Menu, MenuItem, PopoverOrigin } from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import RoleDescription from './role-description';
import RoleTitle from './role-title';
import { SelectButton } from './select-button';
import { getInviteActionText, SelectRole, ShareLinksAction, SharingSelectRole } from '../../../utils/roles-texts';

const CustomMenu = styled(Menu)<{ width: string }>`
  .MuiPopover-paper {
    border-radius: 8px;
    width: ${(props): string => props.width};
    margin-top: 6px;
  }

  .MuiPaper-root {
    color: var(--000000DE-members-permissions-select);
    background-color: var(--FFFFFF-members-permissions-select);
  }
`;

const SelectRoleMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    padding-top: 8px;
    padding-bottom: 8px;
    letter-spacing: normal;
  }
  
  &.MuiListItem-gutters {
    padding-left: 12px;
  }
`;

interface IRoleSelect {
  role: SelectRole|SharingSelectRole|ShareLinksAction;
  setRole: (action: SelectRole|SharingSelectRole|ShareLinksAction) => void;
  type: 'invite'|'sharing';
  width: string;
  menuWidth?: string;
  disabled?: boolean;
  showRemoveMember?: boolean;
  onRemoveMember?: () => void;
  blockAdminSelect?: boolean;
}

const INITIAL_TRANSFORM_ORIGIN: PopoverOrigin = { vertical: 0, horizontal: 0 };

const RoleSelect: FC<IRoleSelect> = (props) => {
  const {
    role, setRole, type, width, menuWidth, disabled,
    showRemoveMember, onRemoveMember, blockAdminSelect,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement|null>(null);

  const { t: translation } = useTranslation();

  const roleAdmin = type === 'invite' ? 'admin' : 'administrator';
  const roleEdit = type === 'invite' ? 'editor' : 'redactor';

  const closeMenu = (): void => setAnchorEl(null);

  const selectRole = (selectedRole: SelectRole | SharingSelectRole): void => {
    if (selectedRole === 'varies') {
      return;
    }

    setRole(selectedRole);
    closeMenu();
  };

  return (
    <>
      <SelectButton
        roleText={getInviteActionText(role, translation)}
        onClick={(e): void => setAnchorEl(e.currentTarget)}
        disabled={disabled}
        selectStyled={true}
        width={width}
      />
      <CustomMenu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={INITIAL_TRANSFORM_ORIGIN}
        width={menuWidth || width}
      >
        <SelectRoleMenuItem onClick={(): void => selectRole('guest')}>
          <div>
            <RoleTitle role='guest' selectedRole={role} />
            <RoleDescription type={type} role={'guest'} />
          </div>
        </SelectRoleMenuItem>
        <SelectRoleMenuItem onClick={(): void => selectRole(roleEdit)}>
          <div>
            <RoleTitle role={roleEdit} selectedRole={role} />
            <RoleDescription type={type} role={roleEdit} />
          </div>
        </SelectRoleMenuItem>
        <SelectRoleMenuItem onClick={(): void => selectRole(roleAdmin)} disabled={blockAdminSelect}>
          <div>
            <RoleTitle role={roleAdmin} selectedRole={role} />
            <RoleDescription type={type} role={roleAdmin} />
          </div>
        </SelectRoleMenuItem>
        {(showRemoveMember && onRemoveMember) ? (
          <SelectRoleMenuItem onClick={(): void => onRemoveMember && onRemoveMember()}>
            <div>
              <RoleTitle role='remove' selectedRole={role} />
            </div>
          </SelectRoleMenuItem>
        ) : null}
      </CustomMenu>
    </>
  );
};

export default RoleSelect;
