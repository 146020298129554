import React, { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ProfilesImportConfirmation from './profiles-import-confirmation';
import ProfilesImportLoader from './profiles-import-loader';
import ProfilesImportResultList from './profiles-import-result-list';
import { NEW_FEATURES } from '../../../../state/feature-toggle/new-features';
import { history } from '../../../../services';
import { workspaceContext } from '../../../../state';
import GologinModal from '../../../../ui/gologin-modal';
import { getAccessibleFolderName } from '../../../../utils/check-folders-to-add-profile';

declare interface IBrowserManager {
  profileImportModalVisible: boolean;
  toggleProfileImportManagerModal: (arg: boolean) => void;
}

const ProfilesImportManager: FC<IBrowserManager> = (props) => {
  const { profileImportModalVisible, toggleProfileImportManagerModal } = props;

  const [objectOfImportedProfiles, setObjectOfImportedProfiles] = useState<any>({});
  const [profilesToImport, setProfilesToImport] = useState<any[]>([]);
  const [headerForImport, setHeaderForImport] = useState<string>('');
  const [currentWindow, setCurrentWindow] = useState<'loader'|'resultList'|'confirmation'>('loader');

  const { folders, me } = useContext(workspaceContext);

  const { t: translation } = useTranslation();

  let folderToImport = '';
  if (NEW_FEATURES.workspaces) {
    folderToImport = getAccessibleFolderName({
      folders,
      permission: 'importProfile',
      limitedAccess: !!me?.limitedAccess,
      hasFirstFolder: true,
      hasSelectedFolder: true,
    });
  }

  const closeBrowserModal = (): void => {
    toggleProfileImportManagerModal(false);
    setCurrentWindow('loader');
    if (
      objectOfImportedProfiles &&
      (Object.keys(objectOfImportedProfiles.createdProfiles || {}).length ||
      Object.keys(objectOfImportedProfiles.updatedProfiles || {}).length)
    ) {
      history.replace('/');
    }
  };

  const getProfilesImportLoader = (): JSX.Element =>
    (
      <ProfilesImportLoader
        toggleProfileImportModalVisible={toggleProfileImportManagerModal}
        setProfilesToImport={setProfilesToImport}
        changeCurrentWindow={setCurrentWindow}
        profileImportModalVisible={profileImportModalVisible}
        setHeaderForImport={setHeaderForImport}
      />
    );

  const getProfileResultList = (): JSX.Element =>
    (
      <ProfilesImportResultList
        objectOfImportedProfiles={objectOfImportedProfiles}
        toggleProfileImportModalVisible={toggleProfileImportManagerModal}
        changeCurrentWindow={setCurrentWindow}
      />
    );

  const getConfirmationScreen = (): JSX.Element =>
    (
      <ProfilesImportConfirmation
        profilesToImport={profilesToImport}
        headerForImport={headerForImport}
        toggleProfileImportModalVisible={toggleProfileImportManagerModal}
        changeCurrentWindow={setCurrentWindow}
        setObjectOfImportedProfiles={setObjectOfImportedProfiles}
        folderName={folderToImport}
      />
    );

  const getContent = (): JSX.Element => {
    if (currentWindow === 'loader') {
      return getProfilesImportLoader();
    }

    if (currentWindow === 'confirmation') {
      return getConfirmationScreen();
    }

    return getProfileResultList();
  };

  let modalTitle = translation('importProfileModal.title');
  if (folderToImport) {
    modalTitle += ' ' + translation('importProfileModal.titleFolder', { folder: folderToImport });
  }

  return (
    <>
      <GologinModal
        isVisible={profileImportModalVisible}
        onClose={(): void => closeBrowserModal()}
        title={modalTitle}
        width={currentWindow === 'loader' ? '520px' : '780px'}
      >
        {getContent()}
      </GologinModal>
    </>
  );
};

export default ProfilesImportManager;
