import React from 'react';
import { Trans } from 'react-i18next';

import { GeoProxyType } from '../../../../../common/constants/types';
import { stringifyRemainingTrafficAmount } from '../../../../../common/proxy/traffic/utils';
import { IGeolocationProxy } from '../../../../interfaces';
import { IconPlusFill } from '../../icons';
import { proxyTypeToRender } from '../geolocation-proxy-type';
import { ItemRow, VolumeProxyContainer } from '../styles';

type ProxyItemProps = {
  trafficData: IGeolocationProxy;
  type: GeoProxyType;
  openPriceList: (type: GeoProxyType, event: HTMLDivElement) => void;
}

const ProxyItem: React.FC<ProxyItemProps> = (props): JSX.Element => {
  const { trafficData, type, openPriceList } = props;

  const stringifiedRemainingTrafficAmount = stringifyRemainingTrafficAmount(trafficData, type);

  return (
    <ItemRow>
      {proxyTypeToRender[type]}
      <VolumeProxyContainer onClick={(event): void => openPriceList(type, event.currentTarget)}>
        <Trans
          i18nKey='proxies.trafficRemainingLimit'
          values={{ limit: stringifiedRemainingTrafficAmount }}
        />
        <IconPlusFill
          padding={0}
          margin='0 0 0 8px'
          iconColor='var(--00A987-header-buy-proxy)'
          iconHoveredColor='var(--00997A-header-buy-proxy)'
        />
      </VolumeProxyContainer>
    </ItemRow>
  );
};

export default ProxyItem;

