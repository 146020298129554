import { parseNameFormatting } from './index';
import { NEW_FEATURES } from '../../state/feature-toggle/new-features';
import { DEFAULT_PROFILE_NAME } from '../../state';

export const getParsedName = (mainName: string, secondaryName: string): string => {
  let nameFormat = mainName || secondaryName || '';
  if (!nameFormat.trim()) {
    nameFormat = DEFAULT_PROFILE_NAME;
  }

  const formattingParams = {
    profiles: [{}],
    nameFormat,
  };

  let nameFromTemplate = '';
  if (NEW_FEATURES.templateProfileName) {
    [{ name: nameFromTemplate = '' }] = parseNameFormatting(formattingParams);
  }

  return nameFromTemplate;
};
