import { useCallback, useRef, useState } from 'react';

import { IExtensionFromDb } from '../features/profileSettingsComponents/extensionsTab/interfaces/extension.interface';
import { IExtensionsCtx } from '../state';

export const useExtensions = (): IExtensionsCtx => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const extensionsMap = useRef(new Map<string, IExtensionFromDb>());

  const updateExtensionsMap = useCallback((extensions: IExtensionFromDb[]): void => extensions.forEach(extension => {
    extensionsMap.current.set(extension.extId, extension);
    setIsLoading(false);
  }), [extensionsMap.current]);

  const addCustomExtension = useCallback((customExtension: IExtensionFromDb): void => {
    extensionsMap.current.set(customExtension.extId, customExtension);
  }, [extensionsMap.current]);

  const getExtensions = (extensionIds: string[]): IExtensionFromDb[] => extensionIds
    .map(extensionId => extensionsMap.current.get(extensionId))
    .filter(Boolean) as IExtensionFromDb[];

  return {
    getExtensions,
    updateExtensionsMap,
    isLoading,
    setIsLoading,
    addCustomExtension,
  };
};
