import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IBookmarkFoldersObj } from '../../../../../electron/interfaces/bookmarks-utils.interfaces';
import { IconPlusFill } from '../../../../ui/gologin-header/icons';
import { blackMain, grey, greyInformers } from '../../../../ui/style-templates/colors';
import { getCountBookmarks, parseBookmarksObjToTxt, parseBookmarksTxtToObj } from '../../../../utils/bookmarks-utils';
import { IChangeSetting } from '../../interfaces';
import { ContainerIcon, SeparatedContainer, ContainerParamsColumn, TextArea } from '../../styles';

interface IBookmarks extends IChangeSetting {
  bookmarks: IBookmarkFoldersObj;
  profileId: string;
}

export const Bookmarks: FC<IBookmarks> = ({ bookmarks, profileId, changeSetting }) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [bookmarksText, setBookmarksText] = useState('');
  const [bookmarksCount, setBookmarksCount] = useState(0);

  const { t: translation } = useTranslation();

  useEffect(() => {
    const parsedBookmarksObjectToTxt = parseBookmarksObjToTxt(bookmarks);
    setBookmarksText(parsedBookmarksObjectToTxt);
  }, [bookmarks, profileId]);

  useEffect(() => {
    const parsedBookmarksObject = parseBookmarksTxtToObj(bookmarks, bookmarksText);
    const count = getCountBookmarks(parsedBookmarksObject);
    setBookmarksCount(count);
  }, [bookmarksText]);

  const changeBookmarks = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    const text = event.target.value;
    setBookmarksText(text);
  };

  const onBlur = (): void => {
    const parsedBookmarksObject = parseBookmarksTxtToObj(bookmarks, bookmarksText);
    changeSetting({ bookmarks: parsedBookmarksObject });
  };

  const renderAddBookmarks = (): JSX.Element => {
    if (bookmarksCount) {
      return (
        <div>
          {translation('quickSettings.browser.bookmarksCountNewKey', { count: bookmarksCount })}
        </div>
      );
    }

    return (
      <ContainerIcon
        iconColor={greyInformers}
        iconHoveredColor={blackMain}
        textColor={grey}
        textHoveredColor={blackMain}
        iconType='stroke'
      >
        <IconPlusFill padding={0} margin='0 8px 0 0' />
        {translation('quickSettings.browser.addBookmarks')}
      </ContainerIcon>
    );
  };

  const renderBookmarksArea = (): JSX.Element|null => {
    if (!isEditing) {
      return null;
    }

    return (
      <TextArea
        style={{ marginTop: 16 }}
        shouldWrapText={true}
        minHeight={132}
        placeholder={translation('profileSettings.bookmarks.placeholder') || ''}
        value={bookmarksText}
        onChange={changeBookmarks}
        onBlur={onBlur}
      />
    );
  };

  return (
    <ContainerParamsColumn hasCursorPointer={true}>
      <SeparatedContainer onClick={(): void => setIsEditing(!isEditing)}>
        {renderAddBookmarks()}
      </SeparatedContainer>
      {renderBookmarksArea()}
    </ContainerParamsColumn>
  );
};
