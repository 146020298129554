import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconSortSmall: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <path d='M3.5 11.0703L5.5 13.0703L7.5 11.0703' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M5.5 13.072V2.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8.5 4.57031L10.5 2.57031L12.5 4.57031' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M10.5 2.57031V13.1423' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  </IconWrapper>
);
