import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled';

export const ProxyItemCheckboxWrapper = styled.div<{ isNewStyle?: boolean }>`
  ${({ isNewStyle }): SerializedStyles | null => isNewStyle ? null : css`
    margin-top: -4px;
  `}

  .ant-checkbox-wrapper {
    line-height: 1.5;
  }

  & .ant-checkbox-inner {
    width: 12px;
    height: 12px;
    border-radius: 2px;
  }
`;
