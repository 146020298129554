import * as Sentry from '@sentry/react';

import { ISharedProxy } from '../../../features/proxy/components/interfaces/shared-proxy.interfaces';
import {
  DEFAULT_PROXIES,
  DEFAULT_HAS_MORE_PROXIES,
  DEFAULT_PROXIES_LIST,
  PROXIES_ITERATIONS_LIMIT,
  LOAD_PROXY_TRANSACTION,
  PROXY_LOAD_OPERATION,
  LOAD_PROXIES_PAGES_SPAN,
  LOAD_PROXIES_SHARED_AND_FREE_SPAN,
  FREE_PROXIES_LOADED_COUNT_TAG,
  HAS_PAGES_NOT_LOADED_TAG,
  PAGES_LOADED_COUNT_TAG,
  SHARED_PROXIES_LOADED_COUNT_TAG,
  PROXY_PAGE_LOADING_ERROR,
  PROXY_PAGE_NOT_LOADED_TAG,
  PROXIES_LOADED_COUNT_TAG,
} from '../../../features/proxy/constants';
import { makeArchivedProxyFromProxy } from '../../../features/proxy/proxy-helpers';
import { IArchivedProxyInBrowser, IProxy } from '../../../interfaces';
import { ReactError } from '../../../utils/sentry-parameters/custom-errors';
import { NEW_FEATURES } from '../../feature-toggle/new-features';
import { updateProfilesArchivedProxy } from '../../profiles-list.atom';
import { requestProxiesFree, requestProxiesList, requestProxiesPaginated, requestProxiesShared } from '../../proxies.context/api';
import { updateFreeProxies } from '../free-proxies.atom';
import { getProxyList, removeProxiesFromList, setIsProxyListLoaded, updateProxyItems, updateProxyList } from '../proxy-list.atom';
import { setSharedProxies } from '../shared-proxies.atom';

const loadProxiesPagesIteratively = async (transaction: Sentry.Transaction): Promise<void> => {
  const span = transaction.startChild({ op: PROXY_LOAD_OPERATION, description: LOAD_PROXIES_PAGES_SPAN });

  let hasMore = true;
  let currentPage = 1;
  let uploadedProxies: IProxy[] = [];
  const proxiesHash = getProxyList().reduce<Record<string, IProxy>>((hash, proxy) => {
    const { id } = proxy;
    hash[id] = proxy;

    return hash;
  }, {});

  while (hasMore && currentPage <= PROXIES_ITERATIONS_LIMIT) {
    let proxiesInfo: { proxies: IProxy[]; hasMore: boolean } = { proxies: DEFAULT_PROXIES_LIST, hasMore: DEFAULT_HAS_MORE_PROXIES };
    try {
      proxiesInfo = await requestProxiesPaginated(currentPage);
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Proxy page loading error';
      Sentry.captureException(new ReactError(errorMessage), (scope) => {
        scope.setTransactionName(PROXY_PAGE_LOADING_ERROR);
        scope.setFingerprint([PROXY_PAGE_LOADING_ERROR]);
        scope.setLevel('error');
        scope.setTag(PROXY_PAGE_NOT_LOADED_TAG, currentPage);

        return scope;
      });

      break;
    }

    const { proxies: uploadedProxiesChunk } = proxiesInfo;
    const chunkProxiesToUpdate: IProxy[]  = [];
    const chunkProxiesToAdd: IProxy[]  = [];
    uploadedProxiesChunk.forEach(uploadedProxy => {
      const { id } = uploadedProxy;
      if (Object.hasOwn(proxiesHash, id)) {
        chunkProxiesToUpdate.push(uploadedProxy);

        return;
      }

      chunkProxiesToAdd.push(uploadedProxy);
    });

    updateProxyItems(chunkProxiesToUpdate);
    const proxiesLoaded = getProxyList();
    updateProxyList([...proxiesLoaded, ...chunkProxiesToAdd]);
    uploadedProxies = [...uploadedProxies, ...proxiesInfo.proxies];

    ({ hasMore } = proxiesInfo);
    currentPage++;
  }

  const uploadedProxiesHash = uploadedProxies.reduce<Record<string, IProxy>>((hash, proxy) => {
    const { id } = proxy;
    hash[id] = proxy;

    return hash;
  }, {});

  const proxyIdsToDeleteFromProxyList = Object.keys(proxiesHash).reduce<string[]>((proxyIdsToDeleteAcc, proxyId) => {
    if (!Object.hasOwn(uploadedProxiesHash, proxyId)) {
      proxyIdsToDeleteAcc.push(proxyId);
    }

    return proxyIdsToDeleteAcc;
  }, []);

  removeProxiesFromList(proxyIdsToDeleteFromProxyList);
  const archivedProxiesArr: IArchivedProxyInBrowser[] = [];
  proxyIdsToDeleteFromProxyList.forEach(proxyIdToDelete => {
    const proxy = proxiesHash[proxyIdToDelete];
    archivedProxiesArr.push(makeArchivedProxyFromProxy(proxy));
  });

  updateProfilesArchivedProxy(archivedProxiesArr);
  const proxiesLoaded = getProxyList();
  const updatedProxyList = [...proxiesLoaded];
  if (!NEW_FEATURES.hideTorAndFreeProxy) {
    updatedProxyList.push(...DEFAULT_PROXIES);
  }

  updateProxyList(updatedProxyList);

  span.setTag(PAGES_LOADED_COUNT_TAG, currentPage);
  span.setTag(PROXIES_LOADED_COUNT_TAG, proxiesLoaded.length);
  span.setTag(HAS_PAGES_NOT_LOADED_TAG, hasMore);
  span.finish();
};

const loadProxiesSharedAndFree = async (transaction: Sentry.Transaction): Promise<void> => {
  const span = transaction.startChild({ op: PROXY_LOAD_OPERATION, description: LOAD_PROXIES_SHARED_AND_FREE_SPAN });

  let proxiesShared: ISharedProxy[] = [];
  let freeProxiesLength = 0;
  if (NEW_FEATURES.hideTorAndFreeProxy) {
    proxiesShared = await requestProxiesShared();
  } else {
    const [requestedProxiesShared, proxiesFree] = await Promise.all([requestProxiesShared(), requestProxiesFree()]);
    proxiesShared = requestedProxiesShared;
    freeProxiesLength = proxiesFree.length;
    updateFreeProxies(proxiesFree);
  }

  setSharedProxies(proxiesShared);

  span.setTag(SHARED_PROXIES_LOADED_COUNT_TAG, proxiesShared.length);
  span.setTag(FREE_PROXIES_LOADED_COUNT_TAG, freeProxiesLength);
  span.finish();
};

export const loadProxyList = async (): Promise<void> => {
  const transaction = Sentry.startTransaction({ name: LOAD_PROXY_TRANSACTION });

  if (!NEW_FEATURES.proxyPagination) {
    const proxyInfo = await requestProxiesList();
    const updatedProxyList = [...proxyInfo.proxies];
    if (!NEW_FEATURES.hideTorAndFreeProxy) {
      updatedProxyList.push(...DEFAULT_PROXIES);
      if (proxyInfo.freeProxies?.length) {
        updateFreeProxies(proxyInfo.freeProxies);
      }
    }

    updateProxyList(updatedProxyList);
    if (proxyInfo.sharedProxyInfos?.length) {
      setSharedProxies(proxyInfo.sharedProxyInfos);
    }

    return transaction.finish();
  }

  await Promise.all([loadProxiesPagesIteratively(transaction), loadProxiesSharedAndFree(transaction)]);
  setIsProxyListLoaded(true);

  transaction.finish();
};
