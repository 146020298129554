import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconSettings2: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_12578_230210)'>
        <path d='M8 10.5C9.38071 10.5 10.5 9.38071 10.5 8C10.5 6.61929 9.38071 5.5 8 5.5C6.61929 5.5 5.5 6.61929 5.5 8C5.5 9.38071 6.61929 10.5 8 10.5Z' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M13.4999 8C13.4999 7.535 13.4359 7.087 13.3279 6.656L15.2449 5.549L13.7449 2.951L11.8299 4.057C11.1859 3.431 10.3889 2.964 9.49988 2.713V0.5H6.49988V2.712C5.61188 2.964 4.81488 3.431 4.16988 4.057L2.25488 2.951L0.754883 5.549L2.67188 6.656C2.56388 7.087 2.49988 7.535 2.49988 8C2.49988 8.464 2.56388 8.913 2.67188 9.344L0.754883 10.451L2.25488 13.049L4.17088 11.943C4.81488 12.569 5.61188 13.036 6.50088 13.287V15.5H9.50088V13.288C10.3899 13.036 11.1859 12.569 11.8309 11.944L13.7469 13.05L15.2469 10.452L13.3299 9.345C13.4359 8.913 13.4999 8.464 13.4999 8Z' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_12578_230210'>
          <rect width='16' height='16' fill='var(--FFFFFF)' />
        </clipPath>
      </defs>
    </svg>
  </IconWrapper>
);
