import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IFullProfile } from '../../../../interfaces';
import CloseableInput from '../../../../ui/closeable-input';
import { IconPlusFill } from '../../../../ui/gologin-header/icons';
import {
  ContainerIcon,
  ContainerParamsColumn,
  ContainerSubRow,
  SeparatedContainer,
} from '../../styles';

interface IStartUrl {
  startUrl: string;
  changeSetting: (profileData: Partial<IFullProfile>) => void;
}

export const StartUrl: FC<IStartUrl> = ({ startUrl, changeSetting }) => {
  const [urlsList, setUrlsList] = useState<string[]>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement|null>(null);

  const { t: translation } = useTranslation();

  const normalizeUrlsList = (): string[] => urlsList
    .map(url => url.replace(/\s/g, ''))
    .filter(Boolean);

  useEffect(() => {
    const cleanedUrls = normalizeUrlsList();

    let newStartUrl = urlsList;
    if (!startUrl) {
      newStartUrl = [];
    }

    const hasStartUrl = startUrl && cleanedUrls.join(',') !== startUrl;
    if (hasStartUrl) {
      newStartUrl = startUrl.split(',');
    }

    setUrlsList(newStartUrl);
  }, [startUrl]);

  const onStartURLInputBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
    const cleanedUrls = normalizeUrlsList();
    const { value = '' } = event.currentTarget;
    if (!value.trim()) {
      setUrlsList(cleanedUrls);
    }

    changeSetting({ startUrl: cleanedUrls.join(',') });
  };

  const hotkeyHandler = (event: React.KeyboardEvent, index: number): void => {
    if (!containerRef.current) {
      return;
    }

    if (event.key === 'Backspace' && !urlsList[index]) {
      event.preventDefault();
      const inputs: NodeListOf<HTMLInputElement> = containerRef.current.querySelectorAll('input');
      inputs[index - 1].focus();

      const cleanedUrls = normalizeUrlsList();
      setUrlsList(cleanedUrls);

      return;
    }

    if (event.key === 'Enter') {
      const inputs: NodeListOf<HTMLInputElement> = containerRef.current.querySelectorAll('input');

      if (!urlsList[index]?.replace(/\s/g, '')) {
        return;
      }

      if (index === inputs.length - 1) {
        addStartUrl();
      }
    }
  };

  const onStartUrlChange = (index: number, event: ChangeEvent<HTMLInputElement>): void => {
    const updatedUrlsList = urlsList.map((url, idx) => idx === index ? event.target.value : url);

    setUrlsList(updatedUrlsList);
  };

  const onStartUrlDelete = (index: number): void => {
    const updatedUrlsList = urlsList.filter((_, idx) => idx !== index);
    setUrlsList(updatedUrlsList);

    const newStartUrl = updatedUrlsList
      .map(url => url.replace(/\s/g, ''))
      .filter(Boolean)
      .join(',');

    changeSetting({
      startUrl: newStartUrl,
    });
  };

  const renderStartURLInput = (value: string, index: number): JSX.Element|null => (
    <CloseableInput
      onChange={(event): void => onStartUrlChange(index, event)}
      onBlur={onStartURLInputBlur}
      onKeyPress={(event): void => hotkeyHandler(event, index)}
      onClickIconClose={(): void => onStartUrlDelete(index)}
      currentValue={value}
      hasMarginTop={!!startUrl}
      placeholder={translation('quickSettings.browser.addStartUrl') || 'Start url'}
      hasAutoFocus={true}
    />
  );

  const renderStartUrlsList = (): (JSX.Element|null)[] => urlsList.map(renderStartURLInput);

  const addStartUrl = (): void => {
    setIsEdit(true);
    if (urlsList.some(url => !url)) {
      return;
    }

    setUrlsList([...urlsList, '']);
  };

  const renderAddStartUrl = (): JSX.Element => (
    <ContainerIcon
      onClick={addStartUrl}
      iconColor='var(--B5B5BA-profile-new-settings)'
      iconHoveredColor='var(--2B2B31-profile-new-settings)'
      textColor='var(--81818A-profile-new-settings)'
      textHoveredColor='var(--2B2B31-profile-new-settings)'
      iconType='stroke'
    >
      <IconPlusFill padding={0} margin='0 8px 0 0' />
      {translation('quickSettings.browser.addStartUrl')}
    </ContainerIcon>
  );

  const renderStartURL = (): JSX.Element|null => {
    if (!urlsList.length) {
      return renderAddStartUrl();
    }

    if (!isEdit) {
      return null;
    }

    return (
      <>
        <ContainerSubRow style={{ marginBottom: 16, marginTop: startUrl ? 8 : 0 }}>
          {renderStartUrlsList()}
        </ContainerSubRow>
        {renderAddStartUrl()}
      </>
    );
  };

  return (
    <ContainerParamsColumn ref={containerRef}>
      {startUrl ? (
        <SeparatedContainer onClick={(): void => setIsEdit(!isEdit)}>
          {urlsList.map((url, index) => (
            <span key={index}>
              {url}
            </span>
          ))}
        </SeparatedContainer>
      ) : null}
      {renderStartURL()}
    </ContainerParamsColumn>
  );
};
