import styled from '@emotion/styled';

export const ResetButtonWrapper = styled.div`
  display: inline-block;
  margin-left: 16px;
  color: var(--000000D9-profile-table-show-all-tags-button);
  cursor: pointer;
  font-weight: 400;

  &:hover {
    color: var(--00A987-profile-table-show-all-tags-button);
  }
`;

export const ResetButtonIconWrapper = styled.div`
  display: inline-block;
  position: relative;
  top: 2px;
  margin-right: 10px;
`;
