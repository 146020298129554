import { message } from 'antd';
import React, { FC, useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  FolderRow,
  SearchFolderInput,
  ContainerIcon,
  FolderNameText,
  FoundText, FolderContainer,
  FOLDERS_POPOVER_Z_INDEX,
} from './styles';
import { requestWorkspace } from '../../../features/common/api';
import {
  createWorkspaceFolder,
  removeWorkspaceFolder,
  renameWorkspaceFolder,
} from '../../../features/folders/api';
import { searchBold } from '../../../features/newProfile/components/folders-select/search';
import { IFolder } from '../../../interfaces';
import { workspaceContext } from '../../../state';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { removeFolderFromFoldersList, addFolderToFoldersList, setFoldersList, useFoldersList } from '../../../state/folders/folders-list.atom';
import { INVITE_MEMBER_MODAL_NAME, openGlobalModal } from '../../../state/global-modal';
import { forceReloadProfilesQuery, setShouldProfilesQueryMakeRequests } from '../../../state/profiles-table/profiles-query';
import {
  resetSelectedFolderId,
  setSelectedFolderId,
  useSelectedFolderId,
  toggleSelectedFolder,
} from '../../../state/selected-folder.atom';
import GologinPopover from '../../gologin-popover';
import { GologinPopoverItemRowStatic } from '../../gologin-popover/gologin-popover-item-row';
import CreateFolderForm from '../create-folder-form';
import FolderSubmenu from '../folder-submenu';
import { IconSearch, IconMeatballs, IconPlus } from '../icons';
import { AddFolderContainer, Text } from '../styles';

interface IFoldersMenu {
  anchorElFolders: HTMLElement | null;
  setAnchorElFolders: (state: null) => void;
  setFolderToShare: (folder: IFolder | null) => void;
  setShareFolderVisible: (state: boolean) => void;
}

const FoldersMenu: FC<IFoldersMenu> = ({ anchorElFolders, setAnchorElFolders, setFolderToShare, setShareFolderVisible }) => {
  const [anchorElFolderSubmenu, setAnchorElFolderSubmenu] = React.useState<HTMLElement | null>(null);
  const [searchInput, setSearchInput] = useState<string>('');
  const [addFolderInput, setAddFolderInput] = useState<string>('');
  const [hoveredFolder, setHoveredFolder] = useState<IFolder|null>(null);

  const { id: workspaceId, updateWorkspace } = useContext(workspaceContext);

  const selectedFolderId = useSelectedFolderId();
  const foldersList = useFoldersList();

  const { t: translation } = useTranslation();

  const refreshWorkspace = async (): Promise<void> => {
    const workspaceData = await requestWorkspace(workspaceId);
    updateWorkspace({ ...workspaceData });
  };

  const doRemoveFolder = (folderToRemove: IFolder, oldFolders: IFolder[] = [], oldSelectedFolder = ''): void => {
    setAnchorElFolderSubmenu(null);
    clear();

    let cachedFolders = foldersList.filter(folder => folder.id !== folderToRemove.id);
    if (oldFolders.length) {
      cachedFolders = oldFolders;
      setSelectedFolderId(oldSelectedFolder);
    }

    if (!cachedFolders.length) {
      setAnchorElFolders(null);
    }

    localStorage.setItem('folders-order', JSON.stringify(cachedFolders));
    setFoldersList(cachedFolders);

    let shouldResetFolder = false;
    // TODO: add a rollback
    if (!oldFolders.length) {
      removeFolderFromFoldersList(folderToRemove.id);
      if (selectedFolderId === folderToRemove.id) {
        resetSelectedFolderId();
        shouldResetFolder = true;
      }
    }

    if (selectedFolderId === folderToRemove.id && !oldFolders.length) {
      setSelectedFolderId(null);
      shouldResetFolder = true;
    }

    if (shouldResetFolder) {
      if (NEW_FEATURES.objectPool) {
        forceReloadProfilesQuery();
      } else {
        setAnchorElFolders(null);
        toggleSelectedFolder();
      }
    }
  };

  const onRemoveFolder = async (): Promise<void> => {
    if (!hoveredFolder) {
      return;
    }

    const targetFolder = foldersList.find(folder => folder.id === hoveredFolder.id);

    if (!targetFolder) {
      return;
    }

    const oldFolderList = [...foldersList];

    doRemoveFolder(targetFolder);

    try {
      await removeWorkspaceFolder(workspaceId, hoveredFolder.name);
      refreshWorkspace();
    } catch (err) {
      doRemoveFolder(targetFolder, oldFolderList, oldSelectedFolder);
    }
  };

  const doRenameFolder = (folderToRename: IFolder, newName: string): void => {
    setAnchorElFolderSubmenu(null);

    const newFolderArray = foldersList.map(folder => ({
      ...folder,
      name: folder.id === folderToRename.id ? newName : folder.name,
    }));

    localStorage.setItem('folders-order', JSON.stringify(newFolderArray));
    setFoldersList(newFolderArray);
    if (selectedFolderId === folderToRename.id) {
      setSelectedFolderId(selectedFolderId);
    }

    clear();
  };

  const onRenameFolder = async (folderName: string): Promise<void> => {
    const newFolderName = folderName.replace(/\s+/g, ' ');
    if (!hoveredFolder) {
      return;
    }

    if (!(newFolderName.trim() || '')) {
      message.error(<Trans i18nKey='notifications.error.emptyFolder' />);

      return;
    }

    if (newFolderName === hoveredFolder.name) {
      clear();

      return;
    }

    const existingFolder = foldersList.find(folder => folder.name === newFolderName);
    if (existingFolder) {
      message.error(`Folder "${newFolderName}" already exists`);

      return;
    }

    const targetFolder = foldersList.find(folder => folder.id === hoveredFolder.id);

    if (!targetFolder) {
      return;
    }

    doRenameFolder(targetFolder, newFolderName);

    try {
      await renameWorkspaceFolder(workspaceId, targetFolder.name, newFolderName);
      refreshWorkspace();
      message.success(<Trans i18nKey='notifications.success.renameFolder' />);
    } catch (err) {
      doRenameFolder(targetFolder, targetFolder.name);
    }
  };

  const createNewFolder = async (folderName: string): Promise<void> => {
    const inputFolderName = folderName.replace(/\s+/g, ' ').trim();

    if (!inputFolderName) {
      message.error(<Trans i18nKey='notifications.error.emptyFolder' />);

      return;
    }

    const existingFolder = foldersList.find(folder => folder.name === inputFolderName);
    if (existingFolder) {
      message.error(`Folder "${inputFolderName}" already exists`);

      return;
    }

    const createdFolder = await createWorkspaceFolder(workspaceId, inputFolderName);
    refreshWorkspace();

    onClose();

    const maxFolderOrder = foldersList.reduce<number>((acc, folder) => {
      const folderOrder = folder.order ?? 0;
      if (acc < folderOrder) {
        return folderOrder;
      }

      return acc;
    }, 0);

    const newFolderOrder = maxFolderOrder + 1;
    const newFoldersList = [...foldersList, { ...createdFolder, order: newFolderOrder }];
    const folderToAdd = { ...createdFolder, order: newFolderOrder };
    localStorage.setItem('folders-order', JSON.stringify(newFoldersList));
    setFoldersList(newFoldersList);

    // TODO: try to remove request block after fixing foldersList state
    // test case: enable grouping in one folder and create another folder
    //
    // update to the folder list updates userContext,
    // which leads to profiles table re-render,
    // but because folder isn't a part of the profiles query
    // and because it takes it from localStorage,
    // it causes only one invalid render of profiles with grouping enabled
    setShouldProfilesQueryMakeRequests(false);
    addFolderToFoldersList(folderToAdd);
    setShouldProfilesQueryMakeRequests(true);

    toggleSelectedFolder(createdFolder);
  };

  const onKeyPressInput = (e: React.KeyboardEvent, folderName: string): void => {
    if (e.key !== 'Enter') {
      return;
    }

    const existingFolder = filteredFolders.find(folder => folder.name === folderName);

    if (existingFolder) {
      toggleSelectedFolder(existingFolder);
      onClose();

      return;
    }

    e.preventDefault();
    createNewFolder(folderName);
  };

  const onClickMeatballs = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation();
    setAnchorElFolderSubmenu(event.currentTarget);
  };

  const clear = (): void => {
    setHoveredFolder(null);
    setFolderToShare(null);
    setAddFolderInput('');
    setSearchInput('');
    setAnchorElFolderSubmenu(null);
  };

  const onClose = (): void => {
    setAnchorElFolders(null);
    clear();
  };

  const openModalShareFolder = (): void => {
    if (NEW_FEATURES.workspaceInviteInFolders) {
      openGlobalModal(INVITE_MEMBER_MODAL_NAME, [hoveredFolder.id]);
    } else {
      setShareFolderVisible(true);
    }

    setHoveredFolder(null);
    setAddFolderInput('');
    setSearchInput('');
    setAnchorElFolders(null);
    setAnchorElFolderSubmenu(null);
  };

  const filteredFolders = !searchInput ? foldersList : foldersList
    .filter(folder => folder.name.toLowerCase()?.includes(searchInput.toLowerCase()));

  return (
    <GologinPopover
      anchorEl={anchorElFolders}
      onClose={onClose}
      maxHeight={214}
      zIndex={FOLDERS_POPOVER_Z_INDEX}
    >
      <GologinPopoverItemRowStatic style={{ display: 'flex' }}>
        <IconSearch styleType={'lightGray'} padding={0} margin={'0 8px 0 0'} iconColor='var(--B5B5BA-header-folders-menu)' />
        <SearchFolderInput
          value={searchInput}
          placeholder={translation('folders.menu.searchFolders')}
          onChange={(e): void => setSearchInput(e.target.value)}
          onKeyPress={(e): void => onKeyPressInput(e, searchInput)}
          autoFocus={true}
        />
      </GologinPopoverItemRowStatic>
      <FolderContainer>
        {filteredFolders.map(item => (
          <FolderRow
            key={item.name}
            onClick={(): void => {
              toggleSelectedFolder(item);
              onClose();
            }}
            isSelected={!!(item.id === selectedFolderId || (anchorElFolderSubmenu && hoveredFolder?.id === item.id))}
            onMouseOver={(): void => {
              setHoveredFolder(item);
              setFolderToShare(item);
            }}
            onMouseLeave={(): false | void => !anchorElFolderSubmenu && setHoveredFolder(null)}
          >
            <FolderNameText>
              {searchInput ? (
                <FoundText>
                  {searchBold(searchInput, item.name)}
                </FoundText>
              ) : item.name}
            </FolderNameText>
            {hoveredFolder?.id === item.id ? (
              <div style={{ display: 'flex' }}>
                <ContainerIcon onClick={onClickMeatballs}>
                  <IconMeatballs
                    padding={0}
                    styleType={anchorElFolderSubmenu ? 'darkGray' : 'activeGray'}
                    iconColor={anchorElFolderSubmenu ? 'var(--2B2B31-header-folders-menu)' : 'var(--gologin-popover-icon-color)'}
                    iconHoveredColor='var(--2B2B31-header-folders-menu)'
                  />
                </ContainerIcon>
              </div>
            ) : null}
          </FolderRow>
        ))}
        {searchInput && !foldersList.find(folder => folder.name === searchInput) ? (
          <GologinPopoverItemRowStatic
            style={{ display: 'block' }}
            onClick={(): Promise<void> => createNewFolder(searchInput)}
          >
            <AddFolderContainer
              colorIconType='activeGray'
              iconColor='var(--gologin-popover-icon-color)'
              iconHoveredColor='var(--2B2B31-header-folders-menu)'
              iconType='stroke'
              textColor='var(--767676-header-folders-menu)'
              textHoveredColor='var(--2B2B31-header-folders-menu)'
            >
              <IconPlus
                padding={0}
                margin={'0 10px 0 0'}
              />
              <Text>
                <Trans i18nKey='folders.menu.add' />
                {' '}
                {searchInput}
              </Text>
            </AddFolderContainer>
          </GologinPopoverItemRowStatic>
        ) : null}
      </FolderContainer>

      {!searchInput ? (
        <GologinPopoverItemRowStatic style={{ display: 'block' }}>
          <CreateFolderForm folderName={addFolderInput} setFolderName={setAddFolderInput} onKeyPress={onKeyPressInput} />
        </GologinPopoverItemRowStatic>
      ) : null}

      {hoveredFolder && foldersList.length ? (
        <FolderSubmenu
          anchorEl={anchorElFolderSubmenu}
          setAnchorEl={setAnchorElFolderSubmenu}
          folder={hoveredFolder}
          setFolder={setHoveredFolder}
          onRemoveFolder={onRemoveFolder}
          onRenameFolder={onRenameFolder}
          openModalShareFolder={openModalShareFolder}
        />
      ) : null}

    </GologinPopover>
  );
};

export default FoldersMenu;
