import React, { useRef } from 'react';

import ProxySectionContextMenuPopover from './proxy-section-context-menu-popover';
import { IconMeatballsWrapper } from './styles';
import {
  hideProxySectionContextMenu,
  ProxySectionContextMenuTargetElement,
  toggleProxySectionContextMenuVisibility,
  useProxySectionContextMenuTargetRef,
} from '../../../../../../state/proxy/proxy-groups/proxy-section-context-menu.atom';
import { IconMeatballs } from '../../../../../../ui/gologin-header/icons';

const ICON_ACTIVE_COLOR = 'var(--2B2B31-header)';

export const ProxySectionContextMenu: React.FC = () => {
  const proxySectionContextMenuTargetRef = useProxySectionContextMenuTargetRef();

  const buttonWrapperRef = useRef<ProxySectionContextMenuTargetElement>(null);

  const handleClick = (): void => {
    toggleProxySectionContextMenuVisibility(buttonWrapperRef);
  };

  const handleClose = (): void => {
    hideProxySectionContextMenu();
  };

  return (
    <>
      <IconMeatballsWrapper ref={buttonWrapperRef}>
        <IconMeatballs
          padding={0}
          iconColor={proxySectionContextMenuTargetRef?.current ? ICON_ACTIVE_COLOR : 'var(--98989F-header)'}
          iconHoveredColor={ICON_ACTIVE_COLOR}
          onClick={handleClick}
        />
      </IconMeatballsWrapper>
      <ProxySectionContextMenuPopover
        anchorElement={proxySectionContextMenuTargetRef?.current || null}
        handleClose={handleClose}
      />
    </>
  );
};
