import { IImportDolphinProfilesBody, IMigrateCookieBody } from './interfaces/import-dolphin-profiles.interfaces';
import { API_BASE_URL } from '../../../../../common/constants/constants';
import { http } from '../../../../services';

const dolphinApiUrl = 'https://dolphin-anty-api.com';
const dolphinSyncApiUrl = 'https://sync.dolphin-anty-api.com';

export const startMigrationProfilesFromDolphin = async (options: IImportDolphinProfilesBody): Promise<void> =>
  http(`${API_BASE_URL}/browser/import/dolphin`, {
    method: 'POST',
    body: JSON.stringify(options),
  }).catch(() => void 0);

export const saveMigrationCookies = async (options: IMigrateCookieBody): Promise<void> =>
  http(`${API_BASE_URL}/browser/import/migrate-cookies`, {
    method: 'POST',
    body: JSON.stringify(options),
  }).catch(() => void 0);

export const checkDolphinTarif = async (apiKey: string, profileId: string): Promise<any> =>
  fetch(`${dolphinSyncApiUrl}/?actionType=getCookies&browserProfileId=${profileId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${apiKey}`,
    },
  });

export const getProfilesDataFromDolphinApi = async (apiKey: string, limit = 1, page = 1): Promise<any> =>
  fetch(`${dolphinApiUrl}/browser_profiles?limit=${limit}&page=${page}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${apiKey}`,
    },
  });
