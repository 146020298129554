import { createAction } from 'redux-starter-kit';

export const setAuth = createAction<boolean>('Auth/SET_AUTH');

/**
 * Profile actions
 */
export const getListProfiles = createAction('Profile/GET_LIST_PFOFILES');
export const addNewProfile = createAction('Profile/ADD_NEW_PROFILE');
export const UpdateProfile = createAction('Profile/UPDATE_PROFILE');
