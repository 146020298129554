import React from 'react';

import { IOrbitaModalBase, IOrbitaModalWrapper } from '../../interfaces';
import ModalBase from '../../modal-base';

interface IUpdateOrSkipModal extends IOrbitaModalWrapper {
  modalType: 'orbitaUpdateOrRun' | 'orbitaUpdateOrSkip';
  areButtonsDisabled: boolean;
  handleClose?: () => void;
  handleAfterClose: () => void;
  orbitaVersion: `${number}`;
}

const UpdateOrSkip: React.FC<IUpdateOrSkipModal> = (props) => {
  const {
    modalType,
    visible,
    areButtonsDisabled,
    handleSubmit,
    handleCancel,
    handleClose,
    handleAfterClose,
    orbitaVersion,
  } = props;

  const keyBase = `modals.${modalType}.`;

  const modalBaseProps: IOrbitaModalBase = {
    visible,
    handleCancel: handleClose ? handleClose : handleCancel,
    handleAfterClose,
    titleKey: keyBase + 'title',
    body: {
      key: keyBase + 'body',
      options: { orbitaVersion },
    },
    primaryButton: {
      key: keyBase + 'submit',
      isDisabled: areButtonsDisabled,
      handleClick: handleSubmit,
    },
    secondaryButton: {
      key: keyBase + 'cancel',
      isDisabled: areButtonsDisabled,
      handleClick: handleCancel,
    },
  };

  return <ModalBase {...modalBaseProps} />;
};

export default UpdateOrSkip;
