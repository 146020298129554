import React, { FC } from 'react';

import { IProfile } from '../../../interfaces';
import { IIconWrapper } from '../../../ui/gologin-header/icons/wrapper';
import { ILaunchProfileOrbita } from '../components/interfaces/launch-profile-orbita.interface';

export interface IProfilesMenu {
  launchProfileOrbita: (opts: ILaunchProfileOrbita) => Promise<any>;
  updateProfileFingerprints: (profilesData: IProfile[]) => void;
  headerRef: React.Ref<HTMLDivElement>;
}

export type PopconfirmProps = {
  title: string;
  onConfirm: () => void;
  cancelText: string;
  okText: string;
  visible?: boolean;
  disabled?: boolean;
}

export type MenuItemsPropsBase = {
  Icon: FC<IIconWrapper>;
  text: string;
  onClick?: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isVisible?: boolean;
  hasTooltip?: boolean;
  setAnchorElTooltip?: (anchorEl: HTMLElement|null) => void;
}

export type MenuItemsPropsWithConfirm = MenuItemsPropsBase & {
  hasPopconfirm: true;
  popconfirmProps: PopconfirmProps;
}

export type MenuItemsProps = MenuItemsPropsBase | MenuItemsPropsWithConfirm;

export const isMenuItemsPropsWithConfirm = (
  itemProps: MenuItemsProps,
): itemProps is MenuItemsPropsWithConfirm => (itemProps as MenuItemsPropsWithConfirm).hasPopconfirm;
