export const readFile = (file: File): Promise<string> => {
  const reader = new FileReader();

  return new Promise((resolve) => {
    reader.onload = (): void => {
      if (typeof reader.result !== 'string') {
        return resolve('');
      }

      resolve(reader.result);
    };
    reader.readAsText(file);
  });
};
