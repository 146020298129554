import { TFunction } from 'i18next';

export interface ISpintaxList {
  title: string;
  description: string;
}

export const getSpintaxList = (translation: TFunction): ISpintaxList[] => [{
  title: '{number from 1}',
  description: translation('spintax.descriptions.numberFrom'),
}, {
  title: '{today}',
  description: translation('spintax.descriptions.today') + ' ' + new Date().toLocaleDateString('en-GB'),
}, {
  title: '{profile name}',
  description: translation('spintax.descriptions.profileName'),
}, {
  title: '{email}',
  description: translation('spintax.descriptions.email'),
}];
