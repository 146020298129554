import React from 'react';

const IconPasteHttp = () => (
    <svg width="19" height="20" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="23" fill="var(--FFFFFF)"/>
    <path d="M12 2V6H17" stroke="var(--BDBDBD)" strokeWidth="1.3" strokeLinejoin="round"/>
    <path d="M4 5H8" stroke="var(--BDBDBD)" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M4 10H12" stroke="var(--BDBDBD)" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M4 15H7" stroke="var(--BDBDBD)" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M1.75 4C1.75 2.75736 2.75736 1.75 4 1.75H12.4818C13.0899 1.75 13.672 1.99609 14.0957 2.43223L16.6139 5.02448L17.0455 4.6052L16.6139 5.02448C17.0218 5.44441 17.25 6.0068 17.25 6.59225V19C17.25 20.2426 16.2426 21.25 15 21.25H4C2.75736 21.25 1.75 20.2426 1.75 19V4Z" stroke="var(--BDBDBD)" strokeWidth="1.5"/>
    <path d="M17.5364 7.8291L9.88228 15.6899L11.6142 17.5198L19.2684 9.65899L17.5364 7.8291Z" fill="var(--FFFFFF)" stroke="var(--BDBDBD)" strokeWidth="1.3" strokeLinejoin="round"/>
    <path d="M8.98969 18.6686L11.6141 17.5198L9.88217 15.6899L8.98969 18.6686Z" stroke="var(--BDBDBD)" strokeWidth="1.3" strokeLinejoin="round"/>
    </svg>


);

export default IconPasteHttp;
