import { Checkbox } from '@material-ui/core';
import React from 'react';

import { CheckboxChecked } from './checkbox-checked';
import { CheckboxIndeterminate } from './checkbox-indeterminate';
import { GologinNewCheckbox } from './gologin-new-checkbox';
import { IGologinCheckbox } from './interfaces';
import { CheckboxContainer, CheckboxWrapper } from './styles';
import { NEW_FEATURES } from '../../state/feature-toggle/new-features';

export const GologinCheckbox = (props: IGologinCheckbox): JSX.Element => {
  const {
    isDisabled,
    hasPadding,
    onChange,
    checked,
    isIndeterminate,
    isShownOnlyOnHover,
    isFilledOnSelection,
    containerStyle,
  } = props;

  if (NEW_FEATURES.header) {
    let innerPadding: string | undefined;
    if (hasPadding) {
      innerPadding = '16px 6px 15px 12px';
    }

    return (
      <GologinNewCheckbox
        innerPadding={innerPadding}
        {...props}
      />
    );
  }

  return (
    <CheckboxContainer
      style={containerStyle}
      hasPadding={!!hasPadding}
      isShownOnlyOnHover={!!isShownOnlyOnHover}
      checked={checked}
      isFilledOnSelection={!!isFilledOnSelection}
      isDisabled={!!isDisabled}
    >
      <Checkbox
        icon={<CheckboxWrapper />}
        checkedIcon={<CheckboxChecked />}
        indeterminateIcon={<CheckboxIndeterminate />}
        checked={checked}
        indeterminate={!!isIndeterminate}
        onChange={onChange}
      />
    </CheckboxContainer>
  );
};
