import React from 'react';
import { Trans } from 'react-i18next';

import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { IconSpin } from '../../../ui/gologin-header/icons';
import IconProxyPencil from '../../../ui/icons/IconProxyPencil';
import TooltipWrapper from '../../../ui/tooltip-custom/tooltip-wrapper';
import { ProxyEditButtonWrapper } from './styles';

type ProxyEditButtonProps = {
  isProxyRestoring: boolean;
  handleProxyInfoEditClick?: React.MouseEventHandler<Element>;
  connectedProfilesCount?: number;
};

const ProxyEditButton: React.FC<ProxyEditButtonProps> = props => {
  const { isProxyRestoring, handleProxyInfoEditClick, connectedProfilesCount } = props;
  if (isProxyRestoring) {
    return <IconSpin hasSpin={true} size={16} iconColor='var(--767676-proxy-manager-check-proxy-button)' padding={0} />;
  }

  const getTooltipKey = (count: number | undefined): string => {
    if (count === undefined) return 'proxies.editProxy';
    if (count === 0) return 'proxyManager.editButton.noProfile.tooltip';
    return 'proxyManager.editButton.tooltip';
  };

  const tooltipKey = getTooltipKey(connectedProfilesCount);

  const renderValue = (
    <Trans
      i18nKey={tooltipKey}
      values={{ number: `${connectedProfilesCount}` }}
      components={{
        span: <span style={{ textAlign: 'center' }} />,
      }}
    />
  );

  return (
    <TooltipWrapper isTextEllipsis={false} hasTooltipAnimation={true} value={renderValue}>
      <ProxyEditButtonWrapper
        isProxyManagerWithGroups={!!NEW_FEATURES.proxyGroupsV2}
        onClick={handleProxyInfoEditClick}
      >
        <IconProxyPencil />
      </ProxyEditButtonWrapper>
    </TooltipWrapper>
  );
};

export default ProxyEditButton;
