export const greyDark = 'var(--767676)';
export const blackMain = 'var(--2B2B31)';
export const greyLight = 'var(--98989F)';
export const grey = 'var(--81818A)';
export const blackDark = 'var(--1B1B1F)';
export const black = 'var(--222222)';
export const greySelected = 'var(--F2F2F3)';
export const greyRowHovered = 'var(--FAFAFA)';
export const tableBorder = 'var(--EFEFF0)';
export const greyInformers = 'var(--B5B5BA)';
export const greyHovered = 'var(--EAEAEB)';
export const checkboxes = 'var(--D2D2D5)';
export const greenMain = 'var(--00A987)';
export const greenMouseover = 'var(--00997A)';
export const darkGreenFill = 'var(--88D1C3)';
export const redHovered = 'var(--F74F58)';
export const red = 'var(--F5222D)';
export const pink = 'var(--FFA8A8)';
export const greenLightHovered = 'var(--00B893)';
export const greyDarkLight = 'var(--B3B3B8)';
export const blue = 'var(--1890FF)';
export const mouseover = 'var(--F9F9F9)';
export const tableBorderLight = 'var(--E9E9E7)';
