import { useTranslate } from '@tolgee/react';
import React, { FC, useContext } from 'react';

import { CardPrice, CardPriceContainer, CardPriceDiscount, ContainerGreenText } from './styles';
import { useCheckoutCurrency, useDiscountObjById, usePlanObjById, usePriceConfig, userContext } from '../../../../state';
import { IconThumbUp } from '../../../../ui/gologin-header/icons';
import { isDiscountAnnual } from '../../../modalsComponents/components/checkout-modal/utils';
import { E_PERIOD, IMappedPlan } from '../../interfaces';
import { Price } from '../../utils';
import { GreenChoosePlanText } from '../domElements';
import SwitchPlanButton from '../switch-plan-button';

interface IPreviewCardFooter {
  previewPlan: IMappedPlan;
  selectedDiscount: string;
  getPlanInfo: (planId: string, field: string) => string;
  onPlanSelect: (id: string, isUpgrade?: boolean) => Promise<void>;
}

export const PreviewCardFooter: FC<IPreviewCardFooter> = (props) => {
  const {
    previewPlan,
    onPlanSelect,
    selectedDiscount,
    getPlanInfo,
  } = props;

  const { buttonStyle, isCustomPlan, id: selectedPlan } = previewPlan;

  const priceConfig = usePriceConfig();
  const currency = useCheckoutCurrency();
  const selectedPlanObj = usePlanObjById(selectedPlan);
  const selectedDiscounObj = useDiscountObjById(selectedDiscount);

  const { firstPlanSelected } = useContext(userContext);

  const { t: translation } = useTranslate();

  const isAnnual = isDiscountAnnual(selectedDiscount);
  const periodType = isAnnual ? E_PERIOD.ANNUAL : E_PERIOD.MONTHLY;

  let greenTextKey = 'pricing.tariffColumn.freeReturn';
  if (!firstPlanSelected) {
    greenTextKey = 'pricing.tariffColumn.noCardRequired';
  }

  const pricePerMonth = new Price(selectedPlanObj, currency).period(selectedDiscounObj).perMonth(periodType).toLocalString(0, priceConfig);
  const pricePerYear = new Price(selectedPlanObj, currency).toLocalString(0, priceConfig);
  const isPriceMoreFiveDigits = new Price(selectedPlanObj, currency).getAmountInCurrency() >= 10000;

  return (
    <div>
      <SwitchPlanButton
        buttonStyle={buttonStyle}
        columnPlanId={selectedPlan}
        selectedDiscount={selectedDiscount}
        onPlanSelect={onPlanSelect}
        getPlanInfo={getPlanInfo}
      />
      <CardPriceContainer isPriceMoreFiveDigits={isPriceMoreFiveDigits} >
        <CardPriceDiscount isCustomPlan={isCustomPlan}>
          {translation('pricing.sumMonth.textPrice', { price: pricePerMonth })}
        </CardPriceDiscount>
        <CardPrice isCustomPlan={isCustomPlan}>
          {isAnnual ?
            translation('pricing.sumMonth.textPrice', { price: pricePerYear }) :
            null}
        </CardPrice>
      </CardPriceContainer>
      <ContainerGreenText>
        <IconThumbUp iconColor={isCustomPlan ? 'var(--FFFFFF-new-pricing-custom-green-text)' : 'var(--00A987-new-pricing)'} padding={0} />
        <GreenChoosePlanText color={isCustomPlan ? 'var(--FFFFFF-new-pricing-custom-green-text)' : 'var(--00A987-new-pricing)'}>
          {translation(greenTextKey)}
        </GreenChoosePlanText>
      </ContainerGreenText>
    </div>
  );
};
