import Popper from '@material-ui/core/Popper';
import React from 'react';

import { InfoBlock, InfoContainer, Info } from './styles';

interface IProfileSettingsBtnTooltip {
  isVisible: boolean;
  anchorEl: HTMLElement | null;
  children: React.ReactNode;
}

const ProfileSettingsBtnTooltip: React.FC<IProfileSettingsBtnTooltip> = (
  props,
) => {
  const { isVisible, anchorEl, children } = props;

  return (
    <Popper
      open={isVisible}
      anchorEl={anchorEl}
      placement='top'
      style={{
        zIndex: 11000,
        pointerEvents: 'none',
      }}
    >
      <InfoContainer>
        <InfoBlock>
          <Info>
            {children}
          </Info>
        </InfoBlock>
      </InfoContainer>
    </Popper>
  );
};

export default ProfileSettingsBtnTooltip;
