interface DictionaryMap {
    [key: string]: string;
}

export const parseLocale = (locale: string): string => {
  const dictionary: DictionaryMap = {
    en: 'en',
    ru: 'ru-RU',
    fr: 'fr',
    pt: 'pt',
    vi: 'vi-VN',
    ja: 'ja',
    zh: 'zh-Hans-CN',
  };

  const parsedLocale = dictionary[locale];

  if (parsedLocale) {
    return parsedLocale;
  }

  return 'en';
};

export const parseTranslatedKey = (name: string): string =>
  name.split('').filter(char => ![8204, 8205].includes(char.charCodeAt(0))).join('');