import React, { FC, useState } from 'react';

import { Mixed } from './mixed';
import MultipleProxyManager from '../../../../../features/modalsComponents/components/multiple-proxy-manager';

export const MixedProxy: FC = (): JSX.Element => {
  const [isShowProxyManager, setIsShowProxyManager] = useState<boolean>(false);

  return (
    <>
      <div onClick={(): void => setIsShowProxyManager(true)}>
        <Mixed colorText='var(--2B2B31-profile-new-settings)' />
      </div>
      <MultipleProxyManager
        onClose={(): void => setIsShowProxyManager(false)}
        visible={isShowProxyManager}
      />
    </>
  );
};
