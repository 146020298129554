import styled from '@emotion/styled';
import { Button, Input, Select } from 'antd';

export const OverviewRowTitle = styled('div')`
  font-family: Roboto;
  display: block;
  font-size: 14px;
  color: var(--000000-personal-area-overview);
  white-space: nowrap;
  align-self: center;
`;

export const AccountEmail = styled('div')`
  font-family: Roboto;
  font-size: 12px;
  color: var(--000000-personal-area-overview);
  opacity: 0.8;
  display: flex;
  align-self: center;
`;

export const StyledInput = styled(Input)`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const StyledBtn = styled(Button)`
  width: 100%;
  margin-top: 10px;
`;

export const AlignedButton = styled(Button)`
  &.ant-btn {
    padding: 0 11px;
  }
`;

export const OverviewGrid = styled('div')`
  margin: 24px 0;
  
  display: grid;
  grid-template-columns: max-content max-content max-content;
  grid-gap: 20px 24px;
  
  //grid-template-rows: repeat(3, 32px);
  
  & > * {
    align-self: center;
    justify-self: left;
  }
`;

export const SubtitleTransfer = styled('div')`
  font-family: Roboto;
  font-size: 14px;
  color: var(--000000-personal-area-overview);
`;

export const SelectCustom = styled(Select)`
  .ant-select-selection-selected-value {
    margin-right: 8px;
  }
`;
