import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IWebRTC } from '../../../../interfaces';
import { ModernSelect } from '../../../../ui/modern-select';
import { IChangeSetting } from '../../interfaces';
import {
  ContainerParams,
  DrawerParams,
} from '../../styles';

interface IWebRTCPublic extends IChangeSetting{
  webRTCMode: IWebRTC['enable'];
}

export const WebRTCPublic: FC<IWebRTCPublic> = ({ changeSetting, webRTCMode }) => {
  const { t: translation } = useTranslation();

  const selectItems = [{
    title: translation('quickSettings.parameters.basedIP'),
    value: 'enabled',
  }, {
    title: translation('profileSettings.webrtc.disabled'),
    value: 'disabled',
  }];

  return (
    <ContainerParams>
      <DrawerParams>
        <ModernSelect
          currentValue={webRTCMode === true ? 'enabled' : webRTCMode || 'disabled'}
          itemList={selectItems}
          onSelected={(value): void => changeSetting({ webRTC: { enable: value === 'enabled' } })}
        />
      </DrawerParams>
    </ContainerParams>
  );
};
