import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { getBasicTableEntityById } from './profiles-table/basic-table-entities.atom';
import { IProfile } from '../interfaces';
import { RowKey } from '../ui/gologin-table/types';

type IRowMeasurer = () => void;
type IRowMeasurerRegistry = Record<RowKey, IRowMeasurer>;

const profilesTableRowMeasurersAtom = atom<IRowMeasurerRegistry>({});

export const useProfilesTableRowMeasurer = (rowKey: RowKey): IRowMeasurer => {
  const allMeasurers = useAtomValue(profilesTableRowMeasurersAtom);

  return allMeasurers[rowKey];
};

export const setProfilesTableRowMeasurer = (rowKey: RowKey, rowMeasurer: IRowMeasurer): void => {
  getDefaultStore().set(profilesTableRowMeasurersAtom, prev => ({
    ...prev,
    [rowKey]: rowMeasurer,
  }));
};

export const callProfilesTableRowMeasurer = (profileId: IProfile['id']): void => {
  const basicTableEntity = getBasicTableEntityById(profileId);
  if (!basicTableEntity) {
    return;
  }

  const rowKey = basicTableEntity.idx;
  const allMeasurers = getDefaultStore().get(profilesTableRowMeasurersAtom);
  const rowMeasurer = allMeasurers[rowKey];
  if (!rowMeasurer) {
    return;
  }

  try {
    rowMeasurer();
  } catch (err: unknown) {
    // might fail on unmounted components etc
    // it is better to be silenced than crash whole app
  }
};
