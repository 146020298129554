import styled from '@emotion/styled';
import { Tabs } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFoldersList } from '../../../state/folders/folders-list.atom';
import { closeProfilesSettings } from '../../../state/profiles-settings-atom';
import { toggleSelectedFolder, useSelectedFolderId } from '../../../state/selected-folder.atom';
import IconSharing from '../../../ui/icons/IconSharing';

const TabsFolder = styled(Tabs)`
  .ant-tabs {
    &-nav {
      display: flex;
      padding-bottom: 5px;
      .ant-tabs-tab {
        padding: 15px 46px;
        text-align: center;
        color: var(--000000-profile-table-old-folders);
        opacity: 0.6;
        font-weight: 500;
      }
      .ant-tabs-tab-active {
        color: var(--00B078-profile-table-old-folders);
      }
    }
  }
`;

export interface ITabFolder {
  id: string;
  name: string;
  shared?: boolean;
}

const TabProfiles = () => {
  const { t: translation } = useTranslation();

  const selectedFolderId = useSelectedFolderId();
  const foldersList = useFoldersList();

  const selectedFolder = foldersList.find(folder => folder.id === selectedFolderId);
  const selectedFolderName = selectedFolder?.name || 'all';

  const changeTab = async (key: string) => {
    const targetFolder = foldersList.find(folder => folder.name === key);
    toggleSelectedFolder(targetFolder);
    closeProfilesSettings();
  };

  const returnContent = (): JSX.Element => {
    if (!(foldersList && foldersList.length)) {
      return (
        <div></div>
      );
    }

    return (
      <TabsFolder
        activeKey={selectedFolderName}
        size='small'
        onChange={changeTab}
      >
        <Tabs.TabPane
          key='all'
          tab={translation('profiles.tabs.allProfilesTab')}
        />
        {foldersList.map(folderElem => (
          <Tabs.TabPane
            key={folderElem.name}
            tab={folderElem.shared ? (
              <>
                <span style={{ marginRight: '5px' }}>
                  <IconSharing />
                </span>
                {folderElem.name}
              </>
            ) : (
              <span>
                {folderElem.name}
              </span>
            )}
          />
        ))}
      </TabsFolder>
    );
  };

  return (
    <>
      {returnContent()}
    </>
  );
};

export default TabProfiles;
