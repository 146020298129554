export const AUTOMATION_API_BASE_URL = 'https://api.goless.com/v1';
export const AUTOMATION_WEBSITE_BASE_URL = 'https://goless.com';

export const workflowStatuses = {
  public: 'public',
  private: 'private',
  review: 'review',
  rejected: 'rejected',
  outdated: 'outdated',
} as const;
