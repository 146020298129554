import { Table, TableBody, TableHead, TableRow } from '@material-ui/core';
import { message } from 'antd';
import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { DeletedProfileRow } from './deleted-profile-row';
import { RestoreButton } from './restore-button';
import { useDeletedProfilesTable, workspaceContext } from '../../../state';
import { onRestoreProfiles } from '../../../state/deleted-profiles-table.commands';
import { TableHeaderCell } from '../../../ui/styles';

interface IDeletedProfilesTable {
}

export const DeletedProfilesTable: FC<IDeletedProfilesTable> = () => {
  const { deletedProfilesList } = useDeletedProfilesTable();

  const {
    id: workspaceId,
  } = useContext(workspaceContext);

  const { t: translation } = useTranslation();

  const onRestoreProfilesAndSendMessage = async (profileIds: string[]): Promise<void> => {
    const { restoredProfileIds, queuedProfileIds } = await onRestoreProfiles(profileIds, workspaceId);
    if (restoredProfileIds.length) {
      message.success(translation('restoreProfiles.notification.profilesRestore', { count: profileIds.length }));
    } else if (queuedProfileIds.length) {
      message.success(translation('restoreProfiles.notification.restoreMultipleProfiles'));
    }
  };

  const onRestoreAllProfiles = (): void => {
    const profileIdsToRestore = deletedProfilesList.map(deletedProfile => deletedProfile.profileId);
    onRestoreProfilesAndSendMessage(profileIdsToRestore);
  };

  return (
    <Table style={{ width: '100%', marginBottom: 64, marginTop: 16 }}>
      <TableHead>
        <TableRow>
          <TableHeaderCell style={{ width: '220px', paddingLeft: 0 }}>
            {translation('personalArea.restoreProfiles.name')}
          </TableHeaderCell>
          <TableHeaderCell style={{ width: '200px' }}>
            {translation('personalArea.restoreProfiles.deletedAt')}
          </TableHeaderCell>
          <TableHeaderCell style={{ width: '160px' }}>
            {translation('personalArea.restoreProfiles.deletedBy')}
          </TableHeaderCell>
          <TableHeaderCell style={{ width: '220px' }}>
            <RestoreButton
              onRestore={onRestoreAllProfiles}
              isDisabled={!deletedProfilesList.length}
              title={translation('personalArea.restoreProfiles.restoreAllButton')}
            />
          </TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {deletedProfilesList.map(deletedProfile => (
          <DeletedProfileRow
            key={deletedProfile.profileId}
            onRestoreProfile={(): Promise<void> => onRestoreProfilesAndSendMessage([deletedProfile.profileId])}
            name={deletedProfile.name}
            deletedAt={deletedProfile.deletedAt}
            deletedByUserEmail={deletedProfile.deletedByUserEmail}
          />
        ))}
      </TableBody>
    </Table>
  );
};
