import styled from '@emotion/styled';

export const PageWrapper = styled.div`
  background: var(--00A987-auth-pages);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: auto;
  min-height: 100%;
  flex: 1;
  flex-direction: column;
`;
