import React from 'react';
import { Trans } from 'react-i18next';

import { IconCreatePlus } from '../../../../../ui/gologin-header/icons/icon-create-plus';
import TooltipWrapper from '../../../../../ui/tooltip-custom/tooltip-wrapper';
import { CreateProxyButtonText, CreateProxyButtonWrapper } from './styles';

const CreateProxyButton: React.FC = () => (
  <TooltipWrapper
    hasTooltipAnimation={false}
    isTextEllipsis={false}
    value={<Trans i18nKey='proxyManager.createButton.tooltip' />}
  >
    <CreateProxyButtonWrapper>
      <IconCreatePlus size={12} padding={0} iconColor='var(--98989F-header)' />
      <CreateProxyButtonText>
        <Trans i18nKey='proxyManager.groupCreateButton.text' />
      </CreateProxyButtonText>
    </CreateProxyButtonWrapper>
  </TooltipWrapper>
);

export default CreateProxyButton;
