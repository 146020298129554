import css from '@emotion/css';
import React from 'react';
import { Trans } from 'react-i18next';
import { PROXY_MANAGER_LOCATION_UNMASKED_HINT_ANALYTICS_EVENTS } from '../../../../common/constants/analytics';
import { sendActionAnalytics } from '../../../features/common/api';
import { updateHintPreferences, useHintPreferences } from '../../../state/hint-preferences/hint-preferences.atom';
import {
  toggleIsNotificationIconInLocationUnmaskedClicked,
  useIsNotificationIconInLocationUnmaskedClicked,
} from '../../../state/is-notification-icon-in-location-unmasked-clicked.atom';
import {
  closeLocationIsUnmasked,
  useOpenedLocationIsUnmaskedHint,
} from '../../../state/opened-location-is-unmasked-hint-atom.atom';
import { getProfilesListProfileById } from '../../../state/profiles-list.atom';
import { openProxyManager } from '../../../state/proxy/proxy-manager-modal-status.atom';
import { submitHintPreferencesUpdate } from '../../gologin-header/hints-preferences/api';
import { NotificationIcon } from '../../gologin-header/icons/notification-icon';
import GologinPopover from '../../gologin-popover';
import NotificationHint from '../notification-card';

const LocationUnmaskedHint: React.FC = () => {
  const { isOpen: isLocationIsUnmaskedHintOpen, profileId, anchorEl, runCallback } = useOpenedLocationIsUnmaskedHint();
  const [isIconClicked] = useIsNotificationIconInLocationUnmaskedClicked();
  const hintPreferences = useHintPreferences();

  if (!isLocationIsUnmaskedHintOpen) return null;

  const handleClose = () => {
    sendActionAnalytics(PROXY_MANAGER_LOCATION_UNMASKED_HINT_ANALYTICS_EVENTS.closedNoProxyPopover);
    closeLocationIsUnmasked();

    const profile = getProfilesListProfileById(profileId);
    if (profile) {
      runCallback({ profile });
    }
  };

  const handleOpenProxyManager = (profileId: string) => {
    const proxySelectorId = `proxy-selector-${profileId}`;
    const containerElement = document.getElementById(proxySelectorId);
    const profile = getProfilesListProfileById(profileId);

    openProxyManager({
      currentProfileId: profileId,
      containerElement,
      currentProxy: profile?.proxy || null,
      proxySelectorLocation: 'no-location',
      modalView: 'proxy-list',
    });
  };

  const handleAddProxy = async () => {
    if (isIconClicked) {
      updateHintPreferences({ locationIsUnmasked: 'hidden' });

      await submitHintPreferencesUpdate({ ...hintPreferences, locationIsUnmasked: 'hidden' });

      sendActionAnalytics(PROXY_MANAGER_LOCATION_UNMASKED_HINT_ANALYTICS_EVENTS.disabledNotificationsInNoProxyPopover);
    }

    sendActionAnalytics(PROXY_MANAGER_LOCATION_UNMASKED_HINT_ANALYTICS_EVENTS.openedProxyManagerFromNoProxyPopover);
    closeLocationIsUnmasked();
    handleOpenProxyManager(profileId);
  };

  const handleContinue = async () => {
    if (isIconClicked) {
      updateHintPreferences({ locationIsUnmasked: 'hidden' });
      await submitHintPreferencesUpdate({ ...hintPreferences, locationIsUnmasked: 'hidden' });
      sendActionAnalytics(PROXY_MANAGER_LOCATION_UNMASKED_HINT_ANALYTICS_EVENTS.disabledNotificationsInNoProxyPopover);
    }

    const profile = getProfilesListProfileById(profileId);
    if (profile) {
      runCallback({ profile });
    }

    sendActionAnalytics(PROXY_MANAGER_LOCATION_UNMASKED_HINT_ANALYTICS_EVENTS.skippedAddProxyInNoProxyPopover);
    closeLocationIsUnmasked();
  };

  const handleIconClick = () => {
    toggleIsNotificationIconInLocationUnmaskedClicked();
  };

  return (
    <GologinPopover
      anchorEl={anchorEl}
      onClose={handleClose}
      trigger='click'
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      width={300}
      popoverStyle={css`
        padding: 0;
        overflow: visible;
        gap: 0;
      `}
      extraTranslate={{ top: 40, left: -32 }}
      zIndex={1100}
    >
      <NotificationHint
        title={<Trans i18nKey='tableProfiles.hints.noProxy.title' />}
        description={<Trans i18nKey='tableProfiles.hints.noProxy.description' />}
        primaryButton={<Trans i18nKey='tableProfiles.hints.noProxy.addProxyButton' />}
        secondaryButton={<Trans i18nKey='tableProfiles.hints.noProxy.continueButton' />}
        onPrimaryButtonClick={handleAddProxy}
        onSecondaryButtonClick={handleContinue}
        handleClose={handleClose}
        AdditionalIconAfterTitle={
          <NotificationIcon
            padding={0}
            iconHoveredColor='var(--36363D)'
            iconColor={isIconClicked ? 'var(--36363D)' : 'var(--98989F)'}
            onClick={handleIconClick}
            isClicked={isIconClicked}
          />
        }
      />
    </GologinPopover>
  );
};

export default LocationUnmaskedHint;
