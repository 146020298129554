import allFonts from '../features/common/fonts';

let ipcRenderer: Electron.IpcRenderer;

if (window.require) {
  ipcRenderer = window.require('electron').ipcRenderer;
}

export const getSystemFonts = async (os: string) => {
  let systemFonts = ipcRenderer && ((await ipcRenderer.invoke('get-system-fonts-from-file')) || []);
  if (!systemFonts?.length) {
    systemFonts = allFonts
      .filter((fontElem: any) => {
        const fontOs = fontElem.os || [];

        return fontOs.includes(os) || (fontElem.fileNames && !fontOs.length);
      })
      .map((fontElem: any) => (fontElem.value).replace(/[-|\s]/g, '_').toLowerCase());
  }

  return systemFonts;
};

export const getFontsList = async (os = 'win', families: string[] = []) => {
  const systemFonts = await getSystemFonts(os);

  return allFonts.reduce((res: any[], fontElem: any) => {
    const formattedFontName = (fontElem.value.replace(/[-|\s]/g, '_')).toLowerCase();
    const [fontFamily] = formattedFontName.split('_');
    const systemFont = systemFonts.find((elem: string) => elem.startsWith(fontFamily));

    const fontValue = fontElem.value;
    const isUserFont = !!families.find((elem: any) => elem === fontValue);

    if (systemFont && !isUserFont) {
      return res;
    }

    const objToPush = {
      ...fontElem,
      display: 'block',
      checked: isUserFont,
    };

    const fontOs = fontElem.os || [];

    if (systemFont && (fontOs.includes(os) || !fontOs.length)) {
      res.push({
        ...objToPush,
        disabled: true,
      });

      return res;
    }

    const filesToDownload = (fontElem.fileNames || []).length;
    if (!filesToDownload) {
      objToPush.disabled = true;
    }

    if (
      filesToDownload && (fontOs.includes(os) || !fontOs.length) ||
      fontOs.includes(os) && isUserFont
    ) {
      res.push(objToPush);
    }

    return res;
  }, [])
    .sort((fontElem: any, siblingFontElem: any) => {
      if (fontElem.value > siblingFontElem.value) {
        return 1;
      }

      if (fontElem.value < siblingFontElem.value) {
        return -1;
      }

      return 0;
    });
};
