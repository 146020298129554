import getSelectedThemeColor from './get-selected-theme-color';
import { ThemeType } from '../interfaces/theme.type';

const toggleGlobalTheme = (to: ThemeType): void => {
  const [body] = document.getElementsByTagName('body');
  const currentTheme = getSelectedThemeColor(to);
  body.classList.add('disable-animations');
  if (currentTheme === 'dark') {
    body.classList.add('theme-dark');
    body.classList.remove('theme-light');
  } else {
    body.classList.add('theme-light');
    body.classList.remove('theme-dark');
  }

  setTimeout(() => body.classList.remove('disable-animations'), 50);
};

export default toggleGlobalTheme;
