import { NEW_FEATURES } from '../../state/feature-toggle/new-features';
import { getAutoLoginToken } from '../../features/pricing/api';

const isElectron = !!window.require;

const patchIntercomLinks = (): void => {
  if (!isElectron) {
    return;
  }

  const className = 'intercom-container';
  const intercomRoot = document.getElementById(className);
  if (!intercomRoot) {
    return;
  }

  const iframes = intercomRoot.getElementsByTagName('iframe');
  [...iframes].forEach(iframe => {
    const intercomDoc = iframe.contentDocument || iframe.contentWindow?.document;
    if (!intercomDoc) {
      return;
    }

    const links = [...intercomDoc.getElementsByTagName('a')].filter(e => !!e.href);
    links.forEach(link => {
      link.onclick = async (): Promise<void> => {
        let url = link.href;
        if (link.href.includes('app.gologin.com')) {
          url = await tweakGologinURL(link.href);
        }

        window.require('electron').shell.openExternal(url);
      };
    });

    if (!NEW_FEATURES.replaceIntercomCall) {
      return;
    }

    const callUrl = 'https://savvycal.com/eugenestepnov/b664c125';
    const buttons: HTMLButtonElement[] = [...intercomDoc.getElementsByClassName('intercom-messenger-card-button')] as HTMLButtonElement[];
    buttons.forEach(button => {
      const isCallButton = button.getAttribute('aria-label') === 'Pick a time for а demo call';
      if (!isCallButton) {
        return;
      }

      button.onclick = (): void => {
        window.require('electron').shell.openExternal(callUrl);
      };
    });
  });
};

const tweakGologinURL = async (url: string): Promise<string> => {
  const autoLoginTokenReq: any = await getAutoLoginToken().catch(() => {});
  const autoLoginToken = autoLoginTokenReq?.auto_login_token;
  if (!autoLoginToken) {
    return url;
  }

  const intercomWrap = 'https://via.intercom.io/c?url=';
  const intercomWrappedGologinUrl = intercomWrap + 'https%3A%2F%2Fapp.gologin.com';
  if (url.startsWith(intercomWrappedGologinUrl)) {
    let encodedUrl = url.slice(intercomWrap.length);
    if (url.indexOf('&') !== -1) {
      encodedUrl = encodedUrl.slice(0, url.indexOf('&'));
    }

    url = decodeURIComponent(encodedUrl);
  } else if (!url.startsWith('https://app.gologin.com')) {
    // is not recognized valid app url
    return url;
  }

  const preAutoLogin = url.includes('?') ? '&' : '?';
  const autoLoginParam = preAutoLogin + `auto-login-token=${autoLoginToken}`;

  return url + autoLoginParam;
};

export default patchIntercomLinks;
