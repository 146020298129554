import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { WORKSPACE_KEY_NAME } from '../../common/constants/constants';

type UserId = string | null;

export const currentUserIdAtom = atom<UserId>(null);

export const getCurrentUserId = (): string => getDefaultStore().get(currentUserIdAtom) || '';
export const useCurrentUserId = (): UserId => useAtomValue(currentUserIdAtom);
export const setCurrentUserId = (newUserId: UserId): void => {
  getDefaultStore().set(currentUserIdAtom, newUserId);

  if (newUserId) {
    localStorage.setItem(WORKSPACE_KEY_NAME, JSON.stringify({ id: newUserId }));
  }
};

export const resetCurrentUserId = (): void => setCurrentUserId(null);
