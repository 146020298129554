import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconCopy: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M51 10H7V60H51V10Z' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='square' />
      <path d='M13 4H57V54' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='square' />
      <path d='M15 23H43' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='square' />
      <path d='M15 31H43' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='square' />
      <path d='M15 39H43' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='square' />
      <path d='M15 47H26' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='square' />
    </svg>
  </IconWrapper>
);
