import React, { FC } from 'react';

import { IFullProfile } from '../../../../interfaces';
import ModernSwitch from '../../../../ui/modern-switch';
import { ContainerParams } from '../../styles';

interface IBrowserPlugins {
  enableVulnerable: boolean;
  changeSetting: (profileData: Partial<IFullProfile>) => void;
}

export const BrowserPlugins: FC<IBrowserPlugins> = ({ changeSetting, enableVulnerable }) => (
  <ContainerParams>
    <ModernSwitch
      checked={enableVulnerable}
      onChange={(checked): void => changeSetting({ plugins: { enableVulnerable: checked } })}
    />
  </ContainerParams>
);
