import React from 'react';

const IconFinger = () => (
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
          width="16px" height="18px" viewBox="0 0 317.768 317.768">
    <g>
        <g>
            <g>
                <path d="M262.54,125.114c-1.376,0-2.762-0.418-3.959-1.282c-4.836-3.491-8.196-14.103-10.198-22.617
                    c-0.298-0.835-0.571-1.921-1.017-3.694c-1.725-6.844-6.297-25.031-16.584-42.503c-1.899-3.227-0.821-7.383,2.406-9.283
                    c3.23-1.899,7.382-0.825,9.281,2.404c11.275,19.151,16.193,38.711,18.045,46.075c0.215,0.869,0.462,1.832,0.588,2.271
                    c0.154,0.364,0.277,0.739,0.366,1.124c1.496,6.48,4.015,13.729,5.349,15.458c2.775,2.25,3.341,6.3,1.226,9.229
                    C266.714,124.137,264.641,125.114,262.54,125.114z" fill="var(--359D73)"/>
            </g>
            <g>
                <path d="M222.086,38.831c-1.793,0-3.587-0.707-4.918-2.113c-13.216-13.925-29.543-21.685-48.526-23.069
                    c-6.013-0.301-12.058,0.141-17.962,1.311c-3.666,0.726-7.24-1.656-7.969-5.33c-0.729-3.672,1.658-7.243,5.33-7.969
                    c7.022-1.391,14.212-1.913,21.365-1.548c0.044,0.003,0.096,0.007,0.144,0.01c22.487,1.622,41.818,10.797,57.455,27.266
                    c2.58,2.717,2.467,7.008-0.247,9.585C225.44,38.215,223.76,38.831,222.086,38.831z" fill="var(--359D73)"/>
            </g>
            <g>
                <path d="M55.465,89.496c-0.541,0-1.034-0.08-1.535-0.193c-2.584-0.566-4.605-2.585-5.176-5.167
                    c-0.568-2.583,0.462-5.31,2.568-6.908c0.512-0.388,1.062-0.693,1.629-0.919c1.679-0.98,6.557-4.38,10.682-9.835l1.59-2.11
                    c2.447-3.252,5.489-7.299,9.067-11.726c11.717-14.489,23.29-25.63,35.372-34.057c3.069-2.143,7.299-1.388,9.44,1.682
                    c2.146,3.073,1.388,7.299-1.682,9.439c-11.024,7.688-21.681,17.98-32.582,31.465c-3.428,4.24-6.388,8.179-8.771,11.346
                    l-1.612,2.143c-3.679,4.862-7.664,8.35-10.355,10.422C59.46,88.637,57.217,89.496,55.465,89.496z" fill="var(--359D73)"/>
            </g>
            <g>
                <path d="M55.164,159.173c-2.982,0-5.717-1.984-6.54-5.001c-0.984-3.612,1.15-7.342,4.758-8.323
                    c12.515-3.406,24.67-9.075,32.665-13.231c3.321-1.725,7.414-0.435,9.142,2.887c1.729,3.323,0.435,7.416-2.888,9.147
                    c-8.581,4.46-21.681,10.561-35.354,14.282C56.351,159.094,55.752,159.173,55.164,159.173z" fill="var(--359D73)"/>
            </g>
            <g>
                <path d="M105,135.945c-2.165,0-4.296-1.037-5.605-2.957c-2.113-3.095-1.314-7.313,1.776-9.423
                    c24.225-16.534,45.285-38.751,45.472-38.961c18.006-20.625,37.662-29.636,53.877-24.67c16.484,5.05,22.942,15.83,25.457,23.984
                    c1.105,3.578-0.903,7.373-4.479,8.477c-3.583,1.104-7.375-0.905-8.477-4.481c-2.255-7.305-7.796-12.357-16.471-15.013
                    c-12.614-3.862-28.794,8.143-39.728,20.671c-2.271,2.601-24.137,24.905-48.001,41.189
                    C107.647,135.563,106.314,135.945,105,135.945z" fill="var(--359D73)"/>
            </g>
            <g>
                <path d="M67.863,229.921c-2.632,0-5.138-1.543-6.239-4.12c-1.472-3.439,0.127-7.43,3.571-8.901
                    c28.672-12.258,69.27-37.603,85.396-47.931c32.149-20.589,53.661-41.437,60.57-58.698c1.393-3.477,5.342-5.166,8.812-3.778
                    c3.477,1.391,5.171,5.337,3.777,8.817c-10.19,25.462-41.432,49.439-65.847,65.076c-16.411,10.513-57.771,36.326-87.38,48.984
                    C69.653,229.743,68.75,229.921,67.863,229.921z" fill="var(--359D73)"/>
            </g>
            <g>
                <g>
                    <path d="M131.136,67.052c6.406-6.136,12.582-11.067,20.216-15.602c7.637-4.531,16.394-7.982,24.822-8.861
                        c3.679-0.384,6.783-2.794,6.783-6.781c0-3.407-3.098-7.163-6.783-6.781c-21.195,2.214-39.516,13.964-54.629,28.433
                        C115.219,63.522,124.822,73.096,131.136,67.052L131.136,67.052z" fill="var(--359D73)"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M59.525,123.081c9.259-2.782,17.316-8.209,25.817-12.643c8.961-4.671,16.853-11.94,23.911-19.07
                        c6.153-6.22-3.431-15.815-9.592-9.592c-6.356,6.423-13.186,12.609-21.166,16.955c-7.37,4.016-14.767,8.928-22.574,11.276
                        c-3.535,1.062-5.732,4.714-4.738,8.34C52.145,121.844,55.987,124.147,59.525,123.081L59.525,123.081z" fill="var(--359D73)"/>
                </g>
            </g>
            <g>
                <path d="M158.182,138.318c-1.885,0-3.761-0.782-5.099-2.313c-2.471-2.813-2.19-7.096,0.628-9.566
                    c11.68-10.243,21.683-21.163,29.742-32.456c2.177-3.049,6.41-3.757,9.459-1.584c3.049,2.176,3.754,6.409,1.581,9.457
                    c-8.669,12.146-19.38,23.847-31.844,34.777C161.364,137.761,159.77,138.318,158.182,138.318z" fill="var(--359D73)"/>
            </g>
            <g>
                <path d="M59.228,195.012c-2.736,0-5.312-1.667-6.343-4.381c-1.324-3.501,0.438-7.412,3.939-8.739
                    c0.067-0.028,0.253-0.093,0.558-0.199c6.665-2.286,24.513-8.895,46.247-20.764c7.327-4.006,14.45-8.261,21.164-12.659
                    c3.137-2.05,7.339-1.17,9.389,1.962c2.05,3.137,1.17,7.339-1.962,9.389c-7.016,4.588-14.448,9.033-22.094,13.212
                    C87.479,185.2,68.773,192.12,61.786,194.519l-0.238,0.082C60.781,194.882,59.999,195.012,59.228,195.012z" fill="var(--359D73)"/>
            </g>
            <g>
                <path d="M244.142,248.1c-0.712,0-1.438-0.113-2.146-0.353c-11.32-3.774-23.661-12.562-35.598-21.063
                    c-11.32-8.062-25.402-18.104-31.169-17.11c-5.619,0.972-22.697,12.008-28.31,15.632c-3.148,2.033-7.342,1.133-9.375-2.016
                    c-2.033-3.145-1.128-7.344,2.016-9.373c18-11.632,27.354-16.57,33.359-17.604c11.323-1.947,25.238,7.953,41.343,19.428
                    c11.118,7.919,22.62,16.108,32.021,19.246c3.549,1.184,5.469,5.023,4.284,8.572C249.625,246.303,246.979,248.1,244.142,248.1z" fill="var(--359D73)"/>
            </g>
            <g>
                <path d="M83.349,262.059c-2.993,0-5.73-1.995-6.543-5.023c-0.974-3.617,1.167-7.334,4.784-8.312
                    c8.343-2.245,27.918-15.146,43.65-25.516c3.128-2.067,7.333-1.198,9.394,1.93c2.06,3.125,1.196,7.33-1.93,9.391
                    c-21.741,14.328-37.881,24.677-47.588,27.292C84.526,261.979,83.93,262.059,83.349,262.059z" fill="var(--359D73)"/>
            </g>
            <g>
                <path d="M227.01,281.261c-0.77,0-1.546-0.131-2.306-0.407c-5.534-1.995-12.632-6.14-19.486-10.312
                    c-3.199-1.95-4.209-6.122-2.259-9.318c1.947-3.2,6.119-4.213,9.319-2.262c5.944,3.624,12.62,7.542,17.035,9.141
                    c3.521,1.272,5.346,5.156,4.072,8.678C232.383,279.542,229.783,281.261,227.01,281.261z" fill="var(--359D73)"/>
            </g>
            <g>
                <path d="M107.865,292.506c-2.128,0-4.223-1-5.544-2.864c-2.163-3.06-1.434-7.289,1.625-9.452
                    c2.167-1.533,5.291-3.812,8.908-6.451c29.559-21.58,47.164-33.547,55.552-34.995c5.793-1.002,13.236,1.76,27.459,10.188
                    c3.221,1.906,4.291,6.064,2.382,9.288c-1.909,3.221-6.067,4.288-9.288,2.382c-13.318-7.888-17.254-8.562-18.318-8.48
                    c-6.776,1.171-38.025,23.986-49.788,32.572c-3.665,2.673-6.825,4.983-9.079,6.574
                    C110.583,292.099,109.213,292.506,107.865,292.506z" fill="var(--359D73)"/>
            </g>
            <g>
                <path d="M134.893,317.768c-1.701,0-3.4-0.633-4.717-1.909c-2.692-2.604-2.758-6.896-0.154-9.589
                    c3.666-3.788,22.561-22.785,34.422-24.831c10.533-1.824,20.149,5.037,31.271,12.974c3.385,2.412,6.885,4.91,10.697,7.398
                    c3.139,2.046,4.018,6.249,1.975,9.384c-2.046,3.138-6.249,4.021-9.384,1.975c-4.048-2.643-7.826-5.343-11.163-7.721
                    c-8.65-6.174-16.125-11.502-21.084-10.65c-5.006,0.866-17.906,11.526-26.988,20.906
                    C138.432,317.073,136.663,317.768,134.893,317.768z" fill="var(--359D73)"/>
            </g>
            <g>
                <g>
                    <path d="M227.917,139.913c2.898,17.588,15.845,27.108,31.313,33.642c3.408,1.438,7.234,1.061,9.277-2.43
                        c1.684-2.878,0.972-7.837-2.434-9.277c-5.653-2.387-11.561-4.897-16.32-8.854c-5.236-4.356-7.669-10.078-8.761-16.685
                        C239.577,127.692,226.507,131.363,227.917,139.913L227.917,139.913z" fill="var(--359D73)"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M202.249,169.021c8.079,19.927,26.631,34.988,47.061,41.281c8.385,2.58,11.938-10.513,3.607-13.076
                        c-5.092-1.567-8.956-3.556-14.346-6.741c-9.866-5.828-18.87-14.277-23.243-25.067c-1.39-3.422-4.463-5.803-8.343-4.738
                        C203.733,161.57,200.855,165.588,202.249,169.021L202.249,169.021z" fill="var(--359D73)"/>
                </g>
            </g>
        </g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    </svg>



);

export default IconFinger;
