import styled from '@emotion/styled';
import { Radio } from 'antd';

export const TooltipText = styled('span')`
  font-size: 12px;
  color: var(--000000-antd-tooltip-opacity);
`;

export const ElementContainer = styled('div')`
  margin-top: 30px;
`;

export const RadioButton = styled(Radio.Button)`
  min-width: 100px;
  height: 30px;
  text-align: center;
  font-size: 12px;
  line-height: 29px;
`;
