import styled from '@emotion/styled';

export const ScrollbarContainer = styled('div')`
  overflow-y: auto;
  height: 100%;

  &::-webkit-scrollbar {
    background: transparent;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--E0E0E0-scrollbar-custom);
    border-radius: 3px;
  }

  ::-webkit-resizer,
  ::-webkit-scrollbar-button,
  ::-webkit-scrollbar-corner {
    display: none;
  }
`;
