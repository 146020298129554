import { Icon } from 'antd';
import React from 'react';

import { CUSTOM_PLAN_2K_ID, FOREVER_FREE_PLAN_ID, PREVIEW_PLANS } from './constants';
import { IGetPlanInfoParams, IMappedPlan, IPreviewPlan } from './interfaces';
import { IPlan } from '../../interfaces/plan';
import { sendUserEventToServer } from '../../utils/send-analytics-to-server';

const CUSTOM_PLAN_NAME = 'Custom';
const UNAVAILABLE_CUSTOM_PLANS = [
  { id: '5e79088cff111196bf9a3732' },
];

export const getMappedPlans = (plans: IPlan[]): IPreviewPlan[] => PREVIEW_PLANS.map((previewPlan) => {
  const planObj = plans.find(({ id }) => previewPlan.id === id);

  if (planObj && planObj.id === CUSTOM_PLAN_2K_ID) {
    return { ...planObj, name: CUSTOM_PLAN_NAME, ...previewPlan };
  }

  return { ...planObj, ...previewPlan };
});

export const getMappedCustomPlans = (plans: IPlan[]): IMappedPlan[] => {
  const customPreviewPlan = PREVIEW_PLANS.find(({ id }) => id === CUSTOM_PLAN_2K_ID);
  if (!customPreviewPlan) {
    return [];
  }

  return plans
    .filter(({ id, name }) =>
      name.includes(CUSTOM_PLAN_NAME) && !UNAVAILABLE_CUSTOM_PLANS.find((customPlan) => customPlan.id === id))
    .map((customPlan) => ({ ...customPreviewPlan, ...customPlan, name: CUSTOM_PLAN_NAME }));
};

export const getFreePlan = (plans: IPlan[]): IPlan | undefined => plans.find(({ id }) => id === FOREVER_FREE_PLAN_ID);

export const getPlanInfo = (planInfoParams: IGetPlanInfoParams): JSX.Element => {
  const { plans, planId, field, getFeatureStatus = false } = planInfoParams;
  const planObj = plans.find(({ id }) => id === planId);

  if (!planObj) {
    throw new Error('Plan is not defined');
  }

  if (!planObj[field]) {
    return <Icon type='close' style={{ fontSize: 13 }} />;
  }

  if (getFeatureStatus) {
    return <Icon type='check' style={{ fontSize: 13 }} />;
  }

  return planObj[field];
};

export interface IUpdatePlanAndSendStats {
  planId: string;
  onPlanSelect: (id: string) => void;
  firstPlanSelected?: boolean;
}

export const updatePlanAndSendStats = async ({ onPlanSelect, firstPlanSelected, planId }: IUpdatePlanAndSendStats): Promise<void> => {
  await onPlanSelect(planId);

  if (firstPlanSelected) {
    return;
  }

  await sendUserEventToServer('pickedTrialPlan');
};

export const getSelectedCustomPlan = (customPlans: IMappedPlan[], selectedCustomPlanId: string): IMappedPlan => {
  const selectedCustomPlan = customPlans.find(({ id }) => id === selectedCustomPlanId);

  if (!selectedCustomPlan) {
    throw new Error('Custom plan is not defined');
  }

  return selectedCustomPlan;
};
