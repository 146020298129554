import styled from '@emotion/styled';

export const Board = styled.div`
  display: flex;
  overflow-x: hidden;
`;

export const ListWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
`;
