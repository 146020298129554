/* eslint-disable max-lines */
import * as Sentry from '@sentry/react';

import { IProfilesTableColumn, IProfilesTableSettings } from './components/interfaces/app-settings.interface';
import { IProfilesTableSummary } from './components/interfaces/profiles-table-summary.interface';
import { IQuickCreateProfile } from './components/interfaces/quick-create-profile.interface';
import { DEFAULT_SORT_FIELD, DEFAULT_SORT_ORDER } from './constants';
import { API_BASE_URL } from '../../../common/constants/constants';
import { denormalizeProfile, normalizeProxy } from '../../../common/utils';
import { IBookmarkFoldersObj } from '../../../electron/interfaces/bookmarks-utils.interfaces';
import { initGA } from '../../initGA';
import { IProfile, IProfileRunningInWeb, ShareInvitedRole, IProxy } from '../../interfaces';
import { GroupHeader } from '../../interfaces/group-header.interface';
import { http } from '../../services';
import { ReactError } from '../../utils/sentry-parameters/custom-errors';
import { sentryTransactionDefaultObject } from '../../utils/sentry-parameters/helper.functions';
import { ITransactionObject } from '../../utils/sentry-parameters/helper.functions.interfaces';
import { SortOrder } from '../common/sorter-order';
import { IProfileShare } from '../modalsComponents/components/multi-share-modal/interfaces';

export const sendChangeIpRequest = (url: string): Promise<Response | null> => {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    url = 'https://' + url;
  }

  return fetch(url)
    .catch((error) => {
      Sentry.captureException(new ReactError(error.body.message), (scope) => {
        scope.setTag('url', url);
        scope.setLevel('error');
        scope.setTransactionName('proxy-change-ip');
        scope.setFingerprint(['proxy-change-ip']);

        return scope;
      });

      return null;
    });
};

type BrowserRun = 'web' | 'desktop';

export interface IRequestProfiles {
  workspaceId: string;
  search?: string;
  limit?: number;
  folder?: string;
  folderId?: string;
  tag?: string;
  proxyId?: string;
  transaction?: ITransactionObject;
  offset?: number;
  sortField: string;
  sortOrder: SortOrder;
  groupField?: GroupHeader['filter']['type'] | null;
  hiddenGroupIds?: string[];
}

export interface IGroupMetadata {
  groupId: string | null;
  filteredProfilesCount: number;
}

export interface IProfilesResponse {
  profiles: IProfile[];
  total: number;
  isFolderDeleted?: boolean;
  currentOrbitaMajorV?: string;
  currentBrowserV?: string;
  currentTestBrowserV?: string;
  currentTestOrbitaMajorV?: string;
  isMoreProfilesAvailable: boolean;
  groupsMetadata?: IGroupMetadata[];
}

export interface IProfilePatchData {
  id: string;
  isRunning: boolean;
}

interface IProfileStatusUpdateData {
  profiles: IProfilePatchData[];
  type?: BrowserRun;
  shouldSendAnalytics?: boolean;
  isNewRoute?: boolean;
  isPinging?: boolean;
}

interface IProfileStatusUpdateBodyNew {
  profiles: IProfilePatchData[];
  type: BrowserRun;
  googleAnalyticsId: string;
}

interface IProfileStatusUpdateBodyOld {
  running: boolean;
  type: BrowserRun;
  googleClientId: string;
}

export const requestProfiles = async ({
  workspaceId = '',
  search = '',
  limit = 30,
  folder = '',
  folderId = '',
  tag = '',
  proxyId = '',
  transaction = sentryTransactionDefaultObject,
  offset = 0,
  sortField = DEFAULT_SORT_FIELD || '',
  sortOrder = DEFAULT_SORT_ORDER,
  groupField = null,
  hiddenGroupIds = [],
}: IRequestProfiles): Promise<IProfilesResponse> => {
  const route = `${API_BASE_URL}/workspaces/${workspaceId}/profiles`;
  let url = `${route}?search=${encodeURIComponent(search)}&limit=${limit}&offset=${offset}`;

  if (sortField && sortOrder) {
    url += `&sortField=${sortField}&sortOrder=${sortOrder}`;
  }

  if (folder) {
    url += `&folder=${folder}`;
  } else if (folderId) {
    url += `&folderId=${folderId}`;
  }

  if (tag) {
    url += `&tag=${tag}`;
  }

  if (proxyId) {
    url += `&proxyId=${proxyId}`;
  }

  if (groupField) {
    url += `&groupBy=${groupField}`;
  }

  if (hiddenGroupIds?.length) {
    const hiddenGroupIdsStr = hiddenGroupIds.join(',');
    url += `&hiddenGroupIds=${hiddenGroupIdsStr}`;
  }

  const requestSpan = transaction.startChild({ op: 'http', description: 'get-workspace-profile-list' });
  const { body: response } = await http(url, { method: 'GET', headers: { 'transaction-id': transaction.traceId } });
  requestSpan.finish();

  return response;
};

export const getProfilesTableSummaryInfo = async (appVersion = ''): Promise<IProfilesTableSummary> => {
  const data = await http(`${API_BASE_URL}/browser_table/summary_info?appVersion=${appVersion}`).catch(console.log);

  return data.body;
};

export const updateProfileProxy = async (profileId: string, proxy: IProxy): Promise<IProxy> => {
  const normalizedProxy = normalizeProxy(proxy);
  const request = await http(`${API_BASE_URL}/browser/${profileId}/proxy`, {
    method: 'PATCH',
    body: JSON.stringify(normalizedProxy),
  });

  return request.body;
};

export const quickCreateBrowser = async ({
  name,
  os,
  workspaceId,
  template,
  folderName,
  userLatestOrbitaVersion,
  osSpec,
}: IQuickCreateProfile): Promise<IProfile> => {
  const isM1 = os === 'macM1';

  let url = `${API_BASE_URL}/browser/quick`;
  if (workspaceId) {
    url += `?currentWorkspace=${workspaceId}`;
  }

  return http(url, {
    method: 'POST',
    body: JSON.stringify({
      name,
      os: isM1 ? 'mac' : os,
      osSpec,
      isM1,
      folderName,
      template,
      userLatestOrbitaVersion,
    }),
  }).then(({ body: newProfile }: { body: IProfile }) => denormalizeProfile(newProfile));
};

export type PinAction = 'pinned'|'unpinned';

export interface IPinParams {
  workspaceId?: string;
  profileId: string;
  folderId?: string;
}

export const unpin = ({ workspaceId, profileId, folderId }: IPinParams) => {
  let url = `${API_BASE_URL}/browser/${profileId}/pin`;
  if (workspaceId) {
    url = `${API_BASE_URL}/workspaces/${workspaceId}/profiles/${profileId}/pin`;
  }

  return http(url, {
    method: 'DELETE',
    body: JSON.stringify({
      folderId,
    }),
  });
};

export const pin = ({ workspaceId, profileId, folderId }: IPinParams) => {
  let url = `${API_BASE_URL}/browser/${profileId}/pin`;
  if (workspaceId) {
    url = `${API_BASE_URL}/workspaces/${workspaceId}/profiles/${profileId}/pin`;
  }

  return http(url, {
    method: 'POST',
    body: JSON.stringify({
      folderId,
    }),
  }).then((res: any) => res.body);
};

export const updateNotes = async (id: string, notes: string) => {
  try {
    const data = await http(`${API_BASE_URL}/browser/${id}/notes`, {
      method: 'PATCH',
      body: JSON.stringify({
        notes,
      }),
    });
  } catch (error) {
    throw error;
  }
};

export const deleteProfiles = async (profilesToDelete: string[]) => {
  try {
    await http(`${API_BASE_URL}/browser`, {
      method: 'DELETE',
      body: JSON.stringify({
        profilesToDelete,
      }),
    });

    return null;
  } catch (error) {
    throw error;
  }
};

export const deleteWorkspaceProfiles = (workspace: string, profilesToDelete: string[]): Promise<void> =>
  http(`${API_BASE_URL}/workspaces/${workspace}/profiles`, {
    method: 'DELETE',
    body: JSON.stringify({
      profiles: profilesToDelete,
    }),
  });

export const updateRunStatus = async ({
  profiles = [],
  type = 'desktop',
  shouldSendAnalytics = true,
  isPinging = false,
}: IProfileStatusUpdateData): Promise<IProfileRunningInWeb[]> => {
  let googleAnalyticsId = '';
  if (shouldSendAnalytics) {
    googleAnalyticsId = await initGA();
  }

  const endpoint = `${API_BASE_URL}/browser/run-sync?isPinging=${isPinging}`;
  const patchBody: IProfileStatusUpdateBodyNew | IProfileStatusUpdateBodyOld = { profiles, type, googleAnalyticsId };

  return http(endpoint, { method: 'PATCH', body: JSON.stringify(patchBody) })
    .then((response: any) => response.body.profilesRunningInWeb || []).catch(() => []);
};

export const getProfileCookies = async (id: string, isView = true) => {
  const param = isView ? 'view=true' : '';

  try {
    const data = await http(`${API_BASE_URL}/browser/${id}/cookies?${param}`);

    return data.body;
  } catch (error) {
    throw error;
  }
};

export const cleanCookies = (id: string) => http(`${API_BASE_URL}/browser/${id}/cookies?cleanCookies=true`, {
  method: 'POST',
  body: JSON.stringify([]),
});

export const updateProfileCookies = (id: string, cookies: object[]): Promise<void> =>
  http(`${API_BASE_URL}/browser/${id}/cookies?fromUser=true`, {
    method: 'POST',
    body: JSON.stringify(cookies),
  });

export const requestProfileInvites = async (profileId: string) => {
  try {
    const data = await http(`${API_BASE_URL}/share/profile/${profileId}`);

    return data.body;
  } catch (error) {
    throw error;
  }
};

export const requestProfilesInvites = (profileIds: string[]): Promise<IProfileShare[]> =>
  http(`${API_BASE_URL}/share/profile/${profileIds}`)
    .then((res: any) => res.body);

export const updateInviteRole = async (inviteId: string, role: 'administrator' | 'redactor' | 'guest') => {
  const params = JSON.stringify({
    role,
  });

  try {
    await http(`${API_BASE_URL}/share/${inviteId}/role`, {
      method: 'PATCH',
      body: params,
    });
  } catch (error) {
    throw error;
  }
};

interface IUpdateInviteRole {
  inviteIds: string[];
  role: ShareInvitedRole;
}

export const updateManyInviteRoles = ({ inviteIds, role }: IUpdateInviteRole): Promise<void> =>
  http(`${API_BASE_URL}/share/role`, {
    method: 'PATCH',
    body: JSON.stringify({
      role,
      instanceIds: inviteIds,
    }),
  });

export const shareProfile = async (profileId: string, role: 'administrator' | 'redactor' | 'guest', email: string) => {
  const params = JSON.stringify({
    type: 'browser',
    recepient: email,
    role,
    instanceId: profileId,
  });

  try {
    await http(`${API_BASE_URL}/share`, {
      method: 'POST',
      body: params,
    });
  } catch (error) {
    throw error;
  }
};

interface ITransferCallParams {
  workspaceId: string;
  profileIds: string[];
  email: string;
}

export const transferProfile = ({ workspaceId, profileIds, email }: ITransferCallParams): Promise<void> =>
  http(`${API_BASE_URL}/workspaces/${workspaceId}/profiles/transfer`, {
    method: 'POST',
    body: JSON.stringify({
      toEmail: email,
      instanceIds: profileIds,
    }),
  });

interface ITransferToMyWorkspaceParams {
  fromWorkspaceId: string;
  folderName: string;
  targetWorkspaceId: string;
  instanceIds: string[];
}

export const transferToMyWorkspace = (transferParams: ITransferToMyWorkspaceParams): Promise<void> =>
  http(`${API_BASE_URL}/workspaces/profiles/transfer_to_my_workspace`, {
    method: 'POST',
    body: JSON.stringify(transferParams),
  });

export const shareMultipleProfilesToOneUser = async (instanceIds: string[], role: 'administrator' | 'redactor' | 'guest', email: string) => {
  const params = JSON.stringify({
    type: 'browser',
    recepient: email,
    role,
    instanceIds,
  });

  try {
    await http(`${API_BASE_URL}/share/multi`, {
      method: 'POST',
      body: params,
    });
  } catch (error) {
    throw error;
  }
};

interface IShareMultipleParams {
  instanceIds: string[];
  role: ShareInvitedRole;
  emails: string[];
  workspaceId: string;
}

export const shareMultipleProfiles = async ({
  instanceIds,
  role,
  emails,
  workspaceId,
}: IShareMultipleParams): Promise<IProfileShare[]> => {
  const params = JSON.stringify({
    type: 'browser',
    recepients: emails,
    role,
    instanceIds,
  });

  return http(`${API_BASE_URL}/share/multi?currentWorkspace=${workspaceId}`, {
    method: 'POST',
    body: params,
  }).then((res: any) => res.body);
};

export const removeProfileInvite = async (inviteId: string): Promise<void> => {
  try {
    await http(`${API_BASE_URL}/share/${inviteId}`, {
      method: 'DELETE',
    });
  } catch (error) {
    throw error;
  }
};

export const removeMultipleInvites = async (invites: string[]): Promise<void> => {
  try {
    const requestBody = JSON.stringify({
      invitesForDelete: invites,
    });

    await http(`${API_BASE_URL}/share`, {
      method: 'DELETE',
      body: requestBody,
    });
  } catch (error) {
    throw error;
  }
};

export const cloneProfile = async (profileId: string, folderName = ''): Promise<void> => {
  try {
    await http(`${API_BASE_URL}/browser/${profileId}/clone`, {
      method: 'POST',
      body: JSON.stringify({
        folderName,
      }),
    });
  } catch (error) {
    throw error;
  }
};

export const cloneProfileMulti = async (profileIds: string[], folderName = '') => {
  try {
    await http(`${API_BASE_URL}/browser/clone_multi`, {
      method: 'POST',
      body: JSON.stringify({
        browsersIds: profileIds,
        folderName,
      }),
    });
  } catch (error) {
    throw error;
  }
};

export const cloneWorkspaceProfiles = (workspaceId: string, profileIds: string[]): Promise<void> =>
  http(`${API_BASE_URL}/workspaces/${workspaceId}/profiles/clone`, {
    method: 'POST',
    body: JSON.stringify({
      browsersIds: profileIds,
    }),
  });

export const saveProfileTableColumns = async (columnsSettings: IProfilesTableColumn[]) => {
  try {
    await http(`${API_BASE_URL}/gologin-settings/profiles-table-columns`, {
      method: 'POST',
      body: JSON.stringify({
        columnsSettings,
      }),
    });
  } catch (error) {
    throw error;
  }
};

export const saveWorkspaceProfileTableColumns = (workspaceId: string, columnsSettings: IProfilesTableColumn[]): Promise<void> =>
  http(`${API_BASE_URL}/gologin-settings/profiles-table-columns`, {
    method: 'POST',
    body: JSON.stringify({
      columnsSettings,
      workspace: workspaceId,
    }),
  }).then((res: any) => res.body);

export const saveProfileTableColumnsOrder = async (columnsOrder: string[]) => {
  try {
    await http(`${API_BASE_URL}/gologin-settings/profiles-table-columns-order`, {
      method: 'POST',
      body: JSON.stringify({
        columnsOrder,
      }),
    });
  } catch (error) {
    throw error;
  }
};

export const saveWorkspaceProfileTableColumnsOrder = (workspaceId: string, columnsOrder: string[]): Promise<void> =>
  http(`${API_BASE_URL}/gologin-settings/profiles-table-columns-order`, {
    method: 'POST',
    body: JSON.stringify({
      columnsOrder,
      workspace: workspaceId,
    }),
  }).then((res: any) => res.body);

export const getFoldersList = async () => {
  try {
    const data = await http(`${API_BASE_URL}/folders`);

    return data.body;
  } catch (error) {
    throw error;
  }
};

export const getWorkspaceProfileTableSettings = (workspaceId: string): Promise<IProfilesTableSettings> => {
  const query = new URLSearchParams({
    workspace: workspaceId || '',
  });

  return http(`${API_BASE_URL}/gologin-settings/profiles-table-settings?${query}`, {
    method: 'GET',
  }).then((res: any) => res.body);
};

export const updateBookmarksMultipleProfiles = async (profileIds: string[], bookmarks: IBookmarkFoldersObj): Promise<void> => {
  const params = JSON.stringify({
    profileIds,
    bookmarks,
  });

  await http(`${API_BASE_URL}/browser/bookmarks/many`, {
    method: 'PATCH',
    body: params,
  });
};

interface IReorderFolderProfiles {
  workspaceId: string;
  folderId: string;
  profileId: string;
  order: number;
}

interface IReorderManyFolderProfiles {
  workspaceId: IReorderFolderProfiles['workspaceId'];
  folderId: IReorderFolderProfiles['workspaceId'];
  profiles: Array<{ profileId: string; order: number }>;
}

export const reorderFolderProfiles = ({
  workspaceId,
  folderId,
  profileId,
  order,
}: IReorderFolderProfiles): void =>
  http(`${API_BASE_URL}/workspaces/${workspaceId}/folders/${folderId}/profiles/order`, {
    method: 'PATCH',
    body: JSON.stringify({
      profileId,
      order,
    }),
  }).then((res: any) => res.body);

export const reorderManyFolderProfiles = ({
  workspaceId,
  folderId,
  profiles,
}: IReorderManyFolderProfiles): void =>
  http(`${API_BASE_URL}/workspaces/${workspaceId}/folders/${folderId}/profiles/order/many`, {
    method: 'PATCH',
    body: JSON.stringify({
      profiles,
    }),
  }).then((res: any) => res.body);
