import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconSortSmallDown: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M6 11.0723L8 13.0723L10 11.0723' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8 13.072V2.5' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  </IconWrapper>
);
