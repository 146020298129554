import styled from '@emotion/styled';

export const Wrapper = styled('div')`
  font-family: 'Roboto', serif;
  line-height: initial;
`;

export const InformationText = styled('div')`
  width: 440px;
  margin-bottom: 10px;
  font-weight: 300;
  font-size: 16px;
`;
