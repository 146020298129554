import { ITextCenteringParams, ITextYValues } from '../../../interfaces';
import { DOUBLING_VALUE } from '../../get-values';

export const centerTextVerticallyStandard = ({
  canvasSize,
  textFontSizeBase,
  lineHeightRatio,
  scaleFactor,
}: ITextCenteringParams, isWin10BigIcon: boolean): ITextYValues => {
  let textFont = textFontSizeBase * scaleFactor;
  if (!isWin10BigIcon) {
    textFont = textFont / DOUBLING_VALUE;
  }

  const textHeightTotal = (1 + lineHeightRatio) * textFont;
  const yValueFirstLine = (canvasSize - textHeightTotal) / DOUBLING_VALUE;
  const yValueSecondLine = yValueFirstLine + textFont * lineHeightRatio;

  return { yValueFirstLine, yValueSecondLine };
};
