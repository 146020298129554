import { ANNUAL_DISCOUNT, MONTH_DISCOUNT, EASY_PLAN_ID } from '../features/modalsComponents/components/checkout-modal/constants';

export const PAYPAL_SUBSCRIPTIONS_IDS = [
  {
    planId: EASY_PLAN_ID,
    links: [
      {
        discountId: MONTH_DISCOUNT,
        subscriptionId: 'P-4B113387UH0641525L7RQDUQ',
      },
      {
        discountId: ANNUAL_DISCOUNT,
        subscriptionId: 'P-9G434946BJ316203CL7RQHAI',
      },
    ],
  },
  {
    planId: '5e3aab2c8481ec0b30795d1f',
    links: [
      {
        discountId: MONTH_DISCOUNT,
        subscriptionId: 'P-3PJ357075W236303LL7RQAMQ',
      },
      {
        discountId: ANNUAL_DISCOUNT,
        subscriptionId: 'P-4C074504BG0879137L7RQEMY',
      },
    ],
  },
  {
    planId: '5e3aab4f8481ec0b30795d20',
    links: [
      {
        discountId: MONTH_DISCOUNT,
        subscriptionId: 'P-79A444662E052944HL7RQCAI',
      },
      {
        discountId: ANNUAL_DISCOUNT,
        subscriptionId: 'P-2XY09892E1119292YL7RQE3Y',
      },
    ],
  },
  {
    planId: '5e3aab7d8481ec0b30795d21',
    links: [
      {
        discountId: MONTH_DISCOUNT,
        subscriptionId: 'P-02L20853UC333125XL7RQCGQ',
      },
      {
        discountId: ANNUAL_DISCOUNT,
        subscriptionId: 'P-15B07287P9163905FL7RQFHY',
      },
    ],
  },
  {
    planId: '5e79088cff111196bf9a3729',
    links: [
      {
        discountId: MONTH_DISCOUNT,
        subscriptionId: 'P-3TE20668E55620426L7RQCTA',
      },
      {
        discountId: ANNUAL_DISCOUNT,
        subscriptionId: 'P-1FA65882DB854725SL7RQGQY',
      },
    ],
  },
  {
    planId: '5e79088cff111196bf9a3730',
    links: [
      {
        discountId: MONTH_DISCOUNT,
        subscriptionId: 'P-3S7838783G246243PL7RQC4I',
      },
      {
        discountId: ANNUAL_DISCOUNT,
        subscriptionId: 'P-8U2725983N7202221L7RQGYY',
      },
    ],
  },
  {
    planId: '605b395119cbecc84cc9ce0d',
    links: [
      {
        discountId: MONTH_DISCOUNT,
        subscriptionId: 'P-8AS52778TP5716647MBOEL5I',
      },
      {
        discountId: ANNUAL_DISCOUNT,
        subscriptionId: 'P-5T170099Y1072594YMBOELJQ',
      },
    ],
  },
  {
    planId: '5e79088cff111196bf9a3732',
    links: [
      {
        discountId: MONTH_DISCOUNT,
        subscriptionId: 'P-32158693E1726681TMCESKZY',
      },
      {
        discountId: ANNUAL_DISCOUNT,
        subscriptionId: 'P-7W370907AY0469600MCESL5A',
      },
    ],
  },
  {
    planId: '5e79088cff111196bf9a3733',
    links: [
      {
        discountId: MONTH_DISCOUNT,
        subscriptionId: 'P-9CV82995LL660662RMCRXBXI',
      },
      {
        discountId: ANNUAL_DISCOUNT,
        subscriptionId: 'P-6W457005RH807362BMCRXA5A',
      },
    ],
  },
  {
    planId: '5e79088cff111196bf9a3731',
    links: [
      {
        discountId: MONTH_DISCOUNT,
        subscriptionId: 'P-52812092917257548MCRXU4Q',
      },
      {
        discountId: ANNUAL_DISCOUNT,
        subscriptionId: 'P-4VC7958061621274KMCRXV2I',
      },
    ],
  },
  {
    planId: '5e79088cff111196bf9a3734',
    links: [
      {
        discountId: MONTH_DISCOUNT,
        subscriptionId: 'P-9YA46191HX354764AMEGWESI',
      },
      {
        discountId: ANNUAL_DISCOUNT,
        subscriptionId: 'P-4UM39975MR837584KMEGWFKI',
      },
    ],
  },
  {
    planId: '62a8577b7c60487d6ec73c55',
    links: [
      {
        discountId: MONTH_DISCOUNT,
        subscriptionId: 'P-7L742569PD061591MMTW5HVQ',
      },
      {
        discountId: ANNUAL_DISCOUNT,
        subscriptionId: 'P-7L742569PD061591MMTW5HVQ',
      },
    ],
  },
  {
    planId: '6686a0937bdeb8449233cd62',
    links: [
      {
        discountId: MONTH_DISCOUNT,
        subscriptionId: 'P-0BE93398EL629682MM2HKO5Y',
      },
      {
        discountId: ANNUAL_DISCOUNT,
        subscriptionId: 'P-6FD055236D195981WM2HKP3Q',
      },
    ],
  },
  {
    planId: '6686a3e97bdeb8449233cd64',
    links: [
      {
        discountId: MONTH_DISCOUNT,
        subscriptionId: 'P-983566473S6127926M2HKRLQ',
      },
      {
        discountId: ANNUAL_DISCOUNT,
        subscriptionId: 'P-4Y849357PG9487155M2HKR3Q',
      },
    ],
  },
  {
    planId: '6687d2ad7bdeb8449233cd68',
    links: [
      {
        discountId: MONTH_DISCOUNT,
        subscriptionId: 'P-01473184C86563645M2HKSLA',
      },
      {
        discountId: ANNUAL_DISCOUNT,
        subscriptionId: 'P-0U578726934454631M2HKS7Q',
      },
    ],
  },
  {
    planId: '6687d32d7bdeb8449233cd6b',
    links: [
      {
        discountId: MONTH_DISCOUNT,
        subscriptionId: 'P-05H62221984672201M2HKT5I',
      },
      {
        discountId: ANNUAL_DISCOUNT,
        subscriptionId: 'P-2SY06313Y11714725M2HKU4A',
      },
    ],
  },
  {
    planId: '6687d3df7bdeb8449233cd6d',
    links: [
      {
        discountId: MONTH_DISCOUNT,
        subscriptionId: 'P-26151678UB428572VM2HKV2I',
      },
      {
        discountId: ANNUAL_DISCOUNT,
        subscriptionId: 'P-8FG64302WM773533FM2HKWPA',
      },
    ],
  },
];
