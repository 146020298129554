import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconArrowBack: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M15.5 8H0.5' stroke='var(--222222)' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M5.5 13L0.5 8L5.5 3' stroke='var(--222222)' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  </IconWrapper>
);
