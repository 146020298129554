import styled from '@emotion/styled';
import { Input } from 'antd';

export const Row = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const InputText = styled(Input)`
  width: 350px;
  @media screen and (max-width: 450px) {
    width: 100%;
  }
`;

export const SubTitle = styled('span')`
  display: inline-block;
  font-size: 12px;
  line-height: 14px;
  width: auto;
`;

export const SwitchTitle = styled(SubTitle)`
  margin-left: 10px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
`;

export const SwitchContainer = styled('div')`
  margin-left: 10px;
  white-space: nowrap;
  display: flex;
  align-items: center;

  .MuiSwitch-colorSecondary.Mui-checked {
    background-color: var(--FFFFFF-antd);
    color: var(--FFFFFF-antd);
  }

  .MuiSwitch-track {
    height: 22px;
    width: 44px;
    border-radius: 100px;
    background-color: var(--00000040-antd);
    opacity: 1;
  }

  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    opacity: 1;
    background-color: var(--00B86D-antd);
  }

  .MuiIconButton-root {
    padding: 0;
  }

  .MuiSwitch-switchBase {
    top: 2px;
    left: 2px;
    color: var(--FFFFFF-antd);
  }

  .PrivateSwitchBase-root-1 {
    padding: 0;
  }

  .MuiSwitch-root {
    padding: 0;
    width: 44px;
    height: auto;
  }

  .MuiSwitch-thumb {
    height: 18px;
    width: 18px;
  }
`;
