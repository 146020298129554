import styled from '@emotion/styled';
import { Input } from 'antd';

export const Wrapper = styled('div')`
  display: flex;
  height: 32px;
`;

export const SearchInput = styled(Input)`
  height: 32px;
  outline: none;

  input, input:focus {
    padding-right: 0;
    border: none;
    box-shadow: none;
  }

  &::placeholder {
    margin-left: 10px;
    color: var(--767676);
  }
`;
