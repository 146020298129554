import React, { FC } from 'react';

import ModernSwitch from '../../../../ui/modern-switch';
import { IChangeSetting } from '../../interfaces';
import { ContainerParams } from '../../styles';

interface IBrowserHistorySaving extends IChangeSetting{
  history: boolean;
}

export const BrowserHistorySaving: FC<IBrowserHistorySaving> = ({ changeSetting, history }) => (
  <ContainerParams>
    <ModernSwitch
      checked={history}
      onChange={(checked): void => changeSetting({ storage: { history: checked } })}
    />
  </ContainerParams>
);
