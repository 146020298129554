import { atom, getDefaultStore, useAtomValue } from 'jotai';

import {
  E_CURRENCY,
  E_PAYMENT_COUNTRY,
  IPriceConfig,
  IPricingConfig,
  IPricingConfigCountry,
  PaymentType,
  TCurrency,
  TPaymentCountry,
  TPaymentTab,
} from '../../features/pricing/interfaces';

const paymentModalPricingConfigAtom = atom<IPricingConfig>({} as IPricingConfig);
const paymentModalPaymentTypeAtom = atom<TPaymentTab>(PaymentType.Card);
const paymentModalPaymentCountryAtom = atom<TPaymentCountry>(E_PAYMENT_COUNTRY.ANY);

const currentTabShownCurrencyAtom = atom((get) => {
  const paymentType = get(paymentModalPaymentTypeAtom);
  const paymentCountry = get(paymentModalPaymentCountryAtom);
  const pricingConfig = get(paymentModalPricingConfigAtom).pricingPageConfig;

  return pricingConfig[paymentCountry].tabs.find(tab => tab.type === paymentType)?.currency || E_CURRENCY.USD;
});

const checkoutCurrencyAtom = atom<TCurrency>((get) => {
  const paymentCountry = get(paymentModalPaymentCountryAtom);
  const pricingConfig = get(paymentModalPricingConfigAtom).pricingPageConfig;

  return pricingConfig[paymentCountry].checkoutCurrency;
});

const priceConfigAtom = atom<IPriceConfig>((get) => {
  const { currencyFactors, currencyLocales, currencyFractionDigits } = get(paymentModalPricingConfigAtom);

  return {
    currencyFactors,
    currencyLocales,
    currencyFractionDigits,
  };
});

export const usePriceConfig = (): IPriceConfig => useAtomValue(priceConfigAtom);
export const usePaymentModalPricingConfig = (): IPricingConfig => useAtomValue(paymentModalPricingConfigAtom);
export const usePaymentModalPaymentType = (): TPaymentTab => useAtomValue(paymentModalPaymentTypeAtom);
export const usePaymentModalPaymentCountry = (): TPaymentCountry => useAtomValue(paymentModalPaymentCountryAtom);
export const useCheckoutCurrency = (): TCurrency => useAtomValue(checkoutCurrencyAtom);
export const useCurrentTabShownCurrency = (): TCurrency => useAtomValue(currentTabShownCurrencyAtom);
export const useModalShownTabsConfig = (): IPricingConfigCountry => {
  const { pricingPageConfig } = usePaymentModalPricingConfig();
  const country = usePaymentModalPaymentCountry();

  return pricingPageConfig[country];
};

export const getCurrentTabShownCurrency = (): TCurrency => getDefaultStore().get(currentTabShownCurrencyAtom);
export const setPaymentModalPaymentType = (paymentType: TPaymentTab): void => getDefaultStore().set(paymentModalPaymentTypeAtom, paymentType);
export const getPaymentModalPaymentType = (): TPaymentTab => getDefaultStore().get(paymentModalPaymentTypeAtom);
export const getModalShownTabsConfig = (country: TPaymentCountry): IPricingConfigCountry => {
  const pricingConfig = getDefaultStore().get(paymentModalPricingConfigAtom).pricingPageConfig;

  return pricingConfig[country];
};

export const getPriceConfig = (): IPriceConfig => {
  const { currencyFactors, currencyLocales, currencyFractionDigits } = getDefaultStore().get(paymentModalPricingConfigAtom);

  return {
    currencyFactors,
    currencyLocales,
    currencyFractionDigits,
  };
};

export const getCheckoutCurrency = (): TCurrency => getDefaultStore().get(checkoutCurrencyAtom);
export const setPricingConfig = (data: IPricingConfig): void => getDefaultStore().set(paymentModalPricingConfigAtom, data);
export const getPaymentModalPaymentCountry = (): TPaymentCountry => getDefaultStore().get(paymentModalPaymentCountryAtom);
export const getPricingConfig = (): IPricingConfig => getDefaultStore().get(paymentModalPricingConfigAtom);
export const setPaymentModalPaymentCountry = (data: string): void => {
  if (!data) {
    getDefaultStore().set(paymentModalPaymentCountryAtom, E_PAYMENT_COUNTRY.ANY);
  }

  const paymentCountry = data.toLowerCase() as TPaymentCountry;
  const pricingConfig = getDefaultStore().get(paymentModalPricingConfigAtom).pricingPageConfig;
  if (!pricingConfig) {
    return;
  }

  const COUNTRIES = Object.keys(pricingConfig);

  if (COUNTRIES.includes(paymentCountry)) {
    getDefaultStore().set(paymentModalPaymentCountryAtom, paymentCountry);
    const paymentType = pricingConfig[paymentCountry]?.tabs[0]?.type || PaymentType.Card;
    setPaymentModalPaymentType(paymentType);

    return;
  }

  getDefaultStore().set(paymentModalPaymentCountryAtom, E_PAYMENT_COUNTRY.ANY);
};
