import { TFunction } from 'i18next';
import React from 'react';

import {
  cloneProfile,
  deleteOrLeaveMultipleProfiles,
  startProfiles,
  stopProfiles,
  toggleModalTransfer,
} from './actions';
import { MenuItemsProps } from './interfaces';
import { getRunningProfileCount } from '../../../state/profile-run-statuses.atom';
import {
  FOLDERS_MANAGER_MODAL_NAME,
  openProfilesTableModal,
  PROFILE_EXTENSIONS_MODAL_NAME,
  SHARE_MODAL_NAME,
} from '../../../state/profiles-table-modal.atom';
import { getProfilesTableSelectedIds, getProfilesTableSelectedProfilesActions } from '../../../state/profiles-table-selected-ids.atom';
import {
  IconBookmark,
  IconClone2,
  IconEdit,
  IconExport,
  IconFolder,
  IconPen,
  IconPlay,
  IconPlug,
  IconRefresh,
  IconShare,
  IconStop2,
  IconSwap,
  IconTrash,
} from '../../../ui/gologin-header/icons';
import { IconProxyDataCenter } from '../../../ui/gologin-header/icons/icon-proxy-datacenter';
import { ILaunchProfileOrbita } from '../components/interfaces/launch-profile-orbita.interface';
import { IconAutomation } from '../../../ui/gologin-header/icons/icon-automation';
import { sendActionAnalytics } from '../../common/api';

const isElectron = !!window.require;

type ActionButtonsParam = {
  translation: TFunction;
  isBrowserUpdating?: boolean;
  setAnchorElTooltip?: (anchorEl: HTMLElement|null) => void;
  setIsShowProxyManager: (state: boolean) => void;
  handleEditTagsClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  openBookmarksManager: () => void;
  onRenameProfiles: () => void;
  exportProfile: () => void;
  showExportPayPopUp?: boolean;
  redirectToSite: () => void;
  updateFingerprints: () => void;
  isProcessing: boolean;
  launchProfileOrbita: (opts: ILaunchProfileOrbita) => Promise<void>;
  openAutomationModal: () => void;
}

export const getActionButtons = (actionButtonParam: ActionButtonsParam): MenuItemsProps[] => {
  const {
    translation,
    isBrowserUpdating,
    setAnchorElTooltip,
    setIsShowProxyManager,
    handleEditTagsClick,
    openBookmarksManager,
    openAutomationModal,
    onRenameProfiles,
    exportProfile,
    showExportPayPopUp,
    redirectToSite,
    updateFingerprints,
    isProcessing,
    launchProfileOrbita,
  } = actionButtonParam;

  const selectedProfilesIds = getProfilesTableSelectedIds();

  const limitForLaunch = getRunningProfileCount() >= 5 ? 5 : 10;
  const isStartDisabled = selectedProfilesIds.length > limitForLaunch;
  const isHasPopconfirmToStartProfiles = !isBrowserUpdating && isStartDisabled;

  const onStartProfile = async (): Promise<void> => {
    if (isHasPopconfirmToStartProfiles) {
      return;
    }

    await startProfiles(launchProfileOrbita);
  };

  const renderTitleButtonToRemoveProfile = (): string => {
    const selectedProfilesActions = getProfilesTableSelectedProfilesActions();
    if (selectedProfilesActions.length === 2) {
      return `${translation('base.delete')} / ${translation('base.leave')}`;
    }

    if (selectedProfilesActions.length === 1) {
      return translation(`base.${selectedProfilesActions[0].toLowerCase()}`);
    }

    return translation('base.delete');
  };

  return [
    {
      Icon: IconPlay,
      text: translation('profiles.multipleOperations.run'),
      onClick: onStartProfile,
      isVisible: isElectron,
      hasTooltip: isBrowserUpdating,
      setAnchorElTooltip,
      hasPopconfirm: isHasPopconfirmToStartProfiles,
      popconfirmProps: {
        title: translation('profiles.multipleOperations.confirms.startProfilesFirstPart') + translation('profiles.multipleOperations.confirms.startProfilesSecondPart'),
        onConfirm: () => startProfiles(launchProfileOrbita),
        cancelText: translation('base.no'),
        okText: translation('base.yes'),
      },
    },
    {
      Icon: IconStop2,
      text: translation('profiles.multipleOperations.stop'),
      onClick: stopProfiles,
    },
    {
      Icon: IconFolder,
      text: translation('profiles.multipleOperations.addToFolder'),
      onClick: () => openProfilesTableModal(FOLDERS_MANAGER_MODAL_NAME, selectedProfilesIds),
    },
    {
      Icon: IconProxyDataCenter,
      text: translation('proxy.title'),
      onClick: () => setIsShowProxyManager(true),
    },
    {
      Icon: IconShare,
      text: translation('profiles.multipleOperations.share'),
      onClick: (): void => {
        sendActionAnalytics('visited share profile', { actionInfo: 'mass operations'});
        openProfilesTableModal(SHARE_MODAL_NAME, getProfilesTableSelectedIds());
      },
    },
    {
      Icon: IconEdit,
      text: translation('tags.editTagsBtnText'),
      onClick: handleEditTagsClick,
    },
    {
      Icon: IconPlug,
      text: translation('profiles.multipleOperations.extensions'),
      onClick: (): void => openProfilesTableModal(PROFILE_EXTENSIONS_MODAL_NAME, selectedProfilesIds),
    },
    {
      Icon: IconBookmark,
      text: translation('profilesTable.multipleOperations.bookmarks'),
      onClick: openBookmarksManager,
    },
    {
      Icon: IconPen,
      text: translation('profiles.multipleOperations.renameProfiles', { count: selectedProfilesIds.length }),
      onClick: onRenameProfiles,
    },
    {
      Icon: IconClone2,
      text: translation('profiles.menu.clone'),
      onClick: () => cloneProfile(translation),
    },
    {
      Icon: IconSwap,
      text: translation('profiles.multipleOperations.transfer'),
      onClick: (): void => toggleModalTransfer(translation),
    },
    {
      Icon: IconExport,
      text: translation('base.export'),
      onClick: exportProfile,
      hasPopconfirm: showExportPayPopUp,
      popconfirmProps: {
        title: translation('profiles.multipleOperations.confirms.exportProfilesUpgradePlan'),
        onConfirm: redirectToSite,
        cancelText: translation('base.cancel'),
        okText: translation('personalArea.billing.changePlanSubmitBtnText'),
      },
    },
    {
      Icon: IconRefresh,
      text: translation('profiles.multipleOperations.updateFingerprint'),
      hasPopconfirm: true,
      popconfirmProps: {
        title: translation('profiles.multipleOperations.confirms.newFingerprints'),
        onConfirm: updateFingerprints,
        cancelText: translation('base.no'),
        okText: translation('base.yes'),
        disabled: isProcessing,
      },
    },
    {
      Icon: IconTrash,
      text: renderTitleButtonToRemoveProfile(),
      hasPopconfirm: true,
      popconfirmProps: {
        title: translation('profiles.multipleOperations.confirms.deleteProfiles'),
        onConfirm: () => deleteOrLeaveMultipleProfiles(translation),
        cancelText: translation('base.no'),
        okText: translation('base.yes'),
        disabled: isProcessing,
      },
    },
    {
      Icon: IconAutomation,
      text: translation('profiles.multipleOperations.automation'),
      onClick: openAutomationModal,
      isVisible: isElectron,
    },
  ];
};
