import styled from '@emotion/styled';
import { Button } from 'antd';

export const Component = styled('div')`
  margin-top: 23px;
  display: flex;
  flex-direction: column;
  line-height: 20px;
`;

export const InLineComponent = styled(Component)`
  margin-right: 20px;
`;

export const RowComponent = styled(Component)`
  margin-top: 0px;
  margin-bottom: 40px;
  flex-direction: row;
`;

export const Title = styled('div')`
  font-size: 16px;
  line-height: 19px;
  padding: 10px 0;
  border-bottom: 0.6px solid var(--E7E7E7);
`;

export const SubTitle = styled('span')`
  display: inline-block;
  font-size: 12px;
  line-height: 14px;
  padding-bottom: 10px;
`;

export const GetProxyBtn = styled(Button)`
  font-size: 12px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoaderDivProxy = styled.div`
  margin-top: 10px;
  width: 300px;
  text-align: center;
  font-size: 30px;
`;
