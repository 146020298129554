import React from 'react';

const IconResyncProfile = () => (
  <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 8.50121C0.999738 7.09515 1.45542 5.72691 2.29867 4.60177C3.14193 3.47663 4.32729 2.65526 5.67692 2.2609C7.02655 1.86654 8.46766 1.92044 9.78406 2.41452C11.1005 2.9086 12.2212 3.81622 12.978 5.00121" stroke="var(--767676)" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13 1V5H9" stroke="var(--767676)" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.0014 8.5C14.0017 9.90606 13.546 11.2743 12.7028 12.3994C11.8595 13.5246 10.6741 14.3459 9.32452 14.7403C7.97489 15.1347 6.53377 15.0808 5.21738 14.5867C3.90098 14.0926 2.78029 13.185 2.02344 12" stroke="var(--767676)" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M2 16V12H6" stroke="var(--767676)" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default IconResyncProfile;
