import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconPlug: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_22721_42932)'>
        <path d='M3.5 0.5V3.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M8.5 0.5V3.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M0.5 3.5H11.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M10.5 3.5V7C10.5 8.19347 10.0259 9.33807 9.18198 10.182C8.33807 11.0259 7.19347 11.5 6 11.5C4.80653 11.5 3.66193 11.0259 2.81802 10.182C1.97411 9.33807 1.5 8.19347 1.5 7V3.5' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_22721_42932'>
          <rect width='12' height='12' fill='white' />
        </clipPath>
      </defs>
    </svg>
  </IconWrapper>
);

