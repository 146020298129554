export default [
  { name: 'Pacific/Niue', offset: '−11:00', latitude: -19.0333, longitude: -169.8667 },
  { name: 'Pacific/Pago_Pago', offset: '−11:00', latitude: -14.2783, longitude: -170.7026  },
  { name: 'Pacific/Honolulu', offset: '−10:00', latitude: 21.3069, longitude: -157.8583 },
  { name: 'Pacific/Rarotonga', offset: '−10:00', latitude: -21.207, longitude: -159.775 },
  { name: 'Pacific/Tahiti', offset: '−10:00', latitude: -17.6509, longitude: -149.426 },
  { name: 'Pacific/Marquesas', offset: '−09:30', latitude: -9.7814, longitude: -139.0814 },
  { name: 'America/Anchorage', offset: '−09:00', latitude: 61.2181, longitude: -149.9003 },
  { name: 'Pacific/Gambier', offset: '−09:00', latitude: -23.1333, longitude: -134.95 },
  { name: 'America/Los_Angeles', offset: '−08:00', latitude: 34.0522, longitude: -118.2437 },
  { name: 'America/Tijuana', offset: '−08:00', latitude: 32.5149, longitude: -117.0382 },
  { name: 'America/Vancouver', offset: '−08:00', latitude: 49.2827, longitude: -123.1207 },
  { name: 'America/Whitehorse', offset: '−08:00', latitude: 60.7212, longitude: -135.0568 },
  { name: 'Pacific/Pitcairn', offset: '−08:00', latitude: -25.0661, longitude: -130.0839 },
  { name: 'America/Dawson_Creek', offset: '−07:00', latitude: 55.7558, longitude: -120.2363 },
  { name: 'America/Denver', offset: '−07:00', latitude: 39.7392, longitude: -104.9903 },
  { name: 'America/Edmonton', offset: '−07:00', latitude: 53.5461, longitude: -113.4938 },
  { name: 'America/Hermosillo', offset: '−07:00', latitude: 29.0729, longitude: -110.9559 },
  { name: 'America/Mazatlan', offset: '−07:00', latitude: 23.6345, longitude: -102.5528 },
  { name: 'America/Phoenix', offset: '−07:00', latitude: 33.4484, longitude: -112.074 },
  { name: 'America/Yellowknife', offset: '−07:00', latitude: 62.454, longitude: -114.3718 },
  { name: 'America/Belize', offset: '−06:00', latitude: 17.251, longitude: -88.759 },
  { name: 'America/Chicago', offset: '−06:00', latitude: 41.8781, longitude: -87.6298 },
  { name: 'America/Costa_Rica', offset: '−06:00', latitude: 9.9281, longitude: -84.0907 },
  { name: 'America/El_Salvador', offset: '−06:00', latitude: 13.6929, longitude: -89.2182 },
  { name: 'America/Guatemala', offset: '−06:00', latitude: 14.6349, longitude: -90.5069 },
  { name: 'America/Managua', offset: '−06:00', latitude: 12.114, longitude: -86.2362 },
  { name: 'America/Mexico_City', offset: '−06:00', latitude: 19.4326, longitude: -99.1332 },
  { name: 'America/Regina', offset: '−06:00', latitude: 50.4452, longitude: -104.6189 },
  { name: 'America/Tegucigalpa', offset: '−06:00', latitude: 14.0723, longitude: -87.1921 },
  { name: 'America/Winnipeg', offset: '−06:00', latitude: 49.8951, longitude: -97.1384 },
  { name: 'Pacific/Galapagos', offset: '−06:00', latitude: -0.9008, longitude: -89.6094 },
  { name: 'America/Bogota', offset: '−05:00', latitude: 4.7109, longitude: -74.0721 },
  { name: 'America/Cancun', offset: '−05:00', latitude: 21.1619, longitude: -86.8515 },
  { name: 'America/Cayman', offset: '−05:00', latitude: 19.329, longitude: -81.39 },
  { name: 'America/Guayaquil', offset: '−05:00', latitude: -0.2295, longitude: -78.5249 },
  { name: 'America/Havana', offset: '−05:00', latitude: 23.1136, longitude: -82.3666 },
  { name: 'America/Iqaluit', offset: '−05:00', latitude: 63.7467, longitude: -68.517 },
  { name: 'America/Jamaica', offset: '−05:00', latitude: 18.1096, longitude: -77.2975 },
  { name: 'America/Lima', offset: '−05:00', latitude: -12.0464, longitude: -77.0428 },
  { name: 'America/Nassau', offset: '−05:00', latitude: 25.0343, longitude: -77.3963 },
  { name: 'America/New_York', offset: '−05:00', latitude: 40.7128, longitude: -74.006 },
  { name: 'America/Panama', offset: '−05:00', latitude: 8.9833, longitude: -79.5167 },
  { name: 'America/Port-au-Prince', offset: '−05:00', latitude: 18.5944, longitude: -72.3074 },
  { name: 'America/Rio_Branco', offset: '−05:00', latitude: -9.9754, longitude: -67.8249 },
  { name: 'America/Toronto', offset: '−05:00', latitude: 43.651, longitude: -79.347 },
  { name: 'Pacific/Easter', offset: '−05:00', latitude: -27.1127, longitude: -109.3497 },
  { name: 'America/Caracas', offset: '−04:30', latitude: 10.4806, longitude: -66.9036 },
  { name: 'America/Asuncion', offset: '−03:00', latitude: -25.2637, longitude: -57.5759 },
  { name: 'America/Barbados', offset: '−04:00', latitude: 13.1132, longitude: -59.5988 },
  { name: 'America/Boa_Vista', offset: '−04:00', latitude: 2.8235, longitude: -60.6758 },
  { name: 'America/Campo_Grande', offset: '−03:00', latitude: -20.4697, longitude: -54.6201 },
  { name: 'America/Cuiaba', offset: '−03:00', latitude: -15.5962, longitude: -56.0969 },
  { name: 'America/Curacao', offset: '−04:00', latitude: 12.1696, longitude: -68.99 },
  { name: 'America/Grand_Turk', offset: '−04:00', latitude: 21.4664, longitude: -71.136 },
  { name: 'America/Guyana', offset: '−04:00', latitude: 6.8013, longitude: -58.1551 },
  { name: 'America/Halifax', offset: '−04:00', latitude: 44.6488, longitude: -63.5752 },
  { name: 'America/La_Paz', offset: '−04:00', latitude: -16.5004, longitude: -68.149 },
  { name: 'America/Manaus', offset: '−04:00', latitude: -3.4653, longitude: -62.2159 },
  { name: 'America/Martinique', offset: '−04:00', latitude: 14.6415, longitude: -61.0242 },
  { name: 'America/Port_of_Spain', offset: '−04:00', latitude: 10.6596, longitude: -61.4789 },
  { name: 'America/Porto_Velho', offset: '−04:00', latitude: -8.7619, longitude: -63.9039 },
  { name: 'America/Puerto_Rico', offset: '−04:00', latitude: 18.2208, longitude: -66.5901 },
  { name: 'America/Santo_Domingo', offset: '−04:00', latitude: 18.4861, longitude: -69.9312 },
  { name: 'America/Thule', offset: '−04:00', latitude: 76.5667, longitude: -68.7847 },
  { name: 'Atlantic/Bermuda', offset: '−04:00', latitude: 32.3078, longitude: -64.7505 },
  { name: 'America/St_Johns', offset: '−03:30', latitude: 47.5605, longitude: -52.7126 },
  { name: 'America/Araguaina', offset: '−03:00', latitude: -7.1928, longitude: -48.2044 },
  { name: 'America/Argentina/Buenos_Aires', offset: '−03:00', latitude: -34.6037, longitude: -58.3816 },
  { name: 'America/Bahia', offset: '−03:00', latitude: -12.9818, longitude: -38.4813 },
  { name: 'America/Belem', offset: '−03:00', latitude: -1.4558, longitude: -48.4902 },
  { name: 'America/Cayenne', offset: '−03:00', latitude: 4.922, longitude: -52.3135 },
  { name: 'America/Fortaleza', offset: '−03:00', latitude: -3.7319, longitude: -38.5267 },
  { name: 'America/Godthab', offset: '−03:00', latitude: 64.1836, longitude: -51.7214 },
  { name: 'America/Maceio', offset: '−03:00', latitude: -9.6498, longitude: -35.7089 },
  { name: 'America/Miquelon', offset: '−03:00', latitude: 47.0833, longitude: -56.3333 },
  { name: 'America/Montevideo', offset: '−03:00', latitude: -34.8833, longitude: -56.1833 },
  { name: 'America/Paramaribo', offset: '−03:00', latitude: 5.8333, longitude: -55.1667 },
  { name: 'America/Recife', offset: '−03:00', latitude: -8.05, longitude: -34.9 },
  { name: 'America/Santiago', offset: '−03:00', latitude: -33.45, longitude: -70.6667 },
  { name: 'America/Sao_Paulo', offset: '−02:00', latitude: -23.55, longitude: -46.6333 },
  { name: 'Antarctica/Palmer', offset: '−03:00', latitude: -64.8, longitude: -64.1 },
  { name: 'Antarctica/Rothera', offset: '−03:00', latitude: -67.5667, longitude: -68.1333 },
  { name: 'Atlantic/Stanley', offset: '−03:00', latitude: -51.6833, longitude: -57.85 },
  { name: 'America/Noronha', offset: '−02:00', latitude: -3.85, longitude: -32.4167 },
  { name: 'Atlantic/South_Georgia', offset: '−02:00', latitude: -54.2667, longitude: -36.5333 },
  { name: 'America/Scoresbysund', offset: '−01:00', latitude: 70.4833, longitude: -21.9667 },
  { name: 'Atlantic/Azores', offset: '−01:00', latitude: 37.7333, longitude: -25.6667 },
  { name: 'Atlantic/Cape_Verde', offset: '−01:00', latitude: 14.9167, longitude: -23.5167 },
  { name: 'Africa/Abidjan', offset: '+00:00', latitude: 5.3167, longitude: -4.0333 },
  { name: 'Africa/Accra', offset: '+00:00', latitude: 5.55, longitude: -0.2167 },
  { name: 'Africa/Bissau', offset: '+00:00', latitude: 11.85, longitude: -15.5833 },
  { name: 'Africa/Casablanca', offset: '+00:00', latitude: 33.65, longitude: -7.5833 },
  { name: 'Africa/El_Aaiun', offset: '+00:00', latitude: 27.15, longitude: -13.2 },
  { name: 'Africa/Monrovia', offset: '+00:00', latitude: 6.3, longitude: -10.7833 },
  { name: 'America/Danmarkshavn', offset: '+00:00', latitude: 76.7667, longitude: -18.6667 },
  { name: 'Atlantic/Canary', offset: '+00:00', latitude: 28.1, longitude: -15.4 },
  { name: 'Atlantic/Faroe', offset: '+00:00', latitude: 62.0167, longitude: -6.7667 },
  { name: 'Atlantic/Reykjavik', offset: '+00:00', latitude: 64.15, longitude: -21.85 },
  { name: 'Etc/', offset: '+00:00', latitude: 0, longitude: 0 },
  { name: 'Europe/Dublin', offset: '+00:00', latitude: 53.3333, longitude: -6.25 },
  { name: 'Europe/Lisbon', offset: '+00:00', latitude: 38.7167, longitude: -9.1333 },
  { name: 'Europe/London', offset: '+00:00', latitude: 51.5083, longitude: -0.1253 },
  { name: 'Africa/Algiers', offset: '+01:00', latitude: 36.75, longitude: 3.04 },
  { name: 'Africa/Ceuta', offset: '+01:00', latitude: 35.8833, longitude: -5.3167 },
  { name: 'Africa/Lagos', offset: '+01:00', latitude: 6.45, longitude: 3.4 },
  { name: 'Africa/Ndjamena', offset: '+01:00', latitude: 12.1167, longitude: 15.05 },
  { name: 'Africa/Tunis', offset: '+01:00', latitude: 36.8, longitude: 10.1833 },
  { name: 'Africa/Windhoek', offset: '+02:00', latitude: -22.57, longitude: 17.0836 },
  { name: 'Europe/Amsterdam', offset: '+01:00', latitude: 52.3667, longitude: 4.9 },
  { name: 'Europe/Andorra', offset: '+01:00', latitude: 42.5, longitude: 1.5167 },
  { name: 'Europe/Belgrade', offset: '+01:00', latitude: 44.8333, longitude: 20.5 },
  { name: 'Europe/Berlin', offset: '+01:00', latitude: 52.5, longitude: 13.3667 },
  { name: 'Europe/Brussels', offset: '+01:00', latitude: 50.8333, longitude: 4.3333 },
  { name: 'Europe/Budapest', offset: '+01:00', latitude: 47.5, longitude: 19.0833 },
  { name: 'Europe/Copenhagen', offset: '+01:00', latitude: 55.6667, longitude: 12.5833 },
  { name: 'Europe/Gibraltar', offset: '+01:00', latitude: 36.1333, longitude: -5.35 },
  { name: 'Europe/Luxembourg', offset: '+01:00', latitude: 49.6, longitude: 6.1167 },
  { name: 'Europe/Madrid', offset: '+01:00', latitude: 40.4, longitude: -3.6833 },
  { name: 'Europe/Malta', offset: '+01:00', latitude: 35.9, longitude: 14.5167 },
  { name: 'Europe/Monaco', offset: '+01:00', latitude: 43.7, longitude: 7.3833 },
  { name: 'Europe/Oslo', offset: '+01:00', latitude: 59.9167, longitude: 10.75 },
  { name: 'Europe/Paris', offset: '+01:00', latitude: 48.8667, longitude: 2.3333 },
  { name: 'Europe/Prague', offset: '+01:00', latitude: 50.0833, longitude: 14.4333 },
  { name: 'Europe/Rome', offset: '+01:00', latitude: 41.9, longitude: 12.4833 },
  { name: 'Europe/Stockholm', offset: '+01:00', latitude: 59.3333, longitude: 18.05 },
  { name: 'Europe/Tirane', offset: '+01:00', latitude: 41.3333, longitude: 19.8333 },
  { name: 'Europe/Vienna', offset: '+01:00', latitude: 48.2167, longitude: 16.3333 },
  { name: 'Europe/Warsaw', offset: '+01:00', latitude: 52.25, longitude: 21 },
  { name: 'Europe/Zurich', offset: '+01:00', latitude: 47.3833, longitude: 8.5333 },
  { name: 'Africa/Cairo', offset: '+02:00', latitude: 30.05, longitude: 31.25 },
  { name: 'Africa/Johannesburg', offset: '+02:00', latitude: -26.2044, longitude: 28.0456 },
  { name: 'Africa/Maputo', offset: '+02:00', latitude: -25.9667, longitude: 32.5833 },
  { name: 'Africa/Tripoli', offset: '+02:00', latitude: 32.8833, longitude: 13.1667 },
  { name: 'Asia/Amman', offset: '+02:00', latitude: 31.95, longitude: 35.9333 },
  { name: 'Asia/Beirut', offset: '+02:00', latitude: 33.8833, longitude: 35.5 },
  { name: 'Asia/Damascus', offset: '+02:00', latitude: 33.5, longitude: 36.3 },
  { name: 'Asia/Gaza', offset: '+02:00', latitude: 31.5, longitude: 34.4667 },
  { name: 'Asia/Jerusalem', offset: '+02:00', latitude: 31.7833, longitude: 35.2167 },
  { name: 'Asia/Nicosia', offset: '+02:00', latitude: 35.1667, longitude: 33.3667 },
  { name: 'Europe/Athens', offset: '+02:00', latitude: 37.9833, longitude: 23.7333 },
  { name: 'Europe/Bucharest', offset: '+02:00', latitude: 44.4333, longitude: 26.1 },
  { name: 'Europe/Chisinau', offset: '+02:00', latitude: 47.0167, longitude: 28.8497 },
  { name: 'Europe/Helsinki', offset: '+02:00', latitude: 60.1667, longitude: 24.9333 },
  { name: 'Europe/Istanbul', offset: '+02:00', latitude: 41.0333, longitude: 28.9833 },
  { name: 'Europe/Kaliningrad', offset: '+02:00', latitude: 54.7167, longitude: 20.5 },
  { name: 'Europe/Kiev', offset: '+02:00', latitude: 50.4333, longitude: 30.5167 },
  { name: 'Europe/Riga', offset: '+02:00', latitude: 56.95, longitude: 24.1 },
  { name: 'Europe/Sofia', offset: '+02:00', latitude: 42.6833, longitude: 23.3167 },
  { name: 'Europe/Tallinn', offset: '+02:00', latitude: 59.4372, longitude: 24.745 },
  { name: 'Europe/Vilnius', offset: '+02:00', latitude: 54.6833, longitude: 25.3167 },
  { name: 'Africa/Khartoum', offset: '+03:00', latitude: 15.6, longitude: 32.5333 },
  { name: 'Africa/Nairobi', offset: '+03:00', latitude: -1.2864, longitude: 36.8172 },
  { name: 'Antarctica/Syowa', offset: '+03:00', latitude: -69.0061, longitude: 39.5908 },
  { name: 'Asia/Baghdad', offset: '+03:00', latitude: 33.35, longitude: 44.4167 },
  { name: 'Asia/Qatar', offset: '+03:00', latitude: 25.2833, longitude: 51.5333 },
  { name: 'Asia/Riyadh', offset: '+03:00', latitude: 24.6333, longitude: 46.7167 },
  { name: 'Europe/Minsk', offset: '+03:00', latitude: 53.9, longitude: 27.5667 },
  { name: 'Europe/Moscow', offset: '+03:00', latitude: 55.7558, longitude: 37.6176 },
  { name: 'Asia/Tehran', offset: '+03:30', latitude: 35.6892, longitude: 51.389 },
  { name: 'Asia/Baku', offset: '+04:00', latitude: 40.3833, longitude: 49.8667 },
  { name: 'Asia/Dubai', offset: '+04:00', latitude: 25.2048, longitude: 55.2708 },
  { name: 'Asia/Tbilisi', offset: '+04:00', latitude: 41.7167, longitude: 44.7833 },
  { name: 'Asia/Yerevan', offset: '+04:00', latitude: 40.1833, longitude: 44.5167 },
  { name: 'Europe/Samara', offset: '+04:00', latitude: 53.195, longitude: 50.1001 },
  { name: 'Indian/Mahe', offset: '+04:00', latitude: -4.6667, longitude: 55.4667 },
  { name: 'Indian/Mauritius', offset: '+04:00', latitude: -20.1667, longitude: 57.5 },
  { name: 'Indian/Reunion', offset: '+04:00', latitude: -20.8833, longitude: 55.45 },
  { name: 'Asia/Kabul', offset: '+04:30', latitude: 34.5167, longitude: 69.1833 },
  { name: 'Antarctica/Mawson', offset: '+05:00', latitude: -67.0061, longitude: 62.8758 },
  { name: 'Asia/Aqtau', offset: '+05:00', latitude: 44.5167, longitude: 50.2667 },
  { name: 'Asia/Aqtobe', offset: '+05:00', latitude: 50.2833, longitude: 57.1667 },
  { name: 'Asia/Ashgabat', offset: '+05:00', latitude: 37.95, longitude: 58.3833 },
  { name: 'Asia/Dushanbe', offset: '+05:00', latitude: 38.5833, longitude: 68.7833 },
  { name: 'Asia/Karachi', offset: '+05:00', latitude: 24.8667, longitude: 67.05 },
  { name: 'Asia/Tashkent', offset: '+05:00', latitude: 41.3167, longitude: 69.25 },
  { name: 'Asia/Yekaterinburg', offset: '+05:00', latitude: 56.8575, longitude: 60.6125 },
  { name: 'Indian/Kerguelen', offset: '+05:00', latitude: -49.35, longitude: 70.2167 },
  { name: 'Indian/Maldives', offset: '+05:00', latitude: 4.175, longitude: 73.509 },
  { name: 'Asia/Calcutta', offset: '+05:30 Time', latitude: 22.5667, longitude: 88.3667 },
  { name: 'Asia/Colombo', offset: '+05:30', latitude: 6.9275, longitude: 79.8486 },
  { name: 'Asia/Katmandu', offset: '+05:45', latitude: 27.7167, longitude: 85.3167 },
  { name: 'Antarctica/Vostok', offset: '+06:00', latitude: -78.4644, longitude: 106.8772 },
  { name: 'Asia/Almaty', offset: '+06:00', latitude: 43.25, longitude: 76.95 },
  { name: 'Asia/Bishkek', offset: '+06:00', latitude: 42.8667, longitude: 74.5667 },
  { name: 'Asia/Dhaka', offset: '+06:00', latitude: 23.7161, longitude: 90.3961 },
  { name: 'Asia/Omsk', offset: '+06:00', latitude: 55, longitude: 73.4 },
  { name: 'Asia/Thimphu', offset: '+06:00', latitude: 27.4728, longitude: 89.6392 },
  { name: 'Indian/Chagos', offset: '+06:00', latitude: -6.6667, longitude: 72.4167 },
  { name: 'Asia/Rangoon', offset: '+06:30', latitude: 16.8, longitude: 96.15 },
  { name: 'Indian/Cocos', offset: '+06:30', latitude: -12.1667, longitude: 96.8333 },
  { name: 'Antarctica/Davis', offset: '+07:00', latitude: -68.5833, longitude: 77.9667 },
  { name: 'Asia/Bangkok', offset: '+07:00', latitude: 13.75, longitude: 100.5167 },
  { name: 'Asia/Hovd', offset: '+07:00', latitude: 48.0056, longitude: 91.6419 },
  { name: 'Asia/Jakarta', offset: '+07:00', latitude: -6.175, longitude: 106.8286 },
  { name: 'Asia/Krasnoyarsk', offset: '+07:00', latitude: 56, longitude: 92.8333 },
  { name: 'Asia/Saigon', offset: '+07:00', latitude: 10.75, longitude: 106.6667 },
  { name: 'Asia/Ho_Chi_Minh', offset: '+07:00', latitude: 10.75, longitude: 106.6667 },
  { name: 'Indian/Christmas', offset: '+07:00', latitude: -10.4167, longitude: 105.7167 },
  { name: 'Antarctica/Casey', offset: '+08:00', latitude: -66.2833, longitude: 110.5167 },
  { name: 'Asia/Brunei', offset: '+08:00', latitude: 4.9333, longitude: 114.9167 },
  { name: 'Asia/Choibalsan', offset: '+08:00', latitude: 48.0667, longitude: 114.5333 },
  { name: 'Asia/Hong_Kong', offset: '+08:00', latitude: 22.3964, longitude: 114.1095 },
  { name: 'Asia/Irkutsk', offset: '+08:00', latitude: 52.2667, longitude: 104.3333 },
  { name: 'Asia/Kuala_Lumpur', offset: '+08:00', latitude: 3.1667, longitude: 101.7 },
  { name: 'Asia/Macau', offset: '+08:00', latitude: 22.1667, longitude: 113.55 },
  { name: 'Asia/Makassar', offset: '+08:00', latitude: -5.1333, longitude: 119.4167 },
  { name: 'Asia/Manila', offset: '+08:00', latitude: 14.5833, longitude: 120.9667 },
  { name: 'Asia/Shanghai', offset: '+08:00', latitude: 31.2333, longitude: 121.469 },
  { name: 'Asia/Singapore', offset: '+08:00', latitude: 1.2931, longitude: 103.8558 },
  { name: 'Asia/Taipei', offset: '+08:00', latitude: 25.0478, longitude: 121.5319 },
  { name: 'Asia/Ulaanbaatar', offset: '+08:00', latitude: 47.9167, longitude: 106.8833 },
  { name: 'Australia/Perth', offset: '+08:00', latitude: -31.95, longitude: 115.85 },
  { name: 'Asia/Pyongyang', offset: '+08:30', latitude: 39.0167, longitude: 125.75 },
  { name: 'Asia/Dili', offset: '+09:00', latitude: -8.5586, longitude: 125.5736 },
  { name: 'Asia/Jayapura', offset: '+09:00', latitude: -2.5333, longitude: 140.7 },
  { name: 'Asia/Seoul', offset: '+09:00', latitude: 37.5663, longitude: 126.9997 },
  { name: 'Asia/Tokyo', offset: '+09:00', latitude: 35.6895, longitude: 139.6917 },
  { name: 'Asia/Yakutsk', offset: '+09:00', latitude: 62, longitude: 129.6667 },
  { name: 'Pacific/Palau', offset: '+09:00', latitude: 7.5, longitude: 134.6242 },
  { name: 'Australia/Adelaide', offset: '+10:30', latitude: -34.9286, longitude: 138.6 },
  { name: 'Australia/Darwin', offset: '+09:30', latitude: -12.4611, longitude: 130.8417 },
  { name: 'Antarctica/DumontDUrville', offset: '+10:00', latitude: -65.3289, longitude: 139.9167 },
  { name: 'Asia/Magadan', offset: '+10:00n', latitude: 59.5667, longitude: 150.8 },
  { name: 'Asia/Vladivostok', offset: '+10:00', latitude: 43.1667, longitude: 131.9333 },
  { name: 'Australia/Brisbane', offset: '+10:00', latitude: -27.4679, longitude: 153.0278 },
  { name: 'Australia/Hobart', offset: '+11:00', latitude: -42.8806, longitude: 147.325 },
  { name: 'Australia/Sydney', offset: '+11:00', latitude: -33.8651, longitude: 151.2099 },
  { name: 'Pacific/Chuuk', offset: '+10:00', latitude: 7.4167, longitude: 151.7833 },
  { name: 'Pacific/Guam', offset: '+10:00', latitude: 13.4667, longitude: 144.75 },
  { name: 'Pacific/Port_Moresby', offset: '+10:00', latitude: -9.4789, longitude: 147.1494 },
  { name: 'Pacific/Efate', offset: '+11:00', latitude: -17.7333, longitude: 168.3167 },
  { name: 'Pacific/Guadalcanal', offset: '+11:00', latitude: -9.5333, longitude: 160.2 },
  { name: 'Pacific/Kosrae', offset: '+11:00', latitude: 5.3167, longitude: 162.9833 },
  { name: 'Pacific/Norfolk', offset: '+11:00', latitude: -29.05, longitude: 167.9667 },
  { name: 'Pacific/Noumea', offset: '+11:00', latitude: -22.2758, longitude: 166.458 },
  { name: 'Pacific/Pohnpei', offset: '+11:00', latitude: 6.9667, longitude: 158.2167 },
  { name: 'Asia/Kamchatka', offset: '+12:00', latitude: 53.0219, longitude: 158.6483 },
  { name: 'Pacific/Auckland', offset: '+13:00', latitude: -36.8667, longitude: 174.7667 },
  { name: 'Pacific/Fiji', offset: '+13:00', latitude: -18.1416, longitude: 178.4419 },
  { name: 'Pacific/Funafuti', offset: '+12:00', latitude: -8.5167, longitude: 179.2167 },
  { name: 'Pacific/Kwajalein', offset: '+12:00', latitude: 9.0833, longitude: 167.3333 },
  { name: 'Pacific/Majuro', offset: '+12:00', latitude: 7.1167, longitude: 171.3667 },
  { name: 'Pacific/Nauru', offset: '+12:00', latitude: -0.5477, longitude: 166.9209 },
  { name: 'Pacific/Tarawa', offset: '+12:00', latitude: 1.4167, longitude: 173 },
  { name: 'Pacific/Wake', offset: '+12:00', latitude: 19.2833, longitude: 166.65 },
  { name: 'Pacific/Wallis', offset: '+12:00', latitude: -13.295, longitude: 176.2125 },
  { name: 'Pacific/Apia', offset: '+14:00', latitude: -13.8333, longitude: -171.7667 },
  { name: 'Pacific/Enderbury', offset: '+13:00', latitude: -3.1333, longitude: -171.0833 },
  { name: 'Pacific/Fakaofo', offset: '+13:00', latitude: -8.6333, longitude: -170.7667 },
  { name: 'Pacific/Tongatapu', offset: '+13:00', latitude: -21.1333, longitude: -175.2 },
  { name: 'Pacific/Kiritimati', offset: '+14:00', latitude: 1.8667, longitude: -157.3333 },
];
