import React, { FC, useContext } from 'react';

import { IRoleCheckbox } from './all-role-checkbox';
import { IFolderRole } from './index';
import { canManageAdminInFolder, lowerRole } from './roles-logic';
import { workspaceContext } from '../../../../state';
import { GologinCheckbox } from '../../../../ui/gologin-checkbox';

interface IFolderRoleCheckbox {
  role: IRoleCheckbox['role'];
  folder: IFolderRole;
  onChange: IRoleCheckbox['onChange'];
}

const RoleCheckbox: FC<IFolderRoleCheckbox> = (props) => {
  const { role, folder, onChange } = props;
  const workspaceCtx = useContext(workspaceContext);

  const isChecked = folder.role === 'admin' || (folder.role === 'editor' && role !== 'admin') || folder.role === role;
  const isDisabled = (folder.role === 'admin' || role === 'admin') && !canManageAdminInFolder(workspaceCtx, folder.name);

  const onChangeEvent = (event: React.ChangeEvent<HTMLInputElement>): void => {
    onChange([{
      name: folder.name,
      role: event.target.checked ? role : lowerRole(role),
    }]);
  };

  return (
    <GologinCheckbox
      checked={isChecked}
      onChange={onChangeEvent}
      disabled={isDisabled}
    />
  );
};

export default RoleCheckbox;
