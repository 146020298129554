import styled from '@emotion/styled';

export const TitleContainer = styled('div')`
  display: flex;
  align-items: center;
`;

export const ModalTitle = styled('div')`
  font: 500 24px / 20px 'Roboto Flex';
  letter-spacing: 0.25px;
  margin-left: 12px;
  color: var(--36363D);
`;
