import { ThemeType } from '../interfaces/theme.type';

const getSelectedThemeColor = (currentTheme: ThemeType): Omit<ThemeType, 'system'> => {
  if (currentTheme !== 'system') {
    return currentTheme;
  }

  let selectedThemeColor = 'light';
  const darkThemeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
  if (darkThemeMediaQuery.matches) {
    selectedThemeColor = 'dark';
  }

  return selectedThemeColor;
};

export default getSelectedThemeColor;
