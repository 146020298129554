import { message } from 'antd';
import * as Sentry from '@sentry/react';

import { isNotNull } from '../../../../common/typescript/predicates';
import { getWorkflowCookieBotSites, setWorkflowCookieBotSites } from '../../../state/automation/workflow-cookiebot-sites.atom';
import { defaultCookieBotSites } from './constants';
import { LaunchCookieBotParams } from '../interfaces/launch-cookie-bot.interface';
import { getProxyListProxyById } from '../../../state/proxy/proxy-list.atom';
import PerformanceObserverService from '../../../services/performance-observer/performance-observer.service';
import { BrowserStartData } from '../../quickProfiles/components/interfaces/launch-profile-orbita.interface';
import { getProfileRunStatus } from '../../../state/profile-run-statuses.atom';
import { updateProfileStateAtLaunch } from '../../../state/profiles-table.commands';
import { getIsProxyArchived, restoreProxy } from '../../proxy/proxy-helpers';
import { generateBrowserIconsMassOpsPromise, getIconsFromSessionStorage, removeIconsFromSessionStorage } from '../../browser-system-icons';
import { CookieBotOptions } from '../../../../electron/interfaces/start-profile.renderer.interfaces';
import { getProfilesTableSelectedIds } from '../../../state/profiles-table-selected-ids.atom';
import { getAvailableToRunWithAutomationProfiles } from '../../quickProfiles/multiple-operations-menu/actions';
import { getProfilesList } from '../../../state/profiles-list.atom';
import { sendReactErrorToSentry } from '../../../utils/sentry.helper';
import { ParsedRunCookiesBotProps, RunCookiesBotProps } from '../interfaces';
import { stopProfileOrbita } from '../../quickProfiles/actions';
import { wait } from '../../../../common/utils';
import { getWorkflowCookieBotParameters } from '../../../state/automation/workflow-cookiebot-parameters.atom';
import { sendActionAnalytics } from '../../common/api';

let ipcRenderer: Electron.IpcRenderer | null = null;
const isElectron = !!window.require;

if (isElectron) {
  ({ ipcRenderer } = window.require('electron'));
}

export const parseCookieBotSites = (sitesString: string) => {
  const sitesStrings = sitesString.split('\n');
  const parsedSites = sitesStrings.map((site) => {
    let url = site.trim();

    const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
    if (!/^https?:\/\//i.test(url)) {
      url = 'https://' + url;
    }

    try {
      const parsedUrl = new URL(url);
      const hostParts = parsedUrl.hostname.split('.');

      if (!domainRegex.test(parsedUrl.hostname)) {
        message.error(`Skipped wrong url: ${url}`);

        return null;
      }

      if (hostParts.length === 2) {
        parsedUrl.hostname = `www.${parsedUrl.hostname}`;
      }

      return parsedUrl.toString();
    } catch {
      message.error(`Skipped wrong url: ${url}`);
    }

    return null;
  }).filter(isNotNull);

  return parsedSites;
}

export const launchCookieBot = async ({
  profile,
  updateStatusToSync = true,
  iconsStartData,
  deepLinkProtocol,
  cookieBotOptions,
}: LaunchCookieBotParams) => {
  const { id: profileId, proxy } = profile;
  const { id: proxyId = '' } = proxy || {};

  const { status = false, checkDate } = getProxyListProxyById(proxyId) || {};

  const performanceObserverService = PerformanceObserverService.getInstance();
  const transaction = performanceObserverService.createProfileRunTransaction(profileId);

  const listedProxy = getProxyListProxyById(proxyId);
  let proxyToRunInProfile: BrowserStartData['proxyInfo'] = { ...proxy, port: +proxy.port };
  if (listedProxy) {
    proxyToRunInProfile = {
      ...proxyToRunInProfile,
      ...listedProxy,
      port: +listedProxy.port,
    };
  }

  const getCurrentStatus = (profileId: string): boolean => {
    const profileRunStatus = getProfileRunStatus(profileId);

    return ['profileStatuses.ready', 'profileStatuses.error'].includes(profileRunStatus.status);
  };

  const isReady = getCurrentStatus(profileId);
  if (!(deepLinkProtocol || isReady)) {
    return;
  }

  const updateRunStatusSpan = transaction.startChild({ op: 'ui', description: 'update-run-status' });
  const finalUpdateStatusToSync = !deepLinkProtocol && updateStatusToSync;
  updateProfileStateAtLaunch(profile, finalUpdateStatusToSync);
  updateRunStatusSpan?.finish();

  const { archivedProxy } = profile;
  if (getIsProxyArchived(archivedProxy)) {
    await restoreProxy(archivedProxy);
  }

  const iconsData = iconsStartData || getIconsFromSessionStorage(profileId);
  ipcRenderer && ipcRenderer.invoke('start-cookie-bot', {
    profileId,
    proxyInfo: proxyToRunInProfile,
    proxyEnabled: profile.proxyEnabled,
    icons: iconsData,
    traceId: transaction.traceId || '',
    deepLinkProtocol,
    lastProxyCheckStatus: {
      id: proxyId,
      status,
      checkDate,
    },
    cookieBotOptions,
  });

  removeIconsFromSessionStorage(profileId, iconsData.pngs.length >= 1);
}

export const getParsedCookieBotParameters = ({
  workflowId,
  cookieBotSites,
  cookieBotParameters,
}: ParsedRunCookiesBotProps): CookieBotOptions|void => {
  const parsedCookieBotSites = parseCookieBotSites(cookieBotSites);
  if (!parsedCookieBotSites.length) {
    message.error('Wrong URLs or list URLs is empty');

    return;
  }
  
  const options: CookieBotOptions['options'] = {
    shouldLoadImages: false,
    shouldScroll: false,
    shouldClickButtons: false,
  };

  cookieBotParameters.forEach(({ name, value }) => {
    const condition =
      name in options &&
      typeof value === typeof options[name as keyof typeof options];

    if (condition) {
      options[name as keyof typeof options] = value as never;
    }
  });

  return {
    workflowId,
    urls: parsedCookieBotSites,
    options,
  };
}

const setDefaultCookieBotSettings = () => {
  setWorkflowCookieBotSites(defaultCookieBotSites);
}

export const setDefaultAutomationSettings = () => {
  setDefaultCookieBotSettings();
}

export const handleRunCookieBot = async ({
  workflowId,
  profileId,
}: RunCookiesBotProps): Promise<void> => {
  const cookieBotSites = getWorkflowCookieBotSites();
  const cookieBotParameters = getWorkflowCookieBotParameters();
  const cookieBotOptions = getParsedCookieBotParameters({
    workflowId,
    cookieBotSites,
    cookieBotParameters,
  });

  if (!cookieBotOptions) {
    return;
  }

  let selectedProfilesIds = getProfilesTableSelectedIds();
  if (profileId) {
    const { status } = getProfileRunStatus(profileId);
    if (status !== 'profileStatuses.ready') {
      stopProfileOrbita(profileId);
      
      await wait(5000);
    }

    selectedProfilesIds = [profileId];
  }

  const transaction = Sentry.startTransaction({ name: 'prepare-mass-run' });

  const profilesList = getProfilesList();
  const profilesSelected = profilesList.filter(({ id }) => selectedProfilesIds.includes(id));
  const [availableToRunProfiles, iconsStartDataMassOps] = await Promise.all([
    getAvailableToRunWithAutomationProfiles({ profiles: profilesSelected, transaction }),
    generateBrowserIconsMassOpsPromise(profilesSelected, transaction),
  ]);

  transaction.finish();

  availableToRunProfiles.forEach((profile) => {
    launchCookieBot({
      profile,
      updateStatusToSync: false,
      deepLinkProtocol: 'custom',
      iconsStartData: iconsStartDataMassOps[profile.id],
      cookieBotOptions,
    }).catch((error) => {
      sendReactErrorToSentry({
        message: 'error-in-run-cookies-bot-automation',
        transactionName: 'error-in-run-cookies-bot-automation',
        extra: {
          errorData: JSON.stringify(error),
        }
      })
    });
  });

  if (availableToRunProfiles && availableToRunProfiles.length) {
    sendActionAnalytics('launched profile with automation', {
      actionId: `${availableToRunProfiles.length}`, 
      actionInfo: 'cookie bot',
    }).catch(() => null);
  }
};
