import styled from '@emotion/styled/macro';

import { IconWrapperWithDescription } from '../../../../../ui/gologin-header/icons/wrapper';
import { textFont } from '../../../../../ui/style-templates';
import { blackMain, greyDark } from '../../../../../ui/style-templates/colors';

export const NoCookiesSpan = styled('div')`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--767676-profile-new-settings);
  cursor: pointer;
  white-space: nowrap;
`;

export const CookiesWrapper = styled('div')`
  flex-wrap: wrap;
  display: flex;
  cursor: pointer;
`;

export const CookieItemContainer = styled('div')`
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  color: var(--2B2B31-profile-new-settings);
  margin-right: 16px;
  font-size: 14px;
`;

export const CookieCountContainer = styled('div')`
  margin-left: 4px;
  color: var(--767676-profile-new-settings);
`;

export const ContainerParams = styled('div')<{ hasCursorPointer?: boolean }>`
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: row;
  color: var(--2B2B31-profile-new-settings);
  width: 100%;
  cursor: ${(props): string => props.hasCursorPointer ? 'pointer' : 'auto'};
  background-color: inherit;
`;

export const ContainerIcon = styled(IconWrapperWithDescription)`
  ${textFont()};
`;
