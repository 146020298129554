import { useState } from 'react';

import { IImportFilesCtx } from '../state';

export type DropFileStep = 'importing'|'loading'|'loaded'|'multipleCreating'|null;
export type DropFileType = 'cookies'|'proxies';
export const DEFAULT_MANY_PROFILE_NAME = '{profile name}';
export const useImportFiles = (): IImportFilesCtx => {
  const [dropFileStep, setDropFileStep] = useState<DropFileStep>(null);
  const [needParseName, setNeedParseName] = useState<boolean>(false);
  const [manyProfilesName, setManyProfilesName] = useState<string>(DEFAULT_MANY_PROFILE_NAME);
  const [errorImportMessage, setErrorImportMessage] = useState<string>('');
  const [needOpenSpintaxPopup, setNeedOpenSpintaxPopup] = useState<boolean>(false);

  return {
    dropFileStep,
    setDropFileStep,
    needParseName,
    setNeedParseName,
    manyProfilesName,
    setManyProfilesName,
    errorImportMessage,
    setErrorImportMessage,
    needOpenSpintaxPopup,
    setNeedOpenSpintaxPopup,
  };
};
