import { useTranslate } from '@tolgee/react';
import { message } from 'antd';
import React, { Dispatch, FC, SetStateAction, useContext, useState } from 'react';

import { PaypalContainerOld, PaypalContainer, ButtonPayPaypal, TextPoweredByPaypal, ContainerTextPoweredByPaypal } from './styles';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { E_ANALYTICS_ACTIONS } from '../../../../common/constants/analytics';
import { IPlan } from '../../../interfaces/plan';
import { useDiscountObjById, usePriceConfig, usePromoDiscountAvaliable, userContext, workspaceContext } from '../../../state';
import { IconPaypalLogo } from '../../../ui/gologin-header/icons/icon-paypal-logo';
import { IconPaypalLogoSmall } from '../../../ui/gologin-header/icons/icon-paypal-logo-small';
import { getExternalCheckoutUrl } from '../../../utils/open-site';
import { PAYPAL_SUBSCRIPTIONS_IDS } from '../../../utils/paypal-subscription-ids';
import { sendReactErrorToSentry } from '../../../utils/sentry.helper';
import { sendActionAnalytics } from '../../common/api';
import { ANNUAL_DISCOUNT, MONTH_DISCOUNT } from '../../modalsComponents/components/checkout-modal/constants';
import PriceList from '../../modalsComponents/components/checkout-modal/price-list';
import { Spinner } from '../../modalsComponents/components/checkout-modal/styles';
import {
  checkPlanMaxProfiles,
  getSelectedPlan,
  isDiscountAnnual,
} from '../../modalsComponents/components/checkout-modal/utils';
import { createPaypalSubIntent } from '../api';
import { E_CURRENCY, E_PAYMENT_METHODS, E_PAYMENT_METHODS_ANALYTICS, E_PERIOD } from '../interfaces';
import { handlePaymentCreationError } from '../utils/sentry';

const isElectron = !!window.require;

interface IPaypalForm {
  selectedPlan: string;
  selectedDiscount: string;
  plans: IPlan[];
  getCheckmarkLine?: () => JSX.Element;
  pricingPlan?: IPlan;
  workspaceId?: string;
  setIsLoadingModalShown: Dispatch<SetStateAction<boolean>>;
  openSuccessModal: (planId: string) => void;
}

const PaypalForm: FC<IPaypalForm> = (props) => {
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);

  const {
    plans,
    selectedPlan,
    selectedDiscount,
    getCheckmarkLine,
    pricingPlan,
    workspaceId = '',
  } = props;

  const {
    _id = '',
    profiles: userProfilesCount = 100,
  } = useContext(userContext);

  const { profilesCount: workspaceProfilesCount } = useContext(workspaceContext);

  const { t: translation } = useTranslate();

  const isAnnual = isDiscountAnnual(selectedDiscount);
  const planPeriod = isAnnual ? E_PERIOD.ANNUAL : E_PERIOD.MONTHLY;
  const profilesCount = NEW_FEATURES.workspaces ? workspaceProfilesCount : userProfilesCount;

  const discountId = isAnnual ? ANNUAL_DISCOUNT : MONTH_DISCOUNT;
  const selectedDiscounObj = useDiscountObjById(discountId);
  const promoDiscount = usePromoDiscountAvaliable();
  const priceConfig = usePriceConfig();

  const checkProfileLimitReached = (): boolean => {
    const planToPay = getSelectedPlan(plans, selectedPlan);
    if (!planToPay) {
      return true;
    }

    return !checkPlanMaxProfiles({ translation, planToPay, profiles: profilesCount, paymentMethod: E_PAYMENT_METHODS.PAYPAL });
  };

  const getPaypalSubscriptionId = (): string|undefined => {
    const paypalSubscriptionObj = PAYPAL_SUBSCRIPTIONS_IDS.find(({ planId }) => planId === selectedPlan);
    if (!paypalSubscriptionObj) {
      return;
    }

    const { links } = paypalSubscriptionObj;
    const discountLinkObj = links.find(({ discountId }) => discountId === selectedDiscount);
    if (!discountLinkObj) {
      return;
    }

    return discountLinkObj.subscriptionId;
  };

  const paypalCustomData = [_id, selectedPlan, selectedDiscount, workspaceId];

  const getPaypalPaymentLink = async (): Promise<void> => {
    sendActionAnalytics(
      E_ANALYTICS_ACTIONS.clickedPayNow,
      {
        actionInfo: E_PAYMENT_METHODS.PAYPAL,
        paymentMethod: E_PAYMENT_METHODS.PAYPAL,
      },
    ).catch(() => null);

    const isProfileLimitReached = checkProfileLimitReached();
    if (isProfileLimitReached) {
      return;
    }

    setIsBtnLoading(true);
    setTimeout(() => {
      setIsBtnLoading(false);
    }, 3000);

    const paypalSubId = getPaypalSubscriptionId() || '';
    if (!paypalSubId) {
      message.error(translation('notifications.error.paypalSubscriptionNotReady'));
    }

    const { paymentLink: checkoutUrl = '' } = await createPaypalSubIntent(paypalSubId, paypalCustomData)
      .catch((error: any) => {
        handlePaymentCreationError({
          error,
          selectedDiscounObj,
          planPeriod,
          method: E_PAYMENT_METHODS.PAYPAL,
          paymentMethod: E_PAYMENT_METHODS_ANALYTICS.PAYPAL,
          promoDiscount,
          plans,
          selectedPlan,
          priceConfig,
          tabCurrency: E_CURRENCY.USD,
        });

        return { paymentLink: '' };
      });

    if (!checkoutUrl || checkoutUrl.includes('error')) {
      return;
    }

    const checkoutPageUrl = await getExternalCheckoutUrl({ checkoutUrl });
    if (isElectron) {
      window.require('electron').shell.openExternal(checkoutPageUrl)
        .catch((error) => {
          const errorMessage = error instanceof Error ? error.message : 'unknown';
          sendReactErrorToSentry({ message: errorMessage, transactionName: 'open-paypal-checkout-url' });
        });

      return;
    }

    window.open(checkoutPageUrl);
  };

  const getPaypalButton = (): JSX.Element => (
    <>
      <ButtonPayPaypal
        onClick={getPaypalPaymentLink}>
        {isBtnLoading ?
          <Spinner size={19} thickness={2} /> :
          <IconPaypalLogo />}
      </ButtonPayPaypal>
      <ContainerTextPoweredByPaypal>
        <TextPoweredByPaypal>
          Developed by
        </TextPoweredByPaypal>
        <IconPaypalLogoSmall />
      </ContainerTextPoweredByPaypal>
    </>
  );

  const getContent = (): JSX.Element|null => {
    if (NEW_FEATURES.pricing) {
      if (!pricingPlan) {
        return null;
      }

      return (
        <>
          <PriceList
            pricingPlan={pricingPlan}
            isAnnual={isAnnual}
          />
          <PaypalContainer>
            {getPaypalButton()}
          </PaypalContainer>
        </>
      );
    }

    return (
      <>
        {getCheckmarkLine ? getCheckmarkLine() : null}
        <PaypalContainerOld paddingBottom={isAnnual}>
          {getPaypalButton()}
        </PaypalContainerOld>
      </>
    );
  };

  return getContent();
};

export default PaypalForm;
