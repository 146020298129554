import { atom, getDefaultStore, useAtomValue } from 'jotai';
import { columnsSettingsAtom } from './columns-settings.atom';

const isProxyColumnPresented = atom((get) => {
  const { columnsSettings } = get(columnsSettingsAtom);
  return columnsSettings.some(col => col.colName === 'proxy' && col.visible);
});

export const useIsProxyColumnPresented = (): boolean => useAtomValue(isProxyColumnPresented);

export const getIsProxyColumnPresented = (): boolean => getDefaultStore().get(isProxyColumnPresented);
