import { Table, TableBody, TableCell, TableHead } from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { AddFolderRow } from './add-folder-row';
import AllRoleCheckbox from './all-role-checkbox';
import RoleCheckbox from './role-checkbox';
import { CustomHeaderTableCell, CustomTableRow, FolderNameContainer, FolderNameText, GreyText } from './styles';
import { LimitedWorkspaceRole } from '../../../../interfaces/workspaces';
import IconFolderSimple from '../../../../ui/icons/IconFolderSimple';
import { ScrollbarContainer } from '../../../../ui/scrollbar';

export type CheckboxRole = LimitedWorkspaceRole|'unset';
const TABLE_COLUMNS: Array<LimitedWorkspaceRole> = ['guest', 'editor', 'admin'];

export interface IFolderRole {
  name: string;
  role: CheckboxRole;
}

interface IFolderRoleTable {
  folders: IFolderRole[];
  onChange: (changedFolders: IFolderRole[]) => void;
  canCreateFolder: boolean;
}

const FoldersRoleTable: FC<IFolderRoleTable> = (props) => {
  const { folders, onChange, canCreateFolder } = props;

  const { t: translation } = useTranslation();

  return (
    <ScrollbarContainer style={{ maxHeight: 320 }}>
      <Table stickyHeader={true}>
        <TableHead>
          <CustomTableRow removeBorder={true}>
            <CustomHeaderTableCell />
            {TABLE_COLUMNS.map(role => (
              <CustomHeaderTableCell align='center' key={role}>
                <GreyText>
                  {translation(`permissionSelectMenu.foldersTable.${role}`)}
                </GreyText>
              </CustomHeaderTableCell>
            ))}
          </CustomTableRow>
        </TableHead>
        <TableBody>
          <CustomTableRow rowPadding={5}>
            <TableCell>
              <GreyText>
                {translation('permissionSelectMenu.foldersTable.allFolders')}
              </GreyText>
            </TableCell>
            {TABLE_COLUMNS.map(role => (
              <TableCell align='center' key={role}>
                <AllRoleCheckbox
                  role={role}
                  folders={folders}
                  onChange={onChange}
                />
              </TableCell>
            ))}
          </CustomTableRow>
          {folders.map(folder => (
            <CustomTableRow key={folder.name} rowPadding={5}>
              <TableCell>
                <FolderNameContainer>
                  <IconFolderSimple />
                  <FolderNameText>
                    {folder.name}
                  </FolderNameText>
                </FolderNameContainer>
              </TableCell>
              {TABLE_COLUMNS.map(role => (
                <TableCell align='center' key={role}>
                  <RoleCheckbox
                    folder={folder}
                    role={role}
                    onChange={onChange}
                  />
                </TableCell>
              ))}
            </CustomTableRow>
          ))}
          {canCreateFolder ? (
            <CustomTableRow rowPadding={8} removeBorder={true}>
              <TableCell>
                <AddFolderRow />
              </TableCell>
              {TABLE_COLUMNS.map(role => (
                <TableCell key={role} />
              ))}
            </CustomTableRow>
          ) : null}
        </TableBody>
      </Table>
    </ScrollbarContainer>
  );
};

export default FoldersRoleTable;
