import { SerializedStyles, css } from '@emotion/core';
import styled from '@emotion/styled';
import React from 'react';

import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { borderRadiusContainer } from '../../../ui/style-templates';

const Container = styled('div')<{ newStyle: boolean }>`
  height: 100%;

  ${(props): SerializedStyles|null => props.newStyle ? css`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: initial;
  ` : null}

  ${(props): SerializedStyles|null => props.newStyle ? borderRadiusContainer() : null}

  background-color: var(--FFFFFF-profile-table-container);
`;

const BodyContainer: React.FC = ({ children }) => (
  <Container newStyle={!!NEW_FEATURES.header}>
    {children}
  </Container>
);

export default BodyContainer;
