export const LOAD_PROXY_TRANSACTION = 'load-proxies';
export const LOAD_TRAFFIC_DATA_TRANSACTION = 'load-traffic-data';
export const LOAD_GEOPROXY_COUNTRIES_TRANSACTION = 'load-geoproxy-countries';

export const PROXY_LOAD_OPERATION = 'http-and-ui';

export const LOAD_PROXIES_PAGES_SPAN = 'load-proxies-pages-iteratively';
export const LOAD_PROXIES_SHARED_AND_FREE_SPAN = 'load-proxies-free-and-from-shared-profiles';

export const PROXY_ID_TAG = 'proxy-id';
export const PROXY_GROUP_ID_TAG = 'proxy-group-id';
export const GEOPROXY_TYPE_TAG = 'geoproxy-type';
export const PROXY_COUNTRY_TAG = 'proxy-country';
export const PROXY_CHECK_ERROR_TAG = 'proxy-check-error';
export const PAGES_LOADED_COUNT_TAG = 'pages-loaded-count';
export const PROXIES_LOADED_COUNT_TAG = 'proxies-loaded-count';
export const HAS_PAGES_NOT_LOADED_TAG = 'has-pages-not-loaded';
export const SHARED_PROXIES_LOADED_COUNT_TAG = 'shared-proxies-loaded-count';
export const FREE_PROXIES_LOADED_COUNT_TAG = 'free-proxies-loaded-count';
export const PROXY_PAGE_NOT_LOADED_TAG = 'proxy-page-not-loaded-index';
export const TRAFFIC_DATA_LOAD_TRIGGER_TAG = 'load-trigger';

export const PROXY_PAGE_LOADING_ERROR = 'proxy-page-loading-error';
export const GEOPROXY_INVALID_TYPE_ERROR = 'geoproxy-invalid-type-error';
export const GEOPROXY_INVALID_TYPE_ERROR_MESSAGE = 'geoproxy has invalid type in Edit/New Browser page';
export const GEOPROXY_TYPE_ICON_NOT_SHOWN_ERROR = 'geoproxy-type-icon-not-shown-error';
export const GEOPROXY_TYPE_ICON_NOT_SHOWN_ERROR_MESSAGE = 'connectionType not found in props';
export const GEOPROXY_CHECK_FAILED_WHEN_CREATING_ERROR = 'geoproxy-check-failed-when-creating-error';
export const GEOPROXY_TRAFFIC_DATA_TIMEOUT_ERROR = 'geoproxy-traffic-data-timed-out-error';
export const GEOPROXY_TRAFFIC_DATA_BACKEND_ERROR = 'geoproxy-traffic-data-backend-error';
export const GEOPROXY_TRAFFIC_DATA_UNKNOWN_ERROR = 'geoproxy-traffic-data-unknown-error';
export const UNUSED_ARTIFICIAL_GEOPROXY_LINKING_ERROR = 'unused-artificial-geoproxy-linking-error';
export const UNUSED_ARTIFICIAL_GEOPROXY_LINK_ERROR_MESSAGE = 'trying to link an unused geoproxy while it is not fully created';
export const NO_PROFILE_IN_GEOPROXY_COUNTRY_HEADER_ERROR = 'no-profile-id-in-geoproxy-country-header-error';
export const GEOPROXY_NOT_FOUND_FOR_COUNTRY_AND_TYPE_ERROR = 'geoproxy-not-found-for-country-and-type-error';
export const COUNTRY_HAS_NO_FLAG_ERROR = 'country-has-no-flag-error';
export const COUNTRY_HAS_NO_FLAG_ERROR_MESSAGE = 'proxy provider has a country that has no saved flag';

export const PROXY_GROUP_NOT_FOUND_WARNING = 'proxy-group-not-found-therefore-created-warning';
export const PROXY_GROUP_NOT_FOUND_WARNING_MESSAGE =
  'proxy group not found for the specified group-id when initializing groups in state';
export const ARTIFICIAL_GEOPROXY_CONTEXT_MENU_WARNING = 'artificial-geoproxy-disabled-context-menu-warning';
export const ARTIFICIAL_GEOPROXY_CONTEXT_MENU_WARN_MESSAGE = 'tried to open context menu for a still creating geoproxy';

export const PROXY_OBSERVED_USER_ACTIONS = <const>{
  linkProxyManagerProxy: 'select-proxy-manager-proxy',
  openProxyGroup: 'open-proxy-group',
};

export const TRAFFIC_DATA_LOAD_TRIGGERS = <const>{
  appStart: 'app-start',
  trafficModal: 'traffic-modal',
};

export type TrafficDataLoadTrigger = typeof TRAFFIC_DATA_LOAD_TRIGGERS[keyof typeof TRAFFIC_DATA_LOAD_TRIGGERS];
