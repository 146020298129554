import switchWorkspace, { IUserCtxSwitchFields } from './switch-workspace';
import { history } from '../../services';
import { IWorkspaceCtx } from '../../state';

const onWorkspaceCreated = async (workspaceId: string, workspaceCtx: IWorkspaceCtx, userCtx: IUserCtxSwitchFields): Promise<void> => {
  const workspace = await switchWorkspace(workspaceId, workspaceCtx, userCtx);
  workspaceCtx.addNewAvailableWorkspace(workspace);
  history.push('/');
};

export default onWorkspaceCreated;
