import * as Sentry from '@sentry/react';

import { ReactError } from './sentry-parameters/custom-errors';
import {
  APP_START_TIMESTAMPS_KEY_NAMES_MAP,
  START_APP_TO_LOAD_PROFILES_TABLE,
  START_APP_TO_LOAD_SIGN_UP_PAGE,
} from '../../common/constants/constants';
import { getCurrentTimestampInSeconds, safeParseJSON } from '../../common/utils';
import { AppStartTransactionSpan, IAppStartTransaction } from '../interfaces/sentry/app-start.transaction';

interface ISpanData {
  [key: string]: { started: number; ended: number };
}

const isElectron = !!window.require;
let ipcRenderer: Electron.IpcRenderer;
if (isElectron) {
  ({ ipcRenderer } = window.require('electron'));
}

export const createAppStartTransactionSpan = (
  spanId: AppStartTransactionSpan,
  started = getCurrentTimestampInSeconds(),
): void => sessionStorage.setItem(APP_START_TIMESTAMPS_KEY_NAMES_MAP[spanId], JSON.stringify({ [spanId]: { started } }));

export const sendAppStartTransactionToSentry = async (): Promise<void> => {
  if (!ipcRenderer) {
    return;
  }

  try {
    const transactionFinishTimestamp = getCurrentTimestampInSeconds();
    const isTimestampsSaved = await ipcRenderer.invoke('save-start-app-timestamps').catch(() => false);
    if (!isTimestampsSaved) {
      return;
    }

    const mergedObjectWithSpans: Partial<IAppStartTransaction> = {};
    const appStartTransactionKeys = Object.keys(APP_START_TIMESTAMPS_KEY_NAMES_MAP) as (keyof typeof APP_START_TIMESTAMPS_KEY_NAMES_MAP)[];
    for (const key of appStartTransactionKeys) {
      const spanValue = safeParseJSON<IAppStartTransaction>(sessionStorage.getItem(APP_START_TIMESTAMPS_KEY_NAMES_MAP[key]) || '');
      sessionStorage.removeItem(APP_START_TIMESTAMPS_KEY_NAMES_MAP[key]);
      localStorage.removeItem(APP_START_TIMESTAMPS_KEY_NAMES_MAP[key]); // TODO: удалить в августе 2024
      if (!spanValue) {
        continue;
      }

      mergedObjectWithSpans[key] = spanValue[key];
    }

    if (!Object.keys(mergedObjectWithSpans).length) {
      return;
    }

    const isSpanLoadedSignUpExists = mergedObjectWithSpans['from-setup-i18n-to-load-sign-up-page'];
    const transactionName = isSpanLoadedSignUpExists ? START_APP_TO_LOAD_SIGN_UP_PAGE : START_APP_TO_LOAD_PROFILES_TABLE;

    const electronLoad = mergedObjectWithSpans['electron-load'];
    const { started: startTimestamp } = electronLoad || {};
    if (!startTimestamp) {
      return;
    }

    const createSentrySpans = (transactions: Partial<IAppStartTransaction>): ISpanData => {
      const transactionEntries = Object.entries(transactions).sort(([, curTimestamp], [, nextTimestamp]) =>
        (curTimestamp.started || 0) - (nextTimestamp.started || 0));

      return transactionEntries.reduce<ISpanData>((acc, [currentKey, currentTransaction], index) => {
        const nextTransaction = transactionEntries[index + 1] || [];
        const { started = 0 } = currentTransaction;
        const ended = nextTransaction[1]?.started || transactionFinishTimestamp;

        acc[currentKey] = {
          started,
          ended,
        };

        return acc;
      }, {});
    };

    const transactionSpansData = createSentrySpans(mergedObjectWithSpans);
    const transaction = Sentry.startTransaction({ name: transactionName, startTimestamp });
    for (const key of appStartTransactionKeys) {
      const { started = 0, ended = 0 } = transactionSpansData[key] || {};
      if (!(started && ended)) {
        continue;
      }

      const newSpan = transaction.startChild({ op: key, startTimestamp: started, endTimestamp: ended });
      newSpan.finish(ended);
    }

    transaction.finish(transactionFinishTimestamp);
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : 'Error sending transaction start app to sentry';
    Sentry.captureException(new ReactError(errorMessage), (scope) => {
      scope.setTransactionName('send-transaction-start-app-to-sentry');
      scope.setFingerprint(['send-transaction-start-app-to-sentry']);
      scope.setLevel('error');

      return scope;
    });
  }
};
