import { getLineHeightVectorized } from './get-line-height-vectorized';
import { getIconsConstants } from '../../../constants';
import { ILineFirstYValueParams } from '../../../interfaces';
import { DOUBLING_VALUE } from '../../get-values';

export const getLinesGapVectorized = ({
  os,
  scaleFactor,
  lineFirstMetrics,
  lineSecondMetrics,
}: ILineFirstYValueParams, isWin10BigIcon: boolean): number => {
  const { textFontSizeBase, lineHeightRatio } = getIconsConstants(os);
  const lineFirstHeight = getLineHeightVectorized(lineFirstMetrics);
  const lineSecondHeight = getLineHeightVectorized(lineSecondMetrics);
  const linesBoundingBoxAscentDifference = lineSecondMetrics.actualBoundingBoxAscent - lineFirstMetrics.actualBoundingBoxAscent;
  const linesBoundingBoxDescentDifference = lineFirstMetrics.actualBoundingBoxDescent - lineSecondMetrics.actualBoundingBoxDescent;

  let gap = scaleFactor * textFontSizeBase * lineHeightRatio;
  if (!isWin10BigIcon) {
    gap = gap / DOUBLING_VALUE;
  }

  switch (true) {
    case lineFirstHeight <= gap && lineSecondHeight <= gap:
      gap = gap - lineSecondHeight - linesBoundingBoxDescentDifference;

      break;
    case lineFirstHeight > gap:
      gap -= lineFirstHeight;
      if (linesBoundingBoxAscentDifference < 0) {
        gap -= linesBoundingBoxAscentDifference;
      }

      break;
    default:
      gap -= lineSecondHeight;
      gap -= linesBoundingBoxDescentDifference;

      break;
  }

  return gap;
};

