import React, { FC, memo } from 'react';

import { ITooltipCustom } from './interfaces';
import { TooltipContainer, TooltipWrapper, TooltipText } from './styles';

const TooltipCustom: FC<ITooltipCustom> = ({
  anchorEl,
  value,
  placement,
  style,
  hasTooltipAnimation,
  isTooltipHoverable,
}) => (
  <TooltipWrapper
    open={!!anchorEl}
    anchorEl={anchorEl}
    placement={placement || 'bottom'}
    style={{ zIndex: 10000, pointerEvents: isTooltipHoverable ? 'auto' : 'none', ...style }}
    hasTooltipAnimation={hasTooltipAnimation}
    onClick={(event): void => event.stopPropagation()}
  >
    <TooltipContainer>
      <TooltipText>
        {value}
      </TooltipText>
    </TooltipContainer>
  </TooltipWrapper>
);

export default memo(TooltipCustom);
