import { Icon } from 'antd';
import React, { FC, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { IItem, ISelectGroupItems, ISelectItem } from './interfaces';
import { ContainerFoundItem, Divider, Ellipsis, Hint, Option, SubtitleContainer } from './styles';
import { searchBold } from '../../features/newProfile/components/folders-select/search';
import { LoaderDiv } from '../../features/profileSettingsComponents/extensionsTab/domElements';

const PAGE_SIZE = 100;

const Item: FC<IItem> = (props) => {
  const { option, onSelect, hasDivider, currentValue, searchValue } = props;
  const [itemsShown, setItemsShown] = useState<number>(PAGE_SIZE);

  const onClick = (value: string, event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    event.stopPropagation();
    onSelect(value);
  };

  const loadMore = (): void => {
    setItemsShown(prev => prev + PAGE_SIZE);
  };

  const getLoader = (): JSX.Element|null => (
    <LoaderDiv>
      <Icon type='loading' />
    </LoaderDiv>
  );

  const isOptionGroup = Object.prototype.hasOwnProperty.call(option, 'groupTitle');
  const allItems: ISelectItem[] = isOptionGroup ? (option as ISelectGroupItems).selectItems : [option as ISelectItem];
  const shownSelectItems = allItems.filter(item => !item.hidden);
  const limitedItems = shownSelectItems.slice(0, itemsShown);
  const hasMore = limitedItems.length < shownSelectItems.length;

  const showGroupTitle = (): JSX.Element|null => {
    if (!(isOptionGroup || (option as ISelectGroupItems).groupTitle)) {
      return null;
    }

    return (
      <SubtitleContainer>
        {(option as ISelectGroupItems).groupTitle}
      </SubtitleContainer>
    );
  };

  const renderOption = (item: ISelectItem): JSX.Element => {
    const { title, hint, isSearchIgnored } = item;
    if (!searchValue || isSearchIgnored) {
      return (
        <>
          {hint ? (
            <Hint>
              {hint}
            </Hint>
          ) : null}
          <Ellipsis>
            {title}
          </Ellipsis>
          {searchBold}
        </>
      );
    }

    let itemText = title;
    if (hint) {
      itemText = `${hint} ${itemText}`;
    }

    return (
      <ContainerFoundItem>
        {searchBold(searchValue.replace('-', '−'), itemText)}
      </ContainerFoundItem>
    );
  };

  return (
    <InfiniteScroll
      dataLength={limitedItems.length}
      next={loadMore}
      hasMore={hasMore}
      loader={getLoader()}
      scrollableTarget='new-select-options'
      style={{ overflow: 'hidden', width: '100%' }}
    >
      <div style={{ width: '100%', display: 'contents' }}>
        {showGroupTitle()}
        {limitedItems.map((item) => {
          const { value, key } = item;

          return (
            <Option
              key={key || value}
              onClick={(event): void => onClick(value, event)}
              isSelected={currentValue.toLowerCase().trim() === value.toLowerCase().trim()}
            >
              {renderOption(item)}
            </Option>
          );
        })}
        {hasDivider ? <Divider /> : null}
      </div>
    </InfiniteScroll>
  );
};

export default Item;
