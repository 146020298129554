import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import showAfterInviteMessage from './after-invite-message';
import { inviteMembers } from './api';
import { EmailInput } from './email-input';
import { InviteModalSubheader } from './modal-styles';
import { IWorkspaceFolderRole, LimitedWorkspaceRole } from '../../../../interfaces/workspaces';
import { workspaceContext } from '../../../../state';
import { useWorkspaceLimits } from '../../../../state/limits/workspace-limits.atom';
import { getSelectedFolderId } from '../../../../state/selected-folder.atom';
import {
  closeGlobalModal,
  INVITE_MEMBER_MODAL_NAME,
  useGlobalModalEntityIds,
  useGlobalModalIsVisible,
} from '../../../../state/global-modal';
import GologinModal from '../../../../ui/gologin-modal';
import { GreenSubmitButton } from '../../../../ui/modern-modal';
import { filterEmailsByUser } from '../../../../utils/filterEmailsByUser';
import PermissionsSelect from '../../../members/permissions-select';
import { useUserBasicInfo } from '../../../../state/user/user-basic-info.atom';

const InviteMembersModal = (): JSX.Element|null => {
  const [limitedAccess, setLimitedAccess] = useState<boolean>(true);
  const [workspaceRole, setWorkspaceRole] = useState<LimitedWorkspaceRole>('guest');
  const [emails, setEmails] = useState<string[]>([]);
  const [folders, setFolders] = useState<IWorkspaceFolderRole[]>([]);
  const [emailErrorText, setEmailErrorText] = useState<string>('');
  const [isEmailInputFocused, setIsEmailInputFocused] = useState<boolean>(true);

  const isModalVisible = useGlobalModalIsVisible(INVITE_MEMBER_MODAL_NAME);
  const modalEntityIds = useGlobalModalEntityIds();
  const selectedFolderId = modalEntityIds[0] ? modalEntityIds[0] : getSelectedFolderId();

  const { email: userEmail } = useUserBasicInfo();
  const workspace = useContext(workspaceContext);

  const { maxMembers: workspaceLimitsMaxMembers = 0 } = useWorkspaceLimits();

  const { t: translation } = useTranslation();

  const { planMembersMax = 1 } = workspace;
  const maxMembersLimit = workspaceLimitsMaxMembers || planMembersMax;
  const currentMemberLimited = workspace.me?.limitedAccess ?? true;
  const maxMembers = workspace.isPlanUnlimited ? Number.POSITIVE_INFINITY : maxMembersLimit;
  const maxEmails = maxMembers - workspace.members.length;
  const isInviteButtonDisabled = limitedAccess && !folders.length;

  useEffect(() => {
    if (!(isModalVisible && selectedFolderId)) {
      return;
    }

    setLimitedAccess(true);
    const foundFolder = workspace.folders.find(folder => folder.id === selectedFolderId);
    if (!foundFolder) {
      return;
    }

    setFolders([{
      name: foundFolder.name,
      role: 'guest',
      permissions: {
        manageAccess: foundFolder.permissions.manageMember,
        manageAdminAccess: foundFolder.permissions.manageAdminMember,
      },
    }]);
  }, [isModalVisible, selectedFolderId]);

  const sendInvites = async (): Promise<void> => {
    if (!emails.length) {
      if (!emailErrorText) {
        setEmailErrorText(translation('modals.inviteUserWorkspace.enterOneEmail') || '');
      }

      return;
    }

    if (emails.length > maxEmails) {
      return;
    }

    const emailsToInvite = filterEmailsByUser(emails, userEmail, 'You can\'t invite yourself'); //
    if (!emailsToInvite.length) {
      setEmails([]);

      return;
    }

    const updatedMembers = await inviteMembers({
      workspaceId: workspace.id,
      emails: emailsToInvite,
      limitedAccess,
      role: workspaceRole,
      folders,
      workspaceName: workspace.name,
    });

    const updatedMembersEmails = updatedMembers.map(member => member.email);
    const membersToSave = workspace.members
      .filter(member => !updatedMembersEmails.includes(member.email))
      .concat(updatedMembers);

    workspace.updateWorkspace({
      members: membersToSave,
    });

    setEmails([]);
    setFolders([]);

    closeGlobalModal();
    showAfterInviteMessage(emailsToInvite);
  };

  const onPermissionsChange = (
    newLimitedAccess: boolean,
    newRole: LimitedWorkspaceRole,
    newFolders: IWorkspaceFolderRole[],
  ): void => {
    setLimitedAccess(newLimitedAccess);
    setWorkspaceRole(newRole);
    setFolders(newLimitedAccess ? newFolders : []);
  };

  const closeModal = (): void => {
    closeGlobalModal();
    setEmails([]);
    setFolders([]);
    setEmailErrorText('');
    setIsEmailInputFocused(true);
  };

  if (!workspace.isLoaded) {
    return null;
  }

  // desired behavior to replace 0 members to 1
  let maxMembersText = '/' + (maxMembers || 1).toString();
  if (workspace.isPlanUnlimited) {
    maxMembersText = '';
  }

  return (
    <GologinModal
      isVisible={isModalVisible}
      onClose={closeModal}
      width='496px'
      title={translation('modals.inviteUserWorkspace.title')}
    >
      <>
        <InviteModalSubheader>
          <span>
            {workspace.members.length}
            {maxMembersText}
            {' '}
            {translation('modals.inviteUserWorkspace.usersJoined')}
          </span>
        </InviteModalSubheader>
        <div
          style={{ marginBottom: 20 }}
          onClick={(): void => setIsEmailInputFocused(true)}
          onBlur={(): void => setIsEmailInputFocused(false)}
        >
          <EmailInput
            emails={emails}
            onChange={setEmails}
            errorText={emailErrorText}
            onErrorTextChange={setEmailErrorText}
            maxEmails={maxEmails}
            inputFocused={isEmailInputFocused}
            placeholder={translation('modals.inviteUserWorkspace.userEmail')}
            type='invite'
          />
        </div>
        <PermissionsSelect
          limitedAccess={limitedAccess}
          role={workspaceRole}
          folders={folders}
          // this whole modal only should be shown when member is admin, so only `limited` is concern here
          isCurrentMemberGlobalAdmin={!currentMemberLimited}
          onChange={onPermissionsChange}
          isRadioExtraTextShown={true}
          blockWorkspaceWideAdminSelect={!workspace.permissions.globallyManageAdminMembers}
        />
        <GreenSubmitButton
          onClick={sendInvites}
          disabled={isInviteButtonDisabled}
          style={{ margin: '32px auto 0' }}
        >
          {translation('modals.inviteUserWorkspace.sendInvite')}
        </GreenSubmitButton>
      </>
    </GologinModal>
  );
};

export default InviteMembersModal;
