import styled from '@emotion/styled';
import { Select } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import TIMEZONES from '../../common/timezones';
import { updateProfileTimezone } from '../api';
import Loader, { TLoaderStatus } from './Loader';

const { Option } = Select;

const ArrowDrawer = styled('div')`
  border: solid var(--01AD7F);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(135deg);
  opacity: 1;
  cursor: pointer;
`;

const ElementContainer = styled('div')`
  font-family: 'Roboto';
  margin-top: 38px;
  margin-left: 26px;
`;

const TitleContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 22px;
  margin-left: 36px;
`;

const TittleSetting = styled('div')`
  font-weight: 500;
  font-family: 'Roboto';
  font-size: 20px;
  color: var(--000000);
  margin-left: 12px;
  margin-top: -2px;
`;

const Subtitle = styled('div')`
  font-family: Roboto;
  width: 320px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin-left: -1px;
  color: var(--7E7E7E);
`;

const TIMEZONE_SETTINGS = {
  fillBasedOnIp: true,
  timezone: '',
}

let hasBeenUpdated = false;
let loaderStatusTimeout: any;

declare interface IProfileDrawerTimezone {
  profileInfo?: any;
  changeVisibility: () => void;
  updateProfileTimezone: (id: string, timezone: any) => any;
}

export const DrawerTimezone: FC<IProfileDrawerTimezone> = (props) => {
  const [loaderStatus, setLoaderStatus] = useState<TLoaderStatus>('default');

  const { t: translation } = useTranslation();

  const save = async () => {
    clearTimeout(loaderStatusTimeout);
    setLoaderStatus('processing');

    loaderStatusTimeout = setTimeout(() => {
      setLoaderStatus('done');
      loaderStatusTimeout = setTimeout(() => setLoaderStatus('default'), 0.5 * 1000);
    }, 0.5 * 1000);

    props.updateProfileTimezone(props?.profileInfo?.id, TIMEZONE_SETTINGS);
    await updateProfileTimezone(props?.profileInfo?.id, TIMEZONE_SETTINGS);
  }

  const onTimezoneChange = (tz: string) => {
    TIMEZONE_SETTINGS.fillBasedOnIp = tz === 'fill_based_on_ip';
    TIMEZONE_SETTINGS.timezone = tz !== 'fill_based_on_ip' ? tz : '';
    hasBeenUpdated = true;
    save();
  }

  useEffect(() => {
    TIMEZONE_SETTINGS.fillBasedOnIp = !!props?.profileInfo?.timezone?.fillBasedOnIp;
    TIMEZONE_SETTINGS.timezone = props?.profileInfo?.timezone?.timezone;
  }, []);

  const getTimezoneContent = () => {
    return (
      <>
        <Select
          showSearch={true}
          optionFilterProp='children'
          onSelect={onTimezoneChange}
          defaultValue={
            props?.profileInfo?.timezone?.fillBasedOnIp
              ? 'fill_based_on_ip'
              : (props?.profileInfo?.timezone?.timezone || 'fill_based_on_ip')
          }
          style={{ width: 320, paddingBottom: 16 }}
        >
          <Option value='fill_based_on_ip'>
            {translation('base.fillBasedOnIp')}
          </Option>
          {TIMEZONES.sort((a, b) => a.name.localeCompare(b.name)).map(
            elem => {
              const timezoneName = elem.name;
              const offset = elem.offset;
              return (
                <Option key={timezoneName} value={timezoneName}>
                  {timezoneName} ({offset})
                </Option>
              );
            }
          )}
        </Select>
      </>
    );
  };

  return (
    <>
      <TitleContainer>
        <ArrowDrawer onClick={props.changeVisibility} />

        <TittleSetting>
          {translation('base.timezone')}
        </TittleSetting>

        <Loader
          state={loaderStatus}
        />
      </TitleContainer>
      <ElementContainer>
        {getTimezoneContent()}
        {props?.profileInfo?.timezone?.fillBasedOnIp && (
          <Subtitle>
            {translation('timezone.help')}.
          </Subtitle>
        )}
      </ElementContainer>
    </>
  );
}
