import { atom, getDefaultStore, useAtomValue } from 'jotai';
import { forceReloadProfilesQuery } from './profiles-table/profiles-query';

const profilesTableProxyIdFilterAtom = atom<string>('');

export const setProfilesTableProxyIdFilter = (proxyId: string): void => {
  getDefaultStore().set(profilesTableProxyIdFilterAtom, proxyId);
  forceReloadProfilesQuery();
};

export const getProfilesTableProxyIdFilter = (): string => getDefaultStore().get(profilesTableProxyIdFilterAtom);
export const useProfilesTableProxyIdFilter = (): string => useAtomValue(profilesTableProxyIdFilterAtom);

export const resetProfilesTableProxyIdFilter = (): void => setProfilesTableProxyIdFilter('');
