import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconCreatePlus: React.FC<IIconWrapper> = props => (
  <IconWrapper {...props}>
    <svg width='11' height='11' viewBox='0 0 11 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M5.5 0.5V10.5' stroke-linecap='round' stroke-linejoin='round' />
      <path d='M0.5 5.5H10.5' stroke-linecap='round' stroke-linejoin='round' />
    </svg>
  </IconWrapper>
);
