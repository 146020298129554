import React from 'react';
import { useTranslation } from 'react-i18next';

import ModalFooter from './modal-footer';
import { InformationText, Wrapper } from './styles';
import GologinModal from '../../../../../ui/gologin-modal';
import { IOrbitaModalBase, IOrbitaModalBaseFooter } from '../interfaces';

const ModalBase: React.FC<IOrbitaModalBase> = (props) => {
  const {
    visible = false,
    handleCancel,
    handleAfterClose,
    titleKey = '',
    body = { key: '' },
    primaryButton,
    secondaryButton,
  } = props;

  const { t: translation } = useTranslation();
  const footerProps: IOrbitaModalBaseFooter = { secondaryButton, primaryButton };

  return (
    <GologinModal
      isVisible={visible}
      onClose={handleCancel}
      title={translation(titleKey)}
      footer={<ModalFooter {...footerProps} />}
    >
      <Wrapper>
        <InformationText>
          {translation(body.key, body.options || {})}
        </InformationText>
      </Wrapper>
    </GologinModal>
  );
};

export default ModalBase;
