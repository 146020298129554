import * as Sentry from '@sentry/react';

import { E_SENTRY_MESSAGES } from './sentry-messages';
import { ReactError } from './sentry-parameters/custom-errors';
import {
  PAYMENT_GATEWAY_URL,
  FORCE_LOAD_OLD_PRICING_KEY_NAME,
  OLD_PRICING_PAGE_URL,
  SELECTED_LANGUAGE_KEY_NAME,
  THEME_KEY_NAME,
} from '../../common/constants/constants';
import { PRICING_PAGE } from '../../common/constants/routes';
import { sendActionAnalytics } from '../features/common/api';
import { IGetAutoLoginToken, getAutoLoginToken } from '../features/pricing/api';
import { history } from '../services';

const isElectron = !!window.require;

export interface IPaymentLinkOptions {
  workspaceId?: string;
  isQuickSettingsEnabled?: boolean;
  isShowPaymentMethods?: boolean;
  redirectQuery?: URLSearchParams;
}

const generatePaymentLink = async (options: IPaymentLinkOptions): Promise<string> => {
  const {
    workspaceId,
    isShowPaymentMethods = true,
    redirectQuery = new URLSearchParams(),
  } = options;

  const paramsObj: Record<string, string> = {};

  if (workspaceId) {
    paramsObj.workspaceId = workspaceId;
  }

  paramsObj.timestamp = String(performance.now() + performance.timeOrigin);

  const forceOldPricing = localStorage.getItem(FORCE_LOAD_OLD_PRICING_KEY_NAME) === 'true';
  if (forceOldPricing) {
    paramsObj.theme = localStorage.getItem(THEME_KEY_NAME) || 'system';
    paramsObj.locale = localStorage.getItem(SELECTED_LANGUAGE_KEY_NAME) || 'en';

    const autoLoginTokenReq: any = await getAutoLoginToken().catch(() => ({}));
    const autoLoginToken = autoLoginTokenReq?.auto_login_token;
    if (autoLoginToken) {
      paramsObj['auto-login-token'] = autoLoginToken;
    }
  }

  const queryParams = new URLSearchParams(paramsObj);
  const isEmptyRedirectQuery = !redirectQuery?.toString();
  if (!isEmptyRedirectQuery) {
    redirectQuery.forEach((value, key) => {
      queryParams.append(key, value);
    });
  }

  if (isShowPaymentMethods) {
    queryParams.append('checkout', 'true');
  }

  return `?${queryParams.toString()}`;
};

const openSite = async (options: IPaymentLinkOptions): Promise<void> => {
  const queryParams = await generatePaymentLink(options);
  const forceOldPricing = localStorage.getItem(FORCE_LOAD_OLD_PRICING_KEY_NAME) === 'true';

  if (!forceOldPricing) {
    history.push(`${PRICING_PAGE}${queryParams.toString()}`);

    return;
  }

  sendActionAnalytics('redirected with temporary token', { actionInfo: OLD_PRICING_PAGE_URL });
  const url = `${OLD_PRICING_PAGE_URL}${queryParams.toString()}`;
  if (!isElectron) {
    history.push(`${PRICING_PAGE}${queryParams.toString()}`);

    return;
  }

  const level: Sentry.SeverityLevel = 'log';
  Sentry.captureMessage(
    `${E_SENTRY_MESSAGES.electronTryOpenExternalUrl} ${url}`, {
      level,
    });

  return window.require('electron').shell.openExternal(url)
    .then(() => {
      Sentry.captureMessage(
        `${E_SENTRY_MESSAGES.electronOpenedExternalUrl} ${url}`, {
          level,
        });
    })
    .catch((error: unknown) => {
      const errorMessage = error instanceof Error ? error.message : 'unknown';
      Sentry.captureException(new ReactError(errorMessage), (scope) => {
        const path = (url as string).replace(/^.*\/\/[^/]+/, '');
        scope.setLevel(<Sentry.SeverityLevel>'error');
        scope.setTag('path', `${path}`);
        scope.setTransactionName('open-external-url-error');
        scope.setFingerprint(['open-external-url-error']);

        return scope;
      });
    });
};

export const openWebsitePricing = async (options: IPaymentLinkOptions): Promise<void> =>
  openSite(options).catch(() => void 0);

export const getExternalCheckoutUrl = async ({ checkoutUrl }: { checkoutUrl: string }): Promise<string> => {
  try {
    const autoLoginTokenReq: IGetAutoLoginToken = await getAutoLoginToken().catch((error) => {
      const errorMessage = error instanceof Error ? error.message : 'unknown';
      Sentry.captureException(new ReactError(errorMessage), (scope) => {
        scope.setLevel(<Sentry.SeverityLevel>'error');
        scope.setTransactionName('payment-gateway-getting-auto-login-token-error');
        scope.setFingerprint(['payment-gateway-getting-auto-login-token-error']);

        return scope;
      });

      return { auto_login_token: '' };
    });

    const autoLoginToken = autoLoginTokenReq?.auto_login_token;
    if (!autoLoginToken) {
      return checkoutUrl;
    }

    const gatewayUrl = PAYMENT_GATEWAY_URL;
    const encodedCheckoutUrl = encodeURIComponent(checkoutUrl);
    const queryString = `temp_token=${autoLoginToken}&payment_url=${encodedCheckoutUrl}`;

    return `${gatewayUrl}?${queryString}`;
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : 'unknown';
    Sentry.captureException(new ReactError(errorMessage), (scope) => {
      scope.setLevel(<Sentry.SeverityLevel>'error');
      scope.setTransactionName('payment-gateway-formatting-checkout-url-error');
      scope.setFingerprint(['payment-gateway-formatting-checkout-url-error']);

      return scope;
    });

    return checkoutUrl;
  }
};
