import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';

const zoomBigIn = keyframes`
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

// The components below are based on TagsInfoContainer, etc.
export const InfoContainer = styled('div')`
  width: 250px;
  height: 32px;
  border-radius: 4px;
  margin-left: -121px;

  display: flex;
  justify-content: center;
  
  position: absolute;
  bottom: 11px; // different from the original one
  z-index: 120;
  
  font-family: Roboto;
  font-style: normal;
  letter-spacing: 0.25px;

  // ↓ different from the original one
  animation: ${zoomBigIn} 0.1s cubic-bezier(0.08, 0.82, 0.17, 1) 0.8s both;
`;

export const InfoBlock = styled('div')`
  padding: 12px 16px; // different from the original one
  border-radius: 4px;
  position: absolute;

  background-color: var(--FFFFFF-profile-table-profile-settings-button);
  box-shadow: 0 1px 4px var(--00000003-profile-table-profile-settings-button), 0 4px 8px var(--00000005-profile-table-profile-settings-button),
    0 1px 12px var(--0000001F-profile-table-profile-settings-button);
`;

export const Info = styled('div')`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--767676-profile-table-profile-settings-button);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
