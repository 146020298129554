import { useTranslate } from '@tolgee/react';
import React, { FC } from 'react';

import { ButtonTelergam, ButtonContainer, TelegramWidgetText } from './styles';
import IconTelegram from '../../../../ui/gologin-header/icons/icon-telegram';
import IconWhatsApp from '../../../../ui/gologin-header/icons/icon-whatsapp';

const telegramLink = 'https://t.me/gologinsupportbot';
const whatsappLink = 'https://wa.me/19299994119';

const SupportLinksWidget: FC = () => {
  const { t: translation } = useTranslate();

  const openInNewWindow = (url: string): void => {
    window.open(url, '_blank');
  };

  return (
    <ButtonContainer>
      <IconWhatsApp padding={0} onClick={(): void => openInNewWindow(whatsappLink)} />
      <ButtonTelergam onClick={(): void => openInNewWindow(telegramLink)}>
        <TelegramWidgetText>
          {translation('pricing.supportWidgetText')}
        </TelegramWidgetText>
        <IconTelegram padding={0} />
      </ButtonTelergam>
    </ButtonContainer>
  );
};

export default SupportLinksWidget;
