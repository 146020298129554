import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ArrowContainer, SectionName } from './styles';
import { IconArrow } from '../../../../ui/gologin-header/icons';
import { ISection } from '../../interfaces';

interface ISectionHeader {
  sectionId: string;
  section: ISection;
  toggleSection: (sectionId: string) => void;
}

const SectionHeader: FC<ISectionHeader> = (props): JSX.Element|null => {
  const { sectionId, section, toggleSection } = props;

  const { t: translation } = useTranslation();

  if (section.name === 'favorites') {
    return (
      <SectionName style={{ paddingTop: 0 }}>
        {translation('quickSettings.' + section.name + '.title')}
      </SectionName>
    );
  }

  return (
    <SectionName
      onClick={(): void => toggleSection(sectionId)}
      iconType='stroke'
      iconColor='var(--98989F-profile-new-settings-sections-group)'
      iconHoveredColor='var(--2B2B31-profile-new-settings-sections-group)'
      style={{ cursor: 'pointer' }}
    >
      {translation('quickSettings.' + section.name + '.title')}
      <ArrowContainer isOpen={section.isOpen}>
        <IconArrow padding={0} />
      </ArrowContainer>
    </SectionName>
  );
};

export default SectionHeader;
