import { css, SerializedStyles } from '@emotion/core';
import styled from '@emotion/styled';
import { Popover } from '@material-ui/core';

import { textFont } from '../../style-templates';
import { blackMain, grey } from '../../style-templates/colors';
import { IconWrapperWithDescription } from '../icons/wrapper';

export const BuyProxyPopoverCustom = styled(Popover)<{ isDarkTheme: boolean }>`
  .MuiPopover-paper {
    color: var(--000000DE-header-buy-proxy);
    margin-left: -24px;
    margin-top: 4px;
    padding: 16px;
    width: 271px;
    background: var(--FFFFFF-header-buy-proxy);

    border-radius: 4px;
    line-height: 0;
    
    ${(props): SerializedStyles|null => !props.isDarkTheme ? css`
      box-shadow: 0 3px 6px var(--0000001A-header-buy-proxy), 0 4px 8px var(--00000014-header-buy-proxy), 0 1px 12px var(--0000000A-header-buy-proxy);
    ` : null}
  }
`;

export const ItemRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  line-height: 20px;

  color: var(--2B2B31-header-buy-proxy);
`;

export const VolumeProxyContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  ${textFont};

  * + * {
    margin-left: 8px;
  }
`;

export const Title = styled.span`
  ${textFont};
  color: var(--81818A-header-buy-proxy);
`;

export const Description = styled(Title)`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
`;

export const ItemsContainer = styled.div`
  margin: 12px 0;
  font-size: 14px;

  * + * {
    margin-top: 8px;
  }
`;

export const UnderlinedText = styled.span`
  border-color: var(--B3B3B8-header-buy-proxy);
  border-bottom: 0.05em solid;
  
  cursor: pointer;
`;

export const IconContainerWithDescription = styled(IconWrapperWithDescription)`
  ${textFont};
  margin-right: 8px;
  padding-right: 8px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  white-space: nowrap;
`;

export const TrafficRequestErrorText = styled('div')`
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  align-self: center;
  line-height: normal;
`;
