import * as Sentry from '@sentry/react';

import { IProfile } from '../../interfaces';
import { generateBrowserIconsPromise } from './generate-browser-icons-promise';
import { IIconsStartData, IIconsStartDataMassOps } from './interfaces';
import { getParamsToGenerateIconsMassOps } from './utils';

export const generateBrowserIconsMassOpsPromise = (profiles: IProfile[], transaction?: Sentry.Transaction): Promise<IIconsStartDataMassOps> =>{
  const spanDrawIconsMassRun = transaction?.startChild({ op: 'draw-icons-mass-run' });

  return new Promise<IIconsStartDataMassOps>((resolve) => {
    const browserIconsDataArray = profiles.map(getParamsToGenerateIconsMassOps).filter(({ iconDesignVersion }) => !!iconDesignVersion);
    const iconsPngsPromises = browserIconsDataArray.map(browserIconsData => generateBrowserIconsPromise(browserIconsData));
    Promise.allSettled(iconsPngsPromises).then((promiseSettledResult) => {
      const iconsStartDataMassOps = promiseSettledResult.reduce<IIconsStartDataMassOps>((acc, result, index) => {
        if (result.status === 'rejected') {
          return acc;
        }

        const { profileId, iconDesignVersion } = browserIconsDataArray[index];
        const iconsStartData: IIconsStartData = { design: iconDesignVersion, pngs: result.value };
        acc[profileId] = iconsStartData;

        return acc;
      }, {});

      spanDrawIconsMassRun?.finish();
      resolve(iconsStartDataMassOps);
    });
  });
};
