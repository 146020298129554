import { IProxy } from '../../interfaces';
import { PROXY_CHECK_TOOLTIP_LOCATIONS } from '../../state/proxy/proxy-check/constants';
import { ProxyCheckTooltipLocation } from '../../state/proxy/proxy-check/types/proxy-check-tooltip-location.type';
import { addMultipleProxies, IMultipleProxiesAddResult } from '../../state/proxy/proxy-operations/create-proxies.operations';
import { parseMultipleProxies } from '../../utils/proxy-string';
import { sendReactErrorToSentry } from '../../utils/sentry.helper';
import { sendActionAnalytics } from '../common/api';

type PastedProxiesSendParams = {
  clipboardText: string;
  profileInEditProxyMode: string | null;
  proxyList: IProxy[];
  localProxySelectorLocation: string;
  isInDrawer: boolean;
}

export const sendPastedProxies = async ({
  clipboardText,
  profileInEditProxyMode,
  proxyList,
  localProxySelectorLocation,
  isInDrawer,
}: PastedProxiesSendParams): Promise<IMultipleProxiesAddResult|null> => {
  const proxies = await parseMultipleProxies(clipboardText);
  if (!profileInEditProxyMode) {
    sendReactErrorToSentry({
      message: 'profile id not found in send-pasted-proxies function params',
      transactionName: 'profile-not-found-in-paste-proxies',
    });

    return null;
  }

  sendActionAnalytics('pasted proxy from buffer');
  let tooltipView: ProxyCheckTooltipLocation = PROXY_CHECK_TOOLTIP_LOCATIONS.selectorProfileTable;
  if (isInDrawer) {
    tooltipView = localProxySelectorLocation === 'pinned-proxy' ?
      PROXY_CHECK_TOOLTIP_LOCATIONS.selectorProfileSettingsFavourite :
      PROXY_CHECK_TOOLTIP_LOCATIONS.selectorProfileSettingsProxy;
  }

  return addMultipleProxies({
    proxyList,
    proxies,
    selectedProfiles: [profileInEditProxyMode],
    proxyCheckTooltipView: tooltipView,
    isPastingProxies: true,
  });
};
