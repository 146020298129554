import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { PriceWrapper, PriceTextWrapper, PriceDivider, PriceText } from './styles';
import { IPlan } from '../../../../../interfaces/plan';
import { convertDiscountIdToPeriod, useDiscountObjById, usePriceConfig, usePromoDiscountAvaliable, userContext } from '../../../../../state';
import { Price } from '../../../../pricing/utils/price';
import { ANNUAL_DISCOUNT, MONTH_DISCOUNT } from '../constants';

interface IPriceList {
  pricingPlan: IPlan;
  isAnnual: boolean;
  isStripePayment?: boolean;
}

const PriceList: FC<IPriceList> = (props) => {
  const {
    pricingPlan,
    isAnnual,
    isStripePayment,
  } = props;

  const discountId = isAnnual ? ANNUAL_DISCOUNT : MONTH_DISCOUNT;
  const selectedDiscounObj = useDiscountObjById(discountId);
  const promoDiscount = usePromoDiscountAvaliable();
  const priceConfig = usePriceConfig();

  const { email = '' } = useContext(userContext);
  const { t: translation } = useTranslation();

  const { name } = pricingPlan;
  const periodType = convertDiscountIdToPeriod(discountId);
  const totalPrice = new Price(pricingPlan)
    .period(selectedDiscounObj)
    .promo(promoDiscount, priceConfig)
    .toLocalString(null, priceConfig);

  const savedAmount = new Price(pricingPlan).savePeriod(selectedDiscounObj).toLocalString(null, priceConfig);

  return (
    <PriceWrapper isStripePayment={!!isStripePayment}>
      <PriceTextWrapper>
        <PriceText>
          {translation('pricing.checkoutModal.accountName')}
        </PriceText>
        <PriceDivider />
        <PriceText>
          {email}
        </PriceText>
      </PriceTextWrapper>
      <PriceTextWrapper>
        <PriceText>
          {isAnnual ?
            `${name} ${translation('pricing.checkoutModal.billedAnnually')}` :
            `GoLogin ${name}`}
        </PriceText>
        <PriceDivider />
        <PriceText>
          {new Price(pricingPlan).multiply(periodType).toLocalString(0, priceConfig)}
        </PriceText>
      </PriceTextWrapper>
      <PriceTextWrapper>
        <PriceText>
          {translation('pricing.checkoutModal.discountTitle')}
        </PriceText>
        <PriceDivider />
        <PriceText>
          {isAnnual ? savedAmount : '-'}
        </PriceText>
      </PriceTextWrapper>
      <PriceTextWrapper>
        <PriceText>
          {translation('pricing.checkoutModal.chargedTitle')}
        </PriceText>
        <PriceDivider />
        <PriceText>
          {totalPrice}
        </PriceText>
      </PriceTextWrapper>
    </PriceWrapper>
  );
};

export default PriceList;
