import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconLink: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_16061_328690)'>
        <path d='M12 9.00025L13.975 6.97525C15.342 5.60825 15.342 3.39225 13.975 2.02525C12.608 0.65825 10.392 0.65825 9.025 2.02525L7 4.00025' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M9.00025 12L6.97525 13.975C5.60825 15.342 3.39225 15.342 2.02525 13.975C0.65825 12.608 0.65825 10.392 2.02525 9.025L4.00025 7' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M5 11L11 5' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_16061_328690'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  </IconWrapper>
);
