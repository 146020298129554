import { getProfileFromStorage } from './get-profile-from-storage';
import { IProfile } from '../../../interfaces';

export const handleShareViaLinkFromStorage = (profilesOld: IProfile[], profileJson: string): IProfile[] => {
  if (!profileJson) {
    return profilesOld;
  }

  const profileSharedViaLink = getProfileFromStorage(profileJson);
  if (!profileSharedViaLink) {
    return profilesOld;
  }

  const profilesNew = [...profilesOld];
  const profileExistingIndex = profilesOld.findIndex(({ id }) => id === profileSharedViaLink.id);
  if (profileExistingIndex >= 0) {
    profilesNew.splice(profileExistingIndex, 1);
  }

  profilesNew.unshift(profileSharedViaLink);

  return profilesNew;
};
