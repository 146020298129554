import styled from '@emotion/styled';

export const TitleContainer = styled('div')`
  display: flex;
  align-items: center;
`;

export const ModalTitle = styled('div')`
  font: 500 24px / 20px 'Roboto Flex';
  letter-spacing: 0.25px;
  margin-left: 12px;
  color: var(--36363D);
`;

export const Container = styled('div')`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

export const ListContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Setting = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const InfoContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const ParameterTitle = styled('div')`
  font: 400 14px/20px Roboto;
  letter-spacing: 0.25px;
  color: var(--36363D);

  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ParameterSubtitle = styled('div')`
  display: -webkit-box;
  width: 246px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;

  font: 400 12px/16px Roboto;
  letter-spacing: 0.2px;
  color: var(--98989F);
`;

export const ValueContainer = styled('div')`
  display: flex;
  padding-right: 24px;
`;
