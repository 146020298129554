import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconCookie: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_29122_24043)">
        <path
          d="M8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.85786 12.1421 0.5 8 0.5C3.85786 0.5 0.5 3.85786 0.5 8C0.5 12.1421 3.85786 15.5 8 15.5Z"
          stroke="#98989F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.5 10C5.5 10.2761 5.27614 10.5 5 10.5C4.72386 10.5 4.5 10.2761 4.5 10C4.5 9.72386 4.72386 9.5 5 9.5C5.27614 9.5 5.5 9.72386 5.5 10Z"
          fill="#98989F"
          stroke="#98989F"
        />
        <path
          d="M11 10C11.5523 10 12 9.55228 12 9C12 8.44772 11.5523 8 11 8C10.4477 8 10 8.44772 10 9C10 9.55228 10.4477 10 11 10Z"
          fill="#98989F"
        />
        <path
          d="M7 6C7.55228 6 8 5.55228 8 5C8 4.44772 7.55228 4 7 4C6.44772 4 6 4.44772 6 5C6 5.55228 6.44772 6 7 6Z"
          fill="#98989F"
        />
      </g>
      <defs>
        <clipPath id="clip0_29122_24043">
          <rect width={16} height={16} fill="white" />
        </clipPath>
      </defs>
    </svg>
  </IconWrapper>
);
