import React, {  } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import { Container, HiddenMask, Mask, Text } from './styles';
import { useHandlesDrop } from './use-handles-drop';
import { IconUpload } from '../gologin-header/icons';

const DragAndDropFiles = ({ setNeedMask }: { setNeedMask: (state: boolean) => void }): JSX.Element | null => {
  const { t: translation } = useTranslation();
  const { dropProps, isDragging }  = useHandlesDrop({ setNeedMask });

  if (!isDragging) {
    return (
      <HiddenMask {...dropProps} />
    );
  }

  return ReactDOM.createPortal(
    <Mask {...dropProps}>
      <Container>
        <IconUpload
          iconColor='var(--98989F)'
          padding={0}
        />
        <Text>
          {translation('importFiles.description')}
        </Text>
      </Container>
    </Mask>,
    document.body,
  );
};

export default DragAndDropFiles;
