import { IParsedFacebookData, LabelParsedFields } from './interfaces';

/**
 * Patterns:
 * khustochkin9393@mail.ru
 * khustochkin9393@mail.ru:962drq$KjZPR
 */
export const EMAIL_REGEX = /[\w._%+-]+@[\w.-]+\.[a-zA-Z]{2,}/g;
export const USER_AGENT_REGEX = /(Mozilla\/|AppleWebKit\/|Chrome\/|Avast\/)/g;

/**
 * Patterns:
 * 23.12.1993
 * 23-12-1993
 * 23/12/1993
 * 3/28/93
 */
export const DATE_REGEX = /\d{1,2}(\.|\-|\/)\d{1,2}(\.|\-|\/)\d{2,4}$/g;

/**
 * Pattern of facebook profile url:
 * https://www.facebook.com/profile.php?id=100093663394630
 */
export const FACEBOOK_PROFILE_URL_REGEX =
  /^(?:https?:\/\/)?(?:www\.)?(facebook|m\.facebook|facebook|fb)\.(com)\/(profile\.)(php\?id\=)/gi;

/**
 * Pattern of facebook 2fa url:
 * https://2fa.fb.tools/FXZWZZUXLEF7KWAYBUDM2YUTCDOIERFC
 */
export const FB_TOOL_URL_REGEX = /^(?:https?:\/\/)?(?:www\.)?(2fa.fb.tools)\/(\w)/gi;

/**
 * Pattern of Google Drive url:
 * https://drive.google.com/file/d/1OlNxrz940QclrXIGhybvnhJENj_Ei49G/view?usp=share_link
 */
export const GOOGLE_DRIVE_URL_REGEX = /^(?:https?:\/\/)?(?:www\.)?(drive.google.com)\/(\w)/gi;

export const labelsParsedFields: Record<LabelParsedFields, string> = {
  email: 'Email',
  fbUrl: 'Facebook profile',
  fbIdAccount: 'Facebook id',
  token: 'Facebook token',
  fb2faToolUrl: 'Facebook tool link',
  googleDriveUrl: 'Google drive link',
  date: 'Date',
  uaVersion: 'User agent',
  notParsedData: 'Not parsed',
};
