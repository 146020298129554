import { useEffect } from 'react';

import { clearProxyLists } from '../state/proxy/proxy-list.atom';
import { loadProxyList } from '../state/proxy/proxy-operations/load-proxies.operations';

export const useInitProxy = (userId?: string): void => {
  useEffect(() => {
    if (!userId) {
      return clearProxyLists();
    }

    loadProxyList();
  }, [userId]);
};
