import React from 'react';

import { SORT_ORDERS } from '../../features/common/sorter-order';
import { useProfilesTableSorting } from '../../state/profiles-table/profiles-sort.atom';
import { IconSort, IconSortDown, IconSortUp } from '../gologin-header/icons';

export const SorterCol = ({ sorterField = '' }: { sorterField?: string }): JSX.Element|null => {
  const { sortField, sortOrder } = useProfilesTableSorting();

  const isSortingCurrCol = sortField === sorterField;
  const iconProps = {
    padding: 4,
    iconColor: 'var(--98989F-profile-table-new-sorter)',
    iconHoveredColor: 'var(--2B2B31-profile-table-new-sorter)',
  };

  if (isSortingCurrCol && sortOrder === SORT_ORDERS.ascend) {
    return (
      <IconSortUp {...iconProps} />
    );
  }

  if (isSortingCurrCol && sortOrder === SORT_ORDERS.descend) {
    return (
      <IconSortDown {...iconProps} />
    );
  }

  return (
    <IconSort {...iconProps} />
  );
};
