import styled from '@emotion/styled';

export const Subtitle = styled.h2`
  font: 300 18px/21.6px 'Graphik LC', Roboto;
  text-align: center;
  color: var(--FFFFFF-auth-pages-text);
  margin-top: 19px;
  margin-bottom: 0;
  width: 336px;
`;
