import { Icon, Tooltip } from 'antd';
import React from 'react';

import { OsType } from '../../../../../types';
import IconAppleM1 from '../../../../../ui/icons/IconAppleM1';
import IconLinuxProfileTable from '../../../../../ui/icons/IconLinuxProfileTable';

interface ITooltipOsIcon {
  os: OsType;
  isM1?: boolean;
}

const TooltipOsIcon: React.FC<ITooltipOsIcon> = (props) => {
  const { os, isM1 } = props;

  const OSIcons = {
    mac: {
      icon: isM1 ? (
        <IconAppleM1 />
      ) : (
        <Icon style={{ position: 'relative', bottom: '1px' }} type='apple' />
      ),
      title: isM1 ? 'Apple M1' : 'Mac OS',
    },
    lin: {
      icon: (
        <span style={{ position: 'relative', top: 1 }}>
          <IconLinuxProfileTable />
        </span>
      ),
      title: 'Linux',
    },
    android: {
      icon: <Icon type='android' />,
      title: 'Android',
    },
    win: {
      icon: <Icon style={{ position: 'relative', top: 1 }} type='windows' />,
      title: 'Windows',
    },
  };

  return (
    <Tooltip title={OSIcons[os].title}>
      {OSIcons[os].icon}
    </Tooltip>
  );
};

export default TooltipOsIcon;
