import styled from '@emotion/styled/macro';
import { Popover, Tooltip, withStyles } from '@material-ui/core';
import { Check, FolderOutlined } from '@material-ui/icons';
import React from 'react';
import { Trans } from 'react-i18next';

import { ScrollbarContainer } from '../scrollbar';

export const FolderNameContainer = styled('div')`
  display: flex;
  align-items: center;
`;

export const FolderNameText = styled('span')`
  font-family: Roboto;
  max-width: 180px;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  letter-spacing: 0.25px;
  color: var(--222222-select-workspace);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FolderOutlinedCustom = styled(FolderOutlined)<{ htmlColor?: string; viewBox?: string }>`
  &.MuiSvgIcon-root {
    width: 24px;
    height: 17px;
  }
`;

export const ContainerIcon = styled('div')`
  display: flex;
  align-items: center;
`;

export const TooltipTitle = styled('span')`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: var(--222222-select-workspace);

  strong {
    font-weight: 500;
  }
`;

export const SelectWorkspacePopover = styled(Popover)`
  .MuiPaper-root {
    color: var(--000000DE-select-workspace);
    background-color: var(--FFFFFF-select-workspace);
  }
`;

export const Row = styled('div')<{ isDisabled?: boolean }>`
  display: flex;
  padding: 8px 16px;
    
  opacity: ${props => props.isDisabled ? '0.4' : '1'};

  &:hover {
    background-color: var(--F9F9F9-select-workspace);
  }
`;

export const RowFolderContainer = styled('div')<{ isDisabled?: boolean }>`
  height: 32px;
  padding: 8px 8px 8px 16px;
  cursor: pointer;
  opacity: ${props => props.isDisabled ? '0.4' : '1'};
  display: flex;
  align-items: center;

  &:hover {
    background-color: var(--F9F9F9-select-workspace);
  }
`;

export const DropdownMenu = styled(ScrollbarContainer)`
  overflow-x: hidden;
  background-color: var(--FFFFFF-select-workspace);
  width: 324px;
  max-height: 264px;
  cursor: pointer;
`;

export const FoldersListPopoverCustom = styled(Popover)`
  .MuiPopover-paper {
    border-radius: 4px;
    margin-left: 18px;
    width: 256px;
  }

  .MuiPaper-root {
    color: var(--000000DE-select-workspace);
    background-color: var(--FFFFFF-select-workspace);
  }
`;

export const ContainerTitleMenuDropdown = styled('div')`
  max-width: 270px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: baseline;
`;

export const ContainerDescriptionMenuDropdown = styled(ContainerTitleMenuDropdown)`
  justify-content: space-between;
`;

export const WorkspaceSelectedCheck = styled(Check)<{ htmlColor?: string; viewBox?: string }>`
  &.MuiSvgIcon-root {
    width: 16px;
    margin-left: 4px;
    height: 15px;
  }
`;

export const NumberText = styled('span')`
  font-family: Roboto;
  font-size: 12px;
  color: var(--000000-select-workspace);
  opacity: 0.6;
  line-height: 16px;
`;

export const PlanText = styled('span')<{ redText?: boolean }>`
  font-family: Roboto;
  font-size: 12px;
  color: ${props => props.redText ? 'var(--FF0000-select-workspace)' : 'var(--000000-select-workspace)'};
  opacity: 0.5;
  margin-left: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 16px;
`;

export const ContainerRowTitle = styled('div')`
  display: flex;
  max-width: 250px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const WorkspaceNameText = styled('span')`
  font-family: Roboto;
  color: var(--000000-select-workspace);
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0;
  line-height: 20px;
`;

export const SelectedFolderNameText = styled('span')`
  font-family: Roboto;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  max-width: 150px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  align-items: center;
  letter-spacing: 0.25px;
  color: var(--767676-select-workspace);
`;

export const ContainerSelectWorkspace = styled('div')`
  display: flex;
  border: 1px solid var(--00000033-select-workspace);
  box-sizing: border-box;
  border-radius: 4px;
  width: 324px;
  padding: 8px 8px 8px 12px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-top: 4px;
`;

export const Divider = styled('div')`
  height: 1px;
  background: var(--000000-select-workspace);;
  opacity: 0.1;
  width: 100%;
`;

export const ContainerAddWorkspace = styled('div')`
  display: flex;
  align-items: center;
  padding: 6px 16px 6px 14px;

  &:hover {
    background-color: var(--F9F9F9-select-workspace);
  }
`;

export const AddWorkspaceText = styled('span')`
  font-family: Roboto;
  color: var(--000000-select-workspace);;
  margin-left: 6px;
  font-size: 14px;
`;

export const TooltipCustom = withStyles(() => ({
  tooltip: {
    background: 'var(--FFFFFF-select-workspace)',
    boxShadow: '0px 8px 24px var(--00000026-select-workspace)',
    borderRadius: '4px',
    maxWidth: '240px',
  },
}))(Tooltip);

interface IErrorTooltipTitleWorkspace {
  name: string;
  type: 'workspace'|'folder';
}

export const ErrorTooltipTitleWorkspace: React.FC<IErrorTooltipTitleWorkspace> = (props) => {
  const { name, type } = props;

  return (
    <TooltipTitle>
      <Trans i18nKey={`selectWorkspaceMenu.noPermissionTooltip.${type}`} values={{ name }} />
    </TooltipTitle>
  );
};

export const tooltipTitleName = (workspaceName: string) => (
  <TooltipTitle>
    {workspaceName}
  </TooltipTitle>
);
