import React, { Component } from 'react';
import { Switch, Radio, Tooltip, Icon } from 'antd';
import styled from '@emotion/styled';
import GeolocationManager from "./geolocation-manager";
import { TransProps, withTranslation } from 'react-i18next';
import { TooltipText } from './styles';

const ElementContainer = styled('div')`
  margin-top: 27px;
`;

const Row = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Col = styled('div')`
  display: flex;
  flex-direction: column;
`;

const Subtitle = styled('span')`
  font-size: 12px;
  color: var(--000000-update-profile-geolocation);
  margin-left: 10px;
`;

const RadioButton = styled(Radio.Button)`
  width: 100px;
  height: 30px;
  text-align: center;
  font-size: 12px;
  line-height: 29px;
`;

interface IGeolocationTab extends TransProps {
  updateProfileInfo: any;
  profileInfo: any;
  setUpdatedField: (field: string) => any;
}
class GeolocationTab extends Component<IGeolocationTab> {
  private setMode = (e: any) => {
    const { geolocation = {} } = this.props.profileInfo;

    this.props.updateProfileInfo({
      geolocation: {
        ...geolocation,
        mode: e.target.value
      }
    });

    this.props.setUpdatedField('geolocation.mode');
  };

  private fillBasedOnIp = (isEnabled: boolean) => {
    const { geolocation = {} } = this.props.profileInfo;

    this.props.updateProfileInfo({
      geolocation: {
        ...geolocation,
        fillBasedOnIp: isEnabled
      }
    });

    this.props.setUpdatedField('geolocation.fillBasedOnIp');
  };

  public getWebgeolocation = () => {
    const { geolocation = {} } = this.props.profileInfo;
    const mode = geolocation.mode;
    if (mode === 'allow') {
      return (
        <>
          <Tooltip
            title={(
              <TooltipText>
                {this.props.t('geolocation.allow.help')}
              </TooltipText>
            )}
            overlayStyle={{ backgroundColor: 'var(--FFFFFF-update-profile-geolocation)', color: 'var(--000000-update-profile-geolocation)' }}
            placement='rightBottom'
            arrowPointAtCenter={true}
          >
            <Icon
              style={{
                marginTop: 7,
                marginLeft: 5,
                marginBottom: 7,
                opacity: 0.4
              }}
              type='info-circle'
            />
          </Tooltip>
        </>
      );
    } else if (mode === 'block') {
      return (
        <>
          <Tooltip
            title={(
              <TooltipText>
                {this.props.t('geolocation.block.help')}
              </TooltipText>
            )}
            overlayStyle={{ backgroundColor: 'var(--FFFFFF-update-profile-geolocation)', color: 'var(--000000-update-profile-geolocation)' }}
            placement='rightBottom'
            arrowPointAtCenter={true}
          >
            <Icon
              style={{
                marginTop: 7,
                marginLeft: 5,
                marginBottom: 7,
                opacity: 0.4
              }}
              type='info-circle'
            />
          </Tooltip>
        </>
      );
    } else {
      return (
        <>
          <Tooltip
            title={(
              <TooltipText>
                {this.props.t('geolocation.prompt.help')}
              </TooltipText>
            )}
            overlayStyle={{ backgroundColor: 'var(--FFFFFF-update-profile-geolocation)', color: 'var(--000000-update-profile-geolocation)' }}
            placement='rightBottom'
            arrowPointAtCenter={true}
          >
            <Icon
              style={{
                marginTop: 7,
                marginLeft: 5,
                marginBottom: 7,
                opacity: 0.4
              }}
              type='info-circle'
            />
          </Tooltip>
        </>
      );
    }
  };

  public geoOptionContainer = () => (
    <ElementContainer>
      <Col>
        <Row>
          <Switch
            checked={this.props.profileInfo.geolocation.fillBasedOnIp}
            onChange={this.fillBasedOnIp}
          />
          <Subtitle>{this.props.t('geolocation.fillBasedOnIpText')}</Subtitle>
        </Row>
        {!this.props.profileInfo.geolocation.fillBasedOnIp && (
          <GeolocationManager {...this.props}/>
        )}
      </Col>
    </ElementContainer>
  );

  public render(): JSX.Element {
    return (
      <>
        <ElementContainer>
          <Row>
            <Radio.Group
              value={this.props.profileInfo.geolocation.mode}
              onChange={this.setMode}
            >
              <Tooltip
                title={(
                  <TooltipText>
                    {this.props.t('geolocation.prompt.help')}
                  </TooltipText>
                )}
                overlayStyle={{ backgroundColor: 'var(--FFFFFF-update-profile-geolocation)', color: 'var(--000000-update-profile-geolocation)' }}
                placement='top'
                arrowPointAtCenter={true}
              >
                <RadioButton value='prompt'>{this.props.t('base.prompt')}</RadioButton>
              </Tooltip>
              <Tooltip
                title={(
                  <TooltipText>
                    {this.props.t('geolocation.allow.help')}
                  </TooltipText>
                )}
                overlayStyle={{ backgroundColor: 'var(--FFFFFF-update-profile-geolocation)', color: 'var(--000000-update-profile-geolocation)' }}
                placement='top'
                arrowPointAtCenter={true}
              >
                <RadioButton value='allow'>{this.props.t('base.allow')}</RadioButton>
              </Tooltip>
              <Tooltip
                title={(
                  <TooltipText>
                    {this.props.t('geolocation.block.help')}
                  </TooltipText>
                )}
                overlayStyle={{ backgroundColor: 'var(--FFFFFF-update-profile-geolocation)', color: 'var(--000000-update-profile-geolocation)' }}
                placement='top'
                arrowPointAtCenter={true}
              >
                <RadioButton value='block'>{this.props.t('base.block')}</RadioButton>
              </Tooltip>
            </Radio.Group>
            {this.getWebgeolocation()}
          </Row>
          {this.props.profileInfo.geolocation.mode !== 'block' && (
            <>{this.geoOptionContainer()}</>
          )}
        </ElementContainer>
      </>
    );
  }
}

export default withTranslation()(GeolocationTab);
