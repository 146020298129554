import React, { Dispatch, FC, SetStateAction } from 'react';

import { PlanCellStyled } from './styles';
import { IPlan } from '../../../../interfaces/plan';
import { IMappedPlan } from '../../interfaces';
import { getMappedCustomPlans } from '../../plans-data';
import CustomPlanSelect from '../custom-plan-select';

interface IPlanCellSelect {
  plans: IPlan[];
  tablePlan: IMappedPlan;
  selectedCustomPlanId: string;
  setSelectedCustomPlanId: (planId: string) => void;
}

const PlanCellSelect: FC<IPlanCellSelect> = (props) => {
  const {
    plans,
    tablePlan,
    selectedCustomPlanId,
    setSelectedCustomPlanId,
  } = props;

  const tableCustomPlans = getMappedCustomPlans(plans);

  return (
    <PlanCellStyled backgroundColor={tablePlan.background}>
      <div>
        <CustomPlanSelect
          previewCustomPlans={tableCustomPlans}
          selectedCustomPlanId={selectedCustomPlanId}
          setSelectedCustomPlanId={setSelectedCustomPlanId}
        />
      </div>
    </PlanCellStyled>
  );
};

export default PlanCellSelect;
