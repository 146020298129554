import { redirectToElectron } from '../../../common/components/redirect-to-desktop-page/utils';
import { resetSelectedFolderId } from '../../../../state/selected-folder.atom';

export const redirectToDesktopWithShare = (profileName = '', linkId = '', token = ''): void => {
  let path = `share/${profileName}/${linkId}`;
  if (token) {
    const searchParams = new URLSearchParams();
    searchParams.append('access-token', token);
    path += `?${searchParams.toString()}`;
  }

  resetSelectedFolderId();
  redirectToElectron(path);
};
