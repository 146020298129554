import { css } from '@emotion/core';
import { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled/macro';

import { IconWrapperWithDescription } from '../../ui/gologin-header/icons/wrapper';
import { SelectArrowContainer } from '../../ui/modern-select/styles';
import { textFont } from '../../ui/style-templates';

export const ProfileSettingsDrawer = styled('div')<{ isVisible: boolean; width: string }>`
  right: ${({ isVisible, width }): string => isVisible ? '0' : `-${width}`};
  top: 0;
  position: fixed;
  height: 100%;
  overflow-y: auto;
  width: ${({ width }): string => width};
  transition: right 0.3s ease;
  z-index: 200;
  background-color: var(--F9F9F9-profile-new-settings);
  box-shadow: var(--box-shadow-profile-settings);
`;

export const OverlayDrawer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

export const ContentContainer = styled('div')`
  padding: 0 16px;
  background-color: var(--F9F9F9-profile-new-settings);
`;

export const SettingsSectionsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding-bottom: 130px;
`;

export const ActionSpan = styled('span')<{ defaultColor?: string }>`
  ${textFont};
  cursor: pointer;
  color: ${(props): string => props.defaultColor ? props.defaultColor : 'var(--CDCDCD-profile-new-settings)'};

  :hover {
    color: var(--2B2B31-profile-new-settings);
  }
`;

export const GreyDarkTextContainer = styled('div')<{ hasCursorPointer?: boolean }>`
  ${textFont};
  display: flex;
  color: var(--767676-profile-new-settings);
  cursor: ${(props): string => props.hasCursorPointer ? 'pointer' : 'auto'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PublicIpContainer = styled('div')`
  display: flex;
`;

export const ContainerParams = styled('div')<{ hasCursorPointer?: boolean }>`
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: row;
  color: var(--2B2B31-profile-new-settings);
  width: 100%;
  cursor: ${(props): string => props.hasCursorPointer ? 'pointer' : 'auto'};
  background-color: inherit;
`;

export const ContainerParamsColumn = styled(ContainerParams)`
  flex-direction: column;
  justify-content: center;
  align-items: normal;
  background-color: inherit;
`;

export const ContainerSpaceBetween = styled('div')`
  display: flex;
  gap: 16px;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;

  :hover {
    ${SelectArrowContainer} {
      opacity: 1;
    }
  }
`;

export const SeparatedContainer = styled('div')`
  ${textFont};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 190px;
  color: var(--2B2B31-profile-new-settings);
  cursor: pointer;
  min-height: 20px;

  * + * {
    margin-left: 16px;
  }
`;

export const ContainerParamsNote = styled('div')`
  align-items: normal;
  display: flex;
  flex-direction: row;
  
  & .ql-drawer-container {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const AreaNoteBorder = styled('div')<{ nameAreaActive?: boolean; parentHeight?: string }>`
  width: 273px;
  line-height: 0;
  
  ${(props): SerializedStyles => css`
    height: ${props.parentHeight};
  `}
`;

export const DrawerNameParams = styled('span')`
  ${textFont};
  width: 104px;
  align-items: center;
  color: var(--767676-profile-new-settings);
  position: relative;
  z-index: 10;
  min-height: 20px;
`;

export const DrawerNameParamsContainer = styled('div')`
  min-width: 138px;
  max-width: 138px;
  padding-right: 16px;
  user-select: none;
`;

export const DrawerParams = styled('span')<{ hasCursorPointer?: boolean }>`
  font-size: 14px;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: ${(props): string => props.hasCursorPointer ? 'pointer' : 'auto'};
  width: 100%;
  background-color: inherit;
`;

export const ContainerWebGLData = styled(DrawerParams)`
  display: flex;
  cursor: inherit;
  color: var(--81818A-profile-new-settings);
  justify-content: end;
  ${textFont()};
`;

export const SubContainerWebGL = styled('div')`
  display: block;
  justify-content: flex-start;
  overflow: hidden;
  white-space: nowrap;
  max-width: 150px;
  text-overflow: ellipsis;
`;

export const ContainerUserAgentData = styled('div')`
  ${textFont()}
  visibility: hidden;
  width: 100%;
  padding: 0;
  height: 0;
  white-space: pre-wrap;
`;

export const FingerprintResultContainer = styled(IconWrapperWithDescription)<{
  isFingerprintRefreshing: boolean;
}>`
  display: flex;
  align-items: center;
`;

export const FontsSpan = styled('div')`
  color: var(--767676-profile-new-settings);
  margin-left: 16px;
  font-size: 14px;
`;

export const SettingsInput = styled('input')<{ width?: string }>`
  ${textFont()};
  outline: none;
  padding: 6px 0;
  border: none;

  width: 100%;

  :focus {
    box-shadow: inset 0px -1px 0px var(--CDCDCD-profile-new-settings);
  }

  ::placeholder {
    color: var(--D2D2D5-profile-new-settings);
  }
`;

export const ContainerIcon = styled(IconWrapperWithDescription)`
  ${textFont()};
`;

export const ProfileNameContainer = styled('div')`
  visibility: hidden;
  width: 100%;
  height: 0;
  ${textFont()}
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  font-weight: 400;
  white-space: pre-wrap;
`;

export const TextArea = styled('textarea')<{ minHeight?: number; shouldWrapText?: boolean }>`
  width: 100%;
  outline: none;
  border: none;
  resize: none;
  ${textFont()}
  color: var(--2B2B31-profile-new-settings);
  white-space: pre-wrap;
  overflow-y: hidden;
  padding: 0;
  background-color: inherit;

  :empty:before {
    content: attr(data-placeholder);
    color: var(--808080-profile-new-settings);
  }
  
  ::placeholder{
    color: var(--CDCDCD-profile-new-settings);
  }

  ${(props): SerializedStyles =>
    css`
      min-height: ${props.minHeight ? props.minHeight : 20}px;
    `}
  
  ${(props): SerializedStyles|null => props.shouldWrapText ? css`
    overflow-y: auto;
    white-space: nowrap;
    resize: none;
  ` : null}
`;

export const SortableItemWrapper = styled('div')<{ noPadding?: boolean }>`
  display: flex;
  align-items: start;
  position: relative;
  width: 100%;
  background-color: inherit;
`;

export const EditRow = styled('div')`
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  background-color: inherit;
`;

export const ContainerSubRow = styled('div')<{ hasMarginTop?: boolean; hasGap?: boolean }>`
  display: ${(props): string => props.hasGap ? 'grid' : 'block'};;
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content min-content min-content;
  align-items: center;
  grid-gap: ${(props): string => props.hasGap ? '0 16px' : '0'};
  margin-top: ${(props): string => props.hasMarginTop ? '8px' : '0'};
  background-color: inherit;
  width: 100%;
`;

export const Item = styled.div`
  display: flex;
  min-height: 52px;
  letter-spacing: 0.25px;
  align-items: normal;
  left: auto;
  line-height: 20px;
  padding: 16px;
  background-color: inherit;
`;

