import React, { ChangeEvent, ClipboardEvent, memo, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { determineIsProxyCheckFailed } from '../../../../../common/proxy/utils';
import { IProxy } from '../../../../interfaces';
import { NEW_FEATURES } from '../../../../state/feature-toggle/new-features';
import { PROXY_CHECK_TOOLTIP_LOCATIONS } from '../../../../state/proxy/proxy-check/constants';
import { ProxyCheckTooltipLocation } from '../../../../state/proxy/proxy-check/types/proxy-check-tooltip-location.type';
import { closeProxyManager, useProxyManagerState } from '../../../../state/proxy/proxy-manager-modal-status.atom';
import { unlinkProfileProxy } from '../../../../state/proxy/proxy-operations/link-proxy.operations';
import { pasteProxies } from '../../../../state/proxy/proxy-operations/paste-proxies-operations';
import { searchProxy } from '../../../../state/proxy/proxy-search.atom';
import { useSharedProxies } from '../../../../state/proxy/shared-proxies.atom';
import CheckProxyBtn from '../../check-proxy-button';
import { ISharedProxy } from '../../components/interfaces/shared-proxy.interfaces';
import { PROXY_SELECTOR_PLACEHOLDER_MIN_WIDTH } from '../../constants/styles';
import ProxyFlag, { BIG_FLAG_HEIGHT, SMALL_FLAG_HEIGHT } from '../../proxy-flag';
import { makeProxyTitle } from '../../proxy-helpers';
import { UngroupedProxyStatusWrapper } from '../../proxy-manager/proxy-list/styles';
import { ProxySearchInputEl } from '../../styles/proxy-search-input';
import {
  ProxyFormContainerEl,
  ProxyFormEl,
  ProxySelectorLeftControlsWrapper,
  ProxyTitleBlockEl,
  ProxyTitleWrapperEl,
} from './styles';

type ProxyFormProps = {
  currentProxy?: IProxy;
  profileId: string;
  isSharedProxy: boolean;
  editProxyMode: boolean;
  isInDrawer: boolean;
  localProxySelectorLocation: string;
  selectorContainerElement: React.RefObject<HTMLDivElement>;
};

const ProxyForm: React.FC<ProxyFormProps> = props => {
  const {
    currentProxy,
    profileId,
    isSharedProxy,
    editProxyMode,
    isInDrawer,
    localProxySelectorLocation,
    selectorContainerElement,
  } = props;

  const [searchValue, setSearchValue] = useState<string>('');
  const [inputChanged, setInputChanged] = useState<boolean>(false);

  const input = useRef<HTMLInputElement>(null);

  const sharedProxies = useSharedProxies();
  const { currentProfileId } = useProxyManagerState();

  const { t: translation } = useTranslation();

  const searchInputPlaceholderTranslationKey = NEW_FEATURES.renameProxyToLocation
    ? 'proxies.searchForLocation'
    : 'proxies.searchForProxy';
  const searchInputPlaceholder = translation(searchInputPlaceholderTranslationKey);

  useEffect((): void => {
    setInputChanged(false);
    setSearchValue('');

    if (editProxyMode && input?.current) {
      input.current.focus();
    }
  }, [editProxyMode]);

  useEffect(() => {
    searchProxy(searchValue);
  }, [searchValue, editProxyMode]);

  const handleSubmit = (event: SyntheticEvent): void => {
    event.preventDefault();

    if (inputChanged && !searchValue && currentProfileId) {
      unlinkProfileProxy(currentProfileId);
      closeProxyManager();
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setInputChanged(true);

    setSearchValue(event.target.value);
  };

  const handleFocus = (event: ChangeEvent<HTMLInputElement>): void => event.currentTarget.select();

  const handlePaste = async (event: ClipboardEvent<HTMLInputElement>): Promise<void> => {
    event.persist();
    const proxies = await pasteProxies({
      profileId,
      localProxySelectorLocation,
      isInDrawer,
    });

    if (proxies.length) {
      event.preventDefault();
    }
  };

  const preventUserTextSelection: React.MouseEventHandler<HTMLInputElement> = event =>
    editProxyMode ? null : event.preventDefault();

  const title = makeProxyTitle(currentProxy);
  const noProxy = !currentProxy || currentProxy.mode === 'none';

  let inputWidth;
  if (noProxy) {
    inputWidth = PROXY_SELECTOR_PLACEHOLDER_MIN_WIDTH;
  }

  let sharedProxyInfo: ISharedProxy | undefined;
  let { country } = currentProxy as IProxy;
  let checkProxy = currentProxy as IProxy;
  if (isSharedProxy) {
    sharedProxyInfo = sharedProxies.find(el => el.profileId === profileId);
    if (sharedProxyInfo) {
      ({ country } = sharedProxyInfo);
      checkProxy = {
        ...sharedProxyInfo,
        ...currentProxy,
      };
    }
  }
  const searchInputId = `proxy-search-input-${profileId}`;

  const isCheckFailed = determineIsProxyCheckFailed(currentProxy);
  const isTransparent = isCheckFailed && !editProxyMode;

  const renderFlagBlock = (): JSX.Element | null => {
    if (noProxy) {
      return null;
    }

    let proxyTooltipView: ProxyCheckTooltipLocation = PROXY_CHECK_TOOLTIP_LOCATIONS.selectorProfileTable;
    if (isInDrawer) {
      proxyTooltipView =
        localProxySelectorLocation === 'pinned-proxy'
          ? PROXY_CHECK_TOOLTIP_LOCATIONS.selectorProfileSettingsFavourite
          : PROXY_CHECK_TOOLTIP_LOCATIONS.selectorProfileSettingsProxy;
    }

    return (
      <ProxySelectorLeftControlsWrapper
        isProxyManagerWithGroups={!!NEW_FEATURES.proxyGroupsV2}
        isFailed={isTransparent}
      >
        <UngroupedProxyStatusWrapper isProxyManagerWithGroups={!!NEW_FEATURES.proxyGroupsV2}>
          <CheckProxyBtn
            proxy={checkProxy}
            profileId={profileId}
            proxyTooltipView={proxyTooltipView}
            selectorContainerElement={selectorContainerElement}
            isSharedProxy={isSharedProxy}
          />
        </UngroupedProxyStatusWrapper>
        <ProxyFlag
          countryCode={country || ''}
          height={NEW_FEATURES.proxyGroupsV2 ? BIG_FLAG_HEIGHT : SMALL_FLAG_HEIGHT}
          isCheckFailed={isCheckFailed}
        />
      </ProxySelectorLeftControlsWrapper>
    );
  };

  const renderInvisibleTitle = (): JSX.Element | null => {
    if (noProxy) {
      return null;
    }

    return (
      <ProxyTitleBlockEl className='proxy-title' style={{ opacity: 0 }}>
        <div className='nowrap'>{title || '-'}</div>
      </ProxyTitleBlockEl>
    );
  };

  return (
    <ProxyFormContainerEl addMaxWidth={editProxyMode} newStyle={!!NEW_FEATURES.header} isEditProxy={editProxyMode}>
      {renderFlagBlock()}
      <ProxyTitleWrapperEl newStyle={!!NEW_FEATURES.header} noProxy={noProxy}>
        <ProxyFormEl onSubmit={handleSubmit}>
          {renderInvisibleTitle()}
          <ProxySearchInputEl
            id={searchInputId}
            newStyle={!!NEW_FEATURES.header}
            isProxyManagerWithGroups={!!NEW_FEATURES.proxyGroupsV2}
            ref={input}
            placeholder={searchInputPlaceholder}
            value={!searchValue && !inputChanged ? title : searchValue}
            onChange={handleInputChange}
            onPaste={handlePaste}
            onFocus={handleFocus}
            onMouseDown={preventUserTextSelection}
            width={inputWidth}
            truncate={!editProxyMode}
            isTransparent={isTransparent}
            isInDrawer={isInDrawer}
          />
        </ProxyFormEl>
      </ProxyTitleWrapperEl>
    </ProxyFormContainerEl>
  );
};

export default memo(ProxyForm);
