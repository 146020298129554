import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { WORKSPACE_KEY_NAME } from '../../common/constants/constants';
import { forceReloadProfilesQuery } from './profiles-table/profiles-query';

type WorkspaceId = string | null;

const currentWorkspaceIdAtom = atom<WorkspaceId>(null);

export const getCurrentWorkspaceId = (): string => getDefaultStore().get(currentWorkspaceIdAtom) || '';
export const useCurrentWorkspaceId = (): WorkspaceId => useAtomValue(currentWorkspaceIdAtom);
export const setCurrentWorkspaceId = (newWorkspaceId: WorkspaceId): void => {
  getDefaultStore().set(currentWorkspaceIdAtom, newWorkspaceId);

  if (newWorkspaceId) {
    localStorage.setItem(WORKSPACE_KEY_NAME, JSON.stringify({ id: newWorkspaceId }));
  }

  forceReloadProfilesQuery();
};

export const resetCurrentWorkspaceId = (): void => setCurrentWorkspaceId(null);
