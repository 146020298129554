import styled from '@emotion/styled';

export const ResetButtonWrapper = styled.div`
  display: inline-block;
  margin-left: 16px;
  color: var(--000000D9-profile-table-show-all-tags-button);
  cursor: pointer;
  font-weight: 400;

  &:hover {
    color: var(--00A987-profile-table-show-all-tags-button);
  }
`;

export const ResetButtonIconWrapper = styled.div`
  display: inline-block;
  position: relative;
  top: 2px;
  margin-right: 10px;
`;

export const ResetButtonInfoWrapper = styled.div`
  background-color: var(--FFFFFF-profile-table-show-all-tags-button);
  box-shadow: 0 1px 4px var(--00000003-profile-table-show-all-tags-button), 0 4px 8px var(--00000005-profile-table-show-all-tags-button), 0 1px 12px var(--0000001F-profile-table-show-all-tags-button);
  border-radius: 4px;
  padding: 8px 12px 7px;
  position: relative;
  top: -3px;
`;

export const ResetButtonInfo = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  letter-spacing: 0.25px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--767676-profile-table-show-all-tags-button);
`;
