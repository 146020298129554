import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled';

import { textFont } from '../../../../ui/style-templates';

export const ProxyInfoWrapper = styled.div`
  width: 100%;
  padding: 16px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  color: var(--FFFFFF-proxy-tooltip-info-text);

  ${textFont()}
`;

export const ProxyInfoHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const ProxyInfoButtonsWrapper = styled.div<{ isSingleButtonVisible: boolean }>`
  width: max-content;
  max-width: 64px;
  height: fit-content;
  padding-top: 2px;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  color: var(--767676-proxy-manager-info);
  text-overflow: ellipsis;

  & button {
    margin-right: 13px;
  }

  & > .proxy-selected {
    color: var(--00A987-proxy-manager);
  }

  & > * + * {
    margin-left: 10px;
  }
`;

export const ProxyInfoCheckDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;
export const ProxyInfoRowWrapper = styled.div`
  max-width: 318px;
  display: flex;
  font-size: 14px;
  align-items: center;
`;

export const ProxyOriginText = styled.span`
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ProxyInfoUpdatedAgoDateWrapper = styled.div<{ isFail?: boolean }>`
  color: ${({ isFail }): string => (isFail ? 'var(--F5222D-proxy-manager-info)' : 'var(--767676-proxy-manager-info)')};
  font-size: 14px;
`;

export const ProxyStatusIconWrapper = styled.div<{ isFail?: boolean }>`
  padding: 0 5px;
  text-align: center;

  ${({ isFail }): SerializedStyles =>
    isFail
      ? css`
          color: var(--F5222D-proxy-manager-info);
          width: 25px;
          margin-right: 3px;
          margin-left: -4px;
        `
      : css`
          color: var(--00A987-proxy-manager);
          top: -2px;
          width: 13px;
          margin-right: 11px;
        `}
`;

export const ChangeIpButton = styled.a`
  margin-left: 8px;
  color: var(--767676-proxy-manager-info);
  outline: none;
  text-decoration: none;
  cursor: pointer;

  flex-shrink: 0;

  &:focus,
  &:visited {
    color: var(--767676-proxy-manager-info);
    outline: none;
    text-decoration: none;
  }

  &:hover {
    color: var(--767676-proxy-manager-info);
    outline: none;
    text-decoration: underline;
  }
`;

export const ProxyStatusDotWrapper = styled.div`
  margin-right: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
