import { Tooltip } from 'antd';
import React, { useState } from 'react';

import { TextEllipsis, Wrapper } from './styles';
import { NEW_FEATURES } from '../../../../state/feature-toggle/new-features';
import PerformanceObserverService from '../../../../services/performance-observer/performance-observer.service';
import { useIsProfilesSettingsOpen } from '../../../../state/profiles-settings-atom';
import TooltipCustom from '../../../../ui/tooltip-custom';

interface IProfileName {
  profileName: string;
  onStartEdit: () => void;
}

const ProfileName: React.FC<IProfileName> = ({
  profileName,
  onStartEdit,
}) => {
  const isProfilesSettingsOpen = useIsProfilesSettingsOpen();

  const [anchorElTooltip, setAnchorElTooltip] = useState<HTMLElement|null>(null);

  const handleClick: React.MouseEventHandler = (event) => {
    if (!isProfilesSettingsOpen) {
      event.stopPropagation();
    }

    const performanceObserverService = PerformanceObserverService.getInstance();
    performanceObserverService.handleUserAction({ userAction: 'start-name-column-edit' });

    onStartEdit();
  };

  const renderProfileName = (): JSX.Element => {
    if (!NEW_FEATURES.header) {
      return (
        <Tooltip title={profileName}>
          {profileName}
        </Tooltip>
      );
    }

    return (
      <>
        <TextEllipsis>
          {profileName}
        </TextEllipsis>
        <TooltipCustom
          anchorEl={anchorElTooltip}
          value={profileName}
        />
      </>
    );
  };

  return (
    <Wrapper
      newStyle={!!NEW_FEATURES.header}
      onClick={handleClick}
      onMouseEnter={(e): void => setAnchorElTooltip(e.currentTarget)}
      onMouseLeave={(): void => setAnchorElTooltip(null)}
    >
      {renderProfileName()}
    </Wrapper>
  );
};

export default ProfileName;
