import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { IProfilesMenu } from './interfaces';
import { MenuItemsProps, MenuItemWithPopconfirm } from './menu-item-with-popconfirm';
import { MassActionsButtonLine, MassActionsMarginBlock } from './styles';
import { workspaceContext } from '../../../state';
import { filterProfilesByPermission } from '../../../state/profiles-list.atom';
import { useIsProfilesSettingsOpen } from '../../../state/profiles-settings-atom';
import {
  getProfilesTableSelectedProfilesActions, resetProfilesTableSelectedIds,
  toggleAllProfilesSelected,
  useAllProfilesSelectionStatus, useProfilesTableSelectedIds,
} from '../../../state/profiles-table-selected-ids.atom';
import { GologinNewCheckbox } from '../../../ui/gologin-checkbox/gologin-new-checkbox';
import {
  IconBookmark,
  IconClone2,
  IconClose,
  IconEdit,
  IconExport,
  IconFolder,
  IconPen, IconPlay,
  IconPlug,
  IconRefresh,
  IconShare,
  IconStop2,
  IconSwap,
  IconTrash,
} from '../../../ui/gologin-header/icons';
import { IconProxyDataCenter } from '../../../ui/gologin-header/icons/icon-proxy-datacenter';
import {
  getActionsButtonStyles,
  MassActionsButtonText,
  MassActionsRow,
} from '../../common/custom-table/components/mass-actions-menu/styles';
import EditTagsMenu from '../components/edit-tags-menu';

const isElectron = !!window.require;

const MultipleOperationsMenu: FC<IProfilesMenu> = (props): JSX.Element => {
  const {
    isBrowserUpdating,
    isProcessing,
    toggleModalSharing,
    openFoldersModal,
    openBookmarksManager,
    toggleModalTransfer,
    openExtensionsModal,
    cloneProfile,
    updateTag,
    addTag,
    removeProfileTag,
    removeTag,
    updateFingerprints,
    deleteOrLeaveMultipleProfiles,
    onRenameProfiles,
    redirectToSite,
    isStartDisabled,
    stopProfiles,
    startProfiles,
    limitForLaunch,
    setIsShowProxyManager,
    exportProfile,
    anchorEditTagsEl,
    setAnchorEditTagsEl,
    handleEditTagsClick,
    setAnchorElTooltip,
  } = props;

  const {
    isUnpaid: workspaceIsUnpaid,
    paymentIsTrial: workspaceIsTrial,
  } = useContext(workspaceContext);

  const isProfilesSettingsOpen = useIsProfilesSettingsOpen();
  const selectedProfilesIds = useProfilesTableSelectedIds();
  const selectedProfilesActions = getProfilesTableSelectedProfilesActions();

  const { t: translation } = useTranslation();

  const allRowsSelectionStatus = useAllProfilesSelectionStatus();
  const isSelected = allRowsSelectionStatus === 'all';
  const isIndeterminate = allRowsSelectionStatus === 'some';

  const canExportProfiles = filterProfilesByPermission(selectedProfilesIds, 'exportProfile');
  const showExportPayPopUp = !canExportProfiles && (workspaceIsUnpaid || workspaceIsTrial);

  const renderTitleButtonToRemoveProfile = (): string => {
    if (selectedProfilesActions.length === 2) {
      return `${translation('base.delete')} / ${translation('base.leave')}`;
    }

    if (selectedProfilesActions.length === 1) {
      return translation(`base.${selectedProfilesActions[0].toLowerCase()}`);
    }

    return translation('base.delete');
  };

  const isHasPopconfirmToStartProfiles = !isBrowserUpdating && isStartDisabled;

  const actionButtons: MenuItemsProps[] = [
    {
      Icon: IconPlay,
      text: translation('profiles.multipleOperations.run'),
      onClick: isHasPopconfirmToStartProfiles ? (): null => null : startProfiles,
      visible: isElectron,
      hasTooltip: isBrowserUpdating,
      setAnchorElTooltip,
      hasPopconfirm: isHasPopconfirmToStartProfiles,
      popconfirmProps: {
        title: translation('profiles.multipleOperations.confirms.startProfilesFirstPart') + limitForLaunch + translation('profiles.multipleOperations.confirms.startProfilesSecondPart'),
        onConfirm: startProfiles,
        cancelText: translation('base.no'),
        okText: translation('base.yes'),
      },
    },
    {
      Icon: IconStop2,
      text: translation('profiles.multipleOperations.stop'),
      onClick: stopProfiles,
    },
    {
      Icon: IconFolder,
      text: translation('profiles.multipleOperations.addToFolder'),
      onClick: () => openFoldersModal(selectedProfilesIds),
    },
    {
      Icon: IconProxyDataCenter,
      text: translation('proxy.title'),
      onClick: () => setIsShowProxyManager(true),
    },
    {
      Icon: IconShare,
      text: translation('profiles.multipleOperations.share'),
      onClick: (): void => toggleModalSharing(true),
    },
    {
      Icon: IconEdit,
      text: translation('tags.editTagsBtnText'),
      onClick: handleEditTagsClick,
    },
    {
      Icon: IconPlug,
      text: translation('profiles.multipleOperations.extensions'),
      onClick: openExtensionsModal,
    },
    {
      Icon: IconBookmark,
      text: translation('profilesTable.multipleOperations.bookmarks'),
      onClick: openBookmarksManager,
    },
    {
      Icon: IconPen,
      text: translation('profiles.multipleOperations.renameProfiles', { count: selectedProfilesIds.length }),
      onClick: onRenameProfiles,
    },
    {
      Icon: IconClone2,
      text: translation('profiles.menu.clone'),
      onClick: cloneProfile,
    },
    {
      Icon: IconSwap,
      text: translation('profiles.multipleOperations.transfer'),
      onClick: (): void => toggleModalTransfer(true),
    },
    {
      Icon: IconExport,
      text: translation('base.export'),
      onClick: exportProfile,
      hasPopconfirm: showExportPayPopUp,
      popconfirmProps: {
        title: translation('profiles.multipleOperations.confirms.exportProfilesUpgradePlan'),
        onConfirm: redirectToSite,
        cancelText: translation('base.cancel'),
        okText: translation('personalArea.billing.changePlanSubmitBtnText'),
      },
    },
    {
      Icon: IconRefresh,
      text: translation('profiles.multipleOperations.updateFingerprint'),
      hasPopconfirm: true,
      popconfirmProps: {
        title: translation('profiles.multipleOperations.confirms.newFingerprints'),
        onConfirm: updateFingerprints,
        cancelText: translation('base.no'),
        okText: translation('base.yes'),
        disabled: isProcessing,
      },
    },
    {
      Icon: IconTrash,
      text: renderTitleButtonToRemoveProfile(),
      hasPopconfirm: true,
      popconfirmProps: {
        title: translation('profiles.multipleOperations.confirms.deleteProfiles'),
        onConfirm: deleteOrLeaveMultipleProfiles,
        cancelText: translation('base.no'),
        okText: translation('base.yes'),
        disabled: isProcessing,
      },
    },
  ];

  return (
    <>
      <MassActionsMarginBlock isProfilesSettingsOpen={isProfilesSettingsOpen}>
        <MassActionsButtonLine
          {...getActionsButtonStyles(isProcessing)}
          style={{ cursor: 'default' }}
        >
          <MassActionsRow>
            <GologinNewCheckbox
              onChange={toggleAllProfilesSelected}
              checked={isSelected}
              isIndeterminate={isIndeterminate}
              isSmall={true}
            />
            <MassActionsButtonText>
              {translation('tableProfiles.multipleOperations.selected', { count: selectedProfilesIds.length })}
            </MassActionsButtonText>
            <IconClose
              onClick={resetProfilesTableSelectedIds}
              padding={0}
              iconColor='var(--B5B5BA-header-account-menu)'
              iconHoveredColor='var(--36363D)'
              size={12}
            />
          </MassActionsRow>
        </MassActionsButtonLine>
        {actionButtons.map((actionButton, index) => (
          <MenuItemWithPopconfirm key={index} {...actionButton} />
        ))}
      </MassActionsMarginBlock>
    </>
  );
};

export default MultipleOperationsMenu;
