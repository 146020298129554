import { createContext } from 'react';

import { DropFileStep } from '../hooks';

export interface IImportFilesCtx {
  dropFileStep: DropFileStep;
  setDropFileStep: (state: DropFileStep) => void;
  needParseName: boolean;
  setNeedParseName: (state: boolean) => void;
  manyProfilesName: string;
  setManyProfilesName: (name: string) => void;
  errorImportMessage: string;
  setErrorImportMessage: (message: string) => void;
  needOpenSpintaxPopup: boolean;
  setNeedOpenSpintaxPopup: (state: boolean) => void;
}

export const IMPORT_FILES_DEFAULT_VALUE: IImportFilesCtx = {
  dropFileStep: null,
  setDropFileStep: () => {},
  needParseName: false,
  setNeedParseName: () => {},
  manyProfilesName: '',
  setManyProfilesName: () => {},
  errorImportMessage: '',
  setErrorImportMessage: () => {},
  needOpenSpintaxPopup: false,
  setNeedOpenSpintaxPopup: () => {},
};

export const importFilesContext = createContext<IImportFilesCtx>(IMPORT_FILES_DEFAULT_VALUE);
