import React from 'react';

const IconProxyPencil = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 7L9 3" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.5 14.5L0.5 15.5L1.5 10.5L11.5 0.5L15.5 4.5L5.5 14.5Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default IconProxyPencil;
