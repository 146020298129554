import React, { FC } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import { useProfilesSettingsState } from '../../../../state/profiles-settings-atom';
import { ISettingParam, SETTINGS_PARAM } from '../../interfaces';
import { DrawerNameParams, DrawerNameParamsContainer, Item, SortableItemWrapper } from '../../styles';

interface ISettingRow {
  settings: ISettingParam;
  setting: typeof SETTINGS_PARAM[number];
  sectionId: string;
  index: number;
}

const SettingRow: FC<ISettingRow> = (props) => {
  const { settings, setting, sectionId, index } = props;

  const { profileIds } = useProfilesSettingsState();
  const isMultiProfileSettings = profileIds.length > 1;

  const { t: translation } = useTranslation();

  const settingKey = `${sectionId}-${setting}`;
  const item = {
    ...settings[setting],
    props: {
      ...settings[setting].props,
      localSettingId: settingKey,
      localProxySelectorLocation: settingKey,
    },
  };

  const localisationKeys: Record<typeof SETTINGS_PARAM[number], string> = {
    name: 'overview.name',
    notes: 'overview.note',
    folders: 'overview.folder',
    proxy: 'overview.proxy',
    language: 'overview.language',
    timezone: 'overview.timezone',
    geolocation: 'overview.geolocation',
    resolution: 'overview.resolution',
    cookies: 'overview.cookie',
    fingerprint: 'overview.fingerprint',
    webRTCPublic: 'webrtc.quickSettingsTitle',
    customDns: 'proxyLocations.customDns',
    useragent: 'browser.userAgent',
    startUrl: 'browser.startUrl',
    extensions: 'browser.extensions',
    fonts: 'browser.fonts',
    bookmarks: 'browser.bookmarks',
    activeSessionLock: 'storage.activeSessionLock',
    sessionSaving: 'storage.sessionSaving',
    browserHistorySaving: 'storage.history',
    browserBookmarkSaving: 'storage.bookmark',
    googleServices: 'storage.google',
    passwordSaving: 'storage.password',
    localStorageAndIndexedDB: 'storage.localStorage',
    browserPlugins: 'storage.plugins',
    extensionStorage: 'storage.extension',
    externalExtensions: 'storage.externalExtensions',
    deviceMemory: 'hardware.device',
    hardwareConcurrency: 'hardware.hardware',
    mediaDevices: 'hardware.mediaDevices',
    canvas: 'hardware.canvas',
    webglImage: 'hardware.webglImage',
    webglMetadata: 'hardware.webglMetadata',
    audioContext: 'hardware.audioContext',
    maxTouchPoints: 'hardware.maxTouchPoints',
  };

  // Костыль из-за position: absolute у заметок и прокси, быстро решить не смог
  const getMarginSection = (): number => {
    if (isMultiProfileSettings) {
      return 0;
    }

    if (setting === 'notes') {
      return -7;
    }

    if (setting === 'proxy') {
      return -8;
    }

    return 0;
  };

  return (
    <Draggable
      key={settingKey}
      draggableId={settingKey}
      index={index}
    >
      {(provide): JSX.Element => (
        <Item ref={provide.innerRef} id={settingKey}>
          <div {...provide.dragHandleProps} />
          <DrawerNameParamsContainer>
            <DrawerNameParams>
              {translation('quickSettings.' + localisationKeys[setting])}
            </DrawerNameParams>
          </DrawerNameParamsContainer>
          <SortableItemWrapper
            style={{ marginLeft: getMarginSection() }}
            className={(setting === 'notes' && !isMultiProfileSettings) ? 'ql-drawer-container' : ''}
          >
            {item}
          </SortableItemWrapper>
        </Item>
      )}
    </Draggable>
  );
};

export default SettingRow;
