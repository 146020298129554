import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

const IconSelectAll: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_11476_141511)'>
        <path
          d='M4.5 5.5L4.5 14.5C4.5 15.0523 4.94771 15.5 5.5 15.5L14.5 15.5C15.0523 15.5 15.5 15.0523 15.5 14.5L15.5 5.5C15.5 4.94772 15.0523 4.5 14.5 4.5L5.5 4.5C4.94771 4.5 4.5 4.94771 4.5 5.5Z'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11.5 2.5L11.5 1.5C11.5 0.947715 11.0523 0.5 10.5 0.5L1.5 0.5C0.947715 0.5 0.5 0.947715 0.5 1.5L0.5 10.5C0.5 11.0523 0.947715 11.5 1.5 11.5L2.5 11.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7 10L8.83333 12L13 8'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  </IconWrapper>
);

export default IconSelectAll;
