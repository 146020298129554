import { purgeAppCache } from '../object-pool/idb-cache/meta-db';
import { getCurrentWorkspaceId } from '../state/current-workspace-id.atom';
import { prepareDbgFeatureDef } from './feature';
import { log } from './log';
import { prepareDbgMonitorDef } from './monitor';
import { prepareDbgPoolDef } from './pool';
import { prepareDbgApiDef, prepareDbgReqDef } from './requests';
import { prepareDbgUtilDef } from './util';

export const defineDbg = (): void => {
  (window as any).dbg = {
    wid: () => getCurrentWorkspaceId(),
    idbCache: { purgeAll: async (): Promise<void> => {
      await purgeAppCache();

      log('purged all idb cache');
    } },
    feature: prepareDbgFeatureDef(),
    monitor: prepareDbgMonitorDef(),
    api: prepareDbgApiDef(),
    req: prepareDbgReqDef(),
    pool: prepareDbgPoolDef(),
    util: prepareDbgUtilDef(),
  };

  // TODO: add help
  log('defined window.dbg');
};
