const dateToText = (date?: Date | string): string => (
  date && new Date(date).toLocaleString(
    'en-EN',
    {
      day: 'numeric',
      month: 'short',
      hour: '2-digit',
      minute: '2-digit',
    },
  )
) || '—';

export default dateToText;
