import { atom, getDefaultStore, useAtomValue } from 'jotai';

export const automationSearchAtom = atom<string>('');

export const getAutomationSearch = () => getDefaultStore().get(automationSearchAtom);

export const setAutomationSearch = (newAutomationSearch: string) =>
  getDefaultStore().set(automationSearchAtom, newAutomationSearch);

export const useAutomationSearch = () => useAtomValue(automationSearchAtom);
