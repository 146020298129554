import React from 'react';
import { Trans } from 'react-i18next';

import GeoProxyTrafficListItem from './geoproxy-traffic-list-item';
import { CardList, CardListItem, CardListProfilesItem, DescriptionPlan, IconContainer } from './styles';
import { NEW_FEATURES } from '../../../../state/feature-toggle/new-features';
import IconPlanCheck from '../../../../ui/icons/IconPlanCheck';
import { IPreviewPlan } from '../../interfaces';

export type PreviewCardContentProps = {
  isCustomPlan: boolean;
  getMaxProfilesItem: () => JSX.Element;
  planName: string;
  maxAccountShares?: number;
  geoProxyTraffic: IPreviewPlan['geoProxyTraffic'];
}

export const PreviewCardContent: React.FC<PreviewCardContentProps> = (props) => {
  const { isCustomPlan, getMaxProfilesItem, planName, maxAccountShares, geoProxyTraffic } = props;

  return (
    <CardList isCustomPlan={isCustomPlan}>
      <CardListItem isCustomPlan={isCustomPlan}>
        <IconContainer>
          <IconPlanCheck />
        </IconContainer>
        <CardListProfilesItem>
          {getMaxProfilesItem()}
          {' '}
          <DescriptionPlan style={{ marginLeft: 0 }}>
            <Trans i18nKey={`pricing.${planName.toLowerCase()}Plan.tick1`} />
          </DescriptionPlan>
        </CardListProfilesItem>
      </CardListItem>
      <CardListItem isCustomPlan={isCustomPlan}>
        <IconContainer>
          <IconPlanCheck />
        </IconContainer>
        <DescriptionPlan>
          <Trans i18nKey={`pricing.${planName.toLowerCase()}Plan.tick2`} />
        </DescriptionPlan>
      </CardListItem>
      <CardListItem isCustomPlan={isCustomPlan}>
        <IconContainer>
          <IconPlanCheck />
        </IconContainer>
        <DescriptionPlan>
          <Trans i18nKey={`pricing.${planName.toLowerCase()}Plan.tick3`} />
        </DescriptionPlan>
      </CardListItem>
      {maxAccountShares ? (
        <CardListItem isCustomPlan={isCustomPlan}>
          <IconContainer>
            <IconPlanCheck />
          </IconContainer>
          <DescriptionPlan>
            {maxAccountShares}
            {' '}
            <Trans i18nKey={`pricing.${planName.toLowerCase()}Plan.tick4`} />
          </DescriptionPlan>
        </CardListItem>
      ) : null}
      {NEW_FEATURES.pricingGeoProxyTraffic ? (
        <GeoProxyTrafficListItem
          isCustomPlan={isCustomPlan}
          planName={planName}
          geoProxyTraffic={geoProxyTraffic}
        />
      ) : null}
    </CardList>
  );
};
