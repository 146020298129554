import { ILinesWithMetrics, IPrepareToDrawTextData } from '../interfaces';
import { getLines } from './get-lines';

export const prepareToDrawText = ({ context, text, maxWidth, font, textAlign, textBaseline }: IPrepareToDrawTextData): ILinesWithMetrics => {
  context.font = font;
  context.textAlign = textAlign;
  context.textBaseline = textBaseline;

  return getLines(context, text, maxWidth);
};
