import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconUnlink: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g xmlns='http://www.w3.org/2000/svg' clipPath='url(#clip0_24070_46799)'>
        <path
          d='M12 9.00025L13.975 6.97525C15.342 5.60825 15.342 3.39225 13.975 2.02525C12.608 0.65825 10.392 0.65825 9.025 2.02525L7 4.00025'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.00001 12L6.97501 13.975C5.60801 15.342 3.39201 15.342 2.02501 13.975C0.658006 12.608 0.658006 10.392 2.02501 9.025L4.00001 7'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M9.5 6.5L11.5 4.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M4.5 11.5L6.5 9.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M1.5 1.5L14.5 14.5' strokeLinecap='round' strokeLinejoin='round' />
      </g>
    </svg>
  </IconWrapper>
);
