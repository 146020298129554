import { IRestoreProfiles } from './interfaces';
import { API_BASE_URL } from '../../../../common/constants/constants';
import { IDeletedProfilesTable } from '../../../interfaces';
import { http } from '../../../services';

export const getDeletedProfileList = async (workspaceId: string, offset: number): Promise<IDeletedProfilesTable> =>
  http(`${API_BASE_URL}/deleted-profiles/v2?currentWorkspace=${workspaceId}&offset=${offset}`, {
    method: 'GET',
  }).then((res: any) => res.body);

export const restoreProfiles = async (profileIds: string[], workspaceId: string): Promise<IRestoreProfiles> =>
  http(`${API_BASE_URL}/deleted-profiles/restore`, {
    method: 'POST',
    body: JSON.stringify({
      profileIds,
      workspaceId,
    }),
  }).then((res: any) => res.body);
