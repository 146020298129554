import { Icon, Radio, Tooltip } from 'antd';
import React, { ChangeEvent, Component } from 'react';
import { TransProps, withTranslation } from 'react-i18next';

import { ElementContainer, RadioButton, TooltipText } from './styles';

declare interface IWebRTCTab extends TransProps {
  updateProfileInfo: any;
  profileInfo: any;
  setUpdatedField: (field: string) => any;
  t: (key: string) => string;
}
class WebRTCTab extends Component<IWebRTCTab> {
  private setMode = (event: ChangeEvent<HTMLInputElement>): void => {
    const { props } = this;
    const { webRTC = {} } = props.profileInfo;

    props.updateProfileInfo({
      webRTC: {
        ...webRTC,
        enable: event.target.value,
      },
    });

    props.setUpdatedField('webrtc.mode');
  };

  public getWebIcon = (): JSX.Element => {
    const { webRTC = {} } = this.props.profileInfo;
    const { enable } = webRTC;

    if (!enable) {
      return (
        <>
          <Tooltip
            title={(
              <TooltipText>
                {this.props.t('webrtc.disabled.help')}
              </TooltipText>
            )}
            overlayStyle={{ backgroundColor: 'var(--FFFFFF-create-profile-webrtc)', color: 'var(--000000-create-profile-webrtc)' }}
            placement='rightBottom'
            arrowPointAtCenter={true}
          >
            <Icon
              style={{
                marginTop: 7,
                marginLeft: 5,
                marginBottom: 7,
                opacity: 0.4,
              }}
              type='info-circle'
            />
          </Tooltip>
        </>
      );
    }

    return (
      <>
        <Tooltip
          title={(
            <TooltipText>
              {this.props.t('profileSettings.webrtc.enabledInfo')}
            </TooltipText>
          )}
          overlayStyle={{ backgroundColor: 'var(--FFFFFF-create-profile-webrtc)', color: 'var(--000000-create-profile-webrtc)' }}
          placement='rightBottom'
          arrowPointAtCenter={true}
        >
          <Icon
            style={{
              marginTop: 7,
              marginLeft: 5,
              marginBottom: 7,
              opacity: 0.4,
            }}
            type='info-circle'
          />
        </Tooltip>
      </>
    );
  };

  public render(): JSX.Element {
    return (
      <>
        <ElementContainer>
          <Radio.Group
            value={this.props.profileInfo.webRTC.enable}
            onChange={this.setMode}
          >
            <Tooltip
              title={(
                <TooltipText>
                  {this.props.t('profileSettings.webrtc.enabledInfo')}
                </TooltipText>
              )}
              overlayStyle={{ backgroundColor: 'var(--FFFFFF-create-profile-webrtc)', color: 'var(--000000-create-profile-webrtc)' }}
              placement='topRight'
              arrowPointAtCenter={true}
            >
              <RadioButton value={true}>{this.props.t('profileSettings.webrtc.enabled')}</RadioButton>
            </Tooltip>
            <Tooltip
              title={(
                <TooltipText>
                  {this.props.t('webrtc.disabled.help')}
                </TooltipText>
              )}
              overlayStyle={{ backgroundColor: 'var(--FFFFFF-create-profile-webrtc)', color: 'var(--000000-create-profile-webrtc)' }}
              placement='top'
              arrowPointAtCenter={true}
            >
              <RadioButton value={false}>{this.props.t('profileSettings.webrtc.disabled')}</RadioButton>
            </Tooltip>
          </Radio.Group>
          {this.getWebIcon()}
        </ElementContainer>
      </>
    );
  }
}

export default withTranslation()(WebRTCTab);
