import React, { FC } from 'react';

import ModernSwitch from '../../../../ui/modern-switch';
import { IChangeSetting } from '../../interfaces';
import { ContainerParams } from '../../styles';

interface IExternalExtensions extends IChangeSetting {
  enableExternalExtensions: boolean;
}

export const ExternalExtensions: FC<IExternalExtensions> = ({ changeSetting, enableExternalExtensions }) => (
  <ContainerParams>
    <ModernSwitch
      checked={enableExternalExtensions}
      onChange={(checked): void => changeSetting({ storage: { enableExternalExtensions: checked } })}
    />
  </ContainerParams>
);
