import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconProxyDataCenterSmall: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width={props.size || 16} height={props.size || 16} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path d='M14.75 1.25H1.25V6.65H14.75V1.25Z' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M6.64922 3.94922H3.94922' strokeLinecap='round' strokeLinejoin='round' />
        <path
          d='M12.0506 4.4C12.2991 4.4 12.5006 4.19853 12.5006 3.95C12.5006 3.70147 12.2991 3.5 12.0506 3.5C11.8021 3.5 11.6006 3.70147 11.6006 3.95C11.6006 4.19853 11.8021 4.4 12.0506 4.4Z'
        />
        <path d='M14.75 9.35156H1.25V14.7516H14.75V9.35156Z' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M6.64922 12.0508H3.94922' strokeLinecap='round' strokeLinejoin='round' />
        <path
          d='M12.0506 12.5016C12.2991 12.5016 12.5006 12.3001 12.5006 12.0516C12.5006 11.803 12.2991 11.6016 12.0506 11.6016C11.8021 11.6016 11.6006 11.803 11.6006 12.0516C11.6006 12.3001 11.8021 12.5016 12.0506 12.5016Z'
        />
      </g>
    </svg>

  </IconWrapper>
);
