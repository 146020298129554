import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IFullProfile } from '../../../../interfaces';
import { extensionsContext } from '../../../../state';
import { updateProfileData } from '../../../../state/profiles-map.atom';
import { openProfilesTableModal, PROFILE_EXTENSIONS_MODAL_NAME } from '../../../../state/profiles-table-modal.atom';
import { IconPlusFill } from '../../../../ui/gologin-header/icons';
import { blackMain, grey, greyInformers } from '../../../../ui/style-templates/colors';
import { getExtensionList } from '../../../profileSettingsComponents/extensionsTab/get-extension-list';
import { IExtensionFromDb } from '../../../profileSettingsComponents/extensionsTab/interfaces/extension.interface';
import { ContainerIcon, SeparatedContainer } from '../../styles';

interface IExtensionsProps {
  fullProfile: IFullProfile;
}

export const Extensions: FC<IExtensionsProps> = (props) => {
  const { fullProfile } = props;
  const { id: profileId, chromeExtensions = [], userChromeExtensions = [] } = fullProfile;

  const [profileExtensions, setProfileExtensions] = useState<IExtensionFromDb[]>([]);

  const { isLoading: isLoadingExtensions, updateExtensionsMap, getExtensions } = useContext(extensionsContext);

  const { t: translation } = useTranslation();

  useEffect(() => {
    fetchProfileExtensions();
  }, [profileId, chromeExtensions, userChromeExtensions]);

  const fetchProfileExtensions = async (): Promise<void> => {
    const extensionList = await getExtensionList({
      chromeExtensions,
      userChromeExtensions,
      updateProfileInfo: updateProfileData,
      isLoadingExtensions,
      updateExtensionsMap,
      getExtensions,
    });

    setProfileExtensions(extensionList);
  };

  const renderExtensions = (): JSX.Element => {
    if (!profileExtensions.length) {
      return (
        <ContainerIcon
          iconColor={greyInformers}
          iconHoveredColor={blackMain}
          textColor={grey}
          textHoveredColor={blackMain}
          iconType='stroke'
        >
          <IconPlusFill padding={0} margin='0 8px 0 0' />
          {translation('storage.extension.addExtensions')}
        </ContainerIcon>
      );
    }

    return (
      <SeparatedContainer>
        {profileExtensions.map(({ name, extId }) => (
          <span key={extId}>
            {name}
          </span>
        ))}
      </SeparatedContainer>
    );
  };

  return (
    <>
      <div onClick={(): void => openProfilesTableModal(PROFILE_EXTENSIONS_MODAL_NAME, [profileId])}>
        {renderExtensions()}
      </div>
    </>
  );
};
