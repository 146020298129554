import { Icon } from 'antd';
import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { AddNotesContainer } from './styles';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { IconPlusFill } from '../../../ui/gologin-header/icons';
import { IconWrapperWithDescription } from '../../../ui/gologin-header/icons/wrapper';
import { textFont } from '../../../ui/style-templates';

interface IAddNotesPlaceholder {
  isInDrawer?: boolean;
  onOpen: () => void;
  placeholderTranslationKey?: string;
}

const AddNotesPlaceholder: FC<IAddNotesPlaceholder> = (props) => {
  const { isInDrawer, onOpen, placeholderTranslationKey = 'notes.addNotesPlaceholder' } = props;

  if (!(NEW_FEATURES.drawer && isInDrawer)) {
    return (
      <AddNotesContainer
        newStyle={!!NEW_FEATURES.header}
        onClick={onOpen}
      >
        <Icon style={{ marginRight: 8 }} type='plus' />
        <Trans i18nKey={placeholderTranslationKey} />
      </AddNotesContainer>
    );
  }

  return (
    <IconWrapperWithDescription
      iconColor='var(--B5B5BA-notes)'
      iconHoveredColor='var(--2B2B31-notes)'
      textColor='var(--81818A-notes)'
      textHoveredColor='var(--2B2B31-notes)'
      iconType='stroke'
      style={{ ...textFont }}
    >
      <AddNotesContainer
        newStyle={!!NEW_FEATURES.header}
        newDrawer={!!NEW_FEATURES.drawer}
        onClick={onOpen}
      >
        <IconPlusFill padding={0} margin='0 8px 0 0' />
        <Trans i18nKey={placeholderTranslationKey} />
      </AddNotesContainer>
    </IconWrapperWithDescription>
  );
};

export default AddNotesPlaceholder;
