import { Icon } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Wrapper } from './styles';
import TooltipOsIcon from './tooltip-os-icon';
import { NEW_FEATURES } from '../../../../state/feature-toggle/new-features';
import { OsType } from '../../../../types';
import { IconCircle, IconPin, IconUser2 } from '../../../../ui/gologin-header/icons';
import { IconWrapperWithDescription } from '../../../../ui/gologin-header/icons/wrapper';
import { greenMain, greyInformers } from '../../../../ui/style-templates/colors';
import TooltipCustom from '../../../../ui/tooltip-custom';

interface IProfileNameIcons {
  os: OsType;
  isM1?: boolean;
  isPinned?: boolean;
  isShared?: boolean;
  isNewProfile?: boolean;
}

const ProfileNameIcons: React.FC<IProfileNameIcons> = (props) => {
  const [anchorElTooltip, setAnchorElTooltip] = useState<HTMLElement|null>(null);
  const [tooltipText, setTooltipText] = useState<string>('');
  const { os, isM1, isPinned, isShared, isNewProfile } = props;

  const { t: translation } = useTranslation();

  if (!NEW_FEATURES.header) {
    return (
      <Wrapper onClick={(event): void => event.stopPropagation()}>
        <TooltipOsIcon os={os} isM1={false} />
        {isPinned ? <Icon type='pushpin' style={{ marginLeft: 4 }} /> : null}
      </Wrapper>
    );
  }

  return (
    <Wrapper newStyle={NEW_FEATURES.header} onClick={(event): void => event.stopPropagation()}>
      {isShared ? (
        <IconWrapperWithDescription
          colorIconType='activeGray'
          onMouseEnter={(event): void => {
            setAnchorElTooltip(event.currentTarget);
            setTooltipText(translation('tooltip.profilesTable.sharedProfile'));
          }}
          onMouseLeave={(): void => setAnchorElTooltip(null)}
          iconColor='var(--B5B5BA-profile-table-profile-name-icons)'
          iconType='stroke'
        >
          <IconUser2 padding={0} />
        </IconWrapperWithDescription>
      ) : null}
      {isPinned ? (
        <IconWrapperWithDescription
          colorIconType='activeGray'
          onMouseEnter={(event): void => {
            setAnchorElTooltip(event.currentTarget);
            setTooltipText(translation('tooltip.profilesTable.pinnedProfile'));
          }}
          onMouseLeave={(): void => setAnchorElTooltip(null)}
          iconColor='var(--B5B5BA-profile-table-profile-name-icons)'
          iconType='stroke'
        >
          <IconPin padding={0} />
        </IconWrapperWithDescription>
      ) : null}
      {isNewProfile ? (
        <IconWrapperWithDescription
          colorIconType='green'
          onMouseEnter={(event): void => {
            setAnchorElTooltip(event.currentTarget);
            setTooltipText(translation('tooltip.profilesTable.newProfile'));
          }}
          onMouseLeave={(): void => setAnchorElTooltip(null)}
          iconColor='var(--00A987-profile-table-profile-name-icons)'
          iconType='stroke'
        >
          <IconCircle padding={0} />
        </IconWrapperWithDescription>
      ) : null}
      <TooltipCustom anchorEl={anchorElTooltip} value={tooltipText} />
    </Wrapper>
  );
};

export default ProfileNameIcons;
