import React, { useState, useEffect, useRef } from 'react';

import TooltipCustom from './index';
import { ITooltipWrapper } from './interfaces';
import { TooltipBox } from './styles';

const TooltipWrapper: React.FC<ITooltipWrapper> = (props) => {
  const {
    hasTooltipAnimation,
    style: tooltipContainerStyle,
    containerStyle,
    value,
    placement,
    children,
    isTooltipHoverable,
    isTextEllipsis = true,
  } = props;

  const [anchorElTooltip, setAnchorElTooltip] = useState<HTMLElement|null>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const containerRef = useRef(null);

  const showTooltip = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => setAnchorElTooltip(event.currentTarget);

  const hideTooltip = (): void => setAnchorElTooltip(null);

  useEffect(() => {
    const element = containerRef.current;
    if (element) {
      setIsOverflowing(element.scrollWidth > element.clientWidth);
    }
  }, [value]);

  return (
    <TooltipBox
      ref={containerRef}
      isTextEllipsis={isTextEllipsis}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      style={containerStyle}
    >
      {children}
      {!isTextEllipsis || isOverflowing ? (
        <TooltipCustom
          anchorEl={anchorElTooltip}
          value={value}
          containerStyle={tooltipContainerStyle}
          hasTooltipAnimation={hasTooltipAnimation}
          placement={placement}
          isTooltipHoverable={isTooltipHoverable}
        />
      ) : null}
    </TooltipBox>
  );
};

export default TooltipWrapper;
