import styled from '@emotion/styled';
import React, { FC } from 'react';

import { IconSpinner } from '../../ui/gologin-header/icons';

const Container = styled.div`
  background: var(--FFFFFF);
  height: 100%;
  font-size: 40px;
  padding-top: 30px;
  margin-bottom: 30px;
  text-align: center;
  color: var(--222222);
  flex: 1;
`;

const LoadingPage: FC = () => (
  <Container>
    <IconSpinner size={40} padding={0} />
  </Container>
);

export default LoadingPage;
