import styled from '@emotion/styled';
import { Popover, TableCell } from '@material-ui/core';

export const FoldersPermissionsPopover = styled(Popover)`
  .MuiPopover-paper {
    border-radius: 16px;
    padding: 24px;
    margin-top: 24px;
  }

  .MuiPaper-root {
    color: var(--000000DE-members-row);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: var(--FFFFFF-members-row);
  }
`;

export const NotStyledButton = styled('button')`
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
`;

export const LastActivity = styled('span')`
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  color: var(--222222-members-row);
  letter-spacing: 0.25px;
`;

export const PermissionsTableCell = styled(TableCell)<{ limitedAccess?: boolean; hoverable?: boolean }>`
  padding: ${(props): string => props.limitedAccess
    ? '13px 0 0px 16px'
    : '17px 0 0 16px'};
  line-height: 16px;
  width: 160px;
  vertical-align: top;
  ${(props): string => props.hoverable ? 'cursor: pointer' : ''};
`;
