import React from 'react';
import { Trans } from 'react-i18next';

import { ProfilesCounterWrapper } from './styles';
import { NEW_FEATURES } from '../../../../../state/feature-toggle/new-features';
import TooltipWrapper from '../../../../../ui/tooltip-custom/tooltip-wrapper';

type ProxyProfilesCounterProps = {
  profilesCounter: number;
  isProxyEditable: boolean;
}

const ProxyProfilesCounter: React.FC<ProxyProfilesCounterProps> = (props) => {
  const { profilesCounter, isProxyEditable } = props;

  const isProxyManagerWithGroups = !!NEW_FEATURES.proxyGroups;
  if (isProxyManagerWithGroups) {
    return (
      <ProfilesCounterWrapper newStyle={isProxyManagerWithGroups}>
        <span>
          {isProxyEditable ? profilesCounter : ''}
        </span>
      </ProfilesCounterWrapper>
    );
  }

  if (!(profilesCounter && isProxyEditable)) {
    return <ProfilesCounterWrapper newStyle={isProxyManagerWithGroups} />;
  }

  const tooltipValue = <Trans i18nKey='proxies.profilesCounterTooltip' values={{ count: profilesCounter }} />;

  return (
    <ProfilesCounterWrapper newStyle={isProxyManagerWithGroups}>
      <TooltipWrapper isTransition={true} value={tooltipValue}>
        <span>
          {profilesCounter}
        </span>
      </TooltipWrapper>
    </ProfilesCounterWrapper>
  );
};

export default ProxyProfilesCounter;
