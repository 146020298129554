import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

interface NotificationIconProps extends IIconWrapper {
  isClicked?: boolean;
}

export const NotificationIcon: React.FC<NotificationIconProps> = ({ isClicked = false, ...props }) => (
  <IconWrapper {...props}>
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10 13.5C10 14.0304 9.78929 14.5391 9.41421 14.9142C9.03914 15.2893 8.53043 15.5 8 15.5C7.46957 15.5 6.96086 15.2893 6.58579 14.9142C6.21071 14.5391 6 14.0304 6 13.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M15.5 11.5H7.5H4.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M0.5 11.5H4.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M1.5 11.5C2.03043 11.5 2.53914 11.2893 2.91421 10.9142C3.28929 10.5391 3.5 10.0304 3.5 9.5L3.5 5C3.5 3.80653 3.97411 2.66193 4.81802 1.81802C5.66193 0.974106 6.80653 0.5 8 0.5C8.96545 0.499788 9.90537 0.810081 10.6809 1.38505C11.4565 1.96002 12.1795 3.27299 12.307 3.693C12.4345 4.11301 12.5 4.5 12.5 5C12.5 5.58579 12.5 6.5 12.5 6.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M14.5 11.5H15C13.895 11.5 12.5 10.605 12.5 9.5V6.5' strokeLinecap='round' strokeLinejoin='round' />
      {isClicked && (
        <path d='M0.5 15.5L15.5 0.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      )}
    </svg>
  </IconWrapper>
);
