import { IRedirectToDesktopProps } from './interfaces/redirect-to-desktop-props.interfaces';
import { TWO_FA_TOKEN_KEY_NAME } from '../../../../common/constants/constants';
import { redirectToElectron } from '../../common/components/redirect-to-desktop-page/utils';
import { getAutoLoginToken } from '../../pricing/api';
import { sendReactErrorToSentry } from '../../../utils/sentry.helper';
import { OAuthMetadata } from './interfaces/request-data.interface';

export const redirectToDesktop = async ({
  accessToken,
  twoFaToken = localStorage.getItem(TWO_FA_TOKEN_KEY_NAME) || '',
  isNewUser,
}: IRedirectToDesktopProps): Promise<void> => {
  const params = new URLSearchParams();
  if (isNewUser) {
    params.append('isNewUser', 'true');
  }

  if (accessToken) {
    params.append('accessToken', accessToken);
    if (twoFaToken) {
      params.append('twoFaToken', twoFaToken);
    }
  } else {
    const { auto_login_token: autoLoginToken } = await getAutoLoginToken();
    params.append('auto-login-token', autoLoginToken);
  }

  redirectToElectron('o-auth', params);
};

export const parseGoogleAuthMetadata = (metadataFromQuery: string): OAuthMetadata => {
  try {
    const decodedMetadata = decodeURIComponent(metadataFromQuery);
    const metadata = JSON.parse(decodedMetadata);

    return metadata;
  } catch (error) {
    sendReactErrorToSentry({
      message: 'wrong-parse-metadata',
      transactionName: 'parse-google-auth-metadata',
      extra: {
        metadataFromQuery,
      },
    });

    return {};
  }
};
