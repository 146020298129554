export interface ISentrySpanData {
  started?: number;
}

export const APP_START_TRANSACTION_SPANS = [
  'electron-load',
  'mount-html',
  'prepare-dom-load',
  'prepare-app-load',
  'from-init-app-to-first-effect',
  'from-first-effect-to-setup-i18n',
  'from-setup-i18n-to-end-loading',
  'from-end-loading-to-load-profiles-table',
  'from-setup-i18n-to-load-sign-up-page',
  'finished-root-route-handler',
] as const;

export type AppStartTransactionSpan = typeof APP_START_TRANSACTION_SPANS[number];
export type IAppStartTransaction = Record<AppStartTransactionSpan, ISentrySpanData>;

