import { E_ANALYTICS_ACTIONS } from '../../../common/constants/analytics';
import { API_BASE_URL } from '../../../common/constants/constants';
import { getEnTranslationForAnalytics } from '../../../common/utils';
import { sendActionAnalytics } from '../../features/common/api';
import { http } from '../../services';
import { Hint, HintForAnalytics } from './types';
import i18n from 'i18next';

export const submitHintsUpdate = (hints: Hint[]): Promise<void> => {
  const requestBody = JSON.stringify({ hints });

  return http(`${API_BASE_URL}/user/hints`, {
    method: 'PATCH',
    body: requestBody,
  });
};

const getHintDataForAnalytics = (hint: Hint): HintForAnalytics => ({
  articleId: hint.articleId,
  titleText: getEnTranslationForAnalytics(hint.titleTranslationKey) || i18n.t(hint.titleTranslationKey),
  subtitleText: getEnTranslationForAnalytics(hint.subtitleTranslationKey) || i18n.t(hint.subtitleTranslationKey),
});

export const sendHintsAnalytics = (hint: Hint, actionType: 'clicked'|'closed'): void => {
  const action = actionType === 'clicked' ? E_ANALYTICS_ACTIONS.clickedHint : E_ANALYTICS_ACTIONS.closedHint;
  const dataForAnalytics = getHintDataForAnalytics(hint);
  const actionInfo = `${dataForAnalytics.titleText} - ${dataForAnalytics.subtitleText}`;

  sendActionAnalytics(action, { actionInfo, actionId: dataForAnalytics.articleId });
};
