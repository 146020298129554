import { API_BASE_URL } from "../../../../common/constants/constants";
import { http } from "../../../services";
import { HintPreferences } from "../../../state/user.context";

export const submitHintPreferencesUpdate = (hintPreferences: Partial<HintPreferences>): Promise<void> => {
  const requestBody = JSON.stringify(hintPreferences);

  return http(`${API_BASE_URL}/gologin-settings/hint-preferences`, {
    method: 'PUT',
    body: requestBody,
  });
};
