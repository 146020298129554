import { atom, getDefaultStore, useAtomValue } from 'jotai';

interface IOverlayMask {
  isVisible: boolean;
  onClose: () => void;
}

const DEFAULT_OVERLAY_MASK: IOverlayMask = {
  isVisible: false,
  onClose: () => {},
};

const overlayMaskAtom = atom<IOverlayMask>(DEFAULT_OVERLAY_MASK);

export const useOverlayMask = (): IOverlayMask => useAtomValue(overlayMaskAtom);

export const showOverlayMask = (onClose: IOverlayMask['onClose']): void => getDefaultStore().set(overlayMaskAtom, { isVisible: true, onClose });

export const closeOverlayMask = (): void => {
  getDefaultStore().get(overlayMaskAtom).onClose();
  getDefaultStore().set(overlayMaskAtom, DEFAULT_OVERLAY_MASK);
};
