import styled from '@emotion/styled';

import { textFont } from '../../../../../ui/style-templates';

const GEOPROXY_EDIT_FORM_FOOTER_HEIGHT = 52;

export const ToggleableProxyEditFormFooterWrapper = styled.footer`
  height: ${GEOPROXY_EDIT_FORM_FOOTER_HEIGHT}px;
  padding: 7px 16px 8px;
  border-top: 1px solid var(--EBEBEB-proxy-manager-update-form);

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ToggleableProxyEditFormFooterButton = styled.button<{ alignment: React.CSSProperties['justifyContent'] }>`
  width: 92px;
  height: 20px;
  padding: 0;
  border: none;

  display: flex;
  justify-content: ${(props): React.CSSProperties['justifyContent'] => props.alignment};
  align-items: center;

  ${textFont()}

  /* TODO: add colour var in the upcoming MR */
  color: var(--767676);
  background: inherit;
  cursor: pointer;

  :hover {
    color: #36363d;
  }
`;

export const ProxyEditFormFooterTabsWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  padding: 2px;
  border-radius: 18px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  /* TODO: add colour var in the upcoming MR */
  background: var(--F9F9F9-proxy-manager-footer-icon-wrapper-background);
  position: relative;
`;

export const MovingBackground = styled.div<{ activeIndex: number }>`
  position: absolute;
  height: calc(100% - 4px);
  width: calc(50% - 2px);
  background: var(--F9F9F9-proxy-manager-footer-active-tab-background);
  border-radius: 16px;
  transition: transform 0.3s ease;
  box-shadow: 0px 1px 2px 0px #0000001F;
  transform: translateX(${props => props.activeIndex * 100}%);
`;
