import { ILineMetrics } from '../interfaces';

const getLineMetrics = (context: CanvasRenderingContext2D, line: string): ILineMetrics => {
  const { actualBoundingBoxAscent, actualBoundingBoxDescent } = context.measureText(line);

  return { actualBoundingBoxAscent, actualBoundingBoxDescent };
};

export const getLinesMetrics = (context: CanvasRenderingContext2D, lines: [string, string?]): [ILineMetrics, ILineMetrics] => {
  const [lineFirst, lineSecond] = lines;
  const lineFirstMetrics = getLineMetrics(context, lineFirst);
  if (!lineSecond) {
    return [lineFirstMetrics, { actualBoundingBoxAscent: 0, actualBoundingBoxDescent: 0 }];
  }

  const lineSecondMetrics = getLineMetrics(context, lineSecond);

  return [lineFirstMetrics, lineSecondMetrics];
};
