import styled from '@emotion/styled';

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  font: 400 14px/20px Roboto;
  letter-spacing: 0.25px;
  text-align: center;
  vertical-align: bottom;
`;

export const IconWrapper = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Icon = styled.img`
  width: 16px;
  height: 16px;
`;

export const WorkflowName = styled.div`
  margin-left: 4px;
`;
