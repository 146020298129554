import { getBrowserBuildLatest } from './get-browser-build-latest';
import { BROWSER_VERSION_FOR_GREEN_SQUARE } from '../constants';
import { BrowserBuild, IconDesignVersion, OsForBrowserIcons } from '../types';

export const getIconDesignVersionForMac = (browserVersionMajor: number, browserVersionFull?: BrowserBuild): IconDesignVersion => {
  let designVersion: IconDesignVersion = null;
  const buildLatest = browserVersionFull ?
    getBrowserBuildLatest(browserVersionFull, BROWSER_VERSION_FOR_GREEN_SQUARE.mac.full) : BROWSER_VERSION_FOR_GREEN_SQUARE.mac.full;

  if (
    browserVersionMajor > BROWSER_VERSION_FOR_GREEN_SQUARE.mac.major ||
    (browserVersionMajor === BROWSER_VERSION_FOR_GREEN_SQUARE.mac.major && browserVersionFull === buildLatest)
  ) {
    designVersion = '2:green-square';
  }

  return designVersion;
};

export const getIconDesignVersionForWin10 = (browserVersionMajor: number, browserVersionFull?: BrowserBuild): IconDesignVersion => {
  let designVersion: IconDesignVersion = null;
  const buildLatest = browserVersionFull ?
    getBrowserBuildLatest(browserVersionFull, BROWSER_VERSION_FOR_GREEN_SQUARE.win10.full) : BROWSER_VERSION_FOR_GREEN_SQUARE.mac.full;

  if (
    browserVersionMajor > BROWSER_VERSION_FOR_GREEN_SQUARE.win10.major ||
    (browserVersionMajor === BROWSER_VERSION_FOR_GREEN_SQUARE.win10.major && browserVersionFull === buildLatest)
  ) {
    designVersion = '2:green-square';
  }

  return designVersion;
};

export const getIconDesignVersionForWin11 = (browserVersionMajor: number, browserVersionFull?: BrowserBuild): IconDesignVersion => {
  let designVersion: IconDesignVersion = null;
  const buildLatest = browserVersionFull ?
    getBrowserBuildLatest(browserVersionFull, BROWSER_VERSION_FOR_GREEN_SQUARE.win11.full) : BROWSER_VERSION_FOR_GREEN_SQUARE.mac.full;

  if (
    browserVersionMajor > BROWSER_VERSION_FOR_GREEN_SQUARE.win11.major ||
    (browserVersionMajor === BROWSER_VERSION_FOR_GREEN_SQUARE.win11.major && browserVersionFull === buildLatest)
  ) {
    designVersion = '2:green-square';
  }

  return designVersion;
};

export const getIconDesignVersion = (
  os: OsForBrowserIcons|null,
  browserVersionMajor: number,
  browsersVersionsFull: string,
): IconDesignVersion => {
  // `browsersVersionsFull` is a string-like list of versions from localStorage
  // localStorage returns type `string|undefined`
  // but we expect only `BrowserBuild`
  // that's why --> `as (BrowserBuild|undefined)[]`
  const [browserVersionFull] = browsersVersionsFull.split(',')
    .filter((versionFull) => versionFull.startsWith(`${browserVersionMajor}`)) as (BrowserBuild|undefined)[];

  let designVersion: IconDesignVersion = null;
  switch (os) {
    case 'mac':
      designVersion = getIconDesignVersionForMac(browserVersionMajor, browserVersionFull);
      break;
    case 'win10':
      designVersion = getIconDesignVersionForWin10(browserVersionMajor, browserVersionFull);
      break;
    case 'win11':
      designVersion = getIconDesignVersionForWin11(browserVersionMajor, browserVersionFull);
      break;
    default:
      break;
  }

  return designVersion;
};
