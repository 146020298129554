import styled from '@emotion/styled';

export const EmptyListEl = styled('section')`
  background: var(--FFFFFF-proxy-manager-empty-list);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
`;

export const EmptyListTitleEl = styled('div')`
  text-align: center;
  width: 100%;
  color: var(--CDCDCD-proxy-manager-empty-list);
  font-size: 24px;
  line-height: 28px;
  letter-spacing: initial;
  font-weight: 400;
  margin-top: 91px;
`;

export const EmptyListButtonsEl = styled('div')`
  text-align: center;
  width: 100%;
  margin-top: 33px;
`;

export const BtnIcon = styled('i')`
  text-align: center;
  color: var(--CDCDCD-proxy-manager-empty-list);
  vertical-align: middle;
  display: inline-block;
  height: 20px;
`;

export const BtnText = styled('span')`
  margin-left: 9px;
  display: inline-block;
  font-size: 14px;
`;

export const BtnAdd = styled('button')`
  color: var(--222222-proxy-manager-empty-list);
  outline: none;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  letter-spacing: 0.25px;
  line-height: 20px;
  padding: 5px 11px 5px 13px;
  border: 1px solid var(--CDCDCD-proxy-manager-empty-list);
  border-radius: 16px;

  &:hover {
    color: var(--00A987-proxy-manager);
    border: 1px solid var(--00A987-proxy-manager);

    i {
      color: var(--00A987-proxy-manager);
    }
  }
`;
