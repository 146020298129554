import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled';
import Popper from '@material-ui/core/Popper';

import { textFont } from '../style-templates';

export const TooltipWrapper = styled(Popper)<{ hasTooltipAnimation?: boolean }>`
  ${({ hasTooltipAnimation }): SerializedStyles|null => hasTooltipAnimation ? css`
    opacity: 0;
    animation: opacityChange 0.2s forwards;
    animation-delay: 0.5s;

    @keyframes opacityChange {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  ` : null}
`;

export const TooltipContainer = styled('div')`
  padding: 8px 16px;
  outline: var(--gologin-tooltip-outline);
  border-radius: 4px;
  margin-top: 8px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  background: var(--gologin-tooltip-background);  
  box-shadow: var(--shadow-tooltip);
`;

export const TooltipText = styled('div')`
  display: flex;
  align-items: center;

  ${textFont};
  font-size: 12px;
  letter-spacing: 0.2px;
  line-height: 16px;

  color: var(--gologin-tooltip-text-color);
`;

export const TooltipBox = styled('div')<{ isTextEllipsis?: boolean }>`
  ${({ isTextEllipsis }): SerializedStyles|null => isTextEllipsis ? css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  ` : null}
`;
