import styled from '@emotion/styled';
import { Button } from 'antd';

const DEFAULT_BORDER_COLOR = 'var(--D9D9D9)';

export const TextArea = styled('textarea')`
  width: 100%;
  height: 100px;
  resize: none;
  padding: 5px 10px;
  border: 1px solid ${DEFAULT_BORDER_COLOR};
  border-radius: 3px;
  &:focus {
    outline: none !important;
  }
  &::placeholder {
    opacity: 0.5;
    font-color: ${DEFAULT_BORDER_COLOR};
  }
`;

export const SaveButton = styled(Button)`
  display: block;
  padding: 6px 16px;

  font: normal 14px 'Roboto';
  color: var(--FFFFFF);
  background: var(--00B86D);
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;

  border-radius: 4px;
  cursor: pointer;
`;

export const CancelButton = styled(Button)`
  display: block;
  padding: 6px 16px;

  font: normal 14px 'Roboto';
  color: var(--767676);
  border-color: var(--CDCDCD);
  background: inherit;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;

  border-radius: 4px;
  cursor: pointer;
  user-select: none;

  :focus {
    color: var(--767676);
    border-color: var(--CDCDCD);
    background-color: inherit;
  }
`;
