import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorTooltip, ResyncButton, TooltipTextError } from './styles';
import { IProfileRunStatus } from '../../../state/profiles-list.atom';
import IconResyncProfile from '../../../ui/icons/IconResyncProfile';

export interface IStatusMessage {
  profileId: string;
  statusMessage: IProfileRunStatus['statusMessage'];
  syncProfile: (profileId: string, onlyUpload?: boolean) => void;
}

const StatusMessage: FC<IStatusMessage> = (props) => {
  const { profileId, statusMessage, syncProfile } = props;

  const { t: translation } = useTranslation();

  if (!statusMessage) {
    return null;
  }

  if (typeof statusMessage === 'string') {
    return (
      <>
        {translation(statusMessage)}
      </>
    );
  }

  const { errorType, errorMessage, prefix } = statusMessage;
  const somethingWentWrongKey = 'notifications.error.somethingWentWrong';
  const translatedErrorType = translation(errorType || somethingWentWrongKey);
  switch (prefix) {
    case 'proxy':
      return (
        <div style={{ display: 'flex' }}>
          {translatedErrorType}
          .&nbsp;
          {translation(errorMessage)}
        </div>
      );
    case 'timezone':
      return (
        <div style={{ display: 'flex' }}>
          {translatedErrorType}
        </div>
      );
    case 'resync':
      return (
        <ErrorTooltip>
          <TooltipTextError>
            {translation(errorMessage || somethingWentWrongKey)}
          </TooltipTextError>
          <ResyncButton onClick={(): void => syncProfile(profileId, errorType.includes('uploading'))}>
            <IconResyncProfile />
          </ResyncButton>
        </ErrorTooltip>
      );
    default:
      return (
        <>
          {translation(somethingWentWrongKey)}
        </>
      );
  }
};

export default StatusMessage;
