import styled from '@emotion/styled';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import ShowingStep from './step-item';
import { BROWSER_UPDATER_DEFAULT_VALUE, browserUpdaterContext } from '../../../../../state';
import { IconPreload, IconCheck, IconClock } from '../../../icons';

let progressBarTimeout: any;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Steps = ({ hasOneStep }: { hasOneStep: boolean }): JSX.Element | null => {
  const {
    currentStep = 0,
    downloadProgressStr = '(0/0)',
    downloadProgressStatus,
    browserUpdating,
    updateBrowserUpdater,
  } = useContext(browserUpdaterContext);

  const { t: translation } = useTranslation();

  const [downloadProgressPercent, downloadProgressSize] = downloadProgressStr.split('%');

  const statusIcon = (orderStep: number): JSX.Element => {

    if (currentStep > orderStep || downloadProgressStatus === 'finish') {
      return <IconCheck iconColor='var(--00A987)' padding={0} margin={'0 8px 0 0'} />;
    }

    if (currentStep === orderStep) {
      return <IconPreload padding={0} margin={'0 8px 0 0'} />;
    }

    return <IconClock padding={0} margin={'0 8px 0 0'} />;
  };

  const stepsDescription = [
    {
      order: 0,
      text: translation('downloadOrbita.stepOne') + ' ' + downloadProgressPercent + '%',
    },
    {
      order: 1,
      text: translation('downloadOrbita.extracting'),
    },
    {
      order: 2,
      text: translation('downloadOrbita.stepThree'),
    },
    {
      order: 3,
      text: translation('downloadOrbita.done'),
    },
  ];

  if (!browserUpdating) {
    if (!['finish', 'error'].includes(downloadProgressStatus || 'progress')) {
      return null;
    }

    if (progressBarTimeout) {
      clearTimeout(progressBarTimeout);
    }

    progressBarTimeout = setTimeout(() => {
      updateBrowserUpdater({
        ...BROWSER_UPDATER_DEFAULT_VALUE,
        initialized: true,
        updateBrowserUpdater,
      });
    }, 7000);
  }

  if (hasOneStep) {
    return (
      <Container style={{ marginLeft: stepsDescription[currentStep].order === 1 ? 0 : 32 }}>
        {statusIcon(stepsDescription[currentStep].order)}
        <ShowingStep
          text={stepsDescription[currentStep].text}
          isFirstStep={stepsDescription[currentStep].order === 0}
          isCurrent={stepsDescription[currentStep].order === currentStep}
          downloadProgressSize={downloadProgressSize}
        />
      </Container>
    );
  }

  return (
    <>
      {stepsDescription.map(step => (
        <Container key={step.order} style={{ marginLeft: step.order === 1 ? 0 : 32 }}>
          {statusIcon(step.order)}
          <ShowingStep
            text={step.text}
            isFirstStep={step.order === 0}
            isCurrent={step.order === currentStep}
            downloadProgressSize={downloadProgressSize}
          />
        </Container>
      ))}
    </>
  );
};

export default Steps;
