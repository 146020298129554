import * as Sentry from '@sentry/react';
import { message } from 'antd';

import { CheckProxyParams, ProxyProtocols } from '../../../../../common/types/proxy';
import { ICheckProxyResult } from '../../../../../electron/interfaces/proxy.service.interfaces';
import { checkProxyRequest } from '../../../../utils/check-proxy';
import { isProxyDataValid } from '../../../../utils/proxy.validation-message';

let ipcRenderer: Electron.IpcRenderer;
const isElectron = !!window.require;
if (isElectron) {
  ({ ipcRenderer } = window.require('electron'));
}

export const checkProxy = (options: any, processCbs: any, resCbs: any, translation: any, successCbs?: any) => {
  const { proxy } = options;
  const {
    id,
    mode: type = 'http',
    host = '',
    port = 80,
    username = '',
    password = '',
    status = false,
    checkDate,
  } = proxy;

  const isProxyValid = isProxyDataValid({ host, port });
  if (!isProxyValid) {
    return;
  }

  if (!(host && port)) {
    message.error(translation('notifications.error.invalidAddress'));

    return;
  }

  if (host.match(/^127|localhost/) && !isElectron) {
    message.error(translation('notifications.error.externalHost'));

    return;
  }

  processCbs();

  const params: CheckProxyParams = {
    type: type === 'geolocation' ? 'http' : type,
    host,
    port,
    username,
    password,
    mode: type,
    lastProxyCheckStatus: {
      id,
      status,
      checkDate,
    },
  };

  const sendVpnUfoError = (sendedToChecker: any, gotFromChecker: any): void => {
    if (host !== 'geo.floppydata.com') {
      return;
    }

    const gotFromVpnUfo = JSON.stringify(proxy);
    sendedToChecker = JSON.stringify(sendedToChecker);
    gotFromChecker = JSON.stringify(gotFromChecker);

    Sentry.captureException('vpn-ufo-error', (scope) => {
      scope.setTag('gotFromVpnUfo', gotFromVpnUfo);
      scope.setTag('sendedToChecker', sendedToChecker);
      scope.setTag('gotFromChecker', gotFromChecker);
      scope.setTag('isElectron', isElectron);
      scope.setTransactionName('vpn-ufo-error');
      scope.setFingerprint(['vpn-ufo-error']);

      return scope;
    });
  };

  if (isElectron && ipcRenderer) {
    ipcRenderer.invoke('check-proxy', params)
      .then((checkProxyResult: ICheckProxyResult) => {
        if (checkProxyResult.error) {
          checkProxyResult.error = translation(checkProxyResult.error);
          sendVpnUfoError(params, checkProxyResult);
        }

        options.setProxyChecked(checkProxyResult);

        if (checkProxyResult.status === 'success') {
          successCbs && successCbs(checkProxyResult);

          return;
        }

        message.error(translation('notifications.error.noProxy'));
      })
      .finally(resCbs);
  } else {
    checkProxyRequest(params)
      .then((res: any) => {
        options.setProxyChecked(res);

        if (res.status === 'success' && successCbs) {
          successCbs();

          return;
        }

        sendVpnUfoError(params, res);

      })
      .catch((error: unknown) => {
        console.log(error);
        sendVpnUfoError(params, error);
      })
      .finally(resCbs);
  }
};

export const PROXY_CHECKED_INIT = {
  status: '',
  origin: '',
  country: '',
  city: '',
  timezone: '',
  error: '',
  connectionType: '',
  checkedProtocol: ProxyProtocols.HTTP,
  trafficUsed: 0,
  trafficLimit: 0,
  trafficUsedBytes: 0,
  trafficLimitBytes: 0,
};
