import DOMPurify from 'dompurify';
import React, { FC } from 'react';

const defaultOptions: DOMPurify.Config = {
  ALLOWED_TAGS: ['s', 'u', 'span', 'p', 'em', 'strong'],
  ALLOWED_ATTR: ['style'],
};

interface ISanitizeHTML {
  html: string;
  options?: DOMPurify.Config;
  divProps?: React.HTMLAttributes<HTMLDivElement>;
}

const SanitizeHTML: FC<ISanitizeHTML> = ({ html, options, divProps }) => (
  <div
    {...divProps}
    dangerouslySetInnerHTML={{
      __html: DOMPurify.sanitize(
        html,
        { ...defaultOptions, ...options },
      ) as string,
    }}
  />
);

export default SanitizeHTML;
