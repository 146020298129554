import styled from '@emotion/styled';

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  z-index: 9999;
  position: fixed;
  right: 32px;
  bottom: 24px;
`;

export const ButtonTelergam = styled.button`
  display: flex;
  align-items: center;
  background: var(--28A8EA);
  border-radius: 24px;
  border-width: 0;
  cursor: pointer;
  max-height: 48px;
  height: 48px;
  padding: 6px 8px 6px 20px;
  margin-right: 12px;

  color: var(--FFFFFF-tg-widget-text);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const ButtonWhatsApp = styled.button`
  display: flex;
  cursor: pointer;
  background-color: green;
  max-height: 48px;
`;

export const TelegramWidgetText = styled.p`
  padding-right: 20px;
  margin-top: 14px;
`;
