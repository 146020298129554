import { createContext } from 'react';

import { IUserCtx } from './user.context';
import { IWorkspace, IWorkspaceMember, IWorkspaceShort } from '../interfaces/workspaces';

// these are technically present in workspace itself,
// but since they are stored as atoms, they are not a part of context's useState
export interface IExtraWorkspaceContextData {
  id: string;
  allProfilesFolderId: string | null;
}

export interface IWorkspaceContextData extends Omit<IWorkspace, keyof IExtraWorkspaceContextData> {
  userId: string;
}

export type IUpdateWorkspaceContextData = IWorkspaceContextData & IExtraWorkspaceContextData;

export interface IWorkspaceCtx extends IWorkspaceContextData, IExtraWorkspaceContextData {
  me?: IWorkspaceMember;
  isLoaded: boolean;

  updateWorkspace: (partial: Partial<IWorkspaceContextData>) => void;

  availableWorkspaces: IWorkspaceShort[];
  updateAvailableWorkspaces: (workspace: IWorkspaceShort[], isLogout?: boolean) => void;
  updateAvailableWorkspacesFolders: () => void;
  addNewAvailableWorkspace: (workspace: IWorkspace) => void;
  updateAvailableWorkspace: (workspaceId: string, workspaceData: Partial<IWorkspaceShort>) => void;

  profilesCount: number;

  isFirstPlanSelected: (userCtx: IUserCtx) => boolean;
  setProfilesCount: (profilesCount: number) => void;
}

export const WORKSPACE_DEFAULT_VALUE: IWorkspaceCtx = {
  id: '',
  name: '',
  owner: '',
  folders: [],
  members: [],
  permissions: {} as any,
  userId: '',
  isLoaded: false,
  updateWorkspace: () => {},
  availableWorkspaces: [],
  updateAvailableWorkspaces: () => {},
  addNewAvailableWorkspace: () => {},
  updateAvailableWorkspacesFolders: () => {},
  planId: '',
  planMembersMax: 0,
  planName: '',
  activeSharesCount: 0,
  planExpiresAt: new Date(0),
  paymentMethod: '',
  paymentDiscount: '',
  planProfilesMax: 0,
  profilesCount: 0,
  isPlanUnlimited: false,
  isFirstPlanSelected: () => false,
  setProfilesCount: () => {},
};

export const workspaceContext = createContext<IWorkspaceCtx>(WORKSPACE_DEFAULT_VALUE);
