import styled from '@emotion/styled';
import { Button, Input } from 'antd';
import { Link } from 'react-router-dom';

import { textFont } from '../style-templates';

export const ContainerSearchInput = styled('div')`
  padding: 0 10px 0 44px;
  @media screen and (max-width: 500px) {
    display: none;
  }
`;

export const InputSearch = styled(Input)`
  width: 192px;
  background: var(--FFFFFF);
  border-radius: 4px;
  margin-left: 2px;
`;

export const Row = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ContainerMenu = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  @media screen and (max-width: 500px) {
    margin-left: 25%;
  }
  @media screen and (max-width: 361px) {
    margin-left: 20%;
  }
  @media screen and (max-width: 325px) {
    margin-left: 15%;
  }
  @media screen and (max-width: 300px) {
    margin-left: 10%;
  }
`;

export const PayNowBtn = styled('button')`
  background-color: var(--FFE164);
  border-radius: 3px;
  border: 1px solid var(--D9D9D9);
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 0;
  margin-top: 5px;
  margin-left: 10px;
  outline: none;
  cursor: pointer;
  height: 32px;
  box-shadow: 0 2px 0 var(--00000004);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  :hover {
    background-color: var(--FFE164);
    color: var(--000000);
    border-color: var(--FFE164);
  }
`;

export const LinkMenu = styled(Link)`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  color: var(--FFFFFF);
  & + & {
    margin-left: 24px;
  }
  :hover {
    color: var(--91DAC8);
  }
  a {
    color: var(--FFFFFF);
    :hover {
      color: var(--91DAC8);
    }
  }
  @media screen and (max-width: 500px) {
    white-space: nowrap;
  }
`;

export const LinkMenuMobile = styled(Link)`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  color: var(--FFFFFF);

    margin-left: 24px;
  :hover {
    color: var(--91DAC8);
  }
  a {
    color: var(--FFFFFF);
    :hover {
      color: var(--91DAC8);
    }
  }
  @media screen and (max-width: 500px) {
    display: none;
  }
`;

export const ContainerLogo = styled('div')`
  flex: auto;
  margin-top:12px;
  margin-left: 40px;
  @media screen and (max-width: 500px) {
    margin-left: 1%;
  }
`;

export const ProgressContainer = styled('div')`
  position: static;
  width: 100%;
  height: 30px;
  background-color: var(--FFFFFF);
  margin: 0;
  padding-top: 10px;
`;

export const ProgressRetryingContainer = styled('div')`
  position: static;
  width: 100%;
  background-color: var(--FFF2F2);
  margin: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: var(--535358);
    ${textFont};
  }
`;

export const ProgressContainerContent = styled('div')`
  position: relative;
  width: 85%;
  left: 50%;
  transform: translateX(-50%);
`;

export const Payment = styled('div')`
  width: 100%;
  background: var(--F5F5F5);
  line-height:42px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const PaymentText = styled('div')`
  font-family: Roboto;
  font-size: 14px;
  margin-left: 6px;
  color: var(--000000);
`;

export const ButtonUpdate = styled(Button)`
  min-height: 26px;
  max-height: 26px;
`;

