/* eslint-disable max-lines */
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/react';
import { message } from 'antd';
import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import BodyContainer from './body-container';
import ContainerDashboard from './container-dashboard';
import { IProfilesTableSettings } from './interfaces/app-settings.interface';
import { IUpdateProfilesList } from './interfaces/get-profiles.interface';
import { INewTagActionSubscription } from './interfaces/new-tag-action-subscription.interface';
import { IProfilesTableSummary } from './interfaces/profiles-table-summary.interface';
import {
  addTagToProfiles,
  findProfilesWithTag,
  IAddTagToProfiles,
  removeCustomStatusesFromProfiles,
  removeTagFromProfiles,
  updateTagInProfiles,
} from './manage-profile-tags';
import PageOverlay from './page-overlay';
import ProfilesTableQuick from './profiles-table';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { ProxyPageAnalyticsEvent } from '../../../../common/constants/analytics';
import { PROFILE_SHARED_KEY_NAME } from '../../../../common/constants/constants';
import { PROFILE_SHARE_DEEP_LINK_EVENT_NAME } from '../../../../common/constants/events';
import { LOCAL_STORAGE_SELECTED_FOLDER } from '../../../../common/constants/local-storage';
import { normalizeTags } from '../../../../common/utils';
import { useQuery } from '../../../hooks';
import { IFolder, IProfile } from '../../../interfaces';
import {
  browserUpdaterContext,
  importFilesContext,
  tagsContext,
  userContext,
  workspaceContext,
  useSearchQuery,
  updateSearchQuery,
  getSearchQuery,
} from '../../../state';
import { manageProfileFoldersList } from '../../../state/folders/profiles-folders.operations';
import { getLatestLocalOrbitaVersion, setLatestLocalOrbitaVersion } from '../../../state/latest-local-orbita-version.atom';
import { refreshLocalUserDevice } from '../../../state/local-user-device.atom';
import {
  getProfilesList,
  mapAndSetProfilesList,
  reloadProfilesTableGroups,
  resetProfilesList,
  setProfilesList,
} from '../../../state/profiles-list.atom';
import { refreshFullProfile, updateFullProfilesMap } from '../../../state/profiles-map.atom';
import {
  getProfilesTableProxyIdFilter,
  resetProfilesTableProxyIdFilter,
  useProfilesTableProxyIdFilter,
} from '../../../state/profiles-proxy-filter.atom';
import { closeProfilesSettings, openProfilesSettingsOfSelectedIds } from '../../../state/profiles-settings-atom';
import { loadGroupsFromLocalStorage } from '../../../state/profiles-table/groups-local-storage';
import {
  fetchProfilesByCurrentQuery,
  HandleCheckUaFn,
  HandleDropFileLoadedFn,
  HandleFolderDeletedFn,
  HandleFullProfilesMapFn,
  HandleOrbitaVersionsFn,
  useCurrentProfilesQuery,
  useProfilesQueryLoadingStatus,
} from '../../../state/profiles-table/profiles-query';
import { initProfilesTableSorting } from '../../../state/profiles-table/profiles-sort.atom';
import { callProfilesTableRowMeasurer } from '../../../state/profiles-table-row-measurers.atom';
import {
  getProfilesTableSelectedIds,
  updateProfilesTableSelectedIds,
} from '../../../state/profiles-table-selected-ids.atom';
import { closeProxyManager } from '../../../state/proxy/proxy-manager-modal-status.atom';
import { checkProfilesProxies } from '../../../state/proxy/proxy-operations/check-proxies.operations';
import { getSelectedFolderId, setSelectedFolderId } from '../../../state/selected-folder.atom';
import { setSelectedTagId } from '../../../state/tags/selected-tag.atom';
import { addNewTagToTagsList, getTagsList } from '../../../state/tags/tags-list.atom';
import { gologinTableCheckboxStyles } from '../../../ui/gologin-checkbox/styles';
import { IconSpinner } from '../../../ui/gologin-header/icons';
import Header from '../../../ui/Header';
import { getFolderSelectedOrAll } from '../../../utils/get-folder-selected-or-all';
import { insertProfileToListByIndex } from '../../../utils/insert-profile-to-list-by-index';
import { sendActionAnalytics } from '../../common/api';
import { getProfileFromStorage, handleShareViaLinkFromEvent } from '../../common/deep-links';
import { manageProfilesInFolder, manageProfilesInWorkspaceFolder } from '../../folders/api';
import FoldersManager from '../../modalsComponents/components/folders-manager';
import MultiShareModal from '../../modalsComponents/components/multi-share-modal';
import RemoveProfileModal from '../../modalsComponents/components/remove-profile-modal';
import ProxyManager from '../../proxy/proxy-manager';
import { addTagRequest, deleteProfilesTag, deleteTag, getAllTags, updateTagRequest } from '../../tags/api';
import TitleTag from '../../tags/components/title-tag';
import { IAddTag, IAddTagMethod, ITag, ITagBase } from '../../tags/interfaces/tag.interface';
import UpdateProfilePage from '../../updateProfile/components/update-profile-page';
import { getProfilesTableSummaryInfo, getWorkspaceProfileTableSettings } from '../api';
import DraggingProfilesOverlay from '../dragging-profiles-overlay';
import QuickPricing from '../quick-pricing';
import { getCurrentWorkspaceId } from '../../../state/current-workspace-id.atom';
import { closeTagManager } from '../../../state/tags/tag-manager-state.atom';
import useGlobalAntStyles from '../../../hooks/use-global-ant-styles';
import LoadingPage from '../../common/loading-page';
import { getProfilesTableGroupField } from '../../../state/profiles-table/profiles-table-group-field.atom';

export const ALL_PROFILES_FOLDER = 'all';

export const ContainerContent = styled('div')<{ newStyle: boolean }>`
  ${props => props.newStyle ? css `
    display: flex;
    flex-direction: column;
    flex: 1;
  ` : css `
    padding: 30px 86px;

    @media screen and (max-width: 500px) {
      padding: 5% 1%;
    }
    @media screen and (max-width: 325px) {
      padding: 5% 0;
    }
  `}

  /* TODO: remove when proxy-table is merged with gologin-table */
  .proxy-table {
    ${gologinTableCheckboxStyles}
  }
`;

const ContainerPreTable = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const ContainerTableName = styled('div')`
@media screen and (max-width: 420px) {
  width: 50%;
}
`;

export const SpinnerContainer = styled('div')`
  font-size: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 150px;
`;

const TextTableName = styled('p')`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: var(--000000-profile-table);
  @media screen and (max-width: 500px) {
    padding-left: 7%;
  }
  @media screen and (max-width: 325px) {
    padding-left: 4%;
  }
`;

let ipcRenderer: Electron.IpcRenderer;
const isElectron = !!window.require;

if (isElectron) {
  ({ ipcRenderer } = window.require('electron'));
}

const ProfilesListPage: FC = () => {
  const userCtx = useContext(userContext);
  const {
    _id,
    selectedFolder: selectedFolderFromCtx,
    defaultWorkspace = '',
    updateSelectedFolder,
    folders: foldersListFromCtx,
    firstPlanSelected,
  } = userCtx;

  const [profilesUAChecking, setProfilesUAChecking] = useState<boolean>(false);
  const [currentOrbitaMajorV, setCurrentOrbitaMajorV] = useState<string>('');
  const [currentBrowserV, setCurrentBrowserV] = useState<string>('');
  const [profilesTableSettings, setProfilesTableSettings] = useState<IProfilesTableSettings|null>(null);
  const [tableSettingsLoaded, setTableSettingsLoaded] = useState<boolean>(false);
  const [foldersList, setFoldersList] = useState<IFolder[]>([]);
  const [foldersSelected, setFoldersSelected] = useState<string[]>([]);
  const [isFoldersManagerVisible, setIsFoldersManagerVisible] = useState<boolean>(false);
  const [profilesToGetFolders, setProfilesToGetFolders] = useState<string[]>(['']);
  const [initStatus, setInitStatus] = useState<'init-block'|'block-by-tags'|'block-by-folders'|'allowed'>('init-block');

  const newTagActionSubscription = useRef<INewTagActionSubscription|null>(null);
  const prevTag = useRef<string|null>(null);

  const profilesTableProxyIdFilter = useProfilesTableProxyIdFilter();
  const currentProfilesQuery = useCurrentProfilesQuery();
  const profilesQueryLoadingStatus = useProfilesQueryLoadingStatus();
  const isIniting = profilesQueryLoadingStatus === 'initing';

  const {
    selectedTag,
    tags,
    setTags,
    newAddedTag,
    setNewAddedTag,
  } = React.useContext(tagsContext);

  const { versionLoadingNow, browserUpdating, updateBrowserUpdater, isOrbitaVersionCompatible } = useContext(browserUpdaterContext);
  const {
    dropFileStep,
    setDropFileStep,
    setNeedParseName,
    errorImportMessage,
    setErrorImportMessage,
  } = useContext(importFilesContext);

  const workspaceCtx = useContext(workspaceContext);
  const {
    id: workspaceId,
    isLoaded: workspaceIsLoaded,
    allProfilesFolderId,
  } = workspaceCtx;

  const query = useQuery();
  const routeUpdateObj = useRouteMatch('/update/:profileId');
  const isUpdatePage = routeUpdateObj?.isExact;
  const searchQuery = useSearchQuery();
  const { t: translation } = useTranslation();

  const { areAntStylesLoading } = useGlobalAntStyles(true, isUpdatePage);

  const setProfilesFnWithRef = useCallback((set: (prevProfiles: IProfile[]) => IProfile[]): void => {
    mapAndSetProfilesList(prevProfiles => {
      const newProfiles = set(prevProfiles);
      updateFullProfilesMap(newProfiles);

      return newProfiles;
    });
  }, [setProfilesList, updateFullProfilesMap]);

  useEffect(() => {
    if (profilesQueryLoadingStatus !== 'loaded') {
      return;
    }

    ipcRenderer && ipcRenderer.invoke('download-all-browser-fonts').catch(() => null);
  }, [profilesQueryLoadingStatus]);

  useEffect(() => {
    if (dropFileStep === 'loading') {
      callFetchProfilesByQuery();

      return;
    }

    if (dropFileStep === 'loaded') {
      const selectedProfileIds = getProfilesTableSelectedIds();
      const profiles = getProfilesList();

      const newProfiles = profiles.filter(profile => selectedProfileIds.includes(profile.id));
      const profileIds = newProfiles.map(profile => profile.id);

      checkProfilesProxies(newProfiles);
      updateProfilesTableSelectedIds(profileIds);
      openProfilesSettingsOfSelectedIds();
      setDropFileStep(null);

      if (errorImportMessage) {
        setErrorImportMessage('');
        setTimeout(() => message.error(errorImportMessage), 4000);
      }
    }
  }, [dropFileStep, getProfilesList]);

  useEffect(() => {

    refreshLocalUserDevice();
    const updateExtensionsOnClose = (_: unknown, res: { profileId: string; extensionsIds: string[] }): any => {
      mapAndSetProfilesList(profiles => profiles.map(profile => {
        if (profile.id === res.profileId) {
          profile.chromeExtensions = res.extensionsIds;
        }

        return profile;
      }));
    };

    ipcRenderer?.on('update-extensions-on-close', updateExtensionsOnClose);

    return () => {
      ipcRenderer?.removeListener('update-extensions-on-close', updateExtensionsOnClose);
      // This fixes proxy manager popover appearing in the top-left corner of a page in some cases
      closeProxyManager();
    };
  }, []);

  useEffect(() => {
    if (profilesTableProxyIdFilter) {
      sendActionAnalytics(ProxyPageAnalyticsEvent.visitedProfilesPageFilteredByProxy);
    }

    if ((NEW_FEATURES.workspaces && !workspaceIsLoaded) || getSearchQuery() === null) {
      return;
    }

    if (NEW_FEATURES.workspaces) {
      loadWorkspaceProfileTableSettings();
    }

    initPage();
  }, [
    profilesTableProxyIdFilter,
    workspaceIsLoaded,
    workspaceId,
  ]);

  const onFolderChange = (newFolderName: string): void => {
    localStorage.setItem('SelectedFolder', newFolderName);
    resetProfilesList();
    callFetchProfilesByQuery();
  };

  const loadWorkspaceProfileTableSettings = async (): Promise<void> => {
    if (!(profilesTableSettings && profilesTableSettings.workspace === workspaceId)) {
      const tableSettings = await getWorkspaceProfileTableSettings(workspaceId);
      tableSettings.workspace = workspaceId;
      if (tableSettings) {
        setProfilesTableSettings(tableSettings);
      }
    }

    setTableSettingsLoaded(true);
  };

  useEffect(() => {
    if (NEW_FEATURES.workspaces) {
      return;
    }

    const appVersion: string = window.gologinAppVersion || '';
    const fetchProfilesTableSummaryInfo = async (): Promise<void> => {
      const {
        profileTableSettings,
        tags: requestedTags,
        folders,
      }: IProfilesTableSummary = await getProfilesTableSummaryInfo(appVersion).catch(() => ({}) as IProfilesTableSummary);

      const rawCachedFolders = localStorage.getItem('folders-order');
      const cachedFolders: any[] = rawCachedFolders ? JSON.parse(rawCachedFolders) : [];

      const newOrderedFolders = processReceivedFolders(folders, cachedFolders);

      localStorage.setItem('folders-order', JSON.stringify(newOrderedFolders));
      setFoldersList(newOrderedFolders);

      if (profileTableSettings) {
        setProfilesTableSettings(profileTableSettings);
      }

      if (requestedTags?.success) {
        const normalizedTags = normalizeTags(requestedTags.tags);
        setTags(normalizedTags);
      }

      setTableSettingsLoaded(true);
    };

    if (!_id) {
      return;
    }

    fetchProfilesTableSummaryInfo();

    return clearColumnFilters;
  }, [_id]);

  useEffect(() => {
    const profiles = getProfilesList();
    const [profileToGetFolders] = profilesToGetFolders;
    const profileFoldersSelected = profiles.find(profile => profile.id === profileToGetFolders)?.folders || [];
    setFoldersSelected(profileFoldersSelected);
  }, [profilesToGetFolders, getProfilesList]);

  useEffect(() => {
    if (!ipcRenderer) {
      return;
    }

    const profileSharedViaLinkJson = sessionStorage.getItem(PROFILE_SHARED_KEY_NAME) || '';
    if (profileSharedViaLinkJson) {
      const profileSharedViaLink = getProfileFromStorage(profileSharedViaLinkJson);
      if (profileSharedViaLink) {
        addProfileFirstToList(profileSharedViaLink);
      }

      return;
    }

    ipcRenderer.on(PROFILE_SHARE_DEEP_LINK_EVENT_NAME, async (_event, args) => {
      const {
        message: errorMessage = '',
        profile = null,
        isUpdatePage: isUpdatePageRoute = false,
      }: { message: string; profile: IProfile|null; isUpdatePage?: boolean } = args;

      if (errorMessage) {
        return message.error(translation(errorMessage));
      }

      await handleShareViaLinkFromEvent({
        profile,
        defaultWorkspace,
        addProfileFirstToList,
        updateSelectedFolder,
        userCtx,
        workspaceCtx,
        isUpdatePageRoute,
      });
    });

    return () => {
      ipcRenderer.removeAllListeners(PROFILE_SHARE_DEEP_LINK_EVENT_NAME);
    };
  }, [isUpdatePage]);

  // temporary hack to update selected folder in the atom
  // as well as unlock profiles if not grouped by custom-status
  // but it should be done from the places where folder changes and not here
  useEffect(() => {
    if (!NEW_FEATURES.dragAndDrop) {
      setInitStatus('allowed');

      return;
    }

    let folderId = allProfilesFolderId;
    const selectedFolderName = selectedFolderFromCtx;
    if (selectedFolderName && selectedFolderName !== ALL_PROFILES_FOLDER) {
      const selectedFolder = foldersListFromCtx.find(folder => folder.name === selectedFolderName);

      folderId = selectedFolder?.id;
    }

    setSelectedFolderId(folderId || null);
    const { field: localStorageGroupsField = null } = loadGroupsFromLocalStorage() || {};
    if (localStorageGroupsField === 'custom-status') {
      setInitStatus('block-by-tags');
    } else if (localStorageGroupsField === 'folder') {
      setInitStatus('block-by-folders');
    } else {
      setInitStatus('allowed');
    }
  }, [selectedFolderFromCtx, foldersListFromCtx]);

  const clearColumnFilters = (): void => {
    setSelectedTagId(null);
    resetProfilesTableProxyIdFilter();
  };

  const updateOnSearch = (queryString: string): void => {
    resetProfilesList();
    updateSearchQuery(queryString);
  };

  const saveProfilesDeletedToSessionStorage = (profileDeletedIds: string[]): void => {
    const profilesDeleted = getProfilesList()
      .map((profileObject, profileIndex) => ({ profileObject, profileIndex }))
      .filter(({ profileObject }) => profileDeletedIds.includes(profileObject.id));

    try {
      const profileJson = JSON.stringify(profilesDeleted);
      sessionStorage.setItem('profilesDeleted', profileJson);
    } catch (error) {
      console.error(error);
    }
  };

  const getProfileDeletedFromSessionStorage = (): { profileObject: IProfile; profileIndex: number }[] => {
    const profilesDeletedJson = sessionStorage.getItem('profilesDeleted');
    if (!profilesDeletedJson) {
      return [];
    }

    try {
      return JSON.parse(profilesDeletedJson);
    } catch (error) {
      return [];
    }
  };

  const addProfileFirstToList = (profile: IProfile): void => mapAndSetProfilesList((prevProfiles) => {
    let profilesToUpdate: IProfile[];
    const profileExistingIndex = prevProfiles.findIndex(({ id }) => id === profile.id);
    if (profileExistingIndex < 0) {
      profilesToUpdate = [...prevProfiles];
      profilesToUpdate.unshift(profile);
    } else {
      profilesToUpdate = insertProfileToListByIndex(prevProfiles, profile, profileExistingIndex);
    }

    updateFullProfilesMap(profilesToUpdate);

    return profilesToUpdate;
  });

  const addProfileToList = (): void => {
    const profilesDeleted = getProfileDeletedFromSessionStorage();
    mapAndSetProfilesList((prevProfiles) => {
      let profilesUpdated = prevProfiles;
      profilesDeleted.forEach(({ profileObject, profileIndex }) => {
        profilesUpdated = insertProfileToListByIndex(profilesUpdated, profileObject, profileIndex);
      });

      updateFullProfilesMap(profilesUpdated);

      return profilesUpdated;
    });

    sessionStorage.removeItem('profilesDeleted');
  };

  const removeProfilesFromList = (profilesIds: string[]): void => {
    saveProfilesDeletedToSessionStorage(profilesIds);
    if (!profilesIds.length) {
      return;
    }

    mapAndSetProfilesList((profilesPrev) => {
      const newProfilesList = profilesPrev.filter(({ id }) => !profilesIds.includes(id));
      updateFullProfilesMap(newProfilesList);

      return newProfilesList;
    });
  };

  const addProfilesToFolder = async (folderName: string, profilesIds = profilesToGetFolders): Promise<void> => {
    setFoldersSelected([...new Set([...foldersSelected, folderName])]);

    const action = 'add';
    manageProfileFoldersList(profilesIds, folderName, action);
    if (NEW_FEATURES.workspaces) {
      await manageProfilesInWorkspaceFolder({
        workspaceId,
        name: folderName,
        profiles: profilesIds,
        action,
      });
    } else {
      await manageProfilesInFolder(folderName, profilesIds, action);
    }

    const folderSelected = getFolderSelectedOrAll();
    if (folderSelected === folderName) {
      addProfileToList();
    }
  };

  const removeProfilesFromFolder = async (folderName: string, profilesIds = profilesToGetFolders): Promise<void> => {
    const newFoldersSelected = [...foldersSelected];
    newFoldersSelected.splice(foldersSelected.indexOf(folderName), 1);
    setFoldersSelected(newFoldersSelected);

    const action = 'remove';
    if (NEW_FEATURES.workspaces) {
      await manageProfilesInWorkspaceFolder({
        workspaceId,
        name: folderName,
        profiles: profilesIds,
        action,
      });
    } else {
      await manageProfilesInFolder(folderName, profilesIds, action);
    }

    manageProfileFoldersList(profilesIds, folderName, action);

    const folderSelected = getFolderSelectedOrAll();
    folderSelected === folderName && removeProfilesFromList(profilesIds);
  };

  const onCloseFolderModal = (): void => {
    setIsFoldersManagerVisible(false);
    sessionStorage.removeItem('profilesDeleted');
    setProfilesToGetFolders([]);
  };

  const openFoldersModal = (profileIds: string[] = []): void => {
    setIsFoldersManagerVisible(true);
    setProfilesToGetFolders(profileIds);
  };

  const initPage = async (): Promise<void> => {
    const search: string = (NEW_FEATURES.header ? searchQuery : query.get('q')) || '';
    updateSearchQuery(search);
    initProfilesTableSorting();
  };

  const processReceivedFolders = (folders: any[], localFolders: any[]): any[] => {
    const sharedFolders = folders.filter((folderObj: any) => folderObj.shared);
    const orderedFolders: any[] = folders
      .filter((folderObj: any) => !folderObj.shared)
      .map((elem: any, index: number) => ({ order: index + 1, ...elem }));

    const nonSharedFoldersLength = orderedFolders.length;
    sharedFolders.forEach((folderObj: any, index: number) => {
      const localFolderObj = localFolders.find((elem: any) => elem.name === folderObj.name);
      orderedFolders.push({
        ...folderObj,
        order: localFolderObj?.order ?? nonSharedFoldersLength + index + 1,
      });
    });

    orderedFolders.sort((a, b) => a.order - b.order);

    return orderedFolders;
  };

  const checkProfilesUA = async (
    receivedProfiles: IProfile[],
    browserV: string = currentBrowserV,
    orbitaMajorV: string = currentOrbitaMajorV,
    currentUserOrbitaV: string = getLatestLocalOrbitaVersion(),
  ): Promise<void> => {
    if (profilesUAChecking || !isElectron) {
      return;
    }

    setProfilesUAChecking(true);

    if (currentUserOrbitaV === '0.0.0') {
      currentUserOrbitaV = await ipcRenderer.invoke('get-orbita-browser-version') || '0.0.0';
      setLatestLocalOrbitaVersion(currentUserOrbitaV);
    }

    if (currentUserOrbitaV === '0.0.0' || !browserV) {
      return;
    }

    const [currentUserOrbitaMajorV] = currentUserOrbitaV.split('.');
    const [browserMajorV] = browserV.split('.');
    receivedProfiles.forEach((profileObj: any) => {
      const profileUA = profileObj.navigator?.userAgent;
      if (profileObj.updateUALastChosenBrowserV === browserV || !profileUA) {
        return;
      }

      const profileUAMatch = profileUA.match(/Chrome\/(\d+\.\d+\.\d+\.\d+)\s/);
      const [_, matchRes = ''] = profileUAMatch || [];
      const [profileUAMajorV] = matchRes.split('.');

      const [intBrowserMajorV, intProfileUAMajorV, intCurrentUserOrbitaMajorV, intOrbitaMajorV] =
        [browserMajorV, profileUAMajorV, currentUserOrbitaMajorV, orbitaMajorV].map(Number);

      if (
        intCurrentUserOrbitaMajorV !== intOrbitaMajorV ||
        intProfileUAMajorV >= intBrowserMajorV
      ) {
        return;
      }

      profileObj.suggestUpdateUA = true;
    });

    setProfilesUAChecking(false);
  };

  useEffect(() => {
    if (initStatus !== 'block-by-folders') {
      return;
    }

    // do not group profiles back if proxy filter is active
    const proxyIdFilter = getProfilesTableProxyIdFilter();
    if (!proxyIdFilter) {
      reloadProfilesTableGroups();
    }

    setInitStatus((prevInitStatus) => prevInitStatus === 'block-by-folders' ? 'allowed' : prevInitStatus);
  }, [initStatus]);

  const handleOrbitaVersions: HandleOrbitaVersionsFn = (currentOrbitaMajorV, currentBrowserV) => {
    setCurrentBrowserV(currentBrowserV);
    setCurrentOrbitaMajorV(currentOrbitaMajorV);
  };

  const handleFolderDeleted: HandleFolderDeletedFn = () => {
    const selectedFolder = NEW_FEATURES.header ? selectedFolderFromCtx : localStorage.getItem(LOCAL_STORAGE_SELECTED_FOLDER);
    updateSelectedFolder('');
    localStorage.setItem(LOCAL_STORAGE_SELECTED_FOLDER, ALL_PROFILES_FOLDER);
    const newFoldersList = foldersList.filter(folder => folder.name !== selectedFolder);
    setFoldersList(newFoldersList);
  };

  const handleFullProfilesMap: HandleFullProfilesMapFn = () => {
    updateFullProfilesMap(getProfilesList());
  };

  const handleDropFileLoaded: HandleDropFileLoadedFn = () => {
    setDropFileStep('loaded');
  };

  const handleLoadingDone: HandleDropFileLoadedFn = () => {
    setNeedParseName(false);
    checkProfilesStatus();
  };

  const handleCheckUa: HandleCheckUaFn = ({ receivedProfiles, currentBrowserV, currentOrbitaMajorV }) =>
    checkProfilesUA(receivedProfiles, currentBrowserV, currentOrbitaMajorV);

  // TODO: fix folders type
  const callFetchProfilesByQuery = (): Promise<void> => fetchProfilesByCurrentQuery({
    foldersList: foldersListFromCtx,
    dropFileStep,
    handleOrbitaVersions,
    handleFolderDeleted,
    handleFullProfilesMap,
    handleDropFileLoaded,
    handleLoadingDone,
    handleCheckUa,
  });

  useEffect(() => {
    if (initStatus !== 'allowed') {
      return;
    }

    callFetchProfilesByQuery();
  }, [currentProfilesQuery, initStatus]);

  const checkProfilesStatus = (): Promise<void> => isElectron && ipcRenderer.invoke('check-profiles');

  const updateTag = (tag: ITagBase): Promise<void> => {
    const profiles = getProfilesList();
    const profilesBackup: IProfile[] = [...profiles];
    let newSelectedTagBackup: ITag|null = null;

    if (selectedTag && tag.id === selectedTag.id) {
      newSelectedTagBackup = {
        ...selectedTag,
      };
    }

    const tagsBackup = [...tags];
    mapAndSetProfilesList(prevProfiles => {
      const { newProfiles, updateRequired } = updateTagInProfiles({ profiles, tag });

      return updateRequired ? newProfiles : prevProfiles;
    });

    profiles.forEach(profile => callProfilesTableRowMeasurer(profile.id));

    if (selectedTag && tag.id === selectedTag.id) {
      setSelectedTagId(tag.id);
    }

    const updateTags = (currentTags: ITag[]): ITag[] => currentTags.map(currentTag => {
      if (currentTag.id !== tag.id) {
        return currentTag;
      }

      return {
        ...currentTag,
        ...tag,
      };
    });

    setTags(updateTags);

    const doUpdateTagRequest = async (newTag: ITag): Promise<void> => updateTagRequest(workspaceId, newTag)
      .catch(() => {
        setProfilesList(profilesBackup);
        setTags(tagsBackup);

        if (selectedTag && selectedTag.id === tag.id) {
          setSelectedTagId(newSelectedTagBackup?.id || null);
        }
      });

    const isUpdatingNewAddedTag = newAddedTag?.id === tag.id && newAddedTag.hasLocalId;
    if (!isUpdatingNewAddedTag) {
      return doUpdateTagRequest(tag);
    }

    newTagActionSubscription.current = { tag, doUpdateTagRequest };
    setNewAddedTag(prev => ({
      ...tag,
      hasLocalId: prev?.hasLocalId,
      field: prev?.field || 'tags',
    }));

    return Promise.resolve();
  };

  const addTag = async (params: IAddTag): Promise<void> => {
    const profiles = getProfilesList();

    const {
      profileIds,
      title,
      color,
      tagId,
      isNewAdded,
      lastTempId,
      field,
    } = params;

    const preparedTitle = title.trim();
    const tempTag: ITag = {
      id: `${new Date().getTime()}`,
      title: preparedTitle,
      color,
      hasLocalId: true,
      field,
    };

    if (!tagId) {
      const tagInList = tags.find(tagItem => tagItem.title === preparedTitle && tagItem.field === field);
      if (tagInList) {
        tempTag.color = tagInList.color;
      } else {
        setNewAddedTag({
          ...tempTag,
          isNewAdded: true,
        });
      }

      const groupField = getProfilesTableGroupField();
      if (groupField === field) {
        closeTagManager();
      }
    }

    const shouldRemoveCustomStatuses = field === 'custom-status' && !isNewAdded;
    const profilesBackup = [...profiles];
    const tagsBackup = [...tags];
    mapAndSetProfilesList(prevProfiles => {
      let newProfiles: IProfile[];
      let updateRequired: boolean;
      let profilesToChange = prevProfiles;

      if (shouldRemoveCustomStatuses) {
        profilesToChange = removeCustomStatusesFromProfiles(prevProfiles, workspaceId, profileIds);
      }

      const tagChange: IAddTagToProfiles = {
        profiles: profilesToChange,
        targetProfileIds: profileIds,
        tag: tempTag,
        compareBy: 'title',
      };

      if (tagId) {
        const newTag: ITag = { id: tagId, title, color, isNewAdded, field };
        ({ newProfiles, updateRequired } = updateTagInProfiles({
          ...tagChange,
          tag: newTag,
          appendIfNotFound: !isNewAdded,
          compareBy: isNewAdded ? 'title' : 'id',
        }));
      } else {
        ({ newProfiles, updateRequired } = addTagToProfiles(tagChange));
      }

      return updateRequired ? newProfiles : profilesToChange;
    });

    profileIds.forEach(profileId => callProfilesTableRowMeasurer(profileId));

    if (tagId && isNewAdded) {
      const newTag: ITag = {
        id: tagId,
        title: preparedTitle,
        color,
        field,
      };

      addNewTagToTagsList(newTag);
      if (lastTempId === prevTag.current) {
        setSelectedTagId(newTag.id);
      }

      return;
    }

    const requestParams: IAddTagMethod = {
      browserIds: profileIds,
      title,
      color,
      field,
    };

    if (workspaceId) {
      requestParams.workspace = workspaceId;
    }

    const addTagResponse = await addTagRequest(requestParams).catch<{ success: false }>(() => {
      setProfilesList(profilesBackup);
      setTags(tagsBackup);

      return { success: false };
    });

    if (!addTagResponse.success || tagId) {
      return;
    }

    const { id: apiTagId, isNewAdded: isTagCreated } = addTagResponse;
    let { title: tagTitle, color: tagColor, field: tagField } = addTagResponse;
    if (isTagCreated) {
      if (newTagActionSubscription.current && !tagId) {
        const { tag: tagFromSub, doUpdateTagRequest } = newTagActionSubscription.current;
        ({ title: tagTitle, color: tagColor, field: tagField } = tagFromSub);
        newTagActionSubscription.current = null;
        doUpdateTagRequest({
          id: apiTagId,
          title: tagTitle,
          color: tagColor,
          field: tagField,
        });
      }

      setNewAddedTag({
        id: apiTagId,
        title: tagTitle,
        color: tagColor,
        hasLocalId: false,
        field: tagField,
      });
    }

    await addTag({
      profileIds,
      title: tagTitle,
      color: tagColor,
      field: tagField,
      tagId: apiTagId,
      isNewAdded: isTagCreated,
      lastTempId: tempTag.id,
    }).catch(() => {
      setProfilesList(profilesBackup);
      setTags(tagsBackup);
    });
  };

  const removeTag = async (tagId: string, isInSuggest: boolean): Promise<void> => {
    const profiles = getProfilesList();

    if (selectedTag && selectedTag.id === tagId) {
      setSelectedTagId(null);
      closeTagManager();
    }

    const tagsBackup = [...tags];
    let profilesBackup: IProfile[];
    mapAndSetProfilesList(prevProfiles => {
      profilesBackup = [...prevProfiles];

      return removeTagFromProfiles(profiles, tagId);
    });

    profiles.forEach(profile => callProfilesTableRowMeasurer(profile.id));

    const updateTags = (currentTags: ITag[]): ITag[] => [
      ...currentTags,
    ].filter(tag => tag.id !== tagId);

    setTags(updateTags);

    await deleteTag(tagId, workspaceId).catch(() => {
      setProfilesList(profilesBackup);
      setTags(tagsBackup);
    });
  };

  const removeProfileTag = async (profileIds: string[], tagId: string): Promise<void> => {
    let profilesBackup: IProfile[];
    mapAndSetProfilesList(prevProfiles => {
      profilesBackup = [...prevProfiles];
      let newProfiles = removeTagFromProfiles(prevProfiles, tagId, profileIds);
      let skipUpdateProfilesList = false;
      if (selectedTag) {
        newProfiles = findProfilesWithTag(newProfiles, selectedTag.id);
        if (!newProfiles.length) {
          skipUpdateProfilesList = true;
          setSelectedTagId(null);
          closeTagManager();
        }
      }

      return skipUpdateProfilesList ? prevProfiles : newProfiles;
    });

    profileIds.forEach(profileId => callProfilesTableRowMeasurer(profileId));

    await deleteProfilesTag({ profileIds, tagId, workspace: workspaceId }).catch(() => {
      setProfilesList(profilesBackup);
    });
  };

  useEffect(() => {
    if (!workspaceId) {
      return;
    }

    const fetchTags = async (): Promise<void> => {
      const userTagsResponse = await getAllTags(workspaceId);
      if (userTagsResponse.tags) {
        setTags(userTagsResponse.tags);
        reloadProfilesTableGroups();
      }

      setInitStatus((prevInitStatus) => prevInitStatus === 'block-by-tags' ? 'allowed' : prevInitStatus);
    };

    fetchTags();

    return clearColumnFilters;
  }, [workspaceIsLoaded, workspaceId]);

  const downloadSpecificOrbitaVersion = async (orbitaVersion: string): Promise<void> => {
    if (!orbitaVersion) {
      return;
    }

    updateBrowserUpdater({
      initialized: true,
      browserUpdating: true,
      showOrbitaDialog: false,
    });

    ipcRenderer && ipcRenderer.invoke('download-browser', { orbitaVersion });
  };

  useEffect(() => {
    if (browserUpdating || isIniting || !isElectron) {
      return;
    }

    ipcRenderer.invoke('get-orbita-browser-version')
      .then((currentUserOrbitaV = '0.0.0') => {
        setLatestLocalOrbitaVersion(currentUserOrbitaV);
        const newProfiles = [...getProfilesList()];
        checkProfilesUA(newProfiles, currentBrowserV, currentOrbitaMajorV);
        setProfilesList(newProfiles);
        updateFullProfilesMap(newProfiles);
      });
  }, [browserUpdating, getProfilesList]);

  if (isUpdatePage) {
    return <UpdateProfilePage />;
  }

  const isNewShareProfileModal = !!(NEW_FEATURES.workspaces && NEW_FEATURES.header);

  if (areAntStylesLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <ContainerDashboard
        closeDrawer={closeProfilesSettings}
      >
        <Header
          onSearch={updateOnSearch}
        />
        <BodyContainer>
          <ContainerContent newStyle={!!NEW_FEATURES.header}>
            {NEW_FEATURES.header ? null : (
              <ContainerPreTable>
                {
                  (!selectedTag) ?
                    (
                      <ContainerTableName>
                        <TextTableName>
                          {translation('profiles.title')}
                        </TextTableName>
                      </ContainerTableName>
                    ) : (
                      <>
                        <ContainerTableName>
                          <TitleTag
                            updateTag={updateTag}
                            removeTag={removeTag}
                          />
                        </ContainerTableName>
                      </>
                    )
                }
              </ContainerPreTable>
            )}
            {(isIniting && NEW_FEATURES.header) || !tableSettingsLoaded || dropFileStep || initStatus !== 'allowed' ? (
              <SpinnerContainer>
                <IconSpinner size={32} padding={0} />
                {dropFileStep === 'importing' ? translation('profiles.importing.text') : null}
                {dropFileStep === 'multipleCreating' ? translation('base.tableProfile.loading') : null}
              </SpinnerContainer>
            ) : (
              <ProfilesTableQuick
                profilesTableSettings={profilesTableSettings}
                setProfilesTableSettings={setProfilesTableSettings}
                setProfiles={setProfilesFnWithRef}
                foldersList={NEW_FEATURES.workspaces || NEW_FEATURES.header ? foldersListFromCtx : foldersList}
                allProfilesFolderId={allProfilesFolderId}
                manageProfileFoldersList={manageProfileFoldersList}
                openFoldersModal={openFoldersModal}
                browserUpdating={browserUpdating}
                versionLoadingNow={versionLoadingNow}
                addProfilesToFolder={addProfilesToFolder}
                removeProfilesFromFolder={removeProfilesFromFolder}
                updateTag={updateTag}
                removeTag={removeTag}
                addTag={addTag}
                removeProfileTag={removeProfileTag}
                downloadSpecificOrbitaVersion={downloadSpecificOrbitaVersion}
                selectedFolderFromCtx={selectedFolderFromCtx}
                isOrbitaVersionCompatible={isOrbitaVersionCompatible}
                lastWorkspaceId={workspaceId}
                updateSelectedProfileIds={updateProfilesTableSelectedIds}
                onFolderChange={onFolderChange}
                profilesQueryLoadingStatus={profilesQueryLoadingStatus}
              />
            )}
          </ContainerContent>
        </BodyContainer>
        <Helmet>
          <title>
            {translation('profiles.title')}
            {' '}
            - GoLogin
            {' '}
            {window.gologinAppVersion || ''}
          </title>
        </Helmet>
      </ContainerDashboard>
      <PageOverlay />
      <FoldersManager
        visible={isFoldersManagerVisible}
        onClose={onCloseFolderModal}
        folders={NEW_FEATURES.workspaces || NEW_FEATURES.header ? foldersListFromCtx : foldersList}
        foldersSelected={foldersSelected}
        setFoldersSelected={setFoldersSelected}
        addProfilesToFolder={addProfilesToFolder}
        removeProfilesFromFolder={removeProfilesFromFolder}
      />
      {!firstPlanSelected || NEW_FEATURES.proxyGroups ? <QuickPricing /> : null}
      <RemoveProfileModal />
      <ProxyManager />
      {isNewShareProfileModal ? <MultiShareModal /> : null}
      <DraggingProfilesOverlay />
    </>
  );
};

export default ProfilesListPage;
