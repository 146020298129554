import styled from '@emotion/styled';
import { Button, Checkbox, Input } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import GologinModal from '../../../ui/gologin-modal';

const FontsMainDiv = styled('div')`
  overflow-y: scroll;
  height: 390px;
  padding: 5%;
  margin-top: 10px;
  border: 1px solid var(--E0E0E0);
  background-color: var(--F9F9F9);
`;

const FontContainer = styled('div')`
  padding: 2% 2% 2% 5%;
  border: 1px solid var(--E0E0E0);
  background-color: var(--FFFFFF);
  font-size: 14px;
  font-weight: 300;
`;

declare interface IFontsManager {
  fonts: any[];
  fontsModalVisible: boolean;
  updateFontsList: (fonts: any[]) => void;
  onOk: () => void;
  onCancel: () => void;
}

const FontsManager: FC<IFontsManager> = (props) => {
  const { t: translation } = useTranslation();

  const searchFontByRegex = (string: string) => {
    const regex = new RegExp(`^${string}`, 'i');
    const fonts = props.fonts || [];
    fonts.forEach(fontElem => {
      const fontName = fontElem.name;
      if ((string && regex.test(fontName)) || !string) {
        fontElem.display = 'block';
      } else {
        fontElem.display = 'none';
      }
    });

    props.updateFontsList(fonts);
  };

  const setFontState = (font: string, state: boolean) => {
    const fonts = props.fonts;
    fonts.forEach(fontElem => {
      if (fontElem.value === font) {
        fontElem.checked = state;
      }
    });

    props.updateFontsList(fonts);
  };

  const fontsParamsTable = () => (
    <div>
      <Input
        style={{ width: '60%' }}
        placeholder={`${translation('fontsManagerModal.search.placeholder')}...`}
        allowClear={true}
        onChange={(e: any) => searchFontByRegex(e.target.value)}
      />
      <FontsMainDiv>
        {props.fonts.map((elem: any, index: number) => (
          <FontContainer style={{ display: elem.display }} key={index}>
            {elem.name}
            <Checkbox
              style={{ float: 'right' }}
              checked={elem.checked}
              disabled={!!elem.disabled}
              onChange={e => setFontState(elem.value, e.target.checked)}
            />
          </FontContainer>
        ))}
      </FontsMainDiv>
    </div>
  );

  return (
    <GologinModal
      isVisible={props.fontsModalVisible}
      onClose={() => props.onCancel()}
      title={translation('fontsManagerModal.title')}
      destroyOnClose={true}
      footer={(
        <>
          <Button
            onClick={() => props.onCancel()}
          >
            {translation('base.cancel')}
          </Button>
          <Button
            type='primary'
            onClick={() => props.onOk()}
            style={{ marginLeft: 8 }}
          >
            {translation('base.save')}
          </Button>
        </>
      )}
      width='520px'
    >
      {fontsParamsTable()}
    </GologinModal>
  );
};

export default FontsManager;
