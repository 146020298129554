import { Popconfirm } from 'antd';
import React from 'react';

import { isMenuItemsPropsWithConfirm, MenuItemsProps } from './interfaces';
import { MenuItem } from './menu-item';

export const MenuItemWithPopconfirm = (props: MenuItemsProps): JSX.Element|null => {
  const { isVisible = true } = props;

  if (!isVisible) {
    return null;
  }

  if (!isMenuItemsPropsWithConfirm(props)) {
    return <MenuItem {...props} />;
  }

  const { popconfirmProps } = props;
  const { title, onConfirm, cancelText, okText } = popconfirmProps;

  return (
    <Popconfirm
      placement='bottom'
      title={title}
      onConfirm={onConfirm}
      cancelText={cancelText}
      okText={okText}
    >
      <MenuItem {...props} />
    </Popconfirm>
  );
};
