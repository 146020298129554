import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconClock: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M8.5 15.5C12.366 15.5 15.5 12.366 15.5 8.5C15.5 4.63401 12.366 1.5 8.5 1.5C4.63401 1.5 1.5 4.63401 1.5 8.5C1.5 12.366 4.63401 15.5 8.5 15.5Z' stroke='var(--CDCDCD)' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8.5 4.5V8.5H12.5' stroke='var(--CDCDCD)' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  </IconWrapper>
);
