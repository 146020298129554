import React from 'react';

import { Mixed } from './mixed';
import { openProfilesTableModal, PROFILE_EXTENSIONS_MODAL_NAME } from '../../../../../state/profiles-table-modal.atom';
import { useProfilesTableSelectedIds } from '../../../../../state/profiles-table-selected-ids.atom';

export const MixedExtensions = (): JSX.Element => {
  const selectedProfileIds = useProfilesTableSelectedIds();

  return (
    <>
      <div onClick={(): void => openProfilesTableModal(PROFILE_EXTENSIONS_MODAL_NAME, selectedProfileIds)}>
        <Mixed colorText='var(--2B2B31-profile-new-settings)' />
      </div>
    </>
  );
};
