import { getProfilesDataFromDolphinApi } from './api';

export const getAllProfileIdsFromDolphin = async (apiKey: string, result: string[] = [], page = 1): Promise<string[]> => {
  const response = await getProfilesDataFromDolphinApi(apiKey, 10, page).catch(() => ({ data: [] }));
  const { data } = await response.json();
  if (!data.length) {
    return result;
  }

  data.map((profile: any) => result.push(profile.id));

  // таймаут между запросами на апи долфина, для перестраховки
  await new Promise(resolve => setTimeout(resolve, 500));

  return getAllProfileIdsFromDolphin(apiKey, result, page + 1);
};
