import styled from '@emotion/styled';

import { IconWrapperWithDescription } from '../../../../../../../ui/gologin-header/icons/wrapper';

export const ShareLinkItemWrapper = styled(IconWrapperWithDescription)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const LinkTextWrapper = styled('div')`
  padding: 8px 0;
  margin: 0 8px 0 0;
  overflow: hidden;
  white-space: nowrap;

  :hover > p {
    color: var(--222222-modals-profile-share-modal);
  }
`;

export const LinkText = styled('p')<{ isPaused: boolean }>`
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;

  font: normal 14px 'Roboto';
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;

  text-decoration-line: none;
  color: ${({ isPaused }): string => isPaused ?
    'var(--B5B5BA-share-links-url)' :
    'var(--222222-modals-profile-share-modal)'};
`;
