import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { sendActionAnalytics } from '../../../../features/common/api';
import { userContext, workspaceContext } from '../../../../state';
import { openWebsitePricing } from '../../../../utils/open-site';
import { IconArrowRight } from '../../icons';
import { IconWrapperWithDescription } from '../../icons/wrapper';
import { Text, TextWitchIcon, Wrapper } from '../styles';

const UnpaidWorkspaceBar = (): JSX.Element => {
  const { isQuickSettingsEnabled, firstPlanSelected } = useContext(userContext);
  const { id: workspaceId } = useContext(workspaceContext);

  const { t: translation } = useTranslation();

  const openPricingPage = async (): Promise<void> => {
    sendActionAnalytics('clicked buy plan', { actionInfo: 'trial finished bar' });
    openWebsitePricing({
      workspaceId,
      isQuickSettingsEnabled,
      isShowPaymentMethods: firstPlanSelected,
    });
  };

  return (
    <Wrapper background={'var(--FFEDED-promo-bar-unpaid)'} border='var(--F3D3D3-promo-bar-unpaid-borders)'>
      <Text>{translation('headerNotify.unpaidNotify.text')}</Text>
      <IconWrapperWithDescription
        onClick={openPricingPage}
        iconColor='var(--00A987-promo-bar-trial)'
        iconHoveredColor='var(--00997A-promo-bar-trial)'
        textColor='var(--00A987-promo-bar-trial)'
        textHoveredColor='var(--00997A-promo-bar-trial)'
        iconType='stroke'
      >
        <TextWitchIcon>{translation('header.payNow')}</TextWitchIcon>
        <IconArrowRight padding={0} />
      </IconWrapperWithDescription>
    </Wrapper>
  );
};

export default UnpaidWorkspaceBar;
