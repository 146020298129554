import React from 'react';

import FacebookButton from './facebook-button';
import GoogleButton from './google-button';
import { SeparationLine, Wrapper } from './styles';

interface IButtons {
  isElectron: boolean;
  isIFrame?: boolean;
}

const Buttons: React.FC<IButtons> = (props) => {
  const { isElectron, isIFrame } = props;

  return (
    <Wrapper>
      <GoogleButton isElectron={isElectron} isIFrame={isIFrame} />
      {/* <FacebookButton /> */}
      <SeparationLine>
        <span>
          OR
        </span>
      </SeparationLine>
    </Wrapper>
  );
};

export default Buttons;
