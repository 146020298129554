import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconLogo: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='33' height='16' viewBox='0 0 33 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M0.5 7.90429C0.5 3.53884 4.04538 0 8.4189 0C10.9966 0 13.5135 1.64633 14.8788 3.30091L20.8488 10.5531L20.849 10.5534C21.8178 11.7294 23.5201 12.7178 24.9099 12.7178C27.5732 12.7178 29.7322 10.5627 29.7322 7.90429C29.7322 5.24585 27.5732 3.09082 24.9099 3.09082C23.5215 3.09082 21.8085 4.08873 20.838 5.26852L20.8376 5.26891L19.7893 6.5424L17.8066 4.08233L18.4449 3.30705L18.4452 3.30667C19.8116 1.64594 22.3291 0 24.9099 0C29.2834 0 32.8288 3.53884 32.8288 7.90429C32.8288 12.2697 29.2834 15.8086 24.9099 15.8086C22.3328 15.8086 19.825 14.1771 18.4568 12.516L18.4567 12.5158L18.4565 12.5157L16.5158 10.1581V15.9986H13.8064V13.5993C12.3889 14.8218 10.4255 15.8086 8.4189 15.8086C4.04538 15.8086 0.5 12.2697 0.5 7.90429ZM12.4887 5.26597L15.0509 8.37854H8.82096V11.083H11.982C10.9933 12.0122 9.5958 12.7178 8.4189 12.7178C5.75555 12.7178 3.59653 10.5627 3.59653 7.90429C3.59653 5.24585 5.75555 3.09082 8.4189 3.09082C9.80339 3.09082 11.5161 4.08802 12.4878 5.26497L12.4887 5.26597Z' fill='var(--00A987)' />
    </svg>
  </IconWrapper>
);
