import { Input } from 'antd';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { changeEmail } from './api';
import { StyledBtn, StyledInput } from './styles';

interface IChangeEmailForm {
  onConfirm: (email: string) => any;
  initialEmail?: string;
}
const ChangeEmailForm: FC<IChangeEmailForm> = (props ) => {
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [isLoading, changeLoading] = useState(false);

  const { t: translation } = useTranslation();

  const changeEmailField = (e: React.FormEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value);
  };

  const changePasswordField = (e: React.FormEvent<HTMLInputElement>) => {
    setPassword(e.currentTarget.value);
  };

  const onConfirmClicked = async () => {
    changeLoading(true);
    try {
      await changeEmail(email, password);

      props.onConfirm(email);
    } catch (err) {
      changeLoading(false);
    } finally {
      changeLoading(false);
    }
  };

  return (
    <>
      <StyledInput
        value={email}
        placeholder={translation('base.newEmail')}
        defaultValue={props.initialEmail}
        onChange={changeEmailField}
        autoFocus={true}
      />
      <Input.Password value={password} placeholder={translation('base.password')} onChange={changePasswordField} />
      <StyledBtn type='primary' loading={isLoading} onClick={onConfirmClicked}>{translation('base.submit')}</StyledBtn>
    </>
  );
};

export default ChangeEmailForm;
