import { IProfile } from '../../interfaces';
import { mapAndSetProfilesList } from '../profiles-list.atom';
import { refreshFullProfile } from '../profiles-map.atom';

type ManageFoldersAction = 'add' | 'remove';

export const manageProfileObjFolders = (profile: IProfile, folderName: string, action: ManageFoldersAction): IProfile => {
  const profileObj = { ...profile };
  if (action === 'add') {
    if (profileObj.folders && !profileObj.folders.includes(folderName)) {
      profileObj.folders.push(folderName);
    } else if (!profileObj.folders) {
      profileObj.folders = [folderName];
    }
  }

  if (action === 'remove' && profileObj.folders && profileObj.folders.includes(folderName)) {
    profileObj.folders = profileObj.folders.map((el: any) => el !== folderName ? el : null).filter(Boolean);
  }

  if (profileObj.folders) {
    profileObj.folders = profileObj.folders.sort();
  }

  return profileObj;
};

export const manageProfileFoldersList = (profilesIds: string[], folderName: string, action: ManageFoldersAction): void => {
  mapAndSetProfilesList((profilesPrev) => profilesPrev.map((profileObj) => {
    if (!profilesIds.includes(profileObj.id)) {
      return profileObj;
    }

    const newProfileObj = manageProfileObjFolders(profileObj, folderName, action);
    refreshFullProfile(newProfileObj);

    return newProfileObj;
  }));
};
