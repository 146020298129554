import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { IPlan } from '../../interfaces/plan';

const plansListAtom = atom<IPlan[]>([]);

export const usePlansList = (): IPlan[] => useAtomValue(plansListAtom);
export const usePlanObjById = (planId: string): IPlan|null => {
  const plansList = usePlansList();

  return plansList.find(planObj => planObj.id === planId) || null;
};

export const setPlansList = (data: IPlan[]): void => getDefaultStore().set(plansListAtom, data);
export const getPlansList = (): IPlan[] => getDefaultStore().get(plansListAtom);
export const getPlanObjById = (planId: string): IPlan|null => getPlansList().find(planObj => planObj.id === planId) || null;
