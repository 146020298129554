import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { useDiscountsList } from './pricing/discounts-list.atom';
import { usePlansList } from './pricing/plans-list.atom';
import { usePromoDiscount } from './pricing/promo-discount.atom';
import { useUpgradeDiscount } from './pricing/upgrade-discount.atom';
import { IUser } from './user.context';
import { EMPTY_DISCOUNT } from '../features/pricing/constants';
import { IRawPricingData } from '../features/pricing/interfaces';
import { IWorkspace } from '../interfaces/workspaces';

const defaultPricingDataValue: IRawPricingData = {
  userData: {} as IUser,
  workspace: {} as IWorkspace,
  discounts: [],
  plans: [],
  promoDiscount: EMPTY_DISCOUNT,
  pricingConfig: {},
};

const pricingDataAtom = atom(defaultPricingDataValue);

const setPricingData = (value: IRawPricingData): void => getDefaultStore().set(pricingDataAtom, value);

export const usePricingData = (): IRawPricingData => {
  const plans = usePlansList();
  const discounts = useDiscountsList();
  const promoDiscount = usePromoDiscount();
  const upgradeDiscount = useUpgradeDiscount();
  const pricingData = useAtomValue(pricingDataAtom);

  return ({ ...pricingData, plans, discounts, promoDiscount, upgradeDiscount });
};


export const updatePricingData = (value: IRawPricingData): void => setPricingData(value);
