import { API_BASE_URL } from '../../../common/constants/constants';
import { http } from '../../services';
import type {
  DeltaSyncApiVer,
  DeltaSyncClientVer,
  DeltaSyncEvent,
  ObjectPoolEntityType,
  ObjectPoolFolderProfile,
  ObjectPoolProfile,
} from '../types';
import { ObjectPoolShare } from '../types/object-pool-share';

type FetchDeltaSyncDataParams = {
  workspaceId: string;
  syncSince: string;
  apiVer: DeltaSyncApiVer;
  clientVer: DeltaSyncClientVer;
}

type UnwrapObjectPoolEntity<T extends { objectPoolEntityType: ObjectPoolEntityType }> = Omit<T, 'objectPoolEntityType'>;

export type FetchDeltaSyncData = {
  workspaces: Array<{
    profiles: UnwrapObjectPoolEntity<ObjectPoolProfile>[];
    foldersProfiles: UnwrapObjectPoolEntity<ObjectPoolFolderProfile>[];
  }>;
  shares: UnwrapObjectPoolEntity<ObjectPoolShare>[];
  events: Array<DeltaSyncEvent>;
  latestSyncDate: string;
  isSyncDone: boolean;
}

export const fetchDeltaSyncData = async ({
  workspaceId,
  syncSince,
  apiVer = 'v2',
  clientVer = 1,
}: FetchDeltaSyncDataParams): Promise<FetchDeltaSyncData> =>
  http(`${API_BASE_URL}/delta-sync/${apiVer}?clientVer=${clientVer}&onlyWorkspaceId=${workspaceId}&syncSince=${syncSince}`, {
    method: 'GET',
  }).then((res: any) => res.body);
