import styled from '@emotion/styled';
import CoinbaseCommerceButton from 'react-coinbase-commerce';

export const Col = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const Row = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 367px;
`;

export const ParamsCardLineContainer = styled('div')`
  height: 94px;
  width: 636px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: var(--DEF7E9-new-pricing);
`;

export const PaymentDetailsContainer = styled('div')`
  width: 367px;
  display: flex;
  justify-content: space-between;
`;

export const PaymentDetailsTxt = styled('div')`
  font-family: Roboto, sans-serif;
  font-size: 13px;
  line-height: 15px;
  color: var(--000000-new-pricing);
  margin-bottom: 7px;
`;

export const PaypalContainerOld = styled.div<{ paddingBottom: boolean }>`
  padding: 22px 133px ${props => props.paddingBottom ? '25px' : '53px'};
`;

export const PaypalContainer = styled.div`
  width: 100%;
  max-width: 367px;
  max-height: 116px;
`;

export const ButtonPay = styled('div')`
  word-spacing: 3px;
  border: 0 solid;
  border-radius: 60px;
  padding: 0;
  box-sizing: border-box;
  height: auto;
  color: var(--00B86D-new-pricing);
  background: var(--FFFFFF-new-pricing);
  min-width: auto;
  align-self: center;
  cursor: pointer;

  &:after {
    box-shadow: none;
    display: none;
  }

  > span {
    color: var(--333333-new-pricing);
    text-shadow: none;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    cursor: pointer;
  }

  .spinner {
    top: 4px;
  }
`;

export const ButtonPayCrypto = styled(CoinbaseCommerceButton)`
  word-spacing: 3px;
  border: 0 solid;
  border-radius: 60px;
  padding: 0;
  box-sizing: border-box;
  height: auto;
  color: var(--00B86D-new-pricing);
  background: var(--FFFFFF-new-pricing);
  min-width: auto;
  align-self: center;
  cursor: pointer;

  &:after {
    box-shadow: none;
    display: none;
  }

  > span {
    color: var(--333333-new-pricing);
    text-shadow: none;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    cursor: pointer;
  }

  .spinner {
    top: 4px;
  }
`;

export const ThreeDSecureStyled = styled.iframe`
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  border-radius: 16px;
`;

export const PaymentModalIconsBarContainer = styled.div`
  padding: 28px 27px;
  background: var(--F9F9F9-new-pricing);
  border-radius: 0 0 16px 16px;
  line-height: 0;
`;

export const ButtonPayPaypal = styled.button`
  position: relative;
  display: flex;
  width: 370px;
  height: 40px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border: 0;
  border-radius: 4px;
  background: #FFC43A;
  cursor: pointer;

  :hover::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.08);
    pointer-events: none;
  }
`;

export const ContainerTextPoweredByPaypal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TextPoweredByPaypal = styled.div`
  display: flex;
  color: #7B8388;
  font-family: Arial;
  font-size: 11px;
  font-style: italic;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.22px;
`;

export const ProxyCheckoutBackground = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--FFFFFF-new-pricing);
`;

export const TextProxyCheckout = styled.span`
  color: var(--00A987-pricing-proxy-checkout);
  margin-right: 7px;
  margin-left: 24px;
  cursor: pointer;

  &:hover {
    color: var(--00A987-pricing-proxy-checkout);
  }
`;
