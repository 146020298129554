import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconAffilate: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_11772_3183)'>
        <path d='M4.5 4C4.5 3.17157 3.82843 2.5 3 2.5C2.17157 2.5 1.5 3.17157 1.5 4C1.5 4.82843 2.17157 5.5 3 5.5C3.82843 5.5 4.5 4.82843 4.5 4Z' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M3.5 14.5H1.5V11.5H0.5V8.5C0.5 8.23478 0.605357 7.98043 0.792893 7.79289C0.98043 7.60536 1.23478 7.5 1.5 7.5H2.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M11.5 4C11.5 4.82843 12.1716 5.5 13 5.5C13.8284 5.5 14.5 4.82843 14.5 4C14.5 3.17157 13.8284 2.5 13 2.5C12.1716 2.5 11.5 3.17157 11.5 4Z' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M12.5 14.5H14.5V11.5H15.5V8.5C15.5 8.23478 15.3946 7.98043 15.2071 7.79289C15.0196 7.60536 14.7652 7.5 14.5 7.5H13.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M10 2.5C10 1.39543 9.10457 0.5 8 0.5C6.89543 0.5 6 1.39543 6 2.5C6 3.60457 6.89543 4.5 8 4.5C9.10457 4.5 10 3.60457 10 2.5Z' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M10.5 15.5H5.5V11.5H4.5V8.5C4.5 7.96957 4.71071 7.46086 5.08579 7.08579C5.46086 6.71071 5.96957 6.5 6.5 6.5H9.5C10.0304 6.5 10.5391 6.71071 10.9142 7.08579C11.2893 7.46086 11.5 7.96957 11.5 8.5V11.5H10.5V15.5Z' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_11772_3183'>
          <rect width='16' height='16' fill='var(--FFFFFF)' />
        </clipPath>
      </defs>
    </svg>
  </IconWrapper>
);
