import css from '@emotion/css';
import styled from '@emotion/styled';

export const BookmarksTextArea = styled('textarea')<{ canResize?: boolean; disabled?: boolean }>`
  min-height: 186px !important;
  min-width: 489px;
  width: 489px;
  max-width: 100%;
  max-height: 2600px;
  border-radius: 4px;
  white-space: pre;

  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.5715;
  color: var(--000000A6);
  background-color: var(--FFFFFF);
  border: 1px solid var(--D9D9D9);
  transition: all 0.3s;

  :hover {
    border-color: var(--1FC47D);
    border-right-width: 1px !important;
  }

  :focus {
    border-color: var(--1FC47D);
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px var(--00B86D33);
  }

  ::placeholder {
    color: var(--CDCDCD);
  }

  :disabled {
    cursor: not-allowed;
    background-color: var(--FAFAFA);
    border: none;
  }
  
  :disabled:hover,
  :disabled:focus {
    border-color: var(--D9D9D9);
    border: none;
  }

  ${props =>
    css`
        resize: ${props.canResize && !props.disabled ? 'both' : 'none'};
      `}
  }
`;

export const BookmarksTabTitle = styled('div')<{ disabled?: boolean }>`
  margin-top: 18px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;

  ${props =>
    css`
        color: ${props.disabled ? 'var(--D2D2D5)' : 'var(--000000)'};
      `}
  }
`;

export const BookmarksTabContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 0px 2px 0px 2px;
`;

export const BookmarksTextEditLocked = styled('text')`
  height: 186px;
  width: 489px;
  margin-top: 15px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  line-height: 20px;
  color: var(--696973);
  white-space: pre-line;
`;

export const BookmarksTextHowToSync = styled('text')`
  font-weight: 500;
  white-space: pre-line;
`;

export const BookmarksTextInfoPart2 = styled('div')`
  margin-top: 10px;
`;
