import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  handleColumnToggle,
  PROFILE_TABLE_COLUMNS,
  SingleProfileColumnSetting,
  useColumnsSettings,
} from '../../../../state/columns-settings.atom';
import {
  closeProfilesTableModal,
  PROFILE_TABLE_COLUMNS_MODAL_NAME,
  useProfilesTableModalIsVisible,
} from '../../../../state/profiles-table-modal.atom';
import { GologinCheckbox } from '../../../../ui/gologin-checkbox';
import GologinModal from '../../../../ui/gologin-modal';
import { MarginIcon, RowNameFolder, TextNameFolder } from '../../../quickProfiles/table/styles';

const ProfilesTableColumnsModal = (): JSX.Element => {
  const { columnsSettings } = useColumnsSettings();
  const isModalVisible = useProfilesTableModalIsVisible(PROFILE_TABLE_COLUMNS_MODAL_NAME);

  const { t: translation } = useTranslation();

  const renderColumnCheckbox = (columnName: typeof PROFILE_TABLE_COLUMNS[number]): JSX.Element | null => {
    if (!columnsSettings) {
      return null;
    }

    const column = columnsSettings
      .find((col: SingleProfileColumnSetting) => col.colName === columnName);

    if (!column) {
      return null;
    }

    return (
      <RowNameFolder
        style={{ cursor: 'pointer' }}
        onClick={(): Promise<void> => handleColumnToggle(column)}
      >
        <TextNameFolder>
          {column.label}
        </TextNameFolder>
        <MarginIcon >
          <GologinCheckbox
            onChange={(): null => null}
            checked={column.visible}
          />
        </MarginIcon>
      </RowNameFolder>
    );
  };

  return (
    <GologinModal
      width='373px'
      isVisible={isModalVisible}
      onClose={closeProfilesTableModal}
      title={translation('profiles.profileFieldsEditor')}
    >
      {PROFILE_TABLE_COLUMNS.slice(1).map(columnTitle => renderColumnCheckbox(columnTitle))}
    </GologinModal>
  );
};

export default ProfilesTableColumnsModal;
