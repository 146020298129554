import React from 'react';

const IconSwitch = () => (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
    <path d="M2.05308 10.2243L10.5745 1.77529M10.5745 1.77529L6.68392 2.11225M10.5745 1.77529L10.2043 5.66284" stroke="var(--666666)" strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.447 5.68102L5.92555 14.13M5.92555 14.13L6.29569 10.2425M5.92555 14.13L9.81611 13.7931" stroke="var(--666666)" strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0">
    <rect width="16" height="15" fill="var(--FFFFFF)"/>
    </clipPath>
    </defs>
    </svg>
);

export default IconSwitch;
