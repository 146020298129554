import React, { useState } from 'react';
import { IProfile } from '../../../interfaces';
import AutomationStatusTooltip from './tooltip';
import { TitleContainer } from './styles';
import { AutomationStatusData } from '../interfaces/automation-status.interfaces';
import AutomationStatusDisplay from './status-display';
import { useAutomationProfileStatus } from '../../../state/automation-profile-statuses.atom';

let ipcRenderer: Electron.IpcRenderer;
const isElectron = !!window.require;
if (isElectron) {
  ({ ipcRenderer } = window.require('electron'));
}

type AutomationStatusProps = {
  profileId: IProfile['id'];
};

const AutomationStatus: React.FC<AutomationStatusProps> = (props) => {
  const { profileId } = props;

  const [visibleAutomationStatusData, setVisibleAutomationStatusData] = useState<AutomationStatusData>({ status: 'none', workflowId: '', profileId });
  const [anchorElTooltip, setAnchorElTooltip] = useState<HTMLElement | null>(null);

  const automationStatusData = useAutomationProfileStatus(profileId);

  if (!automationStatusData.status) {
    return null;
  }
  
  let hideTimeout: NodeJS.Timeout;

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    clearTimeout(hideTimeout);
    setAnchorElTooltip(event.currentTarget);
  };

  const handleMouseLeave = () => {
    hideTimeout = setTimeout(() => setAnchorElTooltip(null), 300);
  };

  return (
    <>
      <TitleContainer>
        <div
          style={{ display: 'flex', cursor: visibleAutomationStatusData.status === 'none' ? 'default' : 'pointer' }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <AutomationStatusDisplay
            actualAutomationStatusData={automationStatusData}
            visibleAutomationStatusData={visibleAutomationStatusData}
            setVisibleAutomationStatusData={setVisibleAutomationStatusData}
          />
        </div>
      </TitleContainer>
      <AutomationStatusTooltip
        onMouseEnter={(event) => {
          event.stopPropagation();
          clearTimeout(hideTimeout);
        }}
        onMouseLeave={(event) => {
          event.stopPropagation();
          handleMouseLeave();
        }}
        anchorElTooltip={anchorElTooltip}
        statusData={visibleAutomationStatusData}
      />
    </>
  );
};

export default AutomationStatus;
