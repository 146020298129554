import { API_BASE_URL } from '../../../../../common/constants/constants';
import { IProxy } from '../../../../interfaces';
import { http } from '../../../../services';

export const updateManyProxy = async (profilesIds: string[], proxy: any) => {
  const url = `${API_BASE_URL}/browser/proxy/many`;

  const data = await http(url, {
    method: 'PATCH',
    body: JSON.stringify({
      profilesIds,
      proxy: {
        mode: proxy.mode,
        username: proxy.username,
        host: proxy.host,
        password: proxy.password,
        port: proxy.port,
        autoProxyRegion: proxy.autoProxyRegion,
        torProxyRegion: proxy.torProxyRegion,
      }
    }),
  });

  return data.body;
}

export interface IProfileProxy {
  profileId: string;
  proxy: IProxy;
}
export const updateProxies = async (profilesProxies: IProfileProxy[]) => {
  const url = `${API_BASE_URL}/browser/proxy/many/v2`;

  const body = JSON.stringify({
    proxies: profilesProxies,
  });

  const data = await http(url, {
    method: 'PATCH',
    body,
  });

  return data.body;
}
