
import { message } from 'antd';
import React from 'react';
import { Trans } from 'react-i18next';

import { isHostValid, isPortValid } from '../../common/proxy/validators/proxy.validators';
import { IProxyDataValidatorProps } from '../interfaces/proxy-data-valodator.interface';

export const isProxyDataValid = ({ host, port, shouldThrowNotification = true }: IProxyDataValidatorProps): boolean => {
  const isValidHost = isHostValid(host);
  const isValidPort = isPortValid(port);
  if (!isValidHost) {
    if (shouldThrowNotification) {
      message.error(<Trans i18nKey='notifications.error.notValidHost' />);
    }

    return false;
  }

  if (!isValidPort) {
    if (shouldThrowNotification) {
      message.error(<Trans i18nKey='notifications.error.notValidPort' />);
    }

    return false;
  }

  return true;
};
