import React from 'react';

export interface IIconArrowDown {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
  style?: any;
}

const IconArrowDown: React.FC<IIconArrowDown> = ({
  width = 8,
  height = 5,
  className,
  color = 'var(--FFFFFF)',
  style,
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    style={style}
    viewBox='0 0 8 5'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M1 1L3.8 4L7 1' stroke={color} />
  </svg>
);

export default IconArrowDown;
