import css from '@emotion/css';
import styled from '@emotion/styled';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import React, { FC, useContext, useRef, useState } from 'react';

import TagsColors from './tag-colors';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { tagsContext, workspaceContext } from '../../../state';
import IconBack from '../../../ui/icons/IconBack';
import IconRename from '../../../ui/icons/IconRename';
import { ITag, ITagBase } from '../interfaces/tag.interface';
import { setSelectedTagId } from '../../../state/tags/selected-tag.atom';

const TextTableName = styled('div')`
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: var(--000000-tags-title);
  padding-left: 14px;
  padding-right: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 400px;
  margin-bottom: 14px;
  font-family: Roboto;
  font-style: normal;
  outline: none;

  &:focus {
    outline: none;
  }
`;

const InputContainer = styled('div')`
  display: flex;
  align-items: center;
  position: relative;
`;

const IconContainer = styled('div')<{ isOpen?: boolean }>`
  cursor: pointer;
  margin-top: 1px;
  margin-bottom: 14px;
  width: 19px;
  height: 20px;
  text-align: right;
  color: var(--767676-tags-title);

  ${(props): any => css`
    ${(props.isOpen) ? `
      color: var(--00A987-tags-title);
    ` : ''}
  `}

  :hover {
    color: var(--00A987-tags-title);
  }
`;

const TagsColorContainer = styled('div')`
  position: relative;
  left: -41px;
  top: -31px;
  margin-left: 14px;
  margin-top: 8px;
`;

interface IProps {
  updateTag: (tag: ITagBase) => Promise<void>;
  removeTag: (tagId: string, isInSuggest: boolean) => Promise<void>;
}

const TitleTag: FC<IProps> = (props) => {
  const { updateTag, removeTag } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement|null>(null);

  const titleBlock = useRef<HTMLInputElement>(null);

  const { selectedTag } = useContext(tagsContext);
  const tag = selectedTag as ITag;

  const {
    permissions,
  } = React.useContext(workspaceContext);

  let allowRemove = true;
  let allowEditTags = true;

  if (NEW_FEATURES.workspaces) {
    allowRemove = permissions.removeTags;
    allowEditTags = permissions.editTags;
  }

  const editorOpen = Boolean(anchorEl);

  const handleBackClick = async (event: React.SyntheticEvent): Promise<void> => {
    event.preventDefault();
    setSelectedTagId(null);
  };

  const handleClick = async (): Promise<void> => {
    setAnchorEl((anchorEl) ? null : titleBlock.current);
  };

  const onTagRemove = (): Promise<void> => removeTag(tag.id, false);

  return (
    <div style={{ marginBottom: 6 }}>
      <InputContainer>
        <IconContainer style={{ marginTop: 0, zIndex: 100, paddingLeft: 4, textAlign: 'left' }} onClick={handleBackClick}>
          <IconBack />
        </IconContainer>
        <TextTableName ref={titleBlock}>
          {selectedTag?.title}
        </TextTableName>
        {(allowEditTags) ? (
          <>
            <IconContainer onClick={handleClick} isOpen={editorOpen} style={{ lineHeight: '22px' }}>
              <IconRename />
            </IconContainer>

            <Popper
              open={editorOpen}
              anchorEl={anchorEl}
              style={{ zIndex: 200 }}
              placement='bottom-start'
              disablePortal={true}
            >
              <ClickAwayListener onClickAway={(): void => {
                setAnchorEl(null);
              }}>
                <TagsColorContainer>
                  <TagsColors
                    tag={tag}
                    updateTag={updateTag}
                    close={(): void => {
                      setAnchorEl(null);
                    }}
                    removeTag={onTagRemove}
                    allowChangeTitle={true}
                    allowRemove={allowRemove}
                  />
                </TagsColorContainer>
              </ClickAwayListener>
            </Popper>
          </>
        ) : null}
      </InputContainer>
    </div>
  );
};

export default TitleTag;
