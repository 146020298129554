import { Icon } from 'antd';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { requestExtensionList } from './api';
import {
  ErrorContainer,
  ErrorContainerButtons,
  ErrorContainerMain,
  ErrorLogo,
  ErrorText,
  RetryButton,
  SearchRow,
  StyledInput,
  UploadIcon,
  UploadSuccessIcon,
  DisabledUploadIcon,
} from './domElements';
import { IExtensionFromDb } from './interfaces/extension.interface';
import { IProfile } from '../../../interfaces';
import { extensionsContext } from '../../../state';
import { PROFILE_EXTENSIONS_MODAL_NAME, useProfilesTableModalIsVisible } from '../../../state/profiles-table-modal.atom';
import IconSearch from '../../../ui/icons/IconSearch';
import IconUpload from '../../../ui/icons/IconUpload';
import TooltipCustom from '../../../ui/tooltip-custom';
import { setExtensionsRequest } from '../../modalsComponents/components/extensions-modal/api';
import { ISetExtensionsOptions } from '../../modalsComponents/components/extensions-modal/interfaces/extensions-modal.interface';

interface ISearchRow {
  profileInfo: any;
  profileIds?: string[];
  updateProfileInfo?: (selectedProfilesIds: string[], profileInfo: Partial<IProfile>) => void;
  setErrorMessage: (field: string) => void;
  errorMessage: string;
  submittedSearchString: string;
  setSubmittedSearchString: (arg: string) => void;
  setUserExtensions: (arg: IExtensionFromDb[]) => void;
  userExtensions: IExtensionFromDb[];
  setExtensions: (arg: IExtensionFromDb[]) => void;
  extensions: IExtensionFromDb[];
  isLoadingModal: boolean;
  isCustomExtLoading: boolean;
  setIsCustomExtLoading: (arg: boolean) => void;
}

const isElectron = !!window.require;

let dialog: any;
let ipcRenderer: Electron.IpcRenderer;
if (isElectron) {
  ({ ipcRenderer } = window.require('electron'));
  ({ dialog } = require('@electron/remote'));
}

const SearchRowComponent: FC<ISearchRow> = (props) => {
  const [isUploadSuccess, setIsUploadSuccess] = useState<boolean>(false);
  const [extensionsTmp, setExtensionsTmp] = useState<IExtensionFromDb[]>([]);
  const [searchString, setSearchString] = useState<string>('');
  const [isDialogOpening, setIsDialogOpening] = useState<boolean>(false);
  const [anchorElTooltip, setAnchorElTooltip] = useState<HTMLElement | null>(null);

  const { addCustomExtension } = useContext(extensionsContext);

  const isModalVisible = useProfilesTableModalIsVisible(PROFILE_EXTENSIONS_MODAL_NAME);

  const { t: translation } = useTranslation();

  const {
    profileInfo, updateProfileInfo, setErrorMessage, errorMessage, setUserExtensions,
    userExtensions, extensions, setExtensions, isLoadingModal, isCustomExtLoading, setIsCustomExtLoading,
    setSubmittedSearchString, submittedSearchString, profileIds,
  } = props;

  useEffect(() => {
    if (!isModalVisible) {
      setSearchString('');
      setSubmittedSearchString('');
      setExtensionsTmp([]);
    }
  }, [isModalVisible]);

  useEffect(() => {
    if (!searchString && extensionsTmp.length) {
      setExtensions(extensionsTmp);
      setSubmittedSearchString('');
    }
  }, [searchString]);

  const uploadCustomExtension = async (): Promise<void> => {
    setIsDialogOpening(true);
    if (!isElectron) {
      return;
    }

    setErrorMessage('');

    const { filePaths } = await dialog.showOpenDialog({
      properties: ['openDirectory', 'openFile'],
    });

    setIsDialogOpening(false);

    const [extensionPath] = filePaths;
    if (!extensionPath) {
      return;
    }

    setIsCustomExtLoading(true);

    const result = ipcRenderer && await ipcRenderer.invoke('upload-custom-extension', extensionPath);

    setIsCustomExtLoading(false);

    if (!['success', 'error'].includes(result.status)) {
      setErrorMessage(translation('notifications.error.somethingWentWrong'));

      return;
    }

    if (result.status === 'error') {
      const { body } = result;
      if (!body) {
        setErrorMessage(translation(result.message));

        return;
      }

      const { key = '', maxFileSize = 0 } = body;
      if (key && maxFileSize) {
        setErrorMessage(translation(body.key, { count: maxFileSize }));

        return;
      }

      setErrorMessage(translation('notifications.error.somethingWentWrong'));

      return;
    }

    if (profileInfo) {
      const currentUserChromeExtensions = profileInfo.userChromeExtensions || [];
      updateProfileInfo && updateProfileInfo(profileIds, {
        userChromeExtensions: [
          ...currentUserChromeExtensions,
          result.message?.extId,
        ],
      });
    }

    const resultExtension = {
      ...result.message,
      type: 'user',
    };

    if (profileIds?.length) {
      const options: ISetExtensionsOptions = {
        chromeExtensionIds: [],
        customExtensionIds: [result.message?.extId],
        profileIds,
        extensionsToRemove: [],
      };

      setExtensionsRequest(options).catch(() => null);
    }

    addCustomExtension(resultExtension);
    setIsUploadSuccess(true);
    setExtensions([...extensions, resultExtension]);
    setUserExtensions([...userExtensions, resultExtension]);
    setTimeout(() => setIsUploadSuccess(false), 3000);
  };

  const getUploadIcon = (): JSX.Element => {
    if (!isElectron || isLoadingModal || isDialogOpening) {
      return (
        <DisabledUploadIcon>
          <IconUpload />
        </DisabledUploadIcon>
      );
    }

    if (isCustomExtLoading) {
      return (
        <UploadIcon>
          <Icon type='loading' />
        </UploadIcon>
      );
    }

    if (isUploadSuccess) {
      return (
        <UploadSuccessIcon>
          <Icon type='check' />
        </UploadSuccessIcon>
      );
    }

    return (
      <UploadIcon onClick={uploadCustomExtension}>
        <IconUpload />
      </UploadIcon>
    );
  };

  const getErrorMessage = (): null|JSX.Element => {
    if (!errorMessage) {
      return null;
    }

    return (
      <ErrorContainer>
        <ErrorContainerMain>
          <ErrorLogo>
            <Icon type='exclamation-circle' theme='filled' />
          </ErrorLogo>
          <ErrorText>
            {errorMessage}
          </ErrorText>
        </ErrorContainerMain>
        <ErrorContainerButtons>
          <RetryButton onClick={uploadCustomExtension}>
            {translation('manageExtensionsModal.buttons.btnRetry')}
          </RetryButton>
        </ErrorContainerButtons>
      </ErrorContainer>
    );
  };

  const submitSearch = async (): Promise<void> => {
    const formattedSearchString = searchString.replace(/[^a-zA-Z0-9 ]/g, '');
    if (!formattedSearchString) {
      return;
    }

    !submittedSearchString && setExtensionsTmp(extensions);

    const extensionsFromSearch = await requestExtensionList({ skip: 0, searchString: formattedSearchString });
    setExtensions(extensionsFromSearch);
    setSubmittedSearchString(searchString);
  };

  const getSearchIcon = (): null|JSX.Element => {
    if (!searchString) {
      return null;
    }

    return (
      <Icon
        onClick={(): void => setSearchString('')}
        style={{ cursor: 'pointer', color: 'var(--00000040)', marginTop: 4 }}
        type='close'
      />
    );
  };

  return (
    <>
      <SearchRow>
        <StyledInput
          value={searchString}
          onChange={(e): void => setSearchString(e.target.value)}
          onPressEnter={(): Promise<void> => submitSearch()}
          suffix={getSearchIcon()}
          prefix={
            <div style={{ marginTop: 10 }}>
              <IconSearch color={'var(--767676)'} />
            </div>
          }
          placeholder={translation<string>('manageExtensionsModal.search.placeholder.label')}
        />
        <div
          onMouseEnter={(event): void => setAnchorElTooltip(event.currentTarget)}
          onMouseLeave={(): void => setAnchorElTooltip(null)}
        >
          {getUploadIcon()}
        </div>
        <TooltipCustom
          value={isElectron ? translation<string>('manageExtensionsModal.upload') : translation<string>('manageExtensionsModal.disabledUploadWeb')}
          anchorEl={anchorElTooltip}
        />
      </SearchRow>
      {getErrorMessage()}
    </>
  );
};

export default SearchRowComponent;
