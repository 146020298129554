import { getDefaultStore, atom, useAtomValue } from 'jotai';

const isQuickPricingVisibleAtom = atom<boolean>(false);
const shouldOpenQuickPricingAtLaunchAtom = atom<boolean>(false);

const setIsQuickPricingVisible = (isVisible: boolean): void => getDefaultStore().set(isQuickPricingVisibleAtom, isVisible);

export const useIsQuickPricingVisible = (): boolean => useAtomValue(isQuickPricingVisibleAtom);

export const setShouldOpenQuickPricingAtLaunch = (newShouldOpenQuickPricingAtLaunch: boolean): void =>
  getDefaultStore().set(shouldOpenQuickPricingAtLaunchAtom, newShouldOpenQuickPricingAtLaunch);

export const openQuickPricing = (): void => {
  setIsQuickPricingVisible(true);
};

export const closeQuickPricing = (): void => {
  setIsQuickPricingVisible(false);
};

export const onProfileLaunched = (): void => {
  const shouldOpenQuickPricingAtLaunch = getDefaultStore().get(shouldOpenQuickPricingAtLaunchAtom);
  if (!shouldOpenQuickPricingAtLaunch) {
    return;
  }

  setShouldOpenQuickPricingAtLaunch(false);
  const delayToShowMs = 5000;
  setTimeout(openQuickPricing, delayToShowMs);
};

