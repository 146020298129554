import React, { FC } from 'react';

import { StyledFormControlLabel, StyledSwitch } from './styles';

interface ICustomSwitch {
  checked: boolean;
  onChange: (checked: boolean) => void;
  label?: string;
}

const ModernSwitch: FC<ICustomSwitch> = ({ checked, onChange, label= '' }) => (
  <StyledFormControlLabel
    control={
      <StyledSwitch
        size='medium'
        checked={checked}
        onChange={(event, afterChecked): void => onChange(afterChecked)}
        color='primary'
      />
    }
    label={label}
    labelPlacement='end'
  />
);

export default ModernSwitch;
