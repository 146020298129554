import { ExpandMore } from '@material-ui/icons';
import React, { FC } from 'react';

import { SelectButtonContainer, TitleRole } from './styles';

interface ISelectButton {
  roleText: string;
  width: string;
  subtext?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  selectStyled?: boolean;
  height?: string;
}

export const SelectButton: FC<ISelectButton> = (props) => (
  <div style={{ flex: 1 }}>
    <SelectButtonContainer
      onClick={(!props.disabled && props.onClick) ? props.onClick : undefined}
      disabled={props.disabled}
      selectStyled={!!props.selectStyled}
      width={props.width}
      height={props.height}
    >
      <TitleRole >{props.roleText}</TitleRole>
      {!props.disabled && <ExpandMore htmlColor='var(--00000066-members-permissions-select)' style={{ width: '26px', height: '16px', paddingRight: 2 }} />}
    </SelectButtonContainer>
    {props.subtext && <p>{props.subtext}</p>}
  </div>
);
