import { css } from '@emotion/core';
import { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled/macro';

export const NameColText = styled('span')<{ newStyle: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${(props): SerializedStyles => props.newStyle ? css`
    opacity: 1;
    font-weight: 400;
    letter-spacing: 0.25px;
  ` : css`
    opacity: 0.5;
  `}
`;
