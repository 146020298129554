import { createTemplate, updateTemplateBrowser } from '../../features/personalArea/browser-template/api';
import { ITemplate, IBrowserParamsFromTemplates } from '../../state';

interface IUseSaveTemplateHook {
  saveTemplate: (param: Partial<IBrowserParamsFromTemplates>) => Promise<void>;
}

export const useSaveTemplate = (selectedTemplate: ITemplate, updateSelectedTemplate: (template: ITemplate) => void): IUseSaveTemplateHook => {
  const saveTemplate = async (param: Partial<IBrowserParamsFromTemplates>): Promise<void> => {
    const newTemplate = { ...selectedTemplate, browser: { ...selectedTemplate.browser, ...param } };
    updateSelectedTemplate(newTemplate);
    const updatedTemplate = !selectedTemplate.id ? await createTemplate(newTemplate) : await updateTemplateBrowser(newTemplate);
    updateSelectedTemplate(updatedTemplate);
  };

  return { saveTemplate };
};
