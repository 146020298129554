import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { setProxyForm } from './proxy-form.atom';
import { resetSelectedProxies } from './selected-proxies.atom';
import { sendActionAnalytics } from '../../features/common/api';
import { ProxyManagerModalView } from '../../features/proxy/components/interfaces/proxy-manager-modal-view.type';
import { DEFAULT_PROXY_FORM_VALUES } from '../../features/proxy/constants';
import { IProxy } from '../../interfaces';

export type ProxySettingsModalView = Extract<ProxyManagerModalView, 'proxy-add'|'proxy-edit'>;

interface IProxySettingsModalState {
  modalView: ProxySettingsModalView|null;
  currentProxy: IProxy|null;
}

const PROXY_SETTINGS_DEFAULT_STATE: IProxySettingsModalState = {
  modalView: null,
  currentProxy: null,
};

const proxySettingsStateAtom = atom(PROXY_SETTINGS_DEFAULT_STATE);

const setProxySettingsModal = (data: IProxySettingsModalState): void => getDefaultStore().set(proxySettingsStateAtom, data);

export const useProxySettingsModal = (): IProxySettingsModalState => useAtomValue(proxySettingsStateAtom);
export const useIsProxySettingsModalVisible = (): boolean => !!useAtomValue(proxySettingsStateAtom).modalView;

export const openProxySettingsModal = (options: IProxySettingsModalState): void => {
  const { currentProxy, modalView } = options;
  if (currentProxy && modalView === 'proxy-edit') {
    setProxyForm(currentProxy);
  } else {
    setProxyForm(DEFAULT_PROXY_FORM_VALUES);
  }

  setProxySettingsModal(options);
  sendActionAnalytics('opened proxy settings modal');
};

export const closeProxySettingsModal = (): void => {
  setProxyForm(DEFAULT_PROXY_FORM_VALUES);
  setProxySettingsModal(PROXY_SETTINGS_DEFAULT_STATE);
  resetSelectedProxies();
};
