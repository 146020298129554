import { centerTextVerticallyStandard, centerTextVerticallyVectorized } from './center-text-vertically';
import { getIconsConstants } from '../constants';
import { ILineFirstYValueParams, ILineSingleYValueParams, ITextParams, ITextYValues, IValueForWin10BigOrSmallIconParams } from '../interfaces';
import { OsForBrowserIcons } from '../types';

export const DOUBLING_VALUE = 2;

const isWin10BigIconsSetting = (canvasSize: number, scaleFactor: number): boolean => {
  const sizeBeforeMultiplication = canvasSize / scaleFactor;
  const { browserIconsSizes } = getIconsConstants('win10');

  return sizeBeforeMultiplication === browserIconsSizes[0];
};

const getValueForWin10BigOrSmallIcons = ({ valueForBigIcon, os, scaleFactor, canvasSize }: IValueForWin10BigOrSmallIconParams): number => {
  if (os !== 'win10') {
    return valueForBigIcon;
  }

  const isWin10BigIcon = isWin10BigIconsSetting(canvasSize, scaleFactor);
  if (isWin10BigIcon) {
    return valueForBigIcon;
  }

  return valueForBigIcon / DOUBLING_VALUE;
};

const getTextXValue = (canvasSize: number): number => canvasSize / DOUBLING_VALUE;

export const getTextYValueSingleLine = ({ canvasSize, lineMetrics }: ILineSingleYValueParams): number =>
  (canvasSize + lineMetrics.actualBoundingBoxAscent - lineMetrics.actualBoundingBoxDescent) / DOUBLING_VALUE;

export const getTextYValuesTwoLines = (params: ILineFirstYValueParams): ITextYValues => {
  const { lineFirstMetrics, lineSecondMetrics, ...paramsBase } = params;
  const { textFontSizeBase, lineHeightRatio } = getIconsConstants(paramsBase.os);
  const isWin10BigIcon = params.os !== 'win10' || isWin10BigIconsSetting(params.canvasSize, params.scaleFactor);

  let yValues: ITextYValues;
  if (paramsBase.isCenteringStandard || !isWin10BigIcon) {
    yValues = centerTextVerticallyStandard({
      ...paramsBase, textFontSizeBase,
      lineHeightRatio,
    }, isWin10BigIcon);
  } else {
    yValues = centerTextVerticallyVectorized({ ...paramsBase, lineFirstMetrics, lineSecondMetrics }, isWin10BigIcon);
  }

  return yValues;
};

const getTextMaxWidth = (os: OsForBrowserIcons, scaleFactor: number): number => getIconsConstants(os).textMaxWidthBase * scaleFactor;
const getTextFont = (os: OsForBrowserIcons, scaleFactor: number): string => `${getIconsConstants(os).textFontSizeBase * scaleFactor}px Gilroy`;

export const getIconPadding = (os: OsForBrowserIcons, scaleFactor: number): number => getIconsConstants(os).iconsPaddingBase * scaleFactor;
export const getRoundRectBorderRadius = (os: OsForBrowserIcons, scaleFactor: number, canvasSize: number): number => {
  const roundRectBorderRadius = getIconsConstants(os).borderRadiusBase * scaleFactor;

  return getValueForWin10BigOrSmallIcons({ valueForBigIcon: roundRectBorderRadius, os, canvasSize, scaleFactor });
};

export const getTextParams = (os: OsForBrowserIcons, canvasSize: number, scaleFactor: number): ITextParams => {
  const xValue = getTextXValue(canvasSize);
  const maxWidth = getTextMaxWidth(os, scaleFactor);
  const font = getTextFont(os, scaleFactor);
  const textParams = { xValue, maxWidth, font };
  if (os !== 'win10') {
    return textParams;
  }

  const isWin10BigIcon = isWin10BigIconsSetting(canvasSize, scaleFactor);
  if (isWin10BigIcon) {
    return textParams;
  }

  const fontForSmallIcon = getTextFont(os, scaleFactor / DOUBLING_VALUE);

  return {
    xValue,
    maxWidth: maxWidth / DOUBLING_VALUE,
    font: fontForSmallIcon,
  };
};

export const getTextShadowParams = (canvasSize: number): { shadowOffsetY: number; shadowBlur: number } => {
  let [shadowOffsetY, shadowBlur] = [2, 2];
  switch (true) {
    case canvasSize >= 256:
      [shadowOffsetY, shadowBlur] = [canvasSize / 102.4, canvasSize / 102.4];
      break;
    case canvasSize <= 64:
      [shadowOffsetY, shadowBlur] = [canvasSize / 32, canvasSize / 32];
      break;
    default:
      break;
  }

  return { shadowOffsetY, shadowBlur };
};

export const getRectShadowParams = (os: OsForBrowserIcons, scaleFactor: number): { shadowOffsetY: number; shadowBlur: number } => {
  if (os !== 'mac') {
    return { shadowOffsetY: 0, shadowBlur: 0 };
  }

  const shadowValue = 2.5 * scaleFactor;

  return { shadowOffsetY: shadowValue, shadowBlur: shadowValue };
};
