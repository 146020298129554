import React from 'react';

const IconUpload = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.5 11.5L8.5 0.5" stroke="var(--767676)" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3.5 5.5L8.5 0.5L13.5 5.5" stroke="var(--767676)" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M15.5 15.5H1.5" stroke="var(--767676)" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default IconUpload;
