import { useTranslate } from '@tolgee/react';
import React, { FC, useEffect } from 'react';

import {
  ThreeDSecureWrapper,
  ThreeDSecureButton,
  ThreeDSecureSubtitle,
  ThreeDSecureTitle,
} from './styles';
import { TWO_FA_TOKEN_KEY_NAME } from '../../../../../common/constants/constants';
import { ACCESS_TOKEN } from '../../../../services/http/config';
import IconModalCheck from '../../../../ui/icons/IconModalCheck';
import { redirectToDesktop } from '../../../authentification/o-auth/utils';
import { redirectToElectron } from '../../../common/components/redirect-to-desktop-page/utils';

const SECONDS_BEFORE_REDIRECT_TO_ELECTRON = 5;

const SuccessPaymentStaticPage: FC = () => {
  const { t: translation } = useTranslate();

  const redirectToApp = (): void => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN) || '';
    const twoFaToken = localStorage.getItem(TWO_FA_TOKEN_KEY_NAME) || '';
    if (!accessToken) {
      redirectToElectron('redirect/after-payment');

      return;
    }

    redirectToDesktop({ accessToken, twoFaToken });
  };

  useEffect(() => {
    setTimeout(() => {
      redirectToApp();
    }, SECONDS_BEFORE_REDIRECT_TO_ELECTRON * 1000);
  }, []);

  return (
    <>
      <ThreeDSecureWrapper>
        <IconModalCheck />
        <div>
          <ThreeDSecureTitle id='modal-title'>
            {translation('pricing.successPayment.title')}
          </ThreeDSecureTitle>
          <ThreeDSecureSubtitle id='modal-description'>
            {translation('pricing.checkoutModal.redirectTextFirst')}
            {' '}
            {SECONDS_BEFORE_REDIRECT_TO_ELECTRON}
            {' '}
            {translation('pricing.checkoutModal.redirectTextSecond')}
          </ThreeDSecureSubtitle>
        </div>
        <ThreeDSecureButton onClick={redirectToApp}>
          {translation('pricing.checkoutModal.profilesBtn')}
        </ThreeDSecureButton>
      </ThreeDSecureWrapper>
    </>
  );
};

export default SuccessPaymentStaticPage;
