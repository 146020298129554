import { message } from 'antd';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ShareLinkItemWrapper, LinkText, LinkTextWrapper } from './styles';
import { ShareRole } from '../../../../../../../interfaces';
import TooltipCustom from '../../../../../../../ui/tooltip-custom';
import { ShareLinksAction, SharingSelectRole } from '../../../../../../../utils/roles-texts';
import { sendActionAnalytics } from '../../../../../../common/api';
import ShareActionsSelect from '../../../share-actions-select';
import { deleteShareLink, updateShareLink } from '../../api';
import { IShareLink } from '../../interfaces';
import { getHostAndOrigin } from '../../utils';

const { host, origin } = getHostAndOrigin();

interface IShareLinkItem extends IShareLink {
  setShareLinks: React.Dispatch<React.SetStateAction<IShareLink[]>>;
}

const ShareLinkItem: React.FC<IShareLinkItem> = (props) => {
  const { _id, url, role, paused = false, profileIds, setShareLinks } = props;

  const [actionCurrent, setActionCurrent] = useState<SharingSelectRole|ShareLinksAction>(role);
  const [isPaused, setIsPaused] = useState(paused);
  const [anchorElTooltip, setAnchorElTooltip] = useState<HTMLElement|null>(null);

  const tooltipRef = useRef(null);
  const { t: translation } = useTranslation();

  const handleClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    const href = `${origin}/share/${url}`;
    navigator.clipboard.writeText(href);
    message.success(translation('base.copiedText'));
    sendActionAnalytics('copied share profile link', { profile: profileIds[0] });
  };

  const urlWithHost = `${host}/share/${url}`;
  const pauseOrResume = isPaused ? 'resume' : 'pause';
  const actionsGlobal: (SharingSelectRole|ShareLinksAction)[] = ['administrator', 'redactor', 'guest', pauseOrResume, 'delete'];

  const handleActionSelect = async (selectedRole: SharingSelectRole|ShareLinksAction|'remove'): Promise<void> => {
    if (selectedRole === 'varies' || selectedRole === 'remove') {
      return;
    }

    const paramsToUpdate: { profileIds: string[]; role?: ShareRole; paused?: boolean } = { profileIds };
    switch (true) {
      case !['pause', 'resume', 'delete'].includes(selectedRole):
        setActionCurrent(selectedRole);
        paramsToUpdate.role = (selectedRole as ShareRole);
        paramsToUpdate.paused = isPaused;
        await updateShareLink(_id, paramsToUpdate);
        break;
      case selectedRole === 'delete':
        setShareLinks(shareLinksOld => shareLinksOld.filter(link => link._id !== _id));
        await deleteShareLink(_id, profileIds);
        break;
      default:
        setIsPaused(selectedRole === 'pause');
        paramsToUpdate.paused = selectedRole === 'pause';

        await updateShareLink(_id, paramsToUpdate);
        break;
    }
  };

  return (
    <>
      <ShareLinkItemWrapper
        iconType='stroke'
        colorIconType='activeGray'
      >
        <LinkTextWrapper
          onClick={handleClick}
          onMouseEnter={(): void => setAnchorElTooltip(tooltipRef.current)}
          onMouseLeave={(): void => setAnchorElTooltip(null)}
        >
          <LinkText ref={tooltipRef} isPaused={isPaused}>
            {urlWithHost}
          </LinkText>
        </LinkTextWrapper>
        <ShareActionsSelect
          action={[actionCurrent]} // TODO make plural later
          actionsGlobal={actionsGlobal}
          actionCurrent={isPaused ? 'pause' : actionCurrent}
          canEditRole={true} // TODO change naming later
          handleActionSelect={handleActionSelect}
          disableSharing={true} // TODO change naming later
        />
      </ShareLinkItemWrapper>
      <TooltipCustom anchorEl={anchorElTooltip} value={translation('newShareModal.links.copy') || ''} />
    </>
  );
};

export default ShareLinkItem;
