import * as moment from 'moment';

const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

interface ICookies {
  domain: string;
  url: string;
  secure: any;
  path: string;
}

export const getFormattedCookies = (txt: string) => {
  txt = txt.trim();
  if (base64regex.test(txt)) {
    txt = (atob(txt))
      .replace(/\sFalse,/g, 'false,')
      .replace(/\sTrue,/g, 'true,')
      .replace(/\s'"|"'|'/g, '"');
  }

  if (txt.startsWith('{') && txt.endsWith('}')) {
    txt = `[${txt}]`;
  }

  txt = txt.replace(/^"/g, '')
    .replace(/"$/g, '');

  // negative lookbehind аналог
  const reversedTxt = txt.split('').reverse().join('');
  const formattedReversedTxt = reversedTxt.replace(/""(?!:|\s|\\)/g, '"');
  txt = formattedReversedTxt.split('').reverse().join('');

  let txtJson = [];
  const resultTxt = (txt.match(/\[(?=\s*{)[.\s\S]*]/g) || [])[0];
  try {
    txtJson = JSON.parse(resultTxt);
    txtJson = txtJson.filter((elem: any) => Object.keys(elem).length !== 0);
  } catch (e) {}

  if (!txtJson.length) {
    txtJson = parseNetscapeCookies(txt);
  }

  const [firstCookie] = txtJson;
  if (firstCookie.creation && firstCookie.has_expires) {
    return parseUsersFormat1Cookies(txtJson);
  }

  txtJson.forEach((elem: ICookies) => {
    if (!elem.url) {
      let domainWithoutDot = elem.domain;

      if (domainWithoutDot.startsWith('.')) {
        domainWithoutDot = domainWithoutDot.substr(1);
      }

      elem.url =
        'http' +
        (elem.secure ? 's' : '') +
        '://' +
        domainWithoutDot +
        elem.path;
    }
  });

  return txtJson.filter((elem: any) => {
    const url = (elem.url || '').toString();

    elem.sameSite = (elem.sameSite || 'unspecified').toLowerCase();
    if (['None', 'none'].includes(elem.sameSite)) {
      elem.sameSite = 'unspecified';
    }

    if (!elem.expirationDate && elem.expires) {
      const isExpiresString = isNaN(+elem.expires);
      elem.expirationDate = isExpiresString ? elem.expires_timestamp : elem.expires;
    }

    return (
      elem.name &&
      (
        !url || !(url.includes('localhost') || url.includes(' ')) &&
        (url.split('.')).length >= 2 &&
        !url.includes('_')
      )
    );
  });
};

const parseNetscapeCookies = (str: string) => {
  const cookies: any[] = [];
  if (!str) {
    throw new Error();
  }

  const lines = str.split('\n');

  lines.forEach((line: string) => {
    if (/^#/.test(line)) {
      return;
    }

    let tokens = line.split('\t');
    if (tokens.length !== 7) {
      return;
    }

    tokens = tokens.map(e => e.trim());
    const cookie: any = {};

    if (tokens[0].startsWith('/')) {
      return;
    }

    cookie.domain = tokens[0];
    cookie.httpOnly = false;
    cookie.path = tokens[2];
    cookie.secure = tokens[3] === 'TRUE';

    let domainWithoutDot = cookie.domain;
    if (domainWithoutDot.startsWith('.')) {
      domainWithoutDot = domainWithoutDot.substr(1);
    }

    cookie.url =
      'http' +
      (cookie.secure ? 's' : '') +
      '://' +
      domainWithoutDot +
      cookie.path;

    cookie.expirationDate = tokens[4];
    cookie.name = tokens[5];
    cookie.value = tokens[6];

    cookies.push(cookie);
  });

  if (!cookies.length) {
    throw new Error();
  }

  return cookies;
};

export const parseUsersFormat1Cookies = (txtJson: any[]) => {
  const cookies = txtJson.map((cookieElem: any) => {
    const { name, value, secure: secureParam, httponly, path, domain, expires } = cookieElem;
    const { year, month: monthPart, day_of_month, hour: hourPart, minute: minutePart, second: secondPart } = expires;
    const date = getFormattedDateParts(day_of_month);
    const month = getFormattedDateParts(monthPart);
    const hour = getFormattedDateParts(hourPart);
    const minute = getFormattedDateParts(minutePart);
    const second = getFormattedDateParts(secondPart);

    const dateStr = `${date}.${month}.${year} ${hour}:${minute}:${second}`;
    const expirationDate = moment.utc(dateStr, 'DD.MM.YYYY HH:mm:ss').unix();
    const secure = secureParam === '1';

    let domainWithoutDot = domain;
    if (domainWithoutDot.startsWith('.')) {
      domainWithoutDot = domainWithoutDot.substr(1);
    }

    const url =
      'http' +
      (secure ? 's' : '') +
      '://' +
      domainWithoutDot +
      path;

    return {
      url,
      path,
      name,
      value,
      domain,
      secure,
      expirationDate,
      httpOnly: httponly === '1',
    };
  });

  if (!cookies.length) {
    throw new Error();
  }

  return cookies;
};

const getFormattedDateParts = (part: string) => Number(part) > 10 ? part : '0' + part;
