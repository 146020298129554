import React from 'react';

const IconDotsInButton = () => (
    <svg style={{ marginBottom: '2px' }} width="15" height="3" viewBox="0 0 15 3" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="1.5" cy="1.5" r="1.5" transform="rotate(-90 1.5 1.5)" fill="var(--828282)"/>
        <circle cx="7.5" cy="1.5" r="1.5" transform="rotate(-90 7.5 1.5)" fill="var(--828282)"/>
        <circle cx="13.5" cy="1.5" r="1.5" transform="rotate(-90 13.5 1.5)" fill="var(--828282)"/>
    </svg>
);

export default IconDotsInButton;
