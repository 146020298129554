import React from 'react';

import ShareLinkItem from './share-link-item';
import { ListWrapper } from './styles';
import { IShareLink } from '../interfaces';

interface IShareLinksList {
  profileIds: string[];
  shareLinks: IShareLink[];
  setShareLinks: React.Dispatch<React.SetStateAction<IShareLink[]>>;
}

const ShareLinksList: React.FC<IShareLinksList> = (props) => {
  const { profileIds, shareLinks, setShareLinks } = props;

  return (
    <ListWrapper>
      {shareLinks.map(link => <ShareLinkItem {...{ ...link, profileIds, setShareLinks }} key={link.url} />)}
    </ListWrapper>
  );
};

export default ShareLinksList;
