import React, { ReactElement } from 'react';

import IconCreditCard from '../../../../ui/icons/IconCreditCard';
import IconCrypto from '../../../../ui/icons/IconCrypto';
import IconGooglePay from '../../../../ui/icons/IconGooglePay';
import IconPayPal from '../../../../ui/icons/IconPayPal2';
import { PaymentType } from '../../../pricing/interfaces';

export const EASY_PLAN_ID = '5e9d494f4fe29c3c69826252';
export const ANNUAL_DISCOUNT = '5f9695b11f9a50d848314701';
export const MONTH_DISCOUNT = '5f9695b11f9a50d848314702';
export const DISABLED_PLANS = ['Forever Free', 'Custom 6k eRI'];
export const MONTH_NAMES = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const PERIOD_PLANS = [
  {
    id: '1',
    name: 'Annually',
  },
  {
    id: '2',
    name: 'Monthly',
  },
];
export const MONTHLY = 'Monthly';

export interface IPaymentType {
  id: string;
  Icon: ReactElement;
  type: PaymentType;
}

export const PAYMENT_TYPES: IPaymentType[] = [
  {
    id: '1',
    Icon: <IconCreditCard />,
    type: PaymentType.Card,
  },
  // {
  //   id: '2',
  //   Icon: <IconGooglePay />,
  //   type: PaymentType.Mobile,
  // },
  {
    id: '3',
    Icon: <IconPayPal />,
    type: PaymentType.PayPal,
  },
  {
    id: '4',
    Icon: <IconCrypto />,
    type: PaymentType.Crypto,
  },
  {
    id: '5',
    Icon: <IconCreditCard />,
    type: PaymentType.Sigma,
  },
];

export const PAYMENT_TYPES_RU: IPaymentType[] = [
  {
    id: '1',
    Icon: <IconCreditCard />,
    type: PaymentType.Sigma,
  },
  {
    id: '2',
    Icon: <IconCreditCard />,
    type: PaymentType.Card,
  },
  // {
  //   id: '3',
  //   Icon: <IconGooglePay />,
  //   type: PaymentType.Mobile,
  // },
  {
    id: '4',
    Icon: <IconPayPal />,
    type: PaymentType.PayPal,
  },
  {
    id: '5',
    Icon: <IconCrypto />,
    type: PaymentType.Crypto,
  },
];

export const CARD_LABELS = [
  {
    id: 1,
    key: 'cardNumber',
  },
  {
    id: 2,
    key: 'cardExpiry',
  },
  {
    id: 3,
    key: 'cardCvc',
  },
];

export const GOOGLE_PAY = 'Google Pay';
export const APPLE_PAY = 'Apple Pay';
export const RU_LOCALE = 'RU';
