import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { PROXY_GROUP_MODES_LIST, ProxyGroupMode } from './types';
import { PROXY_GROUPS_VISIBILE_PROXY_MODES_KEY } from '../../../../common/constants/local-storage';
import { safeParseJSON, safeStringify } from '../../../../common/utils';

const STRINGIFIED_EMPTY_ARRAY = '[]';

const visibleProxyGroupModesAtom = atom<ProxyGroupMode[] | null>(null);
export const visibleProxyGroupModesPersistentAtom = atom((get) => {
  const currentlyVisibleProxyGroupModes = get(visibleProxyGroupModesAtom);
  if (currentlyVisibleProxyGroupModes) {
    return currentlyVisibleProxyGroupModes;
  }

  const visibleProxyGroupModesInStorageString = localStorage.getItem(PROXY_GROUPS_VISIBILE_PROXY_MODES_KEY);
  if (visibleProxyGroupModesInStorageString) {
    const visibleProxyGroupModesInStorage = safeParseJSON<ProxyGroupMode[]>(visibleProxyGroupModesInStorageString);
    if (visibleProxyGroupModesInStorage) {
      return visibleProxyGroupModesInStorage;
    }
  }

  return PROXY_GROUP_MODES_LIST;
}, (_get, set, newVisibleProxyGroupModes: ProxyGroupMode[]) => {
  set(visibleProxyGroupModesAtom, newVisibleProxyGroupModes);
  const visibleProxyGroupModesInStorageString = safeStringify(newVisibleProxyGroupModes) || STRINGIFIED_EMPTY_ARRAY;
  localStorage.setItem(PROXY_GROUPS_VISIBILE_PROXY_MODES_KEY, visibleProxyGroupModesInStorageString);
});

const getVisibleProxyGroupModes = (): ProxyGroupMode[] => getDefaultStore().get(visibleProxyGroupModesPersistentAtom);
const setVisibleProxyGroupModes = (groupModes: ProxyGroupMode[]): void => getDefaultStore().set(visibleProxyGroupModesPersistentAtom, groupModes);

export const useVisibleProxyGroupModes = (): ProxyGroupMode[] => useAtomValue(visibleProxyGroupModesPersistentAtom);

export const toggleProxyGroupModesVisibility = (groupModesToToggle: ProxyGroupMode[]): void => {
  const currentlyVisibleProxyGroupModes = getVisibleProxyGroupModes();
  const newVisibleGroupsModes = PROXY_GROUP_MODES_LIST.reduce<ProxyGroupMode[]>((acc, groupMode) => {
    if (groupModesToToggle.includes(groupMode)) {
      if (!currentlyVisibleProxyGroupModes.includes(groupMode)) {
        acc.push(groupMode);
      }

      return acc;
    }

    if (currentlyVisibleProxyGroupModes.includes(groupMode)) {
      acc.push(groupMode);
    }

    return acc;
  }, []);

  setVisibleProxyGroupModes(newVisibleGroupsModes);
};
