import React, { ChangeEvent, FC } from 'react';

import { ContainerCloseIcon, SettingsInput, InputContainer, InputTitle } from './styles';
import { IconSmallClose } from '../gologin-header/icons';

interface IInputCustom {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onKeyPress: (event: React.KeyboardEvent) => void;
  onClickIconClose: () => void;
  currentValue: string;
  title?: string;
  hasMarginTop?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  hasAutoFocus?: boolean;
}

const CloseableInput: FC<IInputCustom> = (props) => {
  const { title, onChange, onBlur, onClickIconClose, onKeyPress, hasMarginTop, currentValue, placeholder, isDisabled, hasAutoFocus } = props;

  return (
    <>
      {title ? (
        <InputTitle hasMarginTop={hasMarginTop}>
          {title}
        </InputTitle>
      ) : null}
      <InputContainer hasMarginTop={hasMarginTop}>
        <SettingsInput
          placeholder={placeholder}
          onChange={onChange}
          value={currentValue}
          autoFocus={hasAutoFocus}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          disabled={isDisabled}
        />
        <ContainerCloseIcon
          iconType='stroke'
          iconColor='var(--767676-profile-new-settings-input-start-url)'
          iconHoveredColor='var(--2B2B31-profile-new-settings-input-start-url)'
          onClick={onClickIconClose}
        >
          <IconSmallClose padding={0} />
        </ContainerCloseIcon>
      </InputContainer>
    </>
  );
};

export default CloseableInput;
