import React, { FC } from 'react';

import { IWorkspaceShort } from '../../interfaces/workspaces';
import { getAccessibleFolderName } from '../../utils/check-folders-to-add-profile';
import IconToFolder from '../icons/IconToFolder';
import { ISelectWorkspaceParams } from './select-workspace-params.interface';
import {
  ContainerDescriptionMenuDropdown,
  ContainerIcon,
  ContainerRowTitle,
  ContainerTitleMenuDropdown,
  ErrorTooltipTitleWorkspace,
  NumberText,
  PlanText,
  Row, SelectedFolderNameText,
  TooltipCustom,
  tooltipTitleName,
  WorkspaceNameText,
  WorkspaceSelectedCheck,
} from './styles';

interface IWorkspaceRow {
  workspace: IWorkspaceShort;
  hoveredWorkspace: IWorkspaceShort|null;
  selectedWorkspaceId: string;
  setHoveredWorkspace: (w: IWorkspaceShort) => void;
  onSelect: (selectWorkspaceParams: ISelectWorkspaceParams) => void;
  needFoldersList?: boolean;
  selectedFolder?: string;
}

export const WorkspaceRow: FC<IWorkspaceRow> = (props) => {
  const {
    workspace,
    hoveredWorkspace,
    needFoldersList,
    selectedWorkspaceId,
    selectedFolder,
    setHoveredWorkspace,
    onSelect,
  } = props;

  let hasPermission = true;
  if (needFoldersList) {
    const canClaimInFolder = !!workspace.folders?.find(folder => folder.permissions.canClaimProfiles);
    hasPermission = canClaimInFolder || workspace.permissions.canClaimProfilesWithoutFolder;
  }

  const firstAccessFolder = getAccessibleFolderName({
    folders: workspace.folders || [],
    permission: 'canClaimProfiles',
    limitedAccess: !workspace.permissions.canClaimProfilesWithoutFolder,
    hasFirstFolder: true,
  });

  const showFolderIcon = hoveredWorkspace && hoveredWorkspace.id === workspace.id && !!workspace.folders?.length && hasPermission && needFoldersList;

  const showSelectedFolderName = (): JSX.Element|null => {
    let folderName = '';
    const currentWorkspace = selectedWorkspaceId === workspace.id && workspace.folders?.find(folder => folder.name === selectedFolder);

    if (currentWorkspace && selectedFolder) {
      folderName = selectedFolder;
    } else if (!workspace.permissions.canClaimProfilesWithoutFolder) {
      folderName = firstAccessFolder || '';
    }

    if (!folderName) {
      return null;
    }

    return (
      <SelectedFolderNameText>
        {'\xA0'}
        {'· '}
        {folderName}
      </SelectedFolderNameText>
    );
  };

  const onRowMouseOver = (): void => {
    if (!hoveredWorkspace || hoveredWorkspace.id !== workspace.id) {
      setHoveredWorkspace(workspace);
    }
  };

  const onRowClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    onSelect({
      event,
      hasPermission,
      workspace,
      firstAccessFolder,
    });
  };

  const onFolderIconClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    onSelect({
      event,
      hasPermission,
      workspace,
      firstAccessFolder,
      showFoldersList: true,
    });
  };

  return (
    <>
      <Row
        isDisabled={!hasPermission}
        key={workspace.id}
        onClick={onRowClick}
        onMouseOver={onRowMouseOver}
        style={{ cursor: 'pointer', justifyContent: 'space-between' }}
      >
        <TooltipCustom
          title={<ErrorTooltipTitleWorkspace name={workspace.name} type='workspace' />}
          placement='top'
          disableHoverListener={hasPermission}
        >
          <div style={{ width: '100%', height: '100%' }}>
            <ContainerTitleMenuDropdown>
              <ContainerRowTitle>
                <TooltipCustom title={tooltipTitleName(workspace.name)} placement='top' disableHoverListener={!hasPermission}>
                  <WorkspaceNameText>
                    {workspace.name}
                  </WorkspaceNameText>
                </TooltipCustom>
                {showSelectedFolderName()}
              </ContainerRowTitle>
              {selectedWorkspaceId === workspace.id && (
                <WorkspaceSelectedCheck
                  htmlColor={'var(--00A987-select-workspace)'}
                  viewBox='0 0 25 15'
                />
              )}
            </ContainerTitleMenuDropdown>
            <ContainerDescriptionMenuDropdown>
              <Row style={{ padding: 0 }}>
                <NumberText>
                  {workspace.profilesCount}
                  /
                  {workspace.isPlanUnlimited ? '-' : workspace.planProfilesMax}
                </NumberText>
                <PlanText>
                  {workspace.planName}
                </PlanText>
              </Row>
            </ContainerDescriptionMenuDropdown>
          </div>
        </TooltipCustom>
        {showFolderIcon ? (
          <ContainerIcon onClick={onFolderIconClick}>
            <IconToFolder />
          </ContainerIcon>
        ) : null}
      </Row>
    </>
  );
};
