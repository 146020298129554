import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconAutomationError: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width="14" height="14" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.5 14.5C11.366 14.5 14.5 11.366 14.5 7.5C14.5 3.63401 11.366 0.5 7.5 0.5C3.63401 0.5 0.5 3.63401 0.5 7.5C0.5 11.366 3.63401 14.5 7.5 14.5Z" stroke="#EB5757" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7.5 12C8.05228 12 8.5 11.5523 8.5 11C8.5 10.4477 8.05228 10 7.5 10C6.94772 10 6.5 10.4477 6.5 11C6.5 11.5523 6.94772 12 7.5 12Z" fill="#EB5757"/>
      <path d="M7.5 3.5V8" stroke="#EB5757" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </IconWrapper>
);
