import React, { FC, useState } from 'react';

import { Mixed } from './mixed';
import ProfileBookmarksModal from '../../../../../features/modalsComponents/components/profile-bookmarks';
import {
  useProfilesTableSelectedIds,
} from '../../../../../state/profiles-table-selected-ids.atom';

export const MixedBookmarks: FC = (): JSX.Element => {
  const selectedProfileIds = useProfilesTableSelectedIds();

  const [isShowBookmarksManager, setIsShowBookmarksManager] = useState<boolean>(false);

  return (
    <>
      <div onClick={(): void => setIsShowBookmarksManager(true)}>
        <Mixed colorText='var(--2B2B31-profile-new-settings)' />
      </div>
      <ProfileBookmarksModal
        visible={isShowBookmarksManager}
        onClose={(): void => setIsShowBookmarksManager(false)}
        profilesIds={selectedProfileIds}
      />
    </>
  );
};
