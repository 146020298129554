import React, { FunctionComponent } from 'react';

export interface IIconProfile {
  width?: number;
  height?: number;
  color?: string;
}

const IconProfile: FunctionComponent<IIconProfile> = ({
  width = 24,
  height = 24,
  color = 'var(--FFFFFF)'
}) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      id="svg_1"
      fill={color}
      d="m11.99993,12.00005c0.75157,0 1.48626,-0.22287 2.11117,-0.64042c0.6249,-0.41755 1.112,-1.01102 1.3996,-1.70538c0.2876,-0.69436 0.3628,-1.45842 0.2162,-2.19554c-0.1466,-0.73713 -0.5085,-1.41423 -1.03997,-1.94567c-0.53143,-0.53143 -1.20853,-0.89335 -1.94566,-1.03997c-0.73712,-0.14663 -1.50118,-0.07137 -2.19554,0.21624c-0.69436,0.28761 -1.28784,0.77467 -1.70539,1.39957c-0.41755,0.62491 -0.64041,1.3596 -0.64041,2.11117c0,1.00782 0.40036,1.97437 1.11299,2.687c0.71264,0.71264 1.67919,1.113 2.68701,1.113zm0,-6.51429c0.53683,0 1.06161,0.15919 1.50798,0.45744c0.44636,0.29825 0.79425,0.72217 0.99969,1.21814c0.20544,0.49597 0.25919,1.04172 0.15446,1.56824c-0.10473,0.52652 -0.36324,1.01016 -0.74284,1.38976c-0.3796,0.3796 -0.86324,0.63811 -1.38976,0.74284c-0.52652,0.10473 -1.07227,0.05098 -1.56824,-0.15446c-0.49597,-0.20543 -0.91989,-0.55333 -1.21814,-0.99969c-0.29825,-0.44637 -0.45744,-0.97115 -0.45744,-1.50798c0,-0.71987 0.28597,-1.41026 0.795,-1.91929c0.50903,-0.50903 1.19942,-0.795 1.91929,-0.795z"
    />
    <path
      id="svg_2"
      fill={color}
      d="m12.54284,13.08572l-1.08572,0c-1.58372,0 -3.10257,0.62913 -4.22243,1.74894c-1.11986,1.1199 -1.74899,2.6388 -1.74899,4.2225c0,0.144 0.05719,0.282 0.159,0.3838c0.1018,0.1018 0.23988,0.159 0.38385,0.159l11.94285,0c0.144,0 0.2821,-0.0572 0.3839,-0.159c0.1018,-0.1018 0.159,-0.2398 0.159,-0.3838c0,-1.5837 -0.6292,-3.1026 -1.749,-4.2225c-1.1199,-1.11981 -2.63874,-1.74894 -4.22246,-1.74894zm-5.93886,5.42854c0.13349,-1.1939 0.70219,-2.2968 1.59746,-3.0979c0.89526,-0.8012 2.05431,-1.24436 3.25568,-1.24493l1.08572,0c1.20137,0.00057 2.36042,0.44373 3.25566,1.24493c0.8953,0.8011 1.464,1.904 1.5975,3.0979l-10.79202,0z"
    />
    <circle
      fill="none"
      stroke={color}
      strokeWidth="1"
      r="11.5"
      cy="12"
      cx="12"
      id="svg_4"
    />
  </svg>
);

export default IconProfile;
