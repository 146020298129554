import { IFolder } from '../../../../interfaces';

export enum E_PROXY_FOLDERS {
  allProxies = 'allProxies',
  errors = 'errors',
  archive = 'archive',
}

export const PROXY_FOLDERS = Object.values(E_PROXY_FOLDERS);
export type TProxyFolder = (typeof PROXY_FOLDERS)[number];

export const PROXY_FOLDERS_DEFAULT: IFolder[] = [
  { name: E_PROXY_FOLDERS.allProxies, systemFolder: true },
  { name: E_PROXY_FOLDERS.errors, systemFolder: true },
  { name: E_PROXY_FOLDERS.archive, systemFolder: true },
];

export const MAX_RESTORE_PROXIES = 30;
