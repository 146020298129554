import { message } from 'antd';
import React, { ChangeEvent, FC, KeyboardEvent, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ModalCancelConfirm from './cancel-rename-confirm';
import { InputProfileName } from './styles';
import { NEW_FEATURES } from '../../../../../state/feature-toggle/new-features';
import { DEFAULT_MANY_PROFILE_NAME } from '../../../../../hooks';
import {
  DEFAULT_PROFILE_NAME,
  importFilesContext,
  templatesContext,
} from '../../../../../state';
import { useProfilesList } from '../../../../../state/profiles-list.atom';
import { useProfilesSettingsState } from '../../../../../state/profiles-settings-atom';
import { IconCheck, IconClose } from '../../../../../ui/gologin-header/icons';
import { InputWithPopover } from '../../../../../ui/modern-popover/input-with-popover';
import { ISlotInputInactive } from '../../../../../ui/modern-popover/interfaces';
import { IRenderOptions, renderOptions } from '../../../../../ui/modern-popover/options';
import { pasteSpintax } from '../../../../../ui/modern-popover/paste-spintax';
import { getSpintaxList } from '../../../../../ui/modern-popover/spintax-list';
import { parseNameFormatting } from '../../../../../utils/parse-name-formatting';
import { INewNameProfile } from '../../../../../utils/parse-name-formatting/interfaces';
import { renameProfileNameMany } from '../../../api';
import { IChangeSetting } from '../../../interfaces';
import { ContainerParams } from '../../../styles';
import { Mixed } from '../mixed/mixed';

interface IMixedProfileName extends IChangeSetting {
  changeProfilesData: (newProfilesData: INewNameProfile[]) => void;
  localSettingId: string;
}

const MixedProfileName: FC<IMixedProfileName> = (props) => {
  const { changeProfilesData, localSettingId } = props;

  const profilesList = useProfilesList();
  const { profileIds } = useProfilesSettingsState();

  const {
    needParseName,
    setNeedParseName,
    manyProfilesName,
    needOpenSpintaxPopup,
    setNeedOpenSpintaxPopup,
  } = useContext(importFilesContext);

  const [profileName, setProfileName] = useState<string>(manyProfilesName);
  const [oldProfileName, setOldProfileName] = useState<string>(manyProfilesName);
  const [isPopupSpintaxVisible, setIsPopupSpintaxVisible] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [modalConfirmVisible, setModalConfirmVisible] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const { selectedTemplate } = useContext(templatesContext);

  const { t: translation } = useTranslation();

  const isPinnedSection = localSettingId === 'pinned-name';
  const spintaxList = getSpintaxList(translation);

  const getSelectedProfilesName = (selectedProfileId: string): INewNameProfile|null => {
    const selectedProfile = profilesList.find(profile => selectedProfileId === profile.id);
    if (!selectedProfile) {
      return null;
    }

    return { profileId: selectedProfile.id, name: selectedProfile.name, email: selectedProfile.facebookAccountData?.email };
  };

  const selectedProfiles: INewNameProfile[] = profileIds.map(profileId => getSelectedProfilesName(profileId))
    .filter(Boolean) as INewNameProfile[];

  useEffect(() => {
    if (!(needOpenSpintaxPopup && isPinnedSection)) {
      return;
    }

    onClickMixed();
    setNeedOpenSpintaxPopup(false);
  }, [needOpenSpintaxPopup]);

  useEffect(() => {
    const isMultiProfilesSettings = profileIds.length > 1;
    if (!(isPinnedSection && (isMultiProfilesSettings || needParseName))) {
      return;
    }

    const [drawer] = document.getElementsByClassName('ant-drawer-content-wrapper');
    if (!drawer) {
      return;
    }

    const transitionEndEvent = 'transitionend';
    const onTransition = (drawerEvent: Event): void => {
      drawer.removeEventListener(transitionEndEvent, onTransition);
      if (drawerEvent.target !== drawer) {
        return;
      }

      if (needParseName) {
        setIsEditMode(true);
        const defaultSpintax = (NEW_FEATURES.templateProfileName && manyProfilesName) || DEFAULT_PROFILE_NAME;
        setProfileName(defaultSpintax);
        setOldProfileName(defaultSpintax);
        setIsPopupSpintaxVisible(true);

        return;
      }

      onClickMixed();
    };

    drawer.addEventListener(transitionEndEvent, onTransition);

    return () => drawer.removeEventListener(transitionEndEvent, onTransition);
  }, []);

  const onAreaChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setProfileName(event.target.value);
  };

  const onKeyPress = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key !== 'Enter') {
      return;
    }

    onApply();
  };

  const onApply = async (): Promise<void> => {
    if (!profileName.trim()) {
      setNeedParseName(false);
      setIsEditMode(false);

      return;
    }

    setIsEditMode(false);
    setOldProfileName(profileName);
    setNeedParseName(false);

    const formattingParams = {
      profiles: selectedProfiles,
      nameFormat: profileName,
    };

    const newNameProfiles = parseNameFormatting(formattingParams);
    changeProfilesData(newNameProfiles);
    await renameProfileNameMany(newNameProfiles).then(renamedProfiles => {
      message.success(translation('notifications.success.profilesRenamed', { count: renamedProfiles.length }));
    });
  };

  const onCancel = (): void => {
    setNeedParseName(false);
    if (profileName === oldProfileName) {
      setIsEditMode(false);

      return;
    }

    setModalConfirmVisible(true);
  };

  const onConfirm = (): void => {
    setIsEditMode(false);
    setModalConfirmVisible(false);
    setProfileName(selectedTemplate.profileName.rename || DEFAULT_MANY_PROFILE_NAME);
  };

  const iconWithInput = (): JSX.Element => (
    <>
      <IconCheck
        padding={0}
        margin='0 16px'
        iconColor='var(--00A987)'
        onClick={(): Promise<void> => onApply()}
      />
      <IconClose
        padding={0}
        iconColor='var(--98989F)'
        onClick={(): void => onCancel()}
      />
    </>
  );

  const inputInactive = ({ onClick }: ISlotInputInactive): JSX.Element => (
    <InputProfileName
      ref={inputRef}
      value={profileName}
      onChange={(event): void => onAreaChange(event)}
      onKeyPress={(event): void => onKeyPress(event)}
      onClick={onClick}
      autoFocus={true}
    />
  );

  const onClickMixed = (): void => {
    setProfileName(manyProfilesName);
    setOldProfileName(manyProfilesName);
    setIsEditMode(true);
    setIsPopupSpintaxVisible(true);
  };

  const renderContent = (): JSX.Element => {
    if (!isEditMode) {
      return (
        <Mixed
          colorText='var(--2B2B31-profile-new-settings)'
          id={localSettingId + '-param-profile-name'}
          onClick={onClickMixed}
        />
      );
    }

    const propsForRenderOptions: IRenderOptions = {
      profiles: [selectedProfiles[0]],
      nameFormat: profileName,
      translation,
      spintaxList,
      pasteSpintax: (newText: string) => pasteSpintax({ newText, inputElem: inputRef.current, setProfileName }),
    };

    return (
      <InputWithPopover
        popupSlot={(): JSX.Element => renderOptions(propsForRenderOptions)}
        slotInputDecoration={iconWithInput}
        slotInputInactive={inputInactive}
        anchorEl={inputRef.current}
        optionsVisible={isPopupSpintaxVisible}
        setOptionsVisible={setIsPopupSpintaxVisible}
        onCancel={onCancel}
      />
    );
  };

  return (
    <>
      <ContainerParams>
        {renderContent()}
      </ContainerParams>
      <ModalCancelConfirm
        onConfirm={onConfirm}
        onClose={(): void => setModalConfirmVisible(false)}
        visible={modalConfirmVisible}
      />
    </>
  );
};

export default React.memo(MixedProfileName);
