import React from 'react';

const IconAddMembers = () => (
    <svg width="18" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="5" r="3.5" fill="var(--FFFFFF)" stroke="var(--359D73)"/>
    <circle cx="7" cy="5" r="3.5" fill="var(--FFFFFF)" stroke="var(--359D73)"/>
    <path d="M4.5 8.5H9.5C11.1569 8.5 12.5 9.84315 12.5 11.5V14C12.5 14.2761 12.2761 14.5 12 14.5H2C1.72386 14.5 1.5 14.2761 1.5 14V11.5C1.5 9.84315 2.84315 8.5 4.5 8.5Z" fill="var(--FFFFFF)" stroke="var(--359D73)"/>
    <path d="M12.5 8.5H17.5C19.1569 8.5 20.5 9.84315 20.5 11.5V14C20.5 14.2761 20.2761 14.5 20 14.5H10C9.72386 14.5 9.5 14.2761 9.5 14V11.5C9.5 9.84315 10.8431 8.5 12.5 8.5Z" fill="var(--FFFFFF)" stroke="var(--359D73)"/>
    <path d="M8.5 10.5H13.5C15.1569 10.5 16.5 11.8431 16.5 13.5V16C16.5 16.2761 16.2761 16.5 16 16.5H6C5.72386 16.5 5.5 16.2761 5.5 16V13.5C5.5 11.8431 6.84315 10.5 8.5 10.5Z" fill="var(--FFFFFF)" stroke="var(--359D73)"/>
    <circle cx="11" cy="7" r="3.5" fill="var(--FFFFFF)" stroke="var(--359D73)"/>
    </svg>
);

export default IconAddMembers;
