import styled from '@emotion/styled';
import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { SHARE_LINKS_ACTION, SelectRole, SharingSelectRole } from '../../../utils/roles-texts';

const ItemContent = styled('p')`
  font: normal 14px 'Roboto';
  color: var(--222222-members-permissions-select);
  white-space: pre-wrap;
  margin: 2px 0 0 0;
  letter-spacing: normal;
  opacity: 0.5;
  
  strong {
    font-weight: 500;
  }
`;

interface IRoleDescription {
  role: SelectRole|SharingSelectRole|'mySharedAccount'|SHARE_LINKS_ACTION;
  type: 'invite'|'sharing';
}

const RoleDescription: FC<IRoleDescription> = (props) => {
  const { type, role } = props;

  const getDescriptionKey = (): string => {
    switch (true) {
      case type === 'invite' && role === 'admin':
        return 'roles.fullAccess.workspaces.description';
      case type === 'invite' && role === 'editor':
        return 'roles.canEdit.workspaces.description';
      case type === 'invite' && role === 'mySharedAccount':
        return 'workspaceSettingsMembers.popupRemoveMember.text';
      case type === 'sharing' && role === 'administrator':
        return 'roles.fullAccess.share.description';
      case type === 'sharing' && role === 'redactor':
        return 'roles.canEdit.share.description';
      case role === 'guest':
        return 'roles.canRun.description';
      case role === 'pause':
        return 'newShareModal.links.menuPauseDescription';
      case role === 'resume':
        return 'newShareModal.links.menuResumeDescription';
      default:
        return '';
    }
  };

  const key = getDescriptionKey();
  if (!key) {
    return <ItemContent />;
  }

  return (
    <ItemContent>
      <Trans i18nKey={key} />
    </ItemContent>
  );
};

export default RoleDescription;
