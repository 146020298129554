import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from '@emotion/styled';

import IconLogoCircle from '../icons/IconLogoCircle';
import { Icon } from 'antd';
import { jsFonts } from '../../utils/fonts-hash';
import { initGA } from '../../initGA';
import { sendUserEventToServer } from '../../utils/send-analytics-to-server';

const isElectron = !!window.require;

const Container = styled('div')`
  display: flex;
  justify-content: space-around;
  background-color: var(--F5F5F5);
  min-height: 65px;
`;

const Row = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Col = styled('div')`
  display: flex;
  flex-direction: column;
`;

const Item = styled('div')`
  margin-left: 20px;
`;

const CloseContainer = styled('div')`
  margin-left: 20px;
  cursor: pointer;
`;

const TxtApplication = styled('span')`
  color: var(--000000B3);
  font-size: 15px;
  line-height: 18px;
`;
const TxtGoLogin = styled('span')`
  color: var(--000000);
  opacity: 0.8;
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
`;

const ActiveLink = styled('a')`
  background: var(--00AA83);
  border: 0.6px solid var(--00AA83);
  border-radius: 4px;
  font-size: 15px;
  color: var(--FFFFFF);
  padding: 7px 21px;
`;

const Link = styled('a')`
  font-size: 15px;
  color: var(--000000);
`;

declare interface IAppInstaller {
  closeInstaller: () => void;
}

const osLinks = [
  {
    os: 'windows',
    label: 'Windows',
    href: 'https://dl.gologin.com/gologin.exe',
  },
  {
    os: 'mac',
    label: 'Download for Mac OS',
    href: 'https://dl.gologin.com/gologin.dmg',
  },
  {
    os: 'linux',
    label: 'Linux',
    href: 'https://dl.gologin.com/gologin.tar',
  }
];

const AppInstaller: FunctionComponent<IAppInstaller> = ({ closeInstaller }) => {
  const [clientId, setClientId] = useState<string>();
  const [uid, setUid] = useState<string>();

  useEffect(() => {
    setUid(jsFonts());
  }, []);

  const getParams = (): string => {
    const cookies = (document.cookie || '').split(';');
    let utm: string | undefined = '';

    if (cookies.length) {
      utm = cookies.find(elem => elem.includes('utm=')) || '';
    }

    let params = `?googleClientId=${clientId}`;
    if (uid) {
      params += '&uid=' + uid;
    }

    if (utm) {
      params += '&' + uid;
    }

    return params;
  };

  const sendStats = async () => {
    if (isElectron) {
      return;
    }

    const googleClientId = await initGA();
    await sendUserEventToServer('startedDownloadApp', googleClientId);
  }

  useEffect(() => {
    initGA().then(res => setClientId(res));
  }, []);

  const getOsVersion = () => {
    const appVersion = navigator.appVersion;
    let os = 'windows';

    switch (true) {
      case appVersion.includes('Mac'):
        os='mac';
        break;
      case appVersion.includes('X11') || appVersion.includes('Linux'):
        os='linux';
        break;
      default:
    }

    return os;
  }

  return (
    <>
      <Container>
        <Row>
          <IconLogoCircle />
          <Item>
            <Col>
              <TxtApplication>Application</TxtApplication>
              <TxtGoLogin>GoLogin</TxtGoLogin>
            </Col>
          </Item>
          {osLinks.map((item) => {
            const os = getOsVersion();
            if (os === item.os) {
              return (
                <Item
                  key={item.os}
                >
                  <ActiveLink
                    onClick={sendStats}
                    target='_blank'
                    rel='nofollow'
                    href={item.href + `${getParams()}`}
                  >
                    {item.label}
                  </ActiveLink>
                </Item>
              );
            }

            return (
              <Item
                key={item.os}
              >
                <Link
                  onClick={sendStats}
                  target='_blank'
                  rel='nofollow'
                  href={item.href + `${getParams()}`}
                >
                  {item.label}
                </Link>
              </Item>
            );
          })}
          <CloseContainer onClick={closeInstaller}>
            <Icon type='close-circle' />
          </CloseContainer>
        </Row>
      </Container>
    </>
  )
};

export default AppInstaller;
