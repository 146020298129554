import { getDefaultStore, atom, useAtomValue } from 'jotai';

const isMoreProfilesAvailableAtom = atom<boolean>(true);

export const useIsMoreProfilesAvailable = (): boolean => useAtomValue(isMoreProfilesAvailableAtom);

export const setIsMoreProfilesAvailable = (isMoreProfilesAvailable: boolean): void =>
  getDefaultStore().set(isMoreProfilesAvailableAtom, isMoreProfilesAvailable);

export const getIsMoreProfilesAvailable = (): boolean => getDefaultStore().get(isMoreProfilesAvailableAtom);
