import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { workspaceContext } from '../../../../state';
import GologinModal from '../../../../ui/gologin-modal';
import { logout } from '../../../../utils/logout';
import { CancelButton, ConfirmButton } from '../transfer-profile/styles';

interface ILogoutNotificationModalProps {
  isLogoutModalVisible: boolean;
  setIsLogoutModalVisible: (arg: boolean) => void;
}

export const LogoutNotificationModal: FC<ILogoutNotificationModalProps> = (props) => {
  const { isLogoutModalVisible, setIsLogoutModalVisible } = props;

  const workspaceCtx = useContext(workspaceContext);

  const { t: translation } = useTranslation();

  const footer = (): JSX.Element => (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <ConfirmButton onClick={(): void => setIsLogoutModalVisible(false)}>
        {translation('logOutModal.button.cancel')}
      </ConfirmButton>
      <CancelButton style={{ marginLeft: 8 }} onClick={(): Promise<void> => logout(workspaceCtx)}>
        {translation('logOutModal.button.ok')}
      </CancelButton>
    </div>
  );

  return (
    <GologinModal
      title={translation('logOutModal.title.text')}
      isVisible={isLogoutModalVisible}
      onOk={(): Promise<void> => logout(workspaceCtx)}
      okText={translation('logOutModal.button.ok')}
      cancelText={translation('logOutModal.button.cancel')}
      onClose={(): void => setIsLogoutModalVisible(false)}
      footer={footer()}
      width='520px'
    >
      <span style={{ fontSize: 14, fontWeight: 400 }}>
        {translation('logOutModal.subtitle.text')}
      </span>
    </GologinModal>
  );
};
