import styled from '@emotion/styled';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { Select } from 'antd';
import { ExpandMore } from '@material-ui/icons';
import React from 'react';

export const SelectButtonContainer = styled(Button)<{ width?: string }>`
  width: ${(props): string => props.width || '350px'};
  height: 26px;

  .MuiButton-label {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: var(--000000);
    letter-spacing: 0.25px;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &.MuiButton-root {
    background-color: var(--FFFFFF);
    border: 1px solid var(--CDCDCD);
    border-radius: 4px;
    text-transform: none;
  }

  &.MuiButton-root.Mui-disabled {
    background: var(--F5F5F5);
  }
`;

export const CustomMenu = styled(Menu)<{ width?: string }>`
  .MuiPopover-paper {
    border-radius: 8px;
    width: ${(props): string => props.width || '350px'};
    margin-top: 6px;
    max-height: 180px;
  }
`;

export const SelectResolutionMenuItem = styled(MenuItem)<{ isSelected?: boolean }>`
  &.MuiMenuItem-root {
    padding-top: 4px;
    padding-bottom: 4px;
    line-height: 20px;
    letter-spacing: 0.25px;
    font-size: 14px;
    font-weight: ${(props): string => props.isSelected ? '500' : '400'};
  }
`;

export const SelectAdvanced = styled(Select)<{ width?: string }>`
  width: ${(props): string => props.width || '350px'};
  @media screen and (max-width: 450px) {
    width: 100%;
  }
`;

interface IArrowDown {
  htmlColor: string;
  style: { width: number; height: number };
}
export const ArrowDown = (props: IArrowDown): JSX.Element => <ExpandMore {...props} />;

ArrowDown.defaultProps = {
  htmlColor: 'var(--00000066)',
  style: { width: 16, height: 16 },
};
