import styled from '@emotion/styled';

export const ProfileNameCell = styled('div')`
  display: flex;
  align-items: center;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const ProfileNameColumn = styled('span')`
  margin-left: 4px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: var(--222222);
`;

export const EmptyTableText = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: var(--B5B5BA-profile-table);
`;

export const LoaderDiv = styled.div`
  width: 100%;
  text-align: center;
`;
