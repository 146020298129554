import { useTranslate } from '@tolgee/react';
import React, { FC } from 'react';

import { PlanCellContainer, IconContainer, PlanFooter, PlanHeader, PlanCellStyled } from './styles';
import { IPlan } from '../../../../interfaces/plan';
import IconTableCheck from '../../../../ui/icons/IconTableCheck';
import IconTableCross from '../../../../ui/icons/IconTableCross';
import { IRow } from '../../constants';
import { IMappedPlan } from '../../interfaces';
import { PopularPlanTextAbsolute } from '../domElements';
import SwitchPlanButton from '../switch-plan-button';

interface IPlanCell {
  row: IRow;
  tablePlan: IMappedPlan;
  selectedDiscount: string;
  getPlanInfo: (planId: string, field: keyof IPlan) => JSX.Element | string;
  onPlanSelect: (id: string, isUpgrade?: boolean) => Promise<void>;
}

const ZERO_VALUE = 0;

const PlanCell: FC<IPlanCell> = (props) => {
  const { row, tablePlan, selectedDiscount, getPlanInfo, onPlanSelect } = props;
  const { id, type } = row;
  const { name, background, isPopular, buttonStyle } = tablePlan;

  const { t: translation } = useTranslate();

  const getPlanCellContent = (): JSX.Element => {
    if (type === 'header') {
      return (
        <PlanHeader backgroundColor={background}>
          {isPopular ? (
            <PopularPlanTextAbsolute>
              {translation('pricing.popularPlan.text')}
            </PopularPlanTextAbsolute>
          ) : null}
          <span>
            {name}
          </span>
          <SwitchPlanButton
            buttonStyle={buttonStyle}
            columnPlanId={tablePlan.id}
            selectedDiscount={selectedDiscount}
            onPlanSelect={onPlanSelect}
            getPlanInfo={getPlanInfo}
            isSmallButton={true}
            isShortText={true}
          />
        </PlanHeader>
      );
    }

    if (type === 'footer') {
      return (
        <PlanFooter backgroundColor={background}>
          <SwitchPlanButton
            buttonStyle={buttonStyle}
            columnPlanId={tablePlan.id}
            selectedDiscount={selectedDiscount}
            onPlanSelect={onPlanSelect}
            getPlanInfo={getPlanInfo}
            isSmallButton={true}
            isShortText={true}
          />
        </PlanFooter>
      );
    }

    if (type === 'value' && tablePlan[id] !== ZERO_VALUE) {
      return (
        <PlanCellStyled backgroundColor={background}>
          <span>
            {tablePlan[id]}
          </span>
        </PlanCellStyled>
      );
    }

    return (
      <PlanCellStyled backgroundColor={background}>
        <IconContainer>
          {type === 'boolean' && tablePlan[id] ? <IconTableCheck /> : <IconTableCross />}
        </IconContainer>
      </PlanCellStyled>
    );
  };

  return (
    <PlanCellContainer backgroundColor={background} rowInfo={row}>
      {getPlanCellContent()}
    </PlanCellContainer>
  );
};

export default PlanCell;
