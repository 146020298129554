export const ARRAY_OF_ALL_PROPERTIES = [
  '_id', 'name', 'notes', 'proxy', 'os', 'osSpec', 'isM1', 'timezone.enabled', 'timezone.fillBasedOnIp', 'timezone.timezone', 'geolocation.accuracy',
  'geolocation.customize', 'geolocation.enabled', 'geolocation.fillBasedOnIp', 'geolocation.latitude', 'geolocation.longitude', 'geolocation.mode',
  'cookies', 'webGLMetadata.renderer', 'webGLMetadata.vendor', 'proxy.autoProxyRegion', 'proxy.torProxyRegion', 'audioContext.mode',
  'audioContext.noise', 'browserType', 'canvas.mode', 'canvas.noise', 'devicePixelRatio', 'dns', 'extensions.enabled', 'extensions.preloadCustom',
  'fonts.enableDomRect', 'fonts.enableMasking', 'fonts.families', 'googleServicesEnabled', 'lockEnabled', 'autoLang', 'mediaDevices.audioInputs',
  'mediaDevices.audioOutputs', 'mediaDevices.enableMasking', 'mediaDevices.uid',
  'mediaDevices.videoInputs', 'navigator.deviceMemory', 'navigator.language', 'navigator.maxTouchPoints', 'navigator.platform', 'bookmarks',
  'isBookmarksSynced', 'navigator.resolution', 'navigator.userAgent', 'plugins.enableFlash',
  'plugins.enableVulnerable', 'proxyEnabled', 'startUrl', 'storage.bookmarks', 'storage.extensions', 'storage.history', 'storage.local',
  'webGLMetadata.mode','webRTC.enable', 'webRTC.isEmptyIceList', 'storage.passwords', 'storage.session',
  'storage.enableExternalExtensions', 'webGL.getClientRectsNoise', 'webGL.mode', 'webGL.noise',
  'webRTC.enabled', 'webRTC.fillBasedOnIp', 'webRTC.localIpMasking', 'webRTC.localIps', 'webRTC.mode',
  'webRTC.publicIp', 'webglParams.antialiasing',
  'webglParams.extensions', 'webglParams.glCanvas', 'webglParams.glParamValues', 'webglParams.shaiderPrecisionFormat',
  'webglParams.supportedFunctions','webglParams.textureMaxAnisotropyExt', 'navigator.hardwareConcurrency', 'bookmarks', 'isBookmarksSynced',
  'webGLMetadata.mode', 'webRTC.customize',
];
