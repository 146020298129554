import { keyframes } from '@emotion/core';
import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled/macro';

import { textFont } from '../../../../../ui/style-templates';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const QuickSettingsButtonWrapper = styled('div')<{ newStyle: boolean; newDrawer: boolean }>`
  height: 100%;
  border-radius: 4px;

  visibility: hidden;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  position: absolute;
  right: 0;
  padding-left: ${(props): string => props.newDrawer ? '8px' : '0'};
  padding-right: 0;
  width: 68px;
  z-index: 99;

  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: var(--98989F-profile-table);
  background-color: var(--F9F9F9-profile-table-profile-settings-button);

  white-space: nowrap;
  overflow: hidden;
  text-align: end;
  vertical-align: middle;

  cursor: pointer;
  animation: ${fadeIn} 0.2s ease-in-out;

  ${(props): SerializedStyles|null => props.newDrawer ? css`
    width: auto;
  ` : null}

  ${(props): SerializedStyles|null => props.newStyle ? css`
    ${textFont()};
  ` : null}
  
  &:hover {
    color: var(--222222);
  }
`;

export const Text = styled('span')``;

export const IconWrapper = styled('div')`
  margin-left: 6px;

  svg {
    display: flex;
    align-items: center;
  }
`;
