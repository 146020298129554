import { useState, useCallback } from 'react';

import { LOCAL_STORAGE_SELECTED_FOLDER } from '../../common/constants/local-storage';
import { IFolder } from '../interfaces';
import { IUser, IUserCtx, USER_DEFAULT_VALUE } from '../state';
import { toggleNewUserFeature } from '../state/feature-toggle/new-features';
import { setFoldersList, useFoldersList } from '../state/folders/folders-list.atom';
import { setSelectedFolderId } from '../state/selected-folder.atom';

export const useUser = (): IUserCtx => {
  const folders = useFoldersList();

  const [user, setUser] = useState<IUser>(USER_DEFAULT_VALUE);

  const updateUser = useCallback((userInfo: Partial<IUser>): void => {
    if (userInfo?.newFeatures) {
      toggleNewUserFeature(userInfo.newFeatures);
    }

    setUser(prev => ({
      ...prev,
      ...userInfo,
    }));
  }, []);

  const updateFolders = useCallback((newFolders: IFolder[]): void => {
    setFoldersList(newFolders);
  }, []);

  const resetSelectedFolder = useCallback((): void => {
    const systemFolder = '';
    localStorage.setItem(LOCAL_STORAGE_SELECTED_FOLDER, systemFolder);
    updateSelectedFolder(systemFolder);
    setSelectedFolderId(null);
  }, []);

  const updateSelectedFolder = useCallback((folderName: string): void => {
    setUser(prev => ({
      ...prev,
      selectedFolder: folderName,
    }));
  }, [user]);

  return {
    ...user,
    folders,
    updateUser,
    updateFolders,
    updateSelectedFolder,
    resetSelectedFolder,
  };
};
