import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ContainerRadio,
  ErrorText,
  RadioButton,
  RadioGroup,
} from './styles';
import { TransferConfirm } from './transfer-confirm';
import { userContext, workspaceContext } from '../../../../state';
import LabeledElement from '../../../../ui/LabeledElement';
import GologinModal from '../../../../ui/gologin-modal';
import { GreenSubmitButton } from '../../../../ui/modern-modal';
import { GologinInput } from '../../../../ui/styles/input';
import { SelectWorkspace } from '../../../../ui/workspaceSelect';
import { isEmailValid } from '../../../../utils/email-validator';
import { sendActionAnalytics } from '../../../common/api';
import { ITransferParams } from '../../../quickProfiles/components/interfaces/transfer-params.interface';

interface ITransferProfileModal {
  visible: boolean;
  onClose: () => void;
  onTransfer: (transferParams: Omit<ITransferParams, 'profilesIds'>) => void;
  profilesIds: string[];
}

const TransferProfileModal: React.FC<ITransferProfileModal> = (props) => {
  const { visible, onClose, onTransfer, profilesIds } = props;

  const workspaceCtx = useContext(workspaceContext);
  const {
    _id: userId,
  } = useContext(userContext);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isTransferConfirmVisible, setIsTransferConfirmVisible] = useState(false);
  const [targetEmail, setTargetEmail] = useState<string>('');
  const [emailErrorText, setEmailErrorText] = useState<string>('');
  const [transferType, setTransferType] = useState<'transfer' | 'transferToMyWorkspace'>('transfer');
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState<string>(workspaceCtx.id);
  const [selectedFolder, setSelectedFolder] = useState<string>('');

  const { t: translation } = useTranslation();

  useEffect(() => {
    getFolders();
  }, []);

  useEffect(() => {
    if (visible) {
      sendActionAnalytics('visited transfer profile');
    }
  }, [visible]);

  const getFolders = async (): Promise<void> => {
    await workspaceCtx.updateAvailableWorkspacesFolders();
    setIsLoading(false);
  };

  const sendTransfer = async (): Promise<void> => {
    await onTransfer({
      targetWorkspaceId: selectedWorkspaceId || '',
      selectedFolder,
      email: targetEmail,
      transferType,
      workspace: workspaceCtx,
    });

    setTargetEmail('');
    setEmailErrorText('');
    setTransferType('transfer');
    setSelectedWorkspaceId('');
    setSelectedFolder('');
  };

  const onClickTransfer = (): void => {
    if (transferType !== 'transfer') {
      sendTransfer();

      return;
    }

    if (!isEmailValid(targetEmail)) {
      setEmailErrorText(translation('errorMessages.enterValidEmail') || '');

      return;
    }

    setIsTransferConfirmVisible(true);
  };

  const renderForm = (): JSX.Element => {
    if (transferType === 'transferToMyWorkspace') {
      return (
        <LabeledElement title={translation('transferModal.selectWorkspace')}>
          <SelectWorkspace
            userId={userId || ''}
            selectedWorkspaceId={selectedWorkspaceId || workspaceCtx.id}
            setSelectedWorkspaceId={setSelectedWorkspaceId}
            selectedFolder={selectedFolder}
            setSelectedFolder={setSelectedFolder}
            isOwnerOnly={false}
            placeholder={translation('transferModal.selectWorkspace')}
            needFoldersList={true}
            isLoading={isLoading}
          />
        </LabeledElement>
      );
    }

    return (
      <div>
        <ErrorText>
          {emailErrorText || (
            <>
              &nbsp;
            </>
          )}
        </ErrorText>
        <GologinInput
          placeholder={translation('transferModal.emailInputPlaceholder') || ''}
          value={targetEmail}
          onChange={(event): void => setTargetEmail(event.target.value.trim())}
        />
      </div>
    );
  };

  const renderModalTitle = (): string => {
    const profilesCount = profilesIds.length;
    if (profilesCount > 1) {
      return translation('transferModals.titleFirstModal.text', { count: profilesCount });
    }

    return translation('transferModal.titleSingle');
  };

  return (
    <>
      <GologinModal
        isVisible={visible}
        onClose={onClose}
        width='380px'
        title={renderModalTitle()}
      >
        <ContainerRadio>
          <RadioGroup
            value={transferType}
            size='default'
            defaultValue='transfer'
            onChange={(event): void => setTransferType(event.target.value)}
          >
            <RadioButton value='transfer'>
              {translation('transferModal.newOwner')}
            </RadioButton>
            <RadioButton value='transferToMyWorkspace'>
              {translation('transferModal.myWorkspace')}
            </RadioButton>
          </RadioGroup>
        </ContainerRadio>
        {renderForm()}
        <GreenSubmitButton
          style={{ margin: '36px auto 0' }}
          onClick={onClickTransfer}
        >
          {translation('transferModal.transferButton')}
        </GreenSubmitButton>
      </GologinModal>
      <TransferConfirm
        sendTransfer={sendTransfer}
        onClose={(): void => setIsTransferConfirmVisible(false)}
        isVisible={isTransferConfirmVisible}
        profilesCount={profilesIds.length}
      />
    </>
  );
};

export default TransferProfileModal;
