import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled/macro';

export const UnlinkButtonWrapper = styled.div<{ isProxyManagerWithGroups: boolean }>`
  display: flex;
  align-items: flex-start;

  ${(props): SerializedStyles => props.isProxyManagerWithGroups ? css`
    width: 16px;
    height: 16px;
  ` : css`
    width: 18px;
    height: 18px;
  `}

  :hover {
    ${(props): SerializedStyles | null => props.isProxyManagerWithGroups ? null : css`
      color: var(--00A987-proxy-manager);
    `}
  }
`;

export const UnlinkIconWrapper = styled.div<{ isProxyManagerWithGroups: boolean }>`
  padding: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  ${(props): SerializedStyles => props.isProxyManagerWithGroups ? css`
    width: 16px;
    height: 16px;
  ` : css`
    width: 18px;
    height: 18px;

    & > svg {
      width: 18px;
      height: 18px;
    }
  `}
`;
