import * as Sentry from '@sentry/react';

import { UserActionKey } from './performance-observer/interfaces';
import {
  SENTRY_IS_DEVELOPMENT,
  START_APP_TO_LOAD_PROFILES_TABLE,
  START_APP_TO_LOAD_SIGN_UP_PAGE,
  STATE_USING_SENTRY_KEY_NAME,
} from '../../common/constants/constants';
import { PLAN_IDS } from '../features/pricing/constants';
import {
  LOAD_PROXY_TRANSACTION,
  LOAD_PROXIES_PAGES_SPAN,
  LOAD_PROXIES_SHARED_AND_FREE_SPAN,
} from '../features/proxy/constants';
import { IWorkspace } from '../interfaces/workspaces';
import { IUser } from '../state';
import { GLOBAL_MODAL_ACTION } from '../state/global-modal';
import { MODAL_USER_ACTIONS } from '../state/profiles-table-modal.atom';
import { E_SENTRY_ERRORS } from '../utils/sentry-messages';

interface CustomError extends Error {
  isHandled?: boolean;
}

let ipcRenderer: Electron.IpcRenderer;
const isElectron = !!window.require;
if (isElectron) {
  ({ ipcRenderer } = window.require('electron'));
}

let appVersion = '';
const sentryClosingTimeout = 10000;
const SENTRY_DSN = 'https://a32517a4990385f6cfc89c7827828aa7@sentry.amzn.pro/2';

const tooManyRequestsErrorRate = 0.3;
const tooManyRequestsErrorName = 'too-many-requests-error';

const USER_ACTIONS_TRANSACTIONS: UserActionKey[] = [
  'open-profile-settings',
  'open-tags-popup',
  'change-selected-profiles',
  'open-folders-modal',
  ...MODAL_USER_ACTIONS,
  ...GLOBAL_MODAL_ACTION,
  'open-proxy-manager',
  'open-account-menu',
  'start-name-column-edit',
  'start-notes-column-edit',
  'open-profile-settings-timezone-select',
  'select-profile-settings-timezone',
  'open-profile-settings-resolution-select',
  'select-profile-settings-resolution',
  'select-proxy-manager-proxy',
];

const PROXY_TRANSACTIONS = [LOAD_PROXY_TRANSACTION, LOAD_PROXIES_PAGES_SPAN, LOAD_PROXIES_SHARED_AND_FREE_SPAN];
const APP_START_TRANSACTIONS = [START_APP_TO_LOAD_PROFILES_TABLE, START_APP_TO_LOAD_SIGN_UP_PAGE];

let sentryReplayInstance: Sentry.Replay;

const getTransactionSampleRateByName = (transactionName: string): number => {
  if (['get-workspace-profile-list', 'load-pricing-page'].includes(transactionName)) {
    return 1.0;
  }

  if (['create-quick-profile'].includes(transactionName)) {
    return SENTRY_IS_DEVELOPMENT ? 1 : 0.02;
  }

  if (['get-user'].includes(transactionName)) {
    return SENTRY_IS_DEVELOPMENT ? 1 : 0.5;
  }

  if (['load-profiles-table', ...PROXY_TRANSACTIONS, ...APP_START_TRANSACTIONS].includes(transactionName)) {
    return SENTRY_IS_DEVELOPMENT ? 1 : 0.1;
  }

  if ((USER_ACTIONS_TRANSACTIONS as string[]).includes(transactionName)) {
    return SENTRY_IS_DEVELOPMENT ? 1 : 0.1;
  }

  if (['run-profile', 'prepare-mass-run', 'run-profile-full'].includes(transactionName)) {
    return SENTRY_IS_DEVELOPMENT ? 1 : 0.01;
  }

  return 0;
};

const getReactSentryOptions = (release: string): Sentry.BrowserOptions => ({
  dsn: SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing(),
    sentryReplayInstance,
  ],
  environment: SENTRY_IS_DEVELOPMENT ? 'development' : 'production',
  release,
  replaysSessionSampleRate: 1.0,
  tracesSampler: (samplingContext): number | boolean => {
    const { name: transactionName } = samplingContext.transactionContext;

    return getTransactionSampleRateByName(transactionName);
  },
  beforeSend: (event, hint) => {
    const { isHandled = false } = hint.originalException as CustomError || {};
    if (isHandled) {
      return null;
    }

    if (event.transaction === tooManyRequestsErrorName && Math.random() > tooManyRequestsErrorRate) {
      return null;
    }

    if (!event.transaction) {
      const [exceptions] = event.exception?.values || [];
      const { value = E_SENTRY_ERRORS.UNKNOWN_ERROR } = exceptions || {};
      event.transaction = value;
      event.fingerprint = [value];
    }

    return event;
  },
});

const setIsUsingSentry = (newIsUsingSentry: boolean): void => {
  localStorage.setItem(STATE_USING_SENTRY_KEY_NAME, `${newIsUsingSentry}`);

  if (isElectron) {
    ipcRenderer && ipcRenderer.invoke('set-is-using-sentry', { isUsingSentry: newIsUsingSentry });
  }
};

export const beginUsingSentryAndStartReplay = (): void => {
  const replay = sentryReplayInstance.getReplayId();
  setIsUsingSentry(true);

  if (!replay) {
    reinitReactSentry();
  }
};

export const checkConditionUsingSentry = (userData: IUser, workspace: IWorkspace): void => {
  const { isCorrectlyRegistered = false } = userData || {};
  const { paymentIsTrial = true, planId = '' } = workspace || {};

  const checkIsUsingSentry = (): boolean => {
    if (planId.toString() === PLAN_IDS.ForeverFree) {
      return true;
    }

    if (!paymentIsTrial) {
      return true;
    }

    return !!isCorrectlyRegistered;
  };

  const isUsingSentry = checkIsUsingSentry();
  setIsUsingSentry(isUsingSentry);
  if (isUsingSentry) {
    const replay = sentryReplayInstance.getReplayId();
    if (!replay) {
      reinitReactSentry();
    }

    return;
  }

  Sentry.close(sentryClosingTimeout);
  sentryReplayInstance.stop();
};

const reinitReactSentry = (): void => {
  Sentry.close(sentryClosingTimeout).then(() => {
    const reactSentryOptions = getReactSentryOptions(appVersion);
    Sentry.init(reactSentryOptions);
    sentryReplayInstance.start();
  });
};

const initSentry = (rawAppVersion?: string): void => {
  setIsUsingSentry(true);
  appVersion = rawAppVersion ? rawAppVersion.split(' ')[0] : 'site';
  sentryReplayInstance = new Sentry.Replay({
    blockAllMedia: false,
    unmask: ['.sentry-unmask, [data-sentry-unmask]'],
  });
  const reactSentryOptions = getReactSentryOptions(appVersion);
  Sentry.init(reactSentryOptions);
};

export default initSentry;
