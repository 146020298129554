import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IOrbitaModalBaseFooter } from '../../interfaces';

const ModalFooter: React.FC<IOrbitaModalBaseFooter> = (props) => {
  const { primaryButton, secondaryButton } = props;
  const { t: translation } = useTranslation();

  return (
    <div style={{ marginTop: '-10px' }}>
      {secondaryButton ? (
        <Button
          disabled={secondaryButton.isDisabled}
          onClick={secondaryButton.handleClick}
          style={{ marginTop: '10px' }}
        >
          {translation(secondaryButton.key)}
        </Button>
      ) : null}
      <Button
        type='primary'
        style={{ marginTop: 10, marginLeft: 8 }}
        disabled={primaryButton.isDisabled}
        onClick={primaryButton.handleClick}
      >
        {translation(primaryButton.key)}
      </Button>
    </div>
  );
};

export default ModalFooter;
