import { IProfile } from '../../../interfaces';
import { getMajorVersionByUa } from '../../common/orbita-browser';
import { IGenerateBrowserIconsData } from '../interfaces';
import { getSystemDataForIcons } from './';
import { formatOs } from './format-os';
import { getIconDesignVersion } from './get-icon-design-version';

export const getParamsToGenerateIconsMassOps = ({ id, name, navigator }: IProfile): IGenerateBrowserIconsData => {
  const { osPlatform, winVersionMajor, scaleFactor, browsersLocal, browsersVersionsFull } = getSystemDataForIcons();
  const osFormatted = formatOs(osPlatform, winVersionMajor);

  const majorVersionByUa = +getMajorVersionByUa(navigator.userAgent);
  const browsersDownloaded = browsersLocal.split(',').map(Number);
  const isBrowserWithMajorVersionDownloaded = browsersDownloaded.includes(majorVersionByUa);

  // In mass operations, we run only profiles with user-agent browser versions that are downloaded.
  // Therefore, we need no icons' drawing if a browser is absent.
  const iconDesignVersion = isBrowserWithMajorVersionDownloaded ? getIconDesignVersion(osFormatted, majorVersionByUa, browsersVersionsFull) : null;
  const browserIconsData: IGenerateBrowserIconsData = { profileId: id, profileName: name, osFormatted, scaleFactor, iconDesignVersion };

  return browserIconsData;
};
