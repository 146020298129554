import React from 'react';
import styled from '@emotion/styled';

const IconContainer = styled('svg')`
  animation: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes spin {
    from {
      transform:rotate(0deg);
    }
    to {
      transform:rotate(360deg);
    }
  }
`;

const IconSpinnerAutosave = () => (
  <IconContainer width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 12C2 6.5 6.5 2 12 2C15.9 2 19.3 4.2 20.9 7.5" stroke="var(--767676)" strokeMiterlimit="10"/>
    <path d="M22.0001 12C22.0001 17.5 17.5001 22 12.0001 22C8.1001 22 4.7001 19.8 3.1001 16.5" stroke="var(--767676)" strokeMiterlimit="10"/>
    <path d="M21.8 1.69995L21 7.59995L15 6.79995" stroke="var(--767676)" strokeMiterlimit="10" strokeLinecap="square"/>
    <path d="M2.19995 22.2999L2.99995 16.3999L8.99995 17.1999" stroke="var(--767676)" strokeMiterlimit="10" strokeLinecap="square"/>
  </IconContainer>
);

export default IconSpinnerAutosave;
