import React from 'react';

import {
  ORBITA_INCOMPATIBLE_SELECT_MODAL_NAME,
  useGlobalModalIsVisible,
} from '../../../../../../state/global-modal';
import { IOrbitaModalBase, IOrbitaModalWrapper } from '../../interfaces';
import ModalBase from '../../modal-base';

interface IOrbitaIncompatibleSelectModal extends IOrbitaModalWrapper {
  handleClose: () => void;
}

const IncompatibleSelectModal: React.FC<IOrbitaIncompatibleSelectModal> = (props) => {
  const { handleSubmit, handleCancel, handleClose } = props;

  const isModalVisible = useGlobalModalIsVisible(ORBITA_INCOMPATIBLE_SELECT_MODAL_NAME);

  const keyBase = 'modals.orbitaIncompatibleSelectModal.';

  const modalBaseProps: IOrbitaModalBase = {
    visible: isModalVisible,
    handleCancel: handleClose,
    titleKey: keyBase + 'title',
    body: {
      key: keyBase + 'body',
    },
    primaryButton: {
      key: keyBase + 'submit',
      isDisabled: false,
      handleClick: handleSubmit,
    },
    secondaryButton: {
      key: keyBase + 'cancel',
      isDisabled: false,
      handleClick: handleCancel,
    },
  };

  return <ModalBase {...modalBaseProps} />;
};

export default IncompatibleSelectModal;
