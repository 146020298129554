import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IWorkspaceShort } from '../../interfaces/workspaces';
import { ScrollbarContainer } from '../scrollbar';
import {
  FolderNameContainer,
  FolderNameText,
  FolderOutlinedCustom,
  FoldersListPopoverCustom,
  RowFolderContainer,
  WorkspaceSelectedCheck,
  TooltipCustom,
  ErrorTooltipTitleWorkspace,
  tooltipTitleName,
} from './styles';

interface IMenuSelectFolder {
  selectedFolder: string;
  anchorElFolders: null | HTMLElement;
  onCloseFoldersList: () => void;
  workspace: IWorkspaceShort;
  onSelect: (folderName: string) => void;
}

export const MenuSelectFolder: FC<IMenuSelectFolder> = (props) => {
  const { selectedFolder, anchorElFolders, onCloseFoldersList, workspace, onSelect } = props;
  const { folders: workspaceFolders = [], permissions: workspacePermissions } = workspace;

  const { t: translation } = useTranslation();

  const noFolder = { name: '', hasPermission: workspacePermissions.canClaimProfilesWithoutFolder };
  const foldersWithNoFolder = [noFolder].concat(workspaceFolders.map(folder => ({
    name: folder.name,
    hasPermission: folder.permissions.canClaimProfiles,
  })));

  const FolderEntry = ({ name, hasPermission }: { name: string; hasPermission: boolean }) => (
    <TooltipCustom
      title={<ErrorTooltipTitleWorkspace name={name || workspace.name} type={name ? 'folder' : 'workspace'} />}
      placement='top'
      disableHoverListener={hasPermission}
    >
      <RowFolderContainer
        isDisabled={!hasPermission}
        onClick={() => hasPermission && onSelect(name)}
      >
        <FolderNameContainer>
          {name ? <FolderOutlinedCustom viewBox={'0 0 38 24'} /> : null}
          <TooltipCustom title={tooltipTitleName(name)} placement='top' disableHoverListener={!name || !hasPermission}>
            <FolderNameText>
              {name || translation('selectWorkspaceMenu.noFolder')}
            </FolderNameText>
          </TooltipCustom>
          {selectedFolder === name ? <WorkspaceSelectedCheck htmlColor={'var(--00A987-select-workspace)'} viewBox='0 0 25 25' /> : null}
        </FolderNameContainer>
      </RowFolderContainer>
    </TooltipCustom>
  );

  return (
    <FoldersListPopoverCustom
      open={!!anchorElFolders}
      anchorEl={anchorElFolders}
      onClose={onCloseFoldersList}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <ScrollbarContainer style={{ padding: '4px 0', overflowX: 'hidden', maxHeight: 166 }}>
        {foldersWithNoFolder.map(({ name, hasPermission }) => (
          <FolderEntry key={name} name={name} hasPermission={hasPermission} />
        ))}
      </ScrollbarContainer>
    </FoldersListPopoverCustom>
  );
};
