import styled from '@emotion/styled';

export const Title = styled.h1`
  margin: 0;

  font-family: 'Graphik LC', Roboto;
  font-size: 38px;
  font-weight: 700;
  line-height: 45.6px;
  text-align: center;
  color: var(--FFFFFF-auth-pages-text);
`;
