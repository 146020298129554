import { sendAppStartTransactionToSentry } from './app-start.transaction.utils';

const isElectron = !!window.require;
let ipcRenderer: Electron.IpcRenderer;
if (isElectron) {
  ({ ipcRenderer } = window.require('electron'));
}

export const reactLoadingCompletedHandler = (): void => {
  ipcRenderer && ipcRenderer.invoke('download-all-browser-fonts').catch(() => null);

  sendAppStartTransactionToSentry();
};
