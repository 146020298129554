import { atom, getDefaultStore, useAtomValue } from 'jotai';

export const isSelectProxyModeOpenedAtom = atom(false);
const confirmBlockVisibleAtom = atom(false);

export const getIsSelectProxyModeOpened = (): boolean => getDefaultStore().get(isSelectProxyModeOpenedAtom);
const setIsSelectProxyModeOpened = (value: boolean): void => getDefaultStore().set(isSelectProxyModeOpenedAtom, value);
const setConfirmBlockVisible = (value: boolean): void => getDefaultStore().set(confirmBlockVisibleAtom, value);

export const useIsSelectProxyModeOpened = (): boolean => useAtomValue(isSelectProxyModeOpenedAtom);

export const useConfirmBlockVisible = (): boolean => useAtomValue(confirmBlockVisibleAtom);

export const switchIsSelectProxyModeOpened = (value: boolean): void => {
  setIsSelectProxyModeOpened(value);
};

export const switchConfirmBlockVisible = (value: boolean): void => {
  setConfirmBlockVisible(value);
};
