import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { DivAnchor } from '../../types';

interface IPositionProxyContextMenuStatus {
  left: number;
  top: number;
}

const proxyContextMenuProxyAtom = atom<string>('');
const proxyContextMenuPositionAtom = atom<IPositionProxyContextMenuStatus|null>(null);
const proxyContextMenuTargetRefAtom = atom<React.MutableRefObject<DivAnchor> | null>(null);

const setProxyContextMenuProxy = (proxyId: string): void => getDefaultStore().set(proxyContextMenuProxyAtom, proxyId);
const setProxyContextMenuPosition = (value: IPositionProxyContextMenuStatus): void => getDefaultStore().set(proxyContextMenuPositionAtom, value);
const setProxyContextMenuTargetRef = (ref: React.MutableRefObject<DivAnchor> | null): void =>
  getDefaultStore().set(proxyContextMenuTargetRefAtom, ref);

export const useProxyContextMenuProxy = (): string => useAtomValue(proxyContextMenuProxyAtom);
export const useProxyContextMenuPosition = (): IPositionProxyContextMenuStatus|null => useAtomValue(proxyContextMenuPositionAtom);
export const useProxyContextMenuTargetRef = (): React.MutableRefObject<DivAnchor> | null => useAtomValue(proxyContextMenuTargetRefAtom);

export const openProxyContextMenu = (proxyId: string): void => {
  setProxyContextMenuProxy(proxyId);
};

export const hideProxyContextMenu = (): void => {
  setProxyContextMenuProxy('');
  updateProxyContextMenuTargetRef(null);
};

export const updateProxyContextMenuPosition = (value: IPositionProxyContextMenuStatus): void => {
  setProxyContextMenuPosition(value);
};

export const updateProxyContextMenuTargetRef = (ref: React.MutableRefObject<DivAnchor> | null): void => {
  setProxyContextMenuTargetRef(ref);
};
