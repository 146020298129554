import { useCallback, useState } from 'react';

import { ITemplatesCtx, ITemplate, TEMPLATE_DEFAULT_VALUE } from '../../state';

export const useTemplate = (): ITemplatesCtx => {
  const [selectedTemplate, setSelectedTemplate] = useState<ITemplate>(TEMPLATE_DEFAULT_VALUE);
  const [availableTemplates, setAvailableTemplates] = useState<ITemplate[]>([TEMPLATE_DEFAULT_VALUE]);

  const updateSelectedTemplate = useCallback((template: ITemplate) => {
    const newSelectedTemplate: ITemplate = {
      ...TEMPLATE_DEFAULT_VALUE,
      ...template,
      profileName: {
        ...TEMPLATE_DEFAULT_VALUE.profileName,
        ...template.profileName,
      },
      browser: {
        ...TEMPLATE_DEFAULT_VALUE.browser,
        ...template.browser,
      },
    };

    setSelectedTemplate(newSelectedTemplate);
    setAvailableTemplates(prev => [...prev.filter(temp => temp.id && temp.id !== template.id), newSelectedTemplate]);
  }, [setSelectedTemplate, setAvailableTemplates]);

  const updateAvailableTemplates = useCallback((templates: ITemplate[]): void => {
    const selectedTemplateFromList = templates.find(template => selectedTemplate && selectedTemplate.id === template.id)
      || templates[0]
      || TEMPLATE_DEFAULT_VALUE;

    if (selectedTemplateFromList.id !== selectedTemplate.id) {
      updateSelectedTemplate(selectedTemplateFromList);
    }

    setAvailableTemplates(templates);
  }, [selectedTemplate.id, setAvailableTemplates]);

  return {
    selectedTemplate,
    updateSelectedTemplate,
    availableTemplates,
    updateAvailableTemplates,
  };
};
