import React from 'react';
import { IconCloseTooltip } from '../icons/icon-close-tooltip';
import {
  Actions,
  Button,
  ButtonSecondary,
  Card,
  Description,
  Header,
  IconButton,
  Title,
} from './location-unmasked/styles';

interface NotificationHintProps {
  title: JSX.Element;
  description: JSX.Element;
  primaryButton?: JSX.Element;
  secondaryButton?: JSX.Element;
  onPrimaryButtonClick?: () => void;
  onSecondaryButtonClick?: () => void;
  handleClose?: () => void;
  AdditionalIconAfterTitle?: JSX.Element;
  AdditionalIconBeforeTitle?: JSX.Element;
}

const NotificationHint: React.FC<NotificationHintProps> = ({
  title,
  description,
  primaryButton,
  secondaryButton,
  handleClose,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  AdditionalIconAfterTitle,
  AdditionalIconBeforeTitle,
}) => {
  return (
    <Card>
      <Header>
        {AdditionalIconBeforeTitle && <IconButton>{AdditionalIconBeforeTitle}</IconButton>}
        <Title style={{ marginRight: 'auto' }}>{title}</Title>
        {AdditionalIconAfterTitle && <IconButton>{AdditionalIconAfterTitle}</IconButton>}
        {handleClose && (
          <IconButton style={{ marginLeft: '8px' }} onClick={handleClose}>
            <IconCloseTooltip padding={0} iconColor='var(--98989F)' iconHoveredColor='var(--36363D)' />
          </IconButton>
        )}
      </Header>
      <Description>{description}</Description>
      <Actions>
        {primaryButton && <Button onClick={onPrimaryButtonClick}>{primaryButton}</Button>}
        {secondaryButton && (
          <ButtonSecondary
            style={primaryButton ? {} : { alignSelf: 'flex-start', padding: '0', fontSize: '14px', lineHeight: '20px' }}
            onClick={onSecondaryButtonClick}
          >
            {secondaryButton}
          </ButtonSecondary>
        )}
      </Actions>
    </Card>
  );
};
export default NotificationHint;
