import { Icon, Input, message } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ButtonSharing,
  Col,
  IconSharing,
  MenuPopup,
  OptionItem,
  RowSpaceBetween,
  TextSharing,
} from './styles';
import { UserRow } from './user-row';
import GologinModal from '../../../ui/gologin-modal';
import { requestFolderInvites, shareFolder } from '../api';

declare interface IShareModal {
  shareFolderVisible: boolean;
  shareFolder?: string;
  foldersIdsToShare: [string];
  shareMultipleFolders?: boolean;
  onClose: () => void;
}

const ShareFolderModal: FC<IShareModal> = (props) => {
  const [loadingInvites, changeInvitesLoading] = useState(true);
  const [invites, setInvites] = useState<any[]>([]);
  const [selectedRole, changeSelectedRole] = useState<'guest'|'redactor'|'administrator'>('guest');
  const [insertedEmail, changeInsertedEmail] = useState<string>('');
  const [anchorElSelectedRole, setAnchorElSelectedRole] = useState<HTMLElement|null>(null);

  const { t: translation } = useTranslation();

  const getInvites = async () => {
    if (!props.shareFolder) {
      return;
    }

    const profiles = await requestFolderInvites(props.foldersIdsToShare[0]);
    setInvites(profiles);
    changeInvitesLoading(false);
  };

  useEffect(() => {
    getInvites();
  }, [props.shareFolder, props.foldersIdsToShare]);

  const sendInvite = async () => {
    const sharedEmails = invites.find((i: any) => i.to.email === insertedEmail);

    if (!insertedEmail) {
      message.error(translation('errorMessages.insertUserEmail'));

      return;
    }

    if (sharedEmails) {
      message.error(translation('errorMessages.userAlreadyMember'));

      return;
    }

    changeInvitesLoading(true);

    await new Promise((resolve) => {
      resolve(shareFolder(props.foldersIdsToShare, selectedRole, insertedEmail));
    })
      .then(() => {
        changeInsertedEmail('');
        if (props.shareMultipleFolders) {
          props.onClose();
        } else {
          return getInvites();
        }
      })
      .finally(() => {
        changeInvitesLoading(false);
      });
  };

  const openPopupSelectedRole = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => setAnchorElSelectedRole(event.currentTarget);

  const closePopupSelectedRole = (): void => setAnchorElSelectedRole(null);

  const renderInviteText = () => {
    if (selectedRole === 'guest') {
      return translation('roles.profile.guest.title');
    }

    if (selectedRole === 'redactor') {
      return translation('roles.profile.editor.title');
    }

    return translation('roles.profile.administrator.title');
  };

  const getModalTitle = () => {
    if (props.shareFolder) {
      return `${translation('base.share')} "${props.shareFolder.slice(0, 28)}${props.shareFolder.length > 28 ? '...' : ''}" ${translation('shareModal.folder')}`;
    }

    return translation('shareModal.shareMultiple');
  };

  return (
    <GologinModal
      isVisible={props.shareFolderVisible}
      onClose={() => props.onClose()}
      title={getModalTitle()}
      width='390px'
    >
      <Col>
        <RowSpaceBetween style={{ marginBottom: 16 }}>
          <Input
            style={{ height: 30 }}
            type='email'
            placeholder={translation('shareModal.form.emailInput.placeholder')}
            value={insertedEmail}
            onChange={(e: any) => changeInsertedEmail(e.target.value)}
          />
          <div onClick={openPopupSelectedRole}>
            <span style={{ cursor: 'pointer', display: 'flex' }}>
              <TextSharing style={{ marginLeft: 15, marginTop: 2 }}>
                {renderInviteText()}
              </TextSharing>
              <div style={{ marginTop: -3 }}>
                <IconSharing type='down' />
              </div>
            </span>
          </div>
          <ButtonSharing style={{ marginLeft: 10 }} onClick={sendInvite}>
            {translation('shareModal.sendInvite')}
          </ButtonSharing>
        </RowSpaceBetween>
        {loadingInvites ? <div style={{ width: '100%', fontSize: 24, textAlign: 'center' }}>
          <Icon type='loading' />
        </div> : null}
        {invites.map(i => (
          <>
            <UserRow
              email={i.to.email}
              role={i.role}
              inviteId={i.id}
              getInvites={getInvites}
              changeInvitesLoading={changeInvitesLoading}
            />
          </>
        ))}
      </Col>
      <MenuPopup
        open={!!anchorElSelectedRole}
        anchorEl={anchorElSelectedRole}
        onClose={closePopupSelectedRole}
        transitionDuration={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        style={{ zIndex: 1150 }}
      >
        <OptionItem onClick={(): void => changeSelectedRole('guest')}>
          {translation('roles.profile.guest.title')}
        </OptionItem >
        <OptionItem onClick={(): void => changeSelectedRole('redactor')}>
          {translation('roles.profile.editor.title')}
        </OptionItem>
        <OptionItem onClick={(): void => changeSelectedRole('administrator')}>
          {translation('roles.profile.administrator.title')}
        </OptionItem>
      </MenuPopup>
    </GologinModal>
  );
};

export default ShareFolderModal;
