import { Button } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import GologinModal from '../../../ui/gologin-modal';

declare interface ICookiesManagerModal {
  visible: boolean;
  cookiesButtonDisabled: boolean;
  saveCookies: () => any;
  closeCookiesModal: () => any;
  cookiesSettings: () => any;
}

const CookiesManagerModal: FC<ICookiesManagerModal> = (props) => {
  const { t: translation } = useTranslation();

  const { visible, cookiesButtonDisabled, saveCookies, closeCookiesModal, cookiesSettings } = props;

  return (
    <GologinModal
      title={translation('cookieManagerModal.title')}
      isVisible={visible}
      onClose={(): void => closeCookiesModal()}
      footer={
        <>
          <Button
            type='primary'
            onClick={saveCookies}
            disabled={cookiesButtonDisabled}
          >
            {translation('base.import')}
          </Button>
        </>
      }
      width='520px'
    >
      {cookiesSettings()}
    </GologinModal>
  );
};

export default CookiesManagerModal;
