import React, { FC, useContext } from 'react';
import { Trans } from 'react-i18next';

import { WorkspaceContainerDescription } from './styles';
import { sendActionAnalytics } from '../../../features/common/api';
import { userContext, workspaceContext } from '../../../state';
import { openWebsitePricing } from '../../../utils/open-site';
import { IconCube } from '../icons/icon-cube';
import { IconWrapperWithDescription } from '../icons/wrapper';

type WorkspaceUpgradeProps = {
  isSelected: boolean;
}

export const WorkspaceUpgrade: FC<WorkspaceUpgradeProps> = (props) => {
  const { isSelected } = props;

  const { isQuickSettingsEnabled, _id: userId } = useContext(userContext);
  const { owner, _id: workspaceId } = useContext(workspaceContext);

  const onChangePlanClick = (): void => {
    sendActionAnalytics('clicked buy plan', { actionInfo: 'header right' });
    openWebsitePricing({
      workspaceId,
      isQuickSettingsEnabled,
      isShowPaymentMethods: true,
    });
  };

  if (!(isSelected && owner === userId)) {
    return null;
  }

  return (
    <WorkspaceContainerDescription>
      <IconWrapperWithDescription
        onClick={onChangePlanClick}
        iconColor='var(--00A987)'
        iconHoveredColor='var(--00997A)'
        hoveredHighlight={false}
        textColor='var(--00A987)'
        textHoveredColor='var(--00997A)'
        iconType='stroke'
        style={{ fontSize: 14 }}
      >
        <IconCube size={14} padding={0} margin={'4px 6px 4px 0'} />
        <Trans i18nKey={'base.upgrade'} />
      </IconWrapperWithDescription>
    </WorkspaceContainerDescription>
  );
};
