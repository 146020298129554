import { connectRouter, routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { combineReducers, getDefaultMiddleware } from 'redux-starter-kit';
import newProfile from '../features/newProfile/reducer';
import updateProfile from '../features/updateProfile/reducer';


function createReducer(history: History) {
  return combineReducers({
    newProfile,
    updateProfile,
    router: connectRouter(history)
  });
}

function createMiddleware(history: History) {
  return [...getDefaultMiddleware(), routerMiddleware(history)];
}

export { createReducer, createMiddleware };

