import { API_BASE_URL } from '../../../../common/constants/constants';
import { http } from '../../../services';
import { ITemplate } from '../../../state';

export const updateTemplateBrowser = async (templateObj: ITemplate): Promise<ITemplate> => {
  const data = await http(`${API_BASE_URL}/templates/${templateObj.id}`, {
    method: 'PUT',
    body: JSON.stringify(templateObj),
  });

  return data.body;
};

export const createTemplate = async (template: ITemplate): Promise<ITemplate> => {
  const data = await http(`${API_BASE_URL}/templates`, {
    method: 'POST',
    body: JSON.stringify(template),
  });

  return data.body;
};
