import css from '@emotion/css';
import styled from '@emotion/styled';

import { NotStyledButton } from '../member-row/row-styles';

export const SelectButtonContainer = styled(NotStyledButton)<{
  width: string;
  height?: string;
  disabled?: boolean;
  selectStyled?: boolean;
}>`
  width: ${props => props.width};
  height: ${props => props.height || '32px'};
  display: flex;
  align-items: center;

  ${props => props.disabled && css`
    cursor: default;
  `}

  ${props => props.selectStyled && css`
    border: 1px solid var(--D9D9D9-members-permissions-select);
    padding: 6px 0 6px 10px;
    border-radius: 4px;
    justify-content: space-between;
    font-size: 13px;
  `}
`;

export const TitleRole = styled('span')`
  font-family: 'Roboto';
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: var(--222222-members-permissions-select);
`;
