const sortArrayAfterDrag = <T>(array: T[], oldIndex: number, newIndex: number): T[] => {
  if (oldIndex < 0 || oldIndex >= array.length || newIndex < 0 || newIndex >= array.length) {
    return array;
  }

  if (oldIndex === newIndex) {
    return array;
  }

  const isMoveDown = newIndex > oldIndex;

  const minIndex = isMoveDown ? oldIndex : newIndex;
  const maxIndex = isMoveDown ? newIndex : oldIndex;

  const newArray: T[] = [];

  array.forEach((item, i) => {
    // For all items, use same index as before
    let moveToIndex = i;

    // But
    if (i === oldIndex) {
      // Move main item to desired location
      moveToIndex = newIndex;
    } else if (i >= minIndex && i <= maxIndex) {
      // Move by 1 all affected items reverse to main item move
      moveToIndex = moveToIndex + (isMoveDown ? -1 : 1);
    }

    newArray[moveToIndex] = item;
  });

  return newArray;
};

export default sortArrayAfterDrag;
