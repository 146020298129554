import { AutomationWorkflow } from '../interfaces';

const SLUGS_TO_PIN = ['cookie-bot'];

interface IWorkflowsPinnedAndTheRest {
  pinned: AutomationWorkflow[];
  theRest: AutomationWorkflow[];
}

// probably will be replaced with `pinned` field or something else
export const getWorkflowsPinnedAndTheRest = (workflows: AutomationWorkflow[]): IWorkflowsPinnedAndTheRest => {
  const pinned = workflows.filter(({ slug }) => SLUGS_TO_PIN.includes(slug));
  const theRest = workflows.filter(({ slug }) => !SLUGS_TO_PIN.includes(slug));

  return { pinned, theRest };
};
