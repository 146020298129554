import { IAddTagMethod, ITag, ITagBase, TagField } from './interfaces/tag.interface';
import { API_BASE_URL } from '../../../common/constants/constants';
import { normalizeTags } from '../../../common/utils';
import { http } from '../../services';

interface IUpsertTagResponse {
  id: string;
  success: boolean;
  title: string;
  color: string;
  field: TagField;
  isNewAdded: boolean;
}

export const addTagRequest = async (params: IAddTagMethod): Promise<IUpsertTagResponse> => {
  const data = await http(`${API_BASE_URL}/tags/addToProfiles`, {
    method: 'POST',
    body: JSON.stringify(params),
  });

  return data.body;
};

export const updateTagRequest = async (workspaceId: string, tag: ITagBase): Promise<void> => {
  const toSend: { title: string; color: string; workspace?: string } = {
    ...tag,
  };

  if (workspaceId) {
    toSend.workspace = workspaceId;
  }

  const data = await http(`${API_BASE_URL}/tags/${tag.id}`, {
    method: 'POST',
    body: JSON.stringify(toSend),
  });

  return data.body;
};

export const deleteTag = async (tagId: string, workspace: string): Promise<void> => {
  const query = workspace ? new URLSearchParams({
    workspace,
  }) : '';

  const data = await http(`${API_BASE_URL}/tags/${tagId}?${query}`, {
    method: 'DELETE',
  });

  return data.body;
};

export interface IDeleteProfilesTagReq {
  profileIds: string[];
  tagId: string;
  workspace: string;
}

export const deleteProfilesTag = async (deleteReq: IDeleteProfilesTagReq): Promise<{ success: boolean }> => {
  const { profileIds, tagId, workspace } = deleteReq;
  const query = workspace ? new URLSearchParams({
    workspace,
  }) : '';

  const data = await http(`${API_BASE_URL}/tags/${tagId}/removeFromProfiles?${query}`, {
    method: 'DELETE',
    body: JSON.stringify({
      browserIds: profileIds,
    }),
  });

  return data.body;
};

export const searchTags = async (text: string, workspace: string): Promise<{ success: boolean; tags: ITag[] }> => {
  const params: { q: string; workspace?: string } = {
    q: text,
  };

  if (workspace) {
    params.workspace = workspace;
  }

  const query = new URLSearchParams(params);

  const { body } = await http(`${API_BASE_URL}/tags/search?${query}`, {
    method: 'GET',
  });

  return { ...body, tags: normalizeTags(body.tags) };
};

export const getAllTags = async (workspace: string): Promise<{ success: boolean; tags: ITag[] }> => {
  const { body } = await http(`${API_BASE_URL}/tags/all?workspace=${workspace}`, {
    method: 'GET',
  });

  return { ...body, tags: normalizeTags(body.tags) };
};
