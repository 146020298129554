import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconHistory: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_19043_21957)'>
        <path d='M8.5 16C12.366 16 15.5 12.866 15.5 9C15.5 5.13401 12.366 2 8.5 2C4.63401 2 1.5 5.13401 1.5 9C1.5 12.866 4.63401 16 8.5 16Z' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M8.5 5V9H12.5' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_19043_21957'>
          <rect width='16' height='16' fill='white' transform='translate(0 0.5)' />
        </clipPath>
      </defs>
    </svg>
  </IconWrapper>
);
