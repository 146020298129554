import { SerializedStyles } from '@emotion/core';
import styled from '@emotion/styled';
import { Layout } from 'antd';

import { borderRadiusContainer, textFont } from '../../ui/style-templates';

export const MainContainer = styled(Layout)<{ newStyle: boolean }>`
  background-color: var(--FFFFFF-personal-area);
  height: 100%;

  ${(props): SerializedStyles|null => props.newStyle ? borderRadiusContainer() : null};
`;

export const ContentContainer = styled('div')<{ showScroll: boolean }>`
  font-family: Roboto;
  margin: 0 24px 0 86px;
  color: var(--000000-personal-area);
  display: flex;
  justify-content: space-between;

  font-size: 14px;
  line-height: 16px;
  opacity: 0.8;
  flex: 1;

  overflow-y: ${(props): string => props.showScroll ? 'initial' : 'hidden'};
`;

export const BlockShadow = styled('div')`
  box-shadow: 0 0 10px var(--E5E5E766-personal-area);
  clip-path: inset(0px 0 0px -10px);
  width: 100%;
  min-height: 100%;
  padding: 40px 64px 20px 64px;
  overflow: hidden;
`;

export const PageTitle = styled('div')`
  font-family: Roboto;
  font-size: 20px;
  line-height: 19px;
  color: var(--000000-personal-area);
  opacity: 0.8;
`;

export const OldPageTitle = styled('span')`
  margin-bottom: 47px;
  margin-top: 40px;
  display: inline-block;
  color: var(--000000-personal-area);
  font-family: Roboto;
  font-size: 20px;
  line-height: 23px;
`;

export const SubmenuTitle = styled('p')`
  margin: 20px 0 0 0;
  line-height: 1;
  padding: 5px 24px 7px;
  color: var(--000000-personal-area-subtitle);
  white-space: nowrap;
`;

export const MenuTitle = styled(SubmenuTitle)`
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
`;

export const ContentArea = styled('div')`
  margin-top: 20px;
  color: var(--767676-personal-area);
  display: flex;
  flex-direction: column;
`;

export const ContentAreaRow = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;

  button {
    margin: 12px 12px 12px 0;
  }
`;

export const SubtitleTransfer = styled('div')`
  font-family: Roboto;
  display: block;
  font-size: 14px;
  color: var(--000000-personal-area);
  width: 200px;
`;

export const ReceiptIconContainerWithDescription = styled('div')`
  $ ${textFont};
  margin-right: 8px;
  padding-right: 8px;
  display: inline-flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  white-space: nowrap;
`;
