import React, { FC } from 'react';

import PreviewCard from './preview-card';
import { IMappedPlan } from '../../interfaces';
import { getSelectedCustomPlan } from '../../plans-data';
import CustomPlanSelect from '../custom-plan-select';

interface ICustomPreviewCard {
  previewCustomPlans: IMappedPlan[];
  selectedCustomPlanId: string;
  selectedDiscount: string;
  getPlanInfo: (planId: string, field: keyof IPlan) => JSX.Element | string;
  setSelectedCustomPlanId: (planId: string) => void;
  onPlanSelect: (id: string, isUpgrade?: boolean) => Promise<void>;
}

const CustomPreviewCard: FC<ICustomPreviewCard> = (props) => {
  const {
    previewCustomPlans,
    selectedCustomPlanId,
    selectedDiscount,
    getPlanInfo,
    setSelectedCustomPlanId,
    onPlanSelect,
  } = props;

  const selectedCustomPlan = getSelectedCustomPlan(previewCustomPlans, selectedCustomPlanId);

  const getCustomPlanSelect = (): JSX.Element => (
    <div style={{ marginLeft: 4, marginRight: 2 }}>
      <CustomPlanSelect
        previewCustomPlans={previewCustomPlans}
        selectedCustomPlanId={selectedCustomPlanId}
        setSelectedCustomPlanId={setSelectedCustomPlanId}
      />
    </div>
  );

  return (
    <PreviewCard
      previewPlan={selectedCustomPlan}
      getCustomPlanSelect={getCustomPlanSelect}
      selectedDiscount={selectedDiscount}
      onPlanSelect={onPlanSelect}
      getPlanInfo={getPlanInfo}
    />
  );
};

export default CustomPreviewCard;
