import { message } from 'antd';
import React from 'react';
import { Trans } from 'react-i18next';

import { ITranslationOptions } from './handle-multiple-proxies-add-error';
import { ProxyRequestError } from './update-proxies.operations';
import { sendReactErrorToSentry } from '../../../utils/sentry.helper';

export const handleUpdateProxiesRequestError = (errorObject: ProxyRequestError): void => {
  const { error } = errorObject;
  const { message: errorMessage } = error || {};
  const translationOptions: ITranslationOptions = {
    i18nKey: errorMessage,
  };

  message.error(<Trans {...translationOptions} />);
  sendReactErrorToSentry({
    message: JSON.stringify(error),
    transactionName: 'handle-proxies-update-error',
    extra: {
      error,
      errorObject,
    },
  });
};
