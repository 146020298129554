import styled from '@emotion/styled';
import React from 'react';

import ProxySelector from '../../../../features/proxy/proxy-selector';
import { IProfile } from '../../../../interfaces';
import { useProfilesSettingsProfile } from '../../../../state/profiles-settings-atom';
import { getIsProxyArchived } from '../../../proxy/proxy-helpers';

const ProxySelectorWrapper = styled.div`
  height: 21px;
  width: 100%;
  margin-left: 8px;
`;

type ProxyComponentProps = {
  localProxySelectorLocation: string;
}

export const Proxy: React.FC<ProxyComponentProps> = (props) => {
  const { localProxySelectorLocation } = props;
  const profileInfo = useProfilesSettingsProfile() || {} as Partial<IProfile>;
  const { id: profileId, proxy, proxyEnabled, role, archivedProxy } = profileInfo;

  const isProxyArchived = getIsProxyArchived(archivedProxy);

  return (
    <ProxySelectorWrapper>
      <ProxySelector
        proxy={isProxyArchived ? archivedProxy : proxy}
        proxyEnabled={!!proxyEnabled || isProxyArchived}
        profileId={profileId}
        isInDrawer={true}
        isSharedProxy={role !== 'owner'}
        localProxySelectorLocation={localProxySelectorLocation}
      />
    </ProxySelectorWrapper>
  );
};
