import { atom, getDefaultStore, useAtomValue } from 'jotai/index';
import { splitAtom } from 'jotai/utils';

import { AutomationStatusData } from '../features/automation/interfaces/automation-status.interfaces';

const NULL_AUTOMATION_PROFILE_STATUS: AutomationStatusData = { workflowId: '', profileId: '', status: 'none' };
const nullAutomationProfileStatusAtom = atom<AutomationStatusData>(NULL_AUTOMATION_PROFILE_STATUS);
const automationProfileStatusesListAtom = atom<AutomationStatusData[]>([]);

const automationProfileStatusesAtom = splitAtom(automationProfileStatusesListAtom);

export const useAutomationProfileStatus = (profileId: string): AutomationStatusData => {
  const automationProfileStatuses = useAtomValue(automationProfileStatusesAtom);
  let currentAutomationProfileStatusAtom = automationProfileStatuses.find(automationProfileStatusAtom =>
    getDefaultStore().get(automationProfileStatusAtom).profileId === profileId);

  if (!currentAutomationProfileStatusAtom) {
    currentAutomationProfileStatusAtom = nullAutomationProfileStatusAtom;
  }

  return useAtomValue(currentAutomationProfileStatusAtom);
};

export const setAutomationProfileStatuses = (newautomationProfileStatuses: AutomationStatusData[]): void =>
  getDefaultStore().set(automationProfileStatusesListAtom, newautomationProfileStatuses);

const isAutomationStatusSame = (curr: AutomationStatusData, next: AutomationStatusData): boolean =>
  curr.status === next.status;

export const upsertAutomationProfileStatuses = (updatedAutomationProfileStatuses: AutomationStatusData[]): void => {
  const automationProfileStatusAtoms = getDefaultStore().get(automationProfileStatusesAtom);
  const automationProfileStatuses = getDefaultStore().get(automationProfileStatusesListAtom);
  const toAppend = updatedAutomationProfileStatuses.reduce<AutomationStatusData[]>((acc, updatedAutomationProfileStatus) => {
    const { profileId = '' } = updatedAutomationProfileStatus || {};
    if (!profileId) {
      return acc;
    }

    const currentAutomationProfileStatusIdx = automationProfileStatuses.findIndex(automationProfileStatus => automationProfileStatus.profileId === profileId);
    if (currentAutomationProfileStatusIdx === -1) {
      acc.push(updatedAutomationProfileStatus);

      return acc;
    }

    const currentAutomationProfileStatusAtom = automationProfileStatusAtoms[currentAutomationProfileStatusIdx];
    getDefaultStore().set(currentAutomationProfileStatusAtom, updatedAutomationProfileStatus);

    return acc;
  }, []);

  if (toAppend.length) {
    setAutomationProfileStatuses([...automationProfileStatuses, ...toAppend]);
  }
};
