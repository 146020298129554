import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { resetProfilesList } from '../profiles-list.atom';
import { forceReloadProfilesQuery } from '../profiles-table/profiles-query';
import { NEW_FEATURES } from '../feature-toggle/new-features';
import { sendActionAnalytics } from '../../features/common/api';
import { getTagsList } from './tags-list.atom';
import { E_ANALYTICS_ACTIONS, E_ANALYTICS_ACTIONS_INFO } from '../../../common/constants/analytics';

const selectedTagIdAtom = atom<string|null>(null);

export const getSelectedTagId = (): string|null => getDefaultStore().get(selectedTagIdAtom);
export const useSelectedTagId = (): string|null => useAtomValue(selectedTagIdAtom);
export const setSelectedTagId = (newSelectedTagId: string|null): void => {
  const prevSelectedTagId = getSelectedTagId();
  if (prevSelectedTagId === newSelectedTagId) {
    return;
  }

  if (NEW_FEATURES.objectPool && newSelectedTagId) {
    const selectedTag = getTagsList().find(tag => tag.id === newSelectedTagId)
    sendActionAnalytics(E_ANALYTICS_ACTIONS.filteredByTag, { actionInfo: selectedTag.title, actionId: newSelectedTagId });
  }

  // TODO: is this reset needed?
  resetProfilesList();
  getDefaultStore().set(selectedTagIdAtom, newSelectedTagId);

  forceReloadProfilesQuery();
};
