import { atom, getDefaultStore, useAtomValue } from 'jotai';
import React, { useLayoutEffect } from 'react';

import { NEW_FEATURES } from '../state/feature-toggle/new-features';

type AntStylesheetStatus = 'none'|'loading'|'loaded';

const antStylesheetRefAtom = atom<React.MutableRefObject<HTMLStyleElement | null>>(React.createRef<HTMLStyleElement>());
const antStylesheetStatusAtom = atom<AntStylesheetStatus>('none');

const setAntStylesheetStatus = (newStatus: AntStylesheetStatus): void => getDefaultStore().set(antStylesheetStatusAtom, newStatus);

const setAntStylesheetRefElem = (elem: HTMLStyleElement): void => {
  const antStylesheetRef = getDefaultStore().get(antStylesheetRefAtom);
  antStylesheetRef.current = elem;

  setAntStylesheetStatus('loaded');
};

const getAntStylesheetElem = (): HTMLStyleElement | null => {
  const antStylesheetRef = getDefaultStore().get(antStylesheetRefAtom);

  return antStylesheetRef.current;
};

const useAntStylesheetStatus = (): AntStylesheetStatus => useAtomValue(antStylesheetStatusAtom);

const disableAntStylesheet = (): void => {
  const andStylesheetElem = getAntStylesheetElem();
  if (andStylesheetElem) {
    andStylesheetElem.setAttribute('media', 'not all');
  }
};

const enableAntStylesheet = (): void => {
  const andStylesheetElem = getAntStylesheetElem();
  if (andStylesheetElem) {
    andStylesheetElem.removeAttribute('media');
  }
};

const useGlobalAntStyles = (isOldDesignOnly?: boolean, isUpdatePage?: boolean): { areAntStylesLoading: boolean } => {
  const antStylesheetStatus = useAntStylesheetStatus();
  const shouldLoad = !(isOldDesignOnly && NEW_FEATURES.header) || isUpdatePage;

  if (antStylesheetStatus === 'none') {
    setAntStylesheetStatus('loading');
    import('../default.less').then(() => {
      const stylesheets = document.querySelectorAll('head > style');
      const lastStyle = stylesheets[stylesheets.length - 1];
      if (lastStyle instanceof HTMLStyleElement) {
        setAntStylesheetRefElem(lastStyle);
        document.head.insertBefore(lastStyle, stylesheets[0]);
      }

      if (!shouldLoad) {
        disableAntStylesheet();
      }

      setAntStylesheetStatus('loaded');
    });
  }

  useLayoutEffect(() => {
    const antStylesheetElem = getAntStylesheetElem();
    if (antStylesheetElem) {
      if (shouldLoad) {
        enableAntStylesheet();
      } else {
        disableAntStylesheet();
      }

      return disableAntStylesheet;
    }

    return disableAntStylesheet;
  }, [shouldLoad]);

  return { areAntStylesLoading: antStylesheetStatus !== 'loaded' };
};

export default useGlobalAntStyles;
