import React, { SyntheticEvent } from 'react';
import { Trans } from 'react-i18next';

import { ProxySelectorButton, ProxySelectorButtonsWrapper } from './styles';
import useDelayedMount from '../../../../hooks/use-should-mount.hook';
import { NEW_FEATURES } from '../../../../state/feature-toggle/new-features';
import { openProxyManager } from '../../../../state/proxy/proxy-manager-modal-status.atom';
import { pasteProxies } from '../../../../state/proxy/proxy-operations/paste-proxies-operations';
import { IconPaste } from '../../../../ui/gologin-header/icons';
import IconProxyPlus from '../../../../ui/gologin-header/icons/icon-proxy-plus';
import { IIconWrapper } from '../../../../ui/gologin-header/icons/wrapper';
import TooltipWrapper from '../../../../ui/tooltip-custom/tooltip-wrapper';

const buttonIconProps: IIconWrapper = {
  padding: 0,
  iconColor: 'var(--767676-proxy-manager-icon-buttons)',
  iconHoveredColor: 'var(--00A987-proxy-manager)',
};

type ProxySelectorButtonsProps = {
  isVisible: boolean;
  profileId: string;
  selectorContainerElement: React.RefObject<HTMLDivElement>;
  localProxySelector?: string;
  isInDrawer?: boolean;
}

const DEFAULT_LOCATION = 'no-location';

const ProxySelectorButtons: React.FC<ProxySelectorButtonsProps> = (props) => {
  const { isVisible, profileId, selectorContainerElement, localProxySelector, isInDrawer } = props;

  const normalizedLocalProxySelector = localProxySelector || DEFAULT_LOCATION;
  const isInNewDrawer = isInDrawer && NEW_FEATURES.drawer;

  const { shouldMount } = useDelayedMount(!!isVisible);
  if (!shouldMount) {
    return null;
  }

  const handleAddClick = (event: SyntheticEvent): void => {
    event.stopPropagation();
    event.preventDefault();

    openProxyManager({
      containerElement: selectorContainerElement.current,
      modalView: 'proxy-add',
      currentProxy: null,
      modalEditingProxyId: null,
      currentProfileId: profileId,
      proxySelectorLocation: normalizedLocalProxySelector,
    });
  };

  const handlePasteClick = async (event: SyntheticEvent): Promise<void> => {
    event.stopPropagation();
    event.preventDefault();

    const pastedProxies = await pasteProxies({
      profileId,
      localProxySelectorLocation: normalizedLocalProxySelector,
      isInDrawer: !!isInDrawer,
    });

    if (pastedProxies.length > 1) {
      openProxyManager({
        containerElement: selectorContainerElement.current,
        modalView: 'proxy-list',
        currentProxy: null,
        modalEditingProxyId: null,
        currentProfileId: profileId,
        proxySelectorLocation: normalizedLocalProxySelector,
      });
    }
  };

  return (
    <ProxySelectorButtonsWrapper isVisible={isVisible}>
      <ProxySelectorButton
        isInNewDrawer={isInNewDrawer}
        onClick={handleAddClick}
      >
        <TooltipWrapper isTextEllipsis={false} hasTooltipAnimation={true} value={<Trans i18nKey='proxies.addProxy' />}>
          <IconProxyPlus {...buttonIconProps} />
        </TooltipWrapper>
      </ProxySelectorButton>
      <ProxySelectorButton
        isInNewDrawer={isInNewDrawer}
        onClick={handlePasteClick}
      >
        <TooltipWrapper isTextEllipsis={false} hasTooltipAnimation={true} value={<Trans i18nKey='proxies.pasteProxies' />}>
          <IconPaste {...buttonIconProps} />
        </TooltipWrapper>
      </ProxySelectorButton>
    </ProxySelectorButtonsWrapper>
  );
};

export default ProxySelectorButtons;
