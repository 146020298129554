import styled from '@emotion/styled';

export const ProxyListHeaderBtnContainer = styled('div')`
  width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
`;

export const ProxyListHeaderBtnLeft = styled('button')`
  color: var(--767676-proxy-manager-list);
  outline: none;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  margin-right: 11px;
  background: none;
  border: none;
  padding: 1px 0 0;
  width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;

  &:focus,
  &:visited {
    color: var(--767676-proxy-manager-list);
    outline: none;
    text-decoration: none;
  }

  &:hover {
    color: var(--00A987-proxy-manager);
    outline: none;
    text-decoration: none;
  }
`;

export const ProxyListHeaderBtnRight = styled('a')`
  color: var(--767676-proxy-manager-list);
  outline: none;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  background: none;
  border: none;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 1px;
  padding-right: 5px;

  &:focus,
  &:visited {
    color: var(--767676-proxy-manager-list);
    outline: none;
    text-decoration: none;
  }

  &:hover {
    color: var(--00A987-proxy-manager);
    outline: none;
    text-decoration: none;
  }
`;
