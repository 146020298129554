import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import { IPosition, ISelectCustom, ISelectGroupItems, ISelectItem } from './interfaces';
import Item from './item';
import { StretchSearch } from './stretch-search';
import { Options, Select, Mask, SelectButtonContainer, SelectArrowContainer, Ellipsis, SelectWrapper } from './styles';
import { DivAnchor } from '../../types';
import { IconArrow } from '../gologin-header/icons';

export const ModernSelect: FC<ISelectCustom> = (props): JSX.Element => {
  const {
    currentValue, itemList, onSelected, targetElement, popoverWidth, popoverMaxHeight, title, hasSearch, maxWidth,
    zIndex = 300, isDisableDarkTheme, onSelectOpen, margin = '0 0 0 0',
    disabled = false,
  } = props;

  const DEFAULT_POSITION: IPosition = { top: 0, left: 0 };
  const [optionsVisible, setOptionsVisible] = useState<boolean>(false);
  const [optionsPosition, setOptionsPosition] = useState<IPosition>(DEFAULT_POSITION);
  const [selectPosition, setSelectPosition] = useState<IPosition>(DEFAULT_POSITION);
  const [searchInput, setSearchInput] = useState<string>('');
  const searchRef = useRef<HTMLInputElement | null>(null);
  const selectRef = useRef<DivAnchor>(null);
  const optionsRef = useRef<DivAnchor>(null);

  useEffect(() => {
    if (optionsVisible && searchRef.current) {
      searchRef.current.focus();
    }

    setSearchInput('');
  }, [optionsVisible]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === 'Escape') {
        setOptionsVisible(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [optionsVisible]);

  useLayoutEffect(() => {
    const handleWindowResize = (): void => {
      if (!(optionsVisible && selectRef.current && optionsRef.current)) {
        return;
      }

      const selectCurrentPosition = selectRef.current.getBoundingClientRect();
      const relativeComponent = selectRef.current.parentElement?.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const optionsHeight = optionsRef.current.offsetHeight;

      if (!relativeComponent) {
        return;
      }

      const marginFromElement = 8;
      const { top, left } = relativeComponent;
      let newTop = top + selectCurrentPosition.height + marginFromElement;

      if (windowHeight - selectCurrentPosition.bottom < (optionsHeight + marginFromElement)) {
        const relativeComponentHeight = selectCurrentPosition.top - top;
        newTop = selectCurrentPosition.top - optionsHeight - relativeComponentHeight - marginFromElement;
      }

      setSelectPosition({ top: selectCurrentPosition.top, left: selectCurrentPosition.left });
      setOptionsPosition({ top: newTop, left });
    };

    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [optionsVisible]);

  const handleSelectClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    event.stopPropagation();
    setOptionsVisible(prevOptionsVisible => {
      if (!prevOptionsVisible && onSelectOpen) {
        onSelectOpen();
      }

      return !prevOptionsVisible;
    });
  };

  const handleMaskClick = (): void => {
    setOptionsVisible(false);
  };

  const onClickOption = (value: string): void => {
    onSelected(value);
    setOptionsVisible(false);
    setSearchInput('');
  };

  const checkIsItemGroup = (item: ISelectGroupItems|ISelectItem): boolean => Object.prototype.hasOwnProperty.call(item, 'groupTitle');

  const renderTitle = (): JSX.Element | string => {
    if (hasSearch && optionsVisible) {
      return <StretchSearch maxWidth={maxWidth} value={searchInput} placeholder={title || 'Search...'} onChange={setSearchInput} />;
    }

    const allItems: ISelectItem[] = itemList.flatMap((item) => {
      const isItemGroup = checkIsItemGroup(item);
      if (isItemGroup) {
        return (item as ISelectGroupItems).selectItems;
      }

      return item as ISelectItem;
    });

    const currentItem = allItems.find(item => item.value === currentValue);
    if (!currentItem) {
      return (
        <Ellipsis style={{ maxWidth }} disabled={disabled}>
          {title || currentValue}
        </Ellipsis>
      );
    }

    if (!currentItem?.hint) {
      return (
        <Ellipsis style={{ maxWidth }} disabled={disabled}>
          {title || currentItem?.title || currentValue}
        </Ellipsis>
      );
    }

    return (
      <Ellipsis style={{ display: 'flex', gap: 4, maxWidth }} disabled={disabled}>
        <span>
          {currentItem.hint}
        </span>
        <Ellipsis>
          {currentItem.title}
        </Ellipsis>
      </Ellipsis>
    );
  };

  const renderTargetElement = (): JSX.Element => {
    if (targetElement) {
      return targetElement;
    }

    return (
      <SelectButtonContainer onClick={handleSelectClick}>
        {renderTitle()}
        <SelectArrowContainer isOpen={optionsVisible}>
          <IconArrow padding={0} iconColor='var(--2B2B31-profile-new-settings-select)' />
        </SelectArrowContainer>
      </SelectButtonContainer>
    );
  };

  const allFoundItems: (ISelectGroupItems | ISelectItem)[] = itemList.map((item, index) => {
    const isItemGroup = checkIsItemGroup(item);
    if (!(isItemGroup && index)) {
      return item;
    }

    const foundSelectItems = (item as ISelectGroupItems).selectItems.filter(selectItem => {
      const searchValue = selectItem.hint ? selectItem.hint + selectItem.title : selectItem.title;

      return searchValue.replace('−', '-').toLowerCase().includes(searchInput.replace('−', '-').toLowerCase());
    });

    return {
      ...item,
      selectItems: foundSelectItems,
    };
  });

  const filteredItems = searchInput ? allFoundItems : itemList;

  const renderOptions = (): JSX.Element => (
    <>
      {filteredItems.map((item, index) => {
        const isItemGroup = checkIsItemGroup(item);
        const hasDivider = isItemGroup && itemList.length - 1 !== index;

        return (
          <Item
            key={index}
            option={item}
            onSelect={onClickOption}
            hasDivider={hasDivider}
            currentValue={currentValue}
            searchValue={searchInput}
          />
        );
      })}
    </>
  );

  const renderPopover = (): JSX.Element|null => {
    if (!optionsVisible) {
      return null;
    }

    const isFoundItem = !!allFoundItems.filter((foundItem, index) => {
      if (!Object.prototype.hasOwnProperty.call(foundItem, 'groupTitle')) {
        return foundItem;
      }

      if (!index) {
        return false;
      }

      return (foundItem as ISelectGroupItems).selectItems.length;
    }).length;

    const isShowOptions = optionsVisible && isFoundItem;

    return ReactDOM.createPortal(
      <SelectWrapper isDisableDarkTheme={isDisableDarkTheme}>
        <Select style={{ ...selectPosition, zIndex }}>
          {renderTargetElement()}
        </Select>
        <Options
          id='new-select-options'
          ref={optionsRef}
          optionsVisible={isShowOptions}
          style={{ ...optionsPosition, width: popoverWidth || 'auto', maxHeight: popoverMaxHeight || '300px', zIndex }}
        >
          {renderOptions()}
        </Options>
        <Mask style={{ zIndex: zIndex - 50 }} onClick={handleMaskClick} />
      </SelectWrapper>,
      document.body,
    );
  };

  return (
    <>
      <Select
        ref={selectRef}
        onClick={handleSelectClick}
        style={{
          visibility: optionsVisible ? 'hidden' : 'visible',
          position: 'static',
          zIndex: optionsVisible ? zIndex : 100,
          margin,
        }}
        disabled={disabled}
      >
        {renderTargetElement()}
      </Select>
      {renderPopover()}
    </>
  );
};
