import React, { FC } from 'react';

import { HintButton, HintInfoContainer, HintsContainer, HintSubtitle, HintTitle } from './styles';
import { sendHintsAnalytics, submitHintsUpdate } from './api';
import { setHintsWidgetState, useHintsWidgetState } from '../../state/hints/hints.atom';
import { IconClose } from '../gologin-header/icons';
import { Trans } from 'react-i18next';
import { Hint } from './types';

const HintsWidgetComponent: FC = () => {
  const { hints = [] } = useHintsWidgetState();

  const onHintClick = (hint: Hint) => {
    window.Intercom('showArticle', hint.articleId);
    sendHintsAnalytics(hint, 'clicked');
  }

  const onHintClose = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, articleId: string) => {
    event.stopPropagation();

    const condition = (hint: Hint) => hint.articleId === articleId;
    const [closedHints, updatedHints] = hints.reduce((acc: [Hint[], Hint[]], hint) => {
      condition(hint) ? acc[0].push(hint) : acc[1].push(hint);
      return acc;
    }, [[], []]) || [];

    const [closedHint] = closedHints || [];
    closedHint && sendHintsAnalytics(closedHint, 'closed');
    updatedHints && setHintsWidgetState(updatedHints);

    submitHintsUpdate(updatedHints);
  }

  if (!hints.length) {
    return null;
  }

  return (
    <HintsContainer>
      {hints.map((hint) => (
          <HintButton onClick={(): void => onHintClick(hint)}>
            <HintInfoContainer>
              <HintTitle>
                <Trans i18nKey={hint.titleTranslationKey} />
              </HintTitle>
              <HintSubtitle>
                <Trans i18nKey={hint.subtitleTranslationKey} />
              </HintSubtitle>
            </HintInfoContainer>
            <IconClose
              padding={0}
              iconColor='var(--98989F-hints-widget-icon-close)'
              iconHoveredColor='var(--2B2B31-hints-widget-icon-close-hover)'
              onClick={(event): void => onHintClose(event, hint.articleId)}
            />
          </HintButton>
        )
      )}
    </HintsContainer>
  );
};

export default HintsWidgetComponent;
