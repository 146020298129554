import styled from '@emotion/styled';

import { ModalSubtitle, ModalTitle, StyledButton } from '../../../modalsComponents/components/checkout-modal/styles';

export const ThreeDSecureWrapper = styled.div`
  position: relative;
  height: 100%;
  padding: 128px 61px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 32px;
`;

export const ThreeDSecureTitle = styled(ModalTitle)`
  margin: 0;
`;

export const ThreeDSecureSubtitle = styled(ModalSubtitle)`
  margin-top: 16px;
  color: var(--81818A);
  line-height: 20px;
  max-height: unset;
`;

export const ThreeDSecureButton = styled(StyledButton)`
  min-width: 228px;
  margin: 0;
  padding: 8px 30px;
  border: none;
  border-radius: 20px;
  font-family: 'Roboto Flex', sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.44px;
`;
