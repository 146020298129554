import { ISystemDataForIcons } from '../../interfaces';

export const getSystemDataForIcons = (): ISystemDataForIcons => {
  const osPlatform = (localStorage.getItem('@gologin:osPlatform') || 'linux');
  const winVersionMajor = +(localStorage.getItem('@gologin:winVersionMajor') || '8');
  const scaleFactor = +(localStorage.getItem('@gologin:scaleFactor') || 1);
  const browsersLocal = localStorage.getItem('@gologin:browsersLocal') || '';
  const browsersVersionsFull = localStorage.getItem('@gologin:browsersVersionsFull') || '';

  return { osPlatform, winVersionMajor, scaleFactor, browsersLocal, browsersVersionsFull };
};
