import { BrowserBuild } from '../types';

export const getBrowserBuildLatest = (version1: BrowserBuild, version2: BrowserBuild): BrowserBuild => {
  if (version1 === version2) {
    return version1;
  }

  const versionArray1 = version1.split('.');
  const versionArray2 = version2.split('.');

  const comparisonResult = versionArray1.reduce((result, currentValue, index) => {
    if (result) {
      return result;
    }

    const subVersion1 = parseInt(currentValue);
    const subVersion2 = parseInt(versionArray2[index]);

    if (subVersion1 > subVersion2) {
      return 1;
    } else if (subVersion1 < subVersion2) {
      return -1;
    }

    return result;
  }, 0);

  if (comparisonResult === 1) {
    return version1;
  }

  return version2;
};
