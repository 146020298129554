import React, { useState } from 'react';
import { Trans } from 'react-i18next';

import { IIconWrapper } from '../../../../ui/gologin-header/icons/wrapper';
import TooltipCustom from '../../../../ui/tooltip-custom';

const CONTROLS_ICONS_STYLE_PROPS: IIconWrapper = {
  iconType: 'stroke',
  iconColor: 'var(--98989F-proxy-settings-modal-icon)',
  iconHoveredColor: 'var(--2B2B31-proxy-settings-modal-icon-hovered)',
  hoveredHighlight: true,
  padding: 10,
};

// eslint-disable-next-line no-shadow
export enum CONTROL_BUTTON_TOOLTIP_KEY {
  pasteProxy = 'proxies.pasteProxy',
  copyProxy = 'proxies.copyProxy',
  clearForm = 'proxies.clearForm',
}

interface IControlIconButton {
  handleClick: React.MouseEventHandler<HTMLDivElement>;
  tooltipTranslationKey: CONTROL_BUTTON_TOOLTIP_KEY;
  Icon: React.FC<IIconWrapper>;
  wrapperStyles?: React.CSSProperties;
  iconStyles?: IIconWrapper;
  tooltipStyles?: React.CSSProperties;
}

const ControlIconButton: React.FC<IControlIconButton> = (props) => {
  const { handleClick, tooltipTranslationKey, Icon, wrapperStyles, iconStyles = {}, tooltipStyles } = props;

  const [anchorElTooltip, setAnchorElTooltip] = useState<HTMLElement|null>(null);

  const iconProps: IIconWrapper = { ...CONTROLS_ICONS_STYLE_PROPS, ...iconStyles };

  return (
    <div
      onMouseEnter={(event): void => setAnchorElTooltip(event.currentTarget)}
      onMouseLeave={(): void => setAnchorElTooltip(null)}
      style={wrapperStyles}
    >
      <Icon onClick={handleClick} {...iconProps} />
      <TooltipCustom
        anchorEl={anchorElTooltip}
        value={<Trans i18nKey={tooltipTranslationKey} />}
        style={tooltipStyles}
      />
    </div>
  );
};

export default ControlIconButton;
