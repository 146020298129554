export const defaultCookieBotSites = [
  'google.com',
  'youtube.com',
  'facebook.com',
  'instagram.com',
  'x.com',
  'wikipedia.org',
  'reddit.com',
  'amazon.com',
  'yahoo.com',
  'tiktok.com',
];
