import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { DivAnchor } from '../../../types';

type ProxySectionContextMenuTargetRef = React.MutableRefObject<DivAnchor> | null;

const proxySectionContextMenuTargetRefAtom = atom<ProxySectionContextMenuTargetRef>(null);

const getProxySectionContextMenuTargetRef = (): ProxySectionContextMenuTargetRef => getDefaultStore().get(proxySectionContextMenuTargetRefAtom);
const setProxySectionContextMenuTargetRef = (ref: ProxySectionContextMenuTargetRef): void =>
  getDefaultStore().set(proxySectionContextMenuTargetRefAtom, ref);

export const useProxySectionContextMenuTargetRef = (): ProxySectionContextMenuTargetRef =>
  useAtomValue(proxySectionContextMenuTargetRefAtom);

export const toggleProxySectionContextMenuVisibility = (ref: ProxySectionContextMenuTargetRef): void => {
  const isCurrentlyHidden = !getProxySectionContextMenuTargetRef();
  if (isCurrentlyHidden) {
    setProxySectionContextMenuTargetRef(ref);
  } else {
    setProxySectionContextMenuTargetRef(null);
  }
};

export const hideProxySectionContextMenu = (): void => {
  setProxySectionContextMenuTargetRef(null);
};
