import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled/macro';

import { SelectButtonContainer } from '../../members/role-select/styles';

export const ProxySavedSelectorArrowWrapper = styled.div`
  flex-grow: 0;
  margin-left: 8px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const proxySavedSelectorActiveStyles = css`
  ${ProxySavedSelectorArrowWrapper} svg path {
    stroke: var(--36363D-proxy-tab-icon-active);
  }
`;

export const ProxySavedSelectorWrapper = styled(SelectButtonContainer)<{ isActive: boolean }>`
  padding: 7px;
  font-size: 14px;

  ${(props): SerializedStyles | null => props.isActive ? proxySavedSelectorActiveStyles : null}

  :hover {
    ${proxySavedSelectorActiveStyles}
  }
`;

export const SelectedProxyWrapper = styled.div<{ isProxyManagerWithGroups: boolean }>`
  ${(props): SerializedStyles | null => props.isProxyManagerWithGroups ? null : css`
    margin-left: -8px;
  `}

  display: flex;
  flex-grow: 1;
`;

export const SavedProxyStatusWrapper = styled.div<{ isProxyManagerWithGroups: boolean }>`
  height: 100%;
  ${(props): SerializedStyles => props.isProxyManagerWithGroups ? css`
    width: 16px;
    min-width: 16px;
    margin: 0 7px 0 5px;
  ` : css`
    width: 32px;
    min-width: 32px;
  `}

  display: flex;
  justify-content: center;
  align-items: center;
`;
