import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconUser2: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M8 7.5C9.38071 7.5 10.5 6.38071 10.5 5C10.5 3.61929 9.38071 2.5 8 2.5C6.61929 2.5 5.5 3.61929 5.5 5C5.5 6.38071 6.61929 7.5 8 7.5Z' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8 9.5C4.962 9.5 3.5 11.395 3.5 12.5V13.5H12.5V12.5C12.5 11.4 11.038 9.5 8 9.5Z' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  </IconWrapper>
);
