import { atom, getDefaultStore, useAtomValue } from 'jotai';
import { AutomationWorkflow } from '../../features/automation/interfaces';
import { filterWorkflowsBySearch } from '../../features/automation/utils';
import { automationSearchAtom } from './automation-search.atom';

const defaultWorkflowName = 'Unknown workflow';

const automationWorkflowsAtom = atom<AutomationWorkflow[]>([]);

export const automationWorkflowByIdAtom = atom((get) => (id: string) =>
  get(automationWorkflowsAtom).find((workflow) => workflow.id === id) ||
  { name: defaultWorkflowName } as AutomationWorkflow);

const filteredAutomationWorkflowsAtom = atom((get) => {
  const searchString = get(automationSearchAtom).toLowerCase();
  const automationWorkflowsData = get(automationWorkflowsAtom);

  if (!searchString) {
    return automationWorkflowsData;
  };

  return filterWorkflowsBySearch(getDefaultStore().get(automationWorkflowsAtom), searchString);
});

export const getAutomationWorkflows = (searchString = ''): AutomationWorkflow[] => {
  return filterWorkflowsBySearch(getDefaultStore().get(automationWorkflowsAtom), searchString);
};

export const getAutomationWorkflowDataById = (id: string): AutomationWorkflow => {
  return getDefaultStore().get(automationWorkflowsAtom).find((workflow) => workflow.id === id) || { name: defaultWorkflowName } as AutomationWorkflow;
};

export const setAutomationWorkflows = (workflows: AutomationWorkflow[]): void => {
  getDefaultStore().set(automationWorkflowsAtom, workflows);
};

export const useAutomationWorkflows = (): AutomationWorkflow[] => useAtomValue(automationWorkflowsAtom);

export const useFilteredAutomationWorkflows = (): AutomationWorkflow[] => useAtomValue(filteredAutomationWorkflowsAtom);
