import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { ITag } from '../../features/tags/interfaces/tag.interface';

interface ITagManagerState {
  currentProfileId: string | null;
  currentField: ITag['field'] | null;
  tagSelectorLocation: string | null;
}

const TAG_MANAGER_DEFAULT_STATE: ITagManagerState = {
  currentProfileId: null,
  currentField: null,
  tagSelectorLocation: null,
};

export const tagManagerStateAtom = atom(TAG_MANAGER_DEFAULT_STATE);

export const getTagManagerState = (): ITagManagerState => getDefaultStore().get(tagManagerStateAtom);
export const useTagManagerState = (): ITagManagerState => useAtomValue(tagManagerStateAtom);

const setTagManagerState = (data: ITagManagerState): void => getDefaultStore().set(tagManagerStateAtom, data);

export const useIsTagManagerOpen = (): boolean => {
  const tagManagerState = useTagManagerState();

  return !!tagManagerState.currentProfileId;
};

interface IOpenTagManagerOpts {
  currentProfileId: string;
  currentField: ITag['field'];
  tagSelectorLocation: string;
}

export const openTagManager = (opts: IOpenTagManagerOpts): void => {
  setTagManagerState(opts);
};

export const closeTagManager = (): void => {
  setTagManagerState(TAG_MANAGER_DEFAULT_STATE);
};
