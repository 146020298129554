import React from 'react';

const IconHorseHopper = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.04297 35.13L12.085 21.266C12.646 19.7233 13.668 18.3904 15.0124 17.4484C16.3568 16.5064 17.9584 16.0007 19.6 16H27.612C28.4697 15.9957 29.303 15.7143 29.9877 15.1978C30.6725 14.6814 31.1721 13.9575 31.412 13.134C32.0291 11.0711 33.2956 9.26251 35.023 7.97716C36.7505 6.69182 38.8468 5.99839 41 6V9L44.074 14.379C44.3255 14.8189 44.4007 15.3379 44.2843 15.8311C44.1679 16.3243 43.8686 16.755 43.447 17.036L42 18L38 15L36 22L40.93 35.147" stroke="var(--212121)" strokeWidth="2" strokeMiterlimit="10"/>
    <path d="M36.6004 37.3788L35.2864 33.9688C34.4576 31.6371 32.9273 29.6192 30.9056 28.1922C28.8839 26.7651 26.47 25.999 23.9954 25.999C21.5208 25.999 19.1069 26.7651 17.0852 28.1922C15.0635 29.6192 13.5332 31.6371 12.7044 33.9688L11.4004 37.3788" stroke="var(--212121)" strokeWidth="2" strokeMiterlimit="10"/>
    <path d="M14.627 17.514C13.5646 17.0838 12.4127 16.9212 11.2727 17.0402C10.1327 17.1593 9.03936 17.5566 8.08876 18.197C7.13815 18.8375 6.35936 19.7015 5.8208 20.7134C5.28224 21.7252 5.00039 22.8538 5 24H2C2 21.8783 2.84285 19.8434 4.34315 18.3431C5.84344 16.8429 7.87827 16 10 16C11.7264 16.0021 13.4041 16.5717 14.775 17.621" stroke="var(--212121)" strokeWidth="2" strokeMiterlimit="10"/>
    <path d="M3.46777 32.5322C9.22197 37.3562 16.491 40.0001 23.9998 40.0001C31.5086 40.0001 38.7776 37.3562 44.5318 32.5322" stroke="var(--212121)" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square"/>
  </svg>
);

export default IconHorseHopper;
