import * as Sentry from '@sentry/react';

import { IWorkspaceCtx } from '../../state';
import { logout } from '../../utils/logout';
import { ReactError } from '../../utils/sentry-parameters/custom-errors';
import { E_SENTRY_ERRORS } from '../../utils/sentry-messages';

const handlePageLoadError = async (
  requestPath: 'user'|'workspaces',
  err: Error & Response & { body: any },
  workspaceCtx: IWorkspaceCtx,
): Promise<null> => {
  const { message: errMessage = E_SENTRY_ERRORS.UNKNOWN_ERROR, name, body, status } = err;
  const errorMessage = `${errMessage}-${name}-${JSON.stringify(body)}`;
  Sentry.captureException(new ReactError(errorMessage), (scope) => {
    scope.setLevel('error');
    scope.setTransactionName(`page-load-${requestPath}-request-error`);
    scope.setFingerprint([`page-load-${requestPath}-request-error`]);
    scope.setTag('page-load-request-error', requestPath);
    scope.setTag('page-load-request-error-name', name);
    scope.setTag('page-load-request-error-status', status);
    scope.setTag('page-load-request-error-body', body);

    return scope;
  });

  const is2Fa = status === 403 && body?.message === 'Enter two factor authentication code';
  if (!is2Fa) {
    await logout(workspaceCtx);
  }

  return null;
};

export default handlePageLoadError;
