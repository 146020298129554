import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UpdateAppNotificationModal } from '../../../../features/modalsComponents/components/update-app-notification/index';
import { userContext } from '../../../../state';
import { sendUpdateAnalytics } from '../../../Header/api';
import { IconArrowRight, IconLogo } from '../../icons';
import { IconWrapperWithDescription } from '../../icons/wrapper';
import { Text, TextWitchIcon, Wrapper } from '../styles';

let ipcRenderer: Electron.IpcRenderer;

const isElectron = !!window.require;
if (isElectron) {
  ({ ipcRenderer } = window.require('electron'));
}

const UpdateAppBar = (): JSX.Element => {
  const [isUpdateNotificationtModalVisable, setIsUpdateNotificationtModalVisable] = useState(false);
  const { lastAppVersion } = useContext(userContext);

  const { t: translation } = useTranslation();

  const restartAndInstallUpdates = async (): Promise<void> => {
    if (isElectron) {
      const isAnyProfileRunning = await ipcRenderer.invoke('check-profiles-running');
      if (isAnyProfileRunning) {
        setIsUpdateNotificationtModalVisable(true);
      } else {
        await sendUpdateAnalytics('app');
        ipcRenderer.invoke('install-app-updates');
      }
    }
  };

  return (
    <Wrapper background={'var(--EAF3EA-promo-bar-update-app)'}>
      <IconLogo padding={0} highlight={false} margin={'0 8px 0 0'} />
      <Text>
        {translation('updateApp.gologin')}
        {' '}
        {lastAppVersion}
        {' '}
        {translation('updateApp.updated')}
      </Text>
      <IconWrapperWithDescription
        onClick={restartAndInstallUpdates}
        iconColor='var(--00A987-promo-bar-update-app)'
        iconHoveredColor='var(--00997A-promo-bar-update-app)'
        textColor='var(--00A987-promo-bar-update-app)'
        textHoveredColor='var(--00997A-promo-bar-update-app)'
        iconType='stroke'
      >
        <TextWitchIcon>
          {translation('updateApp.restartApp')}
        </TextWitchIcon>
        <IconArrowRight padding={0} />
      </IconWrapperWithDescription>
      <UpdateAppNotificationModal
        isModalVisible={isUpdateNotificationtModalVisable}
        setIsModalVisible={setIsUpdateNotificationtModalVisable}
      />
    </Wrapper>
  );
};

export default UpdateAppBar;
