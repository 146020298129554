import { PROFILE_SHARED_KEY_NAME } from '../../../../common/constants/constants';
import { IProfile } from '../../../interfaces';

export const getProfileFromStorage = (profileJson: string): IProfile|null => {
  let profileSharedViaLink: IProfile|null;
  try {
    profileSharedViaLink = JSON.parse(profileJson);
  } catch (error) {
    profileSharedViaLink = null;
  } finally {
    sessionStorage.removeItem(PROFILE_SHARED_KEY_NAME);
  }

  return profileSharedViaLink;
};
