import React, { FC } from 'react';

import { MAX_TOUCH_POINTS, MaxTouchPointsType } from '../../../../interfaces';
import { ModernSelect } from '../../../../ui/modern-select';
import { ISelectItem } from '../../../../ui/modern-select/interfaces';
import { IChangeSetting } from '../../interfaces';
import { ContainerParams } from '../../styles';

interface  IMaxTouchPoints extends IChangeSetting{
  maxTouchPoints: MaxTouchPointsType;
}

export const MaxTouchPoints: FC<IMaxTouchPoints> = ({ changeSetting, maxTouchPoints }) => {
  const selectItems: ISelectItem[] = MAX_TOUCH_POINTS.map(maxTouchPoint => ({
    title: maxTouchPoint.toString(),
    value: maxTouchPoint.toString(),
  }));

  return (
    <ContainerParams>
      <ModernSelect
        currentValue={maxTouchPoints.toString()}
        itemList={selectItems}
        onSelected={(value): void => changeSetting({ navigator: { maxTouchPoints: Number(value) as MaxTouchPointsType } })}
      />
    </ContainerParams>
  );
};
