import { SerializedStyles } from '@emotion/core';
import css from '@emotion/css';
import styled from '@emotion/styled/macro';

import { IconWrapperWithDescription } from '../gologin-header/icons/wrapper';
import { textFont } from '../style-templates';

export const SettingsInput = styled('input')`
  background: inherit;
  outline: none;
  letter-spacing: 0.25px;
  border: none;
  font-family: Roboto;
  font-size: 14px;
  width: 100%;
  padding: 0;
  text-overflow: ellipsis;
  overflow: hidden;

  ::placeholder {
    color: var(--D2D2D5-profile-new-settings-input-start-url);
  }

  :disabled {
    background-color: inherit;
    color: var(--B5B5BA-profile-new-settings-input-start-url);
  }
`;

export const ContainerCloseIcon = styled(IconWrapperWithDescription)`
  display: none;
  background-color: inherit;
  border-radius: 0;
  margin-top: 1px;
`;

export const InputTitle = styled('span')<{ hasMarginTop?: boolean }>`
  white-space: nowrap;
  margin-top: ${(props): string => props.hasMarginTop ? '8px' : '0'};
  ${textFont()};
  color: var(--81818A-profile-new-settings-input-start-url);
`;

export const InputContainer = styled('div')<{ hasMarginTop?: boolean }>`
  margin-left: auto;
  display: flex;
  background-color: inherit;
  padding: ${(props): string => props.hasMarginTop ? '6px 0' : '0 0 6px 0'};
  width: 100%;

  ${(props): SerializedStyles => props.hasMarginTop ? css`
    padding: 6px 0;
    margin-top: 8px;
  ` : css`
    padding: 0 0 6px 0;
    margin-top: 0;
  `}

  :hover {
    ${ContainerCloseIcon} {
      display: block;
    }
  }

  :focus-within {
    box-shadow: inset 0px -1px 0px var(--CDCDCD-profile-new-settings-input-start-url);
  }
`;
