import React from 'react';
import { Trans } from 'react-i18next';

import GeoProxyTrafficTooltip from './geoproxy-traffic-tooltip';
import { HighlightedDescriptionPlan } from './styles';
import { determineIsGeoProxyType, GeoProxyType } from '../../../../../../common/constants/types';
import { convertBytes, TRAFFIC_UNITS } from '../../../../../../common/proxy/traffic/utils';
import IconPlanCheck from '../../../../../ui/icons/IconPlanCheck';
import TooltipWrapper from '../../../../../ui/tooltip-custom/tooltip-wrapper';
import { PreviewCardContentProps } from '../preview-card-content';
import { CardListItem, IconContainer } from '../styles';

type GeoProxyTrafficListItemProps = Pick<PreviewCardContentProps, 'isCustomPlan' | 'planName' | 'geoProxyTraffic'>;

const GeoProxyTrafficListItem: React.FC<GeoProxyTrafficListItemProps> = (props) => {
  const { isCustomPlan, planName, geoProxyTraffic } = props;

  if (!geoProxyTraffic) {
    return null;
  }

  const geoProxyTrafficInGB = Object.entries(geoProxyTraffic)
    .reduce<Record<GeoProxyType, string> & Record<'totalTraffic', number>>((acc, [geoProxyType, availableTraffic]) => {
      const availableTrafficInGB = convertBytes(availableTraffic, TRAFFIC_UNITS.gb);
      if (determineIsGeoProxyType(geoProxyType)) {
        acc[geoProxyType] = availableTrafficInGB.toFixed(1);
        acc.totalTraffic = acc.totalTraffic + availableTrafficInGB;
      }

      return acc;
    }, {
      resident: '0',
      mobile: '0',
      dataCenter: '0',
      totalTraffic: 0,
    });

  const { totalTraffic, ...trafficByType } = geoProxyTrafficInGB;

  return (
    <CardListItem isCustomPlan={isCustomPlan}>
      <IconContainer>
        <IconPlanCheck />
      </IconContainer>
      <TooltipWrapper
        value={<GeoProxyTrafficTooltip trafficByType={trafficByType} />}
        style={{ width: 268 }}
      >
        <HighlightedDescriptionPlan>
          <Trans
            i18nKey={`pricing.${planName.toLowerCase()}Plan.geoProxyTrafficTick`}
            values={{ traffic: totalTraffic.toFixed(1) }}
          />
        </HighlightedDescriptionPlan>
      </TooltipWrapper>
    </CardListItem>
  );
};

export default GeoProxyTrafficListItem;
