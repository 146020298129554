import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import React, { FC } from 'react';

import { useProfilesList } from '../../../state/profiles-list.atom';
import MultipleTagsPopper from '../../tags/components/multiple-tags-popper';
import { IAddTag, ITagBase } from '../../tags/interfaces/tag.interface';

interface IEditTagsMenu {
  selectedRows: string[];
  addTag: (params: IAddTag) => Promise<void>;
  updateTag: (tag: ITagBase) => Promise<void>;
  removeTag: (tagId: string, isInSuggest: boolean) => Promise<void>;
  removeProfileTag: (profileIds: string[], tagId: string) => Promise<void>;
  anchorEditTagsEl: null | HTMLElement;
  setAnchorEditTagsEl: (state: null | HTMLElement) => void;
}

const EditTagsMenu: FC<IEditTagsMenu> = (props: IEditTagsMenu) => {
  const {
    selectedRows,
    addTag,
    updateTag,
    removeTag,
    removeProfileTag,
    anchorEditTagsEl,
    setAnchorEditTagsEl,
  } = props;

  const profilesList = useProfilesList();

  const isPopperOpen = !!(anchorEditTagsEl && selectedRows.length);

  const hideEditTags = (): void => {
    setAnchorEditTagsEl(null);
  };

  return (
    <>
      <Popper
        open={isPopperOpen}
        anchorEl={anchorEditTagsEl}
        style={{ zIndex: 152 }}
      >
        <ClickAwayListener onClickAway={hideEditTags}>
          <div>
            <MultipleTagsPopper
              profileList={profilesList}
              selectedRows={selectedRows}
              updateTag={updateTag}
              addTag={addTag}
              removeTag={removeTag}
              removeProfileTag={removeProfileTag}
              field='tags'
            />
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default EditTagsMenu;
