export const convertAdsPowerProxy = (profileObject: any) => {
  if (!profileObject.proxy && profileObject.proxytype === 'noproxy') {
    return;
  }

  const proxy = profileObject.proxy;

  if (profileObject.proxytype === 'https') {
    profileObject.proxytype = 'http';
  }

  const proxyArray = proxy.split(':');
  const [host, port, username, password] = proxyArray;
  profileObject.proxy = `${profileObject.proxytype}://${username}:${password}@${host}:${port}`;
  delete profileObject.proxytype;
};
