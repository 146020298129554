import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconFolder: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width={props.size || 16} height={props.size || 16} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M7.5 4.5L5.5 1.5H0.5V13C0.5 13.3978 0.658035 13.7794 0.93934 14.0607C1.22064 14.342 1.60218 14.5 2 14.5H14C14.3978 14.5 14.7794 14.342 15.0607 14.0607C15.342 13.7794 15.5 13.3978 15.5 13V4.5H7.5Z' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  </IconWrapper>
);
