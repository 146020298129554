import { getMajorVersionByUa } from '../../common/orbita-browser';

export const getPickedMajorVersion = (userAgent: string, browsersLocal: string): number => {
  let pickedMajorVer = +getMajorVersionByUa(userAgent);
  const browsersDownloaded = browsersLocal.split(',').map(Number);
  if (!browsersDownloaded.includes(pickedMajorVer)) {
    [pickedMajorVer] = browsersDownloaded;
  }

  return pickedMajorVer;
};
