/* eslint-disable camelcase */
import * as Sentry from '@sentry/react';
import moment from 'moment';
import userflow from 'userflow.js';

import { E_SENTRY_MESSAGES } from './sentry-messages';
import { ReactError } from './sentry-parameters/custom-errors';
import { getDeviceFingerprint, getGologinMetaHeader } from './user-os';
import { API_BASE_URL } from '../../common/constants/constants';
import { IRegistrationLead } from '../../electron/interfaces/registration-lead.interfaces';
import { http } from '../services';

interface IUserInfo {
  _id?: string;
  email?: string;
  plan?: any;
  realPayments?: number;
  lastPaymentDate?: string;
  isCorrectlyRegistered?: boolean;
  lastAppVersion?: string;
  hasTrial?: boolean;
  registrationLead?: IRegistrationLead;
}

interface IWorkspaceInfo {
  workspaceIsUnpaid?: boolean;
  workspaceIsTrial?: boolean;
  workspacePlanId?: string;
}

const unknownUserId = 'unknown';

export class UserSessionService {
  private static instance: UserSessionService;
  private userInfo: IUserInfo = {};
  private workspaceInfo: IWorkspaceInfo = {};
  private userId = '';
  private isInited = false;
  private userAppVersion = '';
  private constructor() { }

  public static getInstance(): UserSessionService {
    if (!UserSessionService.instance) {
      UserSessionService.instance = new UserSessionService();
    }

    return UserSessionService.instance;
  }

  set setUserAppVersion(appVersion: string) {
    this.userAppVersion = appVersion;
  }

  startRecording(): void {
    if (this.isInited || !this.userInfo._id) {
      return;
    }

    const { workspaceIsUnpaid, workspacePlanId } = this.workspaceInfo;
    const {
      realPayments = 0,
      lastPaymentDate,
      isCorrectlyRegistered,
      lastAppVersion,
      hasTrial,
      _id: userId,
      email: userEmail,
      plan: userPlan,
      registrationLead = {},
    } = this.userInfo;

    const isCorrectRegAndLastAppVersion = isCorrectlyRegistered && this.userAppVersion === lastAppVersion;
    const { url = '' } = registrationLead || {};

    const checkConditionForTour = (registrationUrl: string): boolean => {
      const regex = /^gologin\.com/;

      return regex.test(registrationUrl);
    };

    const conditionForTour = checkConditionForTour(url);
    if (conditionForTour) {
      this.initProductTour();
    }

    if (realPayments > 1 || (realPayments === 1 && moment().utc().toDate() > moment.utc(lastPaymentDate).add(3, 'days').toDate())) {
      localStorage.removeItem('lsInited');

      return;
    }

    if (!isCorrectRegAndLastAppVersion) {
      return;
    }

    if (!(hasTrial || realPayments === 1)) {
      return;
    }

    localStorage.setItem('lsInited', 'true');
    this.isInited = true;
  }

  set setUserInfo(userInfo: IUserInfo) {
    this.userInfo = userInfo;
  }

  public setWorkspaceInfo = (workspaceInfo: IWorkspaceInfo): void => {
    this.workspaceInfo = workspaceInfo;
  };

  public setUserId(userId: string): void {
    this.userId = userId;
  }

  private async fetchUserMetadata(): Promise<Record<string, string | number | boolean>> {
    const { body: userMetadataBody } = await http(`${API_BASE_URL}/user/metadata/v3/flat`, {
      method: 'GET',
      headers: {
        'GoLogin-Meta-Header': await getGologinMetaHeader(),
        'app-id': await getDeviceFingerprint(),
      },
    }).catch(() => ({ body: {} }));

    const { userMetadata } = userMetadataBody;

    return userMetadata;
  }

  public initProductTour(): void {
    this.initUserflow()
      .then((result) => {
        const level: Sentry.SeverityLevel = 'log';

        if (!result) {
          Sentry.captureMessage(
            `${E_SENTRY_MESSAGES.userFlowUserIdentified}`, {
              level,
            });

          return;
        }

        Sentry.captureMessage(
          `${E_SENTRY_MESSAGES.userFlowNotInitedUserUnknown}`, {
            level,
          });
      })
      .catch(err => {
        const errorMessage = err instanceof Error ? err.message : E_SENTRY_MESSAGES.userFlowUnknownIssue;
        Sentry.captureException(new ReactError(errorMessage), (scope) => {
          scope.setLevel('error');
          scope.setTransactionName('userflow-connect');
          scope.setFingerprint(['userflow-connect']);

          return scope;
        });
      });
  }

  public async initUserflow(): Promise<string|void> {
    const {
      _id: userId,
      email: userEmail,
      registrationLead = {},
    } = this.userInfo;

    if (!userId) {
      return E_SENTRY_MESSAGES.userFlowNotInitedUserUnknown;
    }

    userflow.init('ct_w4buokn65fathedo3c33uz3et4');

    const level: Sentry.SeverityLevel = 'log';
    Sentry.captureMessage(
      `${E_SENTRY_MESSAGES.userFlowInited}`, {
        level,
      });

    const {
      promocode = '',
      url = '',
      utm = {
        utm_source: '',
        utm_medium: '',
        utm_campaign: '',
        utm_term: '',
        utm_content: '',
      },
      gclid = '',
      referer = '',
    } = registrationLead;

    return userflow.identify(userId, {
      name: userId,
      email: userEmail,
      promocode,
      placement: url,
      utm_source: utm.utm_source,
      utm_medium: utm.utm_medium,
      utm_campaign: utm.utm_campaign,
      utm_term: utm.utm_term,
      utm_content: utm.utm_content,
      gclid,
      referer,
    });
  }

  private decodeKey(key: string): string {
    // to make it much harder to find actual key in code
    switch (true) {
      case key.includes('ace-to') && key.endsWith('up'):
        return 'user-profile-runs-count-group';
      case key.includes('ace-to'):
        return 'user-profile-runs-count';
      case key.includes('ace-no') && key.endsWith('up'):
        return 'client-users-count-group';
      case key.includes('ace-no'):
        return 'client-users-count';
      default:
        return key;
    }
  }
}

