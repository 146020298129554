import { Divider } from '@material-ui/core';
import React, { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IUserInvite } from './interfaces';
import ShareActionsSelect from './share-actions-select';
import { Row, RowSpaceBetween, TextEmail, UserIconContainer } from './styles';
import IconPerson from '../../../../ui/icons/IconPerson';
import { ShareLinksAction, SharingSelectRole } from '../../../../utils/roles-texts';
import { useUserBasicInfo } from '../../../../state/user/user-basic-info.atom';

interface IUserProps extends IUserInvite {
  changeUserRole: (ids: string[], role: SharingSelectRole|'remove', email: string) => Promise<void>;
  disableSharing?: boolean;
}

const UserRow: FC<IUserProps> = (props) => {
  const { role, email, inviteId, changeUserRole, disableSharing } = props;
  const [firstRole] = role;

  const [currentRole, setCurrentRole] = useState<SharingSelectRole>(firstRole);

  const { email: currentUserEmail } = useUserBasicInfo();

  const { t: translation } = useTranslation();

  const canEditRole = !(email === currentUserEmail || currentRole === 'owner' || disableSharing);
  const globalRoles: SharingSelectRole[] = ['administrator', 'redactor', 'guest'];

  const handleActionSelect = async (action: SharingSelectRole|ShareLinksAction|'remove', ids: string[] = [], email = ''): Promise<void> => {
    if (action === 'pause' || action === 'resume' || action === 'delete') {
      return;
    }

    if (action !== 'remove') {
      setCurrentRole(action);
    }

    changeUserRole(ids, action, email);
  };

  return (
    <div>
      <RowSpaceBetween style={{ height: 35 }}>
        <Row style={{ width: 232, minWidth: 0 }}>
          <UserIconContainer>
            <IconPerson width={20} height={20} />
          </UserIconContainer>
          <TextEmail>
            {email}
            {' '}
            {email === currentUserEmail ? translation('newShareModal.you') : ''}
          </TextEmail>
        </Row>
        <ShareActionsSelect
          action={role}
          actionsGlobal={globalRoles}
          actionCurrent={currentRole}
          canEditRole={canEditRole}
          inviteId={inviteId}
          handleActionSelect={handleActionSelect}
          disableSharing={disableSharing}
        />
      </RowSpaceBetween>
      <Divider />
    </div>
  );
};

export default memo(UserRow);
