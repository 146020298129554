import React, { FC, useEffect, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import Collapse from './collapse';
import { DEFAULT_SECTIONS } from './default-sections';
import SectionHeader from './section-header';
import SettingRow from './setting-row';
import {
  ANDROID_HARDWARE_SETTINGS,
  HARDWARE_SETTINGS,
} from './settings-lists';
import { settingsParam } from './settings-param';
import { useProfilesSettingsState } from '../../../../state/profiles-settings-atom';
import { ISections, ISettingsParam, SETTINGS_PARAM } from '../../interfaces';

export const SettingsSections: FC<ISettingsParam> = (props) => {
  const { fullProfile } = props;

  const { profileIds } = useProfilesSettingsState();
  const isMultiProfilesSettings = profileIds.length > 1;

  const [sections, setSections] = useState<ISections>(DEFAULT_SECTIONS);

  const settings = settingsParam({ ...props, isMultiProfilesSettings });

  useEffect(() => {
    const sectionsTmp = { ...sections };
    sectionsTmp.hardware.items = fullProfile.os === 'android' ? ANDROID_HARDWARE_SETTINGS : HARDWARE_SETTINGS;
    setSections({ ...sections, ...sectionsTmp });
  }, [fullProfile.os]);

  const toggleSection = (sectionName: string): void => {
    const sectionsTmp = { ...sections };
    sectionsTmp[sectionName].isOpen = !sectionsTmp[sectionName].isOpen;
    setSections({ ...sections, ...sectionsTmp });
  };

  return (
    <DragDropContext onDragEnd={(): null => null}>
      {Object.entries(sections).map(([sectionId, section]) => (
        <div key={sectionId}>
          <SectionHeader
            sectionId={sectionId}
            section={section}
            toggleSection={toggleSection}
          />
          <div>
            <Droppable droppableId={sectionId} key={sectionId} isDropDisabled={sectionId !== 'pinned'}>
              {(provided): JSX.Element => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <Collapse isAlwaysOpen={sectionId === 'pinned'} isOpen={section.isOpen}>
                    {section.items.map((setting, index) => (
                      <SettingRow
                        key={setting}
                        settings={settings}
                        setting={setting as typeof SETTINGS_PARAM[number]}
                        sectionId={sectionId}
                        index={index}
                      />
                    ))}
                    {provided.placeholder}
                  </Collapse>
                </div>
              )}
            </Droppable>
          </div>
        </div>
      ))}
    </DragDropContext>
  );
};
