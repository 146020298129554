import { IFullProfile } from '../../../../interfaces';

export const buildProfile = (profile: IFullProfile, profileNewData: Partial<IFullProfile>): IFullProfile => ({
  ...profile,
  ...profileNewData,
  navigator: {
    ...profile.navigator,
    ...profileNewData.navigator,
  },
  timezone: {
    ...profile.timezone,
    ...profileNewData.timezone,
  },
  geolocation: {
    ...profile.geolocation,
    ...profileNewData.geolocation,
  },
  proxy: {
    ...profile.proxy,
    ...profileNewData.proxy,
  },
  audioContext: {
    ...profile.audioContext,
    ...profileNewData.audioContext,
  },
  canvas: {
    ...profile.canvas,
    ...profileNewData.canvas,
  },
  extensions: {
    ...profile.extensions,
    ...profileNewData.extensions,
  },
  fonts: {
    ...profile.fonts,
    ...profileNewData.fonts,
  },
  mediaDevices: {
    ...profile.mediaDevices,
    ...profileNewData.mediaDevices,
  },
  plugins: {
    ...profile.plugins,
    ...profileNewData.plugins,
  },
  storage: {
    ...profile.storage,
    ...profileNewData.storage,
  },
  webGL: {
    ...profile.webGL,
    ...profileNewData.webGL,
  },
  webGLMetadata: {
    ...profile.webGLMetadata,
    ...profileNewData.webGLMetadata,
  },
  webglParams: {
    ...profile.webglParams,
    ...profileNewData.webglParams,
  },
  webRTC: {
    ...profile.webRTC,
    ...profileNewData.webRTC,
  },
});
