import { isNotNull } from '../../common/typescript/predicates';
import { zeroProfileBookmarks } from '../../electron/constants/zero-profile-bookmarks';
import {
  IBookmarkFolder,
  IBookmarkFoldersObj,
  IBookmarkParsedStructure,
  ISiteBookmark,
} from '../../electron/interfaces/bookmarks-utils.interfaces';

export const buildBookmarksTree = (children: (IBookmarkFolder|ISiteBookmark)[], currentBookmarks: IBookmarkFoldersObj): IBookmarkFoldersObj => {
  const bookmarksSource = currentBookmarks && currentBookmarks.bookmark_bar ? currentBookmarks : zeroProfileBookmarks.roots;
  // eslint-disable-next-line camelcase
  let result: IBookmarkFoldersObj = { bookmark_bar: {} as IBookmarkFolder };
  try {
    result = JSON.parse(JSON.stringify(bookmarksSource));
    // eslint-disable-next-line camelcase
    result.bookmark_bar = {
      ...result.bookmark_bar,
      children,
    };
  } catch(error: unknown) {
    console.log(error);
  }

  return result;
};

export const parseBookmarksFromText = (
  currentBookmarks: IBookmarkFoldersObj,
  parsedBookmarksTextArray: IBookmarkParsedStructure[],
): IBookmarkFoldersObj => {
  // на данный момент работаем только с теми закладками, что расположены в папке 'Bookmarks bar'
  // остальные папки (Other и Synced (mobile)) получаем из 'ProfileFolder/Default/Bookmarks.json', и храним у себя в БД
  const bookmarkBarStructure: IBookmarkFolder = { children: [], type: 'folder', name: 'Bookmarks bar' };
  for (const parsedBookmark of parsedBookmarksTextArray) {
    const { folders = [], name = '', url = '' } = parsedBookmark;

    let currentFolder = bookmarkBarStructure;
    for (const folderName of folders) {
      const { children } = currentFolder;
      const folderData = children as IBookmarkFolder[];
      let folderNode = folderData.find((node: IBookmarkFolder) =>
        node.type === 'folder' && node.name === folderName,
      );

      if (!folderNode) {
        folderNode = {
          type: 'folder',
          name: folderName,
          children: [],
        };

        currentFolder.children.push(folderNode);
      }

      currentFolder = folderNode;
    }

    const bookmarkNode: ISiteBookmark = {
      name,
      type: 'url',
      url,
    };

    currentFolder.children.push(bookmarkNode);
  }

  return buildBookmarksTree(bookmarkBarStructure.children, currentBookmarks);
};

export const parseInputBookmarksLine = (inputString: string): IBookmarkParsedStructure|null => {
  const dataArray = inputString.split('::');

  switch (dataArray.length) {
    // юзер указал только название ссылки и url ссылки
    case 2: {
      const [name, url] = dataArray;
      if (!url) {
        return null;
      }

      return {
        folders: [],
        name: name ? name : url,
        url: checkAndWrapUrl(url),
      };
    }

    // юзер указал только url ссылки
    case 1: {
      const [url] = dataArray;
      if (!url) {
        return null;
      }

      return {
        folders: [],
        name: url,
        url: checkAndWrapUrl(url),
      };
    }

    default: {
      const folderNames = dataArray.slice(0, -2);
      const name = dataArray[dataArray.length - 2];
      const url = dataArray[dataArray.length - 1];

      return {
        folders: folderNames,
        name,
        url: checkAndWrapUrl(url),
      };
    }
  }
};

export const parseBookmarksTxtToObj = (currentBookmarks: IBookmarkFoldersObj, textToParse: string): IBookmarkFoldersObj => {
  const linesOfText = textToParse.split('\n');
  const parsedLines = linesOfText.map(parseInputBookmarksLine).filter(isNotNull);

  return parseBookmarksFromText(currentBookmarks, parsedLines);
};

export const parseBookmarksObjToTxt = (bookmarksObj: IBookmarkFoldersObj): string => {
  const parsingObj = bookmarksObj.bookmark_bar || zeroProfileBookmarks.roots.bookmark_bar;

  if (!parsingObj) {
    return '';
  }

  // удаляем данные о родительской папке во всех ссылках
  // пока мы не парсим другие базовые папки, эта информация не нужна
  const result = parseTree(parsingObj).replaceAll(/^Bookmarks\s+bar::/gim, '');

  return result;
};

const parseTree = (bookmarkObj: IBookmarkFolder|ISiteBookmark, path = ''): string => {
  const parseFolderData = (bookmarksFolder: IBookmarkFolder): string =>
    bookmarksFolder.children.reduce((acc, child) => {
      acc += parseTree(child, newPath);

      return acc;
    }, '');

  // в закладках браузера есть 3 корневых раздела
  // Bookmarks bar (bookmark_bar) - закладки, которые отображаются у юзера при открытии браузера вверху, под строкой адреса
  // Mobile bookmarks (synced) - закладки с мобильных устройств
  // Other bookmarks (other) - все остальные
  // каждый элемент в дереве закладок имеет id, id папки bookmark_bar всегда = 1, потому что создаётся первой
  // цель if`а - скипать парсинг имени корневой папки bookmark_bar
  if (bookmarkObj.id === '1') {
    return parseFolderData(bookmarkObj as IBookmarkFolder);
  }

  if (bookmarkObj.type === 'url') {
    const bookmarkSite = bookmarkObj as ISiteBookmark;

    return path + bookmarkSite.name + '::' + bookmarkSite.url + '\n';
  }

  const newPath = path + bookmarkObj.name + '::';

  return parseFolderData(bookmarkObj);
};

const checkAndWrapUrl = (url: string): string => {
  const isUrlIncludesHttp = url.includes('http');
  const isExecutableCode = url.includes('javascript:');

  if (!(isUrlIncludesHttp || isExecutableCode)) {
    url = `http://${url}/`;
  }

  return url;
};

export const getCountBookmarks = (bookmarkObj: IBookmarkFoldersObj|IBookmarkFolder|ISiteBookmark): number => {
  const bookmarks = Object.prototype.hasOwnProperty.call(bookmarkObj, 'bookmark_bar') ?
    ((bookmarkObj as IBookmarkFoldersObj).bookmark_bar) :
    (bookmarkObj as IBookmarkFolder|ISiteBookmark);

  if (bookmarks.type === 'url') {
    return 1;
  }

  return bookmarks.children.reduce((acc, child) => acc + getCountBookmarks(child), 0);
};
