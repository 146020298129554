import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconPaypalLogoSmall: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg
      width={60}
      height={15}
      viewBox="0 0 60 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.405 3.272h-3.316a.46.46 0 0 0-.455.39l-1.341 8.502a.276.276 0 0 0 .273.32h1.583a.46.46 0 0 0 .455-.39l.362-2.294a.46.46 0 0 1 .455-.389h1.05c2.184 0 3.444-1.057 3.773-3.151.149-.917.007-1.637-.422-2.141-.471-.554-1.307-.847-2.417-.847m.382 3.106c-.181 1.19-1.09 1.19-1.97 1.19h-.5l.352-2.222a.276.276 0 0 1 .273-.234h.229c.599 0 1.163 0 1.455.342.174.203.228.506.161.924m9.53-.038h-1.588a.28.28 0 0 0-.273.233l-.07.444-.111-.16c-.344-.5-1.11-.667-1.876-.667-1.754 0-3.253 1.33-3.545 3.194-.152.93.064 1.819.592 2.439.483.57 1.176.808 2 .808 1.413 0 2.197-.91 2.197-.91l-.07.442a.276.276 0 0 0 .272.32h1.43a.46.46 0 0 0 .456-.39l.858-5.434a.275.275 0 0 0-.272-.32m-2.213 3.09c-.154.907-.874 1.516-1.792 1.516-.461 0-.83-.148-1.066-.428-.235-.278-.324-.674-.25-1.115.144-.9.876-1.529 1.78-1.529.451 0 .817.15 1.059.433.242.285.338.684.269 1.123M40.773 6.34h-1.596a.46.46 0 0 0-.381.202l-2.2 3.242-.934-3.115a.46.46 0 0 0-.442-.33h-1.568a.277.277 0 0 0-.262.366l1.757 5.158-1.652 2.333a.276.276 0 0 0 .226.436h1.593a.46.46 0 0 0 .379-.198L41 6.774a.276.276 0 0 0-.227-.434"
        fill="#253B80"
      />
      <path
        d="M46.057 3.272h-3.316a.46.46 0 0 0-.455.39l-1.341 8.502a.276.276 0 0 0 .272.32h1.702a.32.32 0 0 0 .318-.273l.38-2.41a.46.46 0 0 1 .456-.39h1.049c2.185 0 3.445-1.057 3.774-3.151.15-.917.006-1.637-.423-2.141-.47-.554-1.306-.847-2.416-.847m.383 3.106c-.181 1.19-1.09 1.19-1.97 1.19h-.5l.352-2.222a.275.275 0 0 1 .272-.234h.23c.598 0 1.163 0 1.455.342.174.203.227.506.16.924m9.528-.038H54.38a.275.275 0 0 0-.272.233l-.07.444-.112-.16c-.344-.5-1.11-.667-1.875-.667-1.755 0-3.253 1.33-3.545 3.194-.15.93.064 1.819.591 2.439.485.57 1.177.808 2 .808 1.414 0 2.198-.91 2.198-.91l-.07.442a.276.276 0 0 0 .273.32h1.43a.46.46 0 0 0 .455-.39l.858-5.434a.277.277 0 0 0-.274-.32m-2.213 3.09c-.152.907-.873 1.516-1.792 1.516-.46 0-.83-.148-1.066-.428-.235-.278-.323-.674-.249-1.115.144-.9.875-1.529 1.78-1.529.45 0 .817.15 1.058.433.243.285.34.684.269 1.123m4.086-5.924-1.362 8.659a.276.276 0 0 0 .273.319h1.368a.46.46 0 0 0 .455-.39l1.342-8.502a.276.276 0 0 0-.272-.32h-1.532a.28.28 0 0 0-.273.234"
        fill="#179BD7"
      />
      <path
        d="m3.522 14.135.254-1.61-.565-.014H.514L2.388.626a.15.15 0 0 1 .152-.13h4.548c1.51 0 2.552.314 3.096.934.255.291.417.595.496.93.082.35.084.77.003 1.282l-.005.037v.328l.255.144q.32.17.516.394c.218.249.36.565.419.94.061.385.041.844-.06 1.363-.116.597-.304 1.118-.558 1.543a3.2 3.2 0 0 1-.885.97c-.338.24-.739.421-1.192.538-.44.114-.94.172-1.49.172H7.33c-.253 0-.5.091-.692.254a1.07 1.07 0 0 0-.36.644l-.028.145-.448 2.84-.02.103c-.005.033-.015.05-.028.06a.1.1 0 0 1-.047.018z"
        fill="#253B80"
      />
      <path
        d="M11.174 3.718q-.02.13-.046.266c-.6 3.08-2.652 4.143-5.272 4.143H4.52c-.32 0-.59.233-.64.55l-.683 4.332-.194 1.228a.34.34 0 0 0 .337.395h2.367a.57.57 0 0 0 .562-.48l.023-.12.446-2.828.029-.155a.57.57 0 0 1 .562-.481h.354c2.293 0 4.088-.931 4.612-3.625.22-1.125.106-2.065-.474-2.726a2.3 2.3 0 0 0-.648-.5"
        fill="#179BD7"
      />
      <path
        d="M10.547 3.467a5 5 0 0 0-.583-.13 7.4 7.4 0 0 0-1.176-.085H5.223a.566.566 0 0 0-.562.48l-.758 4.804-.022.14a.65.65 0 0 1 .64-.549h1.335c2.62 0 4.672-1.064 5.272-4.143a7 7 0 0 0 .046-.267 3.2 3.2 0 0 0-.627-.25"
        fill="#222D65"
      />
      <path
        d="M4.661 3.733a.57.57 0 0 1 .562-.48h3.565q.635-.001 1.176.085.155.026.3.057a5 5 0 0 1 .418.114q.265.088.493.208c.179-1.138-.001-1.912-.617-2.614C9.88.331 8.657 0 7.09 0H2.542a.65.65 0 0 0-.643.55L.005 12.556a.39.39 0 0 0 .385.451h2.808l.705-4.472z"
        fill="#253B80"
      />
    </svg>
  </IconWrapper>
);
