import { atom, getDefaultStore } from 'jotai';
import { logToDbgMonitor } from '../../dbg/monitor';

type SyncDate = Date | null;

const lastSyncDateAtom = atom<SyncDate>(null);

export const getLastSyncDate = (): SyncDate => {
  return getDefaultStore().get(lastSyncDateAtom);
};

export const setLastSyncDate = (newLastSyncDate: SyncDate): void => {
  logToDbgMonitor('delta-sync', { newLastSyncDate });

  getDefaultStore().set(lastSyncDateAtom, newLastSyncDate);
};
