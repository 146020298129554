import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled';

import { textFont } from '../../../../../ui/style-templates';

export const Input = styled('input')<{ newStyle: boolean }>`
  width: 100%;
  height: 26px; // to match the height of the div that contains the name of the profile
  padding: 5px;
  border: 1px solid transparent;
  border-radius: 4px;
  margin-left: -5px;
  
  display: flex;
  align-items: center;

  font-family: Roboto;
  font-size: 14px;
  line-height: 14px;
  color: var(--000000-profile-table-profile-name-edit);
  
  overflow: hidden;
  white-space: nowrap;
  
  &:focus {
    border: ${(props): string => props.newStyle ? 'none' : '1px solid var(--CDCDCD-profile-table-profile-name-edit)'};
    outline: none !important;
    color: ${(props): string => props.newStyle ? 'var(--535358-profile-table-profile-name-edit)' : 'var(--000000-profile-table-profile-name-edit)'};
    box-shadow: none;
  }

  ${(props): SerializedStyles|null => props.newStyle ? css`
    ${textFont()};
    border-width: 0;
    padding: 0;
    height: 20px;
    font-weight: 500; 
    color: var(--535358-profile-table-profile-name-edit);
    background-color: transparent;
    margin-left: 0;
  ` : null}
`;
