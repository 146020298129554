import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconBookmark: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M1.5 11.5L6 8.5L10.5 11.5V2C10.5 1.172 9.828 0.5 9 0.5H3C2.172 0.5 1.5 1.172 1.5 2V11.5Z' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  </IconWrapper>
);
