import React from 'react';
import { Trans } from 'react-i18next';

import { DAYS_TO_ARCHIVE, DAYS_TO_ERASE } from '../../../features/proxy/constants/settings';
import { Text, ArchiveProxiesWrapper } from '../promo-bar/styles';

const ArchiveProxiesHeaderText = (): JSX.Element => (

  <ArchiveProxiesWrapper>
    <Text style={{ fontSize: '12px' }}>
      <Trans
        i18nKey={'header.removeProxyFromArchive'}
        values={{ archiveCount: DAYS_TO_ARCHIVE, eraseCount: DAYS_TO_ERASE }}
        components={{ strong: <span style={{ color: 'var(--00A987-promo-bar-trial)' }} /> }}
      />
    </Text>
  </ArchiveProxiesWrapper>
);

export default ArchiveProxiesHeaderText;
