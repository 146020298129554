import React, { useContext } from 'react';

import { RunButtonWrapper } from './run-button-wrapper';
import { InfoContainer, DescriptionContainer, WorkflowContainer, TitleContainer, ActionsContainer, IconGearContainer } from './styles';
import WorkflowTitle from './workflow-title';
import { AutomationWorkflow, IProfileLaunch } from '../../interfaces';
import { Icon, IconWrapper } from './workflow-title/styles';
import { openWorkflowParametersModal } from '../../../../state/automation/workflow-parameters-modal.atom';
import { IconGear } from '../../../../ui/gologin-header/icons';
import { ILaunchProfileOrbita } from '../../../quickProfiles/components/interfaces/launch-profile-orbita.interface';
import { useTranslation } from 'react-i18next';

type WorkflowItem =
  Pick<AutomationWorkflow, 'id'|'icon'|'name'|'slug'|'drawflow'|'fullDescription'> &
  IProfileLaunch &
  { 
    profileId?: string;
    launchProfileOrbita: (opts: ILaunchProfileOrbita) => Promise<void|null>;
  };

export const WorkflowItem: React.FC<WorkflowItem> = (props: WorkflowItem) => {
  const { id = '', icon = '', name = '', slug = '', drawflow = null, fullDescription = '', profileId, launchProfileOrbita } = props;

  const { t: translation } = useTranslation();

  const handleClickSettings = () => {
    openWorkflowParametersModal(id, profileId);
  }

  return (
    <WorkflowContainer>
      <InfoContainer>
        <TitleContainer>
          <IconWrapper>
            <Icon src={icon} alt='' />
          </IconWrapper>
          <WorkflowTitle name={name} slug={slug} />
        </TitleContainer>
        <DescriptionContainer>
          {translation(fullDescription)}
        </DescriptionContainer>
      </InfoContainer>
      <ActionsContainer>
        <RunButtonWrapper profileId={profileId} workflowId={id} drawflow={drawflow} />
        <IconGearContainer>
          <IconGear
            iconColor='var(--98989F)'
            iconHoveredColor='var(--36363D)'
            onClick={handleClickSettings}
            padding={0}
          />
        </IconGearContainer>
      </ActionsContainer>
    </WorkflowContainer>
  );
};
