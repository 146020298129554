import styled from '@emotion/styled';
import { Layout } from 'antd';
import React, { FunctionComponent } from 'react';

import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { borderRadiusContainer } from '../../../ui/style-templates';

const { Content } = Layout;

declare interface IBodyContainer {}

const Container = styled(Content)<{ newStyle: boolean }>`
  ${props => props.newStyle && borderRadiusContainer()};
  background-color: var(--FFFFFF-update-profile);
`;

const BodyContaine: FunctionComponent<IBodyContainer> = ({ children }) => (
  <Container newStyle={!!NEW_FEATURES.header}>{children}</Container>
);

export default BodyContaine;
