import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconShapeStar: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_17951_60163)'>
        <path d='M15.1421 5.43869L10.8251 4.81069L8.89813 0.899691C8.80655 0.742156 8.67524 0.611416 8.5173 0.52054C8.35936 0.429664 8.18034 0.381836 7.99813 0.381836C7.81591 0.381836 7.63689 0.429664 7.47895 0.52054C7.32101 0.611416 7.1897 0.742156 7.09813 0.899691L5.17113 4.81169L0.854126 5.43869C0.669261 5.4652 0.495515 5.54295 0.352568 5.66314C0.20962 5.78332 0.103184 5.94113 0.045314 6.11869C-0.0125556 6.29626 -0.0195456 6.48648 0.0251358 6.66781C0.0698172 6.84914 0.164384 7.01434 0.298126 7.14469L3.42113 10.1897L2.68413 14.4897C2.65262 14.6736 2.67318 14.8626 2.74351 15.0355C2.81383 15.2083 2.9311 15.358 3.08205 15.4676C3.23301 15.5773 3.41164 15.6425 3.59774 15.656C3.78383 15.6694 3.96998 15.6305 4.13513 15.5437L7.99813 13.5127L11.8591 15.5417C12.0243 15.6285 12.2104 15.6674 12.3965 15.654C12.5826 15.6405 12.7612 15.5753 12.9122 15.4656C13.0632 15.356 13.1804 15.2063 13.2507 15.0335C13.3211 14.8606 13.3416 14.6716 13.3101 14.4877L12.5731 10.1877L15.6981 7.14469C15.8315 7.01443 15.9259 6.84947 15.9705 6.66843C16.0151 6.48739 16.0082 6.29749 15.9506 6.12016C15.893 5.94283 15.7869 5.78514 15.6444 5.6649C15.5019 5.54466 15.3286 5.46665 15.1441 5.43969L15.1421 5.43869Z' />
      </g>
      <defs>
        <clipPath id='clip0_17951_60163'>
          <rect width='16' height='16' />
        </clipPath>
      </defs>
    </svg>
  </IconWrapper>
);
