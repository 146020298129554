import styled from '@emotion/styled';
import { Tabs } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import IconSharing from '../../../ui/icons/IconSharing';
import { closeProfilesSettings } from '../../../state/profiles-settings-atom';

const TabsFolder = styled(Tabs)`
  .ant-tabs {
    &-nav {
      display: flex;
      padding-bottom: 5px;
      .ant-tabs-tab {
        padding: 15px 46px;
        text-align: center;
        color: var(--000000-profile-table-old-folders);
        opacity: 0.6;
        font-weight: 500;
      }
      .ant-tabs-tab-active {
        color: var(--00B078-profile-table-old-folders);
      }
    }
  }
`;

export interface ITabFolder {
  id: string;
  name: string;
  shared?: boolean;
}

declare interface ITabProfiles {
  foldersList: any[];
  defaultActiveTabKey: string;
  changeSelectedFolder: (selectedFolder: string) => Promise<any>;
}

const TabProfiles: FC<ITabProfiles> = (props) => {
  const { t: translation } = useTranslation();

  const changeTab = async (key: string) => {
    await props.changeSelectedFolder(key);
    closeProfilesSettings();
  };

  const returnContent = () => {
    if (!(props.foldersList && props.foldersList.length)) {
      return (
        <div></div>
      );
    }

    return (
      <TabsFolder
        activeKey={props.defaultActiveTabKey}
        size='small'
        onChange={changeTab}
      >
        <Tabs.TabPane
          key='all'
          tab={translation('profiles.tabs.allProfilesTab')}
        />
        {props.foldersList.map(folderElem => (
          <Tabs.TabPane
            key={folderElem.name}
            tab={folderElem.shared ? (
              <>
                <span style={{ marginRight: '5px' }}>
                  <IconSharing />
                </span>
                {folderElem.name}
              </>
            ) : (
              <span>
                {folderElem.name}
              </span>
            )}
          />
        ))}
      </TabsFolder>
    );
  };

  return (
    <>
      {returnContent()}
    </>
  );
};

export default TabProfiles;
