import { API_BASE_URL } from '../../../common/constants/constants';
import { http } from '../../services';

export const getEmail = async (): Promise<any> => {
  const data = await http(`${API_BASE_URL}/user`, {
    method: 'GET',
  });

  return data.body;
};

export const requestUserMetadata = async (): Promise<any> => {
  const data = await http(`${API_BASE_URL}/user/metadata`);

  return data.body;
};

export const updateMigrationData = async (options: any): Promise<void> => {
  await http(`${API_BASE_URL}/gologin-settings/migration-data`, {
    method: 'PUT',
    body: JSON.stringify(options),
  });
};
