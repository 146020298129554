
import { IProxyCredentialsNew } from './components/interfaces/proxy-credentials-new.interface';
import { handleProxiesLimitError } from './utils/handle-proxies-limit-error';
import { API_BASE_URL } from '../../../common/constants/constants';
import { normalizeProxyIds } from '../../../common/utils';
import { IProxy } from '../../interfaces';
import { http } from '../../services';
import { getCurrentWorkspaceId } from '../../state/current-workspace-id.atom';

export const createProxiesRequest = async (proxies: IProxyCredentialsNew[]): Promise<IProxy[]> => {
  const workspaceId = getCurrentWorkspaceId();
  const url = new URL(`${API_BASE_URL}/proxy/add_proxies`);
  if (workspaceId) {
    url.searchParams.append('workspaceId', workspaceId);
  }

  const body = JSON.stringify({ proxies });

  return http(url.toString(), { method: 'POST', body }).then((response: { body: IProxy[] }) => response.body);
};

export type ProxyUpdateParams = {
  proxyId: string;
  proxy: IProxyCredentialsNew;
  profileId?: string;
}

export const updateProxyRequest = async ({ proxyId, proxy, profileId = '' }: ProxyUpdateParams): Promise<IProxy> => {
  const url = new URL(`${API_BASE_URL}/proxy/${proxyId}`);
  url.searchParams.append('profileId', profileId);
  const body = JSON.stringify(proxy);

  return http(url.toString(), { method: 'PUT', body })
    .then((response: { body: IProxy }) => response.body);
};

export const deleteProxiesRequest = async (proxyIds: string[]): Promise<IProxy> => {
  const normalizedProxyIds = normalizeProxyIds(proxyIds);
  const body = JSON.stringify({ proxies: normalizedProxyIds });

  return http(`${API_BASE_URL}/proxy/delete_proxies`, { method: 'POST', body }).then((response: { body: IProxy }) => response.body);
};

export const archiveProxiesRequest = async (proxyIds: string[]): Promise<IProxy> => {
  const normalizedProxyIds = normalizeProxyIds(proxyIds);
  const body = JSON.stringify({ proxyIds: normalizedProxyIds });

  return http(`${API_BASE_URL}/proxy/archive`, { method: 'POST', body }).then((response: { body: IProxy }) => response.body);
};

export const submitProxySimulateCronArchive = async (workspaceId: string, proxyIds: string[]): Promise<IProxy> => {
  const normalizedProxyIds = normalizeProxyIds(proxyIds);
  const body = JSON.stringify({ proxyIds: normalizedProxyIds, workspaceId });

  return http(`${API_BASE_URL}/proxy/archive/simulate-cron-archive`, { method: 'POST', body }).then((response: { body: IProxy }) => response.body);
};

export const restoreProxiesRequest = async (proxyIds: string[]): Promise<IProxy[]> => {
  const normalizedProxyIds = normalizeProxyIds(proxyIds);
  const workspaceId = getCurrentWorkspaceId();
  const body = JSON.stringify({ proxyIds: normalizedProxyIds, workspaceId });

  const { body: restoredProxies } = await http(`${API_BASE_URL}/proxy/archive/restore`, { method: 'POST', body })
    .catch((error) => handleProxiesLimitError(error));

  return restoredProxies;
};
