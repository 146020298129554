import * as Sentry from '@sentry/react';
import moment from 'moment';

import { ReactError } from './custom-errors';
import { IUserCtx } from '../../state';

let ipcRenderer: Electron.IpcRenderer;
const isElectron = !!window.require;
if (isElectron) {
  ({ ipcRenderer } = window.require('electron'));
}

export const setUserSentry = (user: IUserCtx): void => {
  try {
    const { _id: userId, createdAt, profileRuns = 0, plan, hasTrial, isCorrectlyRegistered } = user;

    let realPlan = plan.name;
    const planId = plan._id;
    if (hasTrial) {
      realPlan = 'trial';
    }

    const isFirstSession = isCorrectlyRegistered && profileRuns < 4 && moment.utc(createdAt) > moment.utc().subtract(7, 'days');
    Sentry.setUser({ id: userId || '' });
    Sentry.setTag('is-first-session', isFirstSession);
    Sentry.setTag('plan', realPlan);

    if (isElectron && userId) {
      ipcRenderer.invoke('set-user', { userId, isFirstSession, plan: realPlan, planId });
    }
  } catch(error: unknown) {
    const message = error instanceof Error ? error.message : 'unknown';
    Sentry.captureException(new ReactError(message), (scope) => {
      scope.setLevel(<Sentry.SeverityLevel>'warning');
      scope.setTransactionName('set-user-error');
      scope.setFingerprint(['set-user-error']);

      return scope;
    });
  }
};
