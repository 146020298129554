import React from 'react';

import { IconWrapper } from './styles';
import IconSharing from '../../../../ui/icons/IconSharing';

interface ISharingIcon {
  isShared: boolean;
}

const SharingIcon: React.FC<ISharingIcon> = (props) => {
  const { isShared } = props;

  if (!isShared) {
    return null;
  }

  return (
    <div onClick={(event): void => event.stopPropagation()}>
      <IconWrapper>
        <IconSharing />
      </IconWrapper>
    </div>
  );
};

export default SharingIcon;
