import React, { FunctionComponent } from 'react';

export interface IIconProfile {
  width?: number;
  height?: number;
}

const IconPerson: FunctionComponent<IIconProfile> = (props) => (
  <div>
    <svg width={props.width || '20'} height={props.height || '20'} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="7.5" stroke="var(--767676-icon-person)"/>
      <circle cx="10" cy="9" r="2.5" stroke="var(--767676-icon-person)"/>
      <mask id="path-3-inside-1_4627_22403" fill="var(--FFFFFF-icon-person)">
        <path fillRule="evenodd" clipRule="evenodd" d="M15.798 15.5085C14.3404 17.0431 12.2803 18 9.99667 18C7.71306 18 5.65294 17.0431 4.19531 15.5085C5.21491 14.1448 7.42924 13.2 9.99667 13.2C12.5641 13.2 14.7784 14.1448 15.798 15.5085Z"/>
      </mask>
      <path d="M15.798 15.5085L16.5231 16.1972L17.1041 15.5855L16.5989 14.9097L15.798 15.5085ZM4.19531 15.5085L3.39441 14.9097L2.88919 15.5855L3.47026 16.1972L4.19531 15.5085ZM15.073 14.8199C13.7963 16.164 11.9949 17 9.99667 17V19C12.5656 19 14.8845 17.9223 16.5231 16.1972L15.073 14.8199ZM9.99667 17C7.99841 17 6.19705 16.164 4.92037 14.8199L3.47026 16.1972C5.10882 17.9223 7.42772 19 9.99667 19V17ZM4.99621 16.1073C5.76223 15.0828 7.61785 14.2 9.99667 14.2V12.2C7.24063 12.2 4.66758 13.2069 3.39441 14.9097L4.99621 16.1073ZM9.99667 14.2C12.3755 14.2 14.2311 15.0828 14.9971 16.1073L16.5989 14.9097C15.3257 13.2069 12.7527 12.2 9.99667 12.2V14.2Z" fill="var(--767676-icon-person)" mask="url(#path-3-inside-1_4627_22403)"/>
    </svg>
  </div>
);

export default IconPerson;
