import { E_PERIOD, IDiscountShort, IPreviewPlan, IUpgradeDiscount } from './interfaces';
import { E_DISCOUNT_TYPE } from '../../interfaces';

export const CUSTOM_PLAN_2K_ID = '5e79088cff111196bf9a3731';
export const FOREVER_FREE_PLAN_ID = '5e3aaad78481ec0b30795d1e';

// export const STRIPE_API_KEY = 'pk_test_51Nzz1OCmlB0iaisIfDFv25AxwWhGpnPiCbbYKwNK9BI1Nh5FGLMgx3UR664vI7Fj5a2ExKelpKCPWQLn2Aq5mUmU00oG9qfK6J';
export const STRIPE_API_KEY = process.env.GOLOGIN_STRIPE_API_KEY;

export type BackgroundType = 'gray'|'green'|'white'|'black';
export const BACKGROUNDS: Record<BackgroundType, string> = {
  gray: 'var(--F2F2F2-new-pricing)',
  green: 'var(--00A987-new-pricing)',
  black: 'var(--36363D-new-pricing-custom)',
  white: 'var(--FFFFFF-new-pricing)',
};

const BASE_PLAN_OPTS: Omit<IPreviewPlan, 'id'> = {
  support: 'expert',
  background: 'white',
  buttonStyle: 'gray',
  isPopular: false,
  isCustomPlan: false,
  androidApp: true,
  fingerprintsDatabases: true,
  cloudBrowsing: true,
};

export const PREVIEW_PLANS: IPreviewPlan[] = [
  {
    ...BASE_PLAN_OPTS,
    id: '5e3aab2c8481ec0b30795d1f',
  },
  {
    ...BASE_PLAN_OPTS,
    id: '5e3aab4f8481ec0b30795d20',
    buttonStyle: 'green',
    isPopular: true,
  },
  {
    ...BASE_PLAN_OPTS,
    id: '5e3aab7d8481ec0b30795d21',
  },
  {
    ...BASE_PLAN_OPTS,
    id: '5e79088cff111196bf9a3731',
    support: 'personal',
    background: 'black',
    isCustomPlan: true,
  },
];

type TableRowIdType = 'header'|
  'maxProfiles'|
  'maxShares'|
  'maxAccountShares'|
  'androidApp'|
  'fingerprintsDatabases'|
  'remoteInstances'|
  'cloudBrowsing'|
  'restEnabled'|
  'footer';

export type RowType = 'header'|'value'|'boolean'|'footer';

export interface IRow {
  id: TableRowIdType;
  type: RowType;
  borderWidth: string;
}

const borderWidthValue: Record<RowType, string> = {
  header: '1px 1px 0',
  value: '0px 1px',
  boolean: '0px 1px',
  footer: '0 1px 1px',
};

export const TABLE_ROWS: IRow[] = [
  {
    id: 'header',
    type: 'header',
    borderWidth: borderWidthValue.header,
  },
  {
    id: 'maxProfiles',
    type: 'value',
    borderWidth: borderWidthValue.value,
  },
  {
    id: 'maxShares',
    type: 'value',
    borderWidth: borderWidthValue.value,
  },
  {
    id: 'maxAccountShares',
    type: 'value',
    borderWidth: borderWidthValue.value,
  },
  {
    id: 'androidApp',
    type: 'boolean',
    borderWidth: borderWidthValue.boolean,
  },
  {
    id: 'fingerprintsDatabases',
    type: 'boolean',
    borderWidth: borderWidthValue.boolean,
  },
  {
    id: 'remoteInstances',
    type: 'value',
    borderWidth: borderWidthValue.value,
  },
  {
    id: 'cloudBrowsing',
    type: 'boolean',
    borderWidth: borderWidthValue.boolean,
  },
  {
    id: 'restEnabled',
    type: 'boolean',
    borderWidth: borderWidthValue.boolean,
  },
  {
    id: 'footer',
    type: 'footer',
    borderWidth: borderWidthValue.footer,
  },
];

export const REVIEW_STARS = [5, 5, 5, 5, 4, 5, 4, 5, 5, 5, 4, 5];

export const EMPTY_DISCOUNT: IDiscountShort = {
  active: false,
  discountType: E_DISCOUNT_TYPE.PERCENT,
  value: 0,
};

export const EMPTY_UPGRADE_DISCOUNT: IUpgradeDiscount = {
  active: false,
  discountType: E_DISCOUNT_TYPE.FIX,
  value: 0,
  periodType: E_PERIOD.MONTHLY,
  amountInCurrency: {
    usd: 0,
    rub: 0,
    pkr: 0,
    bdt: 0,
  },
};

export const CENTS_IN_DOLLAR = 100;
export const DOLLARS_IN_DOLLAR = 1;
