import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled';

export const HintsContainer = styled.div`
  display: flex;
  width: 100%;
  z-index: 160;
  position: fixed;
  bottom: 48px;
  gap: 24px;
  justify-content: center;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 0px 12px 0px 12px;
`;

export const HintButton = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  cursor: pointer;
  gap: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 18px 16px 16px 20px;
  border: 1.5px solid var(--CBE7F5-hints-widget-border);
  background: var(--EDFAFF-hints-widget-bg);
  border-radius: 12px;

  &:hover {
    border-color: var(--0291C1-hints-widget-border-hover);
  }
`;

export const HintInfoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  gap: 2px;
`;

export const HintTitle = styled.div`
  font: 600 16px Gilroy;
  color: var(--36363D-hints-widget-title);
  line-height: 20px;
  letter-spacing: -1%;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

export const HintSubtitle = styled.div`
  font: 500 14px Gilroy;
  color: var(--696973-hints-widget-subtitle);
  line-height: 20px;
  letter-spacing: -1%;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;
