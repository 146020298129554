import React, { ChangeEvent, FC, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { randomWords } from '../../../../../features/common/randomizer';
import { IDefaultProps } from '../../../../../interfaces';
import { useProfilesSettingsState } from '../../../../../state/profiles-settings-atom';
import { parseTranslatedKey } from '../../../../../utils/translation.utils';
import { IChangeSetting } from '../../../interfaces';
import { AreaNoteBorder, ProfileNameContainer, TextArea } from '../../../styles';

interface IProfileName extends IChangeSetting {
  previousProfileName: string;
  isAutoGenerated: boolean;
  defaultProps: IDefaultProps;
}

export const ProfileName: FC<IProfileName> = (props) => {
  const { previousProfileName = '', changeSetting, defaultProps, isAutoGenerated } = props;

  const { profileIds } = useProfilesSettingsState();
  const [profileId] = profileIds;

  const [areaHeight, setAreaHeight] = useState<number>(20);
  const [profileName, setProfileName] = useState<string>(previousProfileName);

  const nameRef = useRef<HTMLTextAreaElement|null>(null);
  const hiddenDivRef = useRef<HTMLDivElement|null>(null);

  const { t: translation } = useTranslation();

  useEffect(() => {
    if (!profileId) {
      return;
    }

    setVariables();

    if (hiddenDivRef.current) {
      hiddenDivRef.current.innerText = previousProfileName;
      setAreaHeight(+hiddenDivRef?.current?.scrollHeight);
    }
  }, [previousProfileName, profileId, isAutoGenerated, defaultProps]);

  const setVariables = (): void => {
    let defaultProfileName = previousProfileName;
    if (isAutoGenerated && defaultProps.profileNameIsDefault) {
      defaultProfileName = parseTranslatedKey(translation('profiles.firstProfileName'));
    }

    setProfileName(defaultProfileName);
  };

  const onAreaChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    setProfileName(event.target.value);

    if (hiddenDivRef.current) {
      hiddenDivRef.current.innerText = event.target.value;
      setAreaHeight(+hiddenDivRef?.current?.scrollHeight);
    }
  };

  const onKeyPress = (event: KeyboardEvent<HTMLTextAreaElement>): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      nameRef?.current?.blur();
    }
  };

  const onAreaBlur = (): void => {
    let newProfileName = profileName;
    if (!profileName.trim()) {
      newProfileName = randomWords();
      setProfileName(newProfileName);
    }

    changeSetting({
      name: newProfileName,
      defaultProps: {
        ...defaultProps,
        profileNameIsDefault: false,
      },
    });
  };

  return (
    <AreaNoteBorder>
      <TextArea
        ref={nameRef}
        spellCheck={false}
        placeholder='Add name'
        value={profileName}
        onChange={onAreaChange}
        onBlur={onAreaBlur}
        onKeyPress={onKeyPress}
        style={{ height: areaHeight }}
        onFocus={(event) => event.currentTarget.select()}
      />
      <ProfileNameContainer ref={hiddenDivRef}>
        {profileName}
      </ProfileNameContainer>
    </AreaNoteBorder>
  );
};
