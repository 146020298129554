import React from 'react';

import { IAutomationTitle } from '../../../interfaces';
import { getWorkflowWebsiteUrl } from '../../../utils/get-workflow-website-url';
import { TitleText } from './styles';

const isElectron = !!window.require;

const WorkflowTitle: React.FC<IAutomationTitle> = (props) => {
  const { name, slug } = props;

  const handleClick: React.MouseEventHandler = () => {
    const url = getWorkflowWebsiteUrl(slug);
    isElectron ? window.require('electron').shell.openExternal(url) : window.open(url);
  };

  return (
    <TitleText onClick={handleClick}>
      {name}
    </TitleText>
  );
};

export default WorkflowTitle;
