import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconDragNDrop: React.FC<IIconWrapper> = (props) => (
  <IconWrapper iconType='fill' {...props}>
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'>
      <circle cx='6' cy='4' r='1' />
      <circle cx='6' cy='8' r='1' />
      <circle cx='6' cy='12' r='1' />
      <circle cx='10' cy='4' r='1' />
      <circle cx='10' cy='8' r='1' />
      <circle cx='10' cy='12' r='1' />
    </svg>
  </IconWrapper>
);
