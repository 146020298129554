import React from 'react';

import { IconWrapper, IIconWrapper } from '../gologin-header/icons/wrapper';

export const IconCloseV2: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
      <g opacity="0.5">
        <path d="M19.9766 5.89648L5.97656 19.8965" stroke="#696973" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square"/>
        <path d="M19.9766 19.8965L5.97656 5.89648" stroke="#696973" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square"/>
      </g>
    </svg>
  </IconWrapper>
);
