import { css, SerializedStyles } from '@emotion/core';
import styled from '@emotion/styled';
import { Popover } from '@material-ui/core';

import { GologinPopoverItemRowBasic } from '../../gologin-popover/gologin-popover-item-row';
import { textFont } from '../../style-templates';
import { greyHovered } from '../../style-templates/colors';

export const ItemRow = styled(GologinPopoverItemRowBasic)<{ newStyle?: boolean }>`
  width: 204px;
  height: 32px;

  ${(props): SerializedStyles|'' => props.newStyle ? css`
    padding: 6px 16px;
    :hover {
      background: var(--EAEAEB-header-account-menu);
    }
  ` : ''}
`;

export const MenuHeaderItem = styled('div')<{ isActive?: boolean; newStyle?: boolean }>`
  width: 204px;
  border-radius: 4px;

  ${(props): SerializedStyles|null => props.newStyle ? css`
    padding: 6px 16px 0;
    background: var(--FFFFFF-header-account-menu);
    ${props.isActive ? css`
      cursor: pointer;
      :hover {
        background: ${greyHovered};
      }
    ` : null}
  ` : null}
`;

export const MenuRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const AccountMenuPopoverCustom = styled(Popover)<{ isDarkTheme: boolean }>`
  .MuiPopover-paper {
    padding: 5px;
    width: 214px;

    background: var(--FFFFFF-header-account-menu);
    
    border-radius: 4px;
    
    ${(props): SerializedStyles|null => !props.isDarkTheme ? css`
      box-shadow: 0 3px 6px var(--0000001A-header-account-menu), 0 4px 8px var(--00000014-header-account-menu), 0 1px 12px var(--0000000A-header-account-menu);
    ` : null}
  }
`;

export const DescriptionPlan = styled('span')`
  ${textFont};
  color: var(--81818A-header-account-menu);
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.2px;
  white-space: nowrap;
`;

export const ContainerDescription = styled.div`
  display: grid;
  padding: 6px 16px 6px 24px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
`;

export const WorkspaceContainerDescription = styled(ContainerDescription)`
  padding-top: 0;
`;

export const MenuRowText = styled.span`
  ${textFont};
  color: var(--2B2B31-header-account-menu);

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
