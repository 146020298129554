import * as Sentry from '@sentry/react';

import { generateBrowserIcons } from './generate-browser-icons';
import { IGenerateBrowserIconsData } from './interfaces';
import { ITransactionObject } from '../../utils/sentry-parameters/helper.functions.interfaces';

export const generateBrowserIconsPromise = (
  options: IGenerateBrowserIconsData,
  transaction?: ITransactionObject,
): Promise<string[]> => new Promise<string[]>((resolve, reject) => {
  try {
    // Uncomment when developing browser icons
    // const font = new FontFace('Gilroy', 'url(./Gilroy-ExtraBold.ttf)');
    // font.load().then(() => {
    const iconsPngs = generateBrowserIcons(options, transaction);
    resolve(iconsPngs);
    // });
  } catch (error) {
    reject(error);
  }
});
