import { getDefaultStore } from 'jotai';

import { upsertProfileRunStatuses } from './profile-run-statuses.atom';
import { getProfilesList } from './profiles-list.atom';
import { closeProfilesSettings, getProfilesSettingsState, openProfilesSettings } from './profiles-settings-atom';
import { getBasicTableEntities } from './profiles-table/basic-table-entities.atom';
import { updateProfilesTableSelectedIds } from './profiles-table-selected-ids.atom';
import { onProfileLaunched } from './quick-pricing.atom';
import { IUpdateProfileStatusEvent } from '../../electron/interfaces/profile.status.manager.interfaces';
import { IProfile } from '../interfaces';
import PerformanceObserverService from '../services/performance-observer/performance-observer.service';
import { AutomationProfileStatusTranslationKey, ProfileStatusTranslationKey } from '../types';
import { UpdateAutomationProfileStatusEvent } from '../../electron/interfaces/automation.status.manager.interfaces';

export const onProfilesTableRowClick = (rowIndex: number): void => {
  const { isVisible, profileIds } = getProfilesSettingsState();
  if (!isVisible) {
    return;
  }

  const basicTableProfiles = getBasicTableEntities();
  const basicTableProfile = basicTableProfiles[rowIndex];
  if (!basicTableProfile) {
    return;
  }

  const rowProfileId = getDefaultStore().get(basicTableProfile.atom)?.id;
  if (!rowProfileId) {
    return;
  }

  const [currentProfileId] = profileIds;
  if (currentProfileId === rowProfileId) {
    closeProfilesSettings();

    return;
  }

  const profilesList = getProfilesList();
  const profileIdToOpen = rowProfileId || profilesList[rowIndex]?.id;
  if (profileIdToOpen) {
    openProfilesSettings([profileIdToOpen]);
  }
};

export const updateProfileStateAtLaunch = (profileToUpdate: IProfile, updateStatusToSync = false): void => {
  const { id: profileId } = profileToUpdate;
  if (updateStatusToSync) {
    const statusToSet: ProfileStatusTranslationKey = 'profileStatuses.downloadingProfile';
    upsertProfileRunStatuses([{
      id: profileId,
      status: statusToSet,
    }]);
  }
};

export const updateProfileStateAtFinish = (profileId: string, finalStatus: ProfileStatusTranslationKey): void => {
  upsertProfileRunStatuses([{
    id: profileId,
    status: finalStatus,
  }]);

  onProfileLaunched();
};

export const updateProfileStatus = (newProfileStatus: IUpdateProfileStatusEvent): void => {
  const {
    status = 'profileStatuses.ready',
    profileId,
    message: statusMessage = '',
  } = newProfileStatus;

  upsertProfileRunStatuses([{
    id: profileId,
    status,
    statusMessage,
  }]);
};

export const onUserChangeSelectedProfiles = (newSelectedRowKeys: number[]): void => {
  const selectedProfilesCount = newSelectedRowKeys.length;
  const performanceObserverService = PerformanceObserverService.getInstance();
  performanceObserverService.handleUserAction({
    userAction: 'change-selected-profiles',
    selectedProfilesCount,
  });

  const basicTableProfiles = getBasicTableEntities();
  const newSelectedProfileIds = basicTableProfiles
    .reduce<string[]>((acc, basicTableProfile, idx) => {
      if (newSelectedRowKeys.includes(idx)) {
        const profileId = getDefaultStore().get(basicTableProfile.atom).id;
        if (profileId) {
          acc.push(profileId);
        }
      }

      return acc;
    }, []);

  updateProfilesTableSelectedIds(newSelectedProfileIds);
};
