import { Icon, message, Radio, Tooltip } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useLocation } from 'react-router-dom';
import { E_ANALYTICS_ACTIONS } from '../../../../common/constants/analytics';
import { determineIsProxyTruthy, determineIsTorOrFreeProxy, TorOrFreeProxy } from '../../../../common/constants/types';
import { useQuery } from '../../../hooks';
import { IProfile, IProxy } from '../../../interfaces';
import {
  getProfileSettingsProxyForm,
  setProfileSettingsPreviousProxy,
  setProfileSettingsProxyForm,
  setProfileSettingsProxyId,
  useProfileSettingsProxyForm,
  useProfileSettingsProxyId,
} from '../../../state/proxy/proxy-in-profile-settings.atom';
import {
  getProxyByArtificialProxyId,
  getProxyListProxyById,
  useProxyListProxyById,
} from '../../../state/proxy/proxy-list.atom';
import { setShouldShowProxyType, useShouldShowProxyType } from '../../../state/should-show-proxy-type';
import IconPasteHttp from '../../../ui/icons/IconPastHttp';
import { proxyFromClipboard } from '../../../utils/proxy-string';
import { sendActionAnalytics } from '../../common/api';
import { DEFAULT_PROXIES } from '../../proxy/constants';
import { getNoIdProxyId } from '../../proxy/utils/proxy-id';
import { DEFAULT_GOLOGIN_PROXY_REGION } from './customProxies/gologin-proxy-form';
import ProxyTabCustomForm, { EMPTY_FORM_PROXY } from './customProxies/proxy-tab-custom-form';
import { DEFAULT_TOR_PROXY_REGION } from './customProxies/tor-proxy-form';
import { ElementContainer, PasteIconContainer, RadioButton, TypeRow } from './domElements';
import GeolocationProxyFormContainer from './geolocation/geolocation-proxy-form-container';

type ProxyTabProps = {
  updateProfileInfo: (partialProfile: Partial<IProfile>) => void;
  profileInfo: any;
  profileId?: string;
  setUpdatedField: (field: string) => any;
  isSaveButtonPressed?: boolean;
  setIsGeolocationScreen?: (arg: boolean) => void;
  saveProfile?: () => void;
  isCreateProfile: boolean;
  hasLoaded: boolean;
};

const CUSTOM_CONNECTION_TYPE = 'custom';
const proxyTypes = ['direct', 'geolocation', 'custom'] as const;
type ProxyType = typeof proxyTypes[number];

export type UpdateProxyFn = (partialProxy: Partial<IProxy> | null) => void;

const ProxyTab: React.FC<ProxyTabProps> = props => {
  const { profileId, profileInfo, hasLoaded, isCreateProfile, updateProfileInfo } = props;
  const { proxy: profileProxy, proxyEnabled, archivedProxy = null } = profileInfo;

  const location = useLocation();
  const proxyId = useProfileSettingsProxyId();
  const currentListProxy = useProxyListProxyById(proxyId);
  const currentProxy = useProfileSettingsProxyForm() || archivedProxy || { ...EMPTY_FORM_PROXY };
  const shouldShowType = useShouldShowProxyType();

  const proxyQueryParam = useQuery().get('proxy');
  const defaultConnectionType: ProxyType = isCreateProfile ? 'custom' : 'direct';
  const proxyTypeInitial: ProxyType =
    shouldShowType && isCreateProfile
      ? shouldShowType
      : proxyTypes.find(type => type === proxyQueryParam) || defaultConnectionType;

  const [connectionType, setConnectionType] = useState<ProxyType>(proxyTypeInitial);
  const [geolocationProxy, setGeolocationProxy] = useState<any>({});
  const [isCheckInterrupted, setIsCheckInterrupted] = useState<boolean>(false);

  const { t: translation } = useTranslation();

  useEffect(() => {
    if (!(isCreateProfile && connectionType === 'geolocation')) {
      return;
    }

    sendActionAnalytics(E_ANALYTICS_ACTIONS.visitedGologinProxyTabInNewBrowserProfile);
  }, [connectionType, isCreateProfile]);

  const replaceProxy = (newProxy: IProxy | null): void => {
    let proxyToSet = newProxy;
    if (!proxyToSet) {
      proxyToSet = { ...EMPTY_FORM_PROXY };
    }

    updateProfileInfo({
      proxy: proxyToSet,
      proxyEnabled: proxyToSet.mode !== 'none',
    });

    if (proxyToSet.mode !== 'geolocation') {
      setGeolocationProxy({});
    }

    if (!newProxy) {
      setProfileSettingsProxyForm(null);

      return;
    }

    // should not set geolocation data into the form
    let proxyToSetToForm = proxyToSet;
    if (proxyToSetToForm.mode === 'geolocation') {
      proxyToSetToForm = { ...EMPTY_FORM_PROXY, mode: 'geolocation' };
    }

    setProfileSettingsProxyForm(proxyToSetToForm);
  };

  const updateProxy: UpdateProxyFn = partialProxy => {
    if (!partialProxy) {
      replaceProxy(null);

      return;
    }

    const defaultProxy = DEFAULT_PROXIES.find(
      ({ mode, country }) => mode === partialProxy.mode && country.toLowerCase() === profileProxy.torProxyRegion,
    );

    const prevProxy = getProfileSettingsProxyForm() || { ...EMPTY_FORM_PROXY };
    const newProxy = defaultProxy || { ...prevProxy, ...partialProxy };
    delete newProxy.checkDate;
    replaceProxy(newProxy);
  };

  const updateTorOrFreeProxy = (torOrFreeProxy: TorOrFreeProxy): void => {
    const torOrFreeProxyFields: Partial<IProxy> = { mode: torOrFreeProxy.mode };
    if (currentProxy.mode === 'gologin') {
      const country = currentProxy.autoProxyRegion || DEFAULT_GOLOGIN_PROXY_REGION;
      torOrFreeProxyFields.country = country;
      torOrFreeProxyFields.autoProxyRegion = country;
    } else {
      const country = currentProxy.torProxyRegion || DEFAULT_TOR_PROXY_REGION;
      torOrFreeProxyFields.country = country;
      torOrFreeProxyFields.torProxyRegion = country;
    }

    updateProxy(torOrFreeProxyFields);
  };

  useEffect(() => {
    if (!hasLoaded) {
      return;
    }
   

    let newId: string | null = profileProxy.id;
    if (!newId && determineIsProxyTruthy(profileProxy)) {
      newId = getNoIdProxyId(profileProxy);
    }

    if (newId && newId !== proxyId) {
      setProfileSettingsProxyId(newId);
      setProfileSettingsPreviousProxy({ ...profileProxy });
    }

    const { mode } = currentProxy;
    let type: ProxyType = proxyTypeInitial;
    if (proxyEnabled) {
      type = mode === 'geolocation' ? 'geolocation' : CUSTOM_CONNECTION_TYPE;
    }

    if (shouldShowType && isCreateProfile) {
      setConnectionType(shouldShowType);
      return;
    }

    setConnectionType(type);

    return () => {
      clearProxy();
    };
  }, [profileId, hasLoaded, isCreateProfile]);

  useEffect(() => {
    if (!proxyId) {
      if (determineIsTorOrFreeProxy(currentProxy)) {
        // if first user selected a proxy in the selector
        // and then opted for tor/free in the form
        // we need to reset the selector but not the form
        updateTorOrFreeProxy(currentProxy);
      } else {
        clearProxy();
      }

      return;
    }

    let proxyToSet;
    let proxyListProxy = getProxyListProxyById(proxyId);
    if (!proxyListProxy) {
      proxyListProxy = getProxyByArtificialProxyId(proxyId);
      proxyToSet = { ...proxyListProxy };
      delete proxyToSet.id;
    } else {
      proxyToSet = { ...proxyListProxy, id: proxyId };
    }

    if (!proxyToSet.host) {
      proxyToSet = { ...profileProxy };
    }

    replaceProxy(proxyToSet);
  }, [proxyId]);

  useEffect(() => {
    const prevProxy = getProfileSettingsProxyForm() || { ...EMPTY_FORM_PROXY };
    updateProxy({ ...prevProxy, ...currentListProxy });
  }, [currentListProxy]);

  const handleClickPaste: React.MouseEventHandler<HTMLDivElement> = async event => {
    event.preventDefault();
    event.stopPropagation();

    setIsCheckInterrupted(true);
    const pastedProxy = await proxyFromClipboard();
    if (!pastedProxy) {
      return message.error(<Trans i18nKey='notifications.error.invalidProxy' />);
    }

    updateProxy(pastedProxy);
    sendActionAnalytics('pasted proxy from buffer');
    setConnectionType(CUSTOM_CONNECTION_TYPE);
  };

  const showIconPasteProxy = () => {
    const { mode } = currentProxy;
    if (!['none', 'http', 'socks4', 'socks5'].includes(mode)) {
      return null;
    }

    return (
      <Tooltip
        title={
          <span style={{ fontSize: 12, color: 'var(--000000-antd-tooltip-opacity)' }}>
            {translation('newProfile.proxy.paste.help')}
          </span>
        }
      >
        <PasteIconContainer onClick={handleClickPaste}>
          <IconPasteHttp />
        </PasteIconContainer>
      </Tooltip>
    );
  };

  const getProxyModeTooltipInfo = () => {
    const { mode } = currentProxy;
    if (!['gologin', 'tor'].includes(mode)) {
      return null;
    }

    let tooltipTitleTxt = translation('proxy.connection.gologin.help');
    if (mode === 'tor') {
      tooltipTitleTxt = translation('proxy.connection.tor.help');
    }

    return (
      <Tooltip title={tooltipTitleTxt}>
        <Icon style={{ marginLeft: 10, cursor: 'pointer' }} type='info-circle' />
      </Tooltip>
    );
  };

  const getProxiesForm = (): JSX.Element | null => {
    if (connectionType === CUSTOM_CONNECTION_TYPE) {
      return (
        <ProxyTabCustomForm
          profileId={profileId}
          proxyId={proxyId}
          currentProxy={currentProxy}
          updateProxy={updateProxy}
          setUpdatedField={props.setUpdatedField}
          isCheckInterrupted={isCheckInterrupted}
          setIsCheckInterrupted={setIsCheckInterrupted}
        />
      );
    }

    if (connectionType === 'geolocation') {
      return (
        <GeolocationProxyFormContainer
          profileInfo={props.profileInfo}
          profileId={props.profileId}
          updateProfileInfo={props.updateProfileInfo}
          geolocationProxy={geolocationProxy}
          setGeolocationProxy={setGeolocationProxy}
          isSaveButtonPressed={props.isSaveButtonPressed}
          setIsGeolocationScreen={props.setIsGeolocationScreen}
          saveProfile={props.saveProfile}
          isCreateProfile={props.isCreateProfile}
        />
      );
    }

    return null;
  };

  const clearProxy = (): void => {
    updateProxy(null);
    setProfileSettingsProxyId(null);
  };

  const changeConnectionType = (type: ProxyType): void => {
    if (profileInfo.proxy?.mode !== 'geolocation' || type === 'direct') {
      clearProxy();
    }

    setConnectionType(type);
  };

  const renderGeolocationProxyButton = (): JSX.Element => (
    <RadioButton value='geolocation'>
      <Trans i18nKey='proxy.changeProxy.geolocation' />
    </RadioButton>
  );

  const handleConnectionTypeChange = (newType: ProxyType) => {
    if (isCreateProfile) {
      setShouldShowProxyType(newType);
    }
   
    setConnectionType(newType);

    if (newType === 'direct' && !isCreateProfile) {
      clearProxy();
      updateProfileInfo({
        proxyEnabled: false,
      });
    }
  };

  return (
    <>
      <ElementContainer>
        <TypeRow>
          <Radio.Group
            value={connectionType}
            onChange={(e: RadioChangeEvent) => handleConnectionTypeChange(e.target.value)}
            disabled={!hasLoaded}
          >
            {renderGeolocationProxyButton()}
            <RadioButton value='custom'>
              <Trans i18nKey='proxy.changeProxy.custom' />
            </RadioButton>
            <RadioButton value='direct'>
              <Trans i18nKey='proxy.changeProxy.direct' />
            </RadioButton>
          </Radio.Group>
          {showIconPasteProxy()}
          {getProxyModeTooltipInfo()}
        </TypeRow>
      </ElementContainer>
      <div style={{ marginBottom: 100 }}>{getProxiesForm()}</div>
    </>
  );
};

export default ProxyTab;
