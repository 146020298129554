import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconWarning: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_12869_3826)'>
        <path d='M9.16406 12C9.16406 12.2761 8.9402 12.5 8.66406 12.5C8.38792 12.5 8.16406 12.2761 8.16406 12C8.16406 11.7239 8.38792 11.5 8.66406 11.5C8.9402 11.5 9.16406 11.7239 9.16406 12Z' fill='var(--F5222D)' stroke='var(--F5222D)' />
        <path d='M8.66406 4.5V9.5' stroke='var(--F5222D)' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M1.4051 12.7768L7.6341 1.20877C7.73428 1.02284 7.88293 0.867485 8.06428 0.759204C8.24562 0.650923 8.45289 0.59375 8.6641 0.59375C8.87531 0.59375 9.08258 0.650923 9.26392 0.759204C9.44526 0.867485 9.59391 1.02284 9.6941 1.20877L15.9231 12.7768C16.0189 12.955 16.0669 13.155 16.0623 13.3573C16.0577 13.5595 16.0007 13.7572 15.897 13.9308C15.7932 14.1045 15.6462 14.2483 15.4702 14.3482C15.2943 14.4481 15.0954 14.5007 14.8931 14.5008H2.4351C2.23277 14.5007 2.03393 14.4481 1.85798 14.3482C1.68204 14.2483 1.535 14.1045 1.43123 13.9308C1.32746 13.7572 1.2705 13.5595 1.26592 13.3573C1.26135 13.155 1.3093 12.955 1.4051 12.7768V12.7768Z' stroke='var(--F5222D)' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_12869_3826'>
          <rect width='16' height='16' fill='var(--FFFFFF)' transform='translate(0.664062)' />
        </clipPath>
      </defs>
    </svg>
  </IconWrapper>
);
