import styled from '@emotion/styled';
import { Button, Icon, Table } from 'antd';
import { format } from 'date-fns';
import React, { FC, memo, useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { cloneS3Profile, requestProfileHistory } from './api';
import GologinModal from '../../../../ui/gologin-modal';

let ipcRenderer: Electron.IpcRenderer;
const isElectron = !!window.require;

if (isElectron) {
  ipcRenderer = window.require('electron').ipcRenderer;
}

const ContainerLoader = styled('div')`
  font-size: 32px;
  text-align: center;
`;

const DescriptionModalTxt = styled('div')`
  padding: 4px;
  margin-bottom: 10px;
  font-size: 14px;
  color: var(--000000A6-antd);
`;

interface IHistoryModal {
  profileId: string;
  visible: boolean;
  onClose: () => any;
  afterClose?: () => void;
}

const HistoryModal: FC<IHistoryModal> = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [profileUploads, setProfileUploads] = useState<any[]>([]);

  const { t: translation } = useTranslation();

  const updateProfileHistory = async () => {
    setIsLoading(true);
    const profileHistory = await requestProfileHistory(props.profileId);

    setProfileUploads(profileHistory);
    setIsLoading(false);
  };

  const cloneProfile = async (key: string) => {
    const clonedProfile = await cloneS3Profile(props.profileId, key);
    if (clonedProfile.id) {
      ipcRenderer && ipcRenderer.invoke('clear-profile-cache', props.profileId);
    }
    setProfileUploads([clonedProfile, ...profileUploads]);
  };

  useEffect(() => {
    if (props.profileId) {
      updateProfileHistory();
    }
  }, [props.profileId]);

  return (
    <GologinModal
      isVisible={props.visible}
      onClose={props.onClose}
      width='520px'
      title={translation('historyModal.title')}
    >
      {isLoading ? (
        <ContainerLoader>
          <Icon type='loading'/>
        </ContainerLoader>
      ) : (
        <>
          <DescriptionModalTxt>
            <Trans i18nKey='historyModal.help' t={translation}>
              Continue work from any of the listed sessions. To do that, <b>restore</b> the session needed and run the profile. The <b>current</b> session will be erased.
            </Trans>
          </DescriptionModalTxt>

          <Table dataSource={profileUploads} pagination={false} rowKey={record => record.id} locale={{ emptyText: 'No Data' }}>
            <Table.Column
              title={translation('historyModal.table.columns.date')}
              dataIndex='lastModified'
              render={(v: string) => format(new Date(v), 'MMM dd, h:mm a')}
            />
            <Table.Column
              title={translation('historyModal.table.columns.size')}
              dataIndex='size'
              render={(v: number) => `${(v / (1024 ** 2)).toFixed(2)}MB`}
            />
            <Table.Column
              dataIndex='id'
              render={(v: string, record: any, index: number) => index === 0 ? translation('historyModal.currentSessionLabel') : <Button onClick={() => cloneProfile(v)}>{translation('historyModal.restoreBtn.label')}</Button>}
            />
          </Table>
        </>
      )}
    </GologinModal>
  )
}

export default memo(HistoryModal);
