import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { IDeletedProfile, IDeletedProfilesTable } from '../interfaces';

const DEFAULT_DELETED_PROFILES_TABLE: IDeletedProfilesTable = {
  deletedProfilesList: [],
  isMoreDeletedProfilesAvailable: false,
};

const deletedProfilesTableAtom = atom<IDeletedProfilesTable>(DEFAULT_DELETED_PROFILES_TABLE);

export const useDeletedProfilesTable = (): IDeletedProfilesTable => useAtomValue(deletedProfilesTableAtom);

export const getDeletedProfilesList = (): IDeletedProfile[] => getDefaultStore()
  .get(deletedProfilesTableAtom).deletedProfilesList;

export const getIsMoreDeletedProfilesAvailable = (): boolean => getDefaultStore()
  .get(deletedProfilesTableAtom).isMoreDeletedProfilesAvailable;

export const setDeletedProfilesList = (deletedProfilesList: IDeletedProfile[]): void => {
  const oldDeletedProfilesTable = getDefaultStore().get(deletedProfilesTableAtom);
  getDefaultStore().set(deletedProfilesTableAtom, { ...oldDeletedProfilesTable, deletedProfilesList });
};

export const setIsMoreDeletedProfilesAvailable = (isMoreDeletedProfilesAvailable: boolean): void => {
  const oldDeletedProfilesTable = getDefaultStore().get(deletedProfilesTableAtom);
  getDefaultStore().set(deletedProfilesTableAtom, { ...oldDeletedProfilesTable, isMoreDeletedProfilesAvailable });
};
