import css from '@emotion/css';
import styled from '@emotion/styled/macro';

export const ProxySelectorButtonsWrapper = styled.div<{ isVisible?: boolean }>`
  margin: 0 0 0 16px;
  display: flex;
  white-space: nowrap;
  visibility: ${(props): React.CSSProperties['visibility'] => props.isVisible ? 'visible' : 'hidden'};
`;

const proxySelectorButtonBaseStyle = css`
  outline: none;
  text-decoration: none;
`;

export const ProxySelectorButton = styled.button<{ isInNewDrawer?: boolean }>`
  width: 16px;
  height: 16px;
  padding: 0;
  border: none;
  margin-left: 12px;

  cursor: pointer;
  background: none;
  color: ${(props): React.CSSProperties['color'] => props.isInNewDrawer ?
    'var(--98989F-proxy-manager-icon-buttons)' : 'var(--767676-proxy-manager-icon-buttons)'};

  ${proxySelectorButtonBaseStyle}

  &:focus,
  &:visited {
    ${proxySelectorButtonBaseStyle}
    color: ${(props): React.CSSProperties['color'] => props.isInNewDrawer ?
    'var(--98989F-proxy-manager-icon-buttons)' : 'var(--767676-proxy-manager-icon-buttons)'};
  }

  &:hover {
    ${proxySelectorButtonBaseStyle}
    color: ${(props): React.CSSProperties['color'] => props.isInNewDrawer ?
    'var(--2B2B31-proxy-manager-icon-buttons)' : 'var(--00A987-proxy-manager)'};
  }
`;
