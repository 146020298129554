import styled from '@emotion/styled/macro';

export const CreateFolderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 2px;
`;

export const CreateFolderTxt = styled('div')`
  font-family: Roboto;
  width: 266px;
  text-align: center;
  font-size: 14px;
  line-height: 19px;
  color: var(--676767);
  margin-bottom: 16px;
  margin-top: 8px;
`;

export const CreateFolderBtn = styled('button')`
  font-size: 16px;
  line-height: 19px;
  width: 248px;
  height: 39px;
  background: var(--64BA88);
  color: var(--FFFFFF);
  cursor: pointer;
  border: none;
  border-radius: 2px;
  outline: none;
`;
