export const removeArrayDuplicates = <T extends { id: string }>(arr: T[]): T[] => {
  const seen = new Set<string>();

  return arr.filter(item => {
    if (seen.has(item.id)) {
      return false;
    }

    seen.add(item.id);

    return true;
  });
};

