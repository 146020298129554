import { useAtomValue } from 'jotai';
import React, { FC } from 'react';

import { IProxy } from '../../../../interfaces';
import { NEW_FEATURES } from '../../../../state/feature-toggle/new-features';
import { IBasicTableProfile } from '../../../../state/profiles-table/basic-table-entities-subtypes';
import { useProxyList } from '../../../../state/proxy/proxy-list.atom';
import TooltipWrapper from '../../../../ui/tooltip-custom/tooltip-wrapper';

interface IProps {
  profileAtom: IBasicTableProfile['atom'];
  paramName: keyof IProxy;
}

const ProxyParamCell: FC<IProps> = (props) => {
  const {
    profileAtom,
    paramName,
  } = props;

  let { proxy } = useAtomValue(profileAtom);
  const proxyList = useProxyList();

  let proxyRes = proxy;

  const proxyId = proxyRes.id;
  if (proxyId) {
    proxyRes = proxyList.find(listedProxy => listedProxy.id === proxyId);

    if (!proxyRes) {
      proxyRes = proxy;
    }
  }

  proxy = proxyRes;

  if (!proxy || proxy.mode === 'none') {
    return (
      <div></div>
    );
  }

  if (NEW_FEATURES.header) {
    return (
      <TooltipWrapper
        hasTooltipAnimation={true}
        isTooltipHoverable={true}
        value={proxy[paramName]}
      >
        <span>
          {proxy[paramName]}
        </span>
      </TooltipWrapper>
    );
  }

  return (
    <div>
      {proxy[paramName]}
    </div>
  );
};

export default ProxyParamCell;
