import { format } from 'date-fns';
import { useAtomValue } from 'jotai';
import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import TooltipWrapper from '../../../ui/tooltip-custom/tooltip-wrapper';
import { ITableCell } from '../interfaces';
import { TextCell } from '../table/styles';

export type DateCellField = 'updatedAt' | 'lastActivity' | 'createdAt';

interface IDateCell extends ITableCell {
  field: DateCellField;
}

const DateCell: FC<IDateCell> = (props) => {
  const { profileAtom, field } = props;

  const { t: translation } = useTranslation();

  const profile = useAtomValue(profileAtom);
  const date = profile[field];

  if (!date) {
    return null;
  }

  if (NEW_FEATURES.header) {
    const tooltipText = field === 'lastActivity' ? translation('tooltip.profilesTable.lastLaunched') + ' ' : '';

    return (
      <TextCell>
        <TooltipWrapper
          hasTooltipAnimation={true}
          isTooltipHoverable={true}
          value={tooltipText + format(new Date(date), 'MMMM d, yyyy pp')}
        >
          <span>
            {format(new Date(date), 'MMM d, yyyy p')}
          </span>
        </TooltipWrapper>
      </TextCell>
    );
  }

  return (
    <span>
      {format(new Date(date), 'dd/MM/yyyy')}
    </span>
  );
};

export default memo(DateCell);
