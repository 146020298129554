import React from 'react';

import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { openProfilesTableModal, PROFILE_TABLE_COLUMNS_MODAL_NAME } from '../../../state/profiles-table-modal.atom';
import IconSetting from '../../../ui/icons/IconSetting';
import { sendActionAnalytics } from '../../common/api';
import TabProfiles from '../components/tab-profile';
import { ContainerNameCol, ProfilesTabsContainer } from '../table/styles';

const OldTableHeaderControls = () => {
  const openColumnsModal = (): void => {
    openProfilesTableModal(PROFILE_TABLE_COLUMNS_MODAL_NAME);
    sendActionAnalytics('opened edit columns').catch(() => null);
  };

  if (NEW_FEATURES.header) {
    return null;
  }

  return (
    <ProfilesTabsContainer>
      <TabProfiles />
      <ContainerNameCol style={{ cursor: 'pointer' }} onClick={openColumnsModal} >
        <div style={{ marginRight: 4 }}>
          <IconSetting />
        </div>
      </ContainerNameCol>
    </ProfilesTabsContainer>
  );
};

export default OldTableHeaderControls;
