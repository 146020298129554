import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled';

export const Container = styled.div<{ isMenuShown: boolean; paddingRight: number }>`
  padding-right: ${(props): number => props.paddingRight}px;
  border-radius: 8px;
  margin-right: 8px;
  font-size: 14px;

  display: flex;
  position: relative;
  z-index: 160;
  
  font-family: Roboto;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
    background: var(--EAEAEB)
  }  

  ${(props): SerializedStyles|null => props.isMenuShown ? css`
    background: 'var(--EAEAEB)';
  ` : null}
`;
