import { IApply, INewNameProfile, IOptionsToApply, IParseNameFormatting, IPattern } from './interfaces';
import { createTemplate, updateTemplateBrowser } from '../../features/personalArea/browser-template/api';
import { ITemplatesCtx } from '../../state';

type ParserFn = (pattern: string) => IPattern;
type ApplierFn = (pattern: IPattern, options: IOptionsToApply) => { name: string };
type ConvertSpintaxToStringFn = (pattern: IPattern) => string;

export const parse = (patternsString: string): IPattern[] => {
  const patternParts = patternsString.match(/(?:\{[^}]*\})|(\{)|\}|([^{}]+)/g) || [];

  return patternParts.map(part => {
    const match = part.match(/\{([^{}]*)\}/);
    if (match && match[1].trim()) {
      return parsePattern(match[1]);
    }

    return { literal: part };
  });
};

export const parsePattern: ParserFn = (pattern) => {
  const parsers: ParserFn[] = [parseDate, parseStartAt, parseProfileName, parseProfileEmail];

  for (const parser of parsers) {
    const result = parser(pattern);
    if (!result.error) {
      return result;
    }
  }

  return { literal: pattern };
};

const checkIsSingleEmailPattern = (patterns: IPattern[]): boolean => (
  Object.keys(patterns).length === 1 && !!patterns[0].email
);

export const apply = (applyParam: IApply): INewNameProfile[] => {
  const { profiles, patternObjects } = applyParam;

  return profiles.map((profile, index) => {
    let name = '';
    const options: IOptionsToApply = { index, profileName: profile.name, email: profile.email };

    const appliers: ApplierFn[] = [
      applyLiteral,
      applyStartAt,
      applyDate,
      applyProfileName,
      applyProfileEmail,
    ];

    const isSingleEmailPattern = checkIsSingleEmailPattern(patternObjects);
    options.isSingleEmailPattern = isSingleEmailPattern;

    patternObjects.forEach(pattern => {
      appliers.forEach(applier => {
        const { name: nameApplier } = applier(pattern, options);
        name += nameApplier;
      });
    });

    return { ...profile, name: name.trim() || 'profile' };
  });
};

export const applyLiteral: ApplierFn = (pattern) => ({ name: pattern.literal || '' });

export const applyStartAt: ApplierFn = (pattern, options) =>
  ({ name: pattern.startAt ? String(pattern.startAt + (options.index || 0)) : '' });

export const applyDate: ApplierFn = (pattern) => ({ name: pattern.date ? new Date().toLocaleDateString('en-GB') : '' });

export const applyProfileName: ApplierFn = (pattern, options) =>
  ({ name: pattern.profileName ? (options.profileName || 'profile name') : '' });

export const applyProfileEmail: ApplierFn = (pattern, options) => {
  const name = '';

  if (!pattern.email) {
    return { name };
  }

  if (options.email) {
    return { name: options.email };
  }

  if (options.isSingleEmailPattern) {
    return { name: options.profileName || 'profile' };
  }

  return { name };
};

export const parseDate: ParserFn = (pattern) => {
  if (!/^today$/.test(pattern.toLowerCase())) {
    return { error: true };
  }

  return { date: true };
};

export const parseStartAt: ParserFn = (pattern) => {
  const match = pattern.toLowerCase().match(/^number from (\d+)$/);
  if (match) {
    return { startAt: Number(match[1]) };
  }

  return { error: true };
};

export const parseProfileName: ParserFn = (pattern) => {
  if (!/^profile name$/.test(pattern.toLowerCase())) {
    return { error: true };
  }

  return { profileName: true };
};

export const parseProfileEmail: ParserFn = (pattern) => {
  if (!/^email$/.test(pattern.toLowerCase())) {
    return { error: true };
  }

  return { email: true };
};

export const parseNameFormatting = (formattingParam: IParseNameFormatting): INewNameProfile[] => {
  const { profiles = [], nameFormat } = formattingParam;
  const patternObjects = parse(nameFormat);

  return apply({ patternObjects, profiles });
};

export const convertSpintaxToString = (patternObjects: IPattern[]): string => {
  const converters: ConvertSpintaxToStringFn[] = [
    convertSpintaxToStringLiteral,
    convertSpintaxToStringStartAt,
    convertSpintaxToStringDate,
    convertSpintaxToStringEmail,
  ];

  let name = '';
  patternObjects.forEach(pattern => {
    converters.forEach(converter => {
      name += converter(pattern);
    });
  });

  return name.trim();
};

export const convertSpintaxToStringLiteral: ConvertSpintaxToStringFn = (pattern): string => pattern.literal || '';

export const convertSpintaxToStringStartAt: ConvertSpintaxToStringFn = (pattern): string =>
  pattern.startAt ? `{number from ${pattern.startAt}}` : '';

export const convertSpintaxToStringDate: ConvertSpintaxToStringFn = (pattern): string => pattern.date ? '{today}' : '';

export const convertSpintaxToStringEmail: ConvertSpintaxToStringFn = (pattern): string => pattern.email ? '{email}' : '';

export const getUpdatedSpintaxNumber = (spintax: string, count: number): string => {
  const patternObjects = parse(spintax);
  const updatedPatternObjects = patternObjects.map(pattern => {
    if (!pattern.startAt) {
      return pattern;
    }

    const updatedNumberSpintax = pattern.startAt + count;

    return { ...pattern, startAt: updatedNumberSpintax };
  });

  return convertSpintaxToString(updatedPatternObjects);
};

export const updateTemplateProfileName = async (spintax: string, profileCount: number, templateCtx: ITemplatesCtx): Promise<void> => {
  const { selectedTemplate, updateSelectedTemplate } = templateCtx;
  const updatedSpintax = getUpdatedSpintaxNumber(spintax, profileCount);
  const newTemplate = {
    ...selectedTemplate,
    profileName: {
      ...selectedTemplate.profileName,
      dropFiles: updatedSpintax,
    },
  };

  const updatedTemplate = !selectedTemplate.id ? await createTemplate(newTemplate) : await updateTemplateBrowser(newTemplate);
  updateSelectedTemplate(updatedTemplate);
};
