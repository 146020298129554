import { history } from '../../../services';
import { retrieveToken } from '../../../services/http/storage';

export const checkAuthentication = async (): Promise<void> => {
  const token = await retrieveToken();

  if (token) {
    history.replace('/profileList');
  }
};
