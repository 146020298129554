import Popper from '@material-ui/core/Popper';
import React, { useState, useContext } from 'react';
import { Trans } from 'react-i18next';

import { ResetButtonWrapper, ResetButtonIconWrapper, ResetButtonInfoWrapper, ResetButtonInfo } from './styles';
import { ProxyPageAnalyticsEvent } from '../../../../../../common/constants/analytics';
import { PROXY_PAGE } from '../../../../../../common/constants/routes';
import { history } from '../../../../../services';
import { tagsContext } from '../../../../../state';
import { useProfilesTableProxyIdFilter } from '../../../../../state/profiles-proxy-filter.atom';
import IconArrowBack from '../../../../../ui/icons/IconArrowBack';
import { TagField } from '../../../../tags/interfaces/tag.interface';
import { sendActionAnalytics } from '../../../api';
import { setSelectedTagId } from '../../../../../state/tags/selected-tag.atom';

export type ColumnFilterResetButtonField = TagField|'proxy-id';

const resetButtonTranslationKeys: Record<ColumnFilterResetButtonField, string> = {
  'tags': 'tags.showAllTags',
  'custom-status': 'tags.showAllStatuses',
  'proxy-id': 'tableProfiles.tooltip.showAllProxies',
};

interface IColumnFilterResetButton {
  field: ColumnFilterResetButtonField;
}

const ColumnFilterResetButton: React.FC<IColumnFilterResetButton> = (props) => {
  const { field } = props;

  const [anchorInfoEl, setAnchorInfoEl] = useState<HTMLElement|null>(null);

  const { selectedTag } = useContext(tagsContext);
  const profilesTableProxyIdFilter = useProfilesTableProxyIdFilter();

  const resetButtonTranslationKey = resetButtonTranslationKeys[field];
  const isProxyColumnResetButton = field === 'proxy-id';
  const isResetButtonVisible: boolean = isProxyColumnResetButton ? !!profilesTableProxyIdFilter : field === selectedTag?.field;

  const handleMouseEnter = async (event: React.MouseEvent<HTMLElement, MouseEvent>): Promise<void> => setAnchorInfoEl(event.currentTarget);
  const handleMouseLeave = (): void => setAnchorInfoEl(null);

  const handleClick: React.MouseEventHandler<HTMLDivElement> = () => {
    if (isProxyColumnResetButton) {
      sendActionAnalytics(ProxyPageAnalyticsEvent.visitedProxyPageFromConnectedProfile);

      return history.push(PROXY_PAGE);
    }

    setSelectedTagId(null);
  };

  if (!isResetButtonVisible) {
    return null;
  }

  return (
    <ResetButtonWrapper
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ResetButtonIconWrapper>
        <IconArrowBack />
      </ResetButtonIconWrapper>
      <Popper
        open={!!anchorInfoEl}
        anchorEl={anchorInfoEl}
        style={{ zIndex: 11000 }}
        placement='top'
      >
        <ResetButtonInfoWrapper>
          <ResetButtonInfo>
            <Trans i18nKey={resetButtonTranslationKey} />
          </ResetButtonInfo>
        </ResetButtonInfoWrapper>
      </Popper>
    </ResetButtonWrapper>
  );
};

export default ColumnFilterResetButton;
