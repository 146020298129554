export const defineDelimeterAndCutCookies = (data: string): {
    cookies: string;
    newDataCookiesRemoved: string;
    delimeter: string;
  } => {
  const undefinedValStr = 'undefined';
  const splittedData = data.split('');
  const closeToOpen: Record<string, string> = { ']': '[' };

  let startIndex: number | null;
  let finishIndex: number | null;
  let cookiesString = '';
  let otherData = '';
  let stack: string[] = [];

  let delimeter = '';

  splittedData.forEach((value: string, index: number) => {
    const isSymbolOfCookies = typeof startIndex !== undefinedValStr && !finishIndex;

    if (isSymbolOfCookies) {
      cookiesString = cookiesString + value;
    } else {
      otherData = otherData + value;
    }

    if (!['[', ']'].includes(value)) {
      return;
    }

    const isClose = closeToOpen[value];

    if (index && !delimeter) {
      delimeter = splittedData[index - 1];
    }

    if (isClose) {
      const openValue = closeToOpen[value];
      const prevValue = stack[stack.length - 1];

      if (prevValue === openValue) {
        stack = stack.slice(0, -1);
      }

      if (!stack.length) {
        finishIndex = index;
      }
    } else {
      const isFirstSymbolCookie = typeof startIndex === undefinedValStr;

      if (isFirstSymbolCookie) {
        startIndex = index;
        cookiesString = cookiesString + value;

        otherData = otherData.slice(0, -1);
      }

      stack.push(value);
    }
  });

  return {
    cookies: cookiesString,
    newDataCookiesRemoved: otherData,
    delimeter,
  };
};
