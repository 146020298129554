import { ProxyMode } from '../../app/interfaces';
import { ProxyProtocol, ProxyProtocols } from '../types/proxy';

export const changeableProtocols = [ProxyProtocols.HTTP, ProxyProtocols.SOCKS5, ProxyProtocols.SOCKS4];
type ChangeableProtocol = typeof changeableProtocols[number];
const isChangeableProtocol = (protocol: ProxyMode): protocol is ChangeableProtocol =>
  changeableProtocols.includes(protocol as ChangeableProtocol);

export const determineShouldChangeProtocol = (proxyMode: ProxyMode, checkedProtocol?: ProxyProtocol): boolean =>
  !!(checkedProtocol &&
    isChangeableProtocol(proxyMode) &&
    checkedProtocol !== proxyMode);
