import React from 'react';

const IconExtension = () => (
  <svg
    width='15'
    height='15'
    viewBox='0 0 15 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12 6.5C11.829 6.5 11.662 6.518 11.5 6.55V3.5H8.45C8.482 3.338 8.5 3.171 8.5 3C8.5 1.619 7.381 0.5 6 0.5C4.619 0.5 3.5 1.619 3.5 3C3.5 3.171 3.518 3.338 3.55 3.5H0.5V6.55C1.641 6.782 2.5 7.79 2.5 9C2.5 10.21 1.641 11.218 0.5 11.45V14.5H3.55C3.782 13.359 4.79 12.5 6 12.5C7.21 12.5 8.218 13.359 8.45 14.5H11.5V11.45C11.662 11.483 11.829 11.5 12 11.5C13.381 11.5 14.5 10.381 14.5 9C14.5 7.619 13.381 6.5 12 6.5Z'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default IconExtension;
