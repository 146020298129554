import css from '@emotion/css';
import styled from '@emotion/styled';
import { Modal } from 'antd';

export const ModernModal = styled(Modal)`
  font-family: Roboto;

  .ant-modal-close{
    top: 6px;
    right: 12px;
    color: var(--CDCDCD);
  }

  .ant-modal-close:hover{
    color: var(--000000BF);
  }

  .ant-modal-close-x{
    font-size: 14px;
  }

  .ant-modal-title {
    font: normal 20px 'Roboto';
    line-height: 24px;
    letter-spacing: 0.15px;
    color: var(--222222);
  }

  .ant-modal-header {
    padding: 20px 28px 0;

    border-radius: 16px;
    border: none;
  }

  .ant-modal-body {
    padding: 0 28px 28px;
  }

  .ant-modal-content {
    border-radius: 16px;
    overflow: hidden;
  }
`;

export const GreenSubmitButton = styled('button')<{ disabled?: boolean }>`
  display: block;
  padding: 6px 16px;

  font: normal 14px 'Roboto';
  color: var(--FFFFFF);
  background: var(--00B86D);
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;

  border-radius: 4px;
  cursor: pointer;
  user-select: none;

  outline: none;
  border: none;

  ${props => props.disabled && css`
    opacity: 0.5;
    cursor: not-allowed;
  `}
`;
