import React, { FC } from 'react';

import { IFullProfile } from '../../../../interfaces';
import ModernSwitch from '../../../../ui/modern-switch';
import { ContainerParams } from '../../styles';

interface IGoogleServices {
  googleServicesEnabled: boolean;
  changeSetting: (profileData: Partial<IFullProfile>) => void;
}

export const GoogleServices: FC<IGoogleServices> = ({ changeSetting, googleServicesEnabled }) => (
  <ContainerParams>
    <ModernSwitch
      checked={googleServicesEnabled}
      onChange={(checked): void => changeSetting({ googleServicesEnabled: checked })}
    />
  </ContainerParams>
);
