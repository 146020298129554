import styled from '@emotion/styled';

export const ProxyListMenuEl = styled('div')`
  position: absolute;
  height: 44px;
  left: 0;
  bottom: 0;
  background: var(--FFFFFF-proxy-manager-list-menu);
  border-top: 1px solid var(--EBEBEB-proxy-manager-list-menu);
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 10px 0;
`;

export const ButtonsContainerEl = styled('div')`
  display: flex;
  align-items: center;
`;
