import styled from '@emotion/styled/macro';

import { textFont } from '../../ui/style-templates';

export const PopoverInputContainer = styled('div')`
  ${textFont};
  color: var(--2B2B31-profile-new-settings-select);
  z-index: 300;
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
`;
