interface ICloudBrowserWindowReference {
  profileId: string;
  reference: Window|null;
}

const WINDOW_REFERENCES: ICloudBrowserWindowReference[] = [];

const getWindowReference = (profileIdToFind: string): Window|null => {
  const referenceObject = WINDOW_REFERENCES.find(({ profileId, reference }) => profileId === profileIdToFind && !reference?.closed);

  return referenceObject?.reference || null;
};

const addWindowReference = (profileId: string, reference: Window|null): number => WINDOW_REFERENCES.push({ profileId, reference });

export const openCloudBrowserUrlInSingleTab = (url: string, profileId: string): Window|null => {
  let windowReference = getWindowReference(profileId);
  if (windowReference) {
    windowReference.focus();

    return windowReference;
  }

  windowReference = window.open(url);
  if (windowReference) {
    addWindowReference(profileId, windowReference);
  }

  return windowReference;
};

export const closeCloudBrowserUrlInSingleTab = (profileId: string): void => {
  const windowReference = getWindowReference(profileId);
  windowReference && windowReference.close();
};
