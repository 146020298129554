import React, { useRef, useState } from 'react';
import { Trans } from 'react-i18next';

import CheckProxyStatusDot from './check-proxy-status-dot';
import { CheckProxyButtonWrapper } from './styles';
import { IProxy } from '../../../interfaces';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { PROXY_CHECK_TOOLTIP_LOCATIONS } from '../../../state/proxy/proxy-check/constants';
import {
  hideProxyCheckTooltip,
  showProxyCheckTooltip,
  useIsProxyCheckTooltipVisible,
} from '../../../state/proxy/proxy-check/proxy-check-tooltip.atom';
import { useIsProxyChecking } from '../../../state/proxy/proxy-check/proxy-statuses.atom';
import { ProxyCheckTooltipLocation } from '../../../state/proxy/proxy-check/types/proxy-check-tooltip-location.type';
import TooltipCustom from '../../../ui/tooltip-custom';
import CheckProxyTooltip from '../check-proxy-tooltip';
import { getIsProxyArchived } from '../proxy-helpers';

type CheckProxyButtonProps = {
  proxy: IProxy;
  profileId: string;
  proxyTooltipView: ProxyCheckTooltipLocation;
  selectorContainerElement?: React.RefObject<HTMLDivElement>;
  isSharedProxy?: boolean;
}

const CheckProxyBtn: React.FC<CheckProxyButtonProps> = (props) => {
  const { proxy, profileId, proxyTooltipView, selectorContainerElement, isSharedProxy = false } = props;

  const isChecking = useIsProxyChecking(proxy, profileId, proxyTooltipView);
  const isTooltipVisible = useIsProxyCheckTooltipVisible(proxy, profileId, proxyTooltipView);

  const [isSpinnerHovered, setIsSpinnerHovered] = useState<boolean>(false);
  const [shouldShowArchiveProxyTooltip, setShouldShowArchiveProxyTooltip] = useState<boolean>(false);

  const tooltipRef = useRef<HTMLDivElement>(null);

  const handleMouseEnter = (): void => {
    setIsSpinnerHovered(true);
    showProxyCheckTooltip({
      profileIds: [profileId || ''],
      proxies: [proxy],
      view: proxyTooltipView,
    });

    setShouldShowArchiveProxyTooltip(true);
  };

  const handleMouseLeave = (): void => {
    setIsSpinnerHovered(false);
    hideProxyCheckTooltip();
    setShouldShowArchiveProxyTooltip(false);
  };

  const isButtonCheckHidden = proxy.mode === 'none';
  if (isButtonCheckHidden) {
    return (
      <CheckProxyButtonWrapper
        isTooltipVisible={isTooltipVisible}
        newStyle={!!NEW_FEATURES.header}
      />
    );
  }

  const renderProxyTooltip = (): JSX.Element | null => {
    if (!getIsProxyArchived(proxy)) {
      return (
        <CheckProxyTooltip
          proxy={proxy}
          profileId={profileId}
          proxyTooltipView={proxyTooltipView}
          tooltipRef={tooltipRef}
          selectorContainerElement={selectorContainerElement}
        />
      );
    }

    if (!(shouldShowArchiveProxyTooltip && tooltipRef?.current)) {
      return null;
    }

    return (
      <TooltipCustom
        anchorEl={tooltipRef.current}
        placement={'top'}
        value={<Trans i18nKey={'proxyPage.archivedProxyStateTooltip'} />}
        style={{ width: '293px' }}
      />
    );
  };

  const isFreeOrTor = ['gologin', 'tor'].includes(proxy.mode);
  const isSpinnerVisible = !isFreeOrTor && (isChecking || isSpinnerHovered);
  const isCheckButtonVisible = isChecking || isTooltipVisible || proxyTooltipView !== PROXY_CHECK_TOOLTIP_LOCATIONS.selectorProfileTable;

  return (
    <CheckProxyButtonWrapper
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={(event): void => event.stopPropagation()}
      ref={tooltipRef}
      newStyle={!!NEW_FEATURES.header}
      isTooltipVisible={isTooltipVisible}
      isCheckButtonVisible={isCheckButtonVisible}
    >
      <CheckProxyStatusDot
        proxy={proxy}
        isSpinnerVisible={isSpinnerVisible}
        isChecking={isChecking}
        profileId={profileId}
        proxyTooltipView={proxyTooltipView}
        isSharedProxy={isSharedProxy}
      />
      {renderProxyTooltip()}
    </CheckProxyButtonWrapper>
  );
};

export default CheckProxyBtn;
