import { getProfileSettingsFullProfile } from './profile-settings-full-profile.atom';
import { mapAndSetProfilesList } from './profiles-list.atom';
import { buildProfile } from '../features/profile-settings/content/utils/build-profile';
import { IFullProfile, IProfile } from '../interfaces';
import { DEFAULT_PROFILE_DATA } from '../utils/default-profile-data';

// TODO: refactor into atom
// somewhat of legacy
const profilesMap = new Map<string, IProfile|IFullProfile>();

export const updateFullProfilesMap = (profiles: (IProfile|IFullProfile)[]): void => profiles.forEach(profile => {
  const currentProfile = profilesMap.get(profile.id);
  let updatedProfile = profile;
  if (!currentProfile) {
    profilesMap.set(profile.id, profile);

    return;
  }

  if (!Object.prototype.hasOwnProperty.call(currentProfile, 'isFullProfile')) {
    updatedProfile = buildProfile(buildProfile(DEFAULT_PROFILE_DATA, currentProfile), profile);
  } else {
    updatedProfile = buildProfile(currentProfile as IFullProfile, profile);
  }

  profilesMap.set(profile.id, updatedProfile);
});

export const refreshFullProfile = (newProfile: IProfile|IFullProfile): void => {
  const oldProfile = profilesMap.get(newProfile.id);
  const { archivedProxy = null } = newProfile;
  const { archivedProxy: archivedProxyOldProfile = null } = oldProfile || {};
  if (!archivedProxy && archivedProxyOldProfile) {
    delete oldProfile?.archivedProxy;
  }

  profilesMap.set(
    newProfile.id, {
      ...DEFAULT_PROFILE_DATA,
      ...oldProfile,
      ...newProfile,
      isFullProfile: true as const,
    },
  );
};

export const getProfile = (profileId: string): IFullProfile|null => {
  let currentProfile = profilesMap.get(profileId) || null;

  if (!currentProfile) {
    return currentProfile;
  }

  if (!Object.prototype.hasOwnProperty.call(currentProfile, 'isFullProfile')) {
    currentProfile = {
      ...DEFAULT_PROFILE_DATA,
      ...currentProfile,
      isFullProfile: true,
    };

    refreshFullProfile(currentProfile);
  }

  return currentProfile as IFullProfile;
};

export const updateProfileData = (newData: Partial<IFullProfile>): void => {
  const profileSettingsFullProfile = getProfileSettingsFullProfile();
  mapAndSetProfilesList(profiles => {
    const newProfiles = profiles.map(profile => {
      if (profile.id === profileSettingsFullProfile.id) {
        return buildProfile(profileSettingsFullProfile, newData);
      }

      return profile;
    });

    updateFullProfilesMap(newProfiles);

    return newProfiles;
  });
};
