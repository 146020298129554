import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { GroupField } from '../../interfaces/group-header.interface';
import { profilesTableGroupHeadersAtom } from '../profiles-list.atom';

const profilesTableGroupFieldAtom = atom<GroupField>((get) => {
  const groupHeaders = get(profilesTableGroupHeadersAtom);

  return groupHeaders[0]?.filter.type || null;
});

export const useIsProfilesTableGrouped = (): boolean => {
  const groupField = useAtomValue(profilesTableGroupFieldAtom);

  return !!groupField;
};

export const useProfilesTableGroupField = (): GroupField => useAtomValue(profilesTableGroupFieldAtom);
export const getProfilesTableGroupField = (): GroupField => getDefaultStore().get(profilesTableGroupFieldAtom);
