import { message } from 'antd';

import { API_BASE_URL } from '../../common/constants/constants';
import { CheckProxyParams, CheckProxyStatus, ProxyProtocol } from '../../common/types/proxy';
import { http } from '../services';

let ipcRenderer: Electron.IpcRenderer;
const isElectron = !!window.require;

if (isElectron) {
  ({ ipcRenderer } = window.require('electron'));
}

export const checkProxyRequest = async (options: any) => {
  const data = await http(`${API_BASE_URL}/browser/check_proxy`, {
    method: 'POST',
    body: JSON.stringify(options),
  });

  return data.body;
};

export interface IProxyStatus {
  status: CheckProxyStatus;
  origin?: string;
  country?: string;
  city?: string;
  timezone?: string;
  error?: string;
  languages?: string;
  checkedProtocol?: ProxyProtocol;
}

export const getCheckProxy = async ({ proxy = {} }: any): Promise<IProxyStatus | undefined> => {
  const {
    id,
    mode: type = 'http',
    host = '',
    port = 80,
    username = '',
    password = '',
    status = false,
    checkDate,
  } = proxy;

  if (!(host && port)) {
    message.error('Invalid address');

    return;
  }

  if (host.match(/^127|localhost/) && !isElectron) {
    message.error('Add External Host');

    return;
  }

  const formattedType = type === 'geolocation' ? 'http' : type;
  const params: CheckProxyParams = {
    type: formattedType,
    host,
    port,
    username,
    password,
    mode: type,
    lastProxyCheckStatus: {
      id,
      status,
      checkDate,
    },
  };

  if (isElectron && ipcRenderer) {
    return ipcRenderer.invoke('check-proxy', params);
  }

  return checkProxyRequest(params);
};

