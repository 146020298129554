import { IconDesignVersion } from '../../types';

export const saveIconsToSessionStorage = (profileId: string, iconsPngs: string[], iconDesignVersion: IconDesignVersion): void => {
  const iconDesignVersionKey = `browserIconsDesign:${profileId}`;
  if (iconDesignVersion) {
    sessionStorage.setItem(iconDesignVersionKey, iconDesignVersion);
  } else {
    // here and below we remove items to ensure
    // correct drawing on the first launch of a browser
    // that has been just downloaded
    sessionStorage.removeItem(iconDesignVersionKey);
  }

  const [iconPngBig, iconPngSmall] = iconsPngs;
  const [iconPngBigKey, iconPngSmallKey] = [`browserIconBig:${profileId}`, `browserIconSmall:${profileId}`];
  if (iconPngBig) {
    sessionStorage.setItem(iconPngBigKey, iconPngBig);
  } else {
    sessionStorage.removeItem(iconPngBigKey);
  }

  // for windows 10 (small and big icons setting)
  if (iconPngSmall) {
    sessionStorage.setItem(iconPngSmallKey, iconPngSmall);
  } else {
    sessionStorage.removeItem(iconPngSmallKey);
  }
};
