import { message } from 'antd';
import React from 'react';
import CopyToClipBoard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

import { MenuRowContainer, MenuRowText, MenuHeaderItem } from './styles';
import { IconUser } from '../icons';
import { useUserBasicInfo } from '../../../state/user/user-basic-info.atom';

const EmailInfo = (): JSX.Element => {
  const { email } = useUserBasicInfo();

  const { t: translation } = useTranslation();

  return (
    <MenuHeaderItem>
      <MenuRowContainer>
        <IconUser padding={0} styleType={'lightGray'} iconColor='var(--B5B5BA-header-account-menu)' />
        <CopyToClipBoard
          text={email || ''}
          onCopy={(): void => {
            message.success(translation('base.copiedText'));
          }}
        >
          <MenuRowText style={{ marginLeft: 8, cursor: 'pointer' }}>
            {email}
          </MenuRowText>
        </CopyToClipBoard>
      </MenuRowContainer>
      <div style={{ minHeight: 6 }} />
    </MenuHeaderItem>
  );
};

export default EmailInfo;
