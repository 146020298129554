import { useDroppable } from '@dnd-kit-contextless/core';
import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled';
import React, { memo, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { PROXY_PAGE } from '../../../common/constants/routes';
import { E_PROXY_FOLDERS } from '../../features/proxy/proxy-page/constants/proxy-folders';
import { IFolder } from '../../interfaces';
import { useFoldersList } from '../../state/folders/folders-list.atom';
import { useIsArchiveProxiesFolderSelected, useProxyFolders, useSelectedProxyFolder } from '../../state/proxy/proxy-table/proxy-folders.atom';
import { toggleSelectedFolder, useSelectedFolderId } from '../../state/selected-folder.atom';
import TooltipCustom from '../tooltip-custom';

const HeaderLinkWrapper = styled.div<{ selected?: boolean; highlight?: boolean }>`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  padding: 8px 12px;
  cursor: pointer;

  line-height: 16px;
  letter-spacing: 0.25px;
  transition: background-color 0.05s ease-in-out, color 0.05s ease-in-out, stroke 0.05s ease-in-out;
  border-radius: 8px;

  ${(props): SerializedStyles => props.selected ? css `
    background: var(--F2F2F3-header-folder);
    color: var(--2B2B31-header-folder);
  ` : css `
    background: inherit;
    color: var(--767676-header-folder);
  `}

  ${(props): SerializedStyles|'' => props.highlight ? css`
    color: var(--2B2B31-header-folder);
    background: var(--EAEAEB-header-folder);
  ` : ''}

  :hover {
    color: var(--2B2B31-header-folder);
    background: var(--EAEAEB-header-folder);
  }
`;

interface IFolderItem {
  item: IFolder;
  changeProxySelectedFolder: (folder: IFolder) => void;
}

const FolderItem: React.FC<IFolderItem> = (props) => {
  const { item, changeProxySelectedFolder } = props;

  const [shouldShowArchiveFolderToolTip, setShouldShowArchiveFolderToolTip] = useState(false);

  const proxyFolders = useProxyFolders();
  const proxyFolderSelected = useSelectedProxyFolder();
  const isArchiveFolderSelected = useIsArchiveProxiesFolderSelected();
  const selectedFolderId = useSelectedFolderId();
  const foldersList = useFoldersList();

  const { isOver, setNodeRef, node: folderRef } = useDroppable({
    id: `folder::${item.name}`,
    disabled: item.systemFolder,
  });

  const location = useLocation();

  const isProxyPage = location.pathname === PROXY_PAGE;
  const { id: folderId, name, systemFolder = false } = item;

  const executeScroll = (): void => folderRef?.current?.scrollIntoView({ block: 'nearest' });

  useEffect(() => {
    if (folderRef?.current?.innerText === selectedFolderId) {
      executeScroll();
    }
  }, [selectedFolderId]);

  let isSelected = false;
  let translationKeyBase = 'tableProfiles.header';
  if (isProxyPage) {
    isSelected = proxyFolderSelected === name || !proxyFolders.find(folder => folder.name === proxyFolderSelected) && systemFolder;
    translationKeyBase = 'proxyPage.header';
  } else {
    isSelected = selectedFolderId === folderId || !foldersList.find(folder => folder.id === selectedFolderId) && systemFolder;
  }

  const handleClick: React.MouseEventHandler<HTMLDivElement> = () => {
    setShouldShowArchiveFolderToolTip(false);
    if (isProxyPage) {
      return changeProxySelectedFolder(item);
    }

    toggleSelectedFolder(item);
    executeScroll();
  };

  const isProxiesArchiveFolderTooltipShow =  name !== E_PROXY_FOLDERS.archive;

  const renderArchiveProxyFolderTooltip = (): JSX.Element | null => {
    if (isProxiesArchiveFolderTooltipShow || !folderRef?.current) {
      return null;
    }

    if (isArchiveFolderSelected || !shouldShowArchiveFolderToolTip) {
      return null;
    }

    return (
      <TooltipCustom
        anchorEl={folderRef.current}
        value={<Trans i18nKey={'proxyPage.archiveFolderTooltip'} />}
        style={{ width: '180px', textAlign: 'left' }}
      />
    );
  };

  return (
    <HeaderLinkWrapper
      ref={setNodeRef}
      highlight={isOver}
      selected={isSelected}
      onClick={handleClick}
      onMouseEnter={(): void => setShouldShowArchiveFolderToolTip(true)}
      onMouseLeave={(): void => setShouldShowArchiveFolderToolTip(false)}
    >
      {renderArchiveProxyFolderTooltip()}
      {systemFolder ? <Trans i18nKey={`${translationKeyBase}.${name}`} /> : name}
    </HeaderLinkWrapper>
  );
};

export default memo(FolderItem);
