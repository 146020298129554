import { MessageApi } from 'antd/lib/message';

import { IPostPaymentIntent } from './api.interfaces';
import { PROMOCODE_UNITS } from '../../../../common/constants/constants';
import { GeoProxyPromocodesType } from '../../../../common/constants/types';
import { DISCOUNT_TYPE } from '../../../interfaces';
import { IPlan } from '../../../interfaces/plan';
import { IWorkspace } from '../../../interfaces/workspaces';
import { IUser, IUserCtx, IWorkspaceCtx } from '../../../state';
import { BackgroundType } from '../constants';

export interface IIPayTotalApi {
  createPayment(options: any): Promise<any>;
  checkTransaction(transactionId: string|undefined): Promise<any>;
  updateTransaction(transactionId: string, apiResponse: any): Promise<any>;
  changePlan(planId: string): Promise<any>;
}

export interface IStripeApi {
  postPaymentIntent(opts: IPostPaymentIntent): Promise<any>;
  postPaymentIntentResult(paymentIntentId: string, plan: string, discount: string, setForRecurringPayments: boolean): Promise<any>;
  changePlan(planId: string): Promise<any>;
}

export interface IStripeError {
  status: string;
  message: string;
  manualError: boolean;
}

// Type for payment intent analytics
export enum E_PAYMENT_METHODS {
  STRIPE = 'stripe',
  PAYPAL = 'paypal',
  CENT = 'cent',
  CRYPTO = 'crypto',
  GOOGLE_PAY = 'google_pay',
  APPLE_PAY = 'apple_pay',
  SIGMA = 'sigma',
}

export const PAYMENT_METHODS = Object.values(E_PAYMENT_METHODS);
export type PaymentMethodIntentType = (typeof PAYMENT_METHODS)[number];
// Type for payment error analytics
export enum E_PAYMENT_METHODS_ANALYTICS {
  STRIPE = 'Stripe',
  PAYPAL = 'PayPal',
  CENT = 'Cent',
  CRYPTO = 'Crypto',
  GOOGLE_PAY = 'GooglePay',
  APPLE_PAY = 'ApplePay',
  SIGMA = 'Sigma',
}

export const PAYMENT_METHODS_ANALYTICS = Object.values(E_PAYMENT_METHODS_ANALYTICS);
export type PaymentMethodAnalytics = (typeof PAYMENT_METHODS_ANALYTICS)[number];

export type PaymentAction = 'openModal'|'pressedPayNow';
export enum E_PERIOD {
  MONTHLY = 'monthly',
  ANNUAL = 'annual',
}

export const PERIOD_TYPES = Object.values(E_PERIOD);
export type PaymentPeriodType = (typeof PERIOD_TYPES)[number];

export interface IDiscountsList {
  discounts: IDiscount[];
  id: string;
  monthCount: number;
  name: number;
}

export interface IDiscountShort {
  active: boolean;
  discountType: typeof DISCOUNT_TYPE[number];
  value: number;
}

export interface IDiscountElem extends IDiscountShort {}

export interface IDiscount {
  discounts: IDiscountShort[];
  id: string;
  monthCount: number;
  name: string;
}

export interface IUpgradeDiscount extends IDiscountElem {
  periodType: PaymentPeriodType;
  amountInCurrency: {
    usd: number;
    rub: number;
    pkr: number;
    bdt: number;
  };
}

export interface IPromocodesTraffic {
  type: GeoProxyPromocodesType;
  quantity: number;
}

export interface IPeroid {
  unit: typeof PROMOCODE_UNITS[number];
  amount: number;
}

export interface IPromoDiscount {
  discount: IDiscountShort;
}

export interface IPromoGifts extends IPromoDiscount {
  traffic: IPromocodesTraffic;
  period: IPeroid;
}

export interface IUpdateSelectedWorkspaceParameters {
  workspaceId: string;
  updateWorkspaceHooks: Partial<IWorkspaceCtx>;
}

export interface IUpdateSelectedPlanParameters {
  plans:  IPlan[];
  selectedPlan: string;
  userContextPlan: Partial<IUserCtx>;
  updateWorkspaceParams?: IUpdateSelectedWorkspaceParameters;
}

export interface IGetPlanPrice {
  plans?: IPlan[];
  discounts?: IDiscount[];
  planId: string;
  monthCount: number;
  multiplier?: number;
  locale?: string;
}

export interface IGetPlanInfoParams {
  plans: IPlan[];
  planId: string;
  field: keyof IPlan;
  getFeatureStatus?: boolean;
}

export interface IRawPricingDataForState {
  workspace: IWorkspace;
  discounts: IDiscount[];
  plans: IPlan[];
  promoDiscount: IDiscountShort;
}

export interface IRawPricingData {
  workspace: IWorkspace;
  userData: IUser;
  discounts: IDiscount[];
  plans: IPlan[];
  promoDiscount: IDiscountShort;
  pricingConfig: IPricingConfig;
  upgradeDiscount: IUpgradeDiscount;
}

export interface IRawPricingDataByTempToken extends IRawPricingData {
  accessToken: string;
  twoFaToken?: string;
}

export interface IPricingDataProps {
  workspaceId: string;
}

export interface IPricingDataByTempTokenProps extends IPricingDataProps {
  autoLoginToken: string;
  accessToken: string;
}

export interface IPreviewPlan extends Pick<IPlan, 'geoProxyTraffic'> {
  id: string;
  support: 'expert'|'personal';
  background: BackgroundType;
  buttonStyle: BackgroundType;
  isPopular: boolean;
  isCustomPlan: boolean;
  androidApp: boolean;
  fingerprintsDatabases: boolean;
  cloudBrowsing: boolean;
}

export interface IMappedPlan extends IPreviewPlan, IPlan {
  id: string;
  name: string;
}

export interface IGetCorrectPlanPrice {
  planId: string;
  discountId: string;
  locale: string;
}

export interface IIsWorkspacePaidParams {
  planId: string;
  isUnpaid?: boolean;
  planExpiresAt?: Date;
  paymentIsTrial?: boolean;
  paymentMethod?: PaymentMethodIntentType;
}

export enum E_CURRENCY {
  USD = 'usd',
  RUB = 'rub',
  PKR = 'pkr',
  BDT = 'bdt',
}

export const CURRENCIES = Object.values(E_CURRENCY);
export type TCurrency = (typeof CURRENCIES)[number];

export enum PaymentType {
  Card = 'Card',
  Mobile = 'Mobile',
  PayPal = 'PayPal',
  Crypto = 'Crypto',
  Sigma = 'Sigma',
  Paddle = 'Paddle',
}

export const PAYMENT_TAB = Object.values(PaymentType);
export type TPaymentTab = (typeof PAYMENT_TAB)[number];

export enum E_PAYMENT_COUNTRY {
  ANY = 'any',
  RU = 'ru',
  PK = 'pk',
  BD = 'bd',
}

export const PAYMENT_COUNTRY = Object.values(E_PAYMENT_COUNTRY);
export type TPaymentCountry = (typeof PAYMENT_COUNTRY)[number];

export interface IPaymentTabConfig {
  type: TPaymentTab;
  currency: TCurrency;
}

export interface IPricingConfigCountry {
  checkoutCurrency: TCurrency;
  tabs: IPaymentTabConfig[];
}

export interface IPricingConfig {
  currencyFactors: Record<TCurrency, number>;
  currencyLocales: Record<TCurrency, string>;
  currencyFractionDigits: Record<TCurrency, E_CURRENCY_FRACTION_DIGITS>;
  pricingPageConfig: Record<TPaymentCountry, IPricingConfigCountry>;
}

export interface IPriceConfig {
  currencyFactors: Record<TCurrency, number>;
  currencyLocales: Record<TCurrency, string>;
  currencyFractionDigits: Record<TCurrency, E_CURRENCY_FRACTION_DIGITS>;
}

export interface IPlanPrice extends Record<TCurrency, number> {}

export enum E_CURRENCY_LOCALES {
  USD = 'en-US',
  RUB = 'ru-RU',
  PKR = 'en-PK',
  BDT = 'en-BD',
}

export enum E_CURRENCY_FRACTION_DIGITS {
  ROUND = 0,
  TWO = 2,
}

export const CURRENCY_FRACTION_DIGITS = Object.values(E_CURRENCY_FRACTION_DIGITS);
export type CurrencyFractionDigitsType = (typeof CURRENCY_FRACTION_DIGITS)[number];

export interface ICalcModalHeightParams {
  paymentType: PaymentType;
  isNewStripeFlow?: boolean;
  isTrialEnded?: boolean;
  isUpgradeDiscount?: boolean;
}

export interface IErrorCreationPaymentLinkData {
  message: MessageApi;
  errorCode: number;
  errorMessage: string;
}

export const CARD_TYPE = {
  SIGMA: 'sigma_card',
  PADDLE: 'paddle_card',
};

export const cardTypes = Object.values(CARD_TYPE);
export type CardType = (typeof cardTypes)[number];
