import { atom, getDefaultStore, useAtomValue } from 'jotai';
import { columnsSettingsAtom } from './columns-settings.atom';

const isStateColumnPresentedAtom = atom((get) => {
  const { columnsSettings } = get(columnsSettingsAtom);
  return columnsSettings.some(col => col.colName === 'status' && col.visible);
});

export const useIsStateColumnPresented = (): boolean => useAtomValue(isStateColumnPresentedAtom);

export const getIsStateColumnPresented = (): boolean => getDefaultStore().get(isStateColumnPresentedAtom);
