import React, { FC, FocusEventHandler, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';

import AddNotesPlaceholder from './add-notes-placeholder';
import useNotesColorReplace from './notes-color-replace.hook';
import QuillNotes from './quill-notes';
import StaticNotes from './static-notes';
import { NotesContainer } from './styles';
import { stripTags } from './utils';
import useElementHeight from '../../../hooks/use-element-height.hook';
import PerformanceObserverService from '../../../services/performance-observer/performance-observer.service';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { setIsDragAndDropBlocked } from '../../drag-n-drop/blockable-mouse-sensor';

type NotesProps = {
  profileId: string;
  notes: string;
  saveProfileNotes: (profileId: string, notes: string) => void;
  location: 'old-drawer' | 'profile-settings' | 'profiles-table';
  isDefaultNotes: boolean;
  placeholderTranslationKey?: string;
  onHeightPossibleChange?: () => void;
  isAlwaysStatic?: boolean;
  isDisablingDragAndDrop?: boolean;
}

const Notes: FC<NotesProps> = (props) => {
  const {
    profileId,
    notes: profileNotes = '',
    saveProfileNotes,
    location,
    isDefaultNotes,
    placeholderTranslationKey,
    onHeightPossibleChange,
    isAlwaysStatic = false,
    isDisablingDragAndDrop = false,
  } = props;

  const isInDrawer = location === 'profile-settings';
  const defaultState = isInDrawer ? 'hovered' : 'static';

  const [notes, setNotes] = useState<string>(profileNotes);
  const [notesStatus, setNotesStatus] = useState<'editing'|'hovered'|'static'>(defaultState);

  const quillRef = useRef<ReactQuill>(null);
  const notesContainerRef = useRef(null);
  const notesContainerHeight = useElementHeight(notesContainerRef);

  const { t: translation } = useTranslation();
  const { replaceNotesColors } = useNotesColorReplace();

  const areNotesPresent = isDefaultNotes || stripTags(notes).trim();
  const showPlaceholder = !(notesStatus === 'editing' || areNotesPresent);

  useEffect(() => {
    const defaultNotes = isDefaultNotes ? translation('profiles.firstProfileNotes') : '';
    const currentNotes = defaultNotes || profileNotes || '';
    setNotes(replaceNotesColors(currentNotes));
  }, [profileId, profileNotes, isDefaultNotes]);

  useEffect(() => {
    quillRef.current?.editor?.root.setAttribute('spellcheck', 'false');
  }, [quillRef.current]);

  useEffect(() => {
    onHeightPossibleChange && onHeightPossibleChange();
  }, [notesStatus]);

  useLayoutEffect(() => {
    quillRef.current?.editor?.root.classList.remove('ql-hide-rows');
  }, [notesContainerHeight]);

  const handleChange = (text: string): void => {
    setNotes(text);
    onHeightPossibleChange && onHeightPossibleChange();
  };

  const onNotesStartEditing = (): void => {
    const performanceObserverService = PerformanceObserverService.getInstance();
    performanceObserverService.handleUserAction({ userAction: 'start-notes-column-edit' });

    quillRef.current?.editor?.root.classList.add('ql-hide-rows');
  };

  const handleFocus = (): void => {
    if (isDisablingDragAndDrop) {
      setIsDragAndDropBlocked(true);
    }

    setNotesStatus(prevNotesStatus => {
      if (prevNotesStatus !== 'editing' && location === 'profiles-table') {
        onNotesStartEditing();
      }

      return 'editing';
    });
  };

  const handleDivBlur: FocusEventHandler<HTMLDivElement> = async (event) => {
    if (event.currentTarget.contains(event.relatedTarget)) {
      return;
    }

    if (isDisablingDragAndDrop) {
      setIsDragAndDropBlocked(false);
    }

    setNotesStatus(defaultState);

    const strippedString = stripTags(notes);
    const resValue = (!strippedString) ? strippedString : notes;
    if (resValue !== profileNotes) {
      await saveProfileNotes(profileId, resValue);
    }
  };

  const focusQuill = (): void => {
    quillRef.current && quillRef.current.getEditor().focus();
  };

  const onNotesOpen = (): void => {
    handleFocus();
    focusQuill();
  };

  const toggleHover = (event: 'enter'|'leave') => (): void => {
    setTimeout(() => {
      setNotesStatus(prevStatus => {
        if (event === 'enter' && prevStatus === 'static') {
          return 'hovered';
        }

        if (event === 'leave' && prevStatus === 'hovered') {
          return defaultState;
        }

        return prevStatus;
      });
    }, 0);
  };

  const isDisplayingStaticNotes = notesStatus === 'static' || isAlwaysStatic;
  const shouldShowPlaceholder = showPlaceholder && !isAlwaysStatic;

  return (
    <NotesContainer
      ref={notesContainerRef}
      onBlur={handleDivBlur}
      onFocus={handleFocus}
      onMouseOver={toggleHover('enter')}
      onMouseLeave={toggleHover('leave')}
      newStyle={!!NEW_FEATURES.header}
      newDrawer={!!NEW_FEATURES.drawer}
      isInDrawer={!!isInDrawer}
    >
      {shouldShowPlaceholder ? (
        <AddNotesPlaceholder
          isInDrawer={isInDrawer}
          onOpen={onNotesOpen}
          placeholderTranslationKey={placeholderTranslationKey}
        />
      ) : null}
      {isDisplayingStaticNotes ? (
        <StaticNotes notes={notes} />
      ) : (
        <QuillNotes
          notes={notes}
          isEditing={notesStatus === 'editing'}
          onChange={handleChange}
          onFocus={handleFocus}
          isInDrawer={isInDrawer}
          quillRef={quillRef}
        />
      )}
    </NotesContainer>
  );
};

export default React.memo(Notes, (prevProps, nextProps) => (
  prevProps.profileId === nextProps.profileId &&
  prevProps.notes === nextProps.notes &&
  prevProps.isDefaultNotes === nextProps.isDefaultNotes &&
  prevProps.onHeightPossibleChange === nextProps.onHeightPossibleChange
));
