import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { useProfilesList } from './profiles-list.atom';
import { IProfile } from '../interfaces';
import PerformanceObserverService from '../services/performance-observer/performance-observer.service';

const DELETE_OR_LEAVE_PROFILE_MODAL_NAME = 'delete-or-leave-profile';
export const SHARE_MODAL_NAME = 'share-modal';

const MODAL_NAMES = <const>[DELETE_OR_LEAVE_PROFILE_MODAL_NAME, SHARE_MODAL_NAME];
type ProfilesTableModal = typeof MODAL_NAMES[number];

export type ModalUserAction = `open-${ProfilesTableModal}`;
export const MODAL_USER_ACTIONS = MODAL_NAMES.map<ModalUserAction>(name => `open-${name}`);

type ProfilesTableModalType = 'none'|ProfilesTableModal;

interface IProfilesTableModal {
  currentModal: ProfilesTableModalType;
  modalProfileIds: string[];
  isInCloseAnimation: boolean;
}

const defaultProfilesTableModal: IProfilesTableModal = {
  currentModal: 'none',
  modalProfileIds: [],
  isInCloseAnimation: false,
};

const profilesTableModalAtom = atom<IProfilesTableModal>(defaultProfilesTableModal);

export const useProfileTableModalType = (): IProfilesTableModal['currentModal'] => useAtomValue(profilesTableModalAtom).currentModal;

export const useProfileTableModalIsVisible = (modalType: ProfilesTableModalType): boolean => {
  const isModalActive = useProfileTableModalType() === modalType;
  const isModalInCloseAnimation = useAtomValue(profilesTableModalAtom).isInCloseAnimation;

  return isModalActive && !isModalInCloseAnimation;
};

export const startClosingProfileTableModal = (): void => {
  const profilesTableModal = getDefaultStore().get(profilesTableModalAtom);

  getDefaultStore().set(profilesTableModalAtom, { ...profilesTableModal, isInCloseAnimation: true });
};

export const closeProfileTableModal = (): void => {
  getDefaultStore().set(profilesTableModalAtom, { currentModal: 'none', modalProfileIds: [], isInCloseAnimation: false });
};

export const openProfileTableModal = (modalType: ProfilesTableModalType, modalProfileIds: string[] = []): void => {
  if (modalType !== 'none') {
    const performanceObserverService = PerformanceObserverService.getInstance();
    performanceObserverService.handleUserAction({ userAction: `open-${modalType}` });
  }

  getDefaultStore().set(profilesTableModalAtom, { currentModal: modalType, modalProfileIds, isInCloseAnimation: false });
};

const useProfilesTableModalProfileIds = (): IProfilesTableModal['modalProfileIds'] => useAtomValue(profilesTableModalAtom).modalProfileIds;

export const useProfilesTableModalProfiles = (): IProfile[] => {
  const profilesList = useProfilesList();
  const modalProfileIds = useProfilesTableModalProfileIds();

  return profilesList.filter(profile => modalProfileIds.includes(profile.id));
};
