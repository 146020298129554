import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconGroups: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props} iconType='stroke'>
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M14 2L2 2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M14 9L2 9' strokeLinecap='round' strokeLinejoin='round' />
      <rect x='2.5' y='4.5' width='4' height='2' rx='0.5' />
      <rect x='2.5' y='11.5' width='4' height='2' rx='0.5' />
      <rect x='9.5' y='4.5' width='4' height='2' rx='0.5' />
      <rect x='9.5' y='11.5' width='4' height='2' rx='0.5' />
    </svg>
  </IconWrapper>
);
