import { ProxyStatusForChecker } from '../../app/interfaces';

export const ProxyProtocols = {
  HTTP: 'http',
  HTTPS: 'https',
  SOCKS4: 'socks4',
  SOCKS5: 'socks5',
} as const;

export type ProxyProtocol = typeof ProxyProtocols[keyof typeof ProxyProtocols];

export type CheckProxyStatus = 'success' | 'fail';

export type CheckProxyParams = {
  type: ProxyProtocol;
  host: string;
  port: number;
  username: string;
  password: string;
  mode: string;
  lastProxyCheckStatus: ProxyStatusForChecker;
}
