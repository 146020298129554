/* eslint-disable max-lines */
import { Button, Icon, Input, message, Modal, Popconfirm, Steps } from 'antd';
import moment from 'moment';
import React, { FC, ReactComponentElement, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { Link, withRouter } from 'react-router-dom';

import AccountMenu from './account-menu';
import {
  checkCheckoutSession,
  requestDeleteMySharedAccounts,
  requestGetMySharedAccounts,
  sendUpdateAnalytics,
  shareAccount,
} from './api';
import HeaderContainer from './header-container';
import {
  ButtonUpdate,
  ContainerLogo,
  ContainerMenu,
  ContainerSearchInput,
  InputSearch,
  LinkMenu,
  LinkMenuMobile,
  Payment,
  PaymentText,
  PayNowBtn,
  ProgressContainer,
  ProgressContainerContent,
  ProgressRetryingContainer,
  Row,
} from './styles';
import { NEW_FEATURES } from '../../state/feature-toggle/new-features';
import { PRICING_PAGE, PROFILE_LIST_PAGE } from '../../../common/constants/routes';
import { sendActionAnalytics } from '../../features/common/api';
import ProfilesImportManager from '../../features/modalsComponents/components/profiles-import-manager/index';
import { UpdateAppNotificationModal } from '../../features/modalsComponents/components/update-app-notification';
import { useQuery } from '../../hooks';
import { history } from '../../services';
import {
  BROWSER_UPDATER_DEFAULT_VALUE,
  browserUpdaterContext,
  userContext,
  WORKSPACE_DEFAULT_VALUE,
  workspaceContext,
} from '../../state';
import { openWebsitePricing } from '../../utils/open-site';
import AccountManager from '../AccountManager';
import HeaderNew from '../gologin-header';
import BuyProxy from '../gologin-header/buy-proxy';
import { IconTrafficOld , IconArrowRight, IconWarning } from '../gologin-header/icons';
import { IconWrapperWithDescription } from '../gologin-header/icons/wrapper';
import { Text, TextWithButton } from '../gologin-header/promo-bar/styles';
import IconLogo from '../icons/IconLogo';

let ipcRenderer: Electron.IpcRenderer;
let progressBarTimeout: any;

if (window.require) {
  ({ ipcRenderer } = window.require('electron'));
}

const { Step } = Steps;

const { confirm } = Modal;

interface IHeader extends RouteComponentProps {
  onSearch?: any;
  hasGlobalHeader?: boolean;
}

const Header: FC<IHeader> = (props) => {
  const { hasGlobalHeader } = props;

  const [appNeedRestart, changeAppNeedRestart] = useState<boolean>(false);
  const [appRestartInProcess, changeAppRestartInProcess] = useState<boolean>(false);
  const [switchAccountModalVisible, changeSwitchAccountModalVisible] = useState<boolean>(false);
  const [switchShareAccountModalVisible, changeSwitchShareAccountModalVisible] = useState<boolean>(false);
  const [profileToShare, changeProfileToShare] = useState<string>('');
  const [sharingButtonDisable, changeSharingButtonDisable] = useState<boolean>(false);
  const [sharedAccounts, setSharedAccounts] = useState<any[]>([]);
  const [mySharedAccounts, setMySharedAccounts] = useState<any[]>([]);
  const [windowWidth, changeWindowWidth] = useState(0);
  const [browserManagerModalVisible, setBrowserManagerModalVisible] = useState<boolean>(false);
  const [importForPaidUsersPopUp, setImportForPaidUsersPopUp] = useState<boolean>(false);
  const [anchorElBuyProxy, setAnchorElBuyProxy] = React.useState<HTMLElement | null>(null);
  const [isUpdateNotificationtModalVisable, setIsUpdateNotificationtModalVisable] = useState(false);

  const [noHeader, setNoHeader] = useState<boolean>(false);

  const selectRef = useRef<Input>(null);

  const {
    initialized,
    currentStep,
    downloadProgressStr,
    downloadProgressStatus,
    updateBrowserUpdater,
    browserUpdating,
    showOrbitaDialog,
    isDiscSpaceError,
  } = useContext(browserUpdaterContext);

  const {
    _id,
    hasTrial: userIsTrial,
    trialDays: userTrialDays,
    email: userEmail,
    profiles: userProfiles,
    plan: userPlan = {},
    isEmailConfirmed,
    isQuickSettingsEnabled,
    hasSuccessPayment,
    firstPlanSelected,
  } = useContext(userContext);

  const {
    id: workspaceId,
    isLoaded: isWorkspaceLoaded,
    updateWorkspace,
    updateAvailableWorkspaces,
    planExpiresAt: workspaceExpiresAt,
    paymentIsTrial: workspaceIsTrial,
  } = useContext(workspaceContext);

  const { t: translation } = useTranslation();

  const query = useQuery();

  const { maxProfiles: planMaxProfiles, name: namePlan } = userPlan;
  const isElectron = !!window.require;
  const isProfilesListPage = props.location.pathname === PROFILE_LIST_PAGE;
  const hasTrial = NEW_FEATURES.workspaces ? workspaceIsTrial : userIsTrial;
  let trialDays = userTrialDays;
  if (NEW_FEATURES.workspaces) {
    trialDays = (new Date(workspaceExpiresAt) > new Date())
      ? moment(workspaceExpiresAt).diff(moment(), 'days') + 1
      : 0;
  }

  const onCloseBuyProxy = (): void => {
    setAnchorElBuyProxy(null);
  };

  const getMySharedAccounts = async (): Promise<void> => {
    const mySharedAccounts = await requestGetMySharedAccounts();
    setMySharedAccounts(mySharedAccounts);
  };

  const handleSearchHotKeyKeydown = (event: any) => {
    if (!selectRef.current) {
      return;
    }

    if (event.code === 'KeyF' && (event.ctrlKey || event.metaKey)) {
      selectRef.current.focus();
    }
  };

  useEffect(() => {
    const checkoutStatus = query.get('checkout_status');
    const sessionId = query.get('session_id');

    query.delete('checkout_status');
    query.delete('session_id');

    if (!checkoutStatus) {
      return;
    }

    if (checkoutStatus === 'success' && sessionId) {
      message.success('Payment successful. Return to the app.');
      checkCheckoutSession(sessionId);

      return;
    }

    message.error('Payment failure');
  }, []);

  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('keydown', handleSearchHotKeyKeydown);

    return () => {
      window.removeEventListener('keydown', handleSearchHotKeyKeydown);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleResize = (): void => {
    changeWindowWidth(window.innerWidth);
  };

  useEffect((): (() => void) | void => {
    if (!isElectron) {
      return;
    }

    if (!browserUpdating) {
      ipcRenderer.invoke('check-browser').then((checkBrowserResponse: { needDownloadNewBrowser: boolean; browserExist: boolean }) => {
        if (!checkBrowserResponse?.needDownloadNewBrowser) {
          return;
        }

        if (checkBrowserResponse.browserExist) {
          updateBrowserUpdater({
            initialized: true,
            showOrbitaDialog: true,
          });
        } else {
          startBrowserUpdating();
        }
      });
    }

    if (NEW_FEATURES.header) {
      return;
    }

    ipcRenderer.on('app-update-downloaded', () => {
      changeAppNeedRestart(true);
      changeAppRestartInProcess(false);
    });

    return () => {
      ipcRenderer.removeAllListeners('app-update-downloaded');
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleAccountModal = (state: boolean): void => {
    changeSwitchAccountModalVisible(state);
  };

  const renderButtonMenu = (): JSX.Element => (
    <LinkMenu to='/newProfile'>
      <span>
        {translation('header.newProfile')}
      </span>
    </LinkMenu>
  );

  const toggleShareAccountModal = (options: any) => {
    const { status } = options;

    if (status) {
      sendActionAnalytics('visited add member');

      getMySharedAccounts();
    }

    changeSwitchShareAccountModalVisible(status);
    changeProfileToShare('');
  };

  const shareAccountComponent = () => {
    const recepient = profileToShare.trim();
    if (!recepient) {
      message.error(translation('errorMessages.enterValidEmail'), 3);

      return;
    }

    if (recepient === userEmail) {
      message.error(translation('notifications.error.cannotMakeMember'), 3);

      return;
    }

    changeSharingButtonDisable(true);

    return shareAccount({ type: 'profile', recepient })
      .then(() => {
        message.success(translation('notifications.success.accountHasBeenShared'));
        changeProfileToShare('');
      })
      .finally(() => {
        changeSharingButtonDisable(false);
        getMySharedAccounts();
      });
  };

  const removeSharedAccount = async (id: string, email: string) => confirm({
    title: translation('shareModal.btnSubmitRemoveMember'),
    content: email,
    onOk() {
      requestDeleteMySharedAccounts(id)
        .then(() => {
          message.success(translation('notifications.success.memberHasBeenDeleted'));
          getMySharedAccounts();
        });
    },
  });

  const ent = (event: any) => {
    if (event.keyCode === 13) {
      shareAccountComponent();
    }
  };

  const shareAccountModal = () => (
    <Modal
      width={488}
      title={translation('shareModal.addMembers')}
      visible={switchShareAccountModalVisible}
      onCancel={() => toggleShareAccountModal({ status: false })}
      footer={null}
    >
      <Row>
        <Input
          style={{
            width: 310,
          }}
          placeholder={translation('shareModal.form.emailInput.placeholder')}
          onKeyDown={ent}
          value={profileToShare}
          suffix={(
            <Icon
              type='close-circle'
              style={{ color: 'var(--00000073)', cursor: 'pointer' }}
              onClick={() => changeProfileToShare('')}
            />
          )}
          onChange={e => changeProfileToShare(e.target.value)}
        />
        <Button
          style={{ marginLeft: '10px' }}
          type='primary'
          onClick={() => shareAccountComponent()}
          disabled={sharingButtonDisable}
        >
          {translation('shareModal.addMembers')}
        </Button>
      </Row>
      <div style={{ marginTop: '15px' }}>
        {mySharedAccounts.map((item) => (
          <Row
            key={item.id}
            style={{
              marginBottom: 10,
            }}
          >
            {item.to.email}
            <Icon
              type='delete'
              style={{ marginLeft: '15px', color: 'var(--00000073)', cursor: 'pointer' }}
              onClick={() => removeSharedAccount(item.id, item.to.email)}
            />
          </Row>
        ))}
      </div>
    </Modal>
  );

  const search = (e: any) => {
    localStorage.removeItem('SelectedFolder');

    if (props.location.pathname === '/profileList') {
      props.onSearch && props.onSearch(e.target.value);
    }

    // @ts-ignore
    props.history.replace({
      pathname: '/profileList',
      query: {
        q: e.target.value,
      },
    });
  };

  const openPricingPage = (): void => {
    openWebsitePricing({
      workspaceId,
      isQuickSettingsEnabled,
      isShowPaymentMethods: firstPlanSelected,
    });
  };

  const restartAndInstallUpdates = async () => {
    changeAppRestartInProcess(true);

    if (isElectron) {
      const isAnyProfileRunning = await ipcRenderer.invoke('check-profiles-running');
      if (isAnyProfileRunning) {
        setIsUpdateNotificationtModalVisable(true);
      } else {
        await sendUpdateAnalytics('app');
        ipcRenderer.invoke('install-app-updates');
      }
    }
  };

  const startBrowserUpdating = async (): Promise<void> => {
    updateBrowserUpdater({
      initialized: true,
      browserUpdating: true,
      showOrbitaDialog: false,
    });

    ipcRenderer.invoke('download-browser');
    sendUpdateAnalytics('orbita');
  };

  const getOrbitaDialog = (): ReactComponentElement<any> | null => {
    if (!(isProfilesListPage && showOrbitaDialog)) {
      return null;
    }

    return (
      <ButtonUpdate
        onClick={startBrowserUpdating}
        style={{ width: '100%' }}
      >
        <span>
          {translation('orbitaUpdateAvailable')}
        </span>
      </ButtonUpdate>
    );
  };

  const getBrowserProgress = () => {
    if (!browserUpdating) {
      if (!['finish', 'error'].includes(downloadProgressStatus || 'progress')) {
        return null;
      }

      if (progressBarTimeout) {
        clearTimeout(progressBarTimeout);
      }

      progressBarTimeout = setTimeout(() => {
        updateBrowserUpdater({
          ...BROWSER_UPDATER_DEFAULT_VALUE,
          initialized: true,
          updateBrowserUpdater,
        });
        ipcRenderer && ipcRenderer.invoke('check-is-update-ready');
      }, 7000);
    }

    if (isDiscSpaceError) {
      return (
        <ProgressRetryingContainer>
          <IconWarning padding={0} margin='0 8px 0 0' />
          <Text>
            {translation('header.noDiskSpace')}
          </Text>
          <IconWrapperWithDescription
            onClick={startBrowserUpdating}
            iconColor='var(--767676)'
            iconHoveredColor='var(--2B2B31)'
            textColor='var(--767676)'
            textHoveredColor='var(--2B2B31)'
            iconType='stroke'
          >
            <TextWithButton>
              {translation('base.retry')}
            </TextWithButton>
            <IconArrowRight padding={0} />
          </IconWrapperWithDescription>
        </ProgressRetryingContainer>
      );
    }

    if (downloadProgressStatus === 'retrying') {
      return (
        <ProgressRetryingContainer>
          <IconWarning padding={0} margin='0 8px 0 0' />
          <span>
            {translation('header.connectivityIssues')}
          </span>
        </ProgressRetryingContainer>
      );
    }

    return (
      <ProgressContainer>
        <ProgressContainerContent>
          <Steps
            style={{ position: 'relative' }}
            size='small'
            status={downloadProgressStatus as 'wait'|'process'|'finish'|'error'|undefined}
            current={currentStep}
          >
            <Step
              title={translation('downloadOrbita.stepOne')}
              subTitle={downloadProgressStr}
              icon={<Icon type='download' />}
            />
            <Step
              title={translation('downloadOrbita.stepTwo')}
              icon={<Icon type='file-zip' />}
            />
            <Step
              title={translation('downloadOrbita.stepThree')}
              icon={<Icon type='copy' />}
            />
            <Step
              title={translation('downloadOrbita.done')}
              icon={<Icon type='check' />}
            />
          </Steps>
        </ProgressContainerContent>
      </ProgressContainer>
    );
  };

  const openBrowserImport = (e: any): void => {
    e.preventDefault;

    if (!hasSuccessPayment || hasTrial) {
      setImportForPaidUsersPopUp(true);

      return;
    }

    setBrowserManagerModalVisible(true);
  };

  const getBrowserImportModal = () =>
    (
      <ProfilesImportManager
        profileImportModalVisible={browserManagerModalVisible}
        toggleProfileImportManagerModal={setBrowserManagerModalVisible}
      />
    );

  const redirectToSite = () => {
    openPricingPage();
    setImportForPaidUsersPopUp(false);
  };

  const resetWorkspaceContext = (): void => {
    updateAvailableWorkspaces([], true);
    updateWorkspace(WORKSPACE_DEFAULT_VALUE);
  };

  useLayoutEffect(() => {
    const badPath = [PRICING_PAGE, '/forgot_password', '/sign_in', '/sign_up', '/quiz', '/pay', '/no_workspace'];
    const isLocationBadPath = badPath.find(p => p === history.location.pathname);

    setNoHeader(!!(
      NEW_FEATURES.header && !_id ||
      (NEW_FEATURES.workspaces && !isWorkspaceLoaded) ||
      isLocationBadPath ||
      !props.hasGlobalHeader && NEW_FEATURES.header ||
      props.hasGlobalHeader && !NEW_FEATURES.header
    ));
  }, [history.location.pathname, _id, NEW_FEATURES.header, NEW_FEATURES.workspaces, props.hasGlobalHeader, isWorkspaceLoaded]);

  if (noHeader) {
    return null;
  }

  if (hasGlobalHeader && NEW_FEATURES.header) {
    return <HeaderNew />;
  }

  const isAppUpdateButtionVisible = appNeedRestart && isProfilesListPage && !(browserUpdating || showOrbitaDialog);

  return (
    <>
      <HeaderContainer>
        <ContainerLogo>
          <LinkMenu to='/'>
            <IconLogo color='var(--FFFFFF)' />
          </LinkMenu>
        </ContainerLogo>
        <ContainerMenu>
          {renderButtonMenu()}
          <LinkMenuMobile to='/'>
            <span>
              {translation('header.profiles')}
            </span>
          </LinkMenuMobile>
          <LinkMenuMobile to='/folders'>
            <span>
              {translation('header.folders')}
            </span>
          </LinkMenuMobile>
          <Popconfirm
            placement='bottom'
            title={translation('header.importNotAllowed')}
            visible={importForPaidUsersPopUp}
            onConfirm={redirectToSite}
            onCancel={() => setImportForPaidUsersPopUp(false)}
            okText={translation('header.changePlan')}
            cancelText={translation('base.cancel')}
          >
            <LinkMenuMobile
              onClick={openBrowserImport}
              to='#'
            >
              <span>
                {translation('header.import')}
              </span>
            </LinkMenuMobile>
          </Popconfirm>

          {
            !window.require && windowWidth > 500 && (
              <LinkMenuMobile to='/download'>
                {translation('downloadApp.download')}
              </LinkMenuMobile>
            )
          }

        </ContainerMenu>
        <ContainerSearchInput>
          <InputSearch
            allowClear={true}
            autoComplete='off'
            placeholder={translation('base.search')}
            onPressEnter={search}
            ref={selectRef}
            onChange={(e) => {
              !e.target.value && search(e);
            }}
            defaultValue={
              (props.location as any).query ? (props.location as any).query.q : ''
            }
          />
        </ContainerSearchInput>
        <div
          style={{ cursor: 'pointer', margin: '0 11px 0px 14px' }}
          onClick={(e): void => setAnchorElBuyProxy(e.currentTarget)}
        >
          <IconTrafficOld padding={0} />
        </div>
        <AccountMenu
          isEmailConfirmed={isEmailConfirmed}
          windowWidth={windowWidth}
          userEmail={userEmail!}
          userProfiles={userProfiles!}
          planMaxProfiles={planMaxProfiles}
          namePlan={namePlan}
          addMembersMenuDisplay='block'
          toggleAccountModal={toggleAccountModal}
          toggleShareAccountModal={toggleShareAccountModal}
          isQuickSettingsEnabled={isQuickSettingsEnabled}
          accountMenuPage={isProfilesListPage}
          setSharedAccounts={setSharedAccounts}
          iconColor={'var(--FFFFFF)'}
        />
      </HeaderContainer>

      {hasTrial ? <Payment>
        <PaymentText>
          {translation('headerNotify.trialNotify.secondTextNewKey', { count: trialDays })}
          <Link to='/personalArea/Billing'>
            {namePlan}
          </Link>
        </PaymentText>
        <PayNowBtn onClick={openPricingPage}>
          {translation('header.payNow')}
        </PayNowBtn>
      </Payment> : null}
      {isAppUpdateButtionVisible ? (
        <ButtonUpdate
          style={{ borderRadius: 0, width: '100%' }}
          type='ghost'
          loading={appRestartInProcess}
          onClick={restartAndInstallUpdates}
        >
          {translation('downloadOrbita.updateAvailable')}
        </ButtonUpdate>
      ): null}
      <AccountManager
        toggleAccountModal={(): void => toggleAccountModal(false)}
        switchAccountModalVisible={switchAccountModalVisible}
        sharedAccounts={sharedAccounts}
        resetWorkspaceContext={resetWorkspaceContext}
      />
      {shareAccountModal()}
      {getOrbitaDialog()}
      {getBrowserProgress()}
      {getBrowserImportModal()}
      <BuyProxy
        anchorEl={anchorElBuyProxy}
        onClose={onCloseBuyProxy}
      />
      <UpdateAppNotificationModal
        isModalVisible={isUpdateNotificationtModalVisable}
        setIsModalVisible={setIsUpdateNotificationtModalVisable}
        setUpdatingLoader={changeAppRestartInProcess}
      />
    </>
  );
};

export default withRouter(Header);
