import { Switch } from '@material-ui/core';
import { Icon } from 'antd';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SwitchContainer, SwitchTitle } from './styles';
import TooltipCustom from '../../../../ui/tooltip-custom';

interface IProfileLanguagesInputBlock {
  autoLang: boolean;
  onChange: (state: boolean) => void;
}

const SwitchAutoLang: FC<IProfileLanguagesInputBlock> = (props) => {
  const { autoLang,  onChange } = props;

  const [anchorElTooltip, setAnchorElTooltip] = useState<HTMLElement | null>(null);

  const { t: translation } = useTranslation();

  return (
    <>
      <SwitchContainer>
        <Switch
          checked={autoLang}
          onChange={(event): void => onChange(event.target.checked)}
        />
        <SwitchTitle>
          {translation('profile.languages.autoLang')}
        </SwitchTitle>
        <div
          onMouseEnter={(event): void => setAnchorElTooltip(event.currentTarget)}
          onMouseLeave={(): void => setAnchorElTooltip(null)}
        >
          <Icon
            style={{
              marginLeft: 5,
              opacity: 0.4,
            }}
            type='info-circle'
          />
        </div>
      </SwitchContainer>
      <TooltipCustom
        style={{ fontSize: 12 }}
        value={translation('profile.languages.autoLangTooltip')}
        anchorEl={anchorElTooltip}
      />
    </>
  );
};

export default SwitchAutoLang;
