import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { IProxy } from '../../../interfaces';
import { IDataToCreateProfile } from '../api';

export const prepareProxyProfiles = async (proxyFromFile: IProxy[]): Promise<IDataToCreateProfile[]> => {
  if (!(proxyFromFile.length || NEW_FEATURES.importProxyFile)) {
    return [{}];
  }

  return proxyFromFile.map(proxy => ({ proxy }));
};
