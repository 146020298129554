import { IRedirectToDesktopProps } from './interfaces/redirect-to-desktop-props.interfaces';
import { TWO_FA_TOKEN_KEY_NAME } from '../../../../common/constants/constants';
import { redirectToElectron } from '../../common/components/redirect-to-desktop-page/utils';
import { getAutoLoginToken } from '../../pricing/api';

export const redirectToDesktop = async ({
  accessToken,
  twoFaToken = localStorage.getItem(TWO_FA_TOKEN_KEY_NAME) || '',
  isNewUser,
}: IRedirectToDesktopProps): Promise<void> => {
  const params = new URLSearchParams();
  if (isNewUser) {
    params.append('isNewUser', 'true');
  }

  if (accessToken) {
    params.append('accessToken', accessToken);
    if (twoFaToken) {
      params.append('twoFaToken', twoFaToken);
    }
  } else {
    const { auto_login_token: autoLoginToken } = await getAutoLoginToken();
    params.append('auto-login-token', autoLoginToken);
  }

  redirectToElectron('o-auth', params);
};
