import React, { createContext } from 'react';

import { IAutomationModalVisibility, IProfile } from '../../interfaces';

export interface IAutomationSearch {
  searchString: string;
  setSearchString: React.Dispatch<React.SetStateAction<string>>;
}

export interface IAutomationCtx extends IAutomationSearch, IAutomationModalVisibility {
  profilesSelected: IProfile[];
  setProfilesSelected: React.Dispatch<React.SetStateAction<IProfile[]>>;
}

export const AUTOMATION_DEFAULT_VALUE: IAutomationCtx = {
  profilesSelected: [],
  setProfilesSelected: () => null,
  searchString: '',
  setSearchString: () => null,
  isAutomationModalVisible: false,
  setIsAutomationModalVisible: () => null,
};

export const automationContext = createContext<IAutomationCtx>(AUTOMATION_DEFAULT_VALUE);
