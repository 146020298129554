import styled from '@emotion/styled';
import { Button } from 'antd';

export const ActionButton = styled(Button)`
  padding: 0 16px;
  margin-top: 11px;
  height: 32px;

  border-radius: 4px;
  border-color: var(--767676-actions-button);
  color: var(--767676-actions-button);
  opacity: 1;

  :hover {
    border-color: var(--00B86D-actions-button);
    color: var(--00B86D-actions-button);
    opacity: 1;
  }

  :focus {
    border-color: var(--767676-actions-button);
    color: var(--767676-actions-button);
  }
`;

export const RedActionButton = styled(ActionButton)`
  :hover {
    border-color: var(--EB5757-actions-button);
    color: var(--EB5757-actions-button);
  }
`;
