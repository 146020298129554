import React from 'react';

import { IOrbitaModalBase } from '../../interfaces';
import ModalBase from '../../modal-base';

interface IIncompatibleCannotRunModal {
  visible: boolean;
  handleCancel: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const IncompatibleCannotRunModal: React.FC<IIncompatibleCannotRunModal> = (props) => {
  const { visible, handleCancel } = props;
  const keyBase = 'modals.orbitaIncompatibleCannotRunModal.';

  const modalBaseProps: IOrbitaModalBase = {
    visible,
    handleCancel,
    titleKey: keyBase + 'title',
    body: {
      key: keyBase + 'body',
    },
    primaryButton: {
      key: keyBase + 'submit',
      isDisabled: false,
      handleClick: handleCancel,
    },
  };

  return <ModalBase {...modalBaseProps} />;
};

export default IncompatibleCannotRunModal;
