import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconEyeCrossed: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <g clipPath='url(#clip0_24199_12066)'>
        <path
          d='M13.5001 5.73438C14.7301 6.90237 15.5001 8.00238 15.5001 8.00238C15.5001 8.00238 12.0001 13.0024 8.00009 13.0024C7.48809 13.0024 6.98509 12.9204 6.49609 12.7774'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M4.275 11.725C2.048 10.243 0.5 8 0.5 8C0.5 8 4 3 8 3C9.326 3 10.605 3.553 11.712 4.288'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M1 15L15 1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  </IconWrapper>
);
