import React from 'react';

const IconImportProfileUpload = () => (
    <svg width="36" height="40" viewBox="0 0 36 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.3233 1.68421C16.3233 0.754046 17.0601 0 17.969 0C18.8778 0 19.6146 0.754047 19.6146 1.68421V30.3158C19.6146 31.246 18.8778 32 17.969 32C17.0601 32 16.3233 31.246 16.3233 30.3158V1.68421Z" fill="var(--7A7B7B)"/>
        <path d="M27.3303 18.6553C27.9397 18.0297 28.9292 18.0281 29.5405 18.6518C30.1518 19.2754 30.1534 20.2882 29.544 20.9138L19.2456 31.4867C18.6362 32.1123 17.6467 32.1139 17.0354 31.4902C16.4241 30.8665 16.4225 29.8538 17.0319 29.2281L27.3303 18.6553Z" fill="var(--7A7B7B)"/>
        <path d="M8.66972 18.6553C8.06033 18.0297 7.07077 18.0281 6.45948 18.6518C5.84819 19.2754 5.84665 20.2882 6.45603 20.9138L16.7544 31.4867C17.3638 32.1123 18.3533 32.1139 18.9646 31.4902C19.5759 30.8665 19.5775 29.8538 18.9681 29.2281L8.66972 18.6553Z" fill="var(--7A7B7B)"/>
        <path d="M0 37H36V38.5C36 39.3284 35.3284 40 34.5 40H1.5C0.671574 40 0 39.3284 0 38.5V37Z" fill="var(--7A7B7B)"/>
        <path d="M33 27.5C33 26.6716 33.6716 26 34.5 26C35.3284 26 36 26.6716 36 27.5V37H33V27.5Z" fill="var(--7A7B7B)"/>
        <path d="M0 27.5C0 26.6716 0.671573 26 1.5 26C2.32843 26 3 26.6716 3 27.5V37H0V27.5Z" fill="var(--7A7B7B)"/>
    </svg>
);

export default IconImportProfileUpload;
