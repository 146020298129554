import { Icon } from 'antd';
import React from 'react';

import { SpinnerWrapper } from '../styles';

interface ILoader {
  isVisible: boolean;
  LogoComponent: React.FC;
}

const Loader: React.FC<ILoader> = ({ isVisible, LogoComponent }) => {
  if (!isVisible) {
    return  <LogoComponent />;
  }

  return (
    <SpinnerWrapper>
      <Icon type='loading' />
    </SpinnerWrapper>
  );
};

export default Loader;
