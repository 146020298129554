import { SerializedStyles } from '@emotion/core';
import css from '@emotion/css';
import styled from '@emotion/styled';

export const Wrapper = styled('div')<{ newStyle?: boolean }>`
  display: flex;
  align-items: center;
  font-size: 15px;
  
  ${(props): SerializedStyles|null => props.newStyle ? css`
    margin-left: 8px;

    * + * {
      margin-left: 4px;
    }
  ` : null}
`;
