import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { ITag } from '../../features/tags/interfaces/tag.interface';
import { reconcileCustomStatusGroups } from '../profiles-list.atom';

const tagsListAtom = atom<ITag[]>([]);

export const setTagsList = (newTags: ITag[]): void => {
  getDefaultStore().set(tagsListAtom, newTags);
  reconcileCustomStatusGroups();
};

export const getTagsList = (): ITag[] => getDefaultStore().get(tagsListAtom);
export const useTagsList = (): ITag[] => useAtomValue(tagsListAtom);

export const getTagById = (tagId: string | null): ITag | null => {
  if (!tagId) {
    return null;
  }

  const tagsList = getTagsList();

  return tagsList.find((tag) => tag.id === tagId) || null;
};

export const addNewTagToTagsList = (tag: ITag): void => {
  const newTags = [...getTagsList(), { ...tag }].sort((curr, next) => curr.title.localeCompare(next.title));
  setTagsList(newTags);
};
