import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonRun } from './styles';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { onProfileLaunched } from '../../../state/quick-pricing.atom';
import { openCloudBrowserUrlInSingleTab } from '../../../utils/cloud-browser';
import { sendUserEventToServer } from '../../../utils/send-analytics-to-server';
import { sendActionAnalytics } from '../../common/api';

const isElectron = !!window.require;
let ipcRenderer: Electron.IpcRenderer;
if (isElectron) {
  ({ ipcRenderer } = window.require('electron'));
}

interface IViewProfileButton {
  profileId: string;
  remoteOrbitaUrl?: string;
  isInDrawer?: boolean;
}

const ViewProfileButton: FC<IViewProfileButton> = (props): JSX.Element => {
  const { profileId, remoteOrbitaUrl = '', isInDrawer = false } = props;

  const { t: translation } = useTranslation();

  const browserUrl = remoteOrbitaUrl;

  const focusOrbita = async (): Promise<void> => {
    sendActionAnalytics('opened orbita via View from app', { profile: profileId, appVersion: window?.gologinAppVersion });
    if (browserUrl) {
      window.require('electron').shell.openExternal(browserUrl);

      return;
    }

    ipcRenderer && ipcRenderer.invoke('focus-orbita-window', { profileId });
  };

  const sendStats = async (): Promise<void> => {
    if (isElectron) {
      return;
    }

    await sendUserEventToServer('openedBrowser');

    onProfileLaunched();
  };

  if (isElectron) {
    return (
      <ButtonRun
        isInDrawer={isInDrawer}
        newStyle={!!NEW_FEATURES.header}
        onClick={focusOrbita}
      >
        {translation('base.view')}
      </ButtonRun>
    );
  }

  const handleClickWeb: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();
    openCloudBrowserUrlInSingleTab(browserUrl, profileId);
    sendStats();
  };

  return (
    <ButtonRun
      isInDrawer={isInDrawer}
      newStyle={!!NEW_FEATURES.header}
      onClick={handleClickWeb}
    >
      {translation('base.view')}
    </ButtonRun>
  );
};

export default ViewProfileButton;
