import { MouseSensor, MouseSensorOptions, SensorProps } from '@dnd-kit-contextless/core';
import { atom, getDefaultStore } from 'jotai';
import type { MouseEvent } from 'react';

const MouseButton = {
  RightClick: 2,
} as const;

export type MouseSensorProps = SensorProps<MouseSensorOptions>;

const isDragAndDropBlockedAtom = atom<boolean>(false);

const getIsDragAndDropBlocked = (): boolean => getDefaultStore().get(isDragAndDropBlockedAtom);
export const setIsDragAndDropBlocked = (newIsDragAndDropBlocked: boolean): void =>
  getDefaultStore().set(isDragAndDropBlockedAtom, newIsDragAndDropBlocked);

export class BlockableMouseSensor extends MouseSensor {
  constructor(props: MouseSensorProps) {
    super(props);
  }

  static activators = [
    {
      eventName: 'onMouseDown' as const,
      handler: (
        mouseEvent: MouseEvent,
        { onActivation }: MouseSensorOptions,
      ): boolean => {
        const { nativeEvent: event } = mouseEvent;
        const isBlocked = getIsDragAndDropBlocked();
        if (isBlocked || event.button === MouseButton.RightClick) {
          return false;
        }

        onActivation?.({ event });

        return true;
      },
    },
  ];
}
