import styled from '@emotion/styled';

export const ProxyDeletionConfirmWrapper = styled.section`
  width: 100%;
  height: 100%;

  position: absolute;
  left: 0;
  top: 0;

  display: flex;
  align-items: center;

  background: var(--FFFFFF-proxy-manager-removing-confirm);
`;

export const ProxyDeletionConfirmContent = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ProxyDeletionConfirmTitle = styled.div`
  width: 100%;
  text-align: center;
`;

export const ProxyDeletionConfirmButtons = styled.div`
  width: 100%;
  margin-top: 16px;
  text-align: center;
`;

const ProxyDeletionConfirmButtonBase = styled.button`
  border: none;
  margin: 0 8px;
  outline: none;

  line-height: 20px;
  letter-spacing: 0.25px;

  cursor: pointer;
  background: none;
`;

export const ProxyDeleteButton = styled(ProxyDeletionConfirmButtonBase)`
  padding: 3px 12px;
  border: 1px solid var(--CDCDCD-proxy-manager-removing-confirm);
  border-radius: 14px;

  color: var(--767676-proxy-manager-removing-confirm);

  &:hover {
    color: var(--F5222D-proxy-manager-removing-confirm);
    border: 1px solid var(--F5222D-proxy-manager-removing-confirm);
  }
`;

export const ProxyCancelDeleteButton = styled(ProxyDeletionConfirmButtonBase)`
  padding: 3px 0;

  color: var(--CDCDCD-proxy-manager-removing-confirm);

  &:hover {
    color: var(--222222-proxy-manager-removing-confirm);
  }
`;
