import React from 'react';

const IconGoogleLogo: React.FC = () => (
  <svg
    name='google-logo'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect width='24' height='24' fill='#FFFFFF' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M23.04 12.2615C23.04 11.446 22.9668 10.6619 22.8309 9.90918H12V14.3576H18.1891C17.9225 15.7951 17.1123 17.013 15.8943 17.8285V20.714H19.6109C21.7855 18.7119 23.04 15.7637 23.04 12.2615Z'
      fill='#4285F4'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.9999 23.4996C15.1049 23.4996 17.7081 22.4698 19.6108 20.7134L15.8943 17.828C14.8645 18.518 13.5472 18.9257 11.9999 18.9257C9.00471 18.9257 6.46948 16.9028 5.56516 14.1846H1.72311V17.1641C3.61539 20.9225 7.50448 23.4996 11.9999 23.4996Z'
      fill='#34A853'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.56523 14.1855C5.33523 13.4955 5.20455 12.7584 5.20455 12.0005C5.20455 11.2425 5.33523 10.5055 5.56523 9.81548V6.83594H1.72318C0.944318 8.38844 0.5 10.1448 0.5 12.0005C0.5 13.8562 0.944318 15.6125 1.72318 17.165L5.56523 14.1855Z'
      fill='#FBBC05'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.9999 5.07386C13.6883 5.07386 15.2043 5.65409 16.3961 6.79364L19.6945 3.49523C17.7029 1.63955 15.0997 0.5 11.9999 0.5C7.50448 0.5 3.61539 3.07705 1.72311 6.83545L5.56516 9.815C6.46948 7.09682 9.00471 5.07386 11.9999 5.07386Z'
      fill='#EA4335'
    />
  </svg>
);

export default IconGoogleLogo;
