import { message } from 'antd';
import React, { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { changePassword } from './api';
import { StyledBtn, StyledInput } from './styles';

interface IPasswordChangeForm {
  onSubmit: () => any;
}

const PasswordChangeForm: FC<IPasswordChangeForm> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [oldPassword, setOldPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>('');

  const { t: translation } = useTranslation();

  const onConfirmClicked = async () => {
    try {
      if (!oldPassword) {
        message.error(translation('notifications.error.pleaseEnterYourPassword'));

        return;
      }

      if (!newPassword) {
        message.error(translation('notifications.error.pleaseEnterYourNewPassword'));

        return;
      }

      if (newPassword.length < 8) {
        message.error(translation('notifications.error.passMustBeGrater8'));

        return;
      }

      if (!newPasswordConfirm) {
        message.error(translation('notifications.error.pleaseConfirmYourNewPass'));

        return;
      }

      if (newPassword !== newPasswordConfirm) {
        message.error(translation('notifications.error.passDoNotMatch'));

        return;
      }

      setIsLoading(true);

      await changePassword({
        oldPassword,
        newPassword,
        newPasswordConfirm,
      });

      props.onSubmit();

    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <StyledInput autoFocus={true} autoComplete='off' type='password' value={oldPassword} placeholder={translation('changePassInSettings.yourPassword')} onChange={(e) => setOldPassword(e.target.value)} />
      <StyledInput type='password' autoComplete='off' value={newPassword} placeholder={translation('changePassInSettings.newPassword')} onChange={(e) => setNewPassword(e.target.value)} />
      <StyledInput type='password' autoComplete='off' value={newPasswordConfirm} placeholder={translation('changePassInSettings.confirmPassword')} onChange={(e) => setNewPasswordConfirm(e.target.value)} />
      <StyledBtn type='primary' loading={isLoading} onClick={onConfirmClicked}>{translation('base.submit')}</StyledBtn>
    </>
  );
};

export default memo(PasswordChangeForm);
