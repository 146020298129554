import { IIconWrapper } from '../../../ui/gologin-header/icons/wrapper';

export const PROXY_SAVED_SELECTOR_WIDTH = 278;
export const SAVED_PROXY_SELECTOR_CONTENT_HEIGHT: React.CSSProperties['height'] = '20px';

export const iconProps: IIconWrapper = {
  padding: 0,
  iconColor: 'var(--B5B5BA-proxy-tab-icon)',
  iconHoveredColor: 'var(--36363D-proxy-tab-icon-active)',
};
