import React from 'react';
import Intercom from './intercom';

export default () => {
  return (
    <>
      <Intercom />
    </>
  );
};
