import { MenuItem, Divider } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import RoleDescription from '../../../../../members/role-select/role-description';
import RoleTitle from '../../../../../members/role-select/role-title';
import { IShareActionsPropsBase } from '../../interfaces';
import { CustomMenu, ItemChangeRoleTitle } from '../../styles';

const ShareActionsDropdown: React.FC<IShareActionsPropsBase> = (props) => {
  const { action, actionsGlobal, inviteId = [], handleActionSelect, email = '', disableSharing } = props;
  const [actionFirst] = action;

  const { t: translation } = useTranslation();

  return (
    <CustomMenu>
      {actionsGlobal.map((actionGlobal) => {
        const isShareLinksMenuNext = ['pause', 'resume'].includes(actionGlobal);

        return (
          <>
            {isShareLinksMenuNext ? <Divider style={{ backgroundColor: 'var(--D9D9D9-antd)' }} /> : null}
            <MenuItem
              onClick={(): Promise<void> => handleActionSelect(actionGlobal, inviteId, email)}
              key={actionGlobal}
              style={{ padding: '8px 16px' }}
            >
              <div>
                <RoleTitle role={actionGlobal} selectedRole={action.length === 1 ? actionFirst : 'varies'} />
                <RoleDescription type='sharing' role={actionGlobal} />
              </div>
            </MenuItem>
          </>
        );
      })}
      {!disableSharing ? (
        <MenuItem
          onClick={(): Promise<void> => handleActionSelect('remove', inviteId, email)}
          style={{ height: '36px', padding: '8px 16px' }}
        >
          <ItemChangeRoleTitle>
            {translation('newShareModal.removeMember')}
          </ItemChangeRoleTitle>
        </MenuItem>
      ) : null}
    </CustomMenu>
  );
};

export default ShareActionsDropdown;
