import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { ISearchHistory } from './interfaces';
import { SEARCH_HISTORY_NAME } from '../../../common/constants/constants';

const SEARCH_HISTORY_VERSION = 3;

const searchHistoryAtom = atom<ISearchHistory[]>([]);

const useSearchHistory = (): ISearchHistory[] => useAtomValue(searchHistoryAtom);

const isCurrentUserAndWorkspace = (search: ISearchHistory, userId: string, workspaceId?: string): boolean =>
  userId === search.userId && workspaceId === search.workspaceId;

export interface ISearchHistoryParams {
  newSearch: string;
  userId: string;
  type: ISearchHistory['type'];
  workspaceId?: string;
}

export const addSearchToHistory = ({ newSearch, userId, type, workspaceId }: ISearchHistoryParams): void => {
  const searchHistory = getDefaultStore().get(searchHistoryAtom);
  const isSearchInHistory = !!searchHistory
    .find(search => search.query === newSearch && isCurrentUserAndWorkspace(search, userId, workspaceId));

  if (!newSearch || isSearchInHistory) {
    return;
  }

  const currentDate = new Date();
  const newSearchHistory: ISearchHistory = {
    query: newSearch,
    userId, workspaceId,
    date: currentDate,
    type,
    searchVersion: SEARCH_HISTORY_VERSION,
  };

  const newSearchHistoryList: ISearchHistory[] = [
    ...searchHistory,
    newSearchHistory,
  ];

  getDefaultStore().set(searchHistoryAtom, newSearchHistoryList);

  let localSearchHistory = localStorage.getItem(SEARCH_HISTORY_NAME);
  if (!localSearchHistory) {
    localSearchHistory = '[]';
  }

  const parsedLocalSearchHistory: ISearchHistory[] = JSON.parse(localSearchHistory);
  const newLocalSearchHistory: ISearchHistory[] = [
    ...parsedLocalSearchHistory,
    newSearchHistory,
  ];

  localStorage.setItem(SEARCH_HISTORY_NAME, JSON.stringify(newLocalSearchHistory));
};

export const removeSearchFromHistory = (searchToRemove: string, userId: string, workspaceId?: string): void => {
  const searchHistory = getDefaultStore().get(searchHistoryAtom);

  const newSearchHistory: ISearchHistory[] = searchHistory
    .filter(search => !(search.query === searchToRemove && isCurrentUserAndWorkspace(search, userId, workspaceId)));

  getDefaultStore().set(searchHistoryAtom, newSearchHistory);

  const localSearchHistory = localStorage.getItem(SEARCH_HISTORY_NAME);
  if (!localSearchHistory) {
    return;
  }

  const parsedLocalSearchHistory: ISearchHistory[] = JSON.parse(localSearchHistory);
  const newLocalSearchHistory: ISearchHistory[] = parsedLocalSearchHistory
    .filter(search => !(search.query === searchToRemove && isCurrentUserAndWorkspace(search, userId, workspaceId)));

  localStorage.setItem(SEARCH_HISTORY_NAME, JSON.stringify(newLocalSearchHistory));
};

export const useShownSearchHistory = (searchInputText: string, type: ISearchHistory['type']): ISearchHistory[] => {
  const searchHistory = useSearchHistory();
  const filteredSearchHistory = searchHistory
    .filter(search => search.query.toLowerCase()?.includes(searchInputText.toLowerCase()));

  const preparedSearchHistory = searchInputText ? filteredSearchHistory : searchHistory;

  return [...preparedSearchHistory].filter(queryObject => queryObject.type === type).reverse().slice(0, 5);
};

export const refreshSearchHistory = (userId: string, workspaceId?: string): void => {
  const localSearchHistory = localStorage.getItem(SEARCH_HISTORY_NAME);
  if (!localSearchHistory) {
    return;
  }

  const parsedLocalSearchHistory: ISearchHistory[] = JSON.parse(localSearchHistory);
  const preparedSearchHistory = parsedLocalSearchHistory
    .filter(searchHistory => isCurrentUserAndWorkspace(searchHistory, userId, workspaceId));

  getDefaultStore().set(searchHistoryAtom, preparedSearchHistory);
};
