import { IGetArchivedProxiesPaginatedParams, IProxiesPaginatedResponce } from './interfaces/archived-proxies.interface';
import { IFreeProxy } from './interfaces/IFreeProxy';
import { ISetProxyStatusParams } from './interfaces/ISetProxyStatusParams';
import { ISetSharedProxyStatusParams } from './interfaces/ISetSharedProxyStatusParams';
import { API_BASE_URL } from '../../../common/constants/constants';
import { normalizeProxy } from '../../../common/utils';
import { ISharedProxy } from '../../features/proxy/components/interfaces/shared-proxy.interfaces';
import { DEFAULT_PROXIES_LIST } from '../../features/proxy/constants';
import { IProfile, IProxy } from '../../interfaces';
import { http } from '../../services';

export const requestProxiesList = async (): Promise<any> => {
  const response = await http(`${API_BASE_URL}/proxy`);

  return response.body;
};

export const requestProxiesPaginated = async (page = 1): Promise<{ proxies: IProxy[]; hasMore: boolean }> => {
  const response = await http(`${API_BASE_URL}/proxy/v2?page=${page}`);

  return response.body;
};

export const requestArchivedProxiesPaginated = async (params: IGetArchivedProxiesPaginatedParams): Promise<IProxiesPaginatedResponce> => {
  const { offset = 0, search = '', sortField = '', sortOrder = '' } = params;
  const url = new URL(`${API_BASE_URL}/proxy/archive`);
  url.searchParams.append('offset', offset.toString());
  search && url.searchParams.append('search', search);
  sortField && url.searchParams.append('sortField', sortField);
  sortOrder && url.searchParams.append('sortOrder', sortOrder);
  const response = await http(url.toString());
  const { proxies: archivedProxies = [], hasMore } = response.body;

  return {
    proxies: archivedProxies.map((proxy: IProxy) => ({ ...proxy, isArchivedProxy: true })),
    hasMore,
  };
};

export const requestProxiesFree = async (): Promise<IFreeProxy[]> => {
  const response = await http(`${API_BASE_URL}/proxy/free`).catch(() => ({ body: DEFAULT_PROXIES_LIST }));

  return response.body;
};

export const requestProxiesShared = async (): Promise<ISharedProxy[]> => {
  const response = await http(`${API_BASE_URL}/proxy/shared`).catch(() => ({ body: DEFAULT_PROXIES_LIST }));

  return response.body;
};

export type ITableProxyConnectedProfile = Pick<IProfile, 'id'|'name'>;

interface ITableProxy {
  id: string;
  connectedProfiles: ITableProxyConnectedProfile[];
}

interface ITableProxiesResponse {
  proxies: ITableProxy[];
}

export const requestTableProxies = async (proxiesIds: string[] = [], workspaceId = ''): Promise<ITableProxy[]> => {
  const url = new URL(`${API_BASE_URL}/proxy/table-proxies`);
  url.searchParams.append('proxiesIds', proxiesIds.join(','));
  if (workspaceId) {
    url.searchParams.append('workspaceId', workspaceId);
  }

  return http(url.toString())
    .then(({ body }: { body: ITableProxiesResponse }) => body.proxies)
    .catch(() => DEFAULT_PROXIES_LIST);
};

export const setProxyStatuses = async (proxies: ISetProxyStatusParams[]): Promise<void> => {
  const body = JSON.stringify({ proxies: proxies.map(proxy => normalizeProxy(proxy)) });
  const data = await http(`${API_BASE_URL}/proxy/set_proxy_statuses`, { method: 'POST', body });

  return data.body;
};

export const setSharedProxyStatuses = async (proxies: ISetSharedProxyStatusParams[]): Promise<void> => {
  const body = JSON.stringify({ proxies });
  const data = await http(`${API_BASE_URL}/proxy/set_shared_proxy_statuses`, { method: 'POST', body });

  return data.body;
};

export const getProxyRequest = async (id: string): Promise<IProxy> => {
  const response = await http(`${API_BASE_URL}/proxy/${id}`);

  return response.body;
};
