import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { reconcileFolderGroups } from '../profiles-list.atom';

// temporary interface, should be union between workspace & shared folders
export interface IGenericFolder {
  id: string;
  name: string;
  shared?: boolean;
}

const foldersListAtom = atom<IGenericFolder[]>([]);

export const setFoldersList = (newFolders: IGenericFolder[]): void => {
  getDefaultStore().set(foldersListAtom, newFolders);
  reconcileFolderGroups();
};

export const getFoldersList = (): IGenericFolder[] => getDefaultStore().get(foldersListAtom);
export const useFoldersList = (): IGenericFolder[] => useAtomValue(foldersListAtom);

export const getFolderById = (folderId: string | null): IGenericFolder | null => {
  if (!folderId) {
    return null;
  }

  const foldersList = getFoldersList();

  return foldersList.find((folder) => folder.id === folderId) || null;
};
