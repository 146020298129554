import React from 'react';

const IconArrowBack = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.5 7.5H8C8.92826 7.5 9.8185 7.13125 10.4749 6.47487C11.1313 5.8185 11.5 4.92826 11.5 4C11.5 3.07174 11.1313 2.1815 10.4749 1.52513C9.8185 0.868749 8.92826 0.5 8 0.5H4.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4 4L0.5 7.5L4 11" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default IconArrowBack;
