import React from 'react';

const IconGooglePay = () => (
  <svg width="43" height="20" viewBox="0 0 43 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_13646_222283)">
      <path d="M20.5543 10.3415V15.2234H18.9795V3.1499H23.074C24.0714 3.1499 25.0162 3.51736 25.7511 4.19977C26.4861 4.82969 26.8535 5.77457 26.8535 6.77195C26.8535 7.76932 26.4861 8.66171 25.7511 9.34413C25.0162 10.0265 24.1238 10.394 23.074 10.394L20.5543 10.3415ZM20.5543 4.61972V8.81919H23.179C23.7564 8.81919 24.3338 8.60922 24.7013 8.18927C25.5412 7.40187 25.5412 6.08954 24.7538 5.30213L24.7013 5.24964C24.2813 4.82969 23.7564 4.56723 23.179 4.61972H20.5543Z" fill="var(--5F6368)" />
      <path d="M30.4736 6.71875C31.6285 6.71875 32.5209 7.03371 33.2033 7.66363C33.8857 8.29355 34.2006 9.13345 34.2006 10.1833V15.2227H32.7308V14.0678H32.6783C32.0484 15.0127 31.156 15.4852 30.1062 15.4852C29.2138 15.4852 28.4264 15.2227 27.7964 14.6978C27.219 14.1728 26.8516 13.4379 26.8516 12.6505C26.8516 11.8106 27.1665 11.1282 27.7964 10.6033C28.4264 10.0783 29.3188 9.86836 30.3686 9.86836C31.3135 9.86836 32.0484 10.0258 32.6258 10.3933V10.0258C32.6258 9.5009 32.4159 8.97597 31.9959 8.66101C31.576 8.29355 31.051 8.08358 30.4736 8.08358C29.5812 8.08358 28.8988 8.45103 28.4264 9.18594L27.0615 8.34605C27.9014 7.24368 29.0038 6.71875 30.4736 6.71875ZM28.4789 12.703C28.4789 13.123 28.6888 13.4904 29.0038 13.7004C29.3712 13.9628 29.7912 14.1203 30.2111 14.1203C30.8411 14.1203 31.471 13.8579 31.9434 13.3854C32.4684 12.913 32.7308 12.3355 32.7308 11.7056C32.2584 11.3382 31.576 11.1282 30.6836 11.1282C30.0537 11.1282 29.5287 11.2857 29.1088 11.6006C28.6888 11.8631 28.4789 12.2306 28.4789 12.703Z" fill="var(--5F6368)" />
      <path d="M42.7053 6.9812L37.5085 18.8972H35.9337L37.8759 14.7502L34.4639 7.03369H36.1437L38.6108 12.9655H38.6633L41.078 7.03369H42.7053V6.9812Z" fill="var(--5F6368)" />
      <path d="M14.3053 9.29086C14.3053 8.81842 14.2528 8.34598 14.2004 7.87354H7.63867V10.5507H11.3657C11.2082 11.3906 10.7358 12.178 10.0009 12.6504V14.3827H12.2581C13.5704 13.1754 14.3053 11.3906 14.3053 9.29086Z" fill="var(--4285F4)" />
      <path d="M7.63954 16.0636C9.5293 16.0636 11.1041 15.4337 12.259 14.3838L10.0017 12.6515C9.37182 13.0714 8.58442 13.3339 7.63954 13.3339C5.85476 13.3339 4.27996 12.1266 3.75502 10.4468H1.44531V12.2316C2.65266 14.5938 5.01487 16.0636 7.63954 16.0636Z" fill="var(--34A853)" />
      <path d="M3.75599 10.4463C3.44102 9.60642 3.44102 8.66154 3.75599 7.76915V5.98438H1.44627C0.448898 7.92663 0.448898 10.2363 1.44627 12.2311L3.75599 10.4463Z" fill="var(--FBBC04)" />
      <path d="M7.63978 4.93414C8.63716 4.93414 9.58204 5.3016 10.3169 5.98401L12.3117 3.98926C11.0519 2.83441 9.37206 2.15199 7.69227 2.20448C5.0676 2.20448 2.6529 3.6743 1.49805 6.03651L3.80776 7.82128C4.2802 6.14149 5.855 4.93414 7.63978 4.93414Z" fill="var(--EA4335)" />
    </g>
    <defs>
      <clipPath id="clip0_13646_222283">
        <rect width="41.9948" height="20" fill="var(--FFFFFF)" transform="translate(0.710938)" />
      </clipPath>
    </defs>
  </svg>

);

export default IconGooglePay;
