import React, { useRef, useState } from 'react';

import ProxyGroupAddButtonPopover, { ProxyGroupAddButtonPopoverPropsBase } from './proxy-group-add-button-menu-popover';
import { IconArrowDownWrapper } from './styles';
import { DivAnchor } from '../../../../../../types';
import { IconArrowDown } from '../../../../../../ui/gologin-header/icons/icon-arrow-down';

const ICON_ACTIVE_COLOR = 'var(--2B2B31-header)';

type ProxyGroupAddButtonMenuProps = ProxyGroupAddButtonPopoverPropsBase & {
  setIsArrowHovered: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProxyGroupAddButtonMenu: React.FC<ProxyGroupAddButtonMenuProps> = (props) => {
  const { setIsArrowHovered } = props;

  const [popoverAnchorEl, setPopoverAnchorEl] = useState<DivAnchor>(null);

  const buttonWrapperRef = useRef<DivAnchor>(null);

  const handleClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    setPopoverAnchorEl(previousValue => previousValue ? null : buttonWrapperRef.current);
  };

  const handleClose = (): void => {
    setPopoverAnchorEl(null);
  };

  return (
    <>
      <IconArrowDownWrapper
        ref={buttonWrapperRef}
        onClick={handleClick}
        onMouseOver={(): void => setIsArrowHovered(true)}
        onMouseLeave={(): void => setIsArrowHovered(false)}
      >
        <IconArrowDown
          padding='4px 2px'
          margin='0 0 0 5px'
          borderRadius={4}
          hoveredHighlight={true}
          highlight={!!popoverAnchorEl}
          iconColor={popoverAnchorEl ? ICON_ACTIVE_COLOR : 'var(--98989F-header)'}
          iconHoveredColor={ICON_ACTIVE_COLOR}
        />
      </IconArrowDownWrapper>
      <ProxyGroupAddButtonPopover
        {...props}
        anchorElement={popoverAnchorEl}
        handleClose={handleClose}
      />
    </>
  );
};

export default ProxyGroupAddButtonMenu;
