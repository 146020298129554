import * as Sentry from '@sentry/react';

import { E_SENTRY_ERRORS } from './sentry-messages';
import { ReactError } from './sentry-parameters/custom-errors';
import { ISendReactErrorToSentryProps, ISentryTag } from '../interfaces/sentry/sentry.interfaces';

export const sendReactErrorToSentry = (props: ISendReactErrorToSentryProps): void => {
  const {
    message,
    level = 'error',
    tags = [],
    transactionName,
    extra = {},
  } = props;

  Sentry.captureException(new ReactError(JSON.stringify(message)), (scope) => {
    scope.setLevel(level);
    scope.setTransactionName(transactionName);
    scope.setFingerprint([transactionName]);
    if (Object.keys(extra).length) {
      scope.setExtra('info', {
        data: JSON.stringify(extra),
      });
    }

    tags.forEach((tagArray: ISentryTag) => {
      const [tag, value] = tagArray;
      scope.setTag(tag, value);
    });

    return scope;
  });
};

export const sendReactAnalyticsError = (error: any): void => {
  const { body = { message: E_SENTRY_ERRORS.UNKNOWN_ERROR } } = error || {};
  const { message } = body;

  sendReactErrorToSentry({
    message, transactionName: 'send-analytics-error',
    tags: [['scenario', 'send-analytics']],
    extra: { message },
  });
};
