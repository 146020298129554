import { Icon } from 'antd';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CurrentExtensionsWindow,
  ExtensionsButton,
  ExtensionTabTable,
} from './domElements';
import { getExtensionList } from './get-extension-list';
import {
  IExtensionFromDb,
} from './interfaces/extension.interface';
import { IProfile } from '../../../interfaces';
import { extensionsContext } from '../../../state';
import { openProfilesTableModal, PROFILE_EXTENSIONS_MODAL_NAME } from '../../../state/profiles-table-modal.atom';
import IconExtension from '../../../ui/icons/IconExtension';
import { ExtensionsList } from '../../modalsComponents/components/extensions-modal/extensions-list';
import { ExtensionsModal } from '../../modalsComponents/components/extensions-modal/extensions-modal';

interface IExtensionTab {
  profileInfo: any;
  updateProfileInfo: (profileInfo: any) => any;
}

export const CHROME_STORE_URL = 'https://chrome.google.com/webstore/detail';

const ExtensionTab: FC<IExtensionTab> = props => {
  const [userExtensions, setUserExtensions] = useState<IExtensionFromDb[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const mountedRef = useRef(true);

  const { isLoading: isLoadingExtensions, updateExtensionsMap, getExtensions } = useContext(extensionsContext);

  const { t: translation } = useTranslation();

  useEffect(() => {
    if (mountedRef.current) {
      fetchProfileExtensions();
    }

    return () => {
      mountedRef.current = false;
    };
  }, [props.profileInfo.os]);

  const fetchProfileExtensions = async (): Promise<void> => {
    setIsLoading(true);
    const extensionList = await getExtensionList({
      chromeExtensions: props.profileInfo.chromeExtensions,
      userChromeExtensions: props.profileInfo.userChromeExtensions,
      updateProfileInfo: props.updateProfileInfo,
      isLoadingExtensions,
      updateExtensionsMap,
      getExtensions,
    });

    setUserExtensions(extensionList);
    setIsLoading(false);
  };

  return (
    <ExtensionTabTable>
      <ExtensionsButton type='primary' onClick={(): void => openProfilesTableModal(PROFILE_EXTENSIONS_MODAL_NAME)}>
        <Icon component={IconExtension} style={{ width: 14, height: 14, stroke: 'var(--FFFFFF-extensions)' }} />
        {translation('storage.extension.addExtensions')}
      </ExtensionsButton>
      <CurrentExtensionsWindow>
        <ExtensionsList
          isLoading={isLoading}
          extensions={userExtensions}
          setUserExtensions={setUserExtensions}
          updateProfileInfo={(selectedProfilesIds: string[], profileInfo: Partial<IProfile>): void => props.updateProfileInfo(profileInfo)}
          updateExtensionsMap={updateExtensionsMap}
        />
      </CurrentExtensionsWindow>
      <ExtensionsModal
        userExtensionsTab={userExtensions}
        setUserExtensionsExtensionsTab={setUserExtensions}
        updateProfileInfo={(selectedProfilesIds: string[], profileInfo: Partial<IProfile>): void => props.updateProfileInfo(profileInfo)}
        profileInfo={props.profileInfo}
      />
    </ExtensionTabTable>
  );
};

export default ExtensionTab;
