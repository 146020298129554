import React, { FC, memo } from 'react';

import GologinTableHeaderColumn, { IResizeProps } from './gologin-table-header-column';
import { GologinTableHeaderContainer } from './styles';
import { IProfilesTableColumn } from '../../features/quickProfiles/components/interfaces/app-settings.interface';

interface IGologinTableHeader extends IResizeProps {
  columns: IProfilesTableColumn[];
}

const GologinTableHeader: FC<IGologinTableHeader> = (props) => {
  const { columns, ...columnProps } = props;

  return (
    <GologinTableHeaderContainer>
      {columns.map((column, index) => (
        <GologinTableHeaderColumn
          key={column.colName}
          column={column}
          columnIndex={index}
          {...columnProps}
        />
      ))}
    </GologinTableHeaderContainer>
  );
};

export default memo(GologinTableHeader);
