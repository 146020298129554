import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { TableCell, TableRow } from '@material-ui/core';

export const CustomTableRow = styled(
  TableRow, { shouldForwardProp: isPropValid })`
  &.MuiTableRow-root > .MuiTableCell-root {
    height: 52px;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 4px;
    max-width: 220px;
    letter-spacing: normal;
    line-height: 0;
    border-bottom: 1px solid var(--E0E0E0-members-row);
    color: var(--222222-members-row);
  }
`;

export const TableHeaderCell = styled(TableCell)`
  &.MuiTableCell-head {
    font-weight: 400;
    font-family: 'Roboto';
    color: var(--767676-members-page);
    background: var(--FFFFFF-members-page);
    border-bottom: 1px solid var(--E0E0E0-members-page);
    padding: 0 0 5px 16px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
  }
`;
