import css from '@emotion/css';
import styled from '@emotion/styled';

import { textFont } from '../../../../ui/style-templates';
import { blackMain } from '../../../../ui/style-templates/colors';

export const Wrapper = styled('div')<{ newStyle: boolean }>`
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${props => props.newStyle ? css `
    ${textFont()};
    font-weight: 500; 
    color: var(--2B2B31-profile-table-profile-name);
    padding: 0;
    margin-left: 0;
  ` : css `
    padding: 5px;
    border: 1px dashed transparent;
    margin-left: -5px;
    color: var(--000000-profile-table-profile-name);
    font-size: 14px;
    line-height: 14px;
  `}

  :hover {
    border: 1px dashed var(--CDCDCD-profile-table-profile-name);
    border: ${props => props.newStyle ? '0' : '1px dashed var(--CDCDCD-profile-table-profile-name)'};
  }
`;

export const TextEllipsis = styled('span')`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
