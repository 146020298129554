import React from 'react';
import { Trans } from 'react-i18next';

import { GroupedProxyManagerProxyStatusWrapper, NoGroupsProxyManagerProxyStatusWrapper, ProxyStatusDescription } from './styles';
import { NEW_FEATURES } from '../../../../state/feature-toggle/new-features';
import { IconCheckCircle } from '../../../../ui/gologin-header/icons/icon-check-circle';

const ProxyConnectedStatus: React.FC = () => {
  const renderIconCheckCircle = (): JSX.Element => (
    <IconCheckCircle
      padding={0}
      iconColor='var(--00A987-proxy-manager)'
    />
  );

  if (!NEW_FEATURES.proxyGroups) {
    return (
      <NoGroupsProxyManagerProxyStatusWrapper>
        {renderIconCheckCircle()}
      </NoGroupsProxyManagerProxyStatusWrapper>
    );
  }

  return (
    <GroupedProxyManagerProxyStatusWrapper>
      {renderIconCheckCircle()}
      <ProxyStatusDescription>
        <Trans i18nKey='proxies.connected' />
      </ProxyStatusDescription>
    </GroupedProxyManagerProxyStatusWrapper>
  );
};

export default ProxyConnectedStatus;
