import { RefObject, useEffect, useState } from 'react';

const useElementWidth = (elementRef: RefObject<HTMLElement>): number => {
  const [width, setWidth] = useState<number>(0);

  useEffect(() => {
    const handleResize = (entries: any): void => {
      for (const entry of entries) {
        if (entry.contentRect) {
          setWidth(entry.contentRect.width);
        }
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    if (elementRef.current) {
      resizeObserver.observe(elementRef.current);
    }

    return (): void => {
      resizeObserver.disconnect();
    };
  }, []);

  return width;
};

export default useElementWidth;
