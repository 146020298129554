import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconColumns: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props} iconType='stroke'>
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='2.5' y='3.5' width='4' height='9' rx='0.5' />
      <rect x='9.5' y='3.5' width='4' height='9' rx='0.5' />
    </svg>
  </IconWrapper>
);
