import { getLinesMetrics } from './get-lines-metrics';
import { ILineWidthModifyParams, ILinesWithMetrics } from '../interfaces';

const SYMBOLS_NUMBER_FOR_SQUARE_VIEW = 4;

const modifyLineWidth = ({
  context,
  lines,
  currentLineIndex,
  width,
  maxWidth,
}: ILineWidthModifyParams): void => {
  const lineOriginal = lines[currentLineIndex];
  lines[currentLineIndex] = lineOriginal[0];
  ({ width } = context.measureText(lines[currentLineIndex]));
  let charIndex = 1;
  while (width <= maxWidth) {
    const lineToUpdate = lines[currentLineIndex] + lineOriginal[charIndex];
    ({ width } = context.measureText(lineToUpdate));
    if (width > maxWidth) {
      return;
    }

    lines[currentLineIndex] = lineToUpdate;
    charIndex++;
  }
};

const getLinesForSquareDesign = (text: string): [string, string] => {
  const [char1, char2, char3, char4] = text.split('');

  return [char1 + char2, char3 + char4];
};

export const getLines = (context: CanvasRenderingContext2D, text: string, maxWidth: number): ILinesWithMetrics => {
  if (text.length === SYMBOLS_NUMBER_FOR_SQUARE_VIEW) {
    const lines = getLinesForSquareDesign(text);
    const linesMetrics = getLinesMetrics(context, lines);

    return { lines, linesMetrics };
  }

  let { width } = context.measureText(text);
  if (width <= maxWidth) {
    const lines: [string, string] = [text, ''];
    const linesMetrics = getLinesMetrics(context, lines);

    return { lines, linesMetrics };
  }

  const lines: [string, string] = [text, ''];
  const paramsUnchanging = { context, maxWidth };
  let currentLineIndex = 0;
  if (width > maxWidth) {
    modifyLineWidth({ ...paramsUnchanging, lines, currentLineIndex, width });
  }

  lines[1] = text.slice(lines[currentLineIndex].length);
  currentLineIndex = 1;
  ({ width } = context.measureText(lines[currentLineIndex]));
  if (width > maxWidth) {
    modifyLineWidth({ ...paramsUnchanging, lines, currentLineIndex, width });
  }

  const linesMetrics = getLinesMetrics(context, lines);

  return { lines, linesMetrics };
};
