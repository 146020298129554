import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CancelButton,
  ConfirmBlock,
  ConfirmButton,
  ConfirmContentText,
  ConfirmTitleText,
} from './styles';
import { IconQuestionCircle } from '../../../../ui/gologin-header/icons/icon-question-circle';
import GologinModal from '../../../../ui/gologin-modal';

type TransferConfirm = {
  profilesCount: number;
  sendTransfer: () => void;
  isVisible: boolean;
  onClose: () => void;
}

export const TransferConfirm: FC<TransferConfirm> = (props) => {
  const { profilesCount, sendTransfer, onClose, isVisible } = props;

  const { t: translation } = useTranslation();

  return (
    <GologinModal
      isVisible={isVisible}
      onClose={(): null => null}
      width='416px'
      bodyStyle={{ padding: '32px 32px 24px' }}
    >
      <div style={{ display: 'flex' }}>
        <IconQuestionCircle
          margin='0 16px 0 0'
          size={22} padding={0}
          iconType='fill'
          iconColor='var(--FAAD14-antd-icon-warning)'
        />
        <ConfirmTitleText>
          {translation('transferModal.title.text', { count: profilesCount })}
        </ConfirmTitleText>
      </div>
      <ConfirmBlock>
        <ConfirmContentText>
          {translation('transferModal.suttitle.firstText', { count: profilesCount })}
        </ConfirmContentText>
        <ConfirmContentText style={{ marginTop: 16 }}>
          {translation('transferModal.subtitle.secondText')}
        </ConfirmContentText>
      </ConfirmBlock>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <ConfirmButton onClick={sendTransfer}>
          {translation('transferModal.button.transfer')}
        </ConfirmButton>
        <CancelButton style={{ marginLeft: 8 }} onClick={onClose}>
          {translation('modals.automationParameters.cancel')}
        </CancelButton>
      </div>
    </GologinModal>
  );
};
