import { TFunction } from 'i18next';
import React from 'react';

import { ISpintaxList } from './spintax-list';
import {
  ContainerExample,
  ContainerSpintax,
  ExampleContainer,
  PopupDescription,
  PopupTitle,
  SpintaxDescription,
  SpintaxTitle,
} from '../../features/profile-settings/content/settings/profile-name/styles';
import { parseNameFormatting } from '../../utils/parse-name-formatting';
import { INewNameProfile } from '../../utils/parse-name-formatting/interfaces';
import { Divider } from '../modern-select/styles';

export interface IRenderOptions {
  profiles?: INewNameProfile[];
  nameFormat: string;
  translation: TFunction;
  spintaxList: ISpintaxList[];
  pasteSpintax: (newText: string) => void;
}

export const renderOptions = (props: IRenderOptions): JSX.Element => {
  const { profiles = [{}], translation, nameFormat, spintaxList, pasteSpintax } = props;

  const formattingParams = {
    profiles,
    nameFormat,
  };

  const [{ name: exampleName }] = parseNameFormatting(formattingParams);

  return (
    <div style={{ width: 335, padding: '14px 0 8px' }}>
      <ContainerExample>
        <div style={{ color: 'var(--98989F)' }}>
          Ex:&nbsp;
        </div>
        <ExampleContainer>
          {exampleName}
        </ExampleContainer>
      </ContainerExample>
      <Divider style={{ margin: 0 }} />
      <PopupTitle>
        {translation('modals.renameProfiles.title')}
      </PopupTitle>
      <PopupDescription>
        {translation('modals.renameProfiles.description')}
      </PopupDescription>
      {spintaxList.map(item => (
        <ContainerSpintax key={item.title}>
          <SpintaxTitle onClick={(): void => pasteSpintax(item.title)}>
            {item.title}
          </SpintaxTitle>
          <SpintaxDescription>
            {item.description}
          </SpintaxDescription>
        </ContainerSpintax>
      ))}
    </div>
  );
};
