import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const NavigationLink = styled(Link)`;
  color: var(--FFFFFF-auth-pages-navigation-link);
  font: 300 18px/21.6px 'Graphik LC', Roboto;
  transition: opacity linear .2s;
  
  :hover {
    color: var(--FFFFFF-auth-pages-navigation-link);
    opacity: .5;
  }
`;

export const NavigationSpan = styled('span')`;
  color: var(--FFFFFF-auth-pages-navigation-link);
  font: 300 18px/21.6px 'Graphik LC', Roboto;
  transition: opacity linear .2s;
  cursor: pointer;
  
  :hover {
    color: var(--FFFFFF-auth-pages-navigation-link);
    opacity: .5;
  }
`;
