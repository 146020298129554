import { Folder as FolderIcon } from '@material-ui/icons';
import React from 'react';

import { IFolder } from '../../../../../../interfaces';
import { IFolderChange } from '../../interfaces/folder-change.interface';
import { AddIcon, DoneIcon, FolderName, FolderNameContainer, FolderRow } from './styles';

interface IFolderItem extends IFolderChange, IFolder {
  foldersSelected: string[];
}

export const Folder: React.FC<IFolderItem> = (props) => {
  const { name, foldersSelected, addProfilesToFolder, removeProfilesFromFolder } = props;

  const handleClick: React.MouseEventHandler = () => foldersSelected.includes(name) ?
    removeProfilesFromFolder(name) : addProfilesToFolder(name);

  return (
    <FolderRow
      onClick={handleClick}
      selected={foldersSelected.includes(name)}
    >
      <FolderNameContainer>
        <FolderIcon />
        <FolderName>
          {name}
        </FolderName>
      </FolderNameContainer>
      {foldersSelected.includes(name) ? <DoneIcon /> : <AddIcon />}
    </FolderRow>
  );
};
