import { getLineHeightVectorized } from './get-line-height-vectorized';
import { getLinesGapVectorized } from './get-lines-gap-vectorized';
import { ILineFirstYValueParams, ITextYValues } from '../../../interfaces';
import { DOUBLING_VALUE } from '../../get-values';

export const centerTextVerticallyVectorized = (params: ILineFirstYValueParams, isWin10BigIcon: boolean): ITextYValues => {
  const { lineFirstMetrics, lineSecondMetrics, ...paramsBase } = params;

  const lineFirstHeight = getLineHeightVectorized(lineFirstMetrics);
  const lineSecondHeight = getLineHeightVectorized(lineSecondMetrics);
  const linesBoundingBoxAscentDifference = lineSecondMetrics.actualBoundingBoxAscent - lineFirstMetrics.actualBoundingBoxAscent;
  const gap = getLinesGapVectorized(params, isWin10BigIcon);

  const textHeightTotal = lineFirstHeight + lineSecondHeight + gap;
  const yValueFirstLine = (paramsBase.canvasSize - textHeightTotal) / DOUBLING_VALUE + lineFirstMetrics.actualBoundingBoxAscent;
  const yValueSecondLine = yValueFirstLine + lineFirstHeight + gap + linesBoundingBoxAscentDifference;

  return { yValueFirstLine, yValueSecondLine };
};
