import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconCheckboxSmall: React.FC<IIconWrapper> = (props) => (
  <IconWrapper iconType='stroke' {...props}>
    <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.5' y='0.5' width='11' height='11' rx='2.5' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  </IconWrapper>
);
