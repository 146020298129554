import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { getProxyManagerListEntities } from './proxy-groups/proxy-groups.atom';
import { getIsArtificialProxyId } from '../../features/proxy/utils/proxy-id';
import { IProxy } from '../../interfaces';
import { switchIsSelectProxyModeOpened } from '../proxy-select-menu.atom';

export interface ISelectedProxiesAtom {
  selectedProxy: IProxy | null;
  selectedProxies: string[];
  lastSelectedProxy: string;
}

const defaultValue: ISelectedProxiesAtom = {
  lastSelectedProxy: '',
  selectedProxies: [],
  selectedProxy: null,
};

const selectedProxiesAtom = atom<ISelectedProxiesAtom>(defaultValue);

const getSelectedProxiesAtom = (): ISelectedProxiesAtom => getDefaultStore().get(selectedProxiesAtom);
const setSelectedProxiesAtom = (value: ISelectedProxiesAtom): void => getDefaultStore().set(selectedProxiesAtom, value);

export const useSelectedProxies = (): ISelectedProxiesAtom => useAtomValue(selectedProxiesAtom);

export const updateSelectedProxies = (data: Partial<ISelectedProxiesAtom>): void => {
  const selectedProxies = getSelectedProxiesAtom();

  const newValue = {
    ...selectedProxies,
    ...data,
  };

  setSelectedProxiesAtom(newValue);
};

export const resetSelectedProxies = (): void => {
  setSelectedProxiesAtom(defaultValue);
};

export const selectAllProxies = (): void => {
  const proxyManagerListEntities = getProxyManagerListEntities();
  const newProxyIds = proxyManagerListEntities.reduce<string[]>((acc, proxy) => {
    if (proxy && !getIsArtificialProxyId(proxy.id)) {
      acc.push(proxy.id);
    }

    return acc;
  }, []);

  updateSelectedProxies({ selectedProxies: newProxyIds });
  switchIsSelectProxyModeOpened(true);
};

export const toggleIsProxySelected = (proxyId: string): void => {
  const { selectedProxies } = getSelectedProxiesAtom();

  let newSelectedProxies: string[];
  if (selectedProxies.includes(proxyId)) {
    newSelectedProxies = selectedProxies.filter((el): boolean => el !== proxyId);
  } else {
    newSelectedProxies = [
      ...selectedProxies,
      proxyId,
    ];
  }

  updateSelectedProxies({
    selectedProxies: newSelectedProxies,
  });
};
