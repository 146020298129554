import styled from '@emotion/styled';
import { CheckOutlined } from '@material-ui/icons';
import { Menu, Modal } from 'antd';

import { Spinner } from '../../../modalsComponents/components/checkout-modal/styles';

export const ModalHeaderTxt = styled('p')`
  font-family: Roboto, sans-serif;
  font-size: 24px;
  line-height: 32px;
  color: var(--000000-new-pricing);
  text-align: center;
  margin-bottom: 32px;
  transition: height 0.4s ease-in-out;
`;

export const ModalHeaderBoldTxt = styled('span')`
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: var(--000000-new-pricing);
`;

export const ModalLineBoldTxt = styled('span')`
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-size: 15px;
  line-height: normal;
  color: var(--000000);
`;

export const ModalSwitcher = styled('div')`
  font-family: Graphik, sans-serif;
  width: max-content;
  margin: 0 auto 26px;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: var(--F3F3F3-new-pricing);
`;

export const ModalSwitcherItem = styled('div')`
  font-family: 'Roboto', sans-serif;
  padding: 8px 16px;
  letter-spacing: 0.44px;
  font-size: 16px;
  line-height: 24px;
  color: var(--000000-new-pricing);
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
`;

export const ModalSwitcherItemSelected = styled(ModalSwitcherItem)`
  border-radius: 60px;
  background: var(--00A987-new-pricing);
  color: var(--FFFFFF-new-pricing);
`;

export const ModalParamsLine = styled('div')`
  height: 58px;
  padding: 0 134px 0 135px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ModalParamsLeftTxt = styled.div<{ greyTxt?: boolean }>`
  font-family: Roboto, sans-serif;
  font-size: 15px;
  line-height: 18px;
  white-space: nowrap;
  min-width: 200px;
  color: ${props => props.greyTxt ? 'var(--8B8B8B-new-pricing)' : 'var(--000000-new-pricing)'};
`;

export const ModalParamsRightTxt = styled.div<{ greyTxt?: boolean }>`
  font-family: Roboto, sans-serif;
  font-size: 15px;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => props.greyTxt ? 'var(--7E7E7E-new-pricing)' : 'var(--000000-new-pricing)'};
`;

export const ModalCheckMarkContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding-left: 135px;
  margin-top: 31px;
`;

export const StripeCardErrorContainer = styled(ModalCheckMarkContainer)`
  margin-top: 18px;
`;

export const ModalCheckMarkTxt = styled('div')`
  width: 380px;
  font-family: Roboto;
  font-size: 13px;
  line-height: 15px;
  color: var(--000000-new-pricing);
`;

export const StripeCardErrorTxt = styled(ModalCheckMarkTxt)`
  color: var(--FA0B0B-new-pricing);
`;

export const StripeCentLinkTxt = styled('text')`
  font-weight: bold;
  cursor: pointer;
`;

export const ModalUpgradeButtonContainer = styled.div<{ paddingBottom: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 26px;
  padding-bottom: ${props => props.paddingBottom ? '32px' : '60px'};
`;

export const ModalUpgradeButton = styled('button')`
  font-family: Graphik, sans-serif;
  border: 1px solid var(--00A987-new-pricing);
  border-radius: 60px;
  outline: none;
  cursor: pointer;
  font-size: 18px;
  line-height: 22px;
  background: var(--00A987-new-pricing);
  color: var(--FFFFFF-new-pricing);
  width: 174px;
  height: 56px;
`;

export const ModalUpgradeCardPaymentButton = styled(ModalUpgradeButton)`
  font-family: Roboto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalUpgradeMobilePaymentButton = styled(ModalUpgradeButton)`
  padding: 0;
  width: 100%;
  height: 40px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: var(--FFFFFF-new-pricing);
  background: var(--000000-new-pricing);
  border: unset;
`;

export const ModalUpgradeCryptoButton = styled('button')`
  font-family: Graphik, sans-serif;
  border: 1px solid var(--00A987-new-pricing);
  border-radius: 60px;
  outline: none;
  cursor: pointer;
  font-size: 18px;
  line-height: 22px;
  background: var(--00A987-new-pricing);
  color: var(--FFFFFF-new-pricing);
  width: 278px;
  height: 56px;
`;

export const CustomPaymentModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
    background: var(--FFFFFF-new-pricing);
  }

  .ant-modal-header {
    color: var(--000000A6-new-pricing);
    background: var(--FFFFFF-new-pricing);
    border-bottom: 1px solid var(--E8E8E8-new-pricing);
  }
`;

export const CustomAnnuallyMenu = styled(Menu)`
  border-radius: 8px;
  color: var(--000000A6-new-pricing);
  background-color: var(--FFFFFF-new-pricing);
`;

export const CustomItemMenu = styled(Menu.Item)`
  font-family: Roboto, sans-serif;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  height: 48px;
  color: var(--000000A6-new-pricing);
  background-color: var(--FFFFFF-new-pricing);
  
  :hover {
    color: var(--263238-new-pricing);
    background-color: var(--DFF7E9-new-pricing);
  }

  .ant-dropdown-menu .ant-dropdown-menu-item-disabled, .ant-dropdown-menu .ant-dropdown-menu-submenu-title-disabled {
    color: var(--00000040-antd);
  }
`;

export const GreenCheckoutIcon = styled(CheckOutlined)`
  margin-left: auto;
  margin-right: 20px;
  color: var(--2ECC71-new-pricing);
`;

export const HeaderTitleContainer = styled('div')`
  line-height: 32px;
  padding-top: 28px;
  margin: 0 auto;
`;

export const AnnuallyTxt = styled('span')`
  font-family: Roboto, sans-serif;
  cursor: pointer;
  color: var(--000000-new-pricing);
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  border-bottom: 1px dashed var(--000000-new-pricing);
`;

export const PaymentModalCancelContainer = styled('div')`
  position: absolute;
  right: 16px;
  top: 14px;
  color: var(--868686-new-pricing);
  font-size: 30px;
  opacity: 0.5;
`;

export const InfoHeaderContainer = styled('div')`
  position: absolute;
  top: 29px;
  right: 42px;
  color: var(--868686-new-pricing);
  opacity: 0.5;
`;

export const PricingPageCancelContainer = styled('div')`
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
`;

export const LoadingContainer = styled('div')`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  color: var(--222222-new-pricing);
`;
