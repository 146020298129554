import { atom, getDefaultStore, useAtomValue } from 'jotai';

import {
  EMPTY_PROXY_LIST_ROW,
  PROXY_GROUP_ADD_BUTTON_ID_POSTFIX,
  PROXY_GROUP_HEADER_ID_POSTFIX,
  PROXY_MANAGER_EMPTY_LIST_PLACEHOLDER_ID,
  PROXY_MANAGER_LIST_SECTION_ID_POSTFIX,
} from './constants';
import { PROXY_GROUP_SORTER_FIELDS, proxyGroupsSorterPersistentAtom } from './proxy-groups-sorting.atom';
import { proxySelectionDatesPersistentAtom } from './proxy-selection-dates.atom';
import { visibleProxyGroupModesPersistentAtom } from './proxy-visible-group-modes.atom';
import { visibleProxyGroupIdsAtom } from './proxy-visible-groups.atom';
import {
  getIsProxyGroup,
  IGroupedProxy,
  IProxyGroup,
  IProxyGroupsObject,
  IProxyManagerListEntity,
  IProxyManagerListEntityBase,
  IUngroupedProxy,
  PROXY_GROUP_MODES,
  PROXY_SECTION_TITLES,
  ProxyGroupMode,
  ProxySectionTitle,
} from './types';
import { GEOPROXY_MODE } from '../../../../common/constants/constants';
import { determineIsGologinProxy, determineIsProxyTruthy, GeoProxyType } from '../../../../common/constants/types';
import { calculateRemainingAllTypesTrafficLimitsObject } from '../../../../common/proxy/traffic/utils';
import countries from '../../../features/common/countries';
import { SORT_ORDERS } from '../../../features/common/sorter-order';
import {
  PROXY_GROUP_ID_TAG,
  PROXY_GROUP_NOT_FOUND_WARNING,
  PROXY_GROUP_NOT_FOUND_WARNING_MESSAGE,
} from '../../../features/proxy/constants';
import { getProxyGroupId } from '../../../features/proxy/utils/proxy-id';
import { IProxy } from '../../../interfaces';
import { filterProxies, getSearchIndexOf } from '../../../utils/proxy-string';
import { sendReactErrorToSentry } from '../../../utils/sentry.helper';
import { NEW_FEATURES } from '../../feature-toggle/new-features';
import { currentProfileProxyAtom } from '../current-profile-proxy.atom';
import { geoProxyCountriesAtom } from '../geoproxy-countries.atom';
import { proxyListAtom } from '../proxy-list.atom';
import { proxySearchAtom, searchedProxiesAtom } from '../proxy-search.atom';
import { isSelectProxyModeOpenedAtom } from '../proxy-select-menu.atom';
import { trafficDataAtom } from '../traffic-data.atom';

type GeoProxyEmptyGroupParams = Pick<IProxyManagerListEntityBase, 'groupId' | 'country' | 'selectionDate'> & {
  types: GeoProxyType[];
  isAvailable: boolean;
  createdAt: number;
}

const generateGeoProxyEmptyGroup = ({
  groupId,
  country,
  selectionDate,
  types,
  isAvailable,
  createdAt,
}: GeoProxyEmptyGroupParams): IProxyGroup => {
  const proxyManagerEntityBase: Omit<IProxyManagerListEntityBase, 'id'> = {
    groupId,
    country,
    mode: GEOPROXY_MODE,
    selectionDate,
  };

  return {
    groupId,
    header: {
      ...proxyManagerEntityBase,
      id: groupId + PROXY_GROUP_HEADER_ID_POSTFIX,
      types,
      isAvailable,
      createdAt,
    },
    proxies: [],
    addButton: {
      ...proxyManagerEntityBase,
      id: groupId + PROXY_GROUP_ADD_BUTTON_ID_POSTFIX,
      types,
      isAvailable,
    },
    isAvailable,
  };
};

const proxyGroupsAtom = atom<IProxyGroupsObject>((get) => {
  const proxyList = get(proxyListAtom);
  const searchValue = get(proxySearchAtom);
  const proxyGroupsSorter = get(proxyGroupsSorterPersistentAtom);
  const selectionDates = get(proxySelectionDatesPersistentAtom);
  const geoProxyCountries = get(geoProxyCountriesAtom);
  const trafficData = get(trafficDataAtom);

  const remainingTraffic = calculateRemainingAllTypesTrafficLimitsObject(trafficData);

  const { availableProxies, unavailableProxies } = geoProxyCountries
    .reduce<Record<ProxySectionTitle, Record<string, IProxyGroup>>>((acc, countryObject) => {
      const geoProxyCountry = countryObject.countryCode;
      const groupId = getProxyGroupId(GEOPROXY_MODE, geoProxyCountry);
      const selectionDate = selectionDates[groupId] || 0;
      const isAvailable = countryObject.types.some(type => remainingTraffic[type]);

      let headerSearchString = [geoProxyCountry, countryObject.countryName, countryObject.types].flat().join(' ');
      const foundCountry = countries.find(({ code }) => code.toLowerCase() === geoProxyCountry.toLowerCase());
      if (foundCountry) {
        headerSearchString += ['', foundCountry.name, foundCountry.nameRus].join(' ');
      }

      if (searchValue) {
        const headerSearchIndex = getSearchIndexOf(headerSearchString, searchValue);
        if (headerSearchIndex === -1) {
          return acc;
        }
      }

      const emptyGroup = generateGeoProxyEmptyGroup({
        groupId,
        country: geoProxyCountry,
        selectionDate,
        types: countryObject.types,
        isAvailable,
        createdAt: 0,
      });

      const sectionTitle = isAvailable ? PROXY_SECTION_TITLES.availableProxies : PROXY_SECTION_TITLES.unavailableProxies;
      acc[sectionTitle][groupId] = emptyGroup;

      return acc;
    }, {
      [PROXY_SECTION_TITLES.availableProxies]: {},
      [PROXY_SECTION_TITLES.unavailableProxies]: {},
    });

  const searchedProxies = filterProxies(proxyList, searchValue);
  const proxyGroups = searchedProxies.reduce<IProxyGroupsObject>((acc, proxy) => {
    if (proxy.isInvisible) {
      return acc;
    }

    const country = proxy.country || proxy.autoProxyRegion || proxy.torProxyRegion || '';
    const groupId = getProxyGroupId(proxy.mode, country);
    const selectionDate = selectionDates[proxy.id] || selectionDates[groupId] || 0;
    const creationDate = +(new Date(proxy.createdAt));
    const proxyBase: Omit<IGroupedProxy, 'groupId'> = { ...proxy, selectionDate, country };

    if (proxy.mode === GEOPROXY_MODE) {
      const groupedProxy: IGroupedProxy = { ...proxyBase, groupId };
      const proxyGroup = acc.geolocationProxies[groupId];
      if (!proxyGroup) {
        sendReactErrorToSentry({
          transactionName: PROXY_GROUP_NOT_FOUND_WARNING,
          message: PROXY_GROUP_NOT_FOUND_WARNING_MESSAGE,
          tags: [
            [PROXY_GROUP_ID_TAG, groupId],
            ['groups-length', Object.keys(acc.geolocationProxies).length],
            ['is-searching', Boolean(searchValue)],
          ],
          level: 'warning',
        });

        acc.geolocationProxies[groupId] = generateGeoProxyEmptyGroup({
          groupId,
          country,
          selectionDate,
          types: [],
          isAvailable: true,
          createdAt: creationDate,
        });
      }

      const sorterModifier = proxyGroupsSorter.order === SORT_ORDERS.descend ? 1 : -1;

      acc.geolocationProxies[groupId].header.createdAt = Math.max(acc.geolocationProxies[groupId].header.createdAt, creationDate);
      acc.geolocationProxies[groupId].proxies = [...acc.geolocationProxies[groupId].proxies, groupedProxy]
        .sort((current, next) => {
          if ([current.positionInGroup, next.positionInGroup].includes('end')) {
            return 1;
          }

          const [currentSortValue, nextSortValue] = [current[proxyGroupsSorter.field], next[proxyGroupsSorter.field]].map((sortValue) => {
            if (proxyGroupsSorter.field !== PROXY_GROUP_SORTER_FIELDS.createdAt) {
              return +sortValue;
            }

            return +(new Date(sortValue));
          });

          return (nextSortValue - currentSortValue) * sorterModifier;
        });

      return acc;
    }

    const ungroupedProxy: IUngroupedProxy = { ...proxyBase, groupId: null };
    switch (proxy.mode) {
      case 'gologin':
        acc.gologinProxies.push(ungroupedProxy);
        break;
      case 'tor':
        acc.torProxies.push(ungroupedProxy);
        break;
      default:
        acc.userProxies.push(ungroupedProxy);
        break;
    }

    return acc;
    // do not do `...EMPTY_PROXY_GROUPS` in the accumulator, as it causes cloning proxies in the list
  }, {
    geolocationProxies: availableProxies,
    gologinProxies: [],
    torProxies: [],
    userProxies: [],
    [PROXY_SECTION_TITLES.unavailableProxies]: unavailableProxies,
  });

  return proxyGroups;
});

const moveProxyToBeginning = <ProxyType extends IUngroupedProxy|IGroupedProxy|IProxy>(proxies: ProxyType[], proxyToMove: ProxyType): ProxyType[] => {
  const filteredProxies = proxies.filter(({ id }) => proxyToMove.id !== id);

  return [proxyToMove, ...filteredProxies];
};

const removeCurrentProxyFromGroups = (proxyGroups: IProxyGroupsObject, currentProxy: IProxy): IProxyGroupsObject => {
  const updatedProxyGroups = { ...proxyGroups };
  const proxyGroupMode: ProxyGroupMode = determineIsGologinProxy(currentProxy) ? `${currentProxy.mode}Proxies` : 'userProxies';
  if (proxyGroupMode !== 'geolocationProxies') {
    updatedProxyGroups[proxyGroupMode] = updatedProxyGroups[proxyGroupMode].filter(({ id }) => currentProxy.id !== id);

    return updatedProxyGroups;
  }

  return updatedProxyGroups;
};

// TODO: remove `IProxy` from `IProxy|IProxyManagerListEntity` here and below,
// when 100% users are migrated to groups
const proxyManagerListEntitiesAtom = atom<(IProxy|IProxyManagerListEntity|null)[]>((get) => {
  const isSelectProxyModeOpened = get(isSelectProxyModeOpenedAtom);
  const currentProxy = get(currentProfileProxyAtom);

  const isCurrentProxyPresentAndVisible = determineIsProxyTruthy(currentProxy);
  if (!NEW_FEATURES.proxyGroups) {
    let searchedProxies = get(searchedProxiesAtom);
    if (isCurrentProxyPresentAndVisible) {
      searchedProxies = moveProxyToBeginning(searchedProxies, currentProxy);
    }

    // to keep the last proxy visible right above the proxy-manager multi-select panel
    return isSelectProxyModeOpened ? [...searchedProxies, EMPTY_PROXY_LIST_ROW] : searchedProxies;
  }

  const proxyGroupsSorter = get(proxyGroupsSorterPersistentAtom);
  const visibleProxyGroupModes = get(visibleProxyGroupModesPersistentAtom);
  const visibleProxyGroupIds = get(visibleProxyGroupIdsAtom);
  let proxyGroups = get(proxyGroupsAtom);
  if (isCurrentProxyPresentAndVisible) {
    proxyGroups = removeCurrentProxyFromGroups(proxyGroups, currentProxy);
  }

  const { geolocationProxies, torProxies, gologinProxies, userProxies, unavailableProxies } = proxyGroups;
  const geoProxyEntities = Object.values(geolocationProxies);

  const allAvailableProxies = Object
    .entries({ userProxies, geolocationProxies: geoProxyEntities, torProxies, gologinProxies })
    .reduce<(IUngroupedProxy | IProxyGroup)[]>((acc, [proxyGroupMode, proxyGroupModeProxies]) => {
      if (visibleProxyGroupModes.includes(proxyGroupMode)) {
        acc.push(...proxyGroupModeProxies);
      }

      return acc;
    }, []).flat();

  const sortedAvailableProxies = allAvailableProxies.sort((current, next) => {
    let [currentSortValue, nextSortValue] = [0, 0];
    if (getIsProxyGroup(current)) {
      currentSortValue = current.header[proxyGroupsSorter.field] || 0;
    } else {
      currentSortValue = current[proxyGroupsSorter.field] || 0;
    }

    if (getIsProxyGroup(next)) {
      nextSortValue = next.header[proxyGroupsSorter.field] || 0;
    } else {
      nextSortValue = next[proxyGroupsSorter.field] || 0;
    }

    if (proxyGroupsSorter.field === PROXY_GROUP_SORTER_FIELDS.createdAt) {
      [currentSortValue, nextSortValue] = [currentSortValue, nextSortValue].map(dateString => +new Date(dateString));
    }

    return proxyGroupsSorter.order === SORT_ORDERS.descend ? nextSortValue - currentSortValue : currentSortValue - nextSortValue;
  });

  const flatSortedAvailableProxiesEntities = sortedAvailableProxies.reduce<IProxyManagerListEntity[]>((acc, proxyEntity) => {
    if (!getIsProxyGroup(proxyEntity)) {
      acc.push(proxyEntity);

      return acc;
    }

    const isGroupOpen = visibleProxyGroupIds.includes(proxyEntity.header.groupId);
    const entitiesToAdd = isGroupOpen ? [proxyEntity.header, ...proxyEntity.proxies, proxyEntity.addButton] : [proxyEntity.header];
    acc.push(...entitiesToAdd);

    return acc;
  }, []);

  const unavailableProxyGroupHeaders = Object.values(unavailableProxies).map(unavailableProxyGroup => unavailableProxyGroup.header);
  const availableProxyEntities: IProxyManagerListEntity[] = [
    {
      id: `available${PROXY_MANAGER_LIST_SECTION_ID_POSTFIX}`,
      title: PROXY_SECTION_TITLES.availableProxies,
      isContextMenuEnabled: true,
    },
    ...flatSortedAvailableProxiesEntities,
  ];

  if (isCurrentProxyPresentAndVisible) {
    const country = currentProxy.country || currentProxy.autoProxyRegion || currentProxy.torProxyRegion || '';
    availableProxyEntities.unshift({ ...currentProxy, country, groupId: null });
  }

  const mergedSectionEntities: IProxyManagerListEntity[] = [...availableProxyEntities];
  let unavailableProxyEntities: IProxyManagerListEntity[] = [];
  if (visibleProxyGroupModes.includes(PROXY_GROUP_MODES.geolocationProxies)) {
    unavailableProxyEntities = unavailableProxyGroupHeaders;
    if (unavailableProxyEntities.length) {
      mergedSectionEntities.push(
        {
          id: `unavailable${PROXY_MANAGER_LIST_SECTION_ID_POSTFIX}`,
          title: PROXY_SECTION_TITLES.unavailableProxies,
          isContextMenuEnabled: false,
        },
        ...unavailableProxyEntities,
      );
    }
  }

  const isListEmpty = !(flatSortedAvailableProxiesEntities.length || unavailableProxyEntities.length);
  if (isListEmpty) {
    mergedSectionEntities.push({ id: PROXY_MANAGER_EMPTY_LIST_PLACEHOLDER_ID });
  }

  // to keep the last proxy visible right above the proxy-manager multi-select panel
  return isSelectProxyModeOpened ? [...mergedSectionEntities, EMPTY_PROXY_LIST_ROW] : mergedSectionEntities;
});

export const getProxyGroups = (): IProxyGroupsObject => getDefaultStore().get(proxyGroupsAtom);
export const useProxyGroups = (): IProxyGroupsObject => useAtomValue(proxyGroupsAtom);

export const useProxyManagerListEntities = (): (IProxy|IProxyManagerListEntity|null)[] => useAtomValue(proxyManagerListEntitiesAtom);
export const getProxyManagerListEntities = (): (IProxy|IProxyManagerListEntity|null)[] => getDefaultStore().get(proxyManagerListEntitiesAtom);

export const findUnusedGeoProxy = (groupId: string): IGroupedProxy|null => {
  const { geolocationProxies } = getProxyGroups();
  const [unusedProxy] = geolocationProxies[groupId].proxies.filter(proxyToCheck => proxyToCheck.profilesCount <= 0);
  if (!unusedProxy) {
    return null;
  }

  return unusedProxy;
};
