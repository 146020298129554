import styled from '@emotion/styled';
import { Col, Icon, Input, Radio, Row, Slider, Switch, Tooltip } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { updateProfileGeolocation } from '../api';
import Loader, { TLoaderStatus } from './Loader';

const ArrowDrawer = styled('div')`
  border: solid var(--01AD7F);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(135deg);
  opacity: 1;
  cursor: pointer;
`;

const RadioButton = styled(Radio.Button)`
  height: 30px;
  text-align: center;
  font-size: 12px;
  line-height: 29px;
`;

const InputDiv = styled('div')`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

const IpInput = styled(Input)`
  width: 30%;
`;

const SliderDiv = styled('div')`
  display: inline-block;
  width: 30%;
`;

const SubTitle = styled('span')`
  display: inline-block;
  font-size: 12px;
  line-height: 14px;
  padding-bottom: 10px;
`;

const ElementContainer = styled('div')`
  font-family: 'Roboto';
  margin-top: 39px;
  margin-left: 24px;
`;

const TitleContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 22px;
  margin-left: 36px;
`;

const TittleSetting = styled('div')`
  font-weight: 500;
  font-family: 'Roboto';
  font-size: 20px;
  color: var(--000000);
  margin-left: 12px;
  margin-top: -2px;
`;

const Subtitle = styled('span')`
  font-size: 12px;
  color: var(--000000);
  margin-left: 10px;
`;

const GEOLOCATION_SETTINGS = {
  mode: '',
  latitude: '',
  longitude: '',
  accuracy: '',
  fillBasedOnIp: false,
};

let hasBeenUpdated = false;
let timeout: any;
let loaderStatusTimeout: any;

declare interface IProfileDrawerGeolocation {
  profileInfo?: any;
  changeVisibility: () => void;
  updateProfileGeolocation: (id: string, geolocation: typeof GEOLOCATION_SETTINGS) => any;
}

export const DrawerGeolocation: FC<IProfileDrawerGeolocation> = (props) => {
  const [loaderStatus, setLoaderStatus] = useState<TLoaderStatus>('default');

  const { t: translation } = useTranslation();

  useEffect(() => {
    GEOLOCATION_SETTINGS.mode = props.profileInfo?.geolocation?.mode || '';
    GEOLOCATION_SETTINGS.latitude = props.profileInfo?.geolocation?.latitude || '';
    GEOLOCATION_SETTINGS.longitude = props.profileInfo?.geolocation?.longitude || '';
    GEOLOCATION_SETTINGS.accuracy = props.profileInfo?.geolocation?.accuracy || '';
    GEOLOCATION_SETTINGS.fillBasedOnIp = !!props.profileInfo?.geolocation?.fillBasedOnIp;
  }, []);

  const save = async () => {
    clearTimeout(loaderStatusTimeout);
    setLoaderStatus('processing');

    loaderStatusTimeout = setTimeout(() => {
      setLoaderStatus('done');
      loaderStatusTimeout = setTimeout(() => setLoaderStatus('default'), 0.5 * 1000);
    }, 0.5 * 1000);

    props.updateProfileGeolocation(props?.profileInfo?.id, {
      mode: GEOLOCATION_SETTINGS.mode || 'allow',
      latitude: GEOLOCATION_SETTINGS.latitude || '',
      longitude: GEOLOCATION_SETTINGS.longitude || '',
      accuracy: GEOLOCATION_SETTINGS.accuracy || '',
      fillBasedOnIp: GEOLOCATION_SETTINGS.fillBasedOnIp,
    });

    await updateProfileGeolocation(props?.profileInfo?.id, {
      mode: GEOLOCATION_SETTINGS.mode || 'allow',
      latitude: GEOLOCATION_SETTINGS.latitude || undefined,
      longitude: GEOLOCATION_SETTINGS.longitude || undefined,
      accuracy: GEOLOCATION_SETTINGS.accuracy || undefined,
      fillBasedOnIp: GEOLOCATION_SETTINGS.fillBasedOnIp,
    });
  }

  const updateTimeout = () => {
    if (timeout !== undefined) {
      clearTimeout(timeout)
    }

    timeout = setTimeout(() => save(), 500);
  }

  const onModeChange = (mode: 'allow'|'block'|'prompt') => {
    if (GEOLOCATION_SETTINGS.mode === 'block') {
      GEOLOCATION_SETTINGS.mode = 'prompt';
    } else {
      GEOLOCATION_SETTINGS.mode = mode;
    }
    hasBeenUpdated = true;
    save();
  }

  const onLatitudeChange = (e: any) => {
    GEOLOCATION_SETTINGS.latitude = e.target.value;
    hasBeenUpdated = true;
    updateTimeout();
  }

  const onLongitudeChange = (e: any) => {
    GEOLOCATION_SETTINGS.longitude = e.target.value;
    hasBeenUpdated = true;
    updateTimeout();
  }

  const onAccuracyChange = (accuracy: number) => {
    GEOLOCATION_SETTINGS.accuracy = '' + accuracy;
    hasBeenUpdated = true;
    updateTimeout();
  }

  const onFillBasedOnIpChange = (fillBasedOnIp: boolean) => {
    GEOLOCATION_SETTINGS.fillBasedOnIp = fillBasedOnIp;
    hasBeenUpdated = true;
    save();
  }

const getWebgeolocation = () => {

    return (
      <>
        <Tooltip
          title={(
            <span style={{ fontSize: 12, color: 'var(--000000-antd-tooltip-opacity)' }}>
              {
                props?.profileInfo?.geolocation?.mode === 'block' ? translation('geolocation.block.help') : translation('geolocation.allow.help')
              }
            </span>
          )}
          overlayStyle={{backgroundColor: 'var(--FFFFFF)', color: 'var(--000000)'}}
          placement='rightBottom'
          arrowPointAtCenter={true}
        >
          <Icon
            style={{
              marginTop: 7,
              marginLeft: 18,
              marginBottom: 7,
              opacity: 0.4
            }}
            type='info-circle'
          />
        </Tooltip>
      </>
    );
  };

const geoOptionContainer = () => (
    <ElementContainer style={{ marginLeft: 0, marginTop: 20 }}>
      <Col>
        {['allow', 'prompt'].includes(props?.profileInfo?.geolocation?.mode) && (
          <>
            <Row>
              <Switch
                defaultChecked={props?.profileInfo?.geolocation?.mode === 'prompt'}
                onChange={checked => onModeChange(checked ? 'prompt' : 'allow')}
              />
              <Subtitle style={{ fontWeight: 500 }}>{translation('quickSettings.geolocation.help.sitesAsk')}</Subtitle>
            </Row>
          </>
        )}
        {['allow', 'prompt'].includes(props?.profileInfo?.geolocation?.mode) && (
          <>
            <Row style={{ marginTop: 20 }}>
              <Switch
                defaultChecked={!!props?.profileInfo?.geolocation?.fillBasedOnIp}
                onChange={checked => onFillBasedOnIpChange(checked)}
              />
              <Subtitle>{translation('geolocation.fillBasedOnIpText')}</Subtitle>
            </Row>
          </>
        )}
      </Col>
    </ElementContainer>
  );



  const showContent = () => {
    return (
      <>
        <TitleContainer>
          <ArrowDrawer onClick={props.changeVisibility} />

          <TittleSetting>
            {translation('geolocation.title')}
          </TittleSetting>

          <Loader
            state={loaderStatus}
          />
        </TitleContainer>
        <ElementContainer style={{ marginTop: 37 }}>
          <Row>
            <Radio.Group
              defaultValue={['allow', 'prompt'].includes(props?.profileInfo?.geolocation?.mode) ? 'allow' : 'block'}
              onChange={e => onModeChange(e.target.value)}
            >
              <Tooltip
                title={(
                  <span style={{ fontSize: 12, color: 'var(--000000-antd-tooltip-opacity)' }}>
                    {translation('geolocation.allow.help')}
                  </span>
                )}
                overlayStyle={{ backgroundColor: 'var(--FFFFFF)', color: 'var(--000000)' }}
                placement='top'
                arrowPointAtCenter={true}
              >
                <RadioButton value='allow'>{translation('base.allow')}</RadioButton>
              </Tooltip>
              <Tooltip
                title={(
                  <span style={{ fontSize: 12, color: 'var(--000000-antd-tooltip-opacity)' }}>
                    {translation('geolocation.block.help')}
                  </span>
                )}
                overlayStyle={{ backgroundColor: 'var(--FFFFFF)', color: 'var(--000000)' }}
                placement='top'
                arrowPointAtCenter={true}
              >
                <RadioButton value='block'>{translation('base.block')}</RadioButton>
              </Tooltip>
            </Radio.Group>
            {getWebgeolocation()}
          </Row>
          <>{geoOptionContainer()}</>
          {(!props?.profileInfo?.geolocation?.fillBasedOnIp
            && ['prompt', 'allow'].includes(props?.profileInfo?.geolocation?.mode))
          && (
            <>
              <InputDiv>
                <SubTitle>{translation('base.latitude')}</SubTitle>
                <IpInput
                  size='small'
                  onChange={onLatitudeChange}
                  defaultValue={props?.profileInfo?.geolocation?.latitude}
                />
              </InputDiv>
              <InputDiv>
                <SubTitle>{translation('base.longitude')}</SubTitle>
                <IpInput
                  size='small'
                  onChange={onLongitudeChange}
                  defaultValue={props?.profileInfo?.geolocation?.longitude}
                />
              </InputDiv>
              <InputDiv>
                <SubTitle
                  style={{ paddingBottom: 0 }}
                >
                  {translation('base.accuracy')} ({translation('base.meters').toLowerCase()}) {props?.profileInfo?.geolocation?.accuracy || 0}
                </SubTitle>
                <SliderDiv>
                  <Slider
                    step={10}
                    min={10}
                    defaultValue={props?.profileInfo?.geolocation?.accuracy || 0}
                    dots={true}
                    tooltipVisible={false}
                    onChange={(v) => onAccuracyChange(v as number)}
                  />
                </SliderDiv>
              </InputDiv>
            </>
          )}
        </ElementContainer>
      </>
    );
  }
  return (
    <>
      {showContent()}
    </>
  )
}
