import styled from '@emotion/styled';
import { Popover } from '@material-ui/core';
import {Button, Icon} from 'antd';

export const Row = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const UserContainer = styled('div')`
  margin: 10px 0;
`;

export const RowSpaceBetween = styled(Row)`
  justify-content: space-between;
`;

export const UserIconContainer = styled('div')`
  margin-right: 5px;
`;

export const IconSharing = styled (Icon)`
  font-size: 9px;
  margin-top: 2px;
  opacity: 0.6;
  color: var(--000000-folders-page-share-folder-modal);
  margin-left: 3px;
`;

export const IconUser = styled (Icon)`
  font-size: 20px;
  opacity: 0.6;
  color: var(--000000-folders-page-share-folder-modal);
`;

export const MenuPopup = styled(Popover)`
  .MuiPopover-paper {
    background-color: var(--FFFFFF-antd);
    margin: 0;
    padding: 4px 0;
    text-align: left;
    list-style-type: none;
    background-clip: padding-box;
    border-radius: 4px;
    outline: none;
    box-shadow: var(--box-shadow);
  }
`;

export const OptionItem = styled.div`
  clear: both;
  margin: 0;
  padding: 5px 12px;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
  color: var(--000000A6-antd);
  
  :hover {
    background-color: var(--DFF7E9-antd);
  }
`;

export const TextEmail = styled('div')`
  font-size: 12px;
  margin-left: 10px;
`;

export const TextSharing = styled('span')`
  font-family: Roboto;
  font-size: 12px;
  line-height: 14px;
  color: var(--000000-folders-page-share-folder-modal);
  opacity: 0.6;
  white-space: nowrap;
  justify-content: flex-end;
`;

export const CloseIconContainer = styled('div')`
  margin-left: 5px;
  cursor: pointer;
  min-width: 19px;
`;

export const Col = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const ButtonSharing = styled(Button)`
  font-family: Roboto;
  font-size: 11px;
  line-height: 13px;
  color: var(--00B86D-folders-page-share-folder-modal);
  border-color: var(--00B86D-folders-page-share-folder-modal);
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    opacity: 0.7;
  }
`;
