import React from 'react';

import { TitleContainer, IconWrapper, Icon, WorkflowName} from './styles';

type WorkflowInfoProps = {
  name: string;
  icon: string;
}

const WorkflowStatusInfo: React.FC<WorkflowInfoProps> = (props) => {
  const { name, icon } = props;

  return (
    <TitleContainer>
      <IconWrapper>
        <Icon src={icon} alt='' />
      </IconWrapper>
      <WorkflowName>
        {name}
      </WorkflowName>
    </TitleContainer>
  );
};

export default WorkflowStatusInfo;
