import React, { FC, useEffect } from 'react';

import ProfileSettingsContent from './content/profile-settings-content';
import ProfileSettingsHeader from './profile-settings-header';
import { OverlayDrawer, ProfileSettingsDrawer } from './styles';
import { IProfile } from '../../interfaces';
import {
  closeProfilesSettings,
  useIsProfilesSettingsOpen,
  useProfilesSettingsProfile,
  useProfilesSettingsState,
} from '../../state/profiles-settings-atom';
import { IProfileDrawer } from '../drawer/interfaces';
import PageOverlay from '../quickProfiles/components/page-overlay';

const isElectron = !!window.require;
let ipcRenderer: Electron.IpcRenderer;
if (isElectron) {
  ipcRenderer = window.require('electron').ipcRenderer;
}

const ProfileSettings: FC<IProfileDrawer> = props => {
  const { renderDropDownMenu, toggleCookiesListModal } = props;

  // FIXME: consistent nullable type of profileInfo across profile settings
  const profileInfo = useProfilesSettingsProfile() || ({} as IProfile);

  const isProfilesSettingsOpen = useIsProfilesSettingsOpen();
  const { profileIds } = useProfilesSettingsState();
  const isMultiProfilesSettings = profileIds.length > 1;

  const { id: profileId, name: profileName } = profileInfo;
  const isShowDrawer = profileId || isMultiProfilesSettings;

  useEffect(() => {
    if (isShowDrawer) {

      return;
    }

    closeProfilesSettings();
  }, [profileId, isMultiProfilesSettings]);

  return (
    <div onClick={(event): void => event.stopPropagation()}>
    <ProfileSettingsDrawer
      isVisible={isProfilesSettingsOpen}
      width='505px'
    >
      <ProfileSettingsHeader
        profile={profileInfo}
        changeVisibility={closeProfilesSettings}
        renderDropDownMenu={renderDropDownMenu}
      />
      <ProfileSettingsContent
        profileData={profileInfo}
        showModalCookies={(): void => toggleCookiesListModal({ visible: true, id: profileId, name: profileName })}
        selectedProfilesIds={profileIds}
        {...props}
      />
      <PageOverlay position='absolute' />


    </ProfileSettingsDrawer>
  {isProfilesSettingsOpen ? <OverlayDrawer onClick={closeProfilesSettings} /> : null}
    </div>
  );
};

export default ProfileSettings;
