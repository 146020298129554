/* eslint-disable max-lines */
import { css, Global } from '@emotion/core';
import styled from '@emotion/styled';
import loadable from '@loadable/component';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { message } from 'antd';
import moment from 'moment';
import React, { FC, Suspense, useEffect } from 'react';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import 'moment/min/locales';
import { IS_SEPARATED_CHECKOUT_KEY_NAME } from '../common/constants/constants';
import {
  PADDLE_CHECKOUT_PAGE,
  PRICING_PAGE,
  PRICING_PAGE_WITH_OPENED_PAYMENT_METHODS,
  PRICING_ROUTE_NAME,
  PROFILE_LIST_PAGE,
  PROXY_CHECKOUT_PAGE,
  PROXY_PAGE,
  SHARE_LINK_PAGE,
} from '../common/constants/routes';
import { getCurrentTimestampInSeconds } from '../common/utils';
import { flagIcons } from '../flag-icons';
import OAuth from './features/authentification/o-auth';
import SignUp from './features/authentification/Registration/components/sign-up';
import { fetchPublicWorkflows } from './features/automation/api';
import { generateBrowserIconsOnStart } from './features/browser-system-icons';
import { fillGlobalGeolocationData } from './features/common/geolocations';
import { GlobalDragAndDropContext } from './features/common/global-dnd-context';
import PrivateRoute from './features/common/private-route';
import RootRouteHandler from './features/common/route-controller';
import OverviewPage from './features/personalArea/overview';
import RestoreProfilesPage from './features/personalArea/restore-profiles';
import {
  useBrowserUpdater,
  useExtensions,
  useImportFiles,
  useProfiles,
  useTemplate,
  useUser,
} from './hooks';
import { useInitGeoProxyCountries } from './hooks/use-init-geoproxy-countries';
import { useInitProxy } from './hooks/use-init-proxy';
import { useInitTrafficData } from './hooks/use-init-traffic-data';
import { useWorkspace } from './hooks/workspace.hook';
import i18n from './i18n';
import { getGoogleClientId } from './initGA';
import { ThemeType } from './interfaces/theme.type';
import { history, store } from './services';
import { sendCredentialsToLocalServer } from './services/http';
import { ACCESS_TOKEN, oAuthConfig } from './services/http/config';
import PerformanceObserverService from './services/performance-observer/performance-observer.service';
import {
  browserUpdaterContext,
  extensionsContext,
  importFilesContext,
  IUserCtx,
  profilesContext,
  TagsProvider,
  templatesContext,
  userContext,
  workspaceContext,
} from './state';
import { NEW_FEATURES } from './state/feature-toggle/new-features';
import { setAutomationWorkflows } from './state/automation/workflows.atom';
import { switchTheme } from './state/theme.atom';
import BrowserUpdater from './ui/BrowserUpdater';
import Footer from './ui/Footer';
import Header from './ui/Header';
import { createAppStartTransactionSpan } from './utils/app-start.transaction.utils';
import { jsFonts } from './utils/fonts-hash';
import { sendCommonEventsToServer } from './utils/send-analytics-to-server';
import { setUserSentry } from './utils/sentry-parameters/set-user';
import { parseLocale } from './utils/translation.utils';

import './quill.bubble.css';
import './ui/ant-styles/ant-button.css';
import './ui/ant-styles/ant-input.css';
import './ui/ant-styles/ant-menu.css';
import './ui/ant-styles/ant-message.css';
import './ui/ant-styles/ant-popover.css';
import './ui/ant-styles/ant-radio-button.css';
import './ui/ant-styles/ant-table.css';
import './ui/disable-animations.css';
import './ui/theme.css';

const ProfileListPage = React.lazy(() => import('./features/quickProfiles/components/profiles-list-page'));
const ProxyPage = loadable(() => import('./features/proxy/proxy-page'));
const BillingTab = loadable(() => import('./features/personalArea/billing/components'));
const PreferencesTab = loadable(() => import('./features/personalArea/preference/components'));
const DownloadPage = loadable(() => import('./features/Download/components/DownloadPage'));
const TokensPage = loadable(() => import('./features/personalArea/token'));
const PasswordManagerPage = loadable(() => import('./features/personalArea/password-manager'));
const AffiliateProgram = loadable(() => import('./features/personalArea/affiliateProgram'));
const CacheSettings = loadable(() => import('./features/personalArea/cache'));
const BrowserTemplate = loadable(() => import('./features/personalArea/browser-template'));
const FolderPage = loadable(() => import('./features/folders/components/folders-container'));
const OverviewTab = loadable(() => import('./features/personalArea/overview'));
const PricingPage = loadable(() => import('./features/pricing/components'));
const PaddleCheckoutPage = loadable(() => import('./features/pricing/components/paddle-checkout'));
const TwoFactor = loadable(() => import('./features/authentification/TwoFactor/components'));
const SignUpWrapper = loadable(() => import('./features/authentification/Registration/components/sign-up-wrapper'));
const SignIn = loadable(() => import('./features/authentification/SignIn/components'));
const NewProfilePage = loadable(() => import('./features/newProfile/components/new-profile-page'));
const MembersPage = loadable(() => import('./features/members/members-page'));
const NoWorkspacePage = loadable(() => import('./features/no-workspace-page'));
const ShareLinkPage = loadable(() => import('./features/share-links/share-link-page'));
const OpenLinkPage = loadable(() => import('./features/deep-links/open-links/redirect-to-desktop-page.tsx'));

const PersonalAreaTwoFAPage = loadable(() => import('./features/personalArea/2fa'));
const ActiveSessionsPage = loadable(() => import('./features/personalArea/activeSessions'));
const Quiz = loadable(() => import('./features/quiz'));
const ForgotPassword = loadable(() => import('./features/authentification/ForgotPassword/components'));

let ipcRenderer: Electron.IpcRenderer;
const isElectron = !!window.require;
if (isElectron) {
  ({ ipcRenderer } = window.require('electron'));

  // should be at least 1ms timeout, otherwise the transaction is not started
  // Comment when developing browser icons
  setTimeout(generateBrowserIconsOnStart, 1);
} else {
  ReactGA.initialize('UA-150829020-1');
}

const Main = styled('div')<{ newStyle: boolean }>`
  width: 100%;
  height: 100%;
  background-color: ${props => (props.newStyle ? 'var(--F9F9F9)' : 'var(--00A987-auth-pages)')};
  ${props =>
    props.newStyle &&
    css`
      height: auto;
      min-height: 100%;
      display: flex;
      flex-direction: column;
      flex: 1;
    `}

  ${flagIcons}
`;

const App: FC = () => {
  const appStartTimestamp = getCurrentTimestampInSeconds();

  if (!isElectron) {
    // для обратной совместимости старых апп с новым прайсингом
    // the same goes for share links
    if (window.location.href.includes('/#/pricing') || window.location.href.includes('/#/share/')) {
      // редирект на app.gologin.com/pricing
      window.location.href = window.location.href.replace('/#', '');

      // ИЛИ (оставляем закомментированным один из вариантов редиректа обязательно)

      // редирект на gologin.com/checkout
      // let domain = 'https://gologin.com';
      // let [_, path] = window.location.href.split('#');

      // const forceOldPricing = localStorage.getItem(FORCE_LOAD_OLD_PRICING_KEY_NAME) === 'true'; // данные будут только когда запрос на [GET] /user пройдет успешно
      // if (forceOldPricing) {
      //   domain = 'https://app.gologin.com';
      // } else {
      //   path = path.replace('pricing', 'checkout');
      // }

      // window.location.href = `${domain}${path}`;
    }

    // обратная совместимость для кейсов, когда мы пытаемся
    // открыть страницу прайсинга со старого десктоп апп
    if (window.location.href.includes('pricing')) {
      window.location.href = window.location.href.replace('pricing', `${PRICING_ROUTE_NAME}`);
    }
  }

  // if (!isElectron) {
  //   ReactPixel.init('2796675767037748');
  //   ReactPixel.pageView();
  // }

  // это можно сделать добавив /* webpackPrefetch: true */ в import, но чето нихера не работает
  // по хорошему это нужно разнести на маусоверы
  NewProfilePage.preload();
  FolderPage.preload();
  PreferencesTab.preload();
  DownloadPage.preload();
  BillingTab.preload();
  TokensPage.preload();
  PasswordManagerPage.preload();
  AffiliateProgram.preload();
  CacheSettings.preload();
  BrowserTemplate.preload();
  OverviewTab.preload();
  TwoFactor.preload();
  SignUpWrapper.preload();
  SignIn.preload();
  PersonalAreaTwoFAPage.preload();
  ActiveSessionsPage.preload();
  Quiz.preload();
  MembersPage.preload();
  NoWorkspacePage.preload();
  ShareLinkPage.preload();
  OpenLinkPage.preload();
  PricingPage.preload();
  PaddleCheckoutPage.preload();

  message.config({ top: 64, maxCount: 1 });

  const user: IUserCtx = useUser();
  useInitProxy(user._id);
  useInitTrafficData(user._id);
  useInitGeoProxyCountries(user._id);

  const isAccessTokenExists = !!localStorage.getItem(ACCESS_TOKEN);

  const updateCurrentTheme = (): void => {
    if (isElectron && process.env.GOLOGIN_ELECTRON_THEME) {
      switchTheme((localStorage.getItem('theme') || process.env.GOLOGIN_ELECTRON_THEME) as ThemeType);

      return;
    }

    switchTheme((localStorage.getItem('theme') || 'system') as ThemeType);
  };

  useEffect(() => {
    createAppStartTransactionSpan('from-init-app-to-first-effect', appStartTimestamp);

    sessionStorage.setItem(IS_SEPARATED_CHECKOUT_KEY_NAME, 'false');
    updateCurrentTheme();

    ipcRenderer && ipcRenderer.invoke('app-loaded');
    // for live session
    if (isElectron) {
      const { enable: electronCookiesEnable } = require('@livesession/electron-cookies');

      electronCookiesEnable({
        origin: 'https://gologin.com',
      });
    }

    ipcRenderer &&
      ipcRenderer.invoke('set-useragent', {
        ua: navigator.userAgent,
      });

    let appLanguage = '';
    const defaultLanguage = 'en-US';

    const setupElectronTranslation = (): void => {
      i18n.changeLanguage(appLanguage);
      setTimeout(() => {
        // без таймаута локализация дат не применяется (меньше 3 сек не ставить, на скорость запуска не влияет)
        moment.locale(appLanguage);
        localStorage.setItem('@gologin:selected_language', appLanguage);
        const translationData = {
          language: appLanguage,
          closeAppTitle: i18n.t('closeApp.title'),
          closeAppMessage: i18n.t('closeApp.message'),
          closeAppBtnClose: i18n.t('closeApp.btnClose'),
          closeAppBtnCancel: i18n.t('closeApp.btnCancel'),
        };

        ipcRenderer && ipcRenderer.invoke('send-translation-data-to-electron', translationData);
      }, 3500);
    };

    const setupAppLanguage = async (): Promise<void> => {
      const appConfig = await ipcRenderer.invoke('get-app-config');
      if (appConfig && appConfig.language) {
        appLanguage = appConfig.language;
      }

      if (!appLanguage) {
        await ipcRenderer.invoke('get-system-locale').then(appLocale => {
          // для некоторых локалей, судя по l10n_util библиотеке, могут прийти данные в виде 'en-AU'
          const [localeCode] = appLocale.split('-');
          appLanguage = localeCode ? parseLocale(localeCode) : defaultLanguage;
        });
      }

      setupElectronTranslation();
    };

    if (isElectron) {
      setupAppLanguage();
    } else {
      const userLanguage = localStorage.getItem('@gologin:selected_language');
      appLanguage = userLanguage || defaultLanguage;
      setupElectronTranslation();
    }

    sendCredentialsToLocalServer('access', 'appRender');

    history.listen((location: any) => {
      if (!isElectron) {
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname);
      }
    });

    PerformanceObserverService.getInstance().startObserver();

    createAppStartTransactionSpan('from-first-effect-to-start-setup-i18n');
  }, []);

  useEffect(() => () => localStorage.removeItem('userId'), []);

  useEffect(() => {
    fillGlobalGeolocationData();
    if (!isElectron) {
      return;
    }

    (async (): Promise<void> => {
      try {
        const fontsHash = jsFonts();
        const googleClientId = await getGoogleClientId();

        const userSystemLocale = await ipcRenderer
          .invoke('get-system-locale')
          .then(locale => {
            if (locale === null) {
              return 'null';
            }

            if (locale === undefined) {
              return 'undefined';
            }

            if (locale === '') {
              return 'empty line';
            }

            return locale;
          })
          .catch(() => null);

        const optionsToSend = {
          googleClientId,
          fontsHash,
          openedAppTrue: true,
          userSystemLocale,
        };

        await sendCommonEventsToServer('openedApp', optionsToSend);
      } catch (error) {
        console.log(error);
      }
    })();

    createAppStartTransactionSpan('from-end-setup-i18n-to-last-line-in-effect-inside-app-component');
  }, []);

  if (isElectron) {
    fetchPublicWorkflows()
      .then((publicWorkflows) => setAutomationWorkflows(publicWorkflows))
      .catch(() => []);
  }

  user._id && setUserSentry(user || {});
  const browserUpdater = useBrowserUpdater();
  const templates = useTemplate();
  const profiles = useProfiles();
  const extensions = useExtensions();
  const workspace = useWorkspace();
  const importFiles = useImportFiles();

  return (
    <>
      <Suspense fallback={null}>
        <Provider store={store}>
          {/* router doesnt want to recognize loadable components*/}
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore*/}
          <Router history={history}>
            <Main newStyle={!!NEW_FEATURES.header}>
              <Global
                styles={css`
                  * {
                    font-family: Roboto;
                    box-sizing: border-box;
                    -webkit-font-smoothing: antialiased;
                    ::selection {
                      background-color: var(--00A98733-selected-text-background);
                      color: var(--333232-selected-text);
                    }
                  }

                  html,
                  body {
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    color: var(--222222);
                    background-color: var(--FFFFFF);
                  }

                  #root {
                    width: 100%;
                    height: 100%;
                    overflow-y: auto;
                  }

                  body {
                    margin: 0;
                    padding: 0;
                  }

                  .ant-tabs-nav .ant-tabs-tab {
                    margin: 0 8px 0 0;
                  }

                  .ant-tabs .ant-tabs-small-bar .ant-tabs-tab {
                    padding: 8px;
                  }
                `}
              />
              <GoogleOAuthProvider clientId={oAuthConfig.googleOAuthClientId}>
                <userContext.Provider value={user}>
                  <workspaceContext.Provider value={workspace}>
                    <browserUpdaterContext.Provider value={browserUpdater}>
                      <templatesContext.Provider value={templates}>
                        <extensionsContext.Provider value={extensions}>
                          <TagsProvider>
                            <profilesContext.Provider value={profiles}>
                              <importFilesContext.Provider value={importFiles}>
                                <GlobalDragAndDropContext>
                                  <Header hasGlobalHeader={true} />
                                  <Switch>
                                    <PrivateRoute component={ProfileListPage} path={PROFILE_LIST_PAGE} />
                                    <PrivateRoute
                                      component={ProfileListPage}
                                      path={`${PROFILE_LIST_PAGE}/q/:search`}
                                    />
                                    <PrivateRoute component={ProxyPage} path={PROXY_PAGE} />
                                    <PrivateRoute component={BillingTab} path='/personalArea/Billing' />
                                    <PrivateRoute component={PreferencesTab} path='/personalArea/Preferences' />
                                    <PrivateRoute
                                      component={ActiveSessionsPage}
                                      path='/personalArea/ActiveSessions'
                                    />
                                    <PrivateRoute component={DownloadPage} path='/download' />
                                    <PrivateRoute component={TokensPage} path='/personalArea/TokenApi' />
                                    <PrivateRoute
                                      component={PasswordManagerPage}
                                      path='/personalArea/PasswordManager'
                                    />
                                    <PrivateRoute
                                      component={AffiliateProgram}
                                      path='/personalArea/AffiliateProgram'
                                    />
                                    <PrivateRoute component={PersonalAreaTwoFAPage} path='/personalArea/2fa' />
                                    <PrivateRoute component={CacheSettings} path='/personalArea/cache' />
                                    <PrivateRoute
                                      component={BrowserTemplate}
                                      path='/personalArea/default-profile-settings'
                                    />
                                    <PrivateRoute
                                      component={RestoreProfilesPage}
                                      path='/personalArea/restore-profiles'
                                    />
                                    <PrivateRoute component={FolderPage} path='/folders' />
                                    <PrivateRoute component={OverviewPage} path='/personalArea' />
                                    <PrivateRoute
                                      component={NewProfilePage}
                                      path='/newProfile'
                                      workspaceCtx={workspace}
                                    />
                                    <PrivateRoute component={ProfileListPage} path='/update/:profileId' />
                                    <Route component={TwoFactor} path='/two_factor' />
                                    <PrivateRoute component={MembersPage} path={'/members'} />
                                    <Route path='/sign_up' component={SignUp} />
                                    {isElectron ? null : <Route path={SHARE_LINK_PAGE} component={ShareLinkPage} />}
                                    {isElectron ? null : (
                                      <Route path='/redirect-to-desktop' component={OAuth.RedirectToDesktopPage} />
                                    )}
                                    <Route path='/share' component={SignUpWrapper} />
                                    <Route path='/sign_in' component={SignIn} />
                                    <Route path='/forgot_password' component={ForgotPassword} />
                                    <Route path='/quiz' component={Quiz} />
                                    <Route path='/sign_up_frame' component={SignUp} />
                                    <Route path='/no_workspace' component={NoWorkspacePage} />
                                    <Route path={PROXY_CHECKOUT_PAGE} component={PaddleCheckoutPage} />
                                    <Route path={PADDLE_CHECKOUT_PAGE} component={PaddleCheckoutPage} />
                                    <Route path={PRICING_PAGE} component={PricingPage} />
                                    <Route path={PRICING_PAGE_WITH_OPENED_PAYMENT_METHODS} component={PricingPage} />
                                    {isElectron ? null : <Route path='/:profileIdOrName' component={OpenLinkPage} />}

                                    <Redirect path='/pay' to={PRICING_PAGE} />
                                    <Redirect path='/survey' to='/quiz' />
                                    <Route path='/' component={RootRouteHandler} />
                                    <Redirect path='*' to='/' />
                                  </Switch>
                                  <BrowserUpdater />
                                  <Footer />
                                </GlobalDragAndDropContext>
                              </importFilesContext.Provider>
                            </profilesContext.Provider>
                          </TagsProvider>
                        </extensionsContext.Provider>
                      </templatesContext.Provider>
                    </browserUpdaterContext.Provider>
                  </workspaceContext.Provider>
                </userContext.Provider>
              </GoogleOAuthProvider>
            </Main>
          </Router>
        </Provider>
      </Suspense>
    </>
  );
};

export default App;
