import styled from '@emotion/styled';

import { BACKGROUNDS, BackgroundType, IRow, RowType } from '../../constants';

export const TableContainer = styled('div')`
  margin-top: 70px;
  display: grid;
  grid-template-columns: 296px repeat(4, 218px);
  column-gap: 16px;
`;

export const PlanCellContainer = styled.div<{backgroundColor: BackgroundType; rowInfo: IRow}>`
  background-color: ${({ backgroundColor }): string => BACKGROUNDS[backgroundColor]};
  border: solid var(--D2D2D5-new-pricing);
  border-width: ${({ rowInfo }): string => rowInfo.borderWidth};
  border-radius: ${({ rowInfo }): string => {
    if (rowInfo.type === 'header') {
      return '12px 12px 0 0';
    }

    if (rowInfo.type === 'footer') {
      return '0 0 12px 12px';
    }

    return '0px';
  }};
`;

export const PlanCellStyled = styled('div')<{ backgroundColor: BackgroundType }>`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.44px;
  color: ${({ backgroundColor }): string => backgroundColor === 'black' ? 'var(--FFFFFF-new-pricing-custom)' : 'var(--36363D-new-pricing)'};
  border-bottom: 1px solid ${({ backgroundColor }): string => backgroundColor === 'black' ?
    'var(--696973-new-pricing-custom)' :
    'var(--D2D2D5-new-pricing)'};
`;

export const PlanHeader = styled(PlanCellStyled)`
  position: relative;
  padding: 36px 42px 24px;
  flex-direction: column;
  row-gap: 24px;
  text-align: center;
  font-family: 'Roboto Flex';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
`;

export const PlanFooter = styled(PlanCellStyled)`
  padding: 24px 42px 20px;
  border-bottom: none;
`;

export const FieldContainer = styled('div')<{ rowType: RowType }>`
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: ${({ rowType }): string => rowType === 'footer' ? 'none' : '1px solid rgba(54, 54, 61, 0.1)'};
`;

export const FieldTitle = styled('h3')`
  margin: 0;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: var(--81818A-new-pricing);
`;

export const FieldDescription = styled('p')`
  margin: 4px 0 0 0;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.44px;
  color: var(--36363D-new-pricing);
`;

export const IconContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
`;
