import { requestExtensionList } from './api';
import { IExtensionFromDb, IExtensionsObjectProxyTab } from './interfaces/extension.interface';

interface IGetExtensionList {
  chromeExtensions: string[];
  userChromeExtensions: string[];
  updateProfileInfo: any;
  isLoadingExtensions: boolean;
  updateExtensionsMap: (extensions: IExtensionFromDb[]) => void;
  getExtensions: (extensionIds: string[]) => IExtensionFromDb[];
}

export const getExtensionList = async (props: IGetExtensionList): Promise<IExtensionFromDb[]> => {
  const { chromeExtensions = [], userChromeExtensions = [], updateProfileInfo, updateExtensionsMap } = props;
  const allExtensions: string[] = [...chromeExtensions, ...userChromeExtensions];

  return requestExtensionList({ extensionIds: allExtensions }).then((extensionsFromRequest: IExtensionFromDb[]) => {
    updateExtensionsMap(extensionsFromRequest);
    const extensionsObject: IExtensionsObjectProxyTab = extensionsFromRequest
      .reduce((acc: IExtensionsObjectProxyTab, extension: IExtensionFromDb) => {
        if (allExtensions.includes(extension.extId)) {
          acc.filteredExtensions.push(extension);
        }

        if (extension.addToNewProfiles && extension.type === 'chrome') {
          acc.chromeExtensionsToNewProfiles.push(extension.extId);
        }

        if (extension.addToNewProfiles && extension.type === 'user') {
          acc.userExtensionsToNewProfiles.push(extension.extId);
        }

        return acc;
      }, { filteredExtensions: [], chromeExtensionsToNewProfiles: [], userExtensionsToNewProfiles: [] });

    if (extensionsObject.chromeExtensionsToNewProfiles.length || extensionsObject.userExtensionsToNewProfiles.length) {
      updateProfileInfo({
        chromeExtensionsToNewProfiles: extensionsObject.chromeExtensionsToNewProfiles,
        userChromeExtensionsToNewProfiles: extensionsObject.userExtensionsToNewProfiles,
      });
    }

    return extensionsObject.filteredExtensions;
  });
};
