import { Popper } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { browserUpdaterContext, userContext } from '../../../../state';
import { sendUpdateAnalytics } from '../../../Header/api';
import { IconArrowRight, IconWarning } from '../../icons';
import { IconWrapperWithDescription } from '../../icons/wrapper';
import { Text, TextWithButton, TooltipContainer, TooltipText, Wrapper } from '../styles';
import Steps from './steps';

let ipcRenderer: Electron.IpcRenderer;

const isElectron = !!window.require;
if (isElectron) {
  ipcRenderer = window.require('electron').ipcRenderer;
}

const UpdateOrbitaBar = ({ hasOneStep }: { hasOneStep: boolean }): JSX.Element => {
  const [isErrorUpdate, setIsErrorUpdate] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { updateBrowserUpdater, browserUpdating, downloadProgressStatus, isDiscSpaceError } = useContext(
    browserUpdaterContext,
  );

  const { lastBrowserVersion } = useContext(userContext);

  const { t: translation } = useTranslation();

  useEffect(() => {
    setIsErrorUpdate(isDiscSpaceError || downloadProgressStatus === 'retrying');
  }, [isDiscSpaceError, downloadProgressStatus]);

  const startBrowserUpdating = async (): Promise<void> => {
    updateBrowserUpdater({
      initialized: true,
      browserUpdating: true,
      showOrbitaDialog: false,
    });

    ipcRenderer.invoke('download-browser');
    sendUpdateAnalytics('orbita');
  };

  const renderTitle = (): string => {
    if (isDiscSpaceError) {
      return translation('header.noDiskSpace');
    }

    if (downloadProgressStatus === 'retrying') {
      return translation('header.connectivityIssues');
    }

    return (
      translation('downloadOrbita.orbita') + ' ' + lastBrowserVersion + ' ' + translation('downloadOrbita.available')
    );
  };

  const getUpdateMessage = (): JSX.Element | null => {
    if (downloadProgressStatus === 'retrying') {
      return null;
    }

    const defaultColor = isErrorUpdate
      ? 'var(--767676-promo-bar-update-orbita)'
      : 'var(--00A987-promo-bar-update-orbita)';
    const hoveredColor = isErrorUpdate
      ? 'var(--2B2B31-promo-bar-update-orbita)'
      : 'var(--00997A-promo-bar-update-orbita)';

    return (
      <IconWrapperWithDescription
        onClick={startBrowserUpdating}
        iconColor={defaultColor}
        iconHoveredColor={hoveredColor}
        textColor={defaultColor}
        textHoveredColor={hoveredColor}
        iconType='stroke'
      >
        <TextWithButton>{translation(isErrorUpdate ? 'base.retry' : 'base.update')}</TextWithButton>
        <IconArrowRight padding={0} styleType={isErrorUpdate ? 'darkGray' : 'green'} />
      </IconWrapperWithDescription>
    );
  };

  const renderContent = (): JSX.Element => {
    if (browserUpdating && !isErrorUpdate) {
      return <Steps hasOneStep={hasOneStep} />;
    }

    return (
      <>
        {isErrorUpdate ? <IconWarning padding={0} margin='0 8px 0 0' /> : null}
        <Text onMouseEnter={(e): void => setAnchorEl(e.currentTarget)} onMouseLeave={(): void => setAnchorEl(null)}>
          {renderTitle()}
        </Text>
        {getUpdateMessage()}
      </>
    );
  };

  return (
    <>
      <Wrapper
        background={isErrorUpdate ? 'var(--FFF2F2-promo-bar-update-orbita)' : 'var(--E7F8E7-promo-bar-update-orbita)'}
        border={isErrorUpdate ? '' : 'var(--D6EBD6-promo-bar-update-orbita-borders)'}
      >
        {renderContent()}
      </Wrapper>
      {hasOneStep ? (
        <Popper open={!!anchorEl} anchorEl={anchorEl} placement='bottom'>
          <TooltipContainer>
            <TooltipText>{renderTitle()}</TooltipText>
          </TooltipContainer>
        </Popper>
      ) : null}
    </>
  );
};

export default UpdateOrbitaBar;
