import styled from '@emotion/styled';
import { Check } from '@material-ui/icons';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { getInviteActionText, SelectRole, ShareLinksAction, SharingSelectRole } from '../../../utils/roles-texts';

const ItemTitle = styled('p')`
  font-size: 14px;
  line-height: 16px;
  color: var(--222222-members-permissions-select);

  margin: 0;
`;

const CustomCheck = styled(Check)`
  &.MuiSvgIcon-root {
    height: 10px;
    margin-left: -4px;
  }
`;

interface IRoleTitle {
  role: SelectRole|SharingSelectRole|'mySharedAccount'|ShareLinksAction;
  selectedRole?: SelectRole|SharingSelectRole|ShareLinksAction;
}

const RoleTitle: FC<IRoleTitle> = ({ role, selectedRole }) => {
  const { t: translation } = useTranslation();

  return (
    <ItemTitle>
      {getInviteActionText(role, translation)}
      {selectedRole === role
        ? (
          <CustomCheck
            htmlColor='var(--00A987-members-permissions-select)'
            viewBox='0 7 19 13'
          />
        )
        : null}
    </ItemTitle>
  );
};

export default RoleTitle;
