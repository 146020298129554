import styled from '@emotion/styled';

export const InviteModalSubheader = styled.div`
  display: flex;
  justify-content: space-between;
  color: var(--767676-modals-invite-members);
  font: normal 14px/16px 'Roboto';

  margin-bottom: 4px;
`;
