export enum E_SENTRY_MESSAGES {
  userFlowInited = 'Userflow: inited',
  userFlowUserIdentified = 'Userflow: user should be added to userflow DB',
  userFlowNotInitedUserUnknown = 'Userflow: user not identified, userId not found',
  userFlowUnknownIssue = 'Userflow: unknown issue',
  electronOpenedExternalUrl = 'Electron successfully opened external url:',
  electronTryOpenExternalUrl = 'Electron try open external url:',
  visitedCheckoutPage = 'visited checkout page',
}

export enum E_SENTRY_ERRORS {
  UNKNOWN_ERROR = 'unknown error',
}
