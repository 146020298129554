import { IProfile } from '../interfaces';
import { getFoldersList } from '../state/folders/folders-list.atom';

const getProfileWorkspaceFolderIds = (profile: IProfile): string[] => {
  const allFolders = getFoldersList();
  const profileFolders = profile.folders || [];

  return profileFolders.reduce<string[]>((acc, folderName) => {
    const folderInList = allFolders.find((folder) => folder.name === folderName && !folder.shared);
    if (folderInList) {
      acc.push(folderInList.id);
    }

    return acc;
  }, []);
};

export default getProfileWorkspaceFolderIds;
