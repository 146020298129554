import { css } from '@emotion/core';
import styled from '@emotion/styled';
import React, { FunctionComponent } from 'react';

import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { borderRadiusContainer } from '../../../ui/style-templates';

declare interface IContainerDashboard {
  closeDrawer: () => void;
}

const Container = styled('div')<{ newStyle: boolean }>`
  ${props => props.newStyle && css `
    display: flex;
    flex-direction: column;
    flex: 1;
    background: var(--FFFFFF-profile-table-container);
  `}
  ${props => props.newStyle && borderRadiusContainer()}
  height: 100%;
`;

const ContainerDashboard: FunctionComponent<IContainerDashboard> = (props) => (
  <Container newStyle={!!NEW_FEATURES.header} onClick={props.closeDrawer}>
    {props.children}
  </Container>
);

export default ContainerDashboard;
