import { API_BASE_URL } from '../../../../../common/constants/constants';
import { http } from '../../../../services';

export const requestProfileHistory = async (profileId: string) => {
  const url = `${API_BASE_URL}/browser/${profileId}/history`;

  const data = await http(url);

  return data.body;
};

export const cloneS3Profile = async (profileId: string, keyId: string) => {
  const url = `${API_BASE_URL}/browser/${profileId}/history`;

  const data = await http(url, {
    method: 'PATCH',
    body: JSON.stringify({
      id: keyId.substr(keyId.indexOf('/') + 1),
    }),
  });

  return data.body;
};
