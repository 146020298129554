import React, { Component } from 'react';
import { Switch, Select } from 'antd';
import styled from '@emotion/styled';
import TIMEZONES from '../../common/timezones';
import { TransProps, withTranslation } from 'react-i18next';

const { Option } = Select;

const ElementContainer = styled('div')`
  margin-top: 35px;
  line-height: 20px;
`;

const Row = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Subtitle = styled('span')`
  font-size: 12px;
  color: var(--000000-update-profile-timezone);
  margin-left: 13px;
`;

interface ITimezoneTab extends TransProps {
  updateProfileInfo: any;
  profileInfo: any;
  setUpdatedField: (field: string) => any;
}
class TimezoneTab extends Component<ITimezoneTab> {
  private changeFillTimezone = (isEnabled: boolean) => {
    const { timezone = {} } = this.props.profileInfo;

    this.props.updateProfileInfo({
      timezone: {
        ...timezone,
        fillBasedOnIp: isEnabled
      }
    });

    this.props.setUpdatedField('timezone.fillBasedOnIp');
  };

  private updateTimezone = (value: string) => {
    const { timezone = {} } = this.props.profileInfo;

    this.props.updateProfileInfo({
      timezone: {
        ...timezone,
        timezone: value
      }
    });

    this.props.setUpdatedField('timezone.timezone');
  };

  private getTimezoneContent = () => {
    const { timezone = {} } = this.props.profileInfo;
    const fillBasedOnIp = timezone.fillBasedOnIp;
    if (fillBasedOnIp) {
      return '';
    }

    return (
      <>
        <ElementContainer>
          <Select
            showSearch={true}
            value={this.props.profileInfo.timezone.timezone}
            onSelect={this.updateTimezone}
            style={{ width: 320 }}
          >
            {TIMEZONES.sort((a, b) => a.name.localeCompare(b.name)).map(
              elem => {
                const timezoneName = elem.name;
                const offset = elem.offset;

                // TODO: Localize timezones
                return (
                  <Option key={timezoneName} value={timezoneName}>
                    {timezoneName} ({offset})
                  </Option>
                );
              }
            )}
          </Select>
        </ElementContainer>
      </>
    );
  };

  public render(): JSX.Element {
    return (
      <>
        <ElementContainer>
          <Row>
            <Switch
              checked={this.props.profileInfo.timezone.fillBasedOnIp}
              onChange={this.changeFillTimezone}
            />

            <Subtitle>{this.props.t('editProfile.timezone.fillBasedOnIp')}</Subtitle>
          </Row>
        </ElementContainer>
        {this.getTimezoneContent()}
      </>
    );
  }
}

export default withTranslation()(TimezoneTab);
