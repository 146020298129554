import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconPin: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M2.5 6.16602L9.83333 13.4993' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M9.09961 2.5L13.4996 6.9' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3.70703 7.37395L10.3048 3.70508' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8.62598 12.2931L12.2948 5.69531' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M5.06667 10.9336L2.5 13.5003' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  </IconWrapper>
);
