import { createContext } from 'react';

import { IExtensionFromDb } from '../features/profileSettingsComponents/extensionsTab/interfaces/extension.interface';

export interface IExtensionsCtx {
  getExtensions: (extensionIds: string[]) => IExtensionFromDb[];
  updateExtensionsMap: (extensions: IExtensionFromDb[]) => void;
  isLoading: boolean;
  setIsLoading: (state: boolean) => void;
  addCustomExtension: (extension: IExtensionFromDb) => void;
}

export const EXTENSIONS_DEFAULT_VALUE: IExtensionsCtx = {
  getExtensions: () => [],
  updateExtensionsMap: () => {},
  isLoading: true,
  setIsLoading: () => {},
  addCustomExtension: () => {},
};

export const extensionsContext = createContext<IExtensionsCtx>(EXTENSIONS_DEFAULT_VALUE);
