import { message } from 'antd';

import { ICreateUserBase } from './interfaces/create-user-base.interface';
import { IRequestData } from './interfaces/request-data.interface';
import { FlowType, ProviderType } from './types';
import { AFFILIATE_COOKIE_NAME, API_BASE_URL, E_APP_TYPE } from '../../../../common/constants/constants';
import { getGoogleClientId, initGA } from '../../../initGA';
import { saveToken } from '../../../services/http/storage';
import { getCanvasAndFontsHash, getCanvasHash } from '../../../utils/canvas-hash';
import { retriveCookie } from '../../../utils/document-cookie';
import { jsFonts } from '../../../utils/fonts-hash';
import { SHARE_LINK_KEY } from '../../share-links/constants';
import { getDomainWithPath } from '../helpers/utils';
import { IAuthTokens } from '../interfaces/auth-tokens.interface';

const isElectron = !!window.require;

const handleError = (provider: ProviderType): void => {
  const providerCapitilized = provider[0]?.toUpperCase() + provider?.slice(1);
  message.error(`Failed to authorize with ${providerCapitilized}`);
};

const createBody = async (provider: ProviderType): Promise<string> => {
  const affiliateCookie = retriveCookie(AFFILIATE_COOKIE_NAME);
  const googleClientId = await getGoogleClientId();

  const fontsHash = jsFonts();
  const canvasHash = getCanvasHash();
  const canvasAndFontsHash = getCanvasAndFontsHash();

  const requestObject: ICreateUserBase = {
    googleClientId,
    fromApp: isElectron,
    fromAppTrue: isElectron,
    canvasAndFontsHash,
    affiliate: affiliateCookie || '',
    fontsHash,
    registrationLead: {
      url: isElectron ? E_APP_TYPE.desktop : getDomainWithPath(),
    },
  };

  if (canvasHash) {
    requestObject.canvasHash = canvasHash + '';
  }

  const shareLink = sessionStorage.getItem(SHARE_LINK_KEY);
  if (shareLink) {
    requestObject.shareLink = shareLink;
  }

  console.log(requestObject);
  let body = '';
  try {
    body = JSON.stringify(requestObject);
  } catch (error) {
    handleError(provider);
  }

  return body;
};

const createCredentialParams = (credential: string, flow: FlowType = 'code'): URLSearchParams => {
  const params = new URLSearchParams();
  params.append(flow, credential);

  return params;
};

export const authWithProvider = async ({
  credential,
  provider,
  isFromDesktop,
  flow,
}: IRequestData): Promise<IAuthTokens | undefined> => {
  const body = await createBody(provider);
  if (!body) {
    return;
  }

  const params = createCredentialParams(credential, flow);
  params.append('free-plan', 'true');
  isFromDesktop && params.append('desktop', 'true');

  const authTokensResponse = await fetch(`${API_BASE_URL}/user/o-auth/${provider}?${params.toString()}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body,
  }).catch(() => handleError(provider));

  if (!authTokensResponse) {
    return;
  }

  const authTokens: IAuthTokens = await authTokensResponse.json();

  if (!isFromDesktop) {
    saveToken(authTokens?.token, 'authWithProvider');
    await initGA().catch(() => null);
  }

  return authTokens;
};
