import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { EMPTY_UPGRADE_DISCOUNT } from '../../features/pricing/constants';
import { IUpgradeDiscount } from '../../features/pricing/interfaces';

const upgradeDiscountAtom = atom<IUpgradeDiscount>(EMPTY_UPGRADE_DISCOUNT);
const shouldShowUpgradeDiscountLineAtom = atom<boolean>(false);

export const useUpgradeDiscount = (): IUpgradeDiscount => useAtomValue(upgradeDiscountAtom);
export const useShouldShowUpgradeDiscountLine = (): boolean => useAtomValue(shouldShowUpgradeDiscountLineAtom);
export const useIsUpgradeDiscountAvaliable = (): boolean => {
  const { active = false } = useUpgradeDiscount();

  return active;
};

export const setUpgradeDiscount = (data: IUpgradeDiscount): void => getDefaultStore().set(upgradeDiscountAtom, data);
export const getUpgradeDiscount = (): IUpgradeDiscount => getDefaultStore().get(upgradeDiscountAtom);

export const setShouldShowUpgradeDiscountLine = (data: boolean): void => getDefaultStore().set(shouldShowUpgradeDiscountLineAtom, data);
export const getShouldShowUpgradeDiscountLine = (): boolean => getDefaultStore().get(shouldShowUpgradeDiscountLineAtom);
