import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { AUDIO_CONTEXT_MODES, AudioContextModesType, IFullProfile } from '../../../../interfaces';
import { ModernSelect } from '../../../../ui/modern-select';
import { ContainerParams } from '../../styles';

interface IAudioContextComponent {
  mode: AudioContextModesType;
  changeSetting: (profileData: Partial<IFullProfile>) => void;
}

export const AudioContext: FC<IAudioContextComponent> = ({ changeSetting, mode }) => {
  const { t: translation } = useTranslation();

  const titleSelect: Record<AudioContextModesType, string> = {
    noise: translation('quickSettings.parameters.maskedWithNoise'),
    off: translation('quickSettings.parameters.real'),
  };

  const selectItems = AUDIO_CONTEXT_MODES.map(audioContextMode => ({
    title: titleSelect[audioContextMode],
    value: audioContextMode,
  }));

  return (
    <ContainerParams>
      <ModernSelect
        currentValue={mode}
        itemList={selectItems}
        onSelected={(value): void => changeSetting({ audioContext: { mode: value as AudioContextModesType } })}
      />
    </ContainerParams>
  );
};
