import { Popover } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DrawerProfileHeaderButtons, DrawerTrustworthy, NewStyleText } from './styles';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { IProfile } from '../../../interfaces';
import { history } from '../../../services';
import { useProfileRunStatus } from '../../../state/profile-run-statuses.atom';
import { IProfileRunStatus } from '../../../state/profiles-list.atom';
import { IconSettings2, IconShield } from '../../../ui/gologin-header/icons';
import { IconWrapperWithDescription } from '../../../ui/gologin-header/icons/wrapper';
import IconTrustworthy from '../../../ui/icons/IconTrustworthy';
import { ILaunchProfileOrbita } from '../../quickProfiles/components/interfaces/launch-profile-orbita.interface';
import RunProfileButton from '../../quickProfiles/run-button';

interface IDrawerHeaderButtons {
  profile: IProfile;
  renderDropDownMenu: (rowInfo: IProfile & IProfileRunStatus, isInDrawer?: boolean) => JSX.Element;
  launchProfileOrbita: (opts: ILaunchProfileOrbita) => void;
}

const DrawerHeaderButtons: FC<IDrawerHeaderButtons> = (props) => {
  const {
    profile,
    renderDropDownMenu,
    launchProfileOrbita,
  } = props;

  const profileRunStatus = useProfileRunStatus(profile?.id);

  const { t: translation } = useTranslation();

  const renderAdvanced = (): JSX.Element|null => {
    if (!NEW_FEATURES.header) {
      return null;
    }

    return (
      <IconWrapperWithDescription
        onClick={() => history.push(`/update/${profile?.id}`)}
        style={{ marginLeft: 24 }}
        colorIconType='activeGray'
        iconColor='var(--767676)'
        iconHoveredColor='var(--2B2B31)'
        textColor='var(--767676)'
        textHoveredColor='var(--2B2B31)'
        iconType='stroke'
      >
        <IconSettings2 padding={0} />
        <NewStyleText>
          {translation('profiles.menu.editProfile')}
        </NewStyleText>
      </IconWrapperWithDescription>
    );
  };

  const trustworthyPopover = (
    <div style={{ width: 250, fontFamily: 'Roboto', fontSize: 12, color: '666666' }}>
      <div style={{ fontWeight: 700 }}>
        {translation('profiles.tutorial.trustworthy.title')}
      </div>
      <div style={{ width: 216 }}>
        {translation('profiles.tutorial.trustworthy.contentTxt')}
      </div>
    </div>
  );

  const renderTrustworthy = (): JSX.Element => {
    if (!NEW_FEATURES.header) {
      return (
        <>
          <IconTrustworthy />
          <Popover
            content={trustworthyPopover}
            placement={'top'}
          >
            <DrawerTrustworthy>
              {translation('quickSettings.trustworthyTxt')}
            </DrawerTrustworthy>
          </Popover>
        </>
      );
    }

    return (
      <Popover
        content={trustworthyPopover}
        placement={'top'}
      >
        <div style={{ marginLeft: 16, display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
          <IconShield
            styleType='green'
            padding={0}
            iconColor='var(--00A987)'
          />
          <NewStyleText>
            {translation('quickSettings.trustworthyTxt')}
          </NewStyleText>
        </div>
      </Popover>
    );
  };

  return (
    <DrawerProfileHeaderButtons newStyle={!!NEW_FEATURES.header}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <RunProfileButton
          profile={profile || {}}
          isInDrawer={true}
          launchProfileOrbita={launchProfileOrbita}
        />
        {renderAdvanced()}
        {renderDropDownMenu({ ...profile, ...profileRunStatus }, true)}
      </div>
      {renderTrustworthy()}
    </DrawerProfileHeaderButtons>
  );
};

export default DrawerHeaderButtons;
