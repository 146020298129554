import { Icon, Modal } from 'antd';
import React, { Component } from 'react';
import { NEW_FEATURES } from '../../state/feature-toggle/new-features';

declare interface IModal {
  visible: boolean;
  onClose: any;
  closable?: boolean;
  footer?: any;
  title?: any;
  width?: number|string;
  height?: number;
  destroyOnClose?: boolean;
  bodyStyle?: React.CSSProperties;
  centered?: boolean;
  wrapClassName?: string;
}

class GologinModal extends Component<IModal> {
  render() {
    return (
      <Modal
        afterClose={() => document.body.removeAttribute('style')}
        width={this.props.width}
        visible={this.props.visible}
        closable={this.props.closable || true}
        title={<div style={{ height: 26 }}>{this.props.title || ''}</div>}
        closeIcon={!NEW_FEATURES.header && <Icon type='close' style={{ color: 'var(--FFFFFF)' }} />}
        onCancel={this.props.onClose}
        footer={this.props.footer || ''}
        destroyOnClose={this.props.destroyOnClose || false}
        // style={this.props.style}
        bodyStyle={this.props.bodyStyle}
        centered={this.props.centered || false}
        wrapClassName={this.props.wrapClassName || ''}
      >
        {this.props.children}
      </Modal>
    );
  }
}

export default GologinModal;
