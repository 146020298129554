import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconCheckSmall: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'>
      <path d='M0.75 6.75L3.75 9.75L11.25 2.25' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  </IconWrapper>
);
