import { css, SerializedStyles } from '@emotion/core';
import styled from '@emotion/styled/macro';
import { Popover } from '@material-ui/core';

import { textFont } from '../../style-templates';

export const SearchInputWrapper = styled.div<{ isSearchPinned: boolean; isSearchOpen: boolean }>`
  position: relative;
  display: flex;
  height: 32px;
  padding: 8px;
  background: var(--F2F2F2-header-new-search);
  border-radius: 8px;

  ${(props): SerializedStyles|null => props.isSearchPinned && !props.isSearchOpen ? css`
    :hover {
      background: var(--EAEAEB-header-new-search);
    }
  ` : null}
`;

export const SearchInputContainer = styled.div<{ isSearchPinned: boolean; isSearchOpen: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: row;
  width: ${props => props.isSearchPinned && !props.isSearchOpen ? '200px' : '264px'};
  
  justify-content: ${props => props.isSearchPinned && !props.isSearchOpen ? 'center' : 'none'};
`;

export const SearchInput = styled.input`
  flex: 1;

  padding: 0;
  width: 196px;
  background: var(--F2F2F2-header-new-search);
  border: none;
  outline: none;
  
  color: var(--222222-header-new-search);

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;

  &::placeholder {
    color: var(--CDCDCD-header-new-search);
  }
`;

export const WrapperOptions = styled.div`
  width: 360px;
  padding: 0 4px;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const IconContainer = styled.div`
  display: none;
`;

export const OptionItem = styled.div`
  width: 100%;
  height: 32px;
  padding: 6px 16px 6px 12px;
  border-radius: 4px;
  margin: 1px 0 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;

  ${textFont()}
  color: var(--36363D-header-new-search);

  background: var(--header-new-search-popover-item-background);
  cursor: pointer;

  :hover {
    background: var(--header-new-search-popover-item-hovered-background);
    
    ${IconContainer} {
      display: flex;
    }
  }
`;

export const OptionsTitle = styled.div`
  color: var(--B5B5BA-header-new-search);
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  display: flex;
  padding: 6px 16px 6px 12px;
  align-items: center;
  align-self: stretch;
`;

export const OptionItemTitle = styled.div`
  color: var(--222222-header-new-search);
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ItemTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
`;

export const SearchMenuPopup = styled(Popover)`
  .MuiPopover-paper {
    width: 232px;
    padding: 4px;
    outline: var(--header-new-search-popover-outline);
    border-radius: 4px;
    margin-top: 8px;
    margin-left: 40px;

    display: flex;
    flex-direction: column;

    background: var(--header-new-search-popover-background);
  }

  .MuiPaper-elevation8 {
    box-shadow: var(--box-shadow-popup);
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SearchIconContainer = styled(Row)`
  align-items: center;
`;
