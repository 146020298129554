import React from 'react';

const IconModalCheck = (): JSX.Element => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="32" cy="32" r="32" fill="var(--DEF7E9-new-pricing)" />
    <circle cx="32" cy="32" r="24" fill="var(--00A987-new-pricing)" />
    <path d="M28.5 40C28.102 40 27.72 39.842 27.439 39.561L21.086 33.207C20.696 32.816 20.696 32.183 21.086 31.793L21.793 31.086C22.184 30.695 22.817 30.695 23.207 31.086L28.5 36.379L40.793 24.086C41.184 23.695 41.817 23.695 42.207 24.086L42.914 24.793C43.305 25.184 43.305 25.817 42.914 26.207L29.561 39.561C29.28 39.842 28.898 40 28.5 40Z" fill="var(--FFFFFF-new-pricing)" />
  </svg>
);

export default IconModalCheck;
