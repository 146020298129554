import React from 'react';
import { useTranslation } from 'react-i18next';

import WorkflowList from './automation-list';
import { IProfileLaunch } from './interfaces';
import WorkflowParametersModal from './cookiebot-parameters-modal';
import Search from './search';
import { Board, ListWrapper } from './styles';
import GologinModal from '../../ui/gologin-modal';
import { useFilteredAutomationWorkflows } from '../../state/automation/workflows.atom';
import { closeAutomationModal, useAutomationModal } from '../../state/automation/automation-modal.atom';
import { setAutomationSearch } from '../../state/automation/automation-search.atom';

type IAutomationManager = IProfileLaunch;

const AutomationManager: React.FC<IAutomationManager> = (props) => {
  const { launchProfileOrbita } = props;

  const filteredAutomationWorkflows = useFilteredAutomationWorkflows();

  const automationModalState = useAutomationModal();
  const isAutomationModalVisible = automationModalState.isVisible;

  const profileId = 'profileId' in automationModalState ? automationModalState.profileId : '';

  const { t: translation } = useTranslation();

  const handleClose = (): void => {
    closeAutomationModal();
    setAutomationSearch('');
  };

  return (
    <>
      <GologinModal
        width='640px'
        title={translation('automationModal.title')}
        isVisible={isAutomationModalVisible}
        onClose={handleClose}
        bodyStyle={{ height: 604 }}
      >
        <Board>
          <ListWrapper>
            <Search />
            <WorkflowList
              profileId={profileId}
              workflows={filteredAutomationWorkflows}
              isLoading={false}
              launchProfileOrbita={launchProfileOrbita}
            />
          </ListWrapper>
        </Board>
      </GologinModal>
      <WorkflowParametersModal launchProfileOrbita={launchProfileOrbita} />
    </>
  );
};

export default AutomationManager;
