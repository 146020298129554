import * as Sentry from '@sentry/react';
import { atom, getDefaultStore } from 'jotai';

import { COUNTRIES_WITH_FLAGS } from '../../../flag-icons';
import { getAllVpnUfoCountries } from '../../features/profileSettingsComponents/proxyTab/api';
import { VpnUfoCountryWithTypes } from '../../features/profileSettingsComponents/proxyTab/vpn-ufo.types';
import {
  COUNTRY_HAS_NO_FLAG_ERROR,
  COUNTRY_HAS_NO_FLAG_ERROR_MESSAGE,
  LOAD_GEOPROXY_COUNTRIES_TRANSACTION,
  PROXY_COUNTRY_TAG,
} from '../../features/proxy/constants';
import { sendReactErrorToSentry } from '../../utils/sentry.helper';

export const geoProxyCountriesAtom = atom<VpnUfoCountryWithTypes[]>([]);

const setGeoProxyCountries = (countries: VpnUfoCountryWithTypes[]): void => getDefaultStore().set(geoProxyCountriesAtom, countries);

export const updateGeoProxyCountries = (countries: VpnUfoCountryWithTypes[]): void => {
  const countriesWithFlags = countries.filter(({ countryCode }) => {
    if (COUNTRIES_WITH_FLAGS.includes(countryCode.toLowerCase())) {
      return true;
    }

    sendReactErrorToSentry({
      transactionName: COUNTRY_HAS_NO_FLAG_ERROR,
      message: COUNTRY_HAS_NO_FLAG_ERROR_MESSAGE,
      tags: [
        [PROXY_COUNTRY_TAG, countryCode],
      ],
    });

    return false;
  });

  setGeoProxyCountries(countriesWithFlags);
};

export const loadGeoProxyCountries = async (): Promise<void> => {
  const transaction = Sentry.startTransaction({ name: LOAD_GEOPROXY_COUNTRIES_TRANSACTION });

  const countries = await getAllVpnUfoCountries();
  updateGeoProxyCountries(countries);

  transaction.finish();
};

export const clearGeoProxyCountries = (): void => {
  setGeoProxyCountries([]);
};
