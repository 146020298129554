import { atom, getDefaultStore, useAtomValue } from 'jotai';
import moment from 'moment';

import { ProxyCheckTooltipLocation } from './types/proxy-check-tooltip-location.type';
import { UpdateProxyStatusesParams } from './types/update-proxy-statuses.types';
import { getIsProxyEditable, getProxyStatusParams } from '../../../features/proxy/proxy-helpers';
import { IProxy } from '../../../interfaces';
import { mapAndSetProfilesList } from '../../profiles-list.atom';
import { setProxyStatuses as setProxyStatusesRequest, setSharedProxyStatuses } from '../../proxies.context/api';
import { ISetProxyStatusParams } from '../../proxies.context/interfaces/ISetProxyStatusParams';
import { updateProxyItem } from '../proxy-list.atom';

const proxyStatusesAtom = atom<IProxy[]>([]);
const proxyStatusesProfilesIdsAtom = atom<string[]>([]);
const proxyStatusViewAtom = atom<ProxyCheckTooltipLocation | null>(null);
const currentlyRestoringProxyIdsAtom = atom<string[]>([]);

const getProxyStatuses = (): IProxy[] => getDefaultStore().get(proxyStatusesAtom);
const getProxyStatusesProfilesIds = (): string[] => getDefaultStore().get(proxyStatusesProfilesIdsAtom);
const getCurrentlyRestoringProxyIds = (): string[] => getDefaultStore().get(currentlyRestoringProxyIdsAtom);

const setProxyStatuses = (proxies: IProxy[]): void => getDefaultStore().set(proxyStatusesAtom, proxies);
const setProxyStatusesProfilesIds = (profilesIds: string[]): void => getDefaultStore().set(proxyStatusesProfilesIdsAtom, profilesIds);
const setProxyStatusView = (view: ProxyCheckTooltipLocation): void => getDefaultStore().set(proxyStatusViewAtom, view);
const setCurrentlyRestoringProxyIds = (proxyIds: string[]): void => getDefaultStore().set(currentlyRestoringProxyIdsAtom, proxyIds);

const useProxyStatuses = (): IProxy[] => useAtomValue(proxyStatusesAtom);
const useProxyStatusesProfilesIds = (): string[] => useAtomValue(proxyStatusesProfilesIdsAtom);
const useProxyStatusView = (): ProxyCheckTooltipLocation => useAtomValue(proxyStatusViewAtom);
const useCurrentlyRestoringProxyIds = (): string[] => useAtomValue(currentlyRestoringProxyIdsAtom);

export const useIsProxyChecking = (proxy: IProxy, profileId = '', view?: ProxyCheckTooltipLocation): boolean => {
  const proxiesStatuses = useProxyStatuses();
  const proxyStatusesProfilesIds = useProxyStatusesProfilesIds();
  const proxiesStatusView = useProxyStatusView();

  if (!proxy) {
    return false;
  }

  const isProxyChecking = proxiesStatuses.some(proxyChecking => proxy.id === proxyChecking.id);
  const isProfileCurrentOrNoProfile = profileId && proxyStatusesProfilesIds.length ? proxyStatusesProfilesIds.includes(profileId) : true;
  const isCurrentView = proxiesStatusView && view ? proxiesStatusView === view : true;

  return isProxyChecking && isProfileCurrentOrNoProfile && isCurrentView;
};

export const useIsProxyRestoring = (proxyId: string): boolean => {
  const proxyIdRestoreStatuses = useCurrentlyRestoringProxyIds();
  if (!proxyId) {
    return false;
  }

  return proxyIdRestoreStatuses.includes(proxyId);
};

export const addProxyStatuses = (proxies: IProxy[], profileIds: string[] = [], view?: ProxyCheckTooltipLocation): void => {
  const proxyStatuses = getProxyStatuses();
  const proxyStatusesProfileIds = getProxyStatusesProfilesIds();

  const newProxyStatuses = [...proxyStatuses, ...proxies];

  setProxyStatuses(newProxyStatuses);
  if (view) {
    setProxyStatusView(view);
  }

  const profileIdsTruthy = profileIds.filter(Boolean);
  if (profileIdsTruthy.length) {
    const newProxyStatusesProfileIds = [...proxyStatusesProfileIds, ...profileIdsTruthy];
    setProxyStatusesProfilesIds(newProxyStatusesProfileIds);
  }
};

export const removeProxyStatuses = (proxies: IProxy[], profileIds: string[] = []): void => {
  const proxyStatuses = getProxyStatuses();
  const proxyStatusesProfileIds = getProxyStatusesProfilesIds();

  const newProxyStatuses = proxyStatuses
    .filter(proxyStatus => !proxies.some(proxy => proxyStatus.id === proxy.id));

  const newProxyStatusesProfileIds = proxyStatusesProfileIds
    .filter(proxyStatusProfileId => !profileIds.includes(proxyStatusProfileId));

  setProxyStatusView(null);
  setProxyStatuses(newProxyStatuses);
  setProxyStatusesProfilesIds(newProxyStatusesProfileIds);
};

export const resetProxyStatuses = (): void => {
  setProxyStatuses([]);
  setProxyStatusesProfilesIds([]);
  setProxyStatusView(null);
};

export const updateProxyStatuses = async (params: UpdateProxyStatusesParams): Promise<ISetProxyStatusParams|void> => {
  const {
    proxies,
    profileId = '',
    isSharedProxy = false,
    shouldRestoreProxy = true,
    shouldUpdateProxyInList = true,
    isEditable,
    view,
  } = params;

  addProxyStatuses(proxies, [profileId], view);

  const [proxyToCheck] = proxies;

  const { mode, host, port, username, password } = proxyToCheck;

  // TODO: reuse cloned parts (also, used before)
  const statusParams = await getProxyStatusParams({
    ...proxyToCheck,
    mode,
    host,
    port,
    username,
    password,
  }).catch(() => null);

  if (!statusParams) {
    return removeProxyStatuses(proxies, [profileId].filter(Boolean));
  }

  const proxyChecked: IProxy = {
    ...proxyToCheck,
    ...statusParams,
    checkDate: moment().toDate(),
    isInvisible: isSharedProxy,
  };

  const isProxyEditable = getIsProxyEditable(proxyChecked) || isEditable;
  // TODO: remove `shouldUpdateProxyInList` param when `proxiesTableListAtom` is derived from `proxyList`
  if (shouldUpdateProxyInList) {
    if (isProxyEditable) {
      updateProxyItem(proxyChecked);
    } else {
      mapAndSetProfilesList((previousProfiles) => previousProfiles.map((profile) => {
        if (profile.id !== profileId) {
          return profile;
        }

        return { ...profile, proxy: { ...profile.proxy, ...proxyChecked } };
      }));
    }
  }

  const checkParams: ISetProxyStatusParams = {
    id: '',
    status: statusParams.status,
    country: statusParams.country || proxyToCheck?.country,
    city: statusParams.city || proxyToCheck?.city,
    error: statusParams.error,
    checkDate: moment().unix(),
    lastIp: statusParams.origin,
    timezone: statusParams.timezone,
    languages: statusParams.languages,
    shouldRestoreProxy,
  };

  if (statusParams.mode) {
    checkParams.mode = statusParams.mode;
  }

  if (proxyToCheck.id) {
    checkParams.id = proxyToCheck.id;
    if (isSharedProxy) {
      setSharedProxyStatuses([{ ...checkParams, profileId }]).catch(() => null);
    } else if (isProxyEditable) {
      setProxyStatusesRequest([checkParams]).catch(() => null);
    }
  }

  removeProxyStatuses(proxies, [profileId].filter(Boolean));

  return checkParams;
};

export const onProxyRestoreStarted = (proxyIds: string[]): void => {
  const proxyIdsRestoreStatuses = getCurrentlyRestoringProxyIds();
  const newProxyIdsRestoreStatuses = [...proxyIdsRestoreStatuses, ...proxyIds];
  setCurrentlyRestoringProxyIds(newProxyIdsRestoreStatuses);
};

export const onProxyRestoreDone = (proxyIds: string[]): void => {
  const proxyIdRestoreStatuses = getCurrentlyRestoringProxyIds();

  const newProxyIdStatuses = proxyIdRestoreStatuses
    .filter(proxyIdStatus => !proxyIds.some(proxy => proxyIdStatus === proxy));

  setCurrentlyRestoringProxyIds(newProxyIdStatuses);
};

export const resetProxyRestoreStatuses = (): void => setCurrentlyRestoringProxyIds([]);
