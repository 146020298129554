import styled from '@emotion/styled';
import { Input, Radio } from 'antd';

export const ElementContainer = styled('div')`
  margin-top: 30px;
`;

export const TypeRow = styled('div')`
  display: flex;
  flex-direction: row;
  font-size: 12px;
`;

export const RowCenter = styled('div')`
  display: flex;
  align-items: center;
`;

export const RadioButton = styled(Radio.Button)`
  text-align: center;
  font-size: 12px;
`;

export const ContainerNameCol = styled('div')`
  flex-direction: row;
  display: flex;
  align-items: baseline;
`;

export const InputSearch = styled(Input)`
  width: 250px;
  background: var(--FFFFFF);
  border-radius: 4px;
  margin-bottom: 15px;
`;

export const PasteIconContainer = styled('div')`
  padding-top: 3px;
  margin-left: 10px;
  cursor: pointer;
`;

export const BetaLabel = styled('div')`
  padding-left: 3px;
  display: inline-block;
  color: var(--D9D9D9);
`;
