import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { selectItems } from './select-items';
import TIMEZONES from '../../../../../features/common/timezones';
import { IFullProfile, IProxy, ITimezone } from '../../../../../interfaces';
import PerformanceObserverService from '../../../../../services/performance-observer/performance-observer.service';
import { useLocalUserDevice } from '../../../../../state/local-user-device.atom';
import { useProxyList } from '../../../../../state/proxy/proxy-list.atom';
import { ModernSelect } from '../../../../../ui/modern-select';
import { getTimezoneByBasedIp } from '../../../../common/get-data-by-based-ip';
import { ContainerParams, ContainerSpaceBetween, GreyDarkTextContainer } from '../../../styles';

interface ITimezoneComponent {
  timezone: ITimezone;
  changeSetting: (profileData: Partial<IFullProfile>) => void;
  proxy: IProxy;
}

export const Timezone: FC<ITimezoneComponent> = ({ timezone, changeSetting, proxy }) => {
  const localUserDevice = useLocalUserDevice();

  const [data, setData] = useState<string>('');

  const proxyList = useProxyList();

  const { t: translation } = useTranslation();
  const proxyFromCtx = proxyList.find(listedProxy => listedProxy.id === proxy.id);

  const getData = (): void => {
    const timezoneLocation = getTimezoneByBasedIp({
      proxy,
      translation,
      proxyFromCtx,
      localUserDevice,
    });

    setData(timezoneLocation.timezone);
  };

  useEffect(() => {
    getData();
  }, [proxy, proxyFromCtx, localUserDevice.timezone]);

  const onChange = (value: string): void => {
    const performanceObserverService = PerformanceObserverService.getInstance();
    performanceObserverService.handleUserAction({ userAction: 'select-profile-settings-timezone' });

    if (value === 'automatic') {
      changeSetting({ timezone: { fillBasedOnIp: true } });

      return;
    }

    changeSetting({ timezone: { timezone: value, fillBasedOnIp: false } });
  };

  const onSelectOpen = (): void => {
    const performanceObserverService = PerformanceObserverService.getInstance();
    performanceObserverService.handleUserAction({ userAction: 'open-profile-settings-timezone-select' });
  };

  const renderTitleSelect = (): string => {
    const { name, offset } = TIMEZONES
      .find(({ name: nameFromList }) => timezone.fillBasedOnIp ? nameFromList === data : nameFromList === timezone.timezone)
    || { name: data, offset: '' };

    let title = name.replace('/', ', ').trim();

    if (offset) {
      title = offset + ' ' + title;
    }

    return title;
  };

  const renderTimezone = (): JSX.Element => (
    <ModernSelect
      title={renderTitleSelect()}
      currentValue={timezone.fillBasedOnIp ? 'automatic' : timezone.timezone || 'Choose region'}
      itemList={selectItems(translation)}
      onSelected={onChange}
      hasSearch
      popoverMaxHeight='317px'
      popoverWidth='303px'
      maxWidth='170px'
      onSelectOpen={onSelectOpen}
    />
  );

  const renderDescription = (): string => {
    if (timezone.fillBasedOnIp) {
      return translation('quickSettings.parameters.basedIP');
    }

    return translation('quickSettings.parameters.manual');
  };

  return (
    <ContainerParams>
      <ContainerSpaceBetween style={{ justifyContent: 'space-between', width: 'inherit', overflow: 'visible' }}>
        {renderTimezone()}
        <GreyDarkTextContainer>
          {renderDescription()}
        </GreyDarkTextContainer>
      </ContainerSpaceBetween>
    </ContainerParams>
  );
};
