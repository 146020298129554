import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { resetProfilesList } from '../profiles-list.atom';

export const selectedTagIdAtom = atom<string|null>(null);

export const getSelectedTagId = (): string|null => getDefaultStore().get(selectedTagIdAtom);
export const useSelectedTagId = (): string|null => useAtomValue(selectedTagIdAtom);
export const setSelectedTagId = (newSelectedTagId: string|null): void => {
  const prevSelectedTagId = getSelectedTagId();
  if (prevSelectedTagId === newSelectedTagId) {
    return;
  }

  resetProfilesList();
  getDefaultStore().set(selectedTagIdAtom, newSelectedTagId);
};
