import React, { FC, useState } from 'react';

import { Mixed } from './mixed';
import { ExtensionsModal } from '../../../../../features/modalsComponents/components/extensions-modal/extensions-modal';
import { IProfile } from '../../../../../interfaces';
import { useProfilesTableSelectedIds } from '../../../../../state/profiles-table-selected-ids.atom';

interface IMixedExtensions {
  changeProfilesDataMany: (profileIds: string[], newProfilesData: Partial<IProfile>) => void;
}

export const MixedExtensions: FC<IMixedExtensions> = (props): JSX.Element => {
  const { changeProfilesDataMany } = props;

  const selectedProfileIds = useProfilesTableSelectedIds();

  const [extensionsModalVisible, setExtensionsModalVisible] = useState<boolean>(false);

  return (
    <>
      <div onClick={(): void => setExtensionsModalVisible(true)}>
        <Mixed colorText='var(--2B2B31-profile-new-settings)' />
      </div>
      <ExtensionsModal
        extensionsModalVisible={extensionsModalVisible}
        setExtensionsModalVisible={setExtensionsModalVisible}
        profileIds={selectedProfileIds}
        updateProfileInfo={(newProfileData: Partial<IProfile>): void => changeProfilesDataMany(selectedProfileIds, newProfileData)}
      />
    </>
  );
};
