import React from 'react';

const IconAddFolder = () => (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.35533 4.25725V4.35725H7.45533H8.183H8.54611H12.5447C12.8711 4.35725 13.1723 4.69564 13.1723 5.16123V7.41849V7.51849H13.2723H14H14.1V7.41849V4.71014C14.1 3.92864 13.5854 3.25507 12.9092 3.25507H8.27729C8.23563 2.51698 7.73651 1.9 7.08933 1.9H3.09078C2.41465 1.9 1.9 2.57168 1.9 3.35507V4.71014V6.06521V14.6449C1.9 15.4264 2.41457 16.1 3.09078 16.1H10.3617H10.4617V16V15.096V14.996H10.3617H3.45389C3.12756 14.996 2.82767 14.6578 2.82767 14.1938V9.22644V7.33581V6.06341V5.15943V3.80616C2.82767 3.34208 3.12767 3.00218 3.45533 3.00218H6.72767C7.05411 3.00218 7.35533 3.34057 7.35533 3.80616V4.25725Z" fill="var(--359D73)" stroke="var(--359D73)" strokeWidth="0.2"/>
    <path d="M18.1 12.5024C18.1022 12.1699 17.8341 11.9023 17.5024 11.9023H15.1006V9.49972C15.1006 9.16778 14.8329 8.9 14.501 8.9C14.1688 8.9 13.9014 9.17003 13.9014 9.49972V11.9003H11.4996C11.1674 11.9003 10.9 12.1703 10.9 12.5C10.9 12.8319 11.1677 13.0997 11.4996 13.0997H13.9014V15.5003C13.9014 15.8322 14.1691 16.1 14.501 16.1C14.8329 16.1 15.1006 15.8322 15.1006 15.5003V13.1017H17.5004C17.8322 13.1017 18.0998 12.8341 18.1 12.5024ZM18.1 12.5024C18.1 12.5025 18.1 12.5026 18.1 12.5027L18 12.502H18.1C18.1 12.5021 18.1 12.5023 18.1 12.5024ZM15.0006 13.0017H15.1006L15.0006 13.1017V13.0017ZM14.0014 12.9997V13.0997L13.9014 12.9997H14.0014ZM15.1006 12.0023H15.0006V11.9023L15.1006 12.0023Z" fill="var(--359D73)" stroke="var(--359D73)" strokeWidth="0.2"/>
    </svg>

);

export default IconAddFolder;
