import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { PROXY_CHECK_TOOLTIP_LOCATIONS } from './proxy/proxy-check/constants';

const PROXY_CONTEXT_MENU_PROXY_LOCATIONS = [
  PROXY_CHECK_TOOLTIP_LOCATIONS.proxyUngroupedListItem,
  PROXY_CHECK_TOOLTIP_LOCATIONS.proxyGroupedListItem,
  null,
];

export type ProxyContextMenuProxyLocation = typeof PROXY_CONTEXT_MENU_PROXY_LOCATIONS[number];

interface IPositionProxySelectMenuStatus {
  x: number;
  y: number;
}

const defaultPositionValue: IPositionProxySelectMenuStatus = {
  x: 0,
  y: 0,
};

const isProxyContextMenuVisible = atom(false);
const isSelectProxyModeOpenedAtom = atom(false);
const confirmBlockVisibleAtom = atom(false);
const proxySelectMenuPositionAtom = atom(defaultPositionValue);
// TODO: remove when the anti-pattern in `src/app/state/proxy/proxy-check/constants.ts` is solved
const proxyContextMenuProxyLocationAtom = atom<ProxyContextMenuProxyLocation>(null);

const setIsProxyContextMenuVisible = (value: boolean): void => getDefaultStore().set(isProxyContextMenuVisible, value);
const setIsSelectProxyModeOpened = (value: boolean): void => getDefaultStore().set(isSelectProxyModeOpenedAtom, value);
const setConfirmBlockVisible = (value: boolean): void => getDefaultStore().set(confirmBlockVisibleAtom, value);
const setProxySelectMenuPosition = (value: IPositionProxySelectMenuStatus): void => getDefaultStore().set(proxySelectMenuPositionAtom, value);
const setProxyContextMenuProxyLocation = (value: ProxyContextMenuProxyLocation): void =>
  getDefaultStore().set(proxyContextMenuProxyLocationAtom, value);

export const useIsProxyContextMenuVisible = (): boolean => useAtomValue(isProxyContextMenuVisible);

export const useIsSelectProxyModeOpened = (): boolean => useAtomValue(isSelectProxyModeOpenedAtom);

export const useConfirmBlockVisible = (): boolean => useAtomValue(confirmBlockVisibleAtom);

export const useProxySelectMenuPosition = (): IPositionProxySelectMenuStatus => useAtomValue(proxySelectMenuPositionAtom);

export const useProxyContextMenuProxyLocation = (): ProxyContextMenuProxyLocation => useAtomValue(proxyContextMenuProxyLocationAtom);

export const openProxyContextMenu = (proxyContextMenuProxyLocation: ProxyContextMenuProxyLocation): void => {
  setIsProxyContextMenuVisible(true);
  setProxyContextMenuProxyLocation(proxyContextMenuProxyLocation);
};

export const hideProxyContextMenu = (): void => {
  setIsProxyContextMenuVisible(false);
  setProxyContextMenuProxyLocation(null);
};

export const switchIsSelectProxyModeOpened = (value: boolean): void => {
  setIsSelectProxyModeOpened(value);
};

export const switchConfirmBlockVisible = (value: boolean): void => {
  setConfirmBlockVisible(value);
};

export const updateProxySelectMenuPosition = (value: IPositionProxySelectMenuStatus): void => {
  setProxySelectMenuPosition(value);
};
