import React, { FC } from 'react';

import PerformanceObserverService from '../../../../services/performance-observer/performance-observer.service';
import { ModernSelect } from '../../../../ui/modern-select';
import { ISelectItem } from '../../../../ui/modern-select/interfaces';
import { ANDROID_RESOLUTIONS, RESOLUTIONS } from '../../../common/all-resolutions';
import { IChangeSetting } from '../../interfaces';
import { ContainerSpaceBetween, ContainerParams } from '../../styles';

interface IResolution extends IChangeSetting {
  selectedResolution: typeof RESOLUTIONS[number]|typeof ANDROID_RESOLUTIONS[number];
  isAndroidProfile: boolean;
}

export const Resolution: FC<IResolution> = ({ changeSetting, selectedResolution, isAndroidProfile }) => {
  const resolutionsList = isAndroidProfile ? ANDROID_RESOLUTIONS : RESOLUTIONS;
  const selectItems: ISelectItem[] = resolutionsList.map(resolution => ({
    title: resolution,
    value: resolution,
  }));

  const onSelect = (value: string): void => {
    const performanceObserverService = PerformanceObserverService.getInstance();
    performanceObserverService.handleUserAction({ userAction: 'select-profile-settings-resolution' });

    changeSetting({
      navigator: {
        resolution: value as typeof ANDROID_RESOLUTIONS[number]|typeof RESOLUTIONS[number],
      },
    });
  };

  const onSelectOpen = (): void => {
    const performanceObserverService = PerformanceObserverService.getInstance();
    performanceObserverService.handleUserAction({ userAction: 'open-profile-settings-resolution-select' });
  };

  return (
    <ContainerParams>
      <ContainerSpaceBetween>
        <ModernSelect
          title={selectedResolution}
          currentValue={selectedResolution}
          itemList={selectItems}
          onSelected={onSelect}
          popoverWidth='140px'
          popoverMaxHeight='206px'
          onSelectOpen={onSelectOpen}
        />
      </ContainerSpaceBetween>
    </ContainerParams>
  );
};
