import React from 'react';

import { Wrapper } from './styles';
import { IconSpinner } from '../gologin-header/icons';

type LoadingSpinnerProps = {
  style?: React.CSSProperties;
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ style }) => (
  <Wrapper style={style}>
    <IconSpinner size={40} padding={0} />
  </Wrapper>
);
