import React from 'react';
import { useTranslation } from 'react-i18next';

import TooltipCustom from '../../../../../ui/tooltip-custom';
import { BottomContainer, Description, ReadMore, TooltipContainer } from './styles';
import { IconCookie } from '../../../../../ui/gologin-header/icons/icon-cookie';
import { IconFloppyDisk } from '../../../../../ui/gologin-header/icons/icon-floppy-disk';
import { openUrl } from '../../../../../utils/open-site';

type WorkflowParamsTitleTooltipProps = {
  anchorElTooltip: HTMLElement | null;
  onMouseEnter: React.MouseEventHandler<HTMLElement>;
  onMouseLeave: React.MouseEventHandler<HTMLElement>;
};

const WorkflowParamsTitleTooltip: React.FC<WorkflowParamsTitleTooltipProps> = (props: WorkflowParamsTitleTooltipProps) => {
  const { anchorElTooltip, onMouseEnter, onMouseLeave } = props;

  const { t: translation } = useTranslation();

  return (
    <TooltipCustom
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      isTooltipHoverable={true}
      style={{ maxWidth: 236 }}
      value={
      <TooltipContainer>
        <Description>
          {translation('cookiebotModal.params.title.tooltip')}
        </Description>
        <BottomContainer>
          <IconCookie padding={0} />
          <IconFloppyDisk padding={0} />
          <ReadMore onClick={() => {
            openUrl('https://support.gologin.com/en/articles/10809377-cookie-bot');
          }}>
            {translation('cookiebotModal.title.tooltip.readMore')}
          </ReadMore>
        </BottomContainer>
      </TooltipContainer>
      }
      anchorEl={anchorElTooltip}
    />
  );
};

export default WorkflowParamsTitleTooltip;
