import { Button, Divider, message } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { parseProfiles } from './parse-profile';
import {
  DrugAndDropBrowserContainer,
  Hint,
  IconDiv,
  TemplateLink,
  TextArea,
  BoldTextSpan,
  TextAreaDiv,
  ImportButtonDiv,
} from './styles';
import { IconSpinner } from '../../../../ui/gologin-header/icons';
import IconImportProfileUpload from '../../../../ui/icons/IconImportProfileUpload';
import { ARRAY_OF_ALL_PROPERTIES } from '../../../../utils/longVariables';

const DEFAULT_BORDER_COLOR = 'var(--D9D9D9)';
const isElectron = !!window.require;

declare interface IBrowserManager {
  toggleProfileImportModalVisible: (arg: boolean) => void;
  setProfilesToImport: (arg: any) => void;
  changeCurrentWindow: (arg: 'loader'|'resultList'|'confirmation') => void;
  profileImportModalVisible: boolean;
  setHeaderForImport: (arg: string) => void;
}

const ProfilesImportLoader: FC<IBrowserManager> = (props) => {
  const [profileImportTxt, setProfileImportTxt] = useState<string>('');
  const [isProcessing, changeProcessing] = useState<boolean>(false);
  const [borderColor, setBorderColor] = useState<string>(DEFAULT_BORDER_COLOR);

  const { t: translation } = useTranslation();

  const onDragOver = (e: any): void => {
    e.stopPropagation();
    e.preventDefault();
  };

  useEffect(() => {
    setProfileImportTxt('');
  }, [props.profileImportModalVisible]);

  const handleUploadDrop = (e: any): void => {
    onDragOver(e);
    const files = e.dataTransfer.files;
    parseFiles(files);
  };

  const checkIfTextIsValid = (text: string): boolean => ARRAY_OF_ALL_PROPERTIES.some(property => text.includes(property));

  const parseFiles = (files: any): void => {
    changeProcessing(true);

    for (const file of files) {
      const allowedFileType = file.type === 'text/csv' || file.type.match(/^text*/) || file.name.match(/.csv$/);

      if (!allowedFileType) {
        message.error(translation('importProfile.pasteFile.invalidFormat'));
        return;
      }

      const reader: any = new FileReader();
      reader.readAsText(file);

      reader.onload = () => {
        setProfileImportTxt(reader.result);
        const textIsValid = checkIfTextIsValid(reader.result);

        changeProcessing(false);

        if(!textIsValid) {
          message.error(translation('importProfile.pasteFile.wrongFile'));
          setProfileImportTxt('');

          return;
        }

        importProfiles(reader.result);
      };

      reader.onerror = () => {
        message.error(reader.error);
      };
    }
  };

  const getLoader = () => {
    if (!isProcessing) {
      return null;
    }

    return (
      <IconSpinner size={14} padding={0} />
    );
  };

  const hiddenFileInput = React.useRef(null);

  const handleUploadClick = (): void => {
    // @ts-ignore
    hiddenFileInput.current.click();
  };

  const handleUploadChange = (event: any): void => {
    const filesUploaded = event.target.files;
    parseFiles(filesUploaded);
  };

  const handleTyping = (e: any): void => {
    const txt = e.target.value;
    setProfileImportTxt(e.target.value);
    setBorderColor('var(--FF0000)');

    const textIsValid = checkIfTextIsValid(txt);

    if (textIsValid) {
      setBorderColor(DEFAULT_BORDER_COLOR);
    }
  };

  const getOrSign = () => (
      <h3 style={{ color: DEFAULT_BORDER_COLOR, fontWeight: 'normal', fontSize: 16 }}>{translation('base.or')}</h3>
  );

  const getTextArea = () => (
      <TextArea
        value={profileImportTxt}
        onChange={handleTyping}
        placeholder={translation('importProfileModal.textField.placeholder')}
        style={{ borderColor: borderColor }}
      />
  );

  const openLink = (): void => {
    const link = 'https://support.gologin.com/en/articles/5771836-profile-import-export';
    if (isElectron) {
      window.require('electron').shell.openExternal(link);
      return;
    }

    window.open(link);
  };

  const importProfiles = (profilesTxt: string) => {
    const result: any = parseProfiles(profilesTxt);
    props.setProfilesToImport(result.arrayWithFinalProfiles);
    props.setHeaderForImport(result.header);
    props.changeCurrentWindow('confirmation');
  };

  const getImportButton = () => {
    if (!profileImportTxt && isProcessing) {
      return null;
    }

    return (
      <Button
        type='primary'
        onClick={() => importProfiles(profileImportTxt)}
        style={{ marginLeft: 'auto' }}
      >
        {translation('base.import')}
      </Button>
    );
  };

  return (
    <div>
      <Hint>
        {translation('importProfileModal.help')} <TemplateLink onClick={openLink}>{translation('importProfileModal.link.label')?.toLowerCase()}.</TemplateLink>
      </Hint>
      <DrugAndDropBrowserContainer
        onClick={handleUploadClick}
        onDrop={handleUploadDrop}
        onDragOver={onDragOver}
      >
        <input
          type='file'
          ref={hiddenFileInput}
          onChange={handleUploadChange}
          style={{ display:'none' }}
        />
        <IconDiv>
          <IconImportProfileUpload />
        </IconDiv>
        <div style={{ fontWeight: 400 }}>
          <BoldTextSpan>{translation('importProfileModal.dragNDropField.title')}</BoldTextSpan> {translation('importProfileModal.dragNDropField.subtitle')?.toLowerCase()}
        </div>
      </DrugAndDropBrowserContainer>
      <TextAreaDiv>
        {getOrSign()}
        {getTextArea()}
        {getLoader()}
      </TextAreaDiv>
      <TemplateLink href={'./export_template.csv'} >{translation('importProfileModal.template.label')}</TemplateLink>
      <Divider style={{ marginTop: 5, marginBottom: 5 }} />
      <ImportButtonDiv>
        {getImportButton()}
      </ImportButtonDiv>
    </div>
  );
};

export default ProfilesImportLoader;
