import { OsForBrowserIcons } from '../types';

export const formatOs = (os: string, winVersionMajor: number): OsForBrowserIcons|null => {
  if (os === 'darwin') {
    return 'mac';
  }

  if (os === 'linux') {
    return 'linux';
  }

  if (winVersionMajor === 10 || winVersionMajor === 11) {
    return `win${winVersionMajor}`;
  }

  return null;
};
