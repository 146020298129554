import React from 'react';

const IconRename = () => (
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 2.5L13 5.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12 0.5L15 3.5L5 13.5L1 14.5L2 10.5L12 0.5Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default IconRename;
