import { atom, getDefaultStore } from 'jotai';

type OrbitaVersion = string;

const NULL_ORBITA_VERSION: OrbitaVersion = '0.0.0';

const latestLocalOrbitaVersionAtom = atom<OrbitaVersion>(NULL_ORBITA_VERSION);

export const getLatestLocalOrbitaVersion = (): OrbitaVersion => getDefaultStore().get(latestLocalOrbitaVersionAtom);
export const setLatestLocalOrbitaVersion = (newLatestLocalOrbitaVersion: OrbitaVersion): void =>
  getDefaultStore().set(latestLocalOrbitaVersionAtom, newLatestLocalOrbitaVersion);
