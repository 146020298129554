import styled from '@emotion/styled';

import { highlight } from './icons/wrapper';

const BackgroundHighlight = styled.div<{ highlight?: boolean }>`
  transition: background-color 0.05s ease-in-out, color 0.05s ease-in-out, stroke 0.05s ease-in-out;
  border-radius: 8px;

  &:hover {
    ${props => props.highlight ? highlight : ''};
  }
`;

export default BackgroundHighlight;
