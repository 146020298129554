import React, { useEffect, useState } from 'react';

import { RedirectToDesktopPage } from '../../../common/components/redirect-to-desktop-page';
import { authWithProvider } from '../api';
import { ProviderType } from '../types';
import { parseGoogleAuthMetadata, redirectToDesktop } from '../utils';

const OAuthRedirectToDesktopPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);

  const params = new URLSearchParams(location.search);
  const code = params.get('code');
  const provider = params.get('provider');
  const metadata = params.get('state') || '';

  const { appId = '', gologinMetaHeader = '' } = parseGoogleAuthMetadata(metadata);

  const handleSuccessAuth = async (): Promise<void> => {
    if (!(code && provider && ['google', 'facebook'].includes(provider))) {
      return;
    }

    const accountAuthorised = await authWithProvider({
      credential: code,
      provider: provider as ProviderType,
      isFromDesktop: true,
      gologinMetaHeader,
      appId,
    });

    if (!accountAuthorised?.token) {
      return;
    }

    setIsLoading(false);
    const { token: accessToken = '', isNewUser } = accountAuthorised;
    sessionStorage.setItem('access_token', accessToken);

    redirectToDesktop({ accessToken, isNewUser });
  };

  useEffect(() => {
    handleSuccessAuth();
  }, []);

  const handleClick: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();

    const accessToken = sessionStorage.getItem('access_token') || '';
    const isNewUser = !!sessionStorage.getItem('is_new_user');

    redirectToDesktop({ accessToken, isNewUser });
  };

  return <RedirectToDesktopPage isLoading={isLoading} handleClick={handleClick} />;
};

export default OAuthRedirectToDesktopPage;
