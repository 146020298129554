import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalButton, ModalButtonConfirm, ModalCloseContainer, ModalDescription, ModalTitle } from './styles';
import { IconClose } from '../../../../../ui/gologin-header/icons';
import GologinModal from '../../../../../ui/gologin-modal';

interface IModalCancelConfirm {
  onConfirm: () => void;
  visible: boolean;
  onClose: () => void;
}

const ModalCancelConfirm: FC<IModalCancelConfirm> = (props) => {
  const { onConfirm, visible, onClose } = props;
  const { t: translation } = useTranslation();

  return (
    <GologinModal
      isVisible={visible}
      onClose={onClose}
      width='554px'
      bodyStyle={{ height: 208 }}
    >
      <ModalCloseContainer>
        <IconClose
          padding={0}
          iconColor='var(--00000073-antd)'
          onClick={onClose}
        />
      </ModalCloseContainer>
      <ModalTitle>
        {translation('modals.cancelRenameProfile.title')}
      </ModalTitle>
      <ModalDescription>
        {translation('modals.cancelRenameProfile.description')}
      </ModalDescription>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ModalButton style={{ marginRight: 12 }} onClick={onConfirm}>
          {translation('base.cancel')}
        </ModalButton>
        <ModalButtonConfirm style={{ backgroundColor: 'var(--00997A)' }} onClick={onClose}>
          {translation('modals.cancelRenameProfile.continue')}
        </ModalButtonConfirm>
      </div>
    </GologinModal>
  );
};

export default ModalCancelConfirm;
