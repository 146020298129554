export default [
  { name: 'Afghanistan', code: 'AF', nameRus: 'Афганистан' },
  { name: 'Åland Islands', code: 'AX', nameRus: 'Аландские острова' },
  { name: 'Albania', code: 'AL', nameRus: 'Албания' },
  { name: 'Algeria', code: 'DZ', nameRus: 'Алжир' },
  { name: 'American Samoa', code: 'AS', nameRus: 'Американское Самоа' },
  { name: 'Andorra', code: 'AD', nameRus: 'Андорра' },
  { name: 'Angola', code: 'AO', nameRus: 'Ангола' },
  { name: 'Anguilla', code: 'AI', nameRus: 'Ангилья' },
  { name: 'Antarctica', code: 'AQ', nameRus: 'Антарктида' },
  { name: 'Antigua and Barbuda', code: 'AG', nameRus: 'Антигуа и Барбуда' },
  { name: 'Argentina', code: 'AR', nameRus: 'Аргентина' },
  { name: 'Armenia', code: 'AM', nameRus: 'Армения' },
  { name: 'Aruba', code: 'AW', nameRus: 'Аруба' },
  { name: 'Australia', code: 'AU', nameRus: 'Австралия' },
  { name: 'Austria', code: 'AT', nameRus: 'Австрия' },
  { name: 'Azerbaijan', code: 'AZ', nameRus: 'Азербайджан' },
  { name: 'Bahamas', code: 'BS', nameRus: 'Багамы' },
  { name: 'Bahrain', code: 'BH', nameRus: 'Бахрейн' },
  { name: 'Bangladesh', code: 'BD', nameRus: 'Бангладеш' },
  { name: 'Barbados', code: 'BB', nameRus: 'Барбадос' },
  { name: 'Belarus', code: 'BY', nameRus: 'Беларусь' },
  { name: 'Belgium', code: 'BE', nameRus: 'Бельгия' },
  { name: 'Belize', code: 'BZ', nameRus: 'Белиз' },
  { name: 'Benin', code: 'BJ', nameRus: 'Бенин' },
  { name: 'Bermuda', code: 'BM', nameRus: 'Бермуды' },
  { name: 'Bhutan', code: 'BT', nameRus: 'Бутан' },
  { name: 'Bolivia', code: 'BO', nameRus: 'Боливия' },
  { name: 'Bonaire', code: 'BQ', nameRus: 'Бонэйр' },
  { name: 'Bosnia and Herzegovina', code: 'BA', nameRus: 'Босния и Герцеговина' },
  { name: 'Botswana', code: 'BW', nameRus: 'Ботсвана' },
  { name: 'Bouvet Island', code: 'BV', nameRus: 'Остров Буве' },
  { name: 'Brazil', code: 'BR', nameRus: 'Бразилия' },
  { name: 'British Indian Ocean Territory', code: 'IO', nameRus: 'Британская территория в Индийском океане' },
  { name: 'Brunei Darussalam', code: 'BN', nameRus: 'Бруней-Даруссалам' },
  { name: 'Bulgaria', code: 'BG', nameRus: 'Болгария' },
  { name: 'Burkina Faso', code: 'BF', nameRus: 'Буркина-Фасо' },
  { name: 'Burundi', code: 'BI', nameRus: 'Бурунди' },
  { name: 'Cambodia', code: 'KH', nameRus: 'Камбоджа' },
  { name: 'Cameroon', code: 'CM', nameRus: 'Камерун' },
  { name: 'Canada', code: 'CA', nameRus: 'Канада' },
  { name: 'Cape Verde', code: 'CV', nameRus: 'Кабо-Верде' },
  { name: 'Cayman Islands', code: 'KY', nameRus: 'Каймановы острова' },
  { name: 'Central African Republic', code: 'CF', nameRus: 'Центральноафриканская Республика' },
  { name: 'Chad', code: 'TD', nameRus: 'Чад' },
  { name: 'Chile', code: 'CL', nameRus: 'Чили' },
  { name: 'China', code: 'CN', nameRus: 'Китай' },
  { name: 'Christmas Island', code: 'CX', nameRus: 'Остров Рождества' },
  { name: 'Cocos (Keeling) Islands', code: 'CC', nameRus: 'Кокосовые (Килинг) острова' },
  { name: 'Colombia', code: 'CO', nameRus: 'Колумбия' },
  { name: 'Comoros', code: 'KM', nameRus: 'Коморские острова' },
  { name: 'Congo', code: 'CG', nameRus: 'Конго' },
  { name: 'Congo, The Democratic Republic of the', code: 'CD', nameRus: 'Конго, Демократическая Республика' },
  { name: 'Cook Islands', code: 'CK', nameRus: 'Острова Кука' },
  { name: 'Costa Rica', code: 'CR', nameRus: 'Коста-Рика' },
  { name: 'Cote D\'Ivoire', code: 'CI', nameRus: 'Кот-д\'Ивуар' },
  { name: 'Croatia', code: 'HR', nameRus: 'Хорватия' },
  { name: 'Cuba', code: 'CU', nameRus: 'Куба' },
  { name: 'Curaçao', code: 'CW', nameRus: 'Кюрасао' },
  { name: 'Cyprus', code: 'CY', nameRus: 'Кипр' },
  { name: 'Czech Republic', code: 'CZ', nameRus: 'Чехия' },
  { name: 'Denmark', code: 'DK', nameRus: 'Дания' },
  { name: 'Djibouti', code: 'DJ', nameRus: 'Джибути' },
  { name: 'Dominica', code: 'DM', nameRus: 'Доминика' },
  { name: 'Dominican Republic', code: 'DO', nameRus: 'Доминиканская Республика' },
  { name: 'Ecuador', code: 'EC', nameRus: 'Эквадор' },
  { name: 'Egypt', code: 'EG', nameRus: 'Египет' },
  { name: 'El Salvador', code: 'SV', nameRus: 'Сальвадор' },
  { name: 'Equatorial Guinea', code: 'GQ', nameRus: 'Экваториальная Гвинея' },
  { name: 'Eritrea', code: 'ER', nameRus: 'Эритрея' },
  { name: 'Estonia', code: 'EE', nameRus: 'Эстония' },
  { name: 'Ethiopia', code: 'ET', nameRus: 'Эфиопия' },
  { name: 'Europa', code: 'EU', nameRus: 'Европейские страны' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK', nameRus: 'Фолклендские (Мальвинские) острова' },
  { name: 'Faroe Islands', code: 'FO', nameRus: 'Фарерские острова' },
  { name: 'Fiji', code: 'FJ', nameRus: 'Фиджи' },
  { name: 'Finland', code: 'FI', nameRus: 'Финляндия' },
  { name: 'France', code: 'FR', nameRus: 'Франция' },
  { name: 'French Guiana', code: 'GF', nameRus: 'Французская Гвиана' },
  { name: 'French Polynesia', code: 'PF', nameRus: 'Французская Полинезия' },
  { name: 'French Southern Territories', code: 'TF', nameRus: 'Южные территории Франции' },
  { name: 'Gabon', code: 'GA', nameRus: 'Габон' },
  { name: 'Gambia', code: 'GM', nameRus: 'Гамбия' },
  { name: 'Georgia', code: 'GE', nameRus: 'Грузия' },
  { name: 'Germany', code: 'DE', nameRus: 'Германия' },
  { name: 'Ghana', code: 'GH', nameRus: 'Гана' },
  { name: 'Gibraltar', code: 'GI', nameRus: 'Гибралтар' },
  { name: 'Greece', code: 'GR', nameRus: 'Греция' },
  { name: 'Greenland', code: 'GL', nameRus: 'Гренландия' },
  { name: 'Grenada', code: 'GD', nameRus: 'Гренада' },
  { name: 'Guadeloupe', code: 'GP', nameRus: 'Гваделупа' },
  { name: 'Guam', code: 'GU', nameRus: 'Гуам' },
  { name: 'Guatemala', code: 'GT', nameRus: 'Гватемала' },
  { name: 'Guernsey', code: 'GG', nameRus: 'Гернси' },
  { name: 'Guinea', code: 'GN', nameRus: 'Гвинея' },
  { name: 'Guinea-Bissau', code: 'GW', nameRus: 'Гвинея-Бисау' },
  { name: 'Guyana', code: 'GY', nameRus: 'Гайана' },
  { name: 'Haiti', code: 'HT', nameRus: 'Гаити' },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM', nameRus: 'Остров Херд и острова Макдональд' },
  { name: 'Holy See (Vatican City State)', code: 'VA', nameRus: 'Ватикан' },
  { name: 'Honduras', code: 'HN', nameRus: 'Гондурас' },
  { name: 'Hong Kong', code: 'HK', nameRus: 'Гонконг' },
  { name: 'Hungary', code: 'HU', nameRus: 'Венгрия' },
  { name: 'Iceland', code: 'IS', nameRus: 'Исландия' },
  { name: 'India', code: 'IN', nameRus: 'Индия' },
  { name: 'Indonesia', code: 'ID', nameRus: 'Индонезия' },
  { name: 'Iran, Islamic Republic Of', code: 'IR', nameRus: 'Иран, Исламская Республика' },
  { name: 'Iraq', code: 'IQ', nameRus: 'Ирак' },
  { name: 'Ireland', code: 'IE', nameRus: 'Ирландия' },
  { name: 'Isle of Man', code: 'IM', nameRus: 'Остров Мэн' },
  { name: 'Israel', code: 'IL', nameRus: 'Израиль' },
  { name: 'Italy', code: 'IT', nameRus: 'Италия' },
  { name: 'Jamaica', code: 'JM', nameRus: 'Ямайка' },
  { name: 'Japan', code: 'JP', nameRus: 'Япония' },
  { name: 'Jersey', code: 'JE', nameRus: 'Джерси' },
  { name: 'Jordan', code: 'JO', nameRus: 'Иордания' },
  { name: 'Kazakhstan', code: 'KZ', nameRus: 'Казахстан' },
  { name: 'Kenya', code: 'KE', nameRus: 'Кения' },
  { name: 'Kiribati', code: 'KI', nameRus: 'Кирибати' },
  { name: 'Democratic People\'s Republic of Korea', code: 'KP', nameRus: 'Корейская Народно-Демократическая Республика' },
  { name: 'Korea, Republic of', code: 'KR', nameRus: 'Корея, Республика' },
  { name: 'Kosovo', code: 'XK', nameRus: 'Косово' },
  { name: 'Kuwait', code: 'KW', nameRus: 'Кувейт' },
  { name: 'Kyrgyzstan', code: 'KG', nameRus: 'Кыргызстан' },
  { name: 'Lao People\'s Democratic Republic', code: 'LA', nameRus: 'Лаосская Народно-Демократическая Республика' },
  { name: 'Latvia', code: 'LV', nameRus: 'Латвия' },
  { name: 'Lebanon', code: 'LB', nameRus: 'Ливан' },
  { name: 'Lesotho', code: 'LS', nameRus: 'Лесото' },
  { name: 'Liberia', code: 'LR', nameRus: 'Либерия' },
  { name: 'Libyan Arab Jamahiriya', code: 'LY', nameRus: 'Ливийская Арабская Джамахирия' },
  { name: 'Liechtenstein', code: 'LI', nameRus: 'Лихтенштейн' },
  { name: 'Lithuania', code: 'LT', nameRus: 'Литва' },
  { name: 'Luxembourg', code: 'LU', nameRus: 'Люксембург' },
  { name: 'Macao', code: 'MO', nameRus: 'Макао' },
  { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK', nameRus: 'Македония, Бывшая Югославская Республика' },
  { name: 'Madagascar', code: 'MG', nameRus: 'Мадагаскар' },
  { name: 'Malawi', code: 'MW', nameRus: 'Малави' },
  { name: 'Malaysia', code: 'MY', nameRus: 'Малайзия' },
  { name: 'Maldives', code: 'MV', nameRus: 'Мальдивы' },
  { name: 'Mali', code: 'ML', nameRus: 'Мали' },
  { name: 'Malta', code: 'MT', nameRus: 'Мальта' },
  { name: 'Marshall Islands', code: 'MH', nameRus: 'Маршалловы Острова' },
  { name: 'Martinique', code: 'MQ', nameRus: 'Мартиника' },
  { name: 'Mauritania', code: 'MR', nameRus: 'Мавритания' },
  { name: 'Mauritius', code: 'MU', nameRus: 'Маврикий' },
  { name: 'Mayotte', code: 'YT', nameRus: 'Майотта' },
  { name: 'Mexico', code: 'MX', nameRus: 'Мексика' },
  { name: 'Micronesia, Federated States of', code: 'FM', nameRus: 'Микронезия, Федеративные Штаты' },
  { name: 'Moldova, Republic of', code: 'MD', nameRus: 'Молдова, Республика' },
  { name: 'Monaco', code: 'MC', nameRus: 'Монако' },
  { name: 'Mongolia', code: 'MN', nameRus: 'Монголия' },
  { name: 'Montenegro', code: 'ME', nameRus: 'Черногория' },
  { name: 'Montserrat', code: 'MS', nameRus: 'Монтсеррат' },
  { name: 'Morocco', code: 'MA', nameRus: 'Марокко' },
  { name: 'Mozambique', code: 'MZ', nameRus: 'Мозамбик' },
  { name: 'Myanmar', code: 'MM', nameRus: 'Мьянма' },
  { name: 'Namibia', code: 'NA', nameRus: 'Намибия' },
  { name: 'Nauru', code: 'NR', nameRus: 'Науру' },
  { name: 'Nepal', code: 'NP', nameRus: 'Непал' },
  { name: 'Netherlands', code: 'NL', nameRus: 'Нидерланды' },
  { name: 'Netherlands Antilles', code: 'AN', nameRus: 'Нидерландские Антильские острова' },
  { name: 'New Caledonia', code: 'NC', nameRus: 'Новая Каледония' },
  { name: 'New Zealand', code: 'NZ', nameRus: 'Новая Зеландия' },
  { name: 'Nicaragua', code: 'NI', nameRus: 'Никарагуа' },
  { name: 'Niger', code: 'NE', nameRus: 'Нигер' },
  { name: 'Nigeria', code: 'NG', nameRus: 'Нигерия' },
  { name: 'Niue', code: 'NU', nameRus: 'Ниуэ' },
  { name: 'Norfolk Island', code: 'NF', nameRus: 'Остров Норфолк' },
  { name: 'Northern Mariana Islands', code: 'MP', nameRus: 'Северные Марианские острова' },
  { name: 'Norway', code: 'NO', nameRus: 'Норвегия' },
  { name: 'Oman', code: 'OM', nameRus: 'Оман' },
  { name: 'Pakistan', code: 'PK', nameRus: 'Пакистан' },
  { name: 'Palau', code: 'PW', nameRus: 'Палау' },
  { name: 'Palestinian Territory, Occupied', code: 'PS', nameRus: 'Палестинская территория, оккупированная' },
  { name: 'Panama', code: 'PA', nameRus: 'Панама' },
  { name: 'Papua New Guinea', code: 'PG', nameRus: 'Папуа-Новая Гвинея' },
  { name: 'Paraguay', code: 'PY', nameRus: 'Парагвай' },
  { name: 'Peru', code: 'PE', nameRus: 'Перу' },
  { name: 'Philippines', code: 'PH', nameRus: 'Филиппины' },
  { name: 'Pitcairn', code: 'PN', nameRus: 'Питкэрн' },
  { name: 'Poland', code: 'PL', nameRus: 'Польша' },
  { name: 'Portugal', code: 'PT', nameRus: 'Португалия' },
  { name: 'Puerto Rico', code: 'PR', nameRus: 'Пуэрто-Рико' },
  { name: 'Qatar', code: 'QA', nameRus: 'Катар' },
  { name: 'Reunion', code: 'RE', nameRus: 'Воссоединение' },
  { name: 'Romania', code: 'RO', nameRus: 'Румыния' },
  { name: 'Russian Federation', code: 'RU', nameRus: 'Российская Федерация' },
  { name: 'Rwanda', code: 'RW', nameRus: 'Руанда' },
  { name: 'Saint Helena', code: 'SH', nameRus: 'Святая Елена' },
  { name: 'Saint Kitts and Nevis', code: 'KN', nameRus: 'Сент-Китс и Невис' },
  { name: 'Saint Lucia', code: 'LC', nameRus: 'Сент-Люсия' },
  { name: 'Saint Pierre and Miquelon', code: 'PM', nameRus: 'Сен-Пьер и Микелон' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC', nameRus: 'Сент-Винсент и Гренадины' },
  { name: 'Samoa', code: 'WS', nameRus: 'Самоа' },
  { name: 'San Marino', code: 'SM', nameRus: 'Сан-Марино' },
  { name: 'Sao Tome and Principe', code: 'ST', nameRus: 'Сан-Томе и Принсипи' },
  { name: 'Saudi Arabia', code: 'SA', nameRus: 'Саудовская Аравия' },
  { name: 'Senegal', code: 'SN', nameRus: 'Сенегал' },
  { name: 'Serbia', code: 'RS', nameRus: 'Сербия' },
  { name: 'Seychelles', code: 'SC', nameRus: 'Сейшельские острова' },
  { name: 'Sierra Leone', code: 'SL', nameRus: 'Сьерра-Леоне' },
  { name: 'Sint Maarten', code: 'SX', nameRus: 'Синт-Мартен' },
  { name: 'Singapore', code: 'SG', nameRus: 'Сингапур' },
  { name: 'Slovakia', code: 'SK', nameRus: 'Словакия' },
  { name: 'Slovenia', code: 'SI', nameRus: 'Словения' },
  { name: 'Solomon Islands', code: 'SB', nameRus: 'Соломоновы острова' },
  { name: 'Somalia', code: 'SO', nameRus: 'Сомали' },
  { name: 'South Africa', code: 'ZA', nameRus: 'Южная Африка' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'GS', nameRus: 'Южная Георгия и Южные Сандвичевы острова' },
  { name: 'Spain', code: 'ES', nameRus: 'Испания' },
  { name: 'Sri Lanka', code: 'LK', nameRus: 'Шри-Ланка' },
  { name: 'Sudan', code: 'SD', nameRus: 'Судан' },
  { name: 'Suriname', code: 'SR', nameRus: 'Суринам' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ', nameRus: 'Шпицберген и Ян-Майен' },
  { name: 'Swaziland', code: 'SZ', nameRus: 'Свазиленд' },
  { name: 'Sweden', code: 'SE', nameRus: 'Швеция' },
  { name: 'Switzerland', code: 'CH', nameRus: 'Швейцария' },
  { name: 'Syrian Arab Republic', code: 'SY', nameRus: 'Сирийская Арабская Республика' },
  { name: 'Taiwan', code: 'TW', nameRus: 'Тайвань' },
  { name: 'Tajikistan', code: 'TJ', nameRus: 'Таджикистан' },
  { name: 'Tanzania, United Republic of', code: 'TZ', nameRus: 'Танзания, Объединенная Республика' },
  { name: 'Thailand', code: 'TH', nameRus: 'Таиланд' },
  { name: 'Timor-Leste', code: 'TL', nameRus: 'Тимор-Лешти' },
  { name: 'Togo', code: 'TG', nameRus: 'Того' },
  { name: 'Tokelau', code: 'TK', nameRus: 'Токелау' },
  { name: 'Tonga', code: 'TO', nameRus: 'Тонга' },
  { name: 'Trinidad and Tobago', code: 'TT', nameRus: 'Тринидад и Тобаго' },
  { name: 'Tunisia', code: 'TN', nameRus: 'Тунис' },
  { name: 'Turkey', code: 'TR', nameRus: 'Турция' },
  { name: 'Turkmenistan', code: 'TM', nameRus: 'Туркменистан' },
  { name: 'Turks and Caicos Islands', code: 'TC', nameRus: 'Острова Теркс и Кайкос' },
  { name: 'Tuvalu', code: 'TV', nameRus: 'Тувалу' },
  { name: 'Uganda', code: 'UG', nameRus: 'Уганда' },
  { name: 'Ukraine', code: 'UA', nameRus: 'Украина' },
  { name: 'United Arab Emirates', code: 'AE', nameRus: 'Объединенные Арабские Эмираты' },
  { name: 'United Kingdom', code: 'GB', nameRus: 'Великобритания' },
  { name: 'United Kingdom', code: 'UK', nameRus: 'Великобритания' },
  { name: 'United States', code: 'US', nameRus: 'США' },
  { name: 'United States Minor Outlying Islands', code: 'UM', nameRus: 'Отдаленные малые острова США' },
  { name: 'Uruguay', code: 'UY', nameRus: 'Уругвай' },
  { name: 'Uzbekistan', code: 'UZ', nameRus: 'Узбекистан' },
  { name: 'Vanuatu', code: 'VU', nameRus: 'Вануату' },
  { name: 'Venezuela', code: 'VE', nameRus: 'Венесуэла' },
  { name: 'Viet Nam', code: 'VN', nameRus: 'Вьетнам' },
  { name: 'Virgin Islands, British', code: 'VG', nameRus: 'Виргинские острова, Британские' },
  { name: 'Virgin Islands, U.S.', code: 'VI', nameRus: 'Виргинские острова, США' },
  { name: 'Wallis and Futuna', code: 'WF', nameRus: 'Уоллис и Футуна' },
  { name: 'Western Sahara', code: 'EH', nameRus: 'Западная Сахара' },
  { name: 'Yemen', code: 'YE', nameRus: 'Йемен' },
  { name: 'Zambia', code: 'ZM', nameRus: 'Замбия' },
  { name: 'Zimbabwe', code: 'ZW', nameRus: 'Зимбабве' },
];
