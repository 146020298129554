import { SerializedStyles } from '@emotion/core';
import styled from '@emotion/styled';
import React, { FC, MouseEvent, useContext, useEffect, useState } from 'react';

import { NEW_FEATURES } from '../../state/feature-toggle/new-features';
import { PRICING_PAGE } from '../../../common/constants/routes';
import { browserUpdaterContext, userContext, workspaceContext } from '../../state';
import DragAndDropFiles from '../../ui/drag-and-drop-files';
import Bar from '../../ui/gologin-header/promo-bar';
import {
  borderRadiusContainer,
  changeBorderWrapper,
} from '../../ui/style-templates';

const Wrapper = styled('div')<{ hasBorderRadius: boolean }>`
  margin: 0 24px;

  ${(props): SerializedStyles => borderRadiusContainer(props.hasBorderRadius)};
  background-color: var(--FFFFFF);
  border: 1px solid var(--EBEBEB);
  box-shadow: 0 4px 4px var(--0000001A);
  display: flex;
  flex-direction: column;
  flex: 1;
`;

let ipcRenderer: Electron.IpcRenderer;

const isElectron = !!window.require;
if (isElectron) {
  ipcRenderer = window.require('electron').ipcRenderer;
}

interface IWrapperGlobal {
  location: Location;
}

const WrapperGlobal: FC<IWrapperGlobal> = ({ children, location }) => {
  const [appNeedRestart, setAppNeedRestart] = useState<boolean>(false);
  const [hasWrapperBorderRadius, setHasWrapperBorderRadius] = useState<boolean>(false);
  const [needMask, setNeedMask] = useState(false);

  const { hasTrial: userIsTrial } = useContext(userContext);
  const { isUnpaid, paymentIsTrial: workspaceIsTrial } = useContext(workspaceContext);

  const {
    browserUpdating,
    showOrbitaDialog,
  } = useContext(browserUpdaterContext);

  useEffect((): (() => void) | void => {
    if (!isElectron) {
      return;
    }

    ipcRenderer.on('app-update-downloaded', () => {
      setAppNeedRestart(true);
    });

    return () => ipcRenderer.removeAllListeners('app-update-downloaded');
  }, []);

  useEffect(() => {
    const isUnpaidWorkspace = NEW_FEATURES.workspaces && isUnpaid;
    const isTrial = userIsTrial || (NEW_FEATURES.workspaces && workspaceIsTrial);
    const needWrapperBorderRadius = !(isTrial || showOrbitaDialog || appNeedRestart || browserUpdating || isUnpaidWorkspace);
    changeBorderWrapper(needWrapperBorderRadius);
    setHasWrapperBorderRadius(needWrapperBorderRadius);
  }, [appNeedRestart, showOrbitaDialog, userIsTrial, workspaceIsTrial, browserUpdating, isUnpaid]);

  const badPath = [PRICING_PAGE, '/forgot_password', '/sign_in', '/sign_up', '/quiz', '/pay'];
  const isLocationBadPath = badPath.find(path => path === location.pathname);

  const onDragEnter = (event: any): void => {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.types.includes('Files')) {
      setNeedMask(true);
    }
  };

  const onDragOver = (event: MouseEvent): void => {
    event.stopPropagation();
    event.preventDefault();
  };

  if (!NEW_FEATURES.header || isLocationBadPath) {
    return (
      <>
        {children}
      </>
    );
  }

  return (
    <>
      {needMask && location.pathname === '/profileList' ? (
        <DragAndDropFiles setNeedMask={setNeedMask} />
      ) : null}
      <Bar appNeedRestart={appNeedRestart} />
      <Wrapper hasBorderRadius={hasWrapperBorderRadius} onDragOver={onDragOver} onDragEnter={onDragEnter}>
        {children}
      </Wrapper>
    </>
  );
};

export default WrapperGlobal;
