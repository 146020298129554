import { VpnUfoCountry, VpnUfoCountryWithTypes, VpnUfoProxyOptions } from './vpn-ufo.types';
import { API_BASE_URL } from '../../../../common/constants/constants';
import { GeoProxyWithTraffic } from '../../../interfaces/geoproxy-with-traffic.type';
import { http } from '../../../services';

export const getProxy = async () => {
  const url = `${API_BASE_URL}/users-proxies/profiles-proxies`;

  try {
    const data = await http(url, {
      method: 'GET'
    });
    return data.body;
  } catch (error) {
    throw error;
  }
};

type VpnUfoCountriesResponse<CountryType> = {
  countryList: CountryType[];
};

export const getVpnUfoCountries = async (query: string): Promise<VpnUfoCountry[]> =>
  http(`${API_BASE_URL}/users-proxies/mobile-countries${query}`)
    .then(({ body }: { body: VpnUfoCountriesResponse<VpnUfoCountry> }) => body.countryList)
    .catch(() => []);

export const getAllVpnUfoCountries = async (): Promise<VpnUfoCountryWithTypes[]> =>
  http(`${API_BASE_URL}/users-proxies/countries`)
    .then(({ body }: { body: VpnUfoCountriesResponse<VpnUfoCountryWithTypes> }) => body.countryList)
    .catch(() => []);

export const getVpnUfoProxy = async (options: VpnUfoProxyOptions): Promise<GeoProxyWithTraffic> => {
  let { countryCode } = options;
  if (options.countryCode?.toUpperCase() === 'UK') {
    countryCode = 'GB';
  }

  const normalizedOptions: VpnUfoProxyOptions = { ...options, countryCode };
  const data = await http(`${API_BASE_URL}/users-proxies/mobile-proxy`, {
    method: 'POST',
    body: JSON.stringify(normalizedOptions),
  });

  return data.body;
};

export const getProfileGeoProxyParams = async (query: string): Promise<any> => {
  const data = await http(`${API_BASE_URL}/users-proxies/get_profile_geo_proxy_params?${query}`);

  return data.body;
};
