import styled from '@emotion/styled';

import { DescriptionPlan } from '../styles';

export const HighlightedDescriptionPlan = styled(DescriptionPlan)`
  color: var(--00997A-new-pricing);
  cursor: pointer;

  border-bottom: 1px dashed var(--00997A-new-pricing);
  padding-bottom: 2px;
`;
