import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled';
import React, { FC } from 'react';

import AddTag from './add-tag';
import Tag from './tag';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { IAddTag, ITag, ITagBase, TagField } from '../interfaces/tag.interface';
import { closeTagManager } from '../../../state/tags/tag-manager-state.atom';

const EditModeTagsWrapper = styled('div')<{ isBulkMode?: boolean; newStyle: boolean }>`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  background: var(--FFFFFF-tags-edit);
  border-radius: 4px;
  min-width: 110px;

  ${(props): SerializedStyles|null => css`
    ${(props.isBulkMode) ? `
      z-index: 100;
      position: relative;
    ` : `
      padding: 16px 8px 14px;
      position: absolute;
      left: 0;
      top: 0;
      box-shadow: 0 0 0 1px var(--88D1C3-tags-edit);
      z-index: 145;
    `}
    
    ${props.newStyle ? `
      gap: 4px;
    ` : null}
  `}
`;

interface IProps {
  profileIds: string[];
  tags: ITag[];
  addTag: (params: IAddTag) => Promise<void>;
  updateTag: (tag: ITagBase) => Promise<void>;
  removeProfileTag: (profileIds: string[], tagId: string) => Promise<void>;
  removeTag: (tagId: string, isInSuggest: boolean) => Promise<void>;
  profilesList?: any[];
  isBulkMode?: boolean;
  isAddMode: boolean;
  field: TagField;
}

const EditModeTags: FC<IProps> = (props: IProps) => {
  const {
    tags,
    profileIds,
    addTag,
    updateTag,
    removeProfileTag,
    removeTag,
    profilesList,
    isBulkMode,
    isAddMode,
    field,
  } = props;

  return (
    <EditModeTagsWrapper isBulkMode={isBulkMode} newStyle={!!NEW_FEATURES.header}>
      {tags.map((tag, index) => (
        <Tag
          key={tag.id}
          tag={tag}
          updateTag={updateTag}
          isAddMode={isAddMode}
          removeTag={async (): Promise<void> => {
            closeTagManager();
            await removeProfileTag(profileIds, tag.id);
          }}
          profilesList={profilesList}
          isLastTag={index + 1 === tags.length}
        />
      ))}
      <AddTag
        profileIds={profileIds}
        currentTags={tags}
        addTag={addTag}
        updateTag={updateTag}
        isAddMode={isAddMode}
        isBulkOperation={!!profilesList?.length}
        removeProfileTag={removeProfileTag}
        removeTag={removeTag}
        isBulkMode={isBulkMode}
        field={field}
      />
    </EditModeTagsWrapper>
  );
};

export default EditModeTags;
