import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { E_ANALYTICS_ACTIONS } from '../../../../common/constants/analytics';
import { sendActionAnalytics } from '../../common/api';

const tableCustomizationRootAnchorElAtom = atom<HTMLElement | null>(null);
const tableCustomizationGroupAnchorElAtom = atom<HTMLElement | null>(null);

const setTableCustomizationRootAnchorEl = (elem: HTMLElement | null): void =>
  getDefaultStore().set(tableCustomizationRootAnchorElAtom, elem);

const setTableCustomizationGroupAnchorEl = (elem: HTMLElement | null): void =>
  getDefaultStore().set(tableCustomizationGroupAnchorElAtom, elem);

export const openTableCustomization = (event: React.MouseEvent<HTMLElement>): void => {
  sendActionAnalytics(E_ANALYTICS_ACTIONS.openedProfileTableSettings);
  setTableCustomizationRootAnchorEl(event.currentTarget);
};

export const openGroupsTableCustomization = (event: React.MouseEvent<HTMLElement>): void => {
  setTableCustomizationGroupAnchorEl(event.currentTarget);
};

export const closeTableCustomization = (): void => {
  setTableCustomizationRootAnchorEl(null);
  setTableCustomizationGroupAnchorEl(null);
};

export const closeGroupsTableCustomization = (): void => {
  setTableCustomizationGroupAnchorEl(null);
};

export const useTableCustomizationMenu = (): { rootAnchorEl: HTMLElement | null; groupAnchorEl: HTMLElement | null } => {
  const rootAnchorEl = useAtomValue(tableCustomizationRootAnchorElAtom);
  const groupAnchorEl = useAtomValue(tableCustomizationGroupAnchorElAtom);

  return { rootAnchorEl, groupAnchorEl };
};
