import React from 'react';
export interface IIconAddFolder {
    style?: React.CSSProperties;
  }

const IconDeleteProfile = () => (

    <svg width="13" height="18" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.49792 5.29375H4.44792V5.34375V12.3393V12.3893H4.49792H5.37237H5.42237V12.3393V5.34375V5.29375H5.37237H4.49792Z" fill="var(--A14747)" stroke="var(--A14747)" strokeWidth="0.1"/>
<path d="M6.24683 5.29375H6.19683V5.34375V12.3393V12.3893H6.24683H7.12127H7.17127V12.3393V5.34375V5.29375H7.12127H6.24683Z" fill="var(--A14747)" stroke="var(--A14747)" strokeWidth="0.1"/>
<path d="M7.99548 5.29375H7.94548V5.34375V12.3393V12.3893H7.99548H8.86993H8.91993V12.3393V5.34375V5.29375H8.86993H7.99548Z" fill="var(--A14747)" stroke="var(--A14747)" strokeWidth="0.1"/>
<path d="M1 2.66875H0.95V2.71875V3.59319V3.64319H1H12.3678H12.4178V3.59319V2.71875V2.66875H12.3678H1Z" fill="var(--A14747)" stroke="var(--A14747)" strokeWidth="0.1"/>
<path d="M8.84114 3.20696H8.89114V3.15696V2.28252C8.89114 1.55535 8.28579 0.95 7.55862 0.95H5.80974C5.08257 0.95 4.47722 1.55535 4.47722 2.28252V3.15696V3.20696H4.52722H5.34337H5.39337V3.15696V2.28252C5.39337 2.0478 5.57502 1.86615 5.80974 1.86615H7.55862C7.79334 1.86615 7.975 2.0478 7.975 2.28252V3.15696V3.20696H8.025H8.84114Z" fill="var(--A14747)" stroke="var(--A14747)" strokeWidth="0.1"/>
<path d="M2.64081 13.6824L2.64083 13.6827C2.7012 14.4072 3.33405 15.0111 4.06062 15.0111H9.30728C10.0331 15.0111 10.6667 14.4371 10.7271 13.6826L10.7271 13.6824L11.5432 3.18913L11.5472 3.13872L11.4967 3.13536L10.6223 3.07706L10.5729 3.07377L10.5691 3.12308L9.75295 13.6164L9.7528 13.6164V13.6203C9.7528 13.7205 9.70227 13.8247 9.61879 13.9048C9.53556 13.9847 9.42366 14.0366 9.30728 14.0366H4.06062C3.85436 14.0366 3.64174 13.8534 3.61489 13.6156L2.7988 3.12308L2.79497 3.07377L2.74563 3.07706L1.87119 3.13536L1.82074 3.13872L1.82466 3.18913L2.64081 13.6824Z" fill="var(--A14747)" stroke="var(--A14747)" strokeWidth="0.1"/>
</svg>


);

export default IconDeleteProfile;
