import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { IGeolocationProxyFullData, IProxyTraffic } from '../../interfaces';

const TRAFFIC_DATA_BY_TYPE: IProxyTraffic = {
  trafficUsed: 0,
  trafficLimit: 0,
  trafficUsedBytes: 0,
  trafficLimitBytes: 0,
};

export const TRAFFIC_DATA_DEFAULT: IGeolocationProxyFullData = {
  prices: {
    resident: 0,
    mobile: 0,
    dataCenter: 0,
  },
  availableForPurchase: [5, 10, 20, 50, 100],
  residentTrafficData: TRAFFIC_DATA_BY_TYPE,
  mobileTrafficData: TRAFFIC_DATA_BY_TYPE,
  dataCenterTrafficData: TRAFFIC_DATA_BY_TYPE,
};

export const trafficDataAtom = atom<IGeolocationProxyFullData>(TRAFFIC_DATA_DEFAULT);
const isTrafficDataLoadingAtom = atom<boolean>(true);

const setTrafficData = (trafficData: IGeolocationProxyFullData): void => getDefaultStore().set(trafficDataAtom, trafficData);
const setIsTrafficDataLoading = (isLoading: boolean): void => getDefaultStore().set(isTrafficDataLoadingAtom, isLoading);

export const useTrafficData = (): IGeolocationProxyFullData => useAtomValue(trafficDataAtom);
export const useIsTrafficDataLoading = (): boolean => useAtomValue(isTrafficDataLoadingAtom);

export const updateIsTrafficDataLoading = (isLoading: boolean): void => {
  setIsTrafficDataLoading(isLoading);
};

export const updateTrafficData = (trafficData: IGeolocationProxyFullData): void => {
  updateIsTrafficDataLoading(false);
  setTrafficData(trafficData);
};

export const clearTrafficData = (): void => {
  updateIsTrafficDataLoading(true);
  setTrafficData(TRAFFIC_DATA_DEFAULT);
};
