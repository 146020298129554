import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconUpload: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M16.5 23.5V2.5' strokeMiterlimit='10' />
      <path d='M23.5 9.5L16.5 2.5L9.5 9.5' strokeMiterlimit='10' strokeLinecap='square' />
      <path d='M2.5 22.5V27.5C2.5 28.2956 2.81607 29.0587 3.37868 29.6213C3.94129 30.1839 4.70435 30.5 5.5 30.5H27.5C28.2956 30.5 29.0587 30.1839 29.6213 29.6213C30.1839 29.0587 30.5 28.2956 30.5 27.5V22.5' strokeMiterlimit='10' strokeLinecap='square' />
    </svg>
  </IconWrapper>
);
