import React, { useState } from 'react';

import { ModalTitle, TitleContainer } from './styles';
import WorkflowParamsTitleTooltip from './title-tooltip';
import { Icon as IconContainer, IconWrapper } from '../../automation-list/workflow-item/workflow-title/styles';
import { Icon } from 'antd';

type WorkflowCookieBotParametersModalTitleProps = {
  name: string;
  icon: string;
}

const WorkflowCookieBotParametersModalTitle: React.FC<WorkflowCookieBotParametersModalTitleProps> = (props) => {
  const { name, icon } = props;

  const [anchorElTooltip, setAnchorElTooltip] = useState<HTMLElement | null>(null);

  let hideTimeout: NodeJS.Timeout;

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    clearTimeout(hideTimeout);
    setAnchorElTooltip(event.currentTarget);
  };

  const handleMouseLeave = () => {
    hideTimeout = setTimeout(() => setAnchorElTooltip(null), 300);
  };

  return (
    <>
      <TitleContainer>
        <IconWrapper>
          <IconContainer src={icon} alt='' />
        </IconWrapper>
        <ModalTitle>
          {name}
        </ModalTitle>
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Icon
              style={{
                marginLeft: 24,
                opacity: 0.4,
              }}
              type='info-circle'
            />
        </div>
      </TitleContainer>
      <WorkflowParamsTitleTooltip
        onMouseEnter={(event) => {
          event.stopPropagation();
          clearTimeout(hideTimeout);
        }}
        onMouseLeave={(event) => {
          event.stopPropagation();
          handleMouseLeave();
        }}
        anchorElTooltip={anchorElTooltip}
      />
    </>
  );
};

export default WorkflowCookieBotParametersModalTitle;
