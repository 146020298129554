import { useCallback, useRef } from 'react';

import { buildProfile } from '../features/profile-settings/content/utils/build-profile';
import { IFullProfile, IProfile } from '../interfaces';
import { IProfilesCtx } from '../state';
import { DEFAULT_PROFILE_DATA } from '../utils/default-profile-data';

export const useProfiles = (): IProfilesCtx => {
  const fullProfilesMap = useRef(new Map<string, IProfile|IFullProfile>());

  const updateFullProfilesMap = useCallback((profiles: (IProfile|IFullProfile)[]): void => profiles.forEach(profile => {
    const currentProfile = fullProfilesMap.current.get(profile.id);
    let updatedProfile = profile;
    if (!currentProfile) {
      fullProfilesMap.current.set(profile.id, profile);

      return;
    }

    if (!Object.prototype.hasOwnProperty.call(currentProfile, 'isFullProfile')) {
      updatedProfile = buildProfile(buildProfile(DEFAULT_PROFILE_DATA, currentProfile), profile);
    } else {
      updatedProfile = buildProfile(currentProfile as IFullProfile, profile);
    }

    fullProfilesMap.current.set(profile.id, updatedProfile);
  }), [fullProfilesMap.current]);

  const refreshFullProfile = useCallback((newProfile: IProfile|IFullProfile): void => {
    const oldProfile = fullProfilesMap.current.get(newProfile.id);
    fullProfilesMap.current.set(
      newProfile.id, {
        ...DEFAULT_PROFILE_DATA,
        ...oldProfile,
        ...newProfile,
        isFullProfile: true,
      },
    );
  }, [fullProfilesMap.current]);

  const getProfile = useCallback((profileId: string): IFullProfile|null => {
    let currentProfile = fullProfilesMap.current.get(profileId) || null;

    if (!currentProfile) {
      return currentProfile;
    }

    if (!Object.prototype.hasOwnProperty.call(currentProfile, 'isFullProfile')) {
      currentProfile = {
        ...DEFAULT_PROFILE_DATA,
        ...currentProfile,
        isFullProfile: true,
      };

      refreshFullProfile(currentProfile);
    }

    return currentProfile as IFullProfile;
  }, [fullProfilesMap.current]);

  return {
    getProfile,
    updateFullProfilesMap,
    refreshFullProfile,
  };
};
