import css from '@emotion/css';
import styled from '@emotion/styled';
import { Layout } from 'antd';

import { BACKGROUNDS, BackgroundType } from '../../constants';

export const ChoosePlanButton = styled.button<{ buttonStyle: BackgroundType; isSmallButton?: boolean; isDisabled?: boolean }>`
  display: inline-flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  border-width: 0;
  cursor: pointer;
  background-color: ${({ buttonStyle }): string => buttonStyle === 'white' ? 'var(--FFFFFF-new-pricing-custom-button)' : BACKGROUNDS[buttonStyle]};
  color: ${({ buttonStyle }): string => buttonStyle === 'green' ? 'var(--FFFFFF-new-pricing)' : 'var(--36363D-new-pricing)'};
  border-radius: 24px;
  text-align: center;
  font-family: 'Roboto';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;

  &:hover {
    background-color: ${({ buttonStyle }): string => buttonStyle === 'green' ? 'var(--00997A-new-pricing)': 'var(--E7E7E7-new-pricing)'};
  }
  ${(props) => props.isSmallButton ? css`
    display: flex;
    padding: 8px 20px;
    border-radius: 18px;
    border: none;
    background: var(--FFFFFF-new-pricing);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    background-color: ${props.buttonStyle === 'white' ? 'var(--FFFFFF-new-pricing-custom-button)' : BACKGROUNDS[props.buttonStyle]};
    color: ${props.buttonStyle === 'green' ? 'var(--FFFFFF-new-pricing)' : 'var(--36363D-new-pricing)'};
  ` : null}

  opacity: ${({ isDisabled }): number => isDisabled ? 0.3 : 1};
`;

export const MainContainer = styled(Layout)<{ isPopup?: boolean }>`
  background: var(--FFFFFF-new-pricing);

  @media screen and (max-width: ${(props): string => props.isPopup ? '1440px' : '1340px'}) {
    transform: scale(0.77);
    transform-origin: top;
    height: 3350px;
  }
`;

export const Wrapper = styled('div')`
  background: var(--FFFFFF-new-pricing);
`;

export const ContentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TitleContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const PageTitle = styled('div')`
  color: var(--36363D-new-pricing);
  text-align: center;
  font-family: 'Roboto Flex';
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 45px;
  width: 900px;
  margin-top: 80px;
  letter-spacing: 0em;
`;

export const PopularPlanText = styled('div')`
  color: var(--00A987-new-pricing);
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;

  margin-left: 20px;
  display: inline-flex;
  padding: 2px 8px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 10px;
  background: var(--DEF7E9-new-pricing);
`;

export const PopularPlanTextAbsolute = styled(PopularPlanText)`
  position: absolute;
  bottom: 132px;
  margin-left: 0;
  text-align: center;
`;

export const ModalDivider = styled('div')`
  border-top: 1px solid var(--E7E7E7-new-pricing);
  width: 100%;
`;

export const ColumnContainer = styled('div')`
  display: flex;
`;

export const GreenChoosePlanText = styled('div')<{ color: string }>`
  color: ${({ color }): string => color};
  font-style: normal;
  text-align: center;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.44px;
`;

export const TrustCompaniesContainer = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 64px;
  justify-content: center;
  margin-top: 80px;
  align-items: center;
  white-space: nowrap;
`;

export const TrustTitle = styled('div')`
  color: var(--696973-new-pricing);
  font-family: 'Roboto Flex';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const ScoreContainer = styled('div')`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  gap: 30px;
`;

export const StarsContainer = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 30px;
`;

export const ScoreTextContainer = styled('div')`
  color: var(--696973-new-pricing);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;

  font-family: 'Roboto Flex';
  line-height: 19px;
  letter-spacing: 0;
  text-align: left;
`;

export const ReviewTitle = styled('div')`
  color: var(--36363D-new-pricing);
  text-align: center;
  font-family: 'Roboto Flex';
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 160px;
`;

export const ReviewSubtitle = styled('div')`
  color: var(--36363D-new-pricing);
  text-align: center;
  font-family: 'Roboto Flex';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 16px;
`;

export const ReviewCardsContainer = styled('div')`
  display: flex;
  justify-content: center;
  margin-top: 64px;
`;

export const ReviewCardsGrid = styled('div')`
  display: grid;
  gap: 40px 24px;
  grid-template-columns: repeat(3, 1fr);
`;

export const ReviewContent = styled('div')`
  width: 352px;
  color: var(--36363D-new-pricing);
  text-align: center;
  font-family: 'Roboto Flex';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;

  strong {
    color: var(--36363D-new-pricing);
    font-family: 'Roboto Flex';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    background: var(--gradient-new-pricing);
  }
`;

export const TariffDetailsTitle = styled('div')`
  color: var(--36363D-new-pricing);
  text-align: center;
  font-family: 'Roboto Flex';
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 160px;
`;

export const ReviewContainer = styled('div')`
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 24px;
`;

export const ReviewAuthorContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ReviewAuthorText = styled('div')`
  color: var(--81818A-new-pricing);
  font-family: 'Roboto Flex';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
