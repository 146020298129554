import { message } from 'antd';
import React, { useContext } from 'react';
import CopyToClipBoard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

import { ContainerDescription, DescriptionPlan, MenuRowContainer, MenuRowText, MenuHeaderItem } from './styles';
import { userContext } from '../../../state';
import { greyInformers } from '../../style-templates/colors';
import { IconUser } from '../icons';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';

const EmailInfo = (): JSX.Element => {
  const { email, planExpireDate, plan, profiles } = useContext(userContext);
  const { maxProfiles: planMaxProfiles, name: namePlan } = plan;

  const { t: translation } = useTranslation();

  const renderExpirationDate = (): null | JSX.Element => {
    if (!(planExpireDate && plan.priceForMonth)) {
      return null;
    }

    return (
      <DescriptionPlan>
        {translation('userMenu.nextPayment')}
        {' '}
        {new Date(planExpireDate).toLocaleString('ru-RU', { day: 'numeric', month: 'numeric', year: '2-digit' })}
      </DescriptionPlan>
    );
  };

  let planText = `${profiles} ${translation('userMenu.of')} ${planMaxProfiles} ${translation('userMenu.profiles')}`;
  if (!planMaxProfiles) {
    planText = `${profiles} ${translation('userMenu.profiles')}`;
  }

  return (
    <MenuHeaderItem newStyle={NEW_FEATURES.header}>
      <MenuRowContainer>
        <IconUser padding={0} styleType={'lightGray'} iconColor='var(--B5B5BA-header-account-menu)' />
        <CopyToClipBoard
          text={email || ''}
          onCopy={(): void => {
            message.success(translation('base.copiedText'));
          }}
        >
          <MenuRowText style={{ marginLeft: 8, cursor: 'pointer' }}>
            {email}
          </MenuRowText>
        </CopyToClipBoard>
      </MenuRowContainer>
      {!NEW_FEATURES.workspaces ? (
        <ContainerDescription>
          <DescriptionPlan>
            {namePlan}
            {' '}
            {translation('userMenu.plan')}
          </DescriptionPlan>
          <DescriptionPlan>
            {planText}
          </DescriptionPlan>
          {renderExpirationDate()}
        </ContainerDescription>
      ) : <div style={{ minHeight: 6 }} />}
    </MenuHeaderItem>
  );
};

export default EmailInfo;
