import styled from '@emotion/styled/macro';
import { Add, Done } from '@material-ui/icons';

export const AddIcon = styled(Add)`
  color: var(--C4C4C4);
`;

export const DoneIcon = styled(Done)`
  color: var(--00A987);
`;

export const FolderRow = styled.div<{ selected?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  line-height: 15px;
  padding: 8px;
  color: ${({ selected }): string => (selected ? 'var(--00A987)' : 'var(--767676)')};
  cursor: pointer;

  &:hover {
    background-color: var(--DEF7E9);
    color: var(--00A987);

    ${AddIcon} {
      color: var(--00A987);
    }
  }
`;

export const FolderNameContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FolderName = styled.span`
  margin-left: 8px;
  width: 264px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
