import styled from '@emotion/styled';

export const WorkflowContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  margin: 16px;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  color: inherit;
  cursor: pointer;
  top: 16px;
  left: 16px;
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
`;

export const DescriptionContainer = styled.div`
  width: 266px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  margin-left: 48px;

  font: 400 12px/16px Roboto;
  letter-spacing: 0.2px;
  color: var(--98989F);
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 12px;
`;

export const ButtonRunWorkflow = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  height: 32px;
  border-radius: 4px;
  gap: 8px;
  border-width: 0.5px;

  font: 400 16px/24px Roboto;
  letter-spacing: 0.44px;

  color: var(--767676);
  border: 0.5px solid var(--CDCDCD);
  padding: 0px 16px 0px 16px;
`;

export const IconGearContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
`;
