import React, { FC } from 'react';

import SanitizeHTML from './sanitize-html';

const NOTES_PROPS = { className: 'ql-editor' };

interface IStaticNotes {
  notes: string;
}

const StaticNotes: FC<IStaticNotes> = (props) => {
  const { notes } = props;

  return (
    <div className='ql-container'>
      <SanitizeHTML
        html={notes}
        divProps={NOTES_PROPS}
      />
    </div>
  );
};

export default StaticNotes;
