import { SerializedStyles } from '@emotion/core';
import styled from '@emotion/styled';
import { Layout } from 'antd';
import React, { FunctionComponent } from 'react';

import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { borderRadiusContainer } from '../../../ui/style-templates';

declare interface IContainerDashboard {}

const Container = styled(Layout)<{ newStyle: boolean }>`
  height: 100%;
  ${(props): SerializedStyles|null => props.newStyle ? borderRadiusContainer() : null}
`;

const ContainerDashboard: FunctionComponent<IContainerDashboard> = ({
  children
}) => <Container newStyle={!!NEW_FEATURES.header}>{children}</Container>;

export default ContainerDashboard;
