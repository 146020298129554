import React from 'react';

const IconClose = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M19 5L5 19" stroke="var(--828282)" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" />
      <path d="M19 19L5 5" stroke="var(--828282)" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" />
    </g>
  </svg>
);

export default IconClose;
