import React, { useEffect, useRef, useState } from 'react';

import { IProfile } from '../../../interfaces';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { openLocationIsUnmasked } from '../../../state/opened-location-is-unmasked-hint-atom.atom';
import { useProfileRunStatus } from '../../../state/profile-run-statuses.atom';
import { IProfileRunStatus } from '../../../state/profiles-list.atom';
import { launchProfileWeb } from '../actions';
import { ILaunchProfileOrbita } from '../components/interfaces/launch-profile-orbita.interface';
import RunProfileButton from '../run-button';
import { ContainerButtons } from './styles';
import AutomationStatus from '../../automation/automation-status';

const stopPropagation: React.MouseEventHandler<HTMLDivElement> = event => event.stopPropagation();

const isElectron = !!window.require;
interface IRunButtonCell {
  profile: IProfile;
  // TODO create child components
  // instead of this `renderDropDownMenu` method from profiles table
  renderDropDownMenu: (profile: IProfile & IProfileRunStatus) => JSX.Element | null;
  launchProfileOrbita: (opts: ILaunchProfileOrbita) => void;
}

const RunButtonCell: React.FC<IRunButtonCell> = props => {
  const { profile, renderDropDownMenu, launchProfileOrbita } = props;

  const profileRunStatus = useProfileRunStatus(profile.id);
  const contentRef = useRef<HTMLDivElement | null>(null);

  const [shouldMountDropDown, setShouldMountDropDown] = useState<boolean>(!NEW_FEATURES.header);

  const handleRunButtonClick = () => {
    const handleProfileLaunch = (opts: ILaunchProfileOrbita) => {
      if (isElectron) {
        launchProfileOrbita(opts);
      } else {
        launchProfileWeb(opts.profile);
      }
    };

    openLocationIsUnmasked(profile.id, contentRef.current as HTMLElement, handleProfileLaunch);
  };

  useEffect(() => {
    if (shouldMountDropDown) {
      return;
    }

    window.requestIdleCallback(() => setShouldMountDropDown(true), { timeout: 400 });
  }, [shouldMountDropDown]);

  const profileWithRunStatus: IProfile & IProfileRunStatus = {
    ...profileRunStatus,
    ...profile,
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', backgroundColor: 'inherit' }}>
      <ContainerButtons ref={contentRef} newStyle={NEW_FEATURES.header} onClick={stopPropagation}>
        <AutomationStatus profileId={profile.id} />
        <RunProfileButton
          onRunButtonClick={handleRunButtonClick}
          profile={profileWithRunStatus}
          launchProfileOrbita={launchProfileOrbita}
        />
      </ContainerButtons>
      {shouldMountDropDown ? renderDropDownMenu(profileWithRunStatus) : <div style={{ width: 20, height: 20 }} />}
    </div>
  );
};

export default React.memo(RunButtonCell);
