import React from 'react';

import { ProxyItemCheckboxWrapper } from './styles';
import { IProxy } from '../../../../../interfaces';
import { NEW_FEATURES } from '../../../../../state/feature-toggle/new-features';
import { useSelectedProxies } from '../../../../../state/proxy/selected-proxies.atom';
import { GologinCheckbox } from '../../../../../ui/gologin-checkbox';

type ProxyItemCheckboxProps = {
  proxy: IProxy;
  isCheckboxVisible: boolean;
}

const ProxyItemCheckbox: React.FC<ProxyItemCheckboxProps> = (props) => {
  const { proxy, isCheckboxVisible } = props;
  const { selectedProxies } = useSelectedProxies();

  return (
    <ProxyItemCheckboxWrapper isNewStyle={NEW_FEATURES.header}>
      {isCheckboxVisible ? (
        <GologinCheckbox
          onChange={(): void => void null}
          checked={selectedProxies.includes(proxy.id)}
        />
      ) : null}
    </ProxyItemCheckboxWrapper>
  );
};

export default ProxyItemCheckbox;
