import styled from '@emotion/styled';
import { FormControlLabel, Switch } from '@material-ui/core';

export const StyledSwitch = styled(Switch)`
  .MuiSwitch-track {
    background-color: inherit;
  }

  .MuiSwitch-colorPrimary.Mui-checked {
    color: var(--00A987-profile-new-settings-switch);
    margin: 1px 0 0 -7px;
  }

  .MuiSwitch-thumb {
    width: 12px;
    height: 12px;
    box-shadow: none;
  }

  &.MuiSwitch-root {
    border: 1px solid var(--D2D2D5-profile-new-settings-switch);
    border-radius: 50px;
    width: 28px;
    height: 16px;
    padding: 0;
  }

  .MuiSwitch-switchBase {
    color: var(--E9E9E7-profile-new-settings-switch);
    margin-left: 1px;
    margin-top: 1px;
    padding: 0;
  }

  .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: inherit;
  }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiTypography-body1 {
    font-size: 14px !important;
    margin-left: 16px;
  }

  &.MuiFormControlLabel-root {
    margin-left: 0;
    margin-top: 2px;
  }
`;
