import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconSort: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M7.5 15.0723L9.5 17.0723L11.5 15.0723' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M9.5 17.072V6.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12.5 8.57227L14.5 6.57227L16.5 8.57227' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M14.5 6.57227V17.1443' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  </IconWrapper>
);
