import React from 'react';

const IconBlurredProxyFlag: React.FC = () => (
  <svg width='18' height='14' viewBox='0 0 18 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <mask id='mask0_24744_2676' maskUnits='userSpaceOnUse' x='0' y='0' width='18' height='14'>
      <path
        d='M16.2857 0.570312H1.71429C0.767512 0.570312 0 1.33782 0 2.2846V11.7132C0 12.6599 0.767512 13.4275 1.71429 13.4275H16.2857C17.2325 13.4275 18 12.6599 18 11.7132V2.2846C18 1.33782 17.2325 0.570312 16.2857 0.570312Z'
        fill='white'
      />
    </mask>
    <g mask='url(#mask0_24744_2676)'>
      <path
        d='M16.2857 0.570312H1.71429C0.767512 0.570312 0 1.33782 0 2.2846V11.7132C0 12.6599 0.767512 13.4275 1.71429 13.4275H16.2857C17.2325 13.4275 18 12.6599 18 11.7132V2.2846C18 1.33782 17.2325 0.570312 16.2857 0.570312Z'
        fill='#F0F0EE'
      />
      <path d='M0 9.14062H18V13.4263H0V9.14062Z' fillRule='evenodd' clipRule='evenodd' fill='#CECED1' />
      <path d='M0 0.570312H18V4.85603H0V0.570312Z' fillRule='evenodd' clipRule='evenodd' fill='#E4E4E4' />
      <path
        d='M16.2863 1H1.71491C1.00483 1 0.429199 1.57563 0.429199 2.28571V11.7143C0.429199 12.4244 1.00483 13 1.71491 13H16.2863C16.9964 13 17.5721 12.4244 17.5721 11.7143V2.28571C17.5721 1.57563 16.9964 1 16.2863 1Z'
        stroke='black'
        strokeOpacity='0.1'
      />
    </g>
  </svg>
);

export default IconBlurredProxyFlag;
