import { message } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ContainerRadio, ErrorText, RadioButton, RadioGroup } from './styles';
import { TransferConfirm } from './transfer-confirm';
import { userContext, workspaceContext } from '../../../../state';
import { getCurrentWorkspaceId } from '../../../../state/current-workspace-id.atom';
import { getFolderById } from '../../../../state/folders/folders-list.atom';
import { filterProfilesByPermission, getProfileNamesForNotifications, mapAndSetProfilesList } from '../../../../state/profiles-list.atom';
import {
  closeProfilesTableModal,
  TRANSFER_PROFILE_MODAL_NAME,
  useProfilesTableModalProfiles,
  useProfilesTableModalIsVisible,
} from '../../../../state/profiles-table-modal.atom';
import { getProfilesTableSelectedIds, updateProfilesTableSelectedIds } from '../../../../state/profiles-table-selected-ids.atom';
import { getSelectedFolderId } from '../../../../state/selected-folder.atom';
import GologinModal from '../../../../ui/gologin-modal';
import LabeledElement from '../../../../ui/LabeledElement';
import { GreenSubmitButton } from '../../../../ui/modern-modal';
import { GologinInput } from '../../../../ui/styles/input';
import { SelectWorkspace } from '../../../../ui/workspaceSelect';
import { isEmailValid } from '../../../../utils/email-validator';
import { sendActionAnalytics } from '../../../common/api';
import { transferProfile, transferToMyWorkspace } from '../../../quickProfiles/api';
import { acceptDeleteProfiles } from '../../../../object-pool/transactions/accepters/accept-delete-profiles';

const TransferProfileModal = (): JSX.Element => {
  const workspaceCtx = useContext(workspaceContext);
  const {
    _id: userId,
  } = useContext(userContext);

  const workspaceId = getCurrentWorkspaceId();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isTransferConfirmVisible, setIsTransferConfirmVisible] = useState(false);
  const [targetEmail, setTargetEmail] = useState<string>('');
  const [emailErrorText, setEmailErrorText] = useState<string>('');
  const [transferType, setTransferType] = useState<'transfer' | 'transferToMyWorkspace'>('transfer');
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState<string>(workspaceId);
  const [selectedFolder, setSelectedFolder] = useState<string>('');

  const { t: translation } = useTranslation();

  const isModalVisible = useProfilesTableModalIsVisible(TRANSFER_PROFILE_MODAL_NAME);
  const modalProfiles = useProfilesTableModalProfiles();

  const profileIdsToTransfer = modalProfiles.map(profile => profile.id);

  useEffect(() => {
    if (isModalVisible) {
      getFolders();
      setSelectedWorkspaceId(workspaceId);
      sendActionAnalytics('visited transfer profile');
    }
  }, [isModalVisible]);

  const doTransferProfile = async (): Promise<void> => {
    const profilesCanTransfer = filterProfilesByPermission(profileIdsToTransfer, 'transferProfile');
    const profileIds = profilesCanTransfer.map(profile => profile.id);
    let transferredTo;

    const MAX_ENTRY_LENGTH = 12;

    if (transferType === 'transfer') {
      await transferProfile({ workspaceId, profileIds: profileIdsToTransfer, email: targetEmail });
      transferredTo = targetEmail.length > MAX_ENTRY_LENGTH ? targetEmail.slice(0, MAX_ENTRY_LENGTH) + '...' : targetEmail;
    }

    if (transferType === 'transferToMyWorkspace') {
      await transferToMyWorkspace({
        fromWorkspaceId: workspaceId,
        folderName: selectedFolder,
        targetWorkspaceId: selectedWorkspaceId,
        instanceIds: profileIdsToTransfer,
      });

      const workspaceData = workspaceCtx.availableWorkspaces.find(availableWorkspace => availableWorkspace.id === selectedWorkspaceId);
      if (workspaceData) {
        const { name } = workspaceData;
        transferredTo = name.length > MAX_ENTRY_LENGTH ? name.slice(0, MAX_ENTRY_LENGTH) + '...' : name;
      }
    }

    const youTransferred = translation('transferModal.successMessage.youTransferred');
    const profilesText = getProfileNamesForNotifications(profileIds, translation);
    const toText = translation('transferModal.successMessage.to');
    message.success(
      `${youTransferred} ${profilesText} ${toText} ${transferredTo}`,
    );

    const selectedFolderName = getFolderById(getSelectedFolderId())?.name || '';
    const isTransferToSameWorkspace = transferType === 'transferToMyWorkspace' && workspaceId === selectedWorkspaceId;
    const shouldProfilesRemain = isTransferToSameWorkspace && ['', selectedFolder].includes(selectedFolderName);
    if (!shouldProfilesRemain) {
      mapAndSetProfilesList(profiles => profiles.filter(profile => !profileIds.includes(profile.id)));
      const selectedProfileIds = getProfilesTableSelectedIds();
      const newSelectedProfiles = selectedProfileIds.filter(id => !profileIds.includes(id));
      updateProfilesTableSelectedIds(newSelectedProfiles);
    } else {
      mapAndSetProfilesList(profiles => profiles.map(profile => profileIds.includes(profile.id) ?
        { ...profile, folders: [selectedFolder] } :
        profile,
      ));
    }

    acceptDeleteProfiles(profileIds);
    closeProfilesTableModal();
  };

  const getFolders = async (): Promise<void> => {
    await workspaceCtx.updateAvailableWorkspacesFolders();
    setIsLoading(false);
  };

  const sendTransfer = async (): Promise<void> => {
    await doTransferProfile();

    setTargetEmail('');
    setEmailErrorText('');
    setTransferType('transfer');
    setSelectedWorkspaceId('');
    setSelectedFolder('');
    setIsTransferConfirmVisible(false);
  };

  const onClickTransfer = (): void => {
    if (transferType !== 'transfer') {
      sendTransfer();

      return;
    }

    if (!isEmailValid(targetEmail)) {
      setEmailErrorText(translation('errorMessages.enterValidEmail') || '');

      return;
    }

    setIsTransferConfirmVisible(true);
  };

  const renderForm = (): JSX.Element => {
    if (transferType === 'transferToMyWorkspace') {
      return (
        <LabeledElement title={translation('transferModal.selectWorkspace')}>
          <SelectWorkspace
            userId={userId || ''}
            selectedWorkspaceId={selectedWorkspaceId || workspaceId}
            setSelectedWorkspaceId={setSelectedWorkspaceId}
            selectedFolder={selectedFolder}
            setSelectedFolder={setSelectedFolder}
            isOwnerOnly={false}
            placeholder={translation('transferModal.selectWorkspace')}
            needFoldersList={true}
            isLoading={isLoading}
          />
        </LabeledElement>
      );
    }

    return (
      <div>
        <ErrorText>
          {emailErrorText || (
            <>
              &nbsp;
            </>
          )}
        </ErrorText>
        <GologinInput
          placeholder={translation('transferModal.emailInputPlaceholder') || ''}
          value={targetEmail}
          onChange={(event): void => setTargetEmail(event.target.value.trim())}
        />
      </div>
    );
  };

  const renderModalTitle = (): string => {
    const profilesCount = profileIdsToTransfer.length;
    if (profilesCount > 1) {
      return translation('transferModals.titleFirstModal.text', { count: profilesCount });
    }

    return translation('transferModal.titleSingle');
  };

  return (
    <>
      <GologinModal
        isVisible={isModalVisible}
        onClose={closeProfilesTableModal}
        width='380px'
        title={renderModalTitle()}
      >
        <ContainerRadio>
          <RadioGroup
            value={transferType}
            size='default'
            defaultValue='transfer'
            onChange={(event): void => setTransferType(event.target.value)}
          >
            <RadioButton value='transfer'>
              {translation('transferModal.newOwner')}
            </RadioButton>
            <RadioButton value='transferToMyWorkspace'>
              {translation('transferModal.myWorkspace')}
            </RadioButton>
          </RadioGroup>
        </ContainerRadio>
        {renderForm()}
        <GreenSubmitButton
          style={{ margin: '36px auto 0' }}
          onClick={onClickTransfer}
        >
          {translation('transferModal.transferButton')}
        </GreenSubmitButton>
      </GologinModal>
      <TransferConfirm
        sendTransfer={sendTransfer}
        onClose={(): void => setIsTransferConfirmVisible(false)}
        isVisible={isTransferConfirmVisible}
        profilesCount={profileIdsToTransfer.length}
      />
    </>
  );
};

export default TransferProfileModal;
