import React, { useState, memo, SyntheticEvent, ChangeEvent, ClipboardEvent, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ProxyFormContainerEl, ProxyFormEl, ProxySelectorLeftControlsWrapper, ProxyTitleBlockEl, ProxyTitleWrapperEl } from './styles';
import { IProxy } from '../../../../interfaces';
import { NEW_FEATURES } from '../../../../state/feature-toggle/new-features';
import { PROXY_CHECK_TOOLTIP_LOCATIONS } from '../../../../state/proxy/proxy-check/constants';
import { ProxyCheckTooltipLocation } from '../../../../state/proxy/proxy-check/types/proxy-check-tooltip-location.type';
import { useProxyList } from '../../../../state/proxy/proxy-list.atom';
import { closeProxyManager, useProxyManagerState } from '../../../../state/proxy/proxy-manager-modal-status.atom';
import { unlinkProfileProxy } from '../../../../state/proxy/proxy-operations/link-proxy.operations';
import { pasteProxies } from '../../../../state/proxy/proxy-operations/paste-proxies-operations';
import { searchProxy } from '../../../../state/proxy/proxy-search.atom';
import { useSharedProxies } from '../../../../state/proxy/shared-proxies.atom';
import CheckProxyBtn from '../../check-proxy-button';
import { ISharedProxy } from '../../components/interfaces/shared-proxy.interfaces';
import { PROXY_SELECTOR_PLACEHOLDER_MIN_WIDTH } from '../../constants/styles';
import ProxyFlag, { BIG_FLAG_HEIGHT, SMALL_FLAG_HEIGHT } from '../../proxy-flag';
import { makeProxyTitle } from '../../proxy-helpers';
import { UngroupedProxyStatusWrapper } from '../../proxy-manager/proxy-list/styles';
import { ProxySearchInputEl } from '../../styles/proxy-search-input';

type ProxyFormProps = {
  currentProxy?: IProxy;
  profileId: string;
  isSharedProxy: boolean;
  editProxyMode: boolean;
  isInDrawer: boolean;
  localProxySelectorLocation: string;
  selectorContainerElement: React.RefObject<HTMLDivElement>;
}

const ProxyForm: React.FC<ProxyFormProps> = (props) => {
  const {
    currentProxy,
    profileId,
    isSharedProxy,
    editProxyMode,
    isInDrawer,
    localProxySelectorLocation,
    selectorContainerElement,
  } = props;

  const [searchValue, setSearchValue] = useState<string>('');
  const [inputChanged, setInputChanged] = useState<boolean>(false);

  const input = useRef<HTMLInputElement>(null);

  const sharedProxies = useSharedProxies();
  const proxyList = useProxyList();
  const { currentProfileId } = useProxyManagerState();

  const { t: translation } = useTranslation();
  const placeholder = translation('proxies.searchForProxy');

  useEffect((): void => {
    setInputChanged(false);
    setSearchValue('');

    if (editProxyMode && input?.current) {
      input.current.focus();
    }
  }, [editProxyMode]);

  useEffect((): void => {
    setInputChanged(false);
    setSearchValue('');
  }, [proxyList]);

  useEffect(() => {
    searchProxy(searchValue);
  }, [searchValue, editProxyMode]);

  const handleSubmit = (event: SyntheticEvent): void => {
    event.preventDefault();

    if (inputChanged && !searchValue && currentProfileId) {
      unlinkProfileProxy(currentProfileId);
      closeProxyManager();
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setInputChanged(true);

    setSearchValue(event.target.value);
  };

  const handleFocus = (event: ChangeEvent<HTMLInputElement>): void => event.currentTarget.select();

  const handlePaste = async (event: ClipboardEvent<HTMLInputElement>): Promise<void> => {
    event.persist();
    const proxies = await pasteProxies({
      profileId,
      localProxySelectorLocation,
      isInDrawer,
    });

    if (proxies.length) {
      event.preventDefault();
    }
  };

  const preventUserTextSelection: React.MouseEventHandler<HTMLInputElement> = (event) => event.preventDefault();

  const title = makeProxyTitle(currentProxy);
  const noProxy = (!currentProxy || currentProxy.mode === 'none');

  let inputWidth;
  if (noProxy) {
    inputWidth = PROXY_SELECTOR_PLACEHOLDER_MIN_WIDTH;
  }

  let sharedProxyInfo: ISharedProxy | undefined;
  let { country } = currentProxy as IProxy;
  let checkProxy = currentProxy as IProxy;
  if (isSharedProxy) {
    sharedProxyInfo = sharedProxies.find((el) => el.profileId === profileId);
    if (sharedProxyInfo) {
      ({ country } = sharedProxyInfo);
      checkProxy = {
        ...sharedProxyInfo,
        ...currentProxy,
      };
    }
  }

  const isFailed = (currentProxy?.status === false);
  const isTransparent = (isFailed && !editProxyMode);

  const renderFlagBlock = (): JSX.Element | null => {
    if (noProxy) {
      return null;
    }

    let proxyTooltipView: ProxyCheckTooltipLocation = PROXY_CHECK_TOOLTIP_LOCATIONS.selectorProfileTable;
    if (isInDrawer) {
      proxyTooltipView = localProxySelectorLocation === 'pinned-proxy' ?
        PROXY_CHECK_TOOLTIP_LOCATIONS.selectorProfileSettingsFavourite :
        PROXY_CHECK_TOOLTIP_LOCATIONS.selectorProfileSettingsProxy;
    }

    return (
      <ProxySelectorLeftControlsWrapper
        isProxyManagerWithGroups={!!NEW_FEATURES.proxyGroups}
        isFailed={isTransparent}
      >
        <UngroupedProxyStatusWrapper isProxyManagerWithGroups={!!NEW_FEATURES.proxyGroups}>
          <CheckProxyBtn
            proxy={checkProxy}
            profileId={profileId}
            proxyTooltipView={proxyTooltipView}
            selectorContainerElement={selectorContainerElement}
            isSharedProxy={isSharedProxy}
          />
        </UngroupedProxyStatusWrapper>
        <ProxyFlag
          countryCode={country || ''}
          height={NEW_FEATURES.proxyGroups ? BIG_FLAG_HEIGHT : SMALL_FLAG_HEIGHT}
        />
      </ProxySelectorLeftControlsWrapper>
    );
  };

  const renderInvisibleTitle = (): JSX.Element | null => {
    if (noProxy) {
      return null;
    }

    return (
      <ProxyTitleBlockEl className='proxy-title' style={{ opacity: 0 }}>
        <div className='nowrap'>
          {title || '-'}
        </div>
      </ProxyTitleBlockEl>
    );
  };

  return (
    <ProxyFormContainerEl addMaxWidth={editProxyMode} newStyle={!!NEW_FEATURES.header} isEditProxy={editProxyMode}>
      {renderFlagBlock()}
      <ProxyTitleWrapperEl newStyle={!!NEW_FEATURES.header} noProxy={noProxy}>
        <ProxyFormEl onSubmit={handleSubmit}>
          {renderInvisibleTitle()}
          <ProxySearchInputEl
            newStyle={!!NEW_FEATURES.header}
            isProxyManagerWithGroups={!!NEW_FEATURES.proxyGroups}
            ref={input}
            placeholder={placeholder}
            value={(!searchValue && !inputChanged) ? title : searchValue}
            onChange={handleInputChange}
            onPaste={handlePaste}
            onFocus={handleFocus}
            onMouseDown={preventUserTextSelection}
            width={inputWidth}
            truncate={!editProxyMode}
            isTransparent={isTransparent}
            isInDrawer={isInDrawer}
            newDrawer={!!NEW_FEATURES.drawer}
          />
        </ProxyFormEl>
      </ProxyTitleWrapperEl>
    </ProxyFormContainerEl>
  );
};

export default memo(ProxyForm);
