import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconApi: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_11845_3658)'>
        <path d='M3.968 7.0002C3.62798 6.31412 3.46866 5.55263 3.5051 4.78779C3.54154 4.02295 3.77255 3.28005 4.17625 2.62941C4.57996 1.97877 5.14301 1.44191 5.81213 1.06962C6.48125 0.69734 7.23429 0.501953 8 0.501953C8.76571 0.501953 9.51876 0.69734 10.1879 1.06962C10.857 1.44191 11.42 1.97877 11.8237 2.62941C12.2275 3.28005 12.4585 4.02295 12.4949 4.78779C12.5313 5.55263 12.372 6.31412 12.032 7.0002' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M8 6.5C8.82843 6.5 9.5 5.82843 9.5 5C9.5 4.17157 8.82843 3.5 8 3.5C7.17157 3.5 6.5 4.17157 6.5 5C6.5 5.82843 7.17157 6.5 8 6.5Z' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M8 15.5C8.82843 15.5 9.5 14.8284 9.5 14C9.5 13.1716 8.82843 12.5 8 12.5C7.17157 12.5 6.5 13.1716 6.5 14C6.5 14.8284 7.17157 15.5 8 15.5Z' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M2 15.5C2.82843 15.5 3.5 14.8284 3.5 14C3.5 13.1716 2.82843 12.5 2 12.5C1.17157 12.5 0.5 13.1716 0.5 14C0.5 14.8284 1.17157 15.5 2 15.5Z' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M14 15.5C14.8284 15.5 15.5 14.8284 15.5 14C15.5 13.1716 14.8284 12.5 14 12.5C13.1716 12.5 12.5 13.1716 12.5 14C12.5 14.8284 13.1716 15.5 14 15.5Z' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M8 9.5V12.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M2 12.5V11.009C2.00005 10.8296 2.04835 10.6536 2.13984 10.4993C2.23132 10.345 2.36263 10.2181 2.52 10.132L5.5 8.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M14 12.5V11.009C14 10.8296 13.9517 10.6536 13.8602 10.4993C13.7687 10.345 13.6374 10.2181 13.48 10.132L10.5 8.5' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_11845_3658'>
          <rect width='16' height='16' fill='var(--FFFFFF)' />
        </clipPath>
      </defs>
    </svg>
  </IconWrapper>
);
