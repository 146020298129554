import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CancelButton, SaveButton } from './styles';
import { editProfilesListFields } from '../../../../state/profiles-list.atom';
import { updateProfilesTableSelectedIds } from '../../../../state/profiles-table-selected-ids.atom';
import GologinModal from '../../../../ui/gologin-modal';
import { parseBookmarksTxtToObj } from '../../../../utils/bookmarks-utils';
import { BookmarksTextArea } from '../../../common/tabs/bookmarks/styles';
import { updateBookmarksMultipleProfiles } from '../../../quickProfiles/api';

interface IProfileBookmarksModal {
  visible: boolean;
  onClose: () => void;
  profilesIds: string[];
}

const ProfileBookmarksModal: React.FC<IProfileBookmarksModal> = (props) => {
  const { visible, profilesIds, onClose } = props;
  const [profileBookmarks, setProfileBookmarks] = useState('');

  const { t: translation } = useTranslation();

  const handleOnBookmarksTextChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    setProfileBookmarks(event.target.value);
  };

  const getTextArea = (): JSX.Element => (
    <BookmarksTextArea
      value={profileBookmarks}
      onChange={handleOnBookmarksTextChange}
      placeholder={translation('profileSettings.bookmarks.placeholder') || ''}
    />
  );

  const saveBookmarks = async (): Promise<void> => {
    const newBookmarks = parseBookmarksTxtToObj({}, profileBookmarks);
    editProfilesListFields(profilesIds, { bookmarks: newBookmarks });
    await updateBookmarksMultipleProfiles(profilesIds, newBookmarks);
    setProfileBookmarks('');
    updateProfilesTableSelectedIds(profilesIds);
    onClose();
  };

  const onClickSaveBookmarks = (): void => {
    saveBookmarks();
  };

  return (
    <GologinModal
      isVisible={visible}
      onClose={onClose}
      width='546px'
      title={translation('profilesTable.multipleOperations.bookmarks')}
    >
      {getTextArea()}
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 24 }}>
        <CancelButton
          style={{ marginRight: 12 }}
          onClick={onClose}
        >
          {translation('base.cancel')}
        </CancelButton>
        <SaveButton
          disabled={!profileBookmarks}
          onClick={onClickSaveBookmarks}
        >
          {translation('base.save')}
        </SaveButton>
      </div>
    </GologinModal>
  );
};

export default ProfileBookmarksModal;
