import { DefaultTFuncReturn } from 'i18next';
import { atom, getDefaultStore, useAtomValue } from 'jotai';
import React from 'react';

import { getCurrentWorkspaceId } from './current-workspace-id.atom';
import { getProfilesSortField, resetProfilesTableSorting } from './profiles-table/profiles-sort.atom';
import { saveWorkspaceProfileTableColumns } from '../features/quickProfiles/api';
import { RenderTableCellFn } from '../features/quickProfiles/interfaces';

export const PROFILE_TABLE_COLUMNS: string[] = [
  'name',
  'status',
  'notes',
  'tags',
  'customStatus',
  'lastUpdate',
  'lastLaunch',
  'createdAt',
  'sharing',
  'proxyType',
  'proxyPort',
  'proxyUsername',
  'proxyPassword',
  'proxy',
  'os',
  'orbitaVersion',
] as const;

type ProfileColumnsSettings = {
  title?: string | JSX.Element;
  label?: DefaultTFuncReturn | string;
  labelTooltip?: string;
  visible: boolean;
  width: number;
  minWidth?: number;
  headerWidth?: number;
  dataIndex?: string;
  sortField?: string;
  className?: string;
  onCellClick?: (_: unknown, event: React.MouseEvent) => void;
  originalColumnIndex?: number;
  actionsSlot?: JSX.Element;
  leftPadding?: number;
};

type EnhancedProfileColumnsSettings<ColumnName, RenderCallback> = ProfileColumnsSettings & {
  colName?: ColumnName;
  render?: RenderCallback;
};

export type SingleProfileColumnSetting = EnhancedProfileColumnsSettings<typeof PROFILE_TABLE_COLUMNS[number], RenderTableCellFn>;

export type ColumnsOpts = {
  [key: typeof PROFILE_TABLE_COLUMNS[number]]: SingleProfileColumnSetting;
};

export interface IProfilesTableSettings {
  columnsSettings: SingleProfileColumnSetting[];
  columnsOrder: string[];
  workspace?: string;
}

const defaultColumnsSettings: ColumnsOpts = {
  name: {
    visible: true,
    width: 335,
  },
  status: {
    visible: Boolean(localStorage.getItem('ColumnTableStatus')),
    width: 114,
  },
  notes: {
    visible: true,
    width: 180,
  },
  tags: {
    visible: false,
    width: 185,
  },
  customStatus: {
    visible: true,
    width: 185,
  },
  lastUpdate: {
    visible: Boolean(localStorage.getItem('ColumnTable')),
    width: 120,
  },
  lastLaunch: {
    visible: Boolean(localStorage.getItem('ColumnTableLastLaunch')),
    width: 130,
  },
  createdAt: {
    visible: Boolean(localStorage.getItem('ColumnTableCreatedAt')),
    width: 100,
  },
  sharing: {
    visible: Boolean(localStorage.getItem('ColumnTableSharing')),
    width: 100,
  },
  proxyType: {
    visible: Boolean(localStorage.getItem('ColumnProxyType')),
    width: 120,
  },
  proxy: {
    visible: Boolean(localStorage.getItem('ColumnProxy')),
    width: 150,
  },
  proxyPort: {
    visible: Boolean(localStorage.getItem('ColumnProxyPort')),
    width: 100,
  },
  proxyUsername: {
    visible: Boolean(localStorage.getItem('ColumnProxyUsername')),
    width: 100,
  },
  proxyPassword: {
    visible: Boolean(localStorage.getItem('ColumnProxyPassword')),
    width: 100,
  },
  os: {
    visible: Boolean(localStorage.getItem('ColumnOs')),
    width: 100,
  },
  orbitaVersion: {
    visible: Boolean(localStorage.getItem('ColumnOrbitaVersion')),
    width: 100,
  },
};

const defaultProfilesTableSettings: IProfilesTableSettings = {
  columnsSettings: [],
  columnsOrder: [],
};

export const columnsSettingsAtom = atom<IProfilesTableSettings>(defaultProfilesTableSettings);

export const useColumnsSettings = (): IProfilesTableSettings => useAtomValue(columnsSettingsAtom);

export const getColumnsSettings = (): IProfilesTableSettings => getDefaultStore().get(columnsSettingsAtom);

export const setProfilesTableSettings = (newProfilesTableSettings: IProfilesTableSettings): void =>
  getDefaultStore().set(columnsSettingsAtom, newProfilesTableSettings);

export const setColumnsSettings = (newColumnsOpts: SingleProfileColumnSetting[]): void => {
  const oldColumnsSettings = getColumnsSettings();

  getDefaultStore().set(columnsSettingsAtom, { ...oldColumnsSettings, columnsSettings: newColumnsOpts });
};

export const getDefaultColumnsSettings = (): ColumnsOpts  => {
  const columnsSettings = defaultColumnsSettings;
  let showNotes = localStorage.getItem('ColumnNotes') === null;
  if (localStorage.getItem('ColumnNotes')) {
    showNotes = localStorage.getItem('ColumnNotes') === 'true';
  }

  columnsSettings.notes.visible = showNotes;
  let showStatus = localStorage.getItem('ColumnTableStatus') === null;
  if (localStorage.getItem('ColumnTableStatus')) {
    showStatus = localStorage.getItem('ColumnTableStatus') === 'true';
  }

  columnsSettings.status.visible = showStatus;

  let showProxy = localStorage.getItem('ColumnProxy') === null;
  if (localStorage.getItem('ColumnProxy')) {
    showProxy = localStorage.getItem('ColumnProxy') === 'true';
  }

  columnsSettings.proxy.visible = showProxy;

  return columnsSettings;
};

export const updateProfilesTableSettingsState = (columns: SingleProfileColumnSetting[]): IProfilesTableSettings => {
  const columnsOrder: string[] = [];
  const columnsSettings: SingleProfileColumnSetting[] = columns.map((column: SingleProfileColumnSetting) => {
    if (column.colName) {
      columnsOrder.push(column.colName);
    }

    return {
      ...column,
      width: column.width,
      minWidth: column.minWidth,
      visible: column.visible,
    };
  });

  const res: IProfilesTableSettings = {
    columnsSettings,
    columnsOrder,
  };

  setProfilesTableSettings(res);

  return res;
};

export const handleColumnToggle = async (column: SingleProfileColumnSetting): Promise<void> => {
  const { columnsSettings } = getColumnsSettings();
  const newColumns = columnsSettings.map(col => {
    if (col.colName !== column.colName) {
      return col;
    }

    const { sortField, visible } = col;
    const currSortField = getProfilesSortField();
    if (currSortField === sortField && visible) {
      resetProfilesTableSorting();
    }

    return {
      ...col,
      visible: !visible,
    };
  });

  setColumnsSettings(newColumns);

  await saveProfilesTableColumns();
};

export const saveProfilesTableColumns = async (): Promise<void> => {
  const workspaceId = getCurrentWorkspaceId();
  const { columnsSettings } = getColumnsSettings();

  const columnsSettingsForSave = columnsSettings.map(column => ({ ...column, title: column.colName }));

  await saveWorkspaceProfileTableColumns(workspaceId, columnsSettingsForSave);
};
