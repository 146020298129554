import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconPlay: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width={props.size || 16} height={props.size || 16} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_12591_11008)'>
        <path d='M3.5 2.44313C3.5 1.62977 4.41935 1.15664 5.08122 1.62938L13.381 7.55747C13.9335 7.95212 13.9405 8.77093 13.3948 9.17494L5.095 15.3192C4.435 15.8078 3.5 15.3367 3.5 14.5155V2.44313Z' strokeLinecap='round' strokeLinejoin='round'/>
      </g>
      <defs>
        <clipPath id='clip0_12591_11008'>
          <rect width='16' height='16' fill='var(--FFFFFF)' />
        </clipPath>
      </defs>
    </svg>
  </IconWrapper>
);
