import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconAddGeolocation: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='40' height='32' viewBox='0 0 40 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M31 12C31 18.8 20 30 20 30C20 30 9 18.9 9 12C9 5 14.7 1 20 1C25.3 1 31 5.1 31 12Z'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='square'
      />
      <path d='M20 8V16' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='square' />
      <path d='M16 12H24' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='square' />
    </svg>
  </IconWrapper>
);
