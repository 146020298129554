/* eslint-disable new-cap */
import { FormatIcu } from '@tolgee/format-icu';
import {
  withTolgee,
  Tolgee,
  I18nextPlugin,
  FormatSimple,
  DevBackend,
  DevTools,
} from '@tolgee/i18next';
import i18n from 'i18next';
import ICU from 'i18next-icu';
import { initReactI18next } from 'react-i18next';

import * as translationEn from './locales/translation/en.json';

const tolgee = Tolgee()
  .use(I18nextPlugin())
  .use(FormatSimple())
  .use(FormatIcu())
  .use(DevTools())
  .use(DevBackend())
  .init({
    apiUrl: 'https://app.tolgee.io',
    apiKey: 'tgpak_ge2don27nbrwgm3jgvutcmdlonrhm5runnvxeyjroazwuztune',
    defaultNs: 'translation',
  });

withTolgee(i18n, tolgee)
  .use(ICU)
  .use(initReactI18next)
  .init({
    fallbackLng: ['en', 'en-FALLBACK'],
    defaultNS: 'translation',
    interpolation: {
      escapeValue: false,
    },
    lng: 'en',
    preload: ['en'],
    ns: ['translation'],
    supportedLngs: ['en', 'fr', 'ru-RU', 'pt', 'vi-VN', 'ja', 'zh-Hans-CN', 'en-FALLBACK'],
  });

i18n.addResourceBundle('en-FALLBACK', 'translation', translationEn);

export default i18n;
