import { IProxy } from '../../../interfaces';

export const determineAreProxyCheckSensitiveFieldsEqual = (
  proxy1: IProxy,
  proxy2: IProxy,
  options: { shouldCompareMode?: boolean } = { shouldCompareMode: true },
): boolean => {
  if (options.shouldCompareMode && proxy1.mode !== proxy2.mode) {
    return false;
  }

  return proxy1.host === proxy2.host &&
    proxy1.port === proxy2.port &&
    proxy1.username === proxy2.username &&
    proxy1.password === proxy2.password;
};

export const areProxyFieldsEqual = (proxy1: IProxy, proxy2: IProxy): boolean => proxy1.mode === proxy2.mode &&
  (
    (proxy1.mode === 'gologin' && proxy1.autoProxyRegion === proxy2.autoProxyRegion) ||
    (proxy1.mode === 'tor' && proxy1.torProxyRegion === proxy2.torProxyRegion) ||
    determineAreProxyCheckSensitiveFieldsEqual(proxy1, proxy2, { shouldCompareMode: false })
  );

export const findProxyInList = (proxy: IProxy, proxyList: IProxy[]): IProxy|undefined => {
  let proxyFound: IProxy|undefined;
  if (proxy.id) {
    proxyFound = proxyList.find(proxyListItem => proxyListItem.id === proxy.id);
  }

  if (!proxyFound) {
    proxyFound = proxyList.find((proxyInList) => areProxyFieldsEqual(proxyInList, proxy));
  }

  return proxyFound;
};

export const getProxyForProxySelector = (proxyList: IProxy[], proxy?: IProxy): IProxy|null => {
  if (!proxy) {
    return null;
  }

  let proxyForProxySelector = findProxyInList(proxy, proxyList);
  if (!proxyForProxySelector) {
    proxyForProxySelector = proxy;
  }

  return proxyForProxySelector;
};
