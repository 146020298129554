import { IDataToCreateProfile, IImportSourceData, createProfileByData } from './api';
import { IOnImportFile } from './interfaces';
import { randomWords } from '../../features/common/randomizer';
import { parseNameFormatting } from '../../utils/parse-name-formatting';
import { INewNameProfile, IParseNameFormatting } from '../../utils/parse-name-formatting/interfaces';
import { getUserOS } from '../../utils/user-os';

export const useHandleImportFile = () => {
  const onImportFile = async (dataToImport: IOnImportFile): Promise<string[]|null> => {
    const { filesToImport, selectedFolder, workspaceId, browserName } = dataToImport;
    const dataToCreateNames: INewNameProfile[] = filesToImport.map((file) => {
      const { parsedData } = file.facebookAccount || {};
      const { email } = parsedData || {};

      if (!browserName) {
        return { name: randomWords(), email };
      }

      return { email };
    });

    const formattingParams: IParseNameFormatting = { profiles: dataToCreateNames, nameFormat: browserName };
    const newNames = parseNameFormatting(formattingParams);
    const preparedFiles: IDataToCreateProfile[] = filesToImport.map((file, index) => ({ profileName: newNames[index].name, ...file }));
    const userOs = await getUserOS();

    const preparedDataToImport: IImportSourceData = {
      sources: preparedFiles,
      workspaceId,
      userOs: userOs.name,
      osSpec: userOs.spec,
      selectedFolder,
    };

    return createProfileByData(preparedDataToImport).catch(() => null);
  };

  return {
    onImportFile,
  };
};
