export const isPortValid = (port: number|string): boolean => {
  if (!port) {
    return false;
  }

  const parsedPort = Number(port);
  const isInputNumberValid = !isNaN(parsedPort) && Number.isInteger(parsedPort);
  if (isInputNumberValid) {
    return parsedPort >= 0 && parsedPort <= 65535;
  }

  return false;
};

export const isHostValid = (host: string): boolean => {
  if (!host) {
    return false;
  }

  // регулярка на то, что юзер пытался ввести хост ipv4
  const ipv4HostRegex = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;
  // регулярка на корректность введенных данных, в соответствии с ipv4
  const hostIPv4ValidatorRegex = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

  const isIPv4Host = ipv4HostRegex.test(host);
  if (isIPv4Host) {
    return hostIPv4ValidatorRegex.test(host);
  }

  return true;
};
