import styled from '@emotion/styled';
import { Icon, Table } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const ArrowDrawer = styled('div')`
  border: solid var(--01AD7F);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(135deg);
  opacity: 1;
  cursor: pointer;
`;

const ElementContainer = styled('div')`
  font-family: 'Roboto';
  margin: 0 25px 0 25px;
`;

const TitleContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 41px;
  margin-left: 36px;
`;

const TittleSetting = styled('div')`
  font-weight: 500;
  font-family: 'Roboto';
  font-size: 20px;
  color: var(--000000);
  margin-left: 12px;
  margin-top: -2px;
`;

const TittleSettingSave = styled('div')`
  font-weight: 400;
  font-family: 'Roboto';
  font-size: 14px;
  margin-left: 20px;
  margin-top: 2px;
`;

const TableLanguage = styled(Table)`
  background-color: var(--008000);
  thead > tr > th {
    background-color: var(--FFFFFF);
  }

  .ant-checkbox {
    padding: 0;
   }
  .ant-checkbox {
  .ant-table-thead > tr > th {
    border-bottom: 0px solid var(--E8E8E7);
    }
  }
`;

const ContainerNameCol = styled('div')`
  flex-direction: row;
  display: flex;
  align-items: baseline;
  color: var(--ACACAC);
`;

declare interface IProfileDrawerLanguage {
  languages?: string;
  localesDrawerVisible?: boolean;
  profileInfo?: any;
  changeVisibility: () => void;
}

export const DrawerLanguage: FC<IProfileDrawerLanguage> = (props) => {

  const { t: translation } = useTranslation();

  const sortAlphabet = (a:any, b:any) => a > b ? 1 : a < b ? -1 : 0;

  const dataSource = [];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
  };

  const showContent = () => {
    return (
      <>
        <TitleContainer>
          <ArrowDrawer onClick={props.changeVisibility} />
          <TittleSetting>
            {translation('language.title')}
          </TittleSetting>
          <TittleSettingSave>
            {translation('quickSettings.loader.saving')}
          </TittleSettingSave>
          <TittleSettingSave style={{ marginLeft: 22 }}>
            {translation('quickSettings.loader.saved')}
          </TittleSettingSave>
        </TitleContainer>
        <ElementContainer>
          <TableLanguage
            rowKey='key'
            pagination={false}
            scroll={{ y: 500 }}
            dataSource={dataSource}
            rowSelection={rowSelection}
            style={{ cursor: 'pointer' }}
          >
            <Table.Column
              title={(
                <ContainerNameCol>
                  <span>{translation('language.title')}</span>
                </ContainerNameCol>
              )}
              dataIndex='language'
              key='language'
              sorter={(a: any, b: any) => sortAlphabet(a.language, b.language)}
            />
            <Table.Column
              title={() => <Icon type="search" style={{ color: 'var(--ACACAC)' }}/>}
              width={30}
            />
          </TableLanguage>
        </ElementContainer>
      </>
    )
  }

  return (
    <>
      {showContent()}
    </>
  );
}
