import { IProfile } from '../../../interfaces';
import { IGenerateBrowserIconsData } from '../interfaces';
import { getSystemDataForIcons } from './';
import { formatOs } from './format-os';
import { getIconDesignVersion } from './get-icon-design-version';
import { getPickedMajorVersion } from './get-picked-major-version';

export const getParamsToGenerateIcons = ({ id, name, navigator }: IProfile): IGenerateBrowserIconsData => {
  const { osPlatform, winVersionMajor, scaleFactor, browsersLocal, browsersVersionsFull } = getSystemDataForIcons();
  const pickedMajorVer = getPickedMajorVersion(navigator.userAgent, browsersLocal);
  const osFormatted = formatOs(osPlatform, winVersionMajor);
  const iconDesignVersion = getIconDesignVersion(osFormatted, pickedMajorVer, browsersVersionsFull);
  const browserIconsData: IGenerateBrowserIconsData = { profileId: id, profileName: name, osFormatted, scaleFactor, iconDesignVersion };

  return browserIconsData;
};
