import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddFolderInputContainer } from './styles';
import { blackMain, greyDark, greyInformers, greyLight } from '../../style-templates/colors';
import { IconPlus } from '../icons';
import { AddFolderContainer, AddFolderInput, Text } from '../styles';

interface ICreateFolderForm {
  folderName: string;
  setFolderName: (name: string) => void;
  onKeyPress: (e: React.KeyboardEvent, folderName: string) => void;
}

const CreateFolderForm: FC<ICreateFolderForm> = ({ folderName, setFolderName, onKeyPress }): JSX.Element => {
  const [isFocusAddFolder, setIsFocusAddFolder] = useState<boolean>(false);

  const { t: translation } = useTranslation();

  if (!isFocusAddFolder) {
    return (
      <AddFolderContainer
        colorIconType='activeGray'
        onClick={(): void => setIsFocusAddFolder(true)}
        iconColor='var(--98989F-header-create-folder-form)'
        iconHoveredColor='var(--2B2B31-header-create-folder-form)'
        iconType='stroke'
        textColor='var(--767676-header-create-folder-form)'
        textHoveredColor='var(--2B2B31-header-create-folder-form)'
      >
        <IconPlus
          padding={0}
          margin='0 10px 0 0'
        />
        <Text>
          {folderName || translation('folders.createFirstFolder.addFolder')}
        </Text>
      </AddFolderContainer>
    );
  }

  return (
    <AddFolderInputContainer>
      <IconPlus
        styleType='gray'
        padding={0}
        margin='0 10px 0 0'
        iconColor='var(--2B2B31-header-create-folder-form)'
      />
      <AddFolderInput
        value={folderName}
        placeholder={translation('folders.createFirstFolder.addFolder')}
        onChange={(e): void => setFolderName(e.target.value)}
        onKeyPress={(e): void => onKeyPress(e, folderName)}
        onBlur={(): void => setIsFocusAddFolder(false)}
        autoFocus={true}
      />
    </AddFolderInputContainer>
  );
};

export default CreateFolderForm;
