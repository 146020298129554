import { ICheckAllowedFilesResult } from '../interfaces';

export const checkAllowedFiles = (files: FileList): ICheckAllowedFilesResult => {
  let filesSize = 0;
  const allowedFiles: File[] = [...files].reduce<File[]>((acc, file) => {
    const isAllowedFileType = file.type === 'application/json' || file.type.match(/^text*/);
    if (isAllowedFileType) {
      filesSize += file.size;
      acc.push(file);
    }

    return acc;
  }, []);

  const allowedFilesCount = allowedFiles.length;

  if (!allowedFilesCount) {
    return { error: 'onlyTextFile' };
  }

  if (allowedFilesCount !== files.length) {
    return { allowedFiles, error: 'onlyTextFile' };
  }

  const filesSizeLimit = 4 * 1024 * 1024;
  if (filesSize >= filesSizeLimit) { // временный лимит на общий размер файлов
    return { error: 'filesSize' };
  }

  return { allowedFiles };
};
