import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled';
import { Popover } from '@material-ui/core';

import { typeColorIcon } from '../../../ui/gologin-header/icons/wrapper';
import { textFont } from '../../../ui/style-templates';

export const itemRowIconProps: { padding: number; styleType: typeColorIcon; iconColor: string } = {
  padding: 0,
  styleType: 'lightGray',
  iconColor: 'var(--B5B5BA-header-account-menu)',
};

export const PopoverCustom = styled(Popover)`
  z-index: 950;

  .MuiPopover-paper {
    width: 212px;

    background: var(--FFFFFF-header-folders-menu);
    border-radius: 4px;

    padding: 4px;
    margin-left: 6px;

    display: flex;
    flex-direction: column;
    box-shadow: var(--box-shadow-popup);

    & > * {
      margin-top: 1px;
    }
  }
`;

export const ItemRow = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 12px;
  
  cursor: pointer;

  background: inherit;
  border-radius: 4px;

  user-select: none;

  ${textFont};
  color: var(--2B2B31-header-account-menu);

  :hover {
    background: var(--EAEAEB-header-account-menu);
  }

  ${(props): SerializedStyles | null => props.isDisabled ? css`
    color: var(--00000040-antd);
    cursor: not-allowed;

    :hover {
      background: transparent;
    }
  ` : null};
`;

export const DividerContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 10px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: var(--EBEBEB-header);
`;
