import styled from '@emotion/styled';

export const IconWrapper = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Icon = styled.img`
  width: 32px;
  height: 32px;
`;

export const TitleText = styled.div`
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 16px;

  font: 400 16px/24px Roboto;
  letter-spacing: 0.44px;
  color: var(--222222);
`;
