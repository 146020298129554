import React from 'react';
import { Trans } from 'react-i18next';

import { ProxySectionContextMenu } from './proxy-section-context-menu';
import { ProxySectionItemTitle, ProxySectionItemWrapper } from './styles';
import { ProxySection } from '../../../../../state/proxy/proxy-groups/types';

const PROXY_SECTION_TEXT = {
  availableProxies: 'proxies.groupsSection.available',
  unavailableProxies: 'proxies.groupsSection.unavailable',
} as const;

type ProxySectionItemProps = Pick<ProxySection, 'id' | 'title' | 'isContextMenuEnabled'> & {
  style: React.CSSProperties;
}

const ProxySectionItem: React.FC<ProxySectionItemProps> = (props) => {
  const { title, isContextMenuEnabled, style } = props;

  const finalStyle: React.CSSProperties = {
    ...style,
    height: 30,
    margin: '1px 4px',
  };

  return (
    <ProxySectionItemWrapper style={finalStyle}>
      <ProxySectionItemTitle>
        <Trans i18nKey={PROXY_SECTION_TEXT[title]} />
      </ProxySectionItemTitle>
      {isContextMenuEnabled ? <ProxySectionContextMenu /> : null}
    </ProxySectionItemWrapper>
  );
};

export default ProxySectionItem;
